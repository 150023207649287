import React from "react";
import Layout from "../../../components/Layout";
import Title from "../../../components/Title";

import FormNewRequest from "@pages/User/NewRequestOld/FormNewRequest";
import { FormProviderRequestOrderOld } from "@pages/User/NewRequestOld/FormContext";

const NewRequestOld = () => {
  return (
    <Layout>
      <div style={{ gridArea: "content" }}>
        <Title title="Novo Pedido" />
        <FormProviderRequestOrderOld>
          <FormNewRequest />
        </FormProviderRequestOrderOld>
      </div>
    </Layout>
  );
};

export default NewRequestOld;
