import React from "react";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import * as yup from "yup";

import {
  Box,
  Container,
  Typography,
  Avatar,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  RadioProps,
  Button,
  TypographyProps,
  InputAdornment,
  IconButton,
  createStyles,
  Theme,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";

import { IEmployeeRegister } from "@modules/employee/models/IEmployeeService";

import { useHomeUserContext } from "@context/HomeUserContext";

import { AvatarUserIcon, CloseIcon, MarketIcon } from "@components/Icons";
import { StyledButton } from "@components/StyledButton";
import PhoneTextField from "@components/PhoneTextField";
import TextFieldCustom from "@components/TextFieldCustom";
import Calendar from "@components/Calendar";

import AppError from "@utils/AppError";
import {
  capitalizeFirstLetterFullString,
  formatToIsoDate,
  maskCNPJ,
} from "@utils/index";

import { useEmployee } from "@pages/User/Employee/context";

interface FormCreateEmployeeProps {
  children?: React.ReactNode;
  identifid?: string;
  handle?: () => void; // manipula o evento do drawer
}

const FormCreateEmployee: React.FC<FormCreateEmployeeProps> = ({
  children,
  ...props
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { handleOnClickCreateEmployee } = useEmployee();

  const { documentList } = useHomeUserContext();
  let companySelected = documentList.filter((item) => item.isSelected)[0];

  const [turnState, setTurnState] = React.useState("diurno");
  const handleChangeTurn = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTurnState((event.target as HTMLInputElement).value);
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Insira um e-mail válido")
      .required("Este campo é obrigatório"),
    name: yup
      .string()
      .min(2, "Nome muito curto!")
      .max(50, "Nome muito longo!")
      .required("Este campo é obrigatório"),
    birthday: yup.string().nullable().required("Este campo é obrigatório"),
    telephone: yup
      .string()
      .matches(
        /\(\d{2}\) \d{4,5}-\d{4}|^\d{10,11}/g,
        "Insira um telefone válido"
      )
      .required("Este campo é obrigatório"),
  });

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        birthday: null,
        telephone: "",
        turn: "diurno",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        try {
          const payloadData: IEmployeeRegister = {
            name: values.name,
            telephone: values.telephone,
            email: values.email,
            birthdate: formatToIsoDate(values.birthday),
            work_shift: values.turn,
            identifid: props.identifid,
            document: companySelected.cnpj,
          };

          handleOnClickCreateEmployee(payloadData);

          props.handle && props.handle(); // fechar o drawerRegister

          enqueueSnackbar("Funcionario cadastrado com sucesso!", {
            variant: "success",
          });

          actions.resetForm(); // Limpa campos do formulario
        } catch (error) {
          if (error as AppError) {
            enqueueSnackbar("Ocorreu um erro ao cadastrar o funcionario", {
              variant: "error",
            });
          }
        }
      }}
    >
      {(propsformik) => (
        <Form>
          <Container className={classes.container}>
            <Box style={{ alignSelf: "end" }}>
              <Button onClick={props.handle}>
                <CloseIcon className={classes.closeIcon} />{" "}
              </Button>
            </Box>

            <Box style={{ marginBottom: 32 }}>
              <STypography className={classes.title}>
                Cadastrar funcionário
              </STypography>
            </Box>

            <Box
              style={{
                alignSelf: "center",
                marginTop: "4rem",
                marginBottom: "2rem",
                textAlign: "center",
              }}
            >
              <Avatar className={classes.avatar}>
                <AvatarUserIcon
                  className={classes.avatarUserIcon}
                ></AvatarUserIcon>
              </Avatar>
              <STypography
                style={{ fontWeight: 700, marginTop: 22 }}
                variant="body2"
              >
                {props.identifid ? props.identifid : ""}
              </STypography>
              <STypography style={{ fontWeight: 600, opacity: 0.7 }}>
                ID
              </STypography>
            </Box>

            <Grid container className={classes.container} spacing={3}>
              <Grid item>
                <TextFieldCustom
                  type="search"
                  name="name"
                  label="Nome e sobrenome"
                  value={propsformik.values.name}
                  onChange={propsformik.handleChange}
                  onBlur={propsformik.handleBlur}
                  error={
                    propsformik.touched.name && Boolean(propsformik.errors.name)
                  }
                  helperText={
                    propsformik.touched.name && propsformik.errors.name
                  }
                  variant="outlined"
                />
              </Grid>

              <Grid item>
                <TextFieldCustom
                  id="email"
                  type="email"
                  label="E-mail"
                  name="email"
                  value={propsformik.values.email}
                  onChange={propsformik.handleChange}
                  onBlur={propsformik.handleBlur}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          {propsformik.touched.email &&
                          !propsformik.errors.email ? (
                            <Check style={{ color: "#0BB873", opacity: "1" }} />
                          ) : (
                            <Check style={{ color: "black", opacity: "0" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={
                    propsformik.touched.email &&
                    Boolean(propsformik.errors.email)
                  }
                  helperText={
                    propsformik.touched.email && propsformik.errors.email
                  }
                />
              </Grid>

              <Grid item>
                <Calendar
                  name="birthday"
                  label="Data de Nascimento"
                  value={propsformik.values.birthday}
                  onChange={propsformik.handleChange}
                  onBlur={propsformik.handleBlur}
                  error={
                    propsformik.touched.birthday &&
                    Boolean(propsformik.errors.birthday)
                  }
                  helperText={
                    propsformik.touched.birthday && propsformik.errors.birthday
                  }
                />
              </Grid>

              <Grid item>
                <PhoneTextField
                  label="Telefone (+DDD)"
                  name="telephone"
                  value={propsformik.values.telephone}
                  onChange={propsformik.handleChange}
                  onBlur={propsformik.handleBlur}
                  error={
                    propsformik.touched.telephone &&
                    Boolean(propsformik.errors.telephone)
                  }
                  helperText={
                    propsformik.touched.telephone &&
                    propsformik.errors.telephone
                  }
                  variant="outlined"
                />
              </Grid>

              <Grid item style={{ marginLeft: 16, marginTop: "1rem" }}>
                <STypography style={{ fontWeight: 900, fontSize: "1.5rem" }}>
                  Turno:
                </STypography>
                <RadioGroup
                  aria-label="gender"
                  name="turn"
                  value={turnState}
                  onChange={handleChangeTurn}
                  onBlur={propsformik.handleBlur}
                >
                  <FormControlLabel
                    value="diurno"
                    control={<RadioRed />}
                    label="Diurno"
                  />
                  <FormControlLabel
                    value="noturno"
                    control={<RadioRed />}
                    label="Noturno"
                  />
                </RadioGroup>
              </Grid>

              <Grid item style={{ display: "flex", justifyContent: "center" }}>
                <Box className={classes.boxMarketIcon}>
                  <MarketIcon />
                </Box>
                <Box style={{ marginTop: 4 }}>
                  <STypography style={{ fontWeight: 700 }}>
                    {capitalizeFirstLetterFullString(companySelected.label)}
                  </STypography>
                  <STypography style={{ fontWeight: 400, opacity: 0.5 }}>
                    {maskCNPJ(companySelected.cnpj)}
                  </STypography>
                </Box>
              </Grid>
            </Grid>

            <Grid container></Grid>

            <StyledButton
              type="submit"
              variant="contained"
              color="primary"
              style={{ color: "white" }}
              loading={propsformik.isSubmitting}
            >
              Cadastrar
            </StyledButton>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default FormCreateEmployee;

const RadioRed = withStyles({
  root: {
    "&$checked": {
      color: "#D91F05",
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

const STypography = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: "montserrat",
      color: "#3E3D3D",
    },
    label: {
      textTransform: "capitalize",
    },
  })
)((props: TypographyProps) => <Typography {...props} />);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: 650,
    },
    fullList: {
      width: "auto",
    },
    gridItem: {
      display: "flex",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      width: "auto",
      overflowX: "hidden",
      margin: "2rem",
    },
    closeIcon: {
      margin: "2rem",
    },
    avatar: {
      height: "fit-content",
      width: "fit-content",
      marginLeft: "auto",
      marginRight: "auto",
    },
    avatarUserIcon: {
      fontSize: "120px",
      fill: "none",
    },
    boxMarketIcon: {
      height: "fit-content",
      width: "fit-content",
      marginRight: "1.5rem",
      marginTop: "0.6rem",
    },
    title: {
      fontFamily: "Montserrat",
      fontWeight: 700,
      fontSize: "2rem",
      paddingLeft: "1rem",
      color: theme.palette.text.primary,
    },
    buttonUpdate: {
      color: theme.palette.primary.main,
      textTransform: "initial",
      fontWeight: 700,
      padding: 0,
      margin: 0,
      lineHeight: 0,
    },
    textfield: {
      root: {
        width: "100%",
        opacity: "0.6",
        //color: theme.palette.primary.highlightedplus,
        "& label": {
          // Controle de estilo antes de digitar
          fontFamily: "montserrat",
          fontWeight: 400,
          fontSize: "1.4rem",
          borderRadius: "1rem",
        },
        "& input.Mui-focused": {}, // Controle de estilo durante digitacao
        "& .MuiInputLabel-shrink": {
          // Controle de estilo apos campo digitado
          fontWeight: 900,
          color: theme.palette.primary.highlightedplus,
          fontSize: "1.8rem",
          backgroundColor: "white",
          paddingRight: 6,
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
          // Controla cor da borda durante Digitacao
          border: "2px solid #FF9900",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderRadius: "1rem",
        },
      },
    },
  })
);
