import React, { useEffect } from "react";
import { Field, useFormikContext } from "formik";

import {
  FormControl,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { RemoveCircle, AddCircle, DeleteForever } from "@material-ui/icons";

import { compartmentsAvailable } from "../../../../config/appConfig";
import {
  IFormCreateOrderService,
  SimulationProduct,
} from "../../../../utils/interfaces";

const BootstrapSelect = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    margin: 0,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    color: "#626166",
    padding: "2.4rem",
    border: "none",
    borderRadius: "15px",
    fontSize: 14,
    fontFamily: "Open Sans, sans-serif",
  },
}));

interface TableProductsProps {
  slots: number;
}

interface TableProductRowProps {
  product: SimulationProduct;
  remove: (key: string) => void;
  key: any;
  id: number;
  slots: number;
}

const TableProductRow: React.FC<TableProductRowProps> = ({
  product,
  remove,
  id,
  slots,
}) => {
  const { values, setFieldTouched, setFieldValue } = useFormikContext<
    IFormCreateOrderService
  >();

  const incrementArrow = () => modifyArrow("NEXT");
  let decrementArrow = () => modifyArrow("PREVIOUS");

  const setCompartment = (value: string) => {
    const products = values.products.map((productID) => {
      if (product.uuid === productID.uuid) {
        product.compartment = value;
      }
      return productID;
    });

    setFieldValue("products", products);
  };

  const modifyArrow = (increment: "NEXT" | "PREVIOUS") => {
    const products = values.products.map((currentProducts) => {
      if (product.uuid === currentProducts.uuid) {
        const arrowCalc =
          ((product.current_arrow || 0) + (increment === "NEXT" ? 1 : -1)) %
          product.arrows!.length;
        product.current_arrow =
          arrowCalc >= 0 ? arrowCalc : product.arrows!.length - 1;
        product.quantity = (product.arrows as number[])[product.current_arrow];
      }
      return currentProducts;
    });

    setFieldValue("products", products);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            color="primary"
            component="label"
            onClick={() => remove(product.uuid as string)}
          >
            <DeleteForever />
          </IconButton>
        </TableCell>
        <TableCell align="center">{product.id}</TableCell>
        <TableCell align="center">{product.description}</TableCell>
        <TableCell align="center">
          <FormControl>
            <Select
              name="compartment"
              id="compartment"
              displayEmpty={true}
              onBlur={() => setFieldTouched("compartment", true)}
              onChange={(e) =>
                setCompartment(
                  (e as { target: { value: string } }).target.value
                )
              }
              value={product.id ? product.compartment : ""}
              input={<BootstrapSelect />}
            >
              <MenuItem disabled value="">
                <em>Selecione o compartimento</em>
              </MenuItem>
              {compartmentsAvailable.slice(0, slots).map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell align="center">
          <FormControl>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                backgroundColor: "white",
                border: "none",
                fontSize: "1.6rem",
                color: "#626166",
                borderRadius: "15px",
                padding: "1.6rem 2.4rem",
              }}
            >
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={decrementArrow}
              >
                <RemoveCircle />
              </IconButton>

              <Field
                value={`${product.quantity
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`}
                onFocus={(e: {
                  target: {
                    value: string;
                    setSelectionRange: (i: number, o: number) => void;
                  };
                }) => e.target.setSelectionRange(0, e.target.value.length)}
                onClick={(e: {
                  currentTarget: {
                    value: string;
                    setSelectionRange: (i: number, o: number) => void;
                  };
                }) =>
                  e.currentTarget.setSelectionRange(
                    0,
                    e.currentTarget.value.length
                  )
                }
                style={{
                  outline: "none",
                  fontSize: "1.6rem",
                  fontFamily: "Open Sans, sans-serif",
                  fontWeight: 400,
                  color: "#626166",
                  textAlign: "center",
                  width: 120,
                }}
                placeholder=""
                disabled={true}
              />
              <IconButton
                color="primary"
                component="label"
                onClick={incrementArrow}
              >
                <AddCircle />
              </IconButton>
            </div>
          </FormControl>
        </TableCell>
      </TableRow>
    </>
  );
};

const TemporaryTableProduct: React.FC<TableProductsProps> = ({ slots }) => {
  const { values, setFieldValue } = useFormikContext<IFormCreateOrderService>();

  const removeProduct = (key: string) => {
    let products = values.products.filter((ele) => ele.uuid !== key);
    setFieldValue("products", products);
  };

  useEffect(() => {
    setFieldValue("products", []);
  }, [setFieldValue]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center"></TableCell>
            <TableCell align="center">Código</TableCell>
            <TableCell align="center">Descrição</TableCell>
            <TableCell align="center">Compartimento</TableCell>
            <TableCell align="center">Quantidade(LT)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.products.map((product, idx) => {
            return (
              <TableProductRow
                slots={slots}
                key={product.uuid}
                id={idx}
                product={product}
                remove={removeProduct}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { TemporaryTableProduct };
