import { CardTitleType } from "@modules/cardTitles/container/types";
import { EmployeeTypes } from "@modules/employee/container/types";
import { InvoicesTypes } from "@modules/invoices/container/types";
import { LastOrdersType } from "@modules/lastOrders/container/types";
import { LoginImagesTypes } from "@modules/loginImages/container/types";
import { LogsTypes } from "@modules/logs/container/types";
import { ReclamationTypes } from "@modules/reclamation/container/types";
import { TitlesTypes } from "@modules/titles/container/types";
import { UserTypes } from "@modules/user/container/types";
import { HandoutsType } from "../modules/handouts/container/types";
import { OrdersType } from "../modules/orders/container/types";
import { SimulationTypes } from "./../modules/simulation/container/types";

import { ComissionTypes } from "@modules/comissionCalculator/container/types";
import { CommunicationTypes } from "@modules/communications/container/type";
import { NozzlesTypes } from "@modules/nozzles/container";
import { ProductBlockTypes } from "@modules/productBlock/container/types";
import { ProjectionSalesTypes } from "@modules/projectionSales/container/types";
import { SalesLastMonthTypes } from "@modules/salesLastMonth/container/types";
import { SalesPerformanceTypes } from "@modules/salesPerformance/container/types";
import { SalesVolumeTypes } from "@modules/salesVolume/container/types";
import { SummaryChartTypes } from "@modules/summaryChart/container/types";
import { YourSalesTypes } from "@modules/yourSales/container/types";
import { SalesVolumeTypesHistory } from "@modules/salesVolumeHistory/container/types";
import { RoadLoadTypes } from "@modules/roadLoad/container";
import { DriverCustomerTypes } from "@modules/driverAndCustomer/container/types";
import { DiscountTypes } from "@modules/discount/container/types";
import { ProductType } from "@modules/product/container/types";

export const Types = {
  IHttpService: Symbol("IHttpService"),
  Orders: { ...OrdersType },
  Simulation: { ...SimulationTypes },
  LastOrders: { ...LastOrdersType },
  CardTitle: { ...CardTitleType },
  Handouts: { ...HandoutsType },
  Titles: { ...TitlesTypes },
  Invoices: { ...InvoicesTypes },
  Users: { ...UserTypes },
  LoginImages: { ...LoginImagesTypes },
  Logs: { ...LogsTypes },
  Reclamation: { ...ReclamationTypes },
  Employee: { ...EmployeeTypes },
  YourSales: { ...YourSalesTypes },
  ProjectionSales: { ...ProjectionSalesTypes },
  SalesPerformance: { ...SalesPerformanceTypes },
  SalesVolume: { ...SalesVolumeTypes },
  SalesVolumeHistory: { ...SalesVolumeTypesHistory },
  Nozzles: { ...NozzlesTypes },
  ProductBlock: { ...ProductBlockTypes },
  Communication: { ...CommunicationTypes },
  SalesLastMonth: { ...SalesLastMonthTypes },
  SummaryChart: { ...SummaryChartTypes },
  Comission: { ...ComissionTypes },
  RoadLoad: { ...RoadLoadTypes },
  DriverCustomer: { ...DriverCustomerTypes },
  Discount: { ...DiscountTypes },
  Product: { ...ProductType },
};
