/* eslint-disable react-hooks/exhaustive-deps */

import React, { createContext, useContext, useEffect, useState } from "react";

import { IGetNozzlesService } from "@modules/nozzles/models";

import { IDocument, useHomeUserContext } from "@context/HomeUserContext";
import { useIoCContext } from "@context/IoCContext/IoCContext";

import {
  INozzle,
  INozzleSendFile,
  INozzlesModal,
} from "@pages/User/Nozzle/model";

import useDialogAlert from "@hooks/useDialogAlert";

import AppError from "@utils/AppError";

import { Types } from "@ioc/types";

interface INozzleContext {
  selectedCNPJ: IDocument;
  loading: boolean;
  nozzles: INozzle[];
  nozzlesSelectList: INozzlesModal[];
  handleOnClickFetchNozzles: () => void;
  handleOnClickSendImage: (payload: INozzleSendFile) => void;
  handleOnClickUpdateImage: (payload: INozzleSendFile) => void;
  loadingFetchNozzles: boolean;
}

const NozzleContext = createContext<INozzleContext | undefined>(undefined);

const NozzleProvider: React.FC = ({ children }) => {
  const { snackbar } = useDialogAlert();

  const { documentList } = useHomeUserContext();
  const [selectedCNPJ, setSelectedCNPJ] = React.useState<IDocument>(
    documentList.filter((item) => item.isSelected)[0]
  );

  useEffect(() => {
    if (selectedCNPJ !== documentList.filter((item) => item.isSelected)[0]) {
      setSelectedCNPJ(documentList.filter((item) => item.isSelected)[0]);
    }
  }, [documentList]);

  const [nozzles, setNozzles] = React.useState<INozzle[]>([]);
  const [nozzlesSelectList, setNozzlesSelectList] = React.useState<
    INozzlesModal[]
  >([]);

  const [listenOnClickSendImage, setListenOnClickSendImage] = useState<boolean>(
    false
  );
  const [listenOnClickUpdateImage, setListenOnClickUpdateImage] = useState<
    boolean
  >(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFetchNozzles, setLoadingFetchNozzles] = useState<boolean>(
    false
  );

  const iocContext = useIoCContext();
  const nozzlesService = iocContext.serviceContainer.get<IGetNozzlesService>(
    Types.Nozzles.IGetNozzlesService
  );

  const nozzlesSelectListModal = (nozzlesResponse: INozzle[]) => {
    const nozzleSelectList: INozzlesModal[] = nozzlesResponse.map((group) => {
      const nozzleListByGroup = group.nozzles.map((nozzle) => {
        return nozzle.nozzle;
      });

      let modalSelect;
      if (nozzleListByGroup.length > 0) {
        modalSelect = {
          group: group.group,
          nozzles: nozzleListByGroup,
        };
      } else {
        modalSelect = {
          group: group.group,
          nozzles: [],
        };
      }
      return modalSelect;
    });

    setNozzlesSelectList(nozzleSelectList);
  };

  const handleOnClickFetchNozzles = async () => {
    try {
      setLoadingFetchNozzles(true);

      const nozzlesResponse = await nozzlesService.getNozzles({
        document: selectedCNPJ.cnpj,
      });
      nozzlesSelectListModal(nozzlesResponse); // Transforma em lista de bicos para renderizar no modal, Ex: [ {a1,[2,3,4]}, {a2,[10,11,12]} ]
      setNozzles(nozzlesResponse);

      if (nozzlesResponse.length === 0) {
        snackbar({
          message: `Nenhum registro foi encontrado com base no cnpj selecionado - ${selectedCNPJ.cnpj}`,
          variant: "info",
        });
      }
    } catch (err) {
      if (err instanceof AppError) {
        console.info(`error reason for fetch nozzles: ${err?.message}`);
        snackbar({
          message: `Erro ao carregar dados dos bicos e produtos - ${err?.message}`,
          variant: "error",
        });
      }
    } finally {
      setLoadingFetchNozzles(false);
    }
  };

  const handleOnClickSendImage = async (payload: INozzleSendFile) => {
    try {
      setLoading(true);

      await nozzlesService.addNozzlesFile(payload);
      setListenOnClickSendImage(!listenOnClickSendImage);
      snackbar({
        message: `Bico e produto cadastrado com sucesso (CC)`,
        variant: "success",
      });
    } catch (err) {
      if (err instanceof AppError) {
        console.info(
          `Error reason when trying to SEND image nozzles: ${err?.message}`
        );
        snackbar({
          message: `Erro ao ENVIAR dados dos bicos e produtos - ${err?.message}`,
          variant: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOnClickUpdateImage = async (payload: INozzleSendFile) => {
    try {
      setLoading(true);

      await nozzlesService.updateNozzlesFile(payload);
      setListenOnClickUpdateImage(!listenOnClickUpdateImage);
      snackbar({
        message: `Bico e produto atualizado com sucesso (AC)`,
        variant: "success",
      });
    } catch (err) {
      if (err instanceof AppError) {
        console.info(
          `Error reason when trying to UPDATE image nozzles: ${err?.message}`
        );
        snackbar({
          message: `Erro ao ATUALIZAR dados dos bicos e produtos - ${err?.message}`,
          variant: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        await handleOnClickFetchNozzles();
      } catch (err) {
        if (err instanceof AppError) {
          console.info(`error reason Nozzle all fetch Page: ${err?.message}`);
          snackbar({
            message: `${err?.message}`,
            variant: "error",
          });
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedCNPJ, listenOnClickSendImage, listenOnClickUpdateImage]);

  return (
    <NozzleContext.Provider
      value={{
        selectedCNPJ,
        loading,
        nozzles,
        nozzlesSelectList,
        handleOnClickFetchNozzles,
        handleOnClickSendImage,
        handleOnClickUpdateImage,
        loadingFetchNozzles,
      }}
    >
      {children}
    </NozzleContext.Provider>
  );
};

const useNozzle = (): INozzleContext => {
  const context = useContext(NozzleContext);
  if (!context) {
    throw new Error("useNozzle deve ser utilizado dentro de um NozzleProvider");
  }
  return context;
};

export { NozzleProvider, useNozzle };
