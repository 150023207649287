import { cleanParams } from "@utils/index";
import { inject, injectable, named } from "inversify";
import { Types } from "../../../ioc/types";
import {
  IEmployeeRegister,
  IEmployeeRequestService,
  IEmployeeResponseService,
  IEmployeeService,
} from "../models/IEmployeeService";
import * as IHttpService from "@modules/infra/http/models/IHttpService";

@injectable()
export class EmployeeService implements IEmployeeService {
  @inject(Types.IHttpService)
  @named("pump")
  private httpInstance!: IHttpService.IHttpService;

  addScopes(str?: string): string {
    const scopes = ["whichCompany"];
    if (str) {
      scopes.push(str);
      return JSON.stringify(scopes);
    }

    return JSON.stringify(scopes);
  }

  public async getEmployee(
    data: IEmployeeRequestService
  ): Promise<IEmployeeResponseService> {
    const params = {
      document: data.cnpj,
      company_id: data.company_id ? data.company_id : "",
      scopes: this.addScopes(data.scopes),
      action: data.action ? data.action : "",
    } as IEmployeeRequestService;

    try {
      const employeeResponse = await this.httpInstance.get<
        IEmployeeResponseService
      >(`/employee/?&limit=5000`, {
        params: cleanParams(params),
      });

      return {
        ...employeeResponse,
      };
    } catch (err) {
      throw err;
    }
  }

  public async dropEmployee(id: string): Promise<Boolean> {
    try {
      return await this.httpInstance.delete(`/employee/${id}`);
    } catch (err) {
      throw err;
    }
  }

  public setAuthorization(token: string): void {
    this.httpInstance.setAuthorization(token);
  }

  public async addEmployee(data: IEmployeeRegister): Promise<void> {
    try {
      const employeeResponse = await this.httpInstance.post<void>(
        `/employee/`,
        data
      );
      return employeeResponse;
    } catch (err) {
      throw err;
    }
  }
}
