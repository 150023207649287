import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { IDownloadByLineService } from "../models/IDownlodByLine";
import { DownloadNfeQuery } from "../models/DownloadNfeQuery";

@injectable()
export class DownloadByLineService implements IDownloadByLineService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(query: DownloadNfeQuery): Promise<Blob> {
    return await this.httpInstance.post("/invoices/nfes", query, {
      responseType: "blob",
    });
  }
}
