export interface IDiscountService {
  listDiscounts(cnpj: string): Promise<GasStationProduct>;
  createDiscount(
    cnpj: string,
    discount: NewDiscount
  ): Promise<DiscountResponse>;
}

export interface NewDiscount {
  productId: string;
  value: number;
  paymentMethod: string;
  userId: string;
}

export interface GasStationProduct {
  [key: string]: Discount;
}

export interface Discount {
  discounts: MappedDiscount[];
  internalCode: string;
  value: string;
  unit: string;
  productId: string;
  description?: string;
}

export interface MappedDiscount {
  paymentMethod: string;
  discount: number;
  discountId: string;
}

export interface DiscountResponse {
  companyId?: string;
  createdAt?: string;
  deletedAt?: string;
  id?: string;
  paymentMethod?: string;
  productId?: string;
  updatedAt?: string;
  userId?: string;
  value?: number;
}

export enum PaymentMethod {
  DEBIT = "Débito",
  MONEY = "Dinheiro",
  CREDIT = "Crédito",
  PIX = "Pix",
  FLEET = "Frota",
}
