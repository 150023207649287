import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import React from "react";

const SkeletonHeader: React.FC = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <Grid
      container
      style={{
        display: "flex",
        alignItems: "center",
        width: "230px",
      }}
    >
      <Grid item xs={2} style={{ marginRight: "15px" }}>
        <Skeleton width={35} height={55} style={{ borderRadius: "100%" }} />
      </Grid>

      {!isMobile && (
        <>
          <Grid item xs={8}>
            <Grid container>
              <Grid item>
                <Skeleton width={150} height={20} />
              </Grid>
              <Grid item>
                <Skeleton width={150} height={15} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={1} style={{ marginTop: "-30px" }}>
            <Skeleton width={16} height={30} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default SkeletonHeader;
