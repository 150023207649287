import TabGeneric, { ILabelTab } from "@components/TabGeneric/TabGeneric";
import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Button,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@material-ui/core";
import TableReclamationAll from "./TableReclamationAll";
import { useReclamationContext } from "@context/ReclamationContext/ReclamationUserContext";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { IGetReclamationAdminService } from "@modules/reclamation/models/IGetReclamationAdminService";
import { Types } from "@ioc/types";
import { downloadFile } from "@utils/index";

const TableReclamationIndex: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));
  const {
    dataQueryReclamationUser,
    setTabActive,
    tabActive,
    totalCount,
    filterAdmin,
  } = useReclamationContext();

  const [hasLoadingReportDownload, setHasLoadingReportDownload] = useState(
    false
  );
  const iocContext = useIoCContext();
  const reclamationService = iocContext.serviceContainer.get<
    IGetReclamationAdminService
  >(Types.Reclamation.IGetReclamationAdminService);

  const handleChange = (event: React.ChangeEvent<{}>, newTab: number) => {
    setTabActive(newTab);
  };

  const TabsLabel: ILabelTab[] = [
    {
      label: "Registros em andamento",
      key: 0,
      count: tabActive === 0 ? totalCount : 0,
    },
    {
      label: "Registros finalizados",
      key: 1,
      count: tabActive === 1 ? totalCount : 0,
    },
  ];

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount, dataQueryReclamationUser]);

  const handleReportDownload = async () => {
    try {
      setHasLoadingReportDownload(true);

      const [url, fileName] = await reclamationService.downloadReports(
        filterAdmin
      );
      downloadFile(url, fileName);
      setHasLoadingReportDownload(false);
    } catch (error) {
      throw error;
    }
  };

  return (
    <Container>
      <Grid
        container
        style={{
          justifyContent: "flex-end",
          marginBottom: isMobile || isTablet ? 0 : "-4.4rem",
        }}
        xs={12}
      >
        <Button
          className={classes.buttonDownloadReport}
          color="primary"
          onClick={() => handleReportDownload()}
          endIcon={
            hasLoadingReportDownload && (
              <CircularProgress color="inherit" size={20} />
            )
          }
          disabled={dataQueryReclamationUser.total === 0 ? true : false}
        >
          Baixar relatório completo
        </Button>
      </Grid>
      <TabGeneric
        tabActive={tabActive}
        handleChange={handleChange}
        optionsTabLabel={TabsLabel}
        alignTabs={"left"}
      >
        <TableReclamationAll
          queryDataReclamationUser={dataQueryReclamationUser}
          status={tabActive === 0 ? "IN_PROGRESS" : "CLOSED"}
        />
      </TabGeneric>
    </Container>
  );
};

export default TableReclamationIndex;

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonDownloadReport: {
      textTransform: "none",
      fontFamily: "Montserrat",
      fontWeight: 600,
      lineHeight: "46px",
      height: "3.8rem",
    },
  })
);
