import {
  Container,
  FormHelperText,
  Grid,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import React from "react";

import CalendarRange from "@components/CalendarRange";
import { usePeakTimeHistory } from "@context/PeakTimeHistoryContext";

export interface IFilterPeakTimeHistory {
  startDate: Date | null;
  date_range: [Date | null, Date | null] | null | undefined;
  endDate: Date | null;
}

const FilterPeakTimeHistory: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));

  const { setRangeDate } = usePeakTimeHistory();

  const { values, setFieldValue, setFieldTouched } = useFormikContext<
    IFilterPeakTimeHistory
  >();

  return (
    <Container>
      <Grid
        container
        style={{
          width: "auto",
        }}
      >
        <Grid container>
          <Grid
            md={3}
            xs={12}
            style={{
              marginBottom: isMobile || isTablet ? "25px" : "",
              marginRight: "2.4rem",
            }}
          >
            <CalendarRange
              //@ts-ignore
              name="date_range"
              label="Data ou período"
              value={values.date_range}
              marginTopTextField={"40px"}
              onChange={(date_range) => {
                setFieldValue("date_range", date_range);
                setFieldTouched("startDate", true);
                setFieldTouched("endDate", true);
                if (
                  date_range &&
                  date_range[0] !== null &&
                  date_range &&
                  date_range[1] !== null
                ) {
                  setRangeDate(
                    date_range?.map((cont) => (cont ? cont : new Date())) ?? []
                  );
                }
              }}
              onBlur={() => {
                setFieldTouched("startDate", true);
                setFieldTouched("endDate", true);
              }}
            />
            {(values.date_range && values.date_range[0] === null) ||
              (values.date_range && values.date_range[1] === null && (
                <FormHelperText
                  error={true}
                  style={{
                    marginLeft: "14px",
                    fontSize: isMobile ? "10px" : "12px",
                  }}
                >
                  Data inicial e final é obrigatória
                </FormHelperText>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FilterPeakTimeHistory;
