import React from "react";
import { useTheme } from "@material-ui/core";
import { Box, Grid, Tab, Tabs, Hidden } from "@mui/material";

import DrawerFilter from "@pages/User/RoadLoad/resource/drawer/DrawerFilter";
import { useRoadLoad } from "@pages/User/RoadLoad/context/RoadLoadContext";
import { TableSalesOrder } from "@pages/User/RoadLoad/resource/table/TableSalesOrder";

import {
  IRoadLoadAllNormalized,
  IRoadRowCount,
} from "@pages/User/RoadLoad/model";

import {
  TableTabsTypography,
  TypographyCaption,
  TypographyTitle,
} from "@pages/User/RoadLoad/resource/typography";

import {
  tableHeaderBlocked,
  tableHeaderCanceled,
  tableHeaderScheduled,
  tableHeaderUnscheduled,
  tableHeaderFinished,
} from "@pages/User/RoadLoad/resource/table/header";

import { TabPanel, a11yProps } from "@components/Tab/TabPanel";
import CircularProgress from "@components/CircularProgress";
import Button from "@components/Button";

import TableSalesOrderGrouped from "../resource/table/TableSalesOrderGrouped";
import ModalSchedule from "../resource/modal/ModalSchedule";
import { STATUSROADLOAD } from "@utils/enum";

interface AllRoadLoadProps {
  roadCount: IRoadRowCount;
  road: IRoadLoadAllNormalized;
  handleHiddenAllRoadLoad?: () => void;
}

interface ITabsRoadLoad {
  label: string;
  load?: boolean;
}

const AllRoadLoad: React.FC<AllRoadLoadProps> = (props) => {
  const { road, roadCount, handleHiddenAllRoadLoad } = props;

  const theme = useTheme();
  const {
    loading,
    tabSelected,
    handleRoadLoadMethod,
    queryParams,
    roadsSelected,
    updateRoadSelected,
  } = useRoadLoad();

  const tabsLoads: ITabsRoadLoad[] = [
    {
      label: "Não Agendado",
      load: loading?.waiting,
    },
    {
      label: "Agendados",
      load: loading?.scheduled,
    },
    {
      label: "Concluídos",
      load: loading?.finished,
    },
    {
      label: "Bloqueados",
      load: loading?.blocked,
    },
    {
      label: "Cancelados",
      load: loading?.canceled,
    },
  ];

  const LabelTabs: React.FC<{ tab: ITabsRoadLoad; index: number }> = (
    props
  ) => {
    const { tab, index } = props;
    const quantity: string = roadCount ? Object.values(roadCount)[index] : "-";
    return (
      <TableTabsTypography>
        {tab?.label} ( {tab?.load ? <CircularProgress /> : quantity} )
      </TableTabsTypography>
    );
  };

  const handleUpdateScheduleLoad = async (
    scheduleIndexRemoved: number,
    orderIndexRemoved: number
  ) => {
    await handleRoadLoadMethod.fetchDeleteOrder(
      scheduleIndexRemoved,
      orderIndexRemoved
    );
  };

  return (
    <Grid container className="AllRoadLoad">
      <Grid item xs={12}>
        <TypographyTitle>
          <span
            onClick={handleHiddenAllRoadLoad}
            style={{ cursor: "pointer", opacity: 0.5 }}
          >
            Carregamentos rodoviários
          </span>{" "}
          {">"} <span>Todos os carregamentos</span>
        </TypographyTitle>
      </Grid>

      <Grid item xs={12}>
        <Grid container sx={{ gap: { xs: 1, md: 2 } }}>
          <Grid item xs={12}>
            <Box
              sx={{
                borderColor: "divider",
                p: 1,
                pt: { xs: 1, lg: 3 },
              }}
            >
              <Tabs
                value={tabSelected}
                onChange={(_, tabSelected) => {
                  handleRoadLoadMethod.handleSetTabSelected(tabSelected);
                  updateRoadSelected(undefined);
                }}
                aria-label="basic tabs custom"
                sx={{
                  "& .MuiTabs-scroller": {
                    overflow: "auto !important",
                    scrollbarWidth: "none",
                  },
                  "& .MuiTabs-flexContainer": {
                    border: "0px solid orange",
                    gap: "30px",
                  },
                  "& .Mui-selected": {
                    color: `${theme.palette.primary.main} !important`,
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: theme.palette.primary.main,
                    padding: 0.25,
                    borderRadius: 1.5,
                  },
                }}
              >
                {tabsLoads.map((tab, tabIndex) => {
                  return (
                    <Tab
                      key={tabIndex}
                      sx={{ m: 0, p: 0 }}
                      label={<LabelTabs tab={tab} index={tabIndex} />}
                      {...a11yProps(tabIndex)}
                    />
                  );
                })}
              </Tabs>
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ display: "flex", alignItems: "baseline" }}>
            <Grid container justifyContent={"left"}>
              {queryParams && (
                <Grid item>
                  <Grid container sx={{ gap: 2 }}>
                    <Grid item>
                      <TypographyCaption sx={{ color: "#3E3D3D" }}>
                        Exibindo{" "}
                        <b style={{ color: "#000" }}>
                          {Object.values(roadCount)[tabSelected]}
                        </b>{" "}
                        resultados para o filtro aplicado.
                      </TypographyCaption>
                    </Grid>
                    <Grid item>
                      <Button
                        variant={"text"}
                        onClick={handleRoadLoadMethod.resetFilter}
                        sx={{ color: "gray", p: 0 }}
                      >
                        (Limpar filtro)
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12}>
                <Grid container justifyContent={"end"} alignItems={"center"}>
                  {tabSelected === 0 && (
                    <Grid item>
                      <Hidden mdUp>
                        <Grid item xs={12} textAlign={"right"}>
                          <Button
                            variant="text"
                            onClick={() => updateRoadSelected(undefined)}
                            disabled={roadsSelected?.length === 0}
                          >
                            limpar selecionados
                          </Button>
                        </Grid>
                      </Hidden>
                    </Grid>
                  )}

                  <Grid item>
                    <Hidden mdDown>
                      <DrawerFilter>Filtrar</DrawerFilter>
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {tabSelected === 0 && (
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item sx={{ display: "grid" }}>
                <TypographyCaption>
                  <b style={{ color: "#000" }}>{roadsSelected.length}</b>{" "}
                  Carregamento(s) selecionado(s)
                </TypographyCaption>
                <Hidden mdDown>
                  <Button
                    variant="text"
                    onClick={() => updateRoadSelected(undefined)}
                    disabled={roadsSelected?.length === 0}
                    sx={{ fontWeight: 600, fontSize: 16 }}
                  >
                    limpar selecionados
                  </Button>
                </Hidden>
              </Grid>
              <Grid
                item
                sx={{
                  display: { xs: "flex", lg: "auto" },
                  position: { xs: "fixed", lg: "inherit" },
                  bottom: {
                    xs: "5%",
                    lg: "auto",
                  },
                  right: { xs: "10%", sm: "20%", lg: "auto" },
                  left: { xs: "10%", sm: "30%", lg: "auto" },
                  zIndex: 1501,
                }}
              >
                <ModalSchedule
                  variant="contained"
                  hiddenIcon={true}
                  roadsNotLoadSelected={roadsSelected}
                  isDisable={
                    !(roadsSelected && roadsSelected.length > 0) &&
                    tabSelected === 0
                  }
                >
                  Agendar selecionados
                </ModalSchedule>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} sx={{ marginBottom: { xs: 7, sm: 3, lg: 1 } }}>
            <TabPanel value={tabSelected} index={0}>
              <TableSalesOrder
                multiple
                status={STATUSROADLOAD.WAITING}
                headers={tableHeaderUnscheduled}
                rows={road?.waitingNormalized ?? []} // NOTE: Usar dados mockados na ausencia do servidor <>  notLoadsMock
                loading={loading?.waiting}
                rowsCount={roadCount.waitingRow} // NOTE: Usar dados mockados na ausencia do servidor <> notLoadsMock.length
                handleFetchRoadLoad={handleRoadLoadMethod.fetchWaiting}
              />
            </TabPanel>

            <TabPanel value={tabSelected} index={1}>
              <TableSalesOrderGrouped
                status={STATUSROADLOAD.SCHEDULED}
                columns={tableHeaderScheduled}
                rows={road?.scheduledNormalized}
                rowsCount={roadCount.scheduledRow}
                onClick={handleUpdateScheduleLoad}
                handleFetchRoadLoadGrouped={handleRoadLoadMethod.fetchScheduled}
                enableEditDriver={true}
                tableUnscheduled={{
                  status: STATUSROADLOAD.WAITING,
                  headers: tableHeaderUnscheduled,
                  rows: road?.waitingNormalized ?? [],
                  loading: loading?.waiting,
                  rowsCount: roadCount.waitingRow,
                  handleFetchRoadLoad: handleRoadLoadMethod.fetchWaiting,
                  onClickAddOrder: handleRoadLoadMethod.fetchAddOrder,
                }}
              />
            </TabPanel>
            <TabPanel value={tabSelected} index={2}>
              <TableSalesOrderGrouped
                status={STATUSROADLOAD.FINISHED}
                columns={tableHeaderFinished}
                rows={road?.finishedNormalized ?? []}
                rowsCount={roadCount.finishedRow}
                loading={loading?.finished}
                handleFetchRoadLoadGrouped={handleRoadLoadMethod.fetchFinished}
              />
            </TabPanel>
            <TabPanel value={tabSelected} index={3}>
              <TableSalesOrder
                status={STATUSROADLOAD.BLOCKED}
                headers={tableHeaderBlocked}
                rows={road?.blockedNormalized ?? []} // NOTE: Usar dados mockados na ausencia do servidor <> blockedLoadsMock
                loading={loading?.blocked}
                rowsCount={roadCount.blockedRow}
                handleFetchRoadLoad={handleRoadLoadMethod.fetchBlocked}
              />
            </TabPanel>
            <TabPanel value={tabSelected} index={4}>
              <TableSalesOrderGrouped
                hiddenPdfReport={true}
                status={STATUSROADLOAD.CANCELED}
                columns={tableHeaderCanceled}
                rows={road?.canceledNormalized}
                rowsCount={roadCount.canceledRow}
                tableUnscheduled={{
                  status: STATUSROADLOAD.FINISHED,
                  headers: tableHeaderUnscheduled,
                  rows: road?.waitingNormalized ?? [],
                  loading: loading?.waiting,
                  rowsCount: roadCount.waitingRow,
                }}
              />
            </TabPanel>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AllRoadLoad;
