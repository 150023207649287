import GenericPaginatedTable, {
  Column,
} from "@components/TableGenericPagination";
import { IEmployeeInactive } from "@pages/User/Employee/model";
import React from "react";

interface TableEmployeeInactiveProps {
  data: IEmployeeInactive[];
  rowsPerPage: number;
  onRowsPerPageChange: (e) => void;
  onClick?: (e) => void;
  labelButton?: string;
  hasLoading?: boolean;
}

const TableEmployeeInactive: React.FC<TableEmployeeInactiveProps> = ({
  ...props
}) => {
  const theadEmployeeInactive: Column<IEmployeeInactive>[] = [
    { title: "Id", field: "identifid", align: "left" },
    { title: "Nome", field: "name", align: "left" },
    { title: "Data de nascimento", field: "birthdate", align: "center" },
    { title: "Telefone", field: "telephone", align: "left" },
    { title: "E-mail", field: "email", align: "left" },
    { title: "Posto", field: "fantasy_name", align: "left" },
    { title: "Data de cadastro", field: "createdAt", align: "center" },
    // { title: "", field: "blank", align: "center" }, // Coluna Criada para inserir um botao
  ];

  const dataEmpty: IEmployeeInactive[] = [
    {
      identifid: "-",
      name: "-",
      birthdate: "-",
      telephone: "-",
      email: "-",
      fantasy_name: "-",
      createdAt: "-",
    },
  ];

  return (
    <GenericPaginatedTable
      columns={theadEmployeeInactive}
      data={props.data.length > 0 ? props.data : dataEmpty}
      rowsPerPage={props.rowsPerPage}
      onRowsPerPageChange={props.onRowsPerPageChange}
      onClick={props.onClick}
      labelButton={props.labelButton}
      hasLoading={props.hasLoading}
    />
  );
};

export default TableEmployeeInactive;
