import { Box, Theme, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";

import { getMonthAndConvertByString, numberFormatter } from "@utils/index";
import { IGetSalesLastMonthDTO } from "@pages/User/GolsAndComissions/model";

const column = ["Mês", "Meta de venda", "Quantidade de venda", "Média diária"];
interface IGetTableData {
  data: IGetSalesLastMonthDTO[];
}
const TableSalesLastMonth = (props: IGetTableData) => {
  const classes = useStyle();
  const dados = props.data;
  const lineTable = (label: string | number | undefined | Date) => {
    return (
      <Box className={classes.itemTable}>
        <Typography className={classes.value}>{label}</Typography>
      </Box>
    );
  };

  return (
    <Box display="block">
      <Box style={{ width: " 100%", display: "flex" }}>
        {column.map((value, index) => (
          <Box style={{ width: "25%" }} key={index}>
            <Typography className={classes.titleColumn}>{value}</Typography>
          </Box>
        ))}
      </Box>

      {dados.length &&
        dados.map((value, index) => (
          <>
            <Box className={classes.rowTable} key={index}>
              {lineTable(
                value.startdate
                  ? getMonthAndConvertByString(value.startdate)
                  : "-"
              )}
              {lineTable(
                value.total_lt
                  ? `${numberFormatter(value.total_lt)} litros`
                  : "-"
              )}
              {lineTable(
                value.result_total_lt
                  ? `${numberFormatter(value.result_total_lt)} litros`
                  : "-"
              )}
              {lineTable(
                value.average
                  ? Number.parseFloat(value.average).toFixed(2)
                  : "-"
              )}
            </Box>
          </>
        ))}
    </Box>
  );
};

export default TableSalesLastMonth;

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    titleColumn: {
      paddingBottom: "24px",
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "20px",
      display: "flex",
      alignItems: "center",
      color: "#3E3D3D",
    },
    itemTable: { width: "25%" },
    rowTable: { width: " 100%", display: "flex" },
    value: {
      paddingBottom: "24px",
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "20px",
      display: "flex",
      alignItems: "center",
      color: "#3E3D3D",
    },
  })
);
