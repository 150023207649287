import React from "react";
import * as yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  Button,
  Typography,
  makeStyles,
  Container,
  Drawer,
} from "@material-ui/core";
import { CloseIcon, FilterIcon } from "@components/Icons";
import { useSnackbar } from "notistack";

interface FilterListUsersProps {
  drawer: boolean;
  handle: () => void;
  throttle: (next: string) => void;
  initialPage: React.Dispatch<React.SetStateAction<number>>;
}

const FilterListUsers = ({
  drawer,
  handle,
  throttle,
  initialPage,
}: FilterListUsersProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = yup.object().shape({
    firstName: yup.string().matches(/^[A-Za-z\s]*$/, "Insira um nome válido"),
    email: yup.string().email("Insira um e-mail válido"),
  });

  const handleSubmit = (values) => {
    if (values.firstName === "" && values.email === "") {
      enqueueSnackbar("Preencha pelo menos um campo", {
        variant: "warning",
      });
    } else {
      throttle(values.email || values.firstName);
      initialPage(0);
      handle();
    }
  };

  return (
    <Drawer anchor="right" open={drawer} onClose={handle} variant="temporary">
      <Container className={classes.GeneralContainer}>
        <Container className={classes.TitleContainer}>
          <Typography style={{ fontWeight: 700 }}>Filtrar dados</Typography>
          <Button onClick={handle}>
            <CloseIcon />
          </Button>
        </Container>

        <Container className={classes.IconContainer}>
          <FilterIcon />
        </Container>

        <Container>
          <Formik
            initialValues={{ firstName: "", email: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form className={classes.Form}>
              <Field
                className={classes.Field}
                name="firstName"
                type="text"
                placeholder="Nome"
              />
              <ErrorMessage name="firstName" />

              <Field
                className={classes.Field}
                name="email"
                type="email"
                placeholder="E-mail"
              />
              <ErrorMessage name="email" />

              <Container className={classes.ButtonContainer}>
                <Button
                  className={classes.ButtonFilter}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Filtrar
                </Button>
              </Container>
            </Form>
          </Formik>
        </Container>
      </Container>
    </Drawer>
  );
};

const useStyles = makeStyles({
  GeneralContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "5rem 2rem",
    width: 400,
  },
  TitleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  IconContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "7rem",
  },
  Form: {
    display: "flex",
    flexDirection: "column",
    marginTop: "7rem",
    justifyContent: "space-between",
  },
  Field: {
    margin: "1rem 0",
    padding: "1rem",
    borderRadius: "4px",
    border: "1px solid #BABABA",
  },
  ButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15rem",
  },
  ButtonFilter: {
    textTransform: "capitalize",
    boxShadow: "none",
  },
});

export default FilterListUsers;
