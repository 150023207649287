import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IGetCustomersMarketingPlanDTO } from "../dtos/IGetCustomersMarketingPlanDTO";
import { IListCustomersMarketingPlanService } from "../models/IListCustomersMarketingPlanService";

@injectable()
export class ListCustomersMarketingPlanService
  implements IListCustomersMarketingPlanService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  execute(cnpj?: string): Promise<IGetCustomersMarketingPlanDTO[]> {
    return this.httpInstance.get<IGetCustomersMarketingPlanDTO[]>(
      `/dashboard-posh/customer?cnpj=${cnpj}&action=active-marketing-plan`
    );
  }
}
