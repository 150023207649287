import Actions from "./actions/actions";
import State from "./State";

export default function UserReducer(state: State, action: Actions): State {
  switch (action.type) {
    case "APP_SHOW_HANDOUT": {
      return { ...state, showModal: true, handoutData: action.payload };
    }
    case "APP_HIDE_HANDOUT": {
      return { ...state, showModal: false };
    }
    case "APP_SHOW_HANDOUT_LOGIN": {
      return { ...state, showLoginModal: true, handoutData: action.payload };
    }
    case "APP_HIDE_HANDOUT_LOGIN": {
      return { ...state, showLoginModal: false };
    }
    case "APP_SET_HANDOUTS_NOT_VIEWED": {
      return { ...state, handoutsNotViewed: action.payload };
    }
    case "APP_SHOW_NEXT_HANDOUT": {
      const nextHandoutIdx = state.handoutsNotViewed.findIndex(
        (ele) => ele.id === state.handoutData?.id
      );
      if (
        nextHandoutIdx < 0 ||
        nextHandoutIdx === state.handoutsNotViewed.length - 1
      ) {
        return { ...state };
      }

      const nextHandout = state.handoutsNotViewed[nextHandoutIdx + 1];

      return {
        ...state,
        handoutData: {
          content: nextHandout.content,
          createdAt: nextHandout.createdAt,
          id: nextHandout.id,
          title: nextHandout.title,
          viewed: Boolean(nextHandout.handoutAck),
        },
      };
    }
    case "APP_SHOW_PREV_HANDOUT": {
      const prevHandoutIdx = state.handoutsNotViewed.findIndex(
        (ele) => ele.id === state.handoutData?.id
      );
      if (prevHandoutIdx < 0 || prevHandoutIdx === 0) {
        return { ...state };
      }

      const prevHandout = state.handoutsNotViewed[prevHandoutIdx - 1];

      return {
        ...state,
        handoutData: {
          content: prevHandout.content,
          createdAt: prevHandout.createdAt,
          id: prevHandout.id,
          title: prevHandout.title,
          viewed: Boolean(prevHandout.handoutAck),
        },
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}
