import Button from "@components/Button";
import Modal from "@components/Modal";
import React, { useState, useEffect } from "react";
import { InputLabel, useTheme } from "@material-ui/core";

import { Box, Grid, Select, FormControl, useMediaQuery } from "@mui/material";

import {
  TableBodyTypography,
  TableHeadTypography,
  TypographyCaption,
} from "@pages/User/RoadLoad/resource/typography";
import { ArrowDownSelectIcon, ScheduleIcon } from "@components/Icons";
import { headers } from "./headers";
import { useRoadLoad } from "@pages/User/RoadLoad/context/RoadLoadContext";
import Schedule from "@pages/User/RoadLoad/resource/schedule";
import SelectAutocomplete, { Option } from "@components/SelectAutocomplete";
import CustomTimeInputSchedule from "../schedule/CustomTimeInputSchedule";
import { IDrivers } from "@modules/roadLoad/model";
import {
  arrayToStringJoin,
  capitalizeFirstLetterFullString,
  compareDatesIgnoringTime,
  compareTimesIgnoringDate,
  formatDate,
  formatHoursGMT,
  formatToAmountLiters,
} from "@utils/index";
import { KEY } from "@utils/enum";
import CircularProgress from "@components/CircularProgress";
import useDialogAlert from "@hooks/useDialogAlert";

interface Props {
  children?: React.ReactNode;
  roadNotLoad?;
  roadsNotLoadSelected?;
  roadLoadScheduled?;
  isDisable?: boolean;
  hiddenIcon?: boolean;
  variant?: "text" | "contained";
}

const ModalSchedule: React.FC<Props> = ({
  children,
  roadNotLoad,
  roadsNotLoadSelected,
  roadLoadScheduled,
  isDisable,
  hiddenIcon,
  variant,
}) => {
  const { snackbar } = useDialogAlert();

  const {
    onlyDriver,
    onlyDriverUserID,
    roadLoadFilterOptions,
    handleRoadLoadMethod,
    roadLoadVacancies,
    loading,
    firstDateTimeAvailable,
    availableDate,
    handleFetchDriverByCNPJ,
  } = useRoadLoad();

  const [modalOpen, setModalOpen] = useState(false);
  const [loadSubmitSchedule, setLoadSubmitSchedule] = useState<boolean>(false);
  const [checkTimeHandle, setCheckTimeHandle] = useState<boolean>(true);

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    firstDateTimeAvailable ?? null
  );

  const [selectedDrivers, setSelectedDrivers] = useState<
    Option[] | undefined | Option | null
  >(undefined);

  const handleOpen = () => {
    if (roadNotLoad || roadsNotLoadSelected || roadLoadScheduled) {
      handleFetchDriverByCNPJ(
        roadNotLoad?.cnpj ??
          arrayToStringJoin(roadsNotLoadSelected, KEY.CNPJ) ??
          arrayToStringJoin(roadLoadScheduled?.salesOrder, KEY.CNPJ)
      );
    }

    if (
      roadNotLoad?.order ||
      roadsNotLoadSelected ||
      roadLoadScheduled?.salesOrder
    ) {
      try {
        setLoadSubmitSchedule(true);
        handleRoadLoadMethod.fetchAvailableDate(
          roadNotLoad?.order ??
            arrayToStringJoin(roadsNotLoadSelected, KEY.ORDER) ??
            arrayToStringJoin(roadLoadScheduled?.salesOrder, KEY.ORDER),
          roadNotLoad?.scheduleID ??
            arrayToStringJoin(roadsNotLoadSelected, KEY.SCHEDULEID) ??
            roadLoadScheduled?.scheduleGroupID
        );
      } catch (error) {
        throw error;
      } finally {
        setLoadSubmitSchedule(false);
      }
    }

    setModalOpen(true);
  };

  const handleClose = () => {
    setSelectedDate(null);
    setSelectedDrivers(undefined);
    handleRoadLoadMethod.resetVacancies();
    handleRoadLoadMethod.resetFirstDateTimeAvailable();
    setModalOpen(false);
  };

  const handleOnChangeScheduleVacancies = async (
    dateSelected: Date | null,
    event,
    updateAfterReload?: boolean
  ) => {
    setSelectedDate(dateSelected);

    if (!dateSelected) {
      setSelectedDrivers(undefined);
      return;
    }

    if (compareDatesIgnoringTime(selectedDate, dateSelected)) {
      handleRoadLoadMethod.fetchVacancies(
        roadNotLoad?.order ??
          arrayToStringJoin(roadsNotLoadSelected, KEY.ORDER) ??
          arrayToStringJoin(roadLoadScheduled?.salesOrder, KEY.ORDER),
        formatDate(dateSelected)
      );
      setCheckTimeHandle(false);
    }

    if (!compareDatesIgnoringTime(selectedDate, dateSelected)) {
      if (
        compareTimesIgnoringDate(selectedDate, dateSelected) ||
        updateAfterReload
      ) {
        const order =
          roadNotLoad?.order ??
          arrayToStringJoin(roadsNotLoadSelected, KEY.ORDER) ??
          arrayToStringJoin(roadLoadScheduled?.salesOrder, KEY.ORDER);
        const dateFormatted = formatDate(dateSelected);
        const hourFormatted = formatHoursGMT(dateSelected);
        const checkVacancies = await handleRoadLoadMethod.fetchVacanciesReserve(
          order,
          dateFormatted,
          hourFormatted,
          roadNotLoad?.scheduleID ?? roadLoadScheduled?.scheduleGroupID
        );
        if (checkVacancies) {
          setCheckTimeHandle(true);
        }
      }
    }
  };

  const handleOnClickSchedule = async (): Promise<void> => {
    if (!selectedDate) {
      return snackbar({
        message: `Voce precisa selecionar uma data para agendar o carregamento rodoviario`,
        variant: "info",
      });
    }

    if (!checkTimeHandle) {
      return snackbar({
        message: `Voce precisa selecionar um horario valido e disponivel para concluir seu agendamento`,
        variant: "warning",
      });
    }

    if (!onlyDriver && !selectedDrivers) {
      return snackbar({
        message: `Voce precisa selecionar um Motorista para concluir seu agendamento`,
        variant: "warning",
      });
    }

    if (selectedDate) {
      const payload = {
        orderId:
          roadNotLoad?.order?.toString() ??
          arrayToStringJoin(roadsNotLoadSelected, KEY.ORDER) ??
          arrayToStringJoin(roadLoadScheduled?.salesOrder, KEY.ORDER) ??
          "",
        date: formatDate(selectedDate),
        time: formatHoursGMT(selectedDate),
        driverId: onlyDriver
          ? onlyDriverUserID
          : (selectedDrivers as IDrivers)?.id,
      };

      try {
        setLoadSubmitSchedule(true);

        await handleRoadLoadMethod.fetchCreateSchedule(
          payload,
          roadNotLoad?.scheduleID ?? roadLoadScheduled?.scheduleGroupID
        );

        handleClose();
      } catch (error) {
        throw error;
      } finally {
        setLoadSubmitSchedule(false);
      }
    }
  };

  useEffect(() => {
    if (!modalOpen) return;

    const checkDriver = (): Option | undefined => {
      if (roadNotLoad?.truckDriver || roadLoadScheduled) {
        const optionDriver = roadLoadFilterOptions?.drivers?.find(
          (driver) =>
            driver?.name === roadNotLoad?.truckDriver ||
            driver?.name === roadLoadScheduled?.truckDriver ||
            driver?.id === roadLoadScheduled?.truckDriverId ||
            driver?.id === roadNotLoad?.truckDriverId
        );

        if (optionDriver) {
          return {
            id: optionDriver.id,
            name: optionDriver.name,
          };
        }
      }

      return undefined;
    };

    if (firstDateTimeAvailable) {
      setSelectedDate(firstDateTimeAvailable);
    }

    if (roadNotLoad?.truckDriver || roadLoadScheduled?.truckDriver) {
      const newSelectedDriver = checkDriver();

      setSelectedDrivers(newSelectedDriver);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modalOpen,
    firstDateTimeAvailable,
    roadNotLoad?.truckDriver,
    roadLoadScheduled?.truckDriver,
  ]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const disabledDoubleCheck =
    (!roadNotLoad && !roadsNotLoadSelected && !roadLoadScheduled) ||
    !roadNotLoad?.schedulable;
  const disableScheduleButton =
    isDisable !== undefined ? isDisable : disabledDoubleCheck;

  return (
    <React.Fragment>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        ModalButton={
          <Button
            variant={variant ?? "text"}
            onClick={handleOpen}
            fullWidth={isMobile}
            disabled={disableScheduleButton}
            endIcon={
              !hiddenIcon && (
                <ScheduleIcon
                  sx={{
                    width: { xs: "18px !important", lg: "22px !important" },
                    opacity: disableScheduleButton ? 0.3 : 1,
                  }}
                />
              )
            }
          >
            {children}
          </Button>
        }
      >
        <Box
          className={`ModalSchedule-modal-box`}
          sx={{
            border: "0px solid blue",
            width: { lg: "650px" },
            p: { md: 0 },
            display: "contents",
          }}
        >
          <Grid
            container
            className={"main-modal-container"}
            sx={{
              border: "0px solid red",
              p: { xs: 0.6, md: 1, lg: 2 },
              pb: { xs: 1, lg: 1 },
              alignItems: "baseline",
              display: "block",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Grid
              item
              xs={12}
              className={"header-modal-item"}
              sx={{
                border: "0px solid blue",
                p: 0,
                pt: { xs: 0, lg: 1 },
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Grid
                container
                className={"header-modal-container"}
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {roadsNotLoadSelected !== undefined &&
                roadsNotLoadSelected?.length > 0 ? (
                  <Grid item sx={{ border: "0px solid blue" }}>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel
                        id="demo-simple-select-standard-label"
                        style={{ paddingRight: 25 }}
                      >
                        Foram selecionados <b>{roadsNotLoadSelected?.length}</b>{" "}
                        carregamentos
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        className={"select-custom"}
                        IconComponent={ArrowDownSelectIcon}
                        sx={{
                          height: "28px",
                          marginTop: "-22px !important",
                        }}
                        MenuProps={{
                          sx: {
                            "& .MuiMenu-paper": {},
                          },
                        }}
                      >
                        {roadsNotLoadSelected?.map((road, index) => {
                          return (
                            <Grid
                              container
                              key={index}
                              sx={{
                                borderBottom:
                                  index !== roadsNotLoadSelected?.length - 1
                                    ? "1px solid rgba(0,0,0,0.2)"
                                    : "",
                                padding: 3,
                              }}
                            >
                              <Grid item xs={12}>
                                {headers.map((head, index) => {
                                  return (
                                    <Grid
                                      key={index}
                                      container
                                      sx={{
                                        display: "inline-block",
                                        width: head.width,
                                        mx: 1,
                                      }}
                                    >
                                      <Grid item>
                                        {" "}
                                        <TableHeadTypography>
                                          {head.label}
                                        </TableHeadTypography>{" "}
                                      </Grid>
                                      <Grid item>
                                        <TableBodyTypography
                                          sx={{
                                            textWrap: "nowrap",
                                            overflowX: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {formatEntry(
                                            head.key,
                                            road[head.key]
                                          )}
                                        </TableBodyTypography>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid
                    item
                    sx={{
                      border: "0px solid black",
                      zIndex: 2,
                      opacity: 0.5,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: { xs: "220px", md: "auto", lg: "auto" },
                      pl: 1.4,
                    }}
                  >
                    <TypographyCaption
                      sx={{
                        fontWeight: 400,
                        fontSize: { xs: 11, lg: 12, xl: 13 },
                      }}
                    >
                      {roadNotLoad?.customer && "Cliente"}
                      {roadLoadScheduled?.salesOrder && "Num. Agendamento"}
                    </TypographyCaption>
                    <TypographyCaption
                      sx={{
                        fontWeight: 600,
                        fontSize: { xs: 10, lg: 12, xl: 13 },
                      }}
                    >
                      {" "}
                      {roadNotLoad?.customer ?? roadLoadScheduled?.Ref}{" "}
                    </TypographyCaption>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              className={"body-modal"}
              sx={{
                border: "0px solid black",
                p: { xs: 0, lg: 1 },
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: loading?.availableDate ? "baseline" : "end",
              }}
            >
              {loading?.availableDate ? (
                <div>
                  <CircularProgress /> Por favor aguarde, os horarios estao
                  sendo carregados
                </div>
              ) : (
                <Grid
                  container
                  justifyContent={"left"}
                  sx={{
                    border: "0px solid red",
                    p: { lg: 1 },
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Grid item sx={{ border: "0px solid blue" }}>
                    <Schedule
                      hidden={true}
                      hiddenIcon={true}
                      open={modalOpen}
                      selected={selectedDate}
                      onClickCancel={handleClose}
                      onClickSchedule={handleOnClickSchedule}
                      onChange={handleOnChangeScheduleVacancies}
                      allowDates={availableDate}
                      allowTimes={roadLoadVacancies} //  roadLoadVacancies <> availableTime
                      loading={loading?.vacancies}
                    />
                  </Grid>

                  {!onlyDriver && (
                    <Grid
                      item
                      className="select-motorista"
                      style={{ maxWidth: "auto", display: "block" }}
                    >
                      <SelectAutocomplete
                        label="Motorista"
                        value={selectedDrivers}
                        options={
                          roadLoadFilterOptions?.drivers?.map((item) => ({
                            id: item.id,
                            name: item.name,
                          })) ?? []
                        }
                        onChange={(value) => {
                          setSelectedDrivers(value);
                        }}
                        getOptionLabel={(option) => option.name}
                      />
                    </Grid>
                  )}

                  <Grid
                    item
                    className="item-CustomTimeInputSchedule"
                    sx={{ border: "0px solid black" }}
                  >
                    <CustomTimeInputSchedule
                      onClickCalendarClose={handleClose}
                      onClickCalendarSend={handleOnClickSchedule}
                      onChangeResetButton={() =>
                        handleOnChangeScheduleVacancies(null, undefined)
                      }
                      selected={selectedDate}
                      loading={loadSubmitSchedule}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default ModalSchedule;

const formatEntry = (key: string, value: string) => {
  switch (key) {
    case KEY.QUANTITY:
      return formatToAmountLiters(value);

    default:
      return capitalizeFirstLetterFullString(value);
  }
};
