import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Checkbox,
  CircularProgress,
  Collapse,
  createStyles,
  Fade,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { IGetPriceProductsService } from "@modules/orders/models/IGetPriceProductsService";
import { formatCurrency, formatCurrencyPriceProduct } from "@utils/index";
import { IFormRequestValues, IProductQuantity, Price } from "@utils/interfaces";
import { getIn, useFormikContext } from "formik";
import { useSnackbar } from "notistack";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useForm } from "../FormContext";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      toolbarOpen: {
        backgroundColor: theme.palette.secondary.main,
        "& p": {
          color: "white",
        },
      },
      footerTextTable: {
        fontWeight: "bold",

        display: "flex",
        justifyContent: "space-between",

        "& span": {
          display: "block",
          color: theme.palette.primary.main,
        },
      },
      itemSelect: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    })
);

interface IProductSelecteToExclude {
  [propName: string]: boolean;
}
interface TableProductsProps {}

interface TableProductRowProps {
  product: IProductQuantity;
  setProductSelectedToExclude: Dispatch<
    SetStateAction<IProductSelecteToExclude>
  >;
  productSelectedToExclude: IProductSelecteToExclude;
}

const TableProductRow: React.FC<TableProductRowProps> = ({
  product,
  productSelectedToExclude,
  setProductSelectedToExclude,
}) => {
  const classes = useStyles();
  const {
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
  } = useFormikContext<IFormRequestValues>();
  const formContext = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const iocContext = useIoCContext();

  const getPriceProductService = iocContext.serviceContainer.get<
    IGetPriceProductsService
  >(Types.Orders.IGetPriceProductsService);

  const [loadingPrice, setLoadingPrice] = useState(false);
  const [price, setPrice] = useState<Price | null>();
  const priceCalculated = useMemo(() => {
    const totalPrice =
      price && product.quantity
        ? values.freightType === "CIF"
          ? (price.price + price.freight) * product.quantity // CIF - frete
          : price.price * product.quantity // FOB - frete
        : 0;

    const products = values.products.map((productID) => {
      if (productID.hashPrice === product.hashPrice) {
        productID.price =
          price && product.quantity
            ? values.freightType === "CIF"
              ? price.price + price.freight // CIF - frete
              : price.price // FOB - frete
            : 0;
      }
      return productID;
    });
    setFieldValue("products", products);
    return totalPrice;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price, product.quantity, values.freightType]);

  const getError = (key: string) => {
    const idx = values.products.findIndex(
      (productID) => productID.hashPrice === product.hashPrice
    );
    const keyPath = `products[${idx}].${key}`;
    const error = getIn(errors, keyPath);
    const touch = getIn(touched, keyPath);
    return touch && error ? error : null;
  };
  const renderDeadlinePayment = (deadline: number) => {
    if (deadline === 0) {
      return (
        <MenuItem value={0} className={classes.itemSelect}>
          à vista
        </MenuItem>
      );
    }
    const values = Array.from(Array(deadline + 1).keys());

    return values.map((ele) => {
      return ele !== 0 ? (
        <MenuItem
          key={`${product.hashPrice}${ele}`}
          value={ele}
          className={classes.itemSelect}
        >{`${ele} ${ele !== 1 ? "dias" : "dia"}`}</MenuItem>
      ) : (
        <MenuItem
          key={`${product.hashPrice}${ele}`}
          value={ele}
          className={classes.itemSelect}
        >
          à vista
        </MenuItem>
      );
    });
  };
  const setQuantityProduct = (value: number) => {
    const products = values.products.map((productID) => {
      if (product.hashPrice === productID.hashPrice) {
        product.quantity = value;
      }
      return productID;
    });

    setFieldValue("products", products);
  };

  const generateBasePayload = (): number[] => {
    const basePayload: number[] = [];
    for (let i = 1; i <= 12; i++) {
      basePayload.push(i * 5000);
    }
    basePayload.push(17000);
    basePayload.push(36000);
    basePayload.push(38500);
    basePayload.push(44000);
    basePayload.push(46000);
    basePayload.push(52000);
    basePayload.push(62000);

    return Array.from(new Set(basePayload)).sort((a, b) => a - b);
  };

  const generateFobSpecificPayload = () => {
    const fobMultipliers = [
      2,
      3,
      4,
      6,
      7,
      8,
      9,
      11,
      12,
      13,
      14,
      22,
      23,
      24,
      36,
    ];
    const fobSpecificPayload = fobMultipliers.map((x) => x * 1000);
    return fobSpecificPayload;
  };

  const renderQtds = () => {
    let basePayload: number[] = generateBasePayload();
    const fobSpecificPayload = generateFobSpecificPayload();

    if (values.freightType === "FOB") {
      basePayload = Array.from(
        new Set([...basePayload, ...fobSpecificPayload])
      );
    }

    basePayload.sort((a, b) => a - b);
    const hasQtdFOB = values.products.find((product) => {
      return basePayload.find((value) => value * 1000 === product.quantity);
    });
    if (values.freightType === "CIF" && hasQtdFOB) {
      const products = values.products.map((productID) => {
        return {
          ...productID,
          quantity: null,
        };
      });
      setFieldValue("products", products);
    }

    const qtds = basePayload.map((ele) => {
      return (
        <MenuItem
          key={`${product.hashPrice}${ele}`}
          value={ele}
          className={classes.itemSelect}
        >
          {ele.toLocaleString("pt-BR")}
        </MenuItem>
      );
    });

    return qtds;
  };

  const setDeadlinePaymentProduct = (value: number) => {
    const products = values.products.map((productID) => {
      if (product.hashPrice === productID.hashPrice) {
        product.deadlinePayment = value;
      }
      return productID;
    });

    setFieldValue("products", products);
  };
  const getPaymentType = (ele: IProductQuantity) => {
    if (ele.deadlinePayment === null) {
      return "";
    }
    if (ele.deadlinePayment > 0) {
      return "Boleto";
    } else {
      return "Transferência";
    }
  };

  const getPrice = useCallback(async () => {
    try {
      if (!values.address) return;
      if (product.deadlinePayment === null) return;
      setLoadingPrice(true);
      const priceProduct = await getPriceProductService.execute({
        addressID: values.address.id,
        deadlinePayment: product.deadlinePayment,
        productID: product.id,
        customerID: formContext.dataCustomer?.id as string,
        filialID: values.filialID,
        transportationZone: values.address.transportationZone,
      });
      setPrice(priceProduct);
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro ao buscar preço do produto", {
        variant: "error",
      });
    } finally {
      setLoadingPrice(false);
    }
  }, [
    enqueueSnackbar,
    formContext.dataCustomer,
    getPriceProductService,
    product.deadlinePayment,
    product.id,
    values.address,
    values.filialID,
  ]);

  useEffect(() => {
    getPrice();
  }, [getPrice]);

  return (
    <Fade in={Boolean(values.products.length)} timeout={1000}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            onClick={() =>
              setProductSelectedToExclude({
                ...productSelectedToExclude,
                [product.hashPrice]: !productSelectedToExclude[
                  product.hashPrice
                ],
              })
            }
            checked={productSelectedToExclude[product.hashPrice] ? true : false}
          />
        </TableCell>
        <TableCell>{product.id}</TableCell>
        <TableCell>{product.description}</TableCell>
        <TableCell>
          {loadingPrice ? (
            <CircularProgress style={{ height: 20, width: 20 }} />
          ) : (
            formatCurrencyPriceProduct(
              price
                ? values.freightType === "CIF"
                  ? price.price + price.freight // CIF - frete
                  : price.price // FOB - frete
                : 0
            )
          )}
        </TableCell>
        <TableCell>
          <FormControl fullWidth>
            <Select
              error={!!getError("quantity")}
              onBlur={() => setFieldTouched("quantity", true)}
              onChange={({ target }) =>
                setQuantityProduct(target.value as number)
              }
              MenuProps={{
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                getContentAnchorEl: null,
              }}
              variant="outlined"
              value={product.quantity ? product.quantity : ""}
            >
              {renderQtds()}
            </Select>
            <FormHelperText error={!!getError("quantity")}>
              {getError("quantity")}
            </FormHelperText>
          </FormControl>
        </TableCell>
        <TableCell>
          <Typography>{getPaymentType(product)}</Typography>
        </TableCell>
        <TableCell>
          <FormControl fullWidth>
            <Select
              error={!!getError("deadlinePayment")}
              onBlur={() => setFieldTouched("deadlinePayment", true)}
              onChange={async ({ target }) =>
                setDeadlinePaymentProduct(target.value as number)
              }
              MenuProps={{
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                getContentAnchorEl: null,
              }}
              value={product.deadlinePayment}
            >
              {renderDeadlinePayment(product.payCond)}
            </Select>
            <FormHelperText error={!!getError("deadlinePayment")}>
              {getError("deadlinePayment")}
            </FormHelperText>
          </FormControl>
        </TableCell>
        <TableCell>{formatCurrency(priceCalculated)}</TableCell>
      </TableRow>
    </Fade>
  );
};

const TableProducts: React.FC<TableProductsProps> = () => {
  const classes = useStyles();
  const [productSelectedToExclude, setProductSelectedToExclude] = useState<
    IProductSelecteToExclude
  >({});
  const { values, setFieldValue } = useFormikContext<IFormRequestValues>();

  const removeProducts = () => {
    const exclude = Object.keys(productSelectedToExclude);

    let products = values.products.filter((ele) => {
      const findOk = exclude.find((key) => ele.hashPrice === key);

      return findOk ? false : true;
    });

    setProductSelectedToExclude({});
    setFieldValue("products", products);
  };
  const isCheckedAll = () => {
    const numberOfProductsSelectedToExclude = Object.keys(
      productSelectedToExclude
    ).reduce((previusValue, nextValue) => {
      if (productSelectedToExclude[nextValue]) {
        previusValue += 1;
        return previusValue;
      } else {
        return previusValue;
      }
    }, 0);
    if (values.products.length === 0) return false;
    return values.products.length === numberOfProductsSelectedToExclude;
  };
  const isSomeSelected = () => {
    const numberOfProductsSelectedToExclude = Object.keys(
      productSelectedToExclude
    ).reduce((previusValue, nextValue) => {
      if (productSelectedToExclude[nextValue]) {
        previusValue += 1;
        return previusValue;
      } else {
        return previusValue;
      }
    }, 0);
    return (
      !!numberOfProductsSelectedToExclude &&
      values.products.length > numberOfProductsSelectedToExclude
    );
  };
  const selectAll = () => {
    const products = values.products.map((ele) => ele.hashPrice);
    let excludeAll = { ...productSelectedToExclude };
    for (let i = 0; i < products.length; i++) {
      if (isCheckedAll()) {
        excludeAll = {
          ...excludeAll,
          [products[i]]: false,
        };
      } else {
        excludeAll = {
          ...excludeAll,
          [products[i]]: true,
        };
      }
    }
    setProductSelectedToExclude(excludeAll);
  };

  useEffect(() => {
    setProductSelectedToExclude({});
    setFieldValue("products", []);
  }, [values.CNPJ, setFieldValue]);

  return (
    <Paper>
      <Collapse
        in={
          !!Object.keys(productSelectedToExclude).find((ele) =>
            productSelectedToExclude[ele] === true ? true : false
          )
        }
      >
        <Toolbar
          style={{ display: "flex", justifyContent: "space-around" }}
          className={classes.toolbarOpen}
        >
          <div>
            <Typography>
              {Object.keys(productSelectedToExclude).reduce(
                (previusValue, nextValue) => {
                  if (productSelectedToExclude[nextValue]) {
                    previusValue += 1;
                    return previusValue;
                  } else {
                    return previusValue;
                  }
                },
                0
              )}
              Selecionados
            </Typography>
          </div>
          <div>
            <Tooltip title="Remover Selecionados" arrow placement="top">
              <IconButton aria-label="delete" onClick={() => removeProducts()}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </Collapse>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                onChange={() => selectAll()}
                checked={isCheckedAll()}
                indeterminate={isSomeSelected()}
              />
            </TableCell>
            <TableCell>Código</TableCell>
            <TableCell>Descrição</TableCell>
            <TableCell>Preço</TableCell>
            <TableCell>Quantidade(L)</TableCell>
            <TableCell>Forma de Pagamento</TableCell>
            <TableCell>Prazo</TableCell>
            <TableCell>Subtotal</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.products.map((product) => {
            return (
              <TableProductRow
                key={product.hashPrice}
                product={product}
                productSelectedToExclude={productSelectedToExclude}
                setProductSelectedToExclude={setProductSelectedToExclude}
              />
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={8}>
              <Fade in={Boolean(values.products.length)} timeout={1000}>
                <Grid container justify="space-around">
                  <Grid item>
                    <Typography className={classes.footerTextTable}>
                      Quantidade total do pedido(L):&nbsp;
                      <span>
                        {values.products
                          .reduce(
                            (previousValue, nextValue) =>
                              nextValue.quantity
                                ? previousValue + nextValue.quantity
                                : previousValue,
                            0
                          )
                          .toLocaleString("pt-BR")}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.footerTextTable}>
                      Preço total do pedido:&nbsp;
                      <span>
                        {formatCurrency(
                          values.products.reduce(
                            (previousValue, nextValue) =>
                              nextValue.quantity && nextValue.price
                                ? previousValue +
                                  nextValue.quantity * nextValue.price
                                : previousValue,
                            0
                          )
                        )}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Fade>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
};

export { TableProducts };
