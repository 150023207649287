import { CloseIcon } from "@components/Icons";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { IQueryTitlesDataDTO } from "@modules/titles/dtos/IQueryTitlesDataDTO";
import { formatCurrency, maskCNPJ, nameSituationTitle } from "@utils/index";
import { format } from "date-fns";
import React from "react";

interface Props {
  open: boolean;
  handleOnClose: () => void;
  titlesData: IQueryTitlesDataDTO;
}
const TableTitleModal: React.FC<Props> = ({ ...props }) => {
  const classes = useStyles();
  const [, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    props.handleOnClose();
  };

  const ModalRow: React.FC<{ title: string; subtitle: string }> = ({
    ...props
  }) => {
    return (
      <Grid item xs={12} className={classes.bodyGridItem}>
        <Box>
          <Typography className={classes.typographyHead}>
            {props.title}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.typographyBody}>
            {props.subtitle}
          </Typography>
        </Box>
      </Grid>
    );
  };

  return (
    <>
      <Modal
        className={classes.modal}
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Grid container spacing={4} className={classes.container}>
            <Grid
              item
              xs={12}
              sm={11}
              md={11}
              lg={11}
              xl={11}
              style={{ paddingTop: 0, paddingBottom: 24 }}
            >
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  border: "0px solid blue",
                }}
              >
                <Button
                  onClick={props.handleOnClose}
                  style={{
                    justifyContent: "end",
                    width: "fit-content",
                    border: "0px solid red",
                    paddingTop: 14,
                  }}
                >
                  <CloseIcon className={classes.closeIcon} />
                </Button>
              </Grid>

              <Box style={{ textAlign: "center" }}>
                <Typography className={classes.typographyTitle}>
                  Título
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={11} md={11} lg={11} xl={11}>
              <Grid container spacing={2}>
                <ModalRow
                  title="Título"
                  subtitle={props.titlesData?.id ? props.titlesData?.id : "-"}
                />
                <ModalRow
                  title="Parcela"
                  subtitle={
                    props.titlesData?.Buzei ? props.titlesData?.Buzei : "-"
                  }
                />
                <ModalRow
                  title="Emissão"
                  subtitle={
                    props.titlesData?.emissionDate
                      ? format(props.titlesData.emissionDate, "dd/MM/yyyy")
                      : "-"
                  }
                />
                <ModalRow
                  title="Vencimento"
                  subtitle={
                    props.titlesData?.dueDate
                      ? format(props.titlesData.dueDate, "dd/MM/yyyy")
                      : "-"
                  }
                />
                <ModalRow
                  title="Valor"
                  subtitle={
                    props.titlesData?.value
                      ? formatCurrency(props.titlesData.value)
                      : "-"
                  }
                />
                <ModalRow
                  title="Situação"
                  subtitle={
                    props.titlesData?.situation
                      ? nameSituationTitle(props.titlesData.situation)
                      : "-"
                  }
                />
                <ModalRow
                  title="Referência"
                  subtitle={
                    props.titlesData?.reference
                      ? props.titlesData.reference
                      : "-"
                  }
                />
                <ModalRow
                  title="CNPJ"
                  subtitle={maskCNPJ(props.titlesData.CNPJ) ?? ""}
                />
                <ModalRow
                  title="Razão social"
                  subtitle={
                    props.titlesData?.companyName
                      ? props.titlesData.companyName
                      : "-"
                  }
                />
                <Grid item xs={12} className={classes.bodyGridItemButton}>
                  <Button
                    variant="contained"
                    className={classes.button}
                    style={{ boxShadow: "none" }}
                    onClick={props.handleOnClose}
                  >
                    OK
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </>
  );
};

export default TableTitleModal;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    color: theme.palette.text.primary,
    background: "#fff",
    border: "2px solid #fff",
    borderRadius: 16,
    justifyContent: "center",
    alignContent: "baseline",
    [theme.breakpoints.up("xs")]: {
      width: "90%",
      padding: "8px 0px 8px 0px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80%",
      padding: "16px 0px 16px 0px",
    },
    [theme.breakpoints.up("md")]: {
      width: "80%",
      padding: "16px 0px 16px 0px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "70%",
      padding: "24px 0px 24px 0px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "60%",
      padding: "32px 0px 32px 0px",
    },
  },
  typographyTitle: {
    marginBottom: "0.3rem",
    fontWeight: 700,
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.9rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "2.6rem",
    },
  },
  bodyGridItem: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("xs")]: {
      padding: "7px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "5px !important",
    },
    [theme.breakpoints.up("md")]: {
      padding: "6px !important",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "9px !important",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "10px !important",
    },
  },
  typographyHead: {
    fontWeight: 700,
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.6rem",
    },
  },
  typographyBody: {
    fontWeight: 500,
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.6rem",
    },
  },
  bodyGridItemButton: {
    padding: "1rem",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primaryLight.main,
    fontWeight: 700,
    padding: "0.6rem 3rem 0.6rem 3rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
    },
  },
  closeIcon: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "1rem !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.3rem !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.7rem !important",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.7rem !important",
    },
  },
}));
