import React from "react";

import { Typography, useMediaQuery } from "@material-ui/core";

import ButtonLinkGeneric from "@components/ButtonLinkGeneric/ButtonLinkGeneric";
import { CardSales } from "@components/CardSales";
import {
  MoneyIcon,
  ProjectionIcon,
  SaleIcon,
  SaleVolumeIcon,
  TimeIcon,
} from "@components/Icons";
import ROUTES from "@config/routes";
import { usePerformanceManagement } from "@context/PerformanceManagementContext";
import { useTheme } from "@material-ui/core/styles";
import { Grid, Skeleton } from "@mui/material";
import { numberFormatter } from "@utils/index";
import { useStylesPerformanceManagement } from "..";

const YourSalesDash: React.FC = () => {
  const classes = useStylesPerformanceManagement();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const {
    yourSales,
    indicators,
    isLoadingYourSales,
    isLoadingIndicator,
  } = usePerformanceManagement();

  let skeleton = <Skeleton width={120} animation="wave" />;
  let volume = numberFormatter(yourSales[0]?.totalVolume);
  let invoicing = numberFormatter(yourSales[0]?.totalBilling);
  let time = indicators?.peakTime?.hour || "-- : --";
  let sales = numberFormatter(indicators?.dailyAverage ?? 0);
  let projection = numberFormatter(indicators?.salesProjection ?? 0);

  const dataCardSales = [
    {
      icon: <SaleVolumeIcon />,
      title: isLoadingYourSales ? skeleton : `${volume} litros`,
      subtitle: "Volume de vendas do mês",
    },
    {
      icon: <MoneyIcon />,
      title: isLoadingYourSales ? skeleton : `R$ ${invoicing}`,
      subtitle: "Faturamento do mês",
    },
    {
      icon: <TimeIcon />,
      title: isLoadingIndicator ? skeleton : `${time}`,
      subtitle: "Horário de pico",
    },
    {
      icon: <SaleIcon />,
      title: isLoadingIndicator ? skeleton : `${sales} litros`,
      subtitle: "Venda média diária",
    },
    {
      icon: <ProjectionIcon />,
      title: isLoadingIndicator ? skeleton : `${projection} litros`,
      subtitle: "Projeção de venda",
    },
  ];

  return (
    <>
      <Typography
        className={classes.titleSection}
        variant={isMobile ? "subtitle1" : "h5"}
      >
        Suas vendas
      </Typography>
      <Grid className={isMobile ? classes.block : classes.flex}>
        {dataCardSales.map((value, index) => (
          <Grid container key={index}>
            <Grid item>
              <CardSales
                key={index}
                icon={value.icon}
                title={value.title}
                subtitle={value.subtitle}
              />
              {value.subtitle === "Horário de pico" && (
                <div style={{ paddingLeft: "50px" }}>
                  <ButtonLinkGeneric
                    text="Consultar histórico"
                    link={ROUTES.USER_ROUTES.PEAK_TIME_HISTORY}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default YourSalesDash;
