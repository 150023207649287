import { ReactNode } from "react";
import iconPostFull from "../../../../assets/driverVsCustomer/posto_full.svg";

import React from "react";

export interface ITableDriverCustomer {
  option: string | ReactNode;
  align: "left" | "right" | "inherit" | "center" | "justify" | undefined;
  id: string;
  isVisibleMobile: boolean;
}

export const HeaderTableDriveCustomer: ITableDriverCustomer[] = [
  {
    option: <img src={iconPostFull} width="36px" alt="ícone de caminhão" />,
    align: "left",
    id: "idItem",
    isVisibleMobile: false,
  },
  { option: "BP", align: "left", id: "bp", isVisibleMobile: true },
  {
    option: "CNPJ",
    align: "left",
    id: "cnpj",
    isVisibleMobile: true,
  },

  {
    option: "Razão social",
    align: "left",
    id: "companyName",
    isVisibleMobile: false,
  },
  {
    option: "Segmento",
    align: "left",
    id: "segmento",
    isVisibleMobile: false,
  },
  { option: "Cidade-UF", align: "left", id: "city", isVisibleMobile: false },
  {
    option: "Motoristas",
    align: "left",
    id: "qtdMotoristas",
    isVisibleMobile: false,
  },
  {
    option: " ",
    align: "right",
    id: "acao",
    isVisibleMobile: false,
  },
  {
    option: " ",
    align: "right",
    id: "dots",
    isVisibleMobile: false,
  },
];
