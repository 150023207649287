export const UserTypes = {
  IListUsersService: Symbol("IListUsersService"),
  ICreateUserService: Symbol("ICreateUserService"),
  IBlockUserService: Symbol("IBlockUserService"),
  IUnlockUserService: Symbol("IUnlockUserService"),
  IUpdateUSerService: Symbol("IUpdateUSerService"),
  ISendEmailToNotVerifiedEmailUsers: Symbol(
    "ISendEmailToNotVerifiedEmailUsers"
  ),
  IChangePasswordService: Symbol("IChangePasswordService"),
  IListCustomersMarketingPlanService: Symbol(
    "IListCustomersMarketingPlanService"
  ),
  IGetTotalTaxReceiptWithActionsService: Symbol(
    "IGetTotalTaxReceiptWithActionsService"
  ),
  IGetHistoryQtUserRegisterService: Symbol("IGetHistoryQtUserRegisterService"),
  IGetHistoryTotalQtVolLTService: Symbol("IGetHistoryTotalQtVolLTService"),
  IGetHistoryTotalQtVolLTPerProductService: Symbol(
    "IGetHistoryTotalQtVolLTPerProductService"
  ),
  IGetDetailsInstantRewardPoshService: Symbol(
    "IGetDetailsInstantRewardPoshService"
  ),
  IGetTotalQTVolLTService: Symbol("IGetTotalQTVolLTService"),
  IGetTotalAverageTicketService: Symbol("IGetTotalAverageTicketService"),
  IAuthService: Symbol("IAuthService"),
};
