import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import { IUpdateUserDTO } from "@modules/user/dtos/IUpdateUserDTO";
import { IUpdateUserService } from "@modules/user/models/IUpdateUserService";
import AppError from "@utils/AppError";
import { IUser } from "@utils/interfaces";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ROUTES from "../../../../config/routes";
import FormAddUser from "./Form";
import FormEditUserValidationSchema from "./FormEditUserValidationSchema";
import IFormEditUser from "./IFormEditUser";

const FormEditUserContainer: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { state: stateLocation } = useLocation<{ user: IUser }>();
  const [loading, setLoading] = useState(false);
  const iocContext = useIoCContext();

  const updateUserService = iocContext.serviceContainer.get<IUpdateUserService>(
    Types.Users.IUpdateUSerService
  );

  if (stateLocation?.user === undefined) {
    history.push(ROUTES.ADMIN_ROUTES.HOME);
    return <></>;
  }

  const initialValues: IFormEditUser = {
    firstName: stateLocation.user.firstName,
    lastName: stateLocation.user.lastName,
    companyName: "",
    CNPJ: "",
    email: stateLocation.user.email,
    username: stateLocation.user.username,
    attributes: stateLocation.user.attributes,
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          try {
            setLoading(true);
            delete values.companyName;
            delete values.CNPJ;

            const casted = FormEditUserValidationSchema.cast(values);
            if (!casted) return;

            const updateDataUser: IUpdateUserDTO = {
              ...casted,
              userID: stateLocation.user.id,
              attributes: {
                CNPJ:
                  casted?.attributes && casted.attributes["CNPJ"]
                    ? (casted.attributes["CNPJ"] as {
                        companyName: string;
                        CNPJ: string;
                      }[])
                    : {}[""],
              },
            };

            await updateUserService.execute(updateDataUser);

            enqueueSnackbar("Usuário alterado com sucesso", {
              variant: "success",
            });

            history.push(ROUTES.ADMIN_ROUTES.LIST_USERS);
          } catch (e) {
            if (e instanceof AppError) {
              return enqueueSnackbar(e.message, {
                variant: e.variant,
              });
            }

            enqueueSnackbar("Ocorreu um erro ao atualizar usuário", {
              variant: "error",
            });
          } finally {
            setLoading(false);
          }
        }}
        validationSchema={FormEditUserValidationSchema}
      >
        <Form style={{ margin: "2rem" }}>
          <FormAddUser loading={loading} />
        </Form>
      </Formik>
    </div>
  );
};

export default FormEditUserContainer;
