export enum SITUATION_TYPE {
  OPEN = "open",
  ALL = "all",
  CLOSED = "closed",
  DUE_DATE = "due",
}

export enum FILTER_BY_TYPE {
  DUE_DATE = "dueDate",
  DOCUMENT_DATE = "documentDate",
}

export enum TYPE_REPORT_TITLE {
  PDF = "pdf",
  EXCEL = "v2",
}

export enum ORDER_BY_TYPE {
  DESC = "desc",
  ASC = "asc",
  EMPTY = "",
}
