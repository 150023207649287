import DrawerFilter from "@components/DrawerFilter";
import LayoutDashPosh from "@components/LayoutDashPosh";
import TableVolPerProduct from "@components/TableVolPerProduct";
import { DashPoshProvider, useDashPosh } from "@context/DashPoshContext";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { IGetCustomersMarketingPlanDTO } from "@modules/user/dtos/IGetCustomersMarketingPlanDTO";
import { IGetHistoryQtUserRegisterService } from "@modules/user/models/IGetHistoryQtUserRegisterService";
import { IGetHistoryTotalQtVolLTPerProductService } from "@modules/user/models/IGetHistoryTotalQtVolLTPerProductService";
import { IGetHistoryTotalQtVolLTService } from "@modules/user/models/IGetHistoryTotalQtVolLTService";
import { IListCustomersMarketingPlanService } from "@modules/user/models/IListCustomersMarketingPlanService";
import {
  capitalizeFirstLetterFullString,
  formatAmount,
  formatDateWithoutHours,
  formatDateWithTZ,
  formatDateWithTZPlusOneDay,
  maskCNPJ,
} from "@utils/index";
import { IHistoryTotalQtVolLTPerProduct } from "@utils/interfaces";
import { ApexOptions } from "apexcharts";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useUserState } from "../../../context/UserContext";
import CardsTotals from "./CardsTotals";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CalendarIconCustomer, FilterIconBar } from "@components/Icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import FormValidationFilterDashPosh from "./FormValidationFilterDashPosh";
import { format, isValid } from "date-fns";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(({ ...theme }) =>
  createStyles({
    containerDash: {
      overflow: "hidden",
      padding: "2.4rem 2.4rem",
      height: "100%",
      [theme.breakpoints.up("sm")]: {
        padding: "2.4rem 4rem",
      },
      [theme.breakpoints.down("xs")]: {
        overflowY: "auto",
      },
    },

    containerCardsDash: {
      whiteSpace: "nowrap",
      overflowX: "auto",
      overflowY: "hidden",
      alignItems: "center",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "&::-webkit-overflow-scrolling": "touch",
    },
    rootAutoCompleteInput: {
      backgroundColor: "#fff",
      borderRadius: 8,
      height: "4rem",
    },
    removeBorder: { border: "none" },
    containerChartVol: {
      display: "flex",
      flexDirection: "column",
      paddingTop: "4rem",
      paddingBottom: "4rem",
      [theme.breakpoints.up("sm")]: { flexDirection: "row" },
    },
    chartVol: {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        width: "50%",
        marginRight: "4rem",
      },
    },
    chartVolProduct: {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        width: "50%",
        height: "100%",
      },
    },

    cardConsumers: {
      backgroundColor: "white",
      borderRadius: "5px",
      borderBottom: "3px solid #1A74CD",
      padding: "2.4rem",
      display: "flex",
      alignItems: "center",
      width: "24rem",
      marginRight: "4rem",
      [theme.breakpoints.up("sm")]: {
        width: "34rem",
        height: "14rem",
        padding: "3.2rem",
      },
    },
    titleCard: {
      fontSize: "2.4rem",
      fontWeight: "bold",
      [theme.breakpoints.up("sm")]: { fontSize: "3.2rem" },
    },
    descriptionCard: {
      color: "#B0C1E1",
      paddingBottom: "1.2rem",
      fontSize: "1rem",
      lineHeight: "0.8rem",
      [theme.breakpoints.up("sm")]: { fontSize: "1.6rem" },
    },
    containerWithImage: {
      width: "4rem",
      height: "4rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "5px",
      [theme.breakpoints.up("sm")]: { width: "5.2rem", height: "5.2rem" },
    },
    table: {
      "&:last-child td, &:last-child th": { border: 0 },
    },
    tootipCard: {
      padding: 10,
      color: "#fff",
      background: "#2E392A",
      fontWeight: 500,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    tooltipCard: {
      padding: 10,
      color: "#fff",
      background: "#2E392A",
      fontWeight: 500,
    },
    titleSection: {
      paddingBottom: "1.2rem",
      color: "#3E3D3D",
      fontFamily: " DM Sans",
      fontSize: "2rem",
      fontWeight: 700,
    },

    inputLabel: {
      position: "absolute",
      top: "-2px",
      left: "12px",
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "20px",
      backgroundColor: "#fff",
      paddingLeft: "0.2rem",
      paddingRight: "0.8rem",
      marginLeft: "0rem",
      width: "auto",
    },
    itemFormContainer: {
      marginTop: "4rem",
      marginLeft: "0",
      width: "100%",
      padding: "0px 36px 0px 36px",
    },
    customSelect: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      marginTop: "0",
      height: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
      paddingLeft: "14px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(114, 101, 81, 0.20)",
      },
      "& .MuiSvgIcon-root": {
        fill: "#D91F05",
      },
      borderRadius: "8px",
    },
    labelChip: {
      fontFamily: "Montserrat",
      marginBottom: "0.5rem",
      color: "#3E3D3D",
      fontWeight: "bold",
      fontSize: "24px",
      marginLeft: "4rem",
    },
    formControl: {
      borderRadius: "8px",
      width: "100%",
      height: "100%",
    },
    itemSelectTitle: {
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
    },
    itemSelectSubTitle: {
      color: "#D5D1CB",
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "46px",
      padding: 0,
      marginTop: "-1.2rem",
    },
    customButton: {
      borderRadius: "2px",
      textTransform: "none",
      fontWeight: "bold",
      fontFamily: "Montserrat",
      background: "#D91F05",
      width: "131.14px",
      height: "48px",
      marginTop: "20px",
      marginBottom: "20px",
    },
  })
);

const DashPoshPageProvider = () => {
  const userState = useUserState();
  const classes = useStyles();
  const [cnpj, setCnpj] = useState<string[]>([]);
  const [customers, setCustomers] = useState<IGetCustomersMarketingPlanDTO[]>(
    []
  );
  const [typeDateTruncRegisterChart] = useState("month");
  const useDash = useDashPosh();
  const [typeDateTruncVolChart] = useState("month");
  const [loadingChartConsumers, setLoadingChartConsumers] = useState(true);
  const [loadingTableVolPerProducts, seLoadingTableVolPerProducts] = useState(
    true
  );

  const [optionsHistoryUserRegister, setOptionsHistoryUserRegister] = useState<
    ApexOptions
  >({});

  const [seriesHistoryUserRegister, setSeriesHistoryUserRegister] = useState<
    ApexAxisChartSeries
  >([]);

  const [optionsHistoryTotalQtVolLT, setOptionsHistoryTotalQtVolLT] = useState<
    ApexOptions
  >({});

  const [seriesHistoryTotalQtVolLT, setSeriesHistoryTotalQtVolLT] = useState<
    ApexAxisChartSeries
  >([]);

  const [
    seriesHistoryTotalQtVolLTProduct,
    setSeriesHistoryTotalQtVolLTProduct,
  ] = useState<IHistoryTotalQtVolLTPerProduct[]>([]);

  const [, setCustomersTransformString] = useState<string>();

  const [showFilter, setShowFilter] = useState(false);
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();

  const getCustomersService = iocContext.serviceContainer.get<
    IListCustomersMarketingPlanService
  >(Types.Users.IListCustomersMarketingPlanService);

  const getHistoryUserRegister = iocContext.serviceContainer.get<
    IGetHistoryQtUserRegisterService
  >(Types.Users.IGetHistoryQtUserRegisterService);

  const getHistoryTotalQtVolLT = iocContext.serviceContainer.get<
    IGetHistoryTotalQtVolLTService
  >(Types.Users.IGetHistoryTotalQtVolLTService);

  const getHistoryTotalQtVolLTPerProduct = iocContext.serviceContainer.get<
    IGetHistoryTotalQtVolLTPerProductService
  >(Types.Users.IGetHistoryTotalQtVolLTPerProductService);

  const mapListCNPJ = useMemo(() => {
    if (userState.listCNPJ.length > 0) {
      const map = userState.listCNPJ.map((item) => {
        return item.CNPJ;
      });

      setCnpj(map);
    }
  }, [userState.listCNPJ]);

  const getCustomers = useCallback(async () => {
    try {
      const customers = await getCustomersService.execute(cnpj.toString());
      setCustomers(customers);
    } catch (error) {
    } finally {
    }
  }, [getCustomersService, cnpj]);

  const transformString = useCallback(() => {
    const customersString = customers.map((e) => `'${e.id}'`);
    setCustomersTransformString(customersString.toString());
  }, [customers]);

  const fetchHistoryQtUserRegister = useCallback(async () => {
    try {
      setLoadingChartConsumers(true);
      if (useDash.customersTransformString) {
        const series = await getHistoryUserRegister.execute({
          action: "count-distinct-user-groupped-by-time",
          companies_id: useDash.customersTransformString,
          type_date_trunc: typeDateTruncRegisterChart,
          from: formatDateWithTZ(useDash.dateFilter),
          to: formatDateWithTZPlusOneDay(useDash.dateToFilter),
        });

        let categories = series.reverse().map((item) => {
          const data = new Date(item.dt);

          if (typeDateTruncRegisterChart === "month") {
            return data
              .toLocaleDateString("pt-BR", {
                timeZone: "UTC",
                year: "2-digit",
                month: "short",
              })
              .replace(". de ", "/");
          }
          if (typeDateTruncRegisterChart === "week") {
            return item.dt;
          }

          return data.toLocaleDateString("pt-BR", {
            timeZone: "UTC",
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          });
        });

        if (series) {
          setOptionsHistoryUserRegister({
            chart: {
              id: "basic-bar",
              zoom: {
                enabled: true,
              },
            },
            xaxis: {
              labels: {
                show: true,
                format:
                  typeDateTruncRegisterChart === "month" ? "MM/yy" : "dd/MM/yy",
                style: {
                  colors: ["#626166"],
                  fontSize: "14px",
                  fontFamily: "Open Sans, Arial, sans-serif",
                  fontWeight: 400,
                },
              },
            },
            labels: categories,

            yaxis: {
              show: true,
              labels: {
                show: true,
                style: {
                  colors: ["#626166"],
                  fontSize: "14px",
                  fontFamily: "Open Sans, Arial, sans-serif",
                  fontWeight: 400,
                },
              },
            },
            grid: {
              show: true,
              borderColor: "rgba(176, 193, 225, 0.3)",
              strokeDashArray: 10,
              padding: {
                left: 1,
              },
            },
            tooltip: {
              enabled: true,
              theme: "dark",
              x: {
                show: false,
              },
              y: {
                title: {
                  formatter: function () {
                    return "";
                  },
                },
              },
            },
          });

          setSeriesHistoryUserRegister([
            {
              data: series.map((item) => parseInt(item.count)),
              name: "Usuários cadastrados",
            },
          ]);
        }
      }
    } catch (error) {
    } finally {
      setLoadingChartConsumers(false);
    }
  }, [getHistoryUserRegister, useDash, typeDateTruncRegisterChart]);

  const fetchHistoryTotalQtVolLT = useCallback(async () => {
    try {
      if (useDash.customersTransformString) {
        const series = await getHistoryTotalQtVolLT.execute({
          action: "sum-quantity-item-groupped-by-time",
          companies_id: useDash.customersTransformString,
          type_date_trunc: typeDateTruncVolChart,
          from: formatDateWithTZ(useDash.dateFilter),
          to: formatDateWithTZPlusOneDay(useDash.dateToFilter),
        });

        let categories = series.reverse().map((item, idx) => {
          const data = new Date(item.dt);

          if (typeDateTruncVolChart === "month") {
            return data
              .toLocaleDateString("pt-BR", {
                timeZone: "UTC",
                year: "2-digit",
                month: "short",
              })
              .replace(". de ", "/");
          }

          if (typeDateTruncVolChart === "week") {
            return item.dt;
          }

          return data.toLocaleDateString("pt-BR", {
            timeZone: "UTC",
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          });
        });

        if (series) {
          setOptionsHistoryTotalQtVolLT({
            chart: {
              height: 350,
              type: "bar",
            },
            colors: ["#FFD8D2"],
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "45%",
                borderRadius: 4,
              },
            },
            dataLabels: {
              enabled: false,
            },
            grid: {
              show: true,
              borderColor: "rgba(176, 193, 225, 0.3)",
              strokeDashArray: 10,
              padding: {
                left: 1,
              },
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["#D91F05"],
            },
            xaxis: {
              labels: {
                show: true,
                style: {
                  colors: ["#626166"],
                  fontSize: "14px",
                  fontFamily: "Open Sans, Arial, sans-serif",
                  fontWeight: 400,
                },
              },
            },
            labels: categories,
            yaxis: {
              show: true,
              labels: {
                show: true,
                style: {
                  colors: ["#626166"],
                  fontSize: "14px",
                  fontFamily: "Open Sans, Arial, sans-serif",
                  fontWeight: 400,
                },
              },
            },
            tooltip: {
              enabled: true,
              custom: function ({ series, seriesIndex, dataPointIndex }: any) {
                const value = series[seriesIndex][dataPointIndex]
                  ? formatAmount(series[seriesIndex][dataPointIndex])
                  : series[seriesIndex][dataPointIndex];
                return (
                  `<div class=${classes.tooltipCard}>` +
                  "<span>" +
                  value +
                  "</span>" +
                  "</div>"
                );
              },
              theme: "dark",
            },
          });

          setSeriesHistoryTotalQtVolLT([
            {
              data: series.map((item) => parseFloat(item.sum.toFixed())),
              name: "Volume Total LT",
            },
          ]);
        }
      }
    } catch (error) {
    } finally {
    }
  }, [
    getHistoryTotalQtVolLT,
    useDash,
    typeDateTruncVolChart,
    classes.tooltipCard,
  ]);

  const fetchHistoryTotalQtVolLTPerProduct = useCallback(async () => {
    try {
      seLoadingTableVolPerProducts(true);
      if (useDash.customersTransformString) {
        const series = await getHistoryTotalQtVolLTPerProduct.execute({
          action: "sum-quantity-item-groupped-by-product-group",
          companies_id: useDash.customersTransformString,
          from: formatDateWithTZ(useDash.dateFilter),
          to: formatDateWithTZPlusOneDay(useDash.dateToFilter),
        });

        setSeriesHistoryTotalQtVolLTProduct(series);
      }
    } catch (error) {
    } finally {
      seLoadingTableVolPerProducts(false);
    }
  }, [getHistoryTotalQtVolLTPerProduct, useDash]);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  useEffect(() => {
    transformString();
  }, [transformString]);

  useEffect(() => {
    fetchHistoryQtUserRegister();
  }, [fetchHistoryQtUserRegister]);

  useEffect(() => {
    fetchHistoryTotalQtVolLT();
  }, [fetchHistoryTotalQtVolLT]);

  useEffect(() => {
    fetchHistoryTotalQtVolLTPerProduct();
  }, [fetchHistoryTotalQtVolLTPerProduct]);

  useEffect(() => {}, [loadingChartConsumers, mapListCNPJ]);

  return (
    <LayoutDashPosh>
      <div className={classes.containerDash}>
        <Box>
          <Formik
            initialValues={{
              customersID: customers.map((e) => e.id),
              startDate: useDash.dateFilter,
              endDate: useDash.dateToFilter,
            }}
            validationSchema={FormValidationFilterDashPosh}
            onSubmit={(values) => {
              try {
                const customersConverting = values.customersID.map(
                  (item) => `'${item}'`
                );
                useDash.setCustomersTransformString(
                  customersConverting.toString()
                );
                enqueueSnackbar("Filtro realizado com sucesso", {
                  variant: "success",
                });
              } catch (error) {
                enqueueSnackbar(
                  "Não foi pssível fazer a filtragem no momento!",
                  {
                    variant: "error",
                  }
                );
              } finally {
              }
            }}
          >
            {({ values, setFieldValue, setFieldTouched, touched, errors }) => {
              return (
                <DrawerFilter
                  open={showFilter}
                  onClose={() => setShowFilter(!showFilter)}
                  onOpen={() => setShowFilter(showFilter)}
                >
                  <Grid item xs={12}>
                    <InputLabel className={classes.labelChip}>
                      Adicionar filtros
                    </InputLabel>
                  </Grid>

                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    style={{ padding: "30px 0" }}
                  >
                    <Grid item xs={10}>
                      <FilterIconBar />
                    </Grid>
                  </Grid>
                  <Box className={classes.itemFormContainer}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      format="dd/MM/yyyy"
                      label="Data inicial"
                      value={values.startDate}
                      error={!!errors.startDate && !!touched.startDate}
                      helperText={!!touched.startDate && errors.startDate}
                      onChange={(date) => {
                        setFieldTouched("startDate", true);
                        setFieldValue("startDate", date);
                        if (isValid(date)) {
                          useDash.setDateFilter(
                            date ? format(date, "yyyy-MM-dd") : ""
                          );
                        }
                      }}
                      variant="inline"
                      inputVariant="outlined"
                      keyboardIcon={<CalendarIconCustomer />}
                    />
                  </Box>
                  <Box className={classes.itemFormContainer}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      format="dd/MM/yyyy"
                      label="Data final"
                      value={values.endDate}
                      error={!!errors.endDate && !!touched.endDate}
                      helperText={!!touched.endDate && errors.endDate}
                      onChange={(date) => {
                        setFieldTouched("endDate", true);
                        setFieldValue("endDate", date);
                        if (isValid(date)) {
                          useDash.setDateToFilter(
                            date ? format(date, "yyyy-MM-dd") : ""
                          );
                        }
                      }}
                      variant="inline"
                      inputVariant="outlined"
                      keyboardIcon={<CalendarIconCustomer />}
                    />
                  </Box>
                  <Form className={classes.itemFormContainer}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      variant="outlined"
                    >
                      <InputLabel
                        className={classes.inputLabel}
                        style={{
                          fontWeight: !!touched.customersID ? 700 : "none",
                        }}
                      >
                        Posto
                      </InputLabel>
                      <Select
                        onBlur={() => setFieldTouched("customersID", true)}
                        value={values.customersID}
                        multiple
                        renderValue={(selected) => (
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {(selected as string[]).map((value) => {
                              return (
                                <Chip
                                  style={{
                                    margin: "0.5rem",
                                    border: "1px solid #D91F05",
                                    backgroundColor: "#F4F7F9",
                                    color: "#D91F05",
                                  }}
                                  key={value}
                                  label={
                                    customers.find(
                                      (item) => item.id && item.id === value
                                    )?.name
                                  }
                                />
                              );
                            })}
                          </div>
                        )}
                        onChange={({ target }) => {
                          setFieldValue("customersID", target.value);
                        }}
                        name="customersID"
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {customers.map((ele, idx) => {
                          return (
                            <MenuItem key={ele.id} value={ele.id}>
                              <Grid container>
                                <Grid item>
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      ele.id
                                        ? values.customersID.indexOf(ele.id) >
                                          -1
                                        : false
                                    }
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography
                                    className={classes.itemSelectTitle}
                                  >
                                    {capitalizeFirstLetterFullString(ele.name)}
                                  </Typography>
                                  <Typography
                                    className={classes.itemSelectSubTitle}
                                  >
                                    {maskCNPJ(ele.document)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          bottom: 0,
                          padding: "2.4rem",
                          width: "100%",
                          marginBottom: 0,
                        }}
                      >
                        <Button
                          color="primary"
                          type="submit"
                          variant="contained"
                          onClick={() => setShowFilter(!showFilter)}
                          className={classes.customButton}
                        >
                          Filtrar
                        </Button>
                      </Box>
                    </FormControl>
                  </Form>
                </DrawerFilter>
              );
            }}
          </Formik>
        </Box>

        <div
          style={{
            padding: "4rem 1.6rem 0rem 0rem",
            marginBottom: "1rem",
            marginTop: "1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "2.4rem",
                paddingBottom: "1.6rem",
                color: "#313B45",
              }}
            >
              Dashboard Poshcash
            </Typography>
            <Typography
              style={{
                color: "#B0C1E1",
                fontSize: "1.6rem",
                fontWeight: 500,
                paddingBottom: "2rem",
              }}
            >
              {`Período de ${
                useDash.dateFilter
                  ? formatDateWithoutHours(useDash.dateFilter)
                  : " Início "
              } à ${
                useDash.dateToFilter &&
                formatDateWithoutHours(useDash.dateToFilter)
              }`}
            </Typography>
          </div>

          <Button
            onClick={() => setShowFilter(!showFilter)}
            color="primary"
            style={{
              textTransform: "none",
              height: "4rem",
              fontWeight: 600,
            }}
          >
            Adicionar Filtros
          </Button>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CardsTotals />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div style={{ paddingTop: "6.4rem" }}>
            <Typography className={classes.titleSection}>
              Consumidores cadastrados
            </Typography>

            {loadingTableVolPerProducts ? (
              <div style={{ padding: "1.2rem 0rem" }}>
                <Skeleton variant="rect" height={360} />
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "0.8rem",
                  padding: "2rem",
                }}
              >
                <ReactApexChart
                  type="area"
                  options={optionsHistoryUserRegister}
                  series={seriesHistoryUserRegister}
                  height={350}
                />
              </div>
            )}
          </div>
        </Grid>

        <div className={classes.containerChartVol}>
          <div className={classes.chartVol}>
            <Typography className={classes.titleSection}>Volume</Typography>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "0.8rem",
                padding: "2rem",
              }}
            >
              <>
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    style={{
                      fontSize: "1.4rem",
                      fontWeight: "bold",
                      paddingLeft: "1.6rem",
                    }}
                  >
                    Litros
                  </Typography>
                </Box>
                <ReactApexChart
                  type="bar"
                  options={optionsHistoryTotalQtVolLT}
                  series={seriesHistoryTotalQtVolLT}
                  height={350}
                />
              </>
            </div>
          </div>

          <div className={classes.chartVolProduct}>
            <Typography className={classes.titleSection}>
              Volume por produto
            </Typography>
            {seriesHistoryTotalQtVolLTProduct && (
              <div style={{ overflowX: "auto" }}>
                <TableVolPerProduct
                  items={seriesHistoryTotalQtVolLTProduct}
                  rowsPerPage={5}
                  loading={loadingTableVolPerProducts}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </LayoutDashPosh>
  );
};

const DashPoshPage: React.FC = () => {
  return (
    <DashPoshProvider>
      <DashPoshPageProvider />
    </DashPoshProvider>
  );
};

export default DashPoshPage;
