interface ListUserHeaderTable {
  headerName: string;
}

export const HeaderTable: ListUserHeaderTable[] = [
  { headerName: "Nome" },
  { headerName: "E-mail" },
  { headerName: "Razão social" },
  { headerName: "Cadastrado por" },
  { headerName: "Data de cadastro" },
  { headerName: "" },
];
