import { CloseIconRadiusIcon } from "@components/Icons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import { capitalizeFirstLetterFullString } from "@utils/index";
import React from "react";
import MapOrder from "./MapOrder";

interface IModalTrackOrder {
  latitude: number;
  longitude: number;
  time?: string; // Aguardando do back-end
  productName?: string;
  productID?: string;
  quantityLiter?: number;
  orderNumber?: string;
  companyName?: string;
  open: boolean;

  closeModal: () => void;
}

const ModalTrackOrder: React.FC<IModalTrackOrder> = ({ ...props }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      maxWidth="md"
      PaperProps={{ style: { borderRadius: "8px", padding: "20px 0px" } }}
    >
      <Grid
        xs={12}
        style={{
          display: "flex",
        }}
      >
        <Grid
          xs={12}
          style={{
            paddingLeft: "6.4rem",
            paddingBottom: "2.4rem",
            paddingTop: "1.4rem",
          }}
        >
          <Typography className={classes.titleModal}>
            Pedido {props.orderNumber}
          </Typography>
          <Typography className={classes.titleModalOpacity}>
            {props.companyName
              ? capitalizeFirstLetterFullString(props.companyName)
              : ""}
          </Typography>
        </Grid>
        <Grid>
          <Button
            onClick={() => props.closeModal()}
            className={classes.closeButtonTop}
          >
            <CloseIconRadiusIcon style={{ width: "14px", height: "14px" }} />
          </Button>
        </Grid>
      </Grid>

      <DialogContent
        style={{
          padding: "0px",
        }}
      >
        <DialogTitle style={{ paddingTop: "0px" }}>
          <TableContainer
            style={{
              border: "1px solid rgba(114, 101, 81, 0.20)",
              borderRadius: "8px",
            }}
          >
            <Table style={{ fontSize: "1.6rem" }}>
              <TableBody>
                <TableRow>
                  <TableCell style={{ fontWeight: 700, border: "none" }}>
                    Produto e Volume
                  </TableCell>
                  <TableCell align="right" style={{ border: "none" }}>
                    {props.quantityLiter?.toLocaleString("pt-BR") + " litros"}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      fontWeight: 700,
                      border: "none",
                    }}
                  >
                    {`${props.productID} - ${capitalizeFirstLetterFullString(
                      props.productName
                    )}`}
                  </TableCell>
                </TableRow>
                {/* TODO: descomentar assim que o back-end enviar a estimativa de entrega */}
                {/* <TableRow>
                    <TableCell style={{ fontWeight: 700 }}>
                      Estimativa de tempo(calcular)
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">2 dias - mock</TableCell>
                  </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogTitle>

        <DialogContent>
          <MapOrder latitude={props.latitude} longitude={props.longitude} />
        </DialogContent>

        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={() => props.closeModal()}
            variant="contained"
            className={classes.buttonOK}
          >
            OK
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ModalTrackOrder;

const useStyles = makeStyles((theme) =>
  createStyles({
    titleModal: {
      color: "#3E3D3D",
      fontSize: "2.2rem",
      fontFamily: "Montserrat",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleModalOpacity: {
      color: "#3E3D3D",
      fontSize: "1.5rem",
      fontFamily: "Montserrat",
      fontWeight: 500,
      textAlign: "center",
      opacity: 0.37,
    },
    subTitleModal: {
      color: "#3E3D3D",
      fontSize: "1.6rem",
      opacity: "0.6",
      fontFamily: "Montserrat",
      textAlign: "center",
      margin: "20px 16px",
    },
    buttonOK: {
      textTransform: "none",
      background: "#D91F05",
      color: "#FFF",
      boxShadow: "none",
      fontFamily: "Montserrat",
      borderRadius: "2px",
      fontWeight: "bold",
      whiteSpace: "nowrap",
      padding: "10px 40px",
      "&:hover": {
        opacity: "0.7",
        background: "#D91F05",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
      },
    },

    closeButtonTop: {
      backgroudn: "transparent",
      borderRadius: "30px",
    },
  })
);
