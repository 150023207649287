import { Grid, Theme, Typography, useTheme } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { ReactNode } from "react";

import { INozzleValidate, STATUSNOZZLE } from "@pages/Admin/Nozzle/model";
import ModalValidateNozzle from "@pages/Admin/Nozzle/resource/modal/ModalValidateNozzle";
import {
  TableColumn,
  TypographyBody,
  TypographyHeader,
  getStatusColor,
} from "@pages/Admin/Nozzle/resource/table/TableNozzleAdmin";

import PaginationCustom from "@components/Pagination";
import GetAppIcon from "@material-ui/icons/GetApp";
interface TableNozzleValidateProps {
  children?: ReactNode;
  rows: INozzleValidate[]; // Interface de bicos que serao validados a partir de um CNPJ
  cnpjSelected: string | null;
}

enum KEY {
  IDNOZZLE = "idNozzle",
  PROD = "productDescription",
  IMG = "image",
  STATUS = "status",
  ACTION = "action",
}

export const imageNozzleNormalized = (imageSRC: string, theme: Theme) => {
  const url = imageSRC ?? "-";

  // Dividir a URL usando "/" como delimitador e obter a última parte (nome do arquivo)
  const parts = url.split("/");
  let fileName = parts[parts.length - 1];

  if (!fileName.toLowerCase().endsWith(".png")) {
    fileName += ".png";
  }

  return (
    <span style={{ display: "flex", alignItems: "center", gap: 4 }}>
      <a
        href={imageSRC}
        style={{
          color: theme.palette.primary.main,
          textDecoration: "none",
          fontWeight: 600,
          whiteSpace: "nowrap",
        }}
      >
        <Grid container spacing={2} style={{ display: "flex" }}>
          <Grid item>
            <img src={imageSRC} alt={fileName} height="30" width="30" />
          </Grid>
          <Grid
            item
            style={{
              justifyContent: "center",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              style={{
                justifyContent: "center",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item xs={10}>
                <div>Baixar Imagem</div>
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <GetAppIcon style={{ width: "20px", marginLeft: "10px" }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </a>
    </span>
  );
};

const TableNozzleValidate: React.FC<TableNozzleValidateProps> = ({
  rows,
  cnpjSelected,
}) => {
  const rowsPerPage = 10;

  const theme = useTheme();
  const classes = useStyles();

  function getStatusString(status) {
    switch (status) {
      case STATUSNOZZLE.BLOCK:
        return "Ver motivo da recusa";
      case STATUSNOZZLE.WAITING:
        return "Validar";
      default:
        return "Ver detalhes";
    }
  }

  const tableHeader: TableColumn[] = [
    { key: KEY.IDNOZZLE, label: "Bico", align: "center", width: "10%" },
    { key: KEY.PROD, label: "Produto", align: "left", width: "15%" },
    { key: KEY.IMG, label: "Imagem", align: "left", width: "30%" },
    { key: KEY.STATUS, label: "Status", align: "left" },
    { key: KEY.ACTION, label: "", align: "right", width: "15%" },
  ];

  const formatEntry = (entry: INozzleValidate, key: string) => {
    switch (key) {
      case KEY.IDNOZZLE:
        return (
          entry.pumpNozzle.nozzles.map((nozzle, nozzleIndex) => (
            <span key={nozzleIndex} style={{ lineHeight: 2.5 }}>
              {nozzle.idNozzle}
              <br />
            </span>
          )) ?? "-"
        );

      case KEY.PROD:
        return (
          entry.pumpNozzle.nozzles.map((prod, prodIndex) => (
            <span key={prodIndex} style={{ lineHeight: 2.5 }}>
              {prod.productDescription}
              <br />
            </span>
          )) ?? "-"
        );

      case KEY.IMG:
        return entry.image !== ""
          ? imageNozzleNormalized(entry.image, theme)
          : "-";

      case KEY.STATUS:
        if (entry.status === STATUSNOZZLE.APPROVED) {
          return "Concluído";
        }
        if (entry.status === STATUSNOZZLE.WAITING) {
          return "Aguardando Aprovação";
        }
        if (entry.status === STATUSNOZZLE.BLOCK) {
          return "Recusado";
        }

        if (entry.status === "") {
          return (
            <div style={{ opacity: 0.6 }}>
              Aguardando foto ser anexada pelo usuário solicitante.
            </div>
          );
        }

        return "-";

      case KEY.ACTION:
        if (cnpjSelected) {
          return (
            <ModalValidateNozzle
              nozzleValidate={entry}
              cnpj={cnpjSelected}
              disabled={entry.status === "" ? true : false}
            >
              <Typography
                style={{
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                  fontSize: "1.4rem",
                }}
              >
                {getStatusString(entry.status)}
              </Typography>
            </ModalValidateNozzle>
          );
        }

        return "CNPJ nao identificado";

      default:
        return "-";
    }
  };

  const [page, setPage] = React.useState(0);

  const handleChangePage = (_, newPage: number) => {
    setPage(newPage);
  };

  const handleOnChange = (_, newPage: number) => {
    setPage(newPage - 1);
  };

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {tableHeader.map((item, index) => (
              <TableCell
                key={index}
                sx={{
                  textAlign: item.align ?? "left",
                  width: item.width ?? "auto",
                }}
                className={classes.tableCell}
              >
                <TypographyHeader>{item.label}</TypographyHeader>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {tableHeader.map((col, indexCol) => (
                <TableCell
                  key={indexCol}
                  sx={{ textAlign: col.align ?? "left" }}
                  className={classes.tableCell}
                >
                  <TypographyBody sx={{ color: getStatusColor(row[col.key]) }}>
                    {formatEntry(row, col.key)}
                  </TypographyBody>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <PaginationCustom
        count={rows.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangeHandle={handleOnChange}
        onPageChange={handleChangePage}
      />
    </TableContainer>
  );
};

export default TableNozzleValidate;

export const useStyles = makeStyles({
  table: {
    borderSpacing: "0 16px !important",
    marginTop: "-8px !important",
    "&.MuiTable-root": {
      borderCollapse: "separate",
    },
  },
  tableCell: {
    background: "#fff",
    borderTop: "1px solid #fff",
    borderBottom: "1px solid #fff !important",
    height: "24px",
    paddingLeft: "24px !important",
    "&.MuiTableCell-head": {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    "&:first-child": {
      // Aplica arredondamento nas bordas apenas na primeira celula de cada linha
      borderLeft: "1px solid #fff",
      borderBottomLeftRadius: "0.6rem",
      borderTopLeftRadius: "0.6rem",
    },
    "&:last-child": {
      // Aplica arredondamento nas bordas apenas na ultima celula de cada linha
      borderRight: "1px solid #fff",
      borderBottomRightRadius: "0.6rem",
      borderTopRightRadius: "0.6rem",
      paddingRight: "32px",
    },
  },
});
