import { EmployeeIcon } from "@components/Icons";
import GenericPaginatedTable, {
  Column,
} from "@components/TableGenericPagination";
import { IEmployee, IEmployeeUnregistered } from "@pages/User/Employee/model";
import React from "react";

interface TableEmployeeUnregisteredProps {
  data: IEmployeeUnregistered[];
  rowsPerPage: number;
  onRowsPerPageChange: (e) => void;
  labelButton?: string;
  hasLoading?: boolean;
  hasDrawer: boolean;
  onClickCreateEmployee?: (employee?: IEmployee) => void;
}

const TableEmployeeUnregistered: React.FC<TableEmployeeUnregisteredProps> = ({
  ...props
}) => {
  const theadEmployeeUnregistered: Column<IEmployeeUnregistered>[] = [
    { title: "", field: "blank", align: "right", width: "5%" }, // Coluna Criada para inserir um icone
    { title: "Id", field: "identifid", align: "left", width: "10%" },
    {
      title: "Último abastecimento",
      field: "createdAt",
      align: "center",
      width: "15%",
    },
    { title: "", field: "blank", align: "right" }, // Coluna Criada para inserir um botao
  ];

  const dataEmpty: IEmployeeUnregistered[] = [
    {
      identifid: "-",
      createdAt: "-",
      blank: "",
    },
  ];

  return (
    <GenericPaginatedTable
      columns={theadEmployeeUnregistered}
      data={props.data.length > 0 ? props.data : dataEmpty}
      rowsPerPage={props.rowsPerPage}
      onRowsPerPageChange={props.onRowsPerPageChange}
      labelButton={props.labelButton}
      hasDrawer={true}
      icon={<EmployeeIcon />}
      hasLoading={props.hasLoading}
    />
  );
};

export default TableEmployeeUnregistered;
