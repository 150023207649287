import React from "react";
import { useTheme } from "@material-ui/core";
import {
  OutlinedTextFieldProps,
  TextField,
  MenuItem,
  Typography,
} from "@mui/material";
import { ArrowDownIconPlus } from "@components/Icons";

export interface IOptionTextFieldSelect {
  value: string;
  label: string;
}

interface TextFieldCustomProps extends Omit<OutlinedTextFieldProps, "variant"> {
  options: IOptionTextFieldSelect[];
}

const TexFieldSelect: React.FC<TextFieldCustomProps> = (props) => {
  const theme = useTheme();
  return (
    <TextField
      {...props}
      variant="outlined"
      select
      fullWidth
      sx={{
        "& .Mui-error": {
          color: `${theme.palette.error.main}`,
        },
        "& .MuiFormHelperText-root": {
          fontFamily: theme.typography.fontFamily,
          fontSize: { lg: 12 },
          fontWeight: 400,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderRadius: "1rem",
        },
        "& .Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: `${theme.palette.error.main}`,
        },
      }}
      InputLabelProps={{
        sx: {
          fontFamily: theme.typography.fontFamily,
          backgroundColor: "#fff",
          color: "rgba(0,0,0,0.4)",
          pr: 1,
          fontSize: { xs: 12, md: 14, lg: 15 },
        },
      }}
      inputProps={{
        sx: {
          p: 2.4,
        },
      }}
      SelectProps={{
        IconComponent: ArrowDownIconPlus,
        sx: {
          "& .MuiSelect-icon": {
            mr: 2.5,
          },
        },
      }}
    >
      {props.options.map((item, index) => (
        <MenuItem key={index} value={item.value}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontSize: { xs: 10, md: 12, lg: 12, xl: 16 },
              fontWeight: 400,
              p: 0,
              textAlign: "left",
            }}
          >
            {item.label}
          </Typography>
        </MenuItem>
      ))}
    </TextField>
  );
};

export default TexFieldSelect;
