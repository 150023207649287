import { Types } from "@ioc/types";
import * as IHttpService from "@modules/infra/http/models/IHttpService";
import { inject, injectable, named } from "inversify";
import { IFilterComissionDTO } from "../dtos/IFilterComissionDTO";
import { IFilterComissionData } from "../dtos/IFilterComissionData";
import { IFilterComissionService } from "../models/IFilterComissionService";

@injectable()
export class FilterComissionService implements IFilterComissionService {
  @inject(Types.IHttpService)
  @named("pump")
  private httpInstance!: IHttpService.IHttpService;

  public async execute(data: IFilterComissionDTO) {
    try {
      const reponseComission = await this.httpInstance.post<
        IFilterComissionData
      >("/dash_volume_billing_price/commission", data);
      return reponseComission;
    } catch (err) {
      throw err;
    }
  }

  public setAuthorization(token: string): void {
    this.httpInstance.setAuthorization(token);
  }
}
