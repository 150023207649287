import { useFormikContext } from "formik";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CalendarRange from "@components/CalendarRange";
import { CheckListIcon } from "@components/Icons";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useFormQuery } from "./FormQueryContext";
import { IQuery } from "./interface";
import { useUserState } from "@context/UserContext";
import { optionsFinancialStatus } from "@utils/MyOrders/ENUM_STATUS_ORDER";
import { LocalStorageKeysOrders } from "./utilsOrders/localStorageOrders";
import { endOfMonth, startOfMonth } from "date-fns";
import CardDefault from "@components/CardDefault";
import DropdownSelectAll from "@components/DropdownSelectAll";

const useStyles = makeStyles((theme) =>
  createStyles({
    itemSelect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      height: "6rem",
      paddingTop: "1rem",
    },
    itemSelectTitle: {
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
    },
    itemSelectSubTitle: {
      color: "#D5D1CB",
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "46px",
      padding: 0,
      marginTop: "-1.2rem",
    },
    iconNotDisabled: {
      opacity: "0.6",
    },
    titleCard: {
      color: " #3E3D3D",
      fontFamily: "DM Sans",
      fontSize: "24px",
      fontWeight: 700,
      paddingTop: "2rem",
      paddingBottom: "1rem",
    },
    customSelect: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      marginTop: "0",
      height: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
      paddingLeft: "14px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(114, 101, 81, 0.20)",
      },
      "& .MuiSvgIcon-root": {
        fill: "#D91F05",
      },
      borderRadius: "8px",
    },
    styleButton: {
      marginRight: "2rem",
      textTransform: "none",
      fontWeight: "bold",
      borderRadius: "2px",
      boxShadow: "none",
      "&:hover": {
        background: "#fff",
      },
    },
    styleButtonContained: {
      borderRadius: "2px",
      textTransform: "none",
      fontWeight: "bold",
      background: "#D91F05",
      boxShadow: "none",
      "&:hover": {
        background: "#D91F31",
      },
    },
    gridInput: {
      marginBottom: "2rem",
      height: "75px",
    },
    centeredText: {
      paddingRight: "16px",
      textAlign: "center",
      flexWrap: "nowrap",
    },
    checkbox: {
      padding: 4,
      "& .MuiSvgIcon-root": {
        width: 18,
        height: 18,
      },
    },
  })
);

export interface DrawerFilterState {
  category: string[];
}

export interface FormQueryProps {
  state?: DrawerFilterState;
  setState?: (data: DrawerFilterState) => void;
  setOpen?: boolean;
}

const FormQuery: React.FC<FormQueryProps> = () => {
  const classes = useStyles();
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<IQuery>();

  const userState = useUserState();
  const formQueryContext = useFormQuery();
  const refCNPJ = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [firstLoading, setFirstLoading] = useState(true);

  useEffect(() => {
    if (firstLoading) {
      setFirstLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const optionsCNPJ = userState.listCNPJ.map((cnpj) => ({
    value: cnpj.CNPJ,
    label: cnpj.companyName,
  }));

  const isDateRangeValid = (
    dateRange: [Date | null, Date | null] | null | undefined
  ): boolean => {
    return !!dateRange && !!dateRange[0] && !!dateRange[1];
  };

  const enableButton = useMemo((): boolean => {
    return values.CNPJ.length === 0 || !isDateRangeValid(values.dateRange);
  }, [values.CNPJ.length, values.dateRange]);

  const clearCNPJField = async () => {
    try {
      await setFieldValue("CNPJ", []);
      setFieldTouched("CNPJ", false);
    } catch (error) {
      console.error("Erro ao limpar o campo CNPJ:", error);
    }
  };

  const clearStatusSaleField = async () => {
    try {
      await setFieldValue("statusSale", []);
      setFieldTouched("statusSale", false);
    } catch (error) {
      console.error("Erro ao limpar o campo statusSale:", error);
    }
  };

  const clearDateRangeField = async () => {
    try {
      const firstDayOfMonth = startOfMonth(new Date());
      const lastDayOfMonth = endOfMonth(new Date());
      const dateRange: [Date, Date] = [firstDayOfMonth, lastDayOfMonth];

      await setFieldValue("dateRange", dateRange);
      setFieldTouched("startDate", true);
      setFieldTouched("endDate", true);
    } catch (error) {
      console.error("Erro ao limpar o campo de data:", error);
    }
  };

  const clearFilter = async () => {
    await Promise.all([
      clearCNPJField(),
      clearStatusSaleField(),
      clearDateRangeField(),
    ]);
  };

  const clickedInFilter = () => {
    if (values.CNPJ.length > 0 && values.statusSale.length > 0) {
      // Quando o usuário clica em filtrar então limpa os filtros retornados da tela de Novo Pedido
      localStorage.removeItem(LocalStorageKeysOrders.CNPJFromNewOrder);
      localStorage.removeItem(LocalStorageKeysOrders.DateDelivery);
      localStorage.setItem("isOrderFromNewRequest", "false");
      formQueryContext.setIsClickedFilter(true);
    } else {
      refCNPJ.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleChangeSelectedCompanies = useCallback(
    (event: string[]) => {
      if (!firstLoading) {
        setFieldValue("CNPJ", event);
      }
    },
    [setFieldValue, firstLoading]
  );

  const handleChangeSelectedStatus = useCallback(
    (event: string[]) => {
      if (!firstLoading) {
        setFieldValue("statusSale", event);
      }
    },
    [setFieldValue, firstLoading]
  );

  return (
    <CardDefault>
      <Box width="100%" padding="3rem">
        <Grid item xs={12} style={{ display: "flex", paddingBottom: "1.2rem" }}>
          <Grid xs={10}>
            <Typography
              className={classes.titleCard}
              style={{ fontSize: isMobile ? "1.6rem" : "" }}
            >
              Consultar pedidos
            </Typography>
          </Grid>
          <Grid
            xs={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: "1.6rem",
            }}
          >
            <CheckListIcon fontSize="small" />
          </Grid>
        </Grid>
        <Grid container style={{ display: "flex", width: "100%" }} spacing={2}>
          <Grid item xs={12} md={4} className={classes.gridInput}>
            <DropdownSelectAll
              label="Razão social"
              options={optionsCNPJ}
              value={values.CNPJ}
              isCNPJ={true}
              isSubTitle={true}
              onChange={handleChangeSelectedCompanies}
              error={!!errors.CNPJ && !!touched.CNPJ}
              messageError={
                !!touched.CNPJ && typeof errors.CNPJ === "string"
                  ? errors.CNPJ
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.gridInput}>
            <CalendarRange
              //@ts-ignore
              style={{ height: "30px" }}
              name="dateRange"
              label="Período de Entrega"
              value={values.dateRange}
              onChange={(dateRange) => {
                setFieldValue("dateRange", dateRange);
                setFieldTouched("startDate", true);
                setFieldTouched("endDate", true);
              }}
              onBlur={() => {
                setFieldTouched("startDate", true);
                setFieldTouched("endDate", true);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.gridInput}>
            <DropdownSelectAll
              label="Status do Pedido"
              options={optionsFinancialStatus}
              value={values.statusSale}
              onChange={handleChangeSelectedStatus}
            />
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="flex-end"
          spacing={0}
          className={classes.centeredText}
        >
          <Grid item>
            <Button
              color="primary"
              type="submit"
              variant="outlined"
              className={classes.styleButton}
              startIcon={
                formQueryContext.loadingQuery && (
                  <CircularProgress
                    color="secondary"
                    style={{ height: 20, width: 20 }}
                  />
                )
              }
              onClick={clearFilter}
            >
              Limpar filtros
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={enableButton}
              color="primary"
              type="submit"
              variant="contained"
              className={classes.styleButtonContained}
              startIcon={
                formQueryContext.loadingQuery && (
                  <CircularProgress
                    color="secondary"
                    style={{ height: 20, width: 20 }}
                  />
                )
              }
              onClick={clickedInFilter}
            >
              Consultar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </CardDefault>
  );
};

export default FormQuery;
