import {
  Avatar,
  Box,
  createStyles,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import imgAvatar from "../../assets/avatar.png";
import iconSelectChangeItem from "../../assets/change_select.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { marginRight: "0%" },
    container: {
      display: "flex",
      marginRight: "0px",
    },
    containerText: {
      display: "block",
      marginLeft: "10px",
      marginRight: "5%",
    },
    iconDropdown: { display: "flex-end" },
    small: {
      width: "24px",
      height: "24px",
    },
    name: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "20px",
      color: "#D91F05",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
    },
    description: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#1E1E1E",
      opacity: 0.7,
    },
    nameItemMenu: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      display: "flex",
      alignItems: "center",
      color: "#3E3D3D",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
    },
    descriptionItemMenu: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      display: "flex",
      alignItems: "center",
      color: "#726551",
      opacity: 0.3,
    },
    menu: {
      marginLeft: "-2.4%",
      overflowY: "auto",
      height: "600px",
      overflowX: "hidden",
      marginTop: "48px",
      "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
        backgroundColor: "transparent",
        opacity: 0.2,
        width: "0.8rem",
      },
      "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
        borderRadius: "0.5rem",
        backgroundColor: "#8E8474",
        minHeight: 24,
        border: "3px solid transparent",
        opacity: 0.2,
        width: "4.32px",
      },
    },
  })
);

interface selectProps<T> {
  option: T;
  description?: T;
  options: T[];
  onChange?: (value: T, index?: number) => void;
  getHeaderLabel: (value: T) => string;
  getHeaderSubLabel: (value: T) => string;
  getSelectItemLabel: (value: T) => string;
  getSelectItemSubLabel: (value: T) => string;
}
export default function Dropdown<T>(props: selectProps<T>) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const {
    option,
    options,
    onChange,
    getHeaderLabel,
    getSelectItemLabel,
    getHeaderSubLabel,
    getSelectItemSubLabel,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const selectedIndex = option ? options.indexOf(option!) : 0;

  const open = Boolean(anchorEl);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (option: T) => {
    if (onChange) onChange(option);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Box className={classes.root}>
        <Box
          className={classes.container}
          width={isMobile ? "0px" : "270px"}
          style={{ marginRight: isMobile ? "15px" : "" }}
        >
          <Avatar
            alt="Avatar user"
            src={imgAvatar}
            className={isMobile ? classes.small : ""}
            onClick={isMobile ? handleClickListItem : () => {}}
          />
          {!isMobile && (
            <>
              <Box
                className={classes.containerText}
                aria-expanded={open ? "true" : undefined}
                onClick={handleClickListItem}
              >
                <Typography className={classes.name}>
                  {getHeaderLabel(option)}
                </Typography>
                <Typography className={classes.description}>
                  {getHeaderSubLabel(option)}
                </Typography>
              </Box>
              <Box>
                <img
                  alt="iconSelectChangeItem"
                  src={iconSelectChangeItem}
                  width="16px"
                  height="16px"
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.menu}
        style={{ marginLeft: isMobile ? "1.6px" : "-30px" }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(option)}
            style={{ width: "350px" }}
          >
            <Box className={classes.container}>
              <Box className={classes.containerText}>
                <Typography
                  className={classes.nameItemMenu}
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                >
                  {getSelectItemLabel(option)}
                </Typography>
                <Typography
                  className={classes.descriptionItemMenu}
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                >
                  {getSelectItemSubLabel(option)}
                </Typography>
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
