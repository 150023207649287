import { CloseIconRadiusIcon } from "@components/Icons";
import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  SwipeableDrawer,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(({ ...theme }) =>
  createStyles({
    drawer: {
      width: "90vw",
      borderTopLeftRadius: "30px",
      padding: "1.6rem",
      [theme.breakpoints.up("sm")]: { width: "37vw" },
      [theme.breakpoints.up("lg")]: { width: "30vw" },
    },
  })
);

export interface DrawerFilterState {
  category: string[];
}

export interface DrawerFilterProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  state?: DrawerFilterState;
  setState?: (data: DrawerFilterState) => void;
}

const DrawerFilter: React.FC<DrawerFilterProps> = ({ children, ...props }) => {
  const classes = useStyles();
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <>
      <SwipeableDrawer
        anchor={"right"}
        open={props.open}
        onClose={props.onClose}
        onOpen={props.onOpen}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        SwipeAreaProps={{
          style: {
            height: "70vh",
            marginTop: "auto",
          },
        }}
      >
        <Box className={classes.drawer} role="presentation">
          <Box style={{ display: "flex", justifyContent: "right" }}>
            <IconButton onClick={props.onClose} style={{ cursor: "pointer" }}>
              <CloseIconRadiusIcon
                htmlColor="#313B45"
                style={{ height: "20px", width: "20px" }}
              />
            </IconButton>
          </Box>
          {children}
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default DrawerFilter;
