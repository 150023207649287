import {
  colors,
  createStyles,
  makeStyles,
  Paper,
  PaperProps,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      root: {
        textTransform: "none",
      },
      paperSituation: {
        padding: "0 1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      textStyle: {
        textTransform: "none",
        fontWeight: 700,
        fontFamily: "DM Sans",
        opacity: 0.7,
      },
      paperSituationSuccess: {
        color: theme.palette.success.light,
      },
      paperSituationError: {
        color: theme.palette.error.main,
      },
      paperSituationWarning: {
        color: theme.palette.secondary.light,
      },
      paperSituationBlack: {
        color: colors.grey[700],
        backgroundColor: theme.palette.hexToRGBWithAlpha(
          colors.grey[900],
          0.12
        ),
      },
      paperSituationMiddleWarning: {
        color: colors.purple[700],
        backgroundColor: theme.palette.hexToRGBWithAlpha(
          colors.purple[900],
          0.3
        ),
      },
      paperSituationGrey: {
        color: colors.grey[700],
        backgroundColor: theme.palette.hexToRGBWithAlpha(colors.grey[900], 0.3),
      },
    })
);

export interface TagProps extends PaperProps {
  type: "error" | "success" | "warning" | "black" | "middle-warning" | "grey";
}

const Tag: React.FC<TagProps> = ({ type, children, className, ...props }) => {
  const classes = useStyles();
  return (
    <Paper
      elevation={0}
      className={clsx(
        classes.paperSituation,
        {
          [classes.paperSituationError]: type === "error",
          [classes.paperSituationSuccess]: type === "success",
          [classes.paperSituationWarning]: type === "warning",
          [classes.paperSituationBlack]: type === "black",
          [classes.paperSituationMiddleWarning]: type === "middle-warning",
          [classes.paperSituationGrey]: type === "grey",
        },
        className
      )}
      {...props}
    >
      <Typography align="center" variant="button" className={classes.textStyle}>
        {children}
      </Typography>
    </Paper>
  );
};

export { Tag };
