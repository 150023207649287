import React, { useState } from "react";

import { Form, Formik } from "formik";
import { Grid } from "@mui/material";

import TextFieldMultiSelect from "@components/TextFieldMultiSelect";
import CircularProgress from "@components/CircularProgress";
import CalendarRange from "@components/CalendarRange";
import Button from "@components/Button";

import { limitRowsPerPageDefault } from "@pages/User/RoadLoad/context/RoadLoadContext";
import { useRoadLoad } from "@pages/User/RoadLoad/context/RoadLoadContext";

import {
  formatDateYYYYMMDD,
  getDateInOneMonth,
  getToday,
  labelDuplicatas,
} from "@utils/index";

import {
  IFormikValues,
  initialValuesFormikDefault,
  VALUESNAME,
  optionsList,
} from "./IDrawerformik";

interface DrawerContentFormProps {
  initialValuesFormik: IFormikValues;
  handleValuesFormik: (value: IFormikValues) => void;
  handleCloseDrawer: () => void;
}

const DrawerContentForm: React.FC<DrawerContentFormProps> = (props) => {
  const { initialValuesFormik, handleValuesFormik, handleCloseDrawer } = props;

  const {
    tabSelected,
    loading,
    roadLoadFilterOptions,
    handleRoadLoadMethod,
  } = useRoadLoad();

  const [load, setLoading] = useState<boolean>(false);

  const customerOptionsList = optionsList(roadLoadFilterOptions?.customers);
  const productOptionsList = optionsList(roadLoadFilterOptions?.products);
  const filiaisOptionsList = optionsList(roadLoadFilterOptions?.centers);

  // NOTE: Regra de negocio especifica para quando o usuario estiver usando o calendario na aba "nao agendado" deve bloquear datas D-2
  const checkTabSelected = () => {
    if (tabSelected === 1) {
      const twoDaysAgo = new Date();
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
      return twoDaysAgo;
    }
    return undefined;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValuesFormik}
      onSubmit={async (values: IFormikValues, formikHelpers) => {
        try {
          setLoading(true);

          const fromDefault = values?.scheduleDate?.[0] ?? getToday();
          const toDefault = values?.scheduleDate?.[1] ?? getDateInOneMonth();

          const payload = {
            customers: values?.customer?.map((item) => item?.value) ?? [],
            products: values?.product?.map((item) => item?.value) ?? [],
            filiais: values?.filial?.map((item) => item?.value) ?? [],

            from: formatDateYYYYMMDD(fromDefault),
            to: formatDateYYYYMMDD(toDefault),
            limit: limitRowsPerPageDefault,
          };

          await handleRoadLoadMethod.fetchAllWithFilter(payload);
          handleCloseDrawer();
        } catch (error) {
          throw error;
        } finally {
          setLoading(false);
        }
      }}
    >
      {(formik) => {
        const handleChangeCustomer = (e) => {
          formik.setFieldValue(VALUESNAME.CUSTOMER, e);
          handleValuesFormik({ ...initialValuesFormik, customer: e });
        };

        const handleChangeProduct = (e) => {
          formik.setFieldValue(VALUESNAME.PRODUCT, e);
          handleValuesFormik({ ...initialValuesFormik, product: e });
        };

        const handleChangeFilial = (e) => {
          formik.setFieldValue(VALUESNAME.FILIAL, e);
          handleValuesFormik({ ...initialValuesFormik, filial: e });
        };

        const handleChangeScheduleDate = (e) => {
          formik.setFieldValue(VALUESNAME.SCHEDULEDATE, e);
          handleValuesFormik({ ...initialValuesFormik, scheduleDate: e });
        };

        const handleResetAll = (e) => {
          if (initialValuesFormikDefault === initialValuesFormik) return;
          formik.handleReset(e);
          handleValuesFormik(initialValuesFormikDefault);
          handleRoadLoadMethod.resetFilter();
        };

        return (
          <Form>
            <Grid container sx={{ gap: { xs: 2, lg: 3, xl: 4 } }}>
              <Grid item xs={12}>
                {loading?.customers ? (
                  <CircularProgress />
                ) : (
                  <TextFieldMultiSelect
                    name={VALUESNAME.CUSTOMER}
                    label="Cliente"
                    optionsSelected={formik?.values?.customer}
                    onChange={(e) => handleChangeCustomer(e)}
                    options={customerOptionsList}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <CalendarRange
                  name={VALUESNAME.SCHEDULEDATE}
                  label="Data ou agendamento"
                  value={[
                    formik?.values?.scheduleDate?.[0],
                    formik?.values?.scheduleDate?.[1],
                  ]}
                  onChange={(e) => handleChangeScheduleDate(e)}
                  onBlur={formik?.handleBlur}
                  minDate={checkTabSelected()}
                />
              </Grid>
              <Grid item xs={12}>
                {loading?.products ? (
                  <CircularProgress />
                ) : (
                  <TextFieldMultiSelect
                    name={VALUESNAME.PRODUCT}
                    label="Produtos"
                    options={productOptionsList}
                    optionsSelected={formik?.values?.product}
                    onChange={handleChangeProduct}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {loading?.centers ? (
                  <CircularProgress />
                ) : (
                  <TextFieldMultiSelect
                    name={VALUESNAME.FILIAL}
                    label="Filiais"
                    optionsSelected={formik?.values?.filial}
                    onChange={handleChangeFilial}
                    options={labelDuplicatas(filiaisOptionsList)}
                  />
                )}
              </Grid>
              <Grid item xs={12} sx={{ pt: 2 }}>
                <Grid
                  container
                  sx={{
                    justifyContent: "center",
                    gap: { xs: 3, lg: 2 },
                  }}
                >
                  <Grid item xs={12} lg={"auto"} sx={{ textAlign: "center" }}>
                    <Button
                      type="submit"
                      loading={load}
                      variant="contained"
                      sx={{ px: 5 }}
                      fullWidth
                    >
                      Filtrar
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={"auto"}>
                    <Button
                      onClick={(e) => handleResetAll(e)}
                      variant="outlined"
                      fullWidth
                    >
                      Limpar Filtro
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DrawerContentForm;
