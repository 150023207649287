import React, { ReactNode } from "react";
import {
  Box,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core";

interface ICardSales {
  icon: ReactNode;
  title?: ReactNode;
  subtitle: string;
}

export const CardSales: React.FC<ICardSales> = ({ ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Box display="flex" style={{ paddingRight: 22 }}>
      <Box className={classes.iconContainer}>{props.icon}</Box>
      <Box display="block">
        <Typography
          className={classes.title}
          variant={isMobile ? "subtitle1" : "h6"}
        >
          {props.title}
        </Typography>
        <Typography className={classes.subtitle}>{props.subtitle}</Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: 0,
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
      color: "#3E3D3D",
    },
    subtitle: {
      padding: 0,
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "20px",
      display: "flex",
      alignItems: "center",
      color: "#3E3D3D",
    },
    iconContainer: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      paddingTop: 14,
    },
  })
);
