import { CircularProgress, Typography, useTheme } from "@material-ui/core";
import { Button, ButtonProps, Fade, Grid, Modal, Paper } from "@mui/material";
import React from "react";

import ContainedButton from "@pages/Admin/Communications/resource/ContainedButton";
import TextButton from "@pages/Admin/Communications/resource/TextButton";

import { AttentionIcon, CloseIcon } from "@components/Icons";

interface ProductDeleteModalProps extends ButtonProps {
  open?: boolean;
  handleClose: () => void;
  onDeleteClick?: () => void;
  isLoading?: boolean;
}

const ProductDeleteModal: React.FC<ProductDeleteModalProps> = ({
  open = false,
  handleClose,
  onDeleteClick,
  isLoading = false,
}) => {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Paper
          sx={{
            padding: { xs: "0.8rem", sm: "1.5rem", md: "1.5rem", lg: "3rem 0" },
            borderRadius: 2,
            opacity: 0.9,
            backgroundColor: `${theme.palette.background.paper}`,
            boxShadow: theme.shadows[5],
            width: { xs: "95%", sm: "70%", md: "60%", lg: "40%" },
          }}
        >
          <Grid
            container
            className="modal-container"
            justifyContent={"center"}
            sx={{
              gap: { xs: 0.4, sm: 0.8, md: 1, lg: 4 },
            }}
          >
            <Grid item xs={12}>
              <Grid
                container
                sx={{ paddingTop: "8px" }}
                className="modal-CloseIcon-container"
                justifyContent={"right"}
              >
                <Button sx={{ padding: 1 }} onClick={() => handleClose()}>
                  <CloseIcon></CloseIcon>
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={9}>
              <Grid
                container
                className="modal-attentionIcon-container"
                textAlign={"center"}
                sx={{
                  border: "0px solid black",
                  gap: { xs: 0.6, sm: 1, md: 1.5, lg: 4 },
                }}
              >
                <Grid item xs={12}>
                  <AttentionIcon
                    sx={{ fontSize: { xs: 20, sm: 30, md: 40, lg: 100 } }}
                  ></AttentionIcon>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h5" style={{ fontWeight: 700 }}>
                    {" "}
                    Excluir produto?{" "}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 400,
                      color: "#3E3D3D",
                      fontSize: "12px",
                    }}
                  >
                    Você tem certeza que deseja excluir <br></br>o produto?
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    className="modal-groupButton-container"
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Grid item>
                      <TextButton onClick={handleClose}>
                        Voltar à página anterior
                      </TextButton>
                    </Grid>

                    <Grid item>
                      <ContainedButton
                        startIcon={
                          isLoading && (
                            <CircularProgress
                              style={{
                                height: 20,
                                width: 20,
                                color: theme.palette.background.paper,
                              }}
                            />
                          )
                        }
                        onClick={() => onDeleteClick && onDeleteClick()}
                      >
                        Excluir produto
                      </ContainedButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ProductDeleteModal;
