import ButtonLinkGeneric from "@components/ButtonLinkGeneric/ButtonLinkGeneric";
import StatusTag, {
  StatusTagType,
} from "@components/StatusTagGeneric/StatusTagGeneric";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Grid,
  createStyles,
  makeStyles,
  Typography,
  Box,
} from "@material-ui/core";
import {
  addValueToLocal,
  deleteRefIdinLocalStorage,
  getRefIdinLocalStorage,
} from "@utils/localStorageUtils";
import React, { useEffect, useState } from "react";
import AssistantIcon from "@material-ui/icons/Assistant";

export interface ITableReclamationColumn {
  option: string;
  align: "left" | "right" | "inherit" | "center" | "justify" | undefined;
  key: string;
}

interface ITableReclamationProps {
  tableHeaderColumn: ITableReclamationColumn[];
  tableData?: any;
  positionStatusTag?: number; // posição onde deve ser formatado como status tag
  positionID: number;
  // props são dependentes
  hasButtonSeeDetails?: boolean;
  linkRouteReclamation?: string;
  positionButtonSeeDetails?: number; // posição do botão ai se passar essa prop vai indicar para não exibir o ID
  isListAdmin: boolean;
}

const TableReclamation: React.FC<ITableReclamationProps> = ({ ...props }) => {
  const classes = useStyles();
  const refIDlocalStorage = getRefIdinLocalStorage("refIdReclamationCreated");
  const [showCircle, setShowCircle] = useState(true);

  const getTypeStatusTag = (statusValue) => {
    switch (statusValue) {
      case "IN_PROGRESS": {
        return StatusTagType.WARNING;
      }
      case "CLOSED": {
        return StatusTagType.SUCCESS;
      }
      default: {
        return StatusTagType.NONE;
      }
    }
  };

  const getStatusPTBR = (status: string) => {
    switch (status) {
      case "IN_PROGRESS": {
        return "Em andamento";
      }
      case "CLOSED": {
        return "Finalizado";
      }
      default: {
        return "Em andamento";
      }
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowCircle(false);
      deleteRefIdinLocalStorage("refIdReclamationCreated");
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <TableContainer className={classes.TableContainer}>
      <Table className={classes.Table}>
        <TableHead>
          <TableRow className={classes.TableRow}>
            {props.tableHeaderColumn.map((item) => (
              <TableCell
                key={item.key}
                align={item.align}
                className={classes.TableCellHead}
              >
                {item.option}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {props.tableData?.content?.map((item, index) => (
            <TableRow key={index}>
              {Object.values(item).map((value, subIndex) => (
                <React.Fragment key={subIndex}>
                  {subIndex !== props.positionStatusTag &&
                    subIndex !== props.positionButtonSeeDetails &&
                    subIndex !== props.positionID && (
                      <TableCell
                        className={classes.TableCellFromData}
                        align={
                          props.tableHeaderColumn.map((item) => item.align)[
                            subIndex
                          ]
                        }
                      >
                        {refIDlocalStorage !== value ? (
                          <Box> {value}</Box>
                        ) : (
                          !props.isListAdmin && (
                            <Grid container>
                              <Grid item xs={4}>
                                {value}
                              </Grid>
                              <Grid item xs={8}>
                                <Box
                                  className={
                                    showCircle
                                      ? classes.circleDraw
                                      : classes.hidden
                                  }
                                >
                                  <AssistantIcon color="primary"></AssistantIcon>
                                </Box>
                              </Grid>
                            </Grid>
                          )
                        )}
                      </TableCell>
                    )}
                  {subIndex === props.positionStatusTag && (
                    <TableCell
                      className={classes.TableCellFromData}
                      align={
                        props.tableHeaderColumn.map((item) => item.align)[
                          subIndex
                        ]
                      }
                    >
                      <StatusTag
                        text={getStatusPTBR(value + "")}
                        type={getTypeStatusTag(value)}
                      />
                    </TableCell>
                  )}
                </React.Fragment>
              ))}

              {props.hasButtonSeeDetails && (
                <TableCell className={classes.TableCellFromData}>
                  <ButtonLinkGeneric
                    link={props.linkRouteReclamation + item.id}
                    text="Ver detalhes"
                    onClick={() => {
                      addValueToLocal("reclamationClicked", item.id);
                    }}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
        {props.tableData?.total === 0 && (
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Typography
                style={{
                  textAlign: "center",
                  color: "#3E3D3D",
                  fontFamily: "DM Sans",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  marginTop: "40px",
                  marginBottom: "40px",
                }}
              >
                Não há resultado para a busca.
              </Typography>
            </Grid>
          </Grid>
        )}
      </Table>
    </TableContainer>
  );
};

export default TableReclamation;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circleDraw: {
      transition: "opacity 0.5s",
    },
    hidden: {
      opacity: 0,
    },
    TableContainer: {
      width: "100%",
      boxShadow: "none",
    },
    Table: {
      borderSpacing: "0px 20px",
      borderCollapse: "separate",
      marginBottom: 16,
    },
    TableRow: {
      background: "#fff",
      whiteSpace: "nowrap",
    },
    TableCell: {
      padding: "6px 16px 6px 16px",
      marginBottom: "20px",
    },
    TableCellHead: {
      fontWeight: "bold",
      whiteSpace: "nowrap",
      fontFamily: "Montserrat",
      "&:first-child": {
        paddingLeft: "2.5rem",
        borderBottomLeftRadius: "0.6rem",
        borderTopLeftRadius: "0.6rem",
      },
      "&:last-child": {
        borderBottomRightRadius: "0.6rem",
        borderTopRightRadius: "0.6rem",
      },
    },
    TableCellFromData: {
      background: "#fff",
      marginBottom: "10px",
      borderBottom: "none",
      fontFamily: "Montserrat",

      "&:first-child": {
        paddingLeft: "2.5rem",
        borderBottomLeftRadius: "0.6rem",
        borderTopLeftRadius: "0.6rem",
      },
      "&:last-child": {
        borderBottomRightRadius: "0.6rem",
        borderTopRightRadius: "0.6rem",
      },
    },
  })
);
