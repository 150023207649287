/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core";
import {
  Grid,
  Box,
  Checkbox,
  TextField,
  IconButton,
  Typography,
} from "@mui/material";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";

import { ArrowDownSelectIcon } from "@components/Icons";

export interface Option {
  value: string;
  label: string;
}

interface TextFieldMultiSelectProps {
  name?: string;
  index?: number | string;
  label?: string;
  options: Option[];
  optionsSelected?: Option[];
  onChange?: (e: Option[]) => void;
}

const TextFieldMultiSelect: React.FC<TextFieldMultiSelectProps> = ({
  options,
  optionsSelected,
  ...props
}) => {
  const theme = useTheme();

  const optionAll: Option = {
    label: "Selecionar Todos",
    value: "todos",
  };

  const condition = React.useMemo(() => {
    if (!optionsSelected) return [];
    return optionsSelected?.length === options?.length
      ? [optionAll, ...optionsSelected]
      : optionsSelected;
  }, [options, optionsSelected]);

  const [selectedOptions, setSelectedOptions] = useState<Option[]>(condition);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    setSelectAll(optionsSelected?.length === options?.length ? true : false);
  }, [optionsSelected, options]);

  const [isMenuOpen, setIsMenuOpen] = useState(() => {
    // Tente recuperar o valor de isMenuOpen do localStorage, use false se não estiver definido
    const storedIsMenuOpen = sessionStorage.getItem(
      `isMenuOpen-${props?.index ?? props?.name}`
    );

    return storedIsMenuOpen ? JSON.parse(storedIsMenuOpen) : false;
  });

  useEffect(() => {
    // Quando isMenuOpen mudar, atualize o localStorage
    if (isMenuOpen) {
      sessionStorage.setItem(
        `isMenuOpen-${props?.index ?? props?.name}`,
        JSON.stringify(isMenuOpen)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);

  useEffect(() => {
    // Atualize o estado local quando as propriedades 'optionsSelected' mudarem
    if (optionsSelected) {
      setSelectedOptions(optionsSelected);
    }
  }, [optionsSelected]);

  useEffect(() => {
    // Adicione um manipulador de evento beforeunload para limpar o sessionStorage
    const handleBeforeUnload = () => {
      // Limpe as variáveis no sessionStorage
      sessionStorage.removeItem(`isMenuOpen-${props?.index ?? props?.name}`);
    };

    // Registre o manipulador de evento quando o componente montar
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remova o manipulador de evento quando o componente desmontar
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [props?.index, props?.name]);

  const handleOptionChange = (_event) => {
    const optionDataAttribute = _event.target.getAttribute("option-data");
    const optionSelected = JSON.parse(optionDataAttribute); // Converte o atributo para um objeto JavaScript

    // Verifica se "Todos" esta contido na lista
    const isAllSelected = selectAll;

    //  Verifique se "Todos" foi selecionado
    const optionTodosIsSelected = optionSelected?.value === "todos";

    let optionsResult: Option[] = [];

    // Cenario 1 - Clicou em "Todos" e a opcao estava inicialmente desmarcada -  entao marca todas as opcoes disponives
    if (optionTodosIsSelected && !isAllSelected) {
      optionsResult = [...options];
    }

    // Cenario 2 - Clicou em "Todos"  e a opcao esta marcada - entao desmarca todas as opcoes da lista
    if (optionTodosIsSelected && isAllSelected) {
      optionsResult = [];
    }

    // Cenario 3 - Usuario nao clicou em "Todos"
    if (!optionTodosIsSelected) {
      // Cenario 3.1 - Verifica se o elemento que o usuario selecionou esta na lista

      if (selectedOptions?.some((op) => op?.value === optionSelected?.value)) {
        // opcao selecionada ja consta na lista entao deve remove-la
        optionsResult = selectedOptions?.filter(
          (op) => op?.value !== optionSelected?.value
        );
      } else {
        // opcao selecionada nao consta na lista entao deve inserir
        optionsResult = [...selectedOptions, optionSelected];
      }
    }

    setSelectedOptions(optionsResult);

    // remove a opcao "Todos" da lista (se existir) e envia para o componente pai
    props?.onChange &&
      props?.onChange(optionsResult?.filter((op) => op?.value !== "todos"));
  };

  const handleOpenMenu = () => {
    setIsMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const handleIconButtonMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const customRenderOptionsLabel = (options: Option[]) => {
    const optionsLabel = options.map((option) => {
      return option?.label;
    });
    return optionsLabel.join(" / ");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isMenuOpen &&
        !event.target.closest(".dropdown") &&
        !event.target.classList.contains("dropdown-option")
      ) {
        // O clique ocorreu fora do dropdown quando isOpen é verdadeiro
        setIsMenuOpen(false);
      }
    };

    // Adicione um event listener para detectar cliques fora do dropdown
    document.addEventListener("click", handleClickOutside);

    // Remova o event listener quando o componente for desmontado
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Autocomplete
      multiple
      id="multi-select"
      className="dropdown TextFieldMultiSelect"
      open={isMenuOpen}
      onOpen={handleOpenMenu}
      onClose={handleCloseMenu}
      options={[optionAll, ...options]}
      value={selectedOptions}
      onChange={(e) => handleOptionChange(e)}
      getOptionLabel={(option) => option?.label}
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <Grid container justifyContent={"end"}>
            <TextField
              {...params}
              className="TextField-select-custom"
              label={props.label} //"Selecione opções"
              variant="outlined"
              fullWidth
              sx={{
                "& svg": { display: "none" },
                "& .MuiAutocomplete-inputRoot": {
                  border: "0px solid blue",
                  padding: "1rem 0rem 1rem 1rem !important",
                  margin: 0,
                },
                "& .MuiInputLabel-shrink": {
                  fontWeight: 700,
                  fontSize: "1.4rem",
                  letterSpacing: "1.5px",
                  background: "white",
                },
                "& label": {
                  fontSize: "1.4rem",
                  fontFamily: theme.typography.fontFamily,
                  color: `rgba(0, 0, 0, 0.50)`,
                },
                "& fieldset": {
                  border: "1px solid rgba(114, 101, 81, 0.40)",
                  borderRadius: 2,
                },
                "& input": {
                  maxWidth: "min-content",
                  minWidth: "min-content",
                },
                "& .MuiOutlinedInput-input": {
                  fontSize: "1.3rem",
                  fontFamily: theme.typography.fontFamily,
                },
              }}
            />
            <IconButton
              onClick={handleIconButtonMenu}
              sx={{ position: "absolute", margin: "1rem 1.8rem 1rem 0rem" }}
            >
              <ArrowDownSelectIcon sx={{ fontSize: 14 }} />{" "}
            </IconButton>
          </Grid>
        );
      }}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          style={{ border: "0px solid red", cursor: "pointer" }}
          option-data={JSON.stringify(option)}
          className="dropdown-option"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              pointerEvents: "none",
            }}
          >
            <Checkbox
              style={{
                color: selected ? "red" : "inherit",
                pointerEvents: "none",
              }}
              checked={selected || selectAll}
            />
            <Typography
              variant="h6"
              sx={{
                fontFamily: theme.typography.fontFamily,
                pointerEvents: "none",
              }}
            >
              {option.label}
            </Typography>
          </div>
        </li>
      )}
      disableCloseOnSelect
      isOptionEqualToValue={(option: Option, value: Option) =>
        option?.value === value?.value
      }
      // renderiza os valores selecionados no componente textField
      renderTags={(value: Option[], getTagProps) => (
        <Box
          sx={{
            display: "flex",
            maxWidth: "88%", // Set maximum width
            whiteSpace: "nowrap", // Prevent text from wrapping
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: { xs: 12, lg: "1.2rem" },
              border: "0px solid blue",
              fontFamily: theme.typography.fontFamily,
              fontWeight: 500,
              padding: "1px",
              marginX: "8px",
              overflow: "hidden", // Hide overflow content
              textOverflow: "ellipsis", // Display ellipsis for overflow
            }}
          >
            {customRenderOptionsLabel(value)}
          </Typography>
        </Box>
      )}
    />
  );
};

export default TextFieldMultiSelect;
