import React, { useCallback } from "react";

import { Form, Formik } from "formik";
import * as yup from "yup";

import {
  Avatar,
  Box,
  Button,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";

import SelectFieldCustom from "@components/SelectFieldCustom";
import { CloseIcon, MarketIcon } from "@components/Icons";
import { StyledButton } from "@components/StyledButton";
import CalendarRange from "@components/CalendarRange";
import SelectCustom from "@components/SelectCustom";

import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useHomeUserContext } from "@context/HomeUserContext";

import { Types } from "@ioc/types";
import {
  capitalizeFirstLetterFullString,
  formatCurrencyThreeDigits,
  formatCurrencyTwoDigits,
  formatDate,
  formatTimeRefuel,
  formatToIsoDate,
  maskCNPJ,
} from "@utils/index";

import {
  IGetYourSalesService,
  IProduct,
  IYourSalesReduce,
  IYourSalesRequest,
} from "@modules/yourSales/models/IGetYourSalesService";

interface IEmployeeReduce {
  id: string;
  description: string;
}

interface YourSalesFilterFormikProps {
  labelhead?: string;
  avatar?: React.ReactElement<any, any> | null;
  handle?: () => void;
  handleUpdateSales?: (yourSales: IYourSalesReduce[]) => void;
}

const YourSalesFilterFormik: React.FC<YourSalesFilterFormikProps> = ({
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const { documentList } = useHomeUserContext();
  let itemSelected = documentList.filter((item) => item.isSelected)[0];
  let documentItemSelect = itemSelected?.cnpj;

  const [hasloadingButtom, setHasLoadingButtom] = React.useState(false);
  const [hasLoadingForm, setHasLoadingForm] = React.useState(true);
  const [products, setProducts] = React.useState<IProduct[]>();
  const [employees, setEmployees] = React.useState<IEmployeeReduce[]>([]);

  const iocContext = useIoCContext();
  const yourSalesService = iocContext.serviceContainer.get<
    IGetYourSalesService
  >(Types.YourSales.IGetYourSalesService);

  const getYourSalesProducts = useCallback(async () => {
    try {
      setHasLoadingForm(true);
      const products = await yourSalesService.getYourSalesProduct();
      setProducts(products);
      setHasLoadingForm(false);
    } catch (error) {
      throw error;
    }
  }, [yourSalesService]);

  const getYourSalesEmployees = useCallback(async () => {
    try {
      setHasLoadingForm(true);
      let employees: IEmployeeReduce[] = [];
      if (documentItemSelect) {
        let employeesResponse = await yourSalesService.getYourSalesEmployee(
          documentItemSelect
        );
        const employeeReduces = employeesResponse.content.map((item) => {
          return { id: item.id, description: item.name };
        });
        employees = employeeReduces;
      }
      setEmployees(employees);
      setHasLoadingForm(false);
    } catch (error) {}
  }, [documentItemSelect, yourSalesService]);

  React.useEffect(() => {
    getYourSalesProducts();
    getYourSalesEmployees();
  }, [getYourSalesProducts, getYourSalesEmployees]);

  const getYourSalesWithFilter = async (data: IYourSalesRequest) => {
    setHasLoadingButtom(true);
    const yourSalesFilterResponse = await yourSalesService.getYourSales(data);
    setHasLoadingButtom(false);
    return yourSalesFilterResponse;
  };

  const defaultStartDate = formatDate(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
  );
  const defaultEndDate = formatDate(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  );

  const validationSchema = yup.object().shape({
    date: yup
      .array()
      .of(yup.date().nullable(false).typeError("Insira uma data válida"))
      .required("Este campo é obrigatório"),
  });

  return (
    <>
      {hasLoadingForm ? (
        <Grid
          container
          style={{ justifyContent: "center", paddingTop: "25rem" }}
        >
          <LinearProgress
            variant="indeterminate"
            className={classes.container}
            style={{ width: "8rem" }}
          />
        </Grid>
      ) : (
        <Formik
          initialValues={{
            date: [null, null],
            products: "",
            employees: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            const salesFilterRequest = {
              document: documentItemSelect,
              employee_id: values.employees.length > 0 ? values.employees : "",
              product_id: values.products.length > 0 ? values.products : "",
              enddate: values.date[1]
                ? formatDate(values.date[1])
                : defaultEndDate,
              startdate: values.date[0]
                ? formatDate(values.date[0])
                : defaultStartDate,
            } as IYourSalesRequest;

            const yourSalesWithFilter = await getYourSalesWithFilter(
              salesFilterRequest
            );

            let yourSalesWithFilterReduce: IYourSalesReduce[] = [];
            if (
              yourSalesWithFilter &&
              yourSalesWithFilter.content &&
              yourSalesWithFilter.content.length > 0
            ) {
              yourSalesWithFilter.content.forEach((item, index) => {
                yourSalesWithFilterReduce[index] = {
                  vid: item.vid,
                  employee_id: item.employee?.id ? item.employee?.id : "-",
                  employee_name: item.employee?.name
                    ? item.employee?.name
                    : "-",
                  dt_transaction: formatToIsoDate(item.dt_transaction!),
                  tank: item.tank,
                  product_id: item.product?.id ? item.product.id : "-",
                  product_description: item.product?.description
                    ? item.product.description
                    : "-",
                  refuel_volume: item.refuel_volume,
                  refuel_unit_price: formatCurrencyThreeDigits(
                    item.refuel_unit_price!
                  ),
                  refuel_value: formatCurrencyTwoDigits(item.refuel_value!),
                  refuel_time: formatTimeRefuel(item.refuel_time!),
                };
              });
            } else {
              const yourSalesEmpty: IYourSalesReduce[] = [
                {
                  vid: "-",
                  employee_name: "-",
                  dt_transaction: "-",
                  tank: "-",
                  product_description: "-",
                  refuel_volume: "-",
                  refuel_unit_price: "-",
                  refuel_value: "-",
                  refuel_time: "-",
                },
              ];
              yourSalesWithFilterReduce = yourSalesEmpty;
            }

            props.handleUpdateSales &&
              props.handleUpdateSales(yourSalesWithFilterReduce);
            actions.resetForm();
          }}
        >
          {(propsformik) => {
            return (
              <Form>
                <Grid container className={classes.container}>
                  <Grid item className={classes.boxCloseIcon}>
                    <Button onClick={props.handle}>
                      <CloseIcon />
                    </Button>
                  </Grid>

                  <Grid item>
                    <Typography className={classes.title}>
                      {props.labelhead}
                    </Typography>
                  </Grid>

                  <Grid item className={classes.boxAvatarIcon}>
                    <Avatar className={classes.avatar}>{props.avatar}</Avatar>
                  </Grid>

                  <Grid
                    container
                    spacing={3}
                    className={classes.gridContainerInput}
                  >
                    <Grid item>
                      <CalendarRange
                        name="date"
                        label="Data ou período"
                        value={[
                          propsformik.values.date[0],
                          propsformik.values.date[1],
                        ]}
                        onChange={propsformik.handleChange}
                        onBlur={propsformik.handleBlur}
                      />
                    </Grid>

                    {propsformik.errors.date && propsformik.touched.date ? (
                      <Typography
                        variant="caption"
                        style={{
                          color: theme.palette.error.light,
                          marginTop: -12,
                          marginLeft: 24,
                        }}
                      >
                        O campo data é obrigatório*
                      </Typography>
                    ) : (
                      <></>
                    )}

                    <Grid item>
                      <SelectFieldCustom
                        name="products"
                        label="Produto"
                        value={propsformik.values.products}
                        onChange={propsformik.handleChange}
                        onBlur={propsformik.handleBlur}
                        type="search"
                        variant="outlined"
                        objects={products}
                      />
                    </Grid>

                    <Grid item>
                      <SelectCustom
                        name="employees"
                        label="Funcionários"
                        value={propsformik.values.employees}
                        options={employees}
                        getOptionLabel={(value) =>
                          `${value.description}:${value.id}`
                        }
                        onChange={propsformik.handleChange}
                        onBlur={propsformik.handleBlur}
                      />
                    </Grid>

                    <Grid item className={classes.gridBoxMarket}>
                      <Box className={classes.boxMarketIcon}>
                        <MarketIcon />
                      </Box>
                      <Box style={{ marginTop: 4 }}>
                        <Typography
                          variant="subtitle2"
                          style={{ fontWeight: 700 }}
                        >
                          {capitalizeFirstLetterFullString(itemSelected.label)}
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{ fontWeight: 400, opacity: 0.5 }}
                        >
                          {maskCNPJ(itemSelected.cnpj)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  {hasloadingButtom ? (
                    <LinearProgress
                      className={classes.linearprogress}
                      variant="indeterminate"
                    />
                  ) : (
                    <Grid item>
                      <StyledButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ color: "white" }}
                        loading={propsformik.isSubmitting}
                      >
                        Filtrar
                      </StyledButton>
                    </Grid>
                  )}
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default YourSalesFilterFormik;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "end",
      width: "auto",
      overflowX: "hidden",
      margin: "3rem",
    },
    boxCloseIcon: {
      alignSelf: "end",
      margin: "1rem 3rem 0 0",
    },
    title: {
      color: theme.palette.text.primary,
      fontWeight: 700,
      fontSize: "2.2rem",
      margin: "0 0 0 4rem",
    },
    boxAvatarIcon: {
      alignSelf: "center",
      marginTop: "8rem",
      marginBottom: "8rem",
      textAlign: "center",
    },
    avatar: {
      height: "fit-content",
      width: "fit-content",
      marginLeft: "auto",
      marginRight: "auto",
    },
    gridContainerInput: {
      flexDirection: "column",
      width: "auto",
      margin: "0 3rem 0 3rem",
    },
    gridBoxMarket: {
      display: "flex",
      justifyContent: "center",
      margin: "5.5rem 0 5.5rem 0",
    },
    boxMarketIcon: {
      height: "fit-content",
      width: "fit-content",
      marginRight: "1.5rem",
      marginTop: "0.6rem",
    },
    button: {
      color: theme.palette.primary.main,
      textTransform: "initial",
      fontWeight: 900,
      marginLeft: "5rem",
      marginBottom: "1rem",
      marginTop: "1rem",
    },
    linearprogress: {
      width: "25%",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "1rem",
    },
  })
);
