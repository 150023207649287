import SelectCustomer, { SelectVariant } from "@components/Select";
import { usePerformanceManagement } from "@context/PerformanceManagementContext";
import {
  Box,
  Grid,
  ListItemIcon,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { ExpandMoreOutlined } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import {
  OptionsSelectSalesVolume,
  OptionsSelectSalesVolumePeriod,
} from "../Model/OptionsSelectSaleVolume";
import {
  ISelectType,
  transformData,
} from "../UtilsPerfomanceManagement/utilsPerformanceManagment";
import CustomSwitch from "@components/CustomSwitch";

const useStyles = makeStyles((theme) =>
  createStyles({
    inputLabel: {
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "16px",
    },

    titleSelected: {
      fontSize: "1.4rem",
      fontFamily: "Montserrat",
      fontWeight: 600,
      color: "rgb(88, 89, 91)",
    },

    optionsSelectCustomer: {
      fontSize: "1.4rem",
      fontFamily: "Montserrat",
      color: "#3E3D3D",
    },

    formControl: {
      borderRadius: "8px",
      height: "44px",
      outline: "none",
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #D91F05",
        borderRadius: "8px",
      },
      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
        borderRadius: "8px",
      },
    },

    customSelect: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      marginTop: "0",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontFamily: "Montserrat",
      fontSize: "16px",
      borderRadius: "8px",

      fontWeight: 400,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#E8E9EB",
        borderRadius: "8px",
      },

      "& .MuiSvgIcon-root": {
        fill: "#D91F05",
        fontSize: "2.4rem",
      },
      "&:focus": {
        backgroundColor: "#FFFF",
        border: "2px solid #E8E9EB",
      },
      "&:hover": {
        backgroundColor: "#E8E9EB",
      },
    },

    selectedAll: {
      border: "2px solid blue",
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ISelectToFilterSaleVolume {
  showPeriod?: boolean;
}

const SelectToFilterSaleVolume: React.FC<ISelectToFilterSaleVolume> = ({
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const classes = useStyles();

  const {
    salesVolumePeriodSelected,
    setSalesVolumePeriodSelected,
    salesVolumeSelected,
    setSalesVolumeSelected,
    product,
    selectedProducts,
    setSelectedProducts,
    setShowByProduct,
    showByProduct,
  } = usePerformanceManagement();

  const handleChangePeriod = (selectedOption) => {
    setSalesVolumePeriodSelected(selectedOption);
  };

  const handleChangeSalesVolume = (selectedOption) => {
    setSalesVolumeSelected(selectedOption);
  };
  const handleSwitchChange = (status: boolean) => {
    setShowByProduct(!status);
  };

  const OptionsProductFormated: ISelectType[] = transformData(product);

  const isAllSelected =
    OptionsProductFormated.length > 0 &&
    selectedProducts.length === OptionsProductFormated.length;

  const handleChangeProduct = (event) => {
    const value = event.target.value;

    if (value[value.length - 1] === "all") {
      setSelectedProducts(
        isAllSelected
          ? []
          : OptionsProductFormated.map((option) => option.value)
      );
      return;
    }

    setSelectedProducts(value);
  };

  return (
    <>
      <Grid
        style={{
          display: isMobile ? "block" : "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <CustomSwitch
          customColor="#D91F05"
          label="Visão totalizada"
          labelPosition="left"
          handle={handleSwitchChange}
        />
        {props.showPeriod && (
          <SelectCustomer
            variant={SelectVariant.STANDARD}
            value={salesVolumePeriodSelected}
            options={OptionsSelectSalesVolumePeriod}
            isDisabled={false}
            getOptionItemLabel={(period) => `${period.label}`}
            onChange={handleChangePeriod}
            getSelectedOptionLabel={(period) => `${period.label}`}
            emptyOptionsText="Lista vazia"
          />
        )}
        {!product.length ? (
          <Skeleton
            variant="rect"
            width={"179px"}
            height={"42px"}
            style={{ marginLeft: "1rem", borderRadius: "5px" }}
          />
        ) : (
          <FormControl
            className={classes.formControl}
            variant="outlined"
            style={{
              display: !showByProduct ? "none" : "flex",
              width: isMobile ? "100%" : "195px",
              paddingLeft: isMobile ? "0px" : "1rem",
              marginTop: isMobile ? "20px" : "0px",
              marginBottom: isMobile ? "20px" : "0px",
            }}
          >
            {!selectedProducts.length && (
              <InputLabel
                id="mutiple-select-label"
                className={classes.inputLabel}
              >
                Produto
              </InputLabel>
            )}

            <Select
              labelId="mutiple-select-label"
              multiple
              value={selectedProducts}
              onChange={handleChangeProduct}
              IconComponent={ExpandMoreOutlined}
              className={classes.customSelect}
              MenuProps={MenuProps}
              renderValue={(selected) => {
                const selectedValues = selected as string[];
                const isAllSelected =
                  selectedValues.length === OptionsProductFormated.length;

                return isAllSelected ? (
                  <Typography className={classes.titleSelected}>
                    Todos os Produtos
                  </Typography>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {selectedValues.map((value, index) => (
                      <Typography key={index} className={classes.titleSelected}>
                        {
                          OptionsProductFormated.find(
                            (item) => item.value === value
                          )?.label
                        }
                        {index < selectedValues.length - 1 && ", "}
                      </Typography>
                    ))}
                  </div>
                );
              }}
            >
              <MenuItem
                value="all"
                classes={{
                  root: isAllSelected ? "selectedAll" : "",
                }}
                style={{
                  backgroundColor: isAllSelected ? "#EBEBEB" : "",
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={isAllSelected}
                    style={{ color: "#D91F05" }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography className={classes.optionsSelectCustomer}>
                    Todos os Produtos
                  </Typography>
                </ListItemText>
              </MenuItem>
              {OptionsProductFormated.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  style={{
                    backgroundColor:
                      selectedProducts.indexOf(option.value) > -1
                        ? "#EBEBEB"
                        : "",
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      style={{ color: "#D91F05" }}
                      checked={selectedProducts.indexOf(option.value) > -1}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography className={classes.optionsSelectCustomer}>
                      {option.label}
                    </Typography>
                  </ListItemText>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Box style={{ paddingLeft: isMobile ? "0px" : "1rem" }}>
          <SelectCustomer
            variant={SelectVariant.STANDARD}
            value={salesVolumeSelected}
            options={OptionsSelectSalesVolume}
            isDisabled={false}
            getOptionItemLabel={(sales) => `${sales.label}`}
            onChange={handleChangeSalesVolume}
            getSelectedOptionLabel={(sales) => `${sales.label}`}
            emptyOptionsText="Lista vazia"
          />
        </Box>
      </Grid>
    </>
  );
};

export default SelectToFilterSaleVolume;
