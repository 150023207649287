import React from "react";
import TextField, { OutlinedTextFieldProps } from "@material-ui/core/TextField";
import { Theme, createStyles, makeStyles } from "@material-ui/core";

interface Props extends OutlinedTextFieldProps {
  children?: React.ReactNode;
  inputRef?: React.RefObject<HTMLInputElement>;
  label?: string;
  name: string;
  value: string;
}

const PhoneTextField: React.FC<Props> = ({ children, ...props }) => {
  const classes = useStyles();

  function formatPhoneNumber(_phoneNumber: string) {
    const phoneNumberRegex = /(\d{2})(\d{4,5})(\d{4})/;
    const matches = _phoneNumber.match(phoneNumberRegex);
    if (matches) {
      const phoneNumberFormated = `(${matches[1]}) ${matches[2]}-${matches[3]}`;
      return phoneNumberFormated;
    }
    return _phoneNumber;
  }

  const handleOnChange = (event) => {
    const value = event.target.value.replace(/[^\d]/g, ""); // impede a entrada de letras
    event.target.value = value;
    props.onChange && props.onChange(event);
  };

  return (
    <TextField
      {...props}
      type="tel"
      name={props.name}
      label={props.label}
      className={classes.root}
      value={props.value}
      onChange={handleOnChange}
      InputProps={{ value: formatPhoneNumber(props.value) }}
      variant={props.variant}
      fullWidth
    />
  );
};

export default PhoneTextField;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& input": {
        boxShadow: "0px 0px 0px 30px white inset",
      },
      "& label": {
        // Controle de estilo antes de digitar
        opacity: 0.6,
        fontSize: "1.4rem",
      },
      "& .MuiInputBase-root": {
        borderRadius: "8px",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `2px solid #E87969 `, // Controla cor da borda durante Digitacao "#FFB800"
      },
      "& .MuiInputLabel-shrink": {
        // Controle de estilo da "label" apos campo digitado // -> Equivalencia ->  "& .Mui-focused"
        fontSize: "1.5rem",
        opacity: 1,
        color: theme.palette.text.primary,
        fontWeight: 700,
      },
      "& .MuiInputBase-input": {
        color: theme.palette.text.primary,
        fontWeight: 500,
      },
      "& .Mui-error.Mui-focused.MuiFormHelperText-contained": {
        // Esconde a mensagem de erro enquanto usuario estiver digitando
        opacity: 0,
      },
    },
  })
);
