import React from "react";
import { Grid } from "@mui/material";

import Layout from "@components/Layout";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Typography } from "@material-ui/core";

interface Props {
  children?: React.ReactNode;
}

const NotFoundPage: React.FC<Props> = () => {
  return (
    <Layout disableToolbar={true} disableToolbarMobile={true}>
      <Grid
        container
        className="NotFoundPage-container"
        sx={{
          border: "0px solid red",
          mt: 1,
          height: { xs: "50vw", lg: "20vw" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={5}
          lg={4}
          xl={3.2}
          sx={{ p: 1, border: "0px solid black" }}
        >
          <Grid
            container
            sx={{
              gap: 1,
              p: 1,
              border: "0px solid red",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Grid item>
              <WarningAmberOutlinedIcon
                className="WarningAmberOutlinedIcon"
                fontSize="large"
                sx={{
                  fontSize: { xs: "4rem !important", lg: "10rem !important" },
                  color: "black",
                }}
              />
            </Grid>
            <Grid item sx={{ border: "0px solid blue", color: "red" }}>
              <Typography variant={"h6"}>
                <b>
                  Página não encontrada ou você não possui acesso a este
                  recurso.
                </b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default NotFoundPage;
