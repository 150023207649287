import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { styled } from "@mui/system";
interface TittleAndSubTitleProps {
  title: string;
  subTitle?: string;
  inLine?: boolean;
  textAlign?: "left" | "center" | "right";
  color?: string;
  opacity?: string;
  paddingTop?: string;
  paddingRight?: string;
  lineHeight?: string;
}

const TittleAndSubTitle: React.FC<TittleAndSubTitleProps> = ({ ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));

  const StyledTypographyTitle = styled(Typography)(() => ({
    fontWeight: "bold",
    fontFamily: "'Montserrat', sans-serif",
    fontSize: isMobile ? "11px" : isTablet ? "14px" : "16px",
    display: "flex",
    alignItems: "center",
    color: props.color || "#3E3D3D",
    textAlign: props.textAlign || "left",
    opacity: props.opacity || "",
    paddingTop: props.paddingTop || "",
  }));

  const StyledTypographySubTitle = styled(Typography)(() => ({
    fontFamily: "'Montserrat', sans-serif",
    fontStyle: "normal",
    fontSize: isMobile ? "11px" : isTablet ? "14px" : "16px",
    display: "flex",
    alignItems: "center",
    color: props.color ? props.color : "#3E3D3D",
    textAlign: props.textAlign,
    opacity: props.opacity || "",
    paddingTop: props.paddingTop || "",
    lineHeight: props.lineHeight || "",
  }));

  return (
    <Box sx={{ paddingRight: props.paddingRight || "" }}>
      {props.inLine ? (
        <StyledTypographyTitle>
          {props.title}
          <Box
            component="span"
            sx={{ fontWeight: "normal", paddingLeft: "10px" }}
          >
            {props.subTitle}&nbsp;
          </Box>
        </StyledTypographyTitle>
      ) : (
        <>
          <StyledTypographyTitle>{props.title}</StyledTypographyTitle>
          <StyledTypographySubTitle>{props.subTitle}</StyledTypographySubTitle>
        </>
      )}
    </Box>
  );
};

export default TittleAndSubTitle;
