import BreadcrumbCustom, {
  BreadcrumbChild,
} from "@components/BreadcrumbCustom";
import { HomeIcon } from "@components/Icons";
import LayoutDashPosh from "@components/LayoutDashPosh";
import TableGeneric from "@components/TableInstantReward";
import ROUTES from "@config/routes";
import { DashPoshProvider } from "@context/DashPoshContext";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import { Types } from "@ioc/types";
import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { IGetCustomersMarketingPlanDTO } from "@modules/user/dtos/IGetCustomersMarketingPlanDTO";
import { IGetDetailsInstantRewardPoshService } from "@modules/user/models/IGetDetailsInstantRewardPoshService";
import { IListCustomersMarketingPlanService } from "@modules/user/models/IListCustomersMarketingPlanService";
import { IDetailsInstantRewardPosh } from "@utils/interfaces";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(({ ...theme }) =>
  createStyles({
    containerDash: {
      padding: "2.4rem 2.4rem",
      height: "100%",
      overflowY: "hidden",
      [theme.breakpoints.up("sm")]: {
        padding: "2.4rem 4rem",
        overflowY: "hidden",
      },
    },
    containerCardsDash: {
      whiteSpace: "nowrap",
      overflowX: "auto",
      alignItems: "center",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "&::-webkit-overflow-scrolling": "touch",
    },
    rootAutoCompleteInput: {
      backgroundColor: "#fff",
      borderRadius: 8,
      height: "4rem",
    },
    removeBorder: { border: "none" },
    containerChartVol: {
      display: "flex",
      flexDirection: "column",
      paddingTop: "4rem",
      paddingBottom: "4rem",
      [theme.breakpoints.up("sm")]: { flexDirection: "row" },
    },
    chartVol: {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        width: "50%",
        marginRight: "4rem",
      },
    },
    chartVolProduct: {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        width: "50%",
      },
    },

    cardConsumers: {
      backgroundColor: "white",
      borderRadius: "5px",
      borderBottom: "3px solid #1A74CD",
      padding: "2.4rem",
      display: "flex",
      alignItems: "center",
      width: "24rem",
      marginRight: "4rem",
      height: "16rem",
      [theme.breakpoints.up("sm")]: { width: "38rem", padding: "3.2rem" },
    },
    titleCard: {
      fontSize: "2.4rem",
      fontWeight: "bold",
      [theme.breakpoints.up("sm")]: { fontSize: "3.2rem" },
    },
    descriptionCard: {
      color: "#B0C1E1",
      paddingBottom: "1.2rem",
      fontSize: "1rem",
      lineHeight: "0.4rem",
      [theme.breakpoints.up("sm")]: { fontSize: "1.6rem" },
    },
    containerWithImage: {
      width: "4rem",
      height: "4rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "5px",
      [theme.breakpoints.up("sm")]: { width: "5.2rem", height: "5.2rem" },
    },
    table: {
      "&:last-child td, &:last-child th": { border: 0 },
    },
  })
);

const breadcrumbData: BreadcrumbChild[] = [
  {
    isIcon: true,
    link: ROUTES.USER_ROUTES.DASH_POSH,
    icon: <HomeIcon style={{ fontSize: 19 }} />,
  },
  {
    label: "Prêmios entregues",
  },
];

const InstantRewardPageProvider = () => {
  const userState = useUserState();
  const companiesId = useParams();
  const classes = useStyles();
  const [cnpj, setCnpj] = useState<string[]>([]);
  const [customers, setCustomers] = useState<IGetCustomersMarketingPlanDTO[]>(
    []
  );
  const [instantReward, setInstantReward] = useState<
    IDetailsInstantRewardPosh[]
  >([]);

  const [loadingInstantReward, setLoandigInstantReward] = useState(true);

  const [, setCustomersTransformString] = useState<string>();
  const iocContext = useIoCContext();

  const getCustomersService = iocContext.serviceContainer.get<
    IListCustomersMarketingPlanService
  >(Types.Users.IListCustomersMarketingPlanService);

  const getDetailsInstantReward = iocContext.serviceContainer.get<
    IGetDetailsInstantRewardPoshService
  >(Types.Users.IGetDetailsInstantRewardPoshService);

  const getCustomers = useCallback(async () => {
    try {
      // setLoadingPrice(true);
      if (userState.listCNPJ.length > 0) {
        const map = userState.listCNPJ.map((item) => {
          return item.CNPJ;
        });

        setCnpj(map);
      }
      const customers = await getCustomersService.execute(cnpj.toString());
      setCustomers(customers);
    } catch (error) {
      // enqueueSnackbar("Ocorreu um erro ao buscar preço do produto", {
      //   variant: "error",
      // });
    } finally {
      // setLoadingPrice(false);
    }
  }, [userState.listCNPJ, getCustomersService, cnpj]);

  const transformString = useCallback(() => {
    const customersString = customers.map((e) => `'${e.id}'`);
    setCustomersTransformString(customersString.toString());
  }, [customers]);

  const fetchDetailsInstantReward = useCallback(async () => {
    try {
      setLoandigInstantReward(true);
      if (companiesId) {
        const id = JSON.stringify(Object.values(companiesId));
        const idTransform = id.replace(/[[\]\\]/g, ""); //Remover [] do id
        const details = await getDetailsInstantReward.execute({
          action: "count-instant-reward-by-company",
          companies_id: idTransform.replace(/"/g, ""), //Replace para remover "" do id
        });
        setInstantReward(details);
      }
    } catch (error) {
    } finally {
      setLoandigInstantReward(false);
    }
  }, [getDetailsInstantReward, companiesId]);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  useEffect(() => {
    transformString();
  }, [transformString]);

  useEffect(() => {
    fetchDetailsInstantReward();
  }, [fetchDetailsInstantReward]);

  return (
    <LayoutDashPosh>
      <div className={classes.containerDash}>
        <div
          style={{
            padding: "1.6rem 1.6rem 0rem 0rem",
            display: "block",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "1.2rem",
          }}
        >
          <BreadcrumbCustom data={breadcrumbData} />
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "2.4rem",
              color: "#313B45",
              marginTop: "1.2rem",
            }}
          >
            Todos os prêmios entregues
          </Typography>
        </div>

        <div style={{ paddingTop: "2.4rem" }}>
          {instantReward && (
            <TableGeneric
              headers={{
                user: "Nome",
                phone: "Telefone",
                document: "Documento",
                taxReceipt: "Data da compra",
                company: "Empresa",
                cnpj: "CNPJ",
                resource: "Ver nota",
              }}
              items={instantReward}
              rowsPerPage={10}
              loading={loadingInstantReward}
            />
          )}
        </div>
      </div>
    </LayoutDashPosh>
  );
};

const InstantRewardPage: React.FC = () => {
  return (
    <DashPoshProvider>
      <InstantRewardPageProvider />
    </DashPoshProvider>
  );
};

export default InstantRewardPage;
