import "react-datepicker/dist/react-datepicker.css";

import React, { RefObject, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";

import { getMonth, getYear } from "date-fns";
import { useFormikContext } from "formik";
import moment from "moment";

import { ArrowLeftIcon, ArrowRightIcon, CalendarIcon } from "@components/Icons";
import {
  FormGroup,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

const monthsBR = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const daysBR = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];

const ptbr: Locale = {
  localize: {
    ordinalNumber: (...args: Array<any>) => "",
    era: (...args: Array<any>) => "",
    quarter: (...args: Array<any>) => "",
    day: (n: any) => daysBR[n],
    month: (n: any) => monthsBR[n],
    dayPeriod: (...args: Array<any>) => "",
  },
  formatLong: {
    date: () => "dd/mm/yyyy",
    time: (...args: Array<any>) => "",
    dateTime: (...args: Array<any>) => "",
  },
};

interface CalendarRangeProps {
  name: string;
  label: string;
  value?: [Date | null, Date | null] | null | undefined;
  clearValueCalendar?: boolean;
  marginTopTextField?: string;
  onChange: (dateRange: [Date | null, Date | null] | null) => void;
  onBlur: (e: React.FocusEvent<any>) => void;
  minDate?: Date | null | undefined;
}

const CalendarRange: React.FC<CalendarRangeProps> = ({ ...props }) => {
  const theme = useTheme();
  const { setFieldValue } = useFormikContext();
  const classes = useStyles();

  const [dateRange, setDateRange] = React.useState([new Date(), null]);
  const [startDate, endDate] = dateRange;

  const [isOpen, setIsOpen] = React.useState(false);
  const datePickerRef: RefObject<HTMLDivElement> = useRef(null);

  const isMobile = useMediaQuery(theme?.breakpoints?.only("xs"));

  useEffect(() => {
    if (props.clearValueCalendar) {
      setDateRange([null, null]);
    }

    if (props?.value?.[0] === null) {
      setDateRange([new Date(), null]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clearValueCalendar, props?.value]);

  const handleChange = (date: [Date | null, Date | null]) => {
    setDateRange(date);
    setFieldValue(props.name, date);
    props.onChange(date);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleTextFieldChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    // Assuming you are getting the date range value as a string from the TextField
    // You'll need to convert it back to the [Date | null, Date | null] format
    // based on your specific date format used in the TextField
    // For example, if the date format is "DD/MM/YYYY à DD/MM/YYYY"
    const dateRangeString = event.target.value;
    const [startDateStr, endDateStr] = dateRangeString.split(" à ");
    const startDate = startDateStr
      ? moment(startDateStr, "DD/MM/YYYY").toDate()
      : null;
    const endDate = endDateStr
      ? moment(endDateStr, "DD/MM/YYYY").toDate()
      : null;
    const dateRange: [Date | null, Date | null] = [startDate, endDate];
    props.onChange(dateRange);
  };

  const dateFormat = (startDate: Date | null, endDate: Date | null) => {
    const startDatFormat = moment(startDate).format("DD/MM/YYYY");
    const endDateFormat = moment(endDate).format("DD/MM/YYYY");
    return `${startDatFormat} à ${endDateFormat}`;
  };

  function range(start: number, end: number) {
    return Array.from({ length: end - start }, (v, k) => k + start);
  }

  const years = range(2022, 2031).sort((a, b) => b - a);

  const CustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    changeYear,
    changeMonth,
  }) => {
    if (!date) {
      // Handle the case when date is undefined
      return null;
    }

    return (
      <div
        className="react-datepicker__custom-header"
        style={{
          margin: 10,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <select
          className="react-datepicker__selectyears--custom"
          value={getYear(date)}
          onChange={({ target: { value } }) => changeYear(parseInt(value))}
        >
          {/** react-datepicker__option */}
          {/** react-datepicker__month-dropdown */}
          {years.map((option) => (
            <option
              key={option}
              value={option}
              className="react-datepicker__option"
              style={{
                fontFamily: theme.typography.fontFamily,
                fontWeight: getYear(date) === option ? 700 : 500,
                color: theme.palette.primary.highlightedplus,
                padding: "5px 0",
              }}
            >
              {option}
            </option>
          ))}
        </select>

        <div className="react-datepicker__boxselectmonths--custom">
          <button
            type="button"
            className="react-datepicker__button--custom"
            onClick={decreaseMonth}
            style={{ cursor: "pointer" }}
            disabled={prevMonthButtonDisabled}
          >
            <ArrowLeftIcon />
          </button>

          <select
            className="react-datepicker__selectmonths--custom"
            value={monthsBR[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(monthsBR.indexOf(value))
            }
          >
            {monthsBR.map((option) => (
              <option
                key={option}
                value={option}
                style={{
                  fontFamily: theme.typography.fontFamily,
                  fontWeight: monthsBR[getMonth(date)] === option ? 700 : 500,
                  color: theme.palette.primary.highlightedplus,
                  padding: "5px 0",
                }}
              >
                {option}
              </option>
            ))}
          </select>

          <button
            className="react-datepicker__button--custom"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            type="button"
            style={{ cursor: "pointer" }}
          >
            <ArrowRightIcon />
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datePickerRef]);

  return (
    <FormGroup ref={datePickerRef}>
      <TextField
        type="text"
        id="outlined-basic"
        variant="outlined"
        name={props.name}
        label={props.label}
        onClick={handleClick}
        onChange={handleTextFieldChange}
        onBlur={props.onBlur}
        autoComplete="off"
        value={
          props.value && props.value[1] !== null
            ? dateFormat(props.value[0], props.value[1])
            : ""
        }
        className={classes.textfield}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <CalendarIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputProps={{
          style: {
            WebkitBoxShadow: "0 0 0 1000px white inset",
            fontSize: isMobile ? "1.2rem" : "1.4rem",
            height: "100%",
          },
        }}
        fullWidth
      />

      <div
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 3,
            marginTop: props.marginTopTextField ? props.marginTopTextField : "",
          }}
        >
          {isOpen && (
            <DatePicker
              calendarClassName={classes.datePicker}
              wrapperClassName="datePicker"
              startDate={startDate}
              endDate={endDate}
              locale={ptbr}
              onChange={handleChange}
              selectsRange
              inline
              isClearable
              renderCustomHeader={CustomHeader}
              minDate={props.minDate}
            />
          )}
        </div>
      </div>
    </FormGroup>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textfield: {
      width: "100%",
      color: theme.palette.text.primary,
      "& label": {
        // Controle de estilo antes de digitar
        fontFamily: theme.typography.fontFamily,
        fontWeight: 400,
        fontSize: "1.4rem",
        borderRadius: "1rem",
        opacity: "0.6",
      },
      "& input.Mui-focused": {}, // Controle de estilo durante digitacao
      "& .MuiInputLabel-shrink": {
        // Controle de estilo apos campo digitado
        fontSize: "1.8rem",
        fontWeight: 700,
        color: theme.palette.text.primary,
        opacity: 1,
        backgroundColor: "#fff",
        paddingRight: 6,
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        // Controla cor da borda durante Digitacao
        border: "2px solid #D91F05",
        borderColor: "#D91F05",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "1rem",
      },
    },
    datePicker: {
      width: 400,
      left: 10,
      bottom: 15,
      border: "0px solid gainsboro",
      boxShadow: "0 0 6px 0px gainsboro",
      borderRadius: "1rem",
      fontSize: "1.1rem",

      [theme.breakpoints.only("xs")]: {
        width: 250,
        boxShadow: "0 0 6px 0px gainsboro",
        marginLeft: "-30px",
      },
      "& .react-datepicker__month-container": {
        width: "inherit",
      },
      "& .react-datepicker__month": {
        marginBottom: 24,
      },
      "& .react-datepicker__header--custom": {
        borderBottom: "0px solid #aeaeae",
        backgroundColor: "#fff",
      },
      "& .react-datepicker__year-select": {
        // teste
        border: "2px solid red",
        lineHeight: 2,
      },
      "& .react-datepicker__selectyears--custom": {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.primary.main,
        fontSize: "1.6rem",
        backgroundColor: "#fff",
        border: "none",
        fontWeight: 600,
        marginLeft: 10,
      },
      "& .react-datepicker__button--custom": {
        color: theme.palette.primary.main,
        backgroundColor: "#fff",
        border: "none",
        padding: 0,
      },
      "& .react-datepicker__boxselectmonths--custom": {
        marginRight: 10,
      },
      "& .react-datepicker__selectmonths--custom": {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.primary.main,
        fontSize: "1.6rem",
        fontWeight: 600,
        border: "none",
        appearance: "none",
        textAlign: "center",
        padding: "0 4px 0 4px",
      },
      "& .react-datepicker__day-names": {
        marginBottom: 0,
        marginTop: 24,
        [theme.breakpoints.only("xs")]: {
          marginTop: 6,
        },
      },
      "& .react-datepicker__day-name": {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.primary.light,
        width: "3.4rem",
        fontWeight: 700,
        margin: "0.9rem",
        [theme.breakpoints.only("xs")]: {
          width: "1.4rem",
          fontSize: "11px",
          paddindRight: "0.6rem",
        },
      },
      "& .react-datepicker__day": {
        width: "3.4rem",
        height: "2rem",
        margin: "0.9rem",

        [theme.breakpoints.only("xs")]: {
          // altera o tamanho dos dias
          width: "25px",
          height: "25px",
          margin: "3px",
          padding: "5px 4px",
          borderRadius: "5px",
        },
      },
      "& .react-datepicker__day--keyboard-selected": {
        // Controla a cor da primeira data selecionada - serve para sobrepor a cor default
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: theme.palette.primary.highlighted,
      },
      "& .react-datepicker__day--in-selecting-range": {
        // Controla a cor durante a selecao do intervalo das datas
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: theme.palette.primary.highlighted,
      },
      "& .react-datepicker__day--selecting-range-start": {
        // Estilo do botao antes de selecionar
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: theme.palette.primary.highlighted,
      },
      "& .react-datepicker__day--selecting-range": {
        backgroundColor: theme.palette.primary.highlighted,
      },
      "& .react-datepicker__day--range-start": {
        // Altera a cor da primeira data selecionada
        color: `${theme.palette.primary.main} !important`,
      },
      "& .react-datepicker__day--in-range": {
        // Altera a cor do intervalo apos selecionado data inicio e data fim pelo usuario
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.highlighted,
      },
      "& .react-datepicker__day--in-range:hover": {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.highlighted,
      },
      "& .react-datepicker__day--range-end": {
        // Altera a cor da data fim selecionada
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.highlighted,
      },
      "& .react-datepicker__day--outside-month": {
        // Altera a cor dos dias que estao fora do intervalo valido do mes selecionado
        color: `${theme.palette.text.primary} !important`,
        pointerEvents: "none",
        opacity: 0.5,
      },
    },
  })
);

export default CalendarRange;
