/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import AppError from "@utils/AppError";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import {
  Discount,
  GasStationProduct,
  IDiscountService,
  NewDiscount,
} from "@modules/discount/model/IDiscountService";
import { Types } from "@ioc/types";

interface DiscountContextProps {
  loading: boolean;
  discounts: GasStationProduct | null;
  selectedCNPJ: string | null;
  mappedDiscounts: Discount[];
  setLoading: (value: boolean) => void;
  setDiscounts: (data: GasStationProduct | null) => void;
  handleSave: (discount: NewDiscount) => Promise<any>;
  getDiscounts: () => void;
  setSelectedCNPJ: (cnpj: string | null) => void;
}

const DiscountContext = createContext<DiscountContextProps>(
  {} as DiscountContextProps
);

const DiscountProvider: React.FC = ({ children }) => {
  const [selectedCNPJ, setSelectedCNPJ] = useState<string | null>(null);
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [discounts, setDiscounts] = useState<GasStationProduct | null>(null);

  const discountService = iocContext.serviceContainer.get<IDiscountService>(
    Types.Discount.IDiscountService
  );

  const mappedDiscounts = useMemo(() => {
    let mapped: Discount[] = [];
    if (discounts) {
      const keys = Object.keys(discounts);
      keys.forEach((key) => {
        const productDiscount = discounts?.[key];
        const newMappedDiscount: Discount = {
          ...productDiscount,
          description: key,
        };
        mapped.push(newMappedDiscount);
      });
    }
    return mapped;
  }, [discounts]);

  const getDiscounts = useCallback(async () => {
    try {
      if (selectedCNPJ) {
        setLoading(true);
        const response = await discountService.listDiscounts(selectedCNPJ);
        setDiscounts(response);
      }
    } catch (error) {
      enqueueSnackbar(
        `Falha ao buscar desconto! ${(error as AppError).message} `,
        {
          variant: "error",
        }
      );
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, selectedCNPJ]);

  const handleSave = useCallback(
    async (discount: NewDiscount) => {
      try {
        if (selectedCNPJ) {
          const response = await discountService.createDiscount(
            selectedCNPJ,
            discount
          );
          enqueueSnackbar("Desconto editado com sucesso!", {
            variant: "success",
          });
          return response;
        }
      } catch (error) {
        enqueueSnackbar(
          `Falha ao buscar desconto! ${(error as AppError).message} `,
          {
            variant: "error",
          }
        );
      }
    },
    [enqueueSnackbar, selectedCNPJ]
  );

  return (
    <DiscountContext.Provider
      value={{
        loading,
        discounts,
        selectedCNPJ,
        mappedDiscounts,
        setLoading,
        setDiscounts,
        handleSave,
        getDiscounts,
        setSelectedCNPJ,
      }}
    >
      {children}
    </DiscountContext.Provider>
  );
};

const useDiscountContext = () => {
  const context = useContext(DiscountContext);
  if (Object.values(context).length === 0) {
    throw new Error(
      "useDiscountContext deve ser utilizado dentro de um DiscountProvider"
    );
  }
  return context;
};

export { useDiscountContext, DiscountProvider };
