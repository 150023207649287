import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import { Types } from "@ioc/types";
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { IMarkImageAsSeenService } from "@modules/titles/models/IMarkImageAsSeenService";
import AppError from "@utils/AppError";
import { useSnackbar } from "notistack";
import React, { useMemo, useState } from "react";
import { useListInvoicesImages } from "./ListInvoicesImagesContext";

const useStyles = makeStyles(() =>
  createStyles({
    imgFluid: {
      width: "100%",
      height: "auto",
      verticalAlign: "middle",
    },
  })
);

const ModalImage: React.FC = () => {
  const classes = useStyles();

  const listInvoicesImagesContext = useListInvoicesImages();
  const { image, listTitle } = listInvoicesImagesContext;
  const { userID } = useUserState();
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const viewed = useMemo(() => {
    return listTitle?.content.find(
      (ele) => ele.imageTitle && ele.imageTitle.id === image.id
    )?.imageTitle?.userSeen;
  }, [image.id, listTitle]);

  return (
    <Dialog
      open={listInvoicesImagesContext.openModalImage}
      onClose={() => listInvoicesImagesContext.setOpenModalImage(false)}
    >
      <DialogContent>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          style={{
            marginBottom: "1rem",
            display: viewed ? "none" : "flex",
          }}
          onClick={async () => {
            try {
              setLoading(true);
              const markImageAsSeenService = iocContext.serviceContainer.get<
                IMarkImageAsSeenService
              >(Types.Titles.IMarkImageAsSeenService);

              const marked = await markImageAsSeenService.execute({
                userID,
                titleImageID: listInvoicesImagesContext.image.id,
              });

              if (listInvoicesImagesContext.listTitle) {
                const idx = listInvoicesImagesContext.listTitle.content.findIndex(
                  (title) =>
                    title.imageTitle?.id === listInvoicesImagesContext.image.id
                );

                if (idx > -1) {
                  const titles = [
                    ...listInvoicesImagesContext.listTitle.content,
                  ];

                  titles[idx].imageTitle = {
                    id: listInvoicesImagesContext.image.id,
                    link: listInvoicesImagesContext.image.link,
                    comments: listInvoicesImagesContext.image.comments,
                    userSeen: marked.user_seen,
                  };

                  listInvoicesImagesContext.setListTitle({
                    count: listInvoicesImagesContext.listTitle.count,
                    content: titles,
                  });
                }
              }

              enqueueSnackbar("Imagem marcada como vista", {
                variant: "success",
              });

              listInvoicesImagesContext.setOpenModalImage(false);
            } catch (error) {
              console.error(error);
              if (error instanceof AppError) {
                return enqueueSnackbar(error.message, {
                  variant: error.variant,
                });
              }
              enqueueSnackbar("Ocorreu um erro ao marcar imagem como vista", {
                variant: "error",
              });
            } finally {
              setLoading(false);
            }
          }}
          startIcon={
            loading && (
              <CircularProgress
                color="secondary"
                style={{ height: "2.5rem", width: "2.5rem" }}
              />
            )
          }
        >
          Marcar como visto
        </Button>

        {listInvoicesImagesContext.image.comments && (
          <TextField
            variant="outlined"
            value={listInvoicesImagesContext.image.comments}
            label="Comentários"
            disabled
            fullWidth
            margin="normal"
          />
        )}
        <img
          className={classes.imgFluid}
          src={listInvoicesImagesContext.image.link}
          alt="imagem comprovante"
        />
      </DialogContent>
    </Dialog>
  );
};

export default ModalImage;
