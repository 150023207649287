import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import arrowImg from "../../assets/mini_arrow_to_right.svg";
import { Link as LinkRouter } from "react-router-dom";
import { Box, Link } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    height: 320,
    width: 386,
    boxShadow: "0rem 0.25rem 0.90rem 0rem rgba(176, 193, 225, 0.25)",
    marginRight: "24px",
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "22px",
    lineHeight: "34px",
    color: "#D91F05",
    marginBottom: "0px",
  },
  media: {
    height: 200,
  },
  link: {
    textDecoration: "none",
    fontSize: "2.4rem",
  },
  arrow: { marginTop: "-3%", textAlign: "right", marginRight: "4%" },
  description: {
    fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14.8px",
    lineHeight: "21px",
    color: "#3E3D3D",
    opacity: 0.7,
  },
});

interface ICardOption {
  image?: string;
  title: string;
  description: string;
  link: string;
}
export default function CardOption({
  image,
  title,
  description,
  link,
}: ICardOption) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Link
        component={LinkRouter}
        to={link}
        className={classes.link}
        underline="none"
      >
        <CardActionArea>
          <CardMedia className={classes.media} image={image} title={title} />
          <CardContent>
            <Typography gutterBottom className={classes.title}>
              {title}
            </Typography>
            <Box style={{ height: "50px" }}>
              <Typography className={classes.description}>
                {description}
              </Typography>
            </Box>
            <Box className={classes.arrow}>
              <img src={arrowImg} alt="ícone de seta" />
            </Box>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
}
