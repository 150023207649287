export const OrdersType = {
  IQueryOrdersService: Symbol("IQueryOrdersService"),
  ICreateOrderService: Symbol("ICreateOrderService"),
  ICreatePDFOrdersService: Symbol("ICreatePDFOrdersService"),
  IGetClientInfoService: Symbol("IGetClientInfoService"),
  IGetPriceProductsService: Symbol("IGetPriceProductsService"),
  ITimeLimitLocationService: Symbol("ITimeLimitLocationService"),
  ICancelOrderService: Symbol("ICancelOrderService"),
  IDownloadByLineService: Symbol("IDownloadByLineService"),
  IUpdateOrderService: Symbol("IUpdateOrderService"),
  IGetLastPaymentService: Symbol("IGetLastPaymentService"),
};
