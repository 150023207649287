import { AlertDeliveryDateIcon, CloseIconRadiusIcon } from "@components/Icons";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    titleModal: {
      color: "#3E3D3D",
      fontSize: "2.2rem",
      fontFamily: "Montserrat",
      fontWeight: "bold",
      textAlign: "center",
      paddingTop: "30px",
    },
    subTitleModal: {
      color: "#3E3D3D",
      fontSize: "1.6rem",
      opacity: "0.6",
      fontFamily: "Montserrat",
      textAlign: "center",
      margin: "20px 16px",
    },
    buttonReturnHome: {
      textTransform: "none",
      background: "transparent",
      fontFamily: "Montserrat",
      color: "#D91F05",
      boxShadow: "none",
      fontWeight: "bold",
      whiteSpace: "nowrap",
      padding: "10px 30px",

      "&:hover": {
        background: "#FFF",
        opacity: "0.8",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
      },
    },
    buttonCancel: {
      textTransform: "none",
      background: "#D91F05",
      color: "#FFF",
      boxShadow: "none",
      fontFamily: "Montserrat",
      borderRadius: "2px",
      fontWeight: "bold",
      whiteSpace: "nowrap",
      padding: "10px 40px",
      "&:hover": {
        opacity: "0.7",
        background: "#D91F05",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
      },
    },
    closeButtonTop: {
      backgroudn: "transparent",
      paddingTop: "20px",
      paddingBottom: "20px",
      borderRadius: "30px",
    },
  })
);

interface Props {
  open: boolean;
  closeModal: () => void;
  reasonReject?: string;
}

const ModalReasonReject: React.FC<Props> = ({
  reasonReject,
  open,
  closeModal,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      PaperProps={{ style: { borderRadius: "8px", padding: "40px 0px" } }}
    >
      <Grid
        xs={12}
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginRight: "30px",
          cursor: "pointer",
        }}
      >
        <Button onClick={() => closeModal()} className={classes.closeButtonTop}>
          <CloseIconRadiusIcon style={{ width: "14px", height: "14px" }} />
        </Button>
      </Grid>
      <DialogContent>
        <DialogTitle>
          <Grid container>
            <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
              <AlertDeliveryDateIcon
                style={{ width: "7rem", height: "7rem" }}
              />
            </Grid>
            <Grid xs={12}>
              <Typography className={classes.titleModal}>
                Motivo da recusa
              </Typography>
            </Grid>
          </Grid>
          <Typography className={classes.subTitleModal}>
            {reasonReject}
          </Typography>
        </DialogTitle>
      </DialogContent>
    </Dialog>
  );
};

export { ModalReasonReject };
