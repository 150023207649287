import {
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Button, CircularProgress } from "@mui/material";
import React from "react";

interface IButtonGenericProps {
  text: string;
  onChange?: (id: unknown) => void;
  onClick?: () => void;
  typeButton: "outlined" | "submit";
  loadingData?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles({
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontStyle: "normal",
    fontWeight: 600,
    color: "#D91F05",
    marginBottom: "0px",
    whiteSpace: "nowrap",
    marginRight: "10px",
  },
  link: {
    textDecoration: "none",
  },
});

const ButtonGeneric: React.FC<IButtonGenericProps> = ({ ...props }) => {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <>
      {props.typeButton === "outlined" && (
        <Button
          color="primary"
          type="submit"
          variant="contained"
          disabled={props.disabled}
          style={{
            borderRadius: "2px",
            border: "none",
            textTransform: "none",
            fontWeight: "bold",
            fontFamily: "Montserrat",
            background: "transparent",
            padding: "14px, 40px, 14px, 40px",
            boxShadow: "none",
            whiteSpace: "nowrap",
          }}
          startIcon={
            props.loadingData && (
              <CircularProgress
                color="secondary"
                style={{ height: 20, width: 20 }}
              />
            )
          }
          onClick={props.onClick}
        >
          <Typography
            gutterBottom
            className={classes.title}
            style={{ fontSize: isMobile ? "11px" : "14px" }}
          >
            {props.text}
          </Typography>
        </Button>
      )}

      {props.typeButton === "submit" && (
        <Button
          color="primary"
          type="submit"
          variant="contained"
          disabled={props.disabled}
          style={{
            borderRadius: "2px",
            textTransform: "none",
            fontWeight: "bold",
            fontFamily: "Montserrat",
            background: props.disabled ? "#CCC" : "#D91F05",
            padding: "14px, 40px, 14px, 40px",
            boxShadow: "none",
            fontStyle: "normal",
            fontSize: isMobile ? "11px" : "14px",
            color: "white",
            whiteSpace: "nowrap",
            cursor: props.disabled ? "not-allowed" : "pointer",
          }}
          startIcon={
            props.loadingData && (
              <CircularProgress
                color="secondary"
                style={{ height: 20, width: 20 }}
              />
            )
          }
          onClick={props.onClick}
        >
          {props.text}
        </Button>
      )}
    </>
  );
};

export default ButtonGeneric;
