import moment from "moment";
import React from "react";

import { useTheme } from "@material-ui/core";
import {
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { INozzleAdmin, STATUSNOZZLE } from "@pages/Admin/Nozzle/model";
import ActionTableNozzleAdmin from "@pages/Admin/Nozzle/resource/table/ActionTableNozzleAdmin";

import { NozzleReticenceIcon } from "@components/Icons";
import PaginationCustom from "@components/Pagination";
import { maskCNPJ } from "@utils/index";

interface TableNozzleProps {
  rows: INozzleAdmin[];
  handleSelectedCNPJ: (cnpjSelected: string) => void;
}

enum KEYNOZZLEADMIN {
  COMPANY = "companyName",
  CNPJ = "cnpj",
  REQUESTING = "requestingUser",
  REGISTRATION = "registrationDate",
  AUTHORIZED = "authorizedBy",
  STATUS = "status",
  ACTION = "action",
}

export interface TableColumn {
  key: string;
  label: string;
  align?: "center" | "left" | "right";
  width?: string;
}

export const TypographyHeader = ({ children }) => {
  const theme = useTheme();
  return (
    <Typography
      variant={"body1"}
      sx={{
        fontFamily: theme.typography.fontFamily,
        fontSize: { xs: 10, md: 12, lg: 12, xl: 16 },
        fontWeight: 600,
      }}
    >
      {children}
    </Typography>
  );
};

export const TypographyBody = ({ children, sx }) => {
  const theme = useTheme();
  return (
    <Typography
      variant={"body1"}
      sx={{
        fontFamily: theme.typography.fontFamily,
        fontSize: { xs: 10, md: 12, lg: 12, xl: 16 },
        fontWeight: 400,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case STATUSNOZZLE.BLOCK:
      return "#F97A91";
    case STATUSNOZZLE.WAITING:
      return "#FFB800";
    case STATUSNOZZLE.APPROVED:
      return "#0BB873";
    default:
      return "#3E3D3D";
  }
};

const TableNozzleAdmin: React.FC<TableNozzleProps> = ({
  rows,
  handleSelectedCNPJ,
}) => {
  const rowsPerPage = 5;

  const tableHeader: TableColumn[] = [
    { key: KEYNOZZLEADMIN.COMPANY, label: "Razão social" },
    { key: KEYNOZZLEADMIN.CNPJ, label: "CNPJ" },
    { key: KEYNOZZLEADMIN.REQUESTING, label: "Usuário solicitante" },
    { key: KEYNOZZLEADMIN.REGISTRATION, label: "Data de cadastro" },
    { key: KEYNOZZLEADMIN.AUTHORIZED, label: "Autorizado por" },
    { key: KEYNOZZLEADMIN.STATUS, label: "Status" },
    { key: KEYNOZZLEADMIN.ACTION, label: "" },
  ];

  const formatEntry = (entry: INozzleAdmin, key: string) => {
    switch (key) {
      case KEYNOZZLEADMIN.COMPANY:
        return entry.companyName;

      case KEYNOZZLEADMIN.CNPJ:
        return maskCNPJ(entry.cnpj);

      case KEYNOZZLEADMIN.REGISTRATION:
        return moment.utc(entry.registrationDate).format("DD/MM/YYYY");

      case KEYNOZZLEADMIN.STATUS:
        if (entry.status === STATUSNOZZLE.APPROVED) return "Concluído";
        if (entry.status === STATUSNOZZLE.WAITING)
          return "Aguardando Aprovação";
        if (entry.status === STATUSNOZZLE.BLOCK) return "Recusado";

        return "-";

      case KEYNOZZLEADMIN.ACTION:
        return (
          <ActionTableNozzleAdmin
            nozzleAdmin={entry}
            handle={handleSelectedCNPJ}
          >
            <NozzleReticenceIcon />
          </ActionTableNozzleAdmin>
        );

      default:
        return entry[key] ?? "-";
    }
  };

  const [page, setPage] = React.useState(0);

  const handleChangePage = (_, newPage: number) => {
    setPage(newPage);
  };

  const handleOnChange = (_, newPage: number) => {
    setPage(newPage - 1);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none", overflowX: "inherit" }}
    >
      <Table
        sx={{
          borderCollapse: "collapse",
        }}
      >
        <TableHead>
          <TableRow>
            {tableHeader.map((headerCell, index) => (
              <TableCell key={index}>
                <TypographyHeader>{headerCell.label}</TypographyHeader>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, rowIndex) => (
              <TableRow key={rowIndex} sx={{ borderTop: "16px solid #F5F5F5" }}>
                {tableHeader.map((col, indexCol) => (
                  <TableCell key={indexCol}>
                    <TypographyBody
                      sx={{ color: getStatusColor(row[col.key]) }}
                    >
                      {formatEntry(row, col.key)}
                    </TypographyBody>
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <div style={{ borderTop: "8px solid #F5F5F5" }}>
        <PaginationCustom
          count={rows.length} //////// Quantidade total de registros
          page={page} ////////////////////// Informa qual pagina o usuario esta no momento
          rowsPerPage={rowsPerPage} // Informa quantas linhas deve ser exibido por pagina
          onChangeHandle={handleOnChange} // Controla qual pagina o usuario esta dentro do componte pagination
          onPageChange={handleChangePage} // Controla qual pagina o usuario - obrigatorio para o componente TablePagination
        />
      </div>
    </TableContainer>
  );
};

export default TableNozzleAdmin;

export const SkeletonTable = ({ colSpan }) => {
  return (
    <Grid container spacing={1}>
      {/* Criar esqueleto de uma tabela */}
      {[...Array(colSpan)].map((_, indexRow) => (
        <Grid container item key={indexRow} spacing={2}>
          {[1, 2, 3, 4].map((_, indexCol) => (
            <Grid item key={indexCol} xs={3} style={{ paddingTop: "10px" }}>
              <Skeleton variant="text" height={70} />
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};
