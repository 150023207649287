import { inject, named, injectable } from "inversify";
import { Types } from "@ioc/types";

import * as IHttpService from "@modules/infra/http/models/IHttpService";
import {
  IGetSalesLastMonthDTO,
  IGetSalesLastMonthService,
} from "@modules/salesLastMonth/model";

@injectable()
export class GetSalesLastMonthService implements IGetSalesLastMonthService {
  @inject(Types.IHttpService)
  @named("pump")
  private httpInstance!: IHttpService.IHttpService;

  public async execute(cnpj: string): Promise<IGetSalesLastMonthDTO[]> {
    try {
      const salesResponse = await this.httpInstance.get<
        IGetSalesLastMonthDTO[]
      >(`/dashboard?document=${cnpj}&action=commission_sales_by_month`);
      return salesResponse;
    } catch (err) {
      throw err;
    }
  }

  public setAuthorization(token: string): void {
    this.httpInstance.setAuthorization(token);
  }
}
