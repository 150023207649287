import React from "react";

import {
  Box,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@material-ui/core";

import CardCommunication from "@pages/Admin/Communications/resource/CardCommunication";
import DragInDrop from "@pages/Admin/Communications/resource/DragInDrop";
import { useCommunication } from "@pages/Admin/Communications/context";

import ButtomBorderless from "@components/ButtonBorderless";
import ButtonDefault from "@components/ButtonDefault";
import { CommunicatedIcon } from "@components/Icons";

const AddCommunicated: React.FC = () => {
  const theme = useTheme();
  const {
    communications,
    loadingCommunication,
    handleCreateCommunicated,
    handleUpdateCommunicated,
    handleDeleteCommunicated,
    handleSendCommunication,
    handleResetCommunications,
  } = useCommunication();

  const TitlePage: React.FC = ({ children }) => {
    return (
      <Box>
        <Typography
          sx={{
            fontSize: { xs: 18, sm: 22, md: 22, lg: 26 },
            fontFamily: theme.typography.fontFamily,
            fontWeight: 700,
          }}
        >
          {children}
        </Typography>
      </Box>
    );
  };

  const ImageForCommunicated: React.FC = ({ children }) => {
    const TitleImageForCommunicated = () => {
      return (
        <Typography
          sx={{
            fontSize: { xs: 12, sm: 16, md: 16, lg: 18 },
            fontFamily: theme.typography.fontFamily,
            fontWeight: 700,
          }}
        >
          Imagens para comunicados
        </Typography>
      );
    };

    return (
      <Grid
        container
        className="ImageForCommunicated-Container"
        sx={{
          width: "100%",
          border: "1px solid white",
          borderRadius: 2,
          boxShadow: "0px 4px 4px rgba(115, 97, 97, 0.10)",
          margin: { xs: 0, lg: 2 },
          padding: { lg: 4 },
          background: theme.palette.background.paper,
          gap: { lg: 4 },
          alignContent: "baseline",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            border: "0px solid blue",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: { xs: 1, lg: 0 },
          }}
        >
          {TitleImageForCommunicated()}
          <CommunicatedIcon
            sx={{ fontSize: { xs: 12, md: 18, sm: 18, lg: 22 } }}
          />
        </Grid>

        <Grid item xs={12} sx={{ border: "0px solid red" }}>
          {children}
        </Grid>
      </Grid>
    );
  };

  const LayoutDragInDrop: React.FC = ({ children }) => {
    return (
      <Grid
        container
        className="AddCommunicated-LayoutDragInDrop-container"
        sx={{
          justifyContent: "center",
          alignContent: "center",
          border: "0px solid blue",
          margin: { lg: 2 },
        }}
      >
        <Grid
          item
          xs={11}
          md={10}
          sm={10}
          lg={9}
          sx={{
            border: "0px solid green",
            display: "flex",
            alignItems: "center",
            margin: { lg: 2 },
          }}
        >
          {children}
        </Grid>
      </Grid>
    );
  };

  const conditionButtonDisabled = communications.some(
    (communication) =>
      communication?.centers?.length === 0 ||
      communication?.expiresIn?.length === 0
  );

  return (
    <Grid
      container
      sx={{
        border: "0px solid blue",
        margin: { lg: "1rem 0rem 0rem 1rem" },
        padding: { lg: 2 },
        width: "98%",
        gap: { lg: 1 },
      }}
    >
      {/* -------- Novo Comunicado */}
      <Grid
        item
        xs={12}
        sx={{
          border: "0px solid purple",
          padding: { lg: "0px 0px 0px 1.5rem" },
        }}
      >
        <TitlePage>Novo Comunicado</TitlePage>
      </Grid>

      {/* -------- Imagens para comunicados */}
      <Grid
        item
        xs={12}
        sx={{
          border: "0px solid purple",
          padding: { xs: 1.5 },
        }}
      >
        {/*  Container - Imagens para comunicados */}
        <ImageForCommunicated>
          {/* Main - Componente Arrasta e solta arquivos */}
          {communications?.length === 0 && ( // communications // communicationData
            <LayoutDragInDrop>
              <DragInDrop
                amountFilesLimit={6}
                onChange={handleCreateCommunicated}
              />
            </LayoutDragInDrop>
          )}

          <Grid
            container
            sx={{
              border: "0px solid red",
              gap: { lg: 4 },
              justifyContent: "left",
            }}
          >
            {communications?.length > 0 &&
              communications.map((communication, index) => (
                <Grid item xs={3} sx={{ border: "0px solid blue" }} key={index}>
                  <CardCommunication
                    index={index}
                    communication={communication}
                    onChange={(newValue) =>
                      handleUpdateCommunicated(newValue, index)
                    }
                    onClickDelete={handleDeleteCommunicated}
                  />
                </Grid>
              ))}

            {/* Rodape da sequencia de Card Communications */}
            {communications?.length > 0 && (
              <Grid item xs={12} sx={{ border: "0px solid red" }}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 600, fontSize: { lg: "1.2rem" } }}
                >
                  Total de{" "}
                  <b style={{ color: theme.palette.secondary.main }}>
                    {" "}
                    {communications?.length}
                  </b>{" "}
                  itens
                </Typography>
              </Grid>
            )}
            {/* Botao de enviar comunicado */}
            {communications?.length > 0 && (
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent={"right"}
                  sx={{ border: "0px solid red" }}
                >
                  <Grid
                    item
                    xs={1.5}
                    sx={{ display: "flex", alignContent: "center" }}
                  >
                    <ButtomBorderless
                      label={"Escolher arquivo"}
                      style={{ border: "0px solid red" }}
                      onClick={handleResetCommunications}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {loadingCommunication ? (
                      <CircularProgress />
                    ) : (
                      <Tooltip
                        title={
                          conditionButtonDisabled ? (
                            <p style={{ fontSize: "10px" }}>
                              Cada comunicado precisa ter uma filial selecionada
                              e precisa ter uma data de expiracao
                            </p>
                          ) : (
                            ""
                          )
                        }
                        arrow
                      >
                        <span>
                          <ButtonDefault
                            onClick={() => handleSendCommunication()}
                            disabled={conditionButtonDisabled}
                          >
                            Enviar
                          </ButtonDefault>
                        </span>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </ImageForCommunicated>
      </Grid>
    </Grid>
  );
};

export default AddCommunicated;
