import { CloseIcon } from "@components/Icons";
import { useHomeUserContext } from "@context/HomeUserContext";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { IGetLastOrdersDTO } from "@modules/lastOrders/dtos/IGetLastOrdersDTO";
import MapOrder from "@pages/User/MyOrders/TrackOrderMap/MapOrder";
import {
  formatAllFistLetterCapitalized,
  formatCurrency,
  formatCurrencyPriceProductPlus,
  formatDateWithoutHoursPlus,
  getHoursFromISODate,
  numberFormatter,
  removeLeadingZero,
} from "@utils/index";
import { useFlags } from "flagsmith/react";
import React from "react";

interface Props {
  open: boolean;
  handleOnClose: () => void;
  lastOrder: IGetLastOrdersDTO;
}

const logisticalStatus = {
  BL: {
    text: "Bloqueado",
    color: "#F97A91",
  },
  FT: {
    text: "Faturado",
    color: "#0BB873",
  },
  LIB: {
    text: "Liberado",
    color: "#FFB800",
  },
  DEFAULT: {
    text: "",
    color: "#ccc",
  },
};

const ModalLastOrders: React.FC<Props> = ({ ...props }) => {
  const classes = useStyles();
  const {
    isShowTableLastOrder,
    setIsShowTableLastOrder,
  } = useHomeUserContext();

  const [, setOpen] = React.useState(false);
  const { lastOrder } = props;

  const isDisabledMap =
    lastOrder?.latitude === null || lastOrder?.longitude === null;
  const flags = useFlags(["user_home_show_gps"]);

  const handleClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    setOpen(false);
    if (reason === "backdropClick") {
      props.handleOnClose();
    }
  };

  const checkSizeIsZero = (str: string): boolean => {
    if (removeLeadingZero(str).length === 0) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Modal
        className={classes.modal}
        open={props.open}
        onClose={(event, reason) => handleClose(event, reason)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box className={classes.box}>
            <Grid
              container
              className={classes.container}
              style={{ padding: "20px 20px", border: "0px solid red" }}
            >
              <Grid
                item
                xs={12}
                sm={11}
                md={11}
                lg={11}
                xl={11}
                style={{
                  paddingTop: 0,
                  paddingBottom: 24,
                  border: "0px solid blue",
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    border: "0px solid blue",
                  }}
                >
                  <Button
                    onClick={props.handleOnClose}
                    style={{
                      justifyContent: "end",
                      width: "fit-content",
                      border: "0px solid red",
                      paddingTop: 14,
                    }}
                  >
                    <CloseIcon className={classes.closeIcon} />
                  </Button>
                </Grid>

                <Box style={{ textAlign: "center" }}>
                  <Typography className={classes.typographyTitle}>
                    {" "}
                    Pedido{" "}
                    {props.lastOrder && props.lastOrder.numberOrder
                      ? props.lastOrder.numberOrder
                      : "-"}{" "}
                  </Typography>
                  <Typography className={classes.typographySubtitle}>
                    {props.lastOrder && props.lastOrder.companyName
                      ? formatAllFistLetterCapitalized(
                          props.lastOrder.companyName
                        )
                      : "-"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={11} style={{ border: "0px solid red" }}>
                {isShowTableLastOrder ? (
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      className={classes.bodyGridItem}
                      style={{
                        border: "1px solid rgba(62, 61, 61, 0.3)",
                        borderRadius: "0.6rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <Grid item xs={4}>
                        <Grid item xs={6}>
                          <Typography className={classes.typographyHead}>
                            Produto
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className={classes.typographyHead}>
                            Volume
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item xs={8} style={{ textAlign: "end" }}>
                        <Grid item xs={12}>
                          <Typography className={classes.typographyBody}>
                            {props.lastOrder && props.lastOrder.productID}-
                            {props.lastOrder && props.lastOrder.productName
                              ? formatAllFistLetterCapitalized(
                                  props.lastOrder.productName
                                )
                              : "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.typographyBody}>
                            {props.lastOrder && props.lastOrder.quantity
                              ? numberFormatter(props.lastOrder.quantity) +
                                " " +
                                "litros"
                              : "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.bodyGridItem}>
                      <Box>
                        <Typography className={classes.typographyHead}>
                          Status financeiro
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.typographyBody}>
                          {props.lastOrder && props.lastOrder.statusAtem ? (
                            <span
                              style={{
                                fontWeight: 700,
                                color:
                                  logisticalStatus[
                                    props.lastOrder.statusAtem ?? "DEFAULT"
                                  ].color,
                              }}
                            >
                              {
                                logisticalStatus[props.lastOrder.statusAtem]
                                  .text
                              }
                            </span>
                          ) : (
                            "-"
                          )}{" "}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className={classes.bodyGridItem}>
                      <Box>
                        <Typography className={classes.typographyHead}>
                          Número da nota fiscal
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.typographyBody}>
                          {props.lastOrder && props.lastOrder.invoice
                            ? props.lastOrder.invoice
                            : "-"}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className={classes.bodyGridItem}>
                      <Box>
                        <Typography className={classes.typographyHead}>
                          Valor unitário
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.typographyBody}>
                          {props.lastOrder && props.lastOrder.price
                            ? formatCurrencyPriceProductPlus(
                                props.lastOrder.price
                              )
                            : "-"}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className={classes.bodyGridItem}>
                      <Box>
                        <Typography className={classes.typographyHead}>
                          Valor total
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.typographyBody}>
                          {props.lastOrder && props.lastOrder.totalPrice
                            ? formatCurrency(props.lastOrder.totalPrice)
                            : "-"}{" "}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className={classes.bodyGridItem}>
                      <Box>
                        <Typography className={classes.typographyHead}>
                          Condição de pagamento
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.typographyBody}>
                          {props.lastOrder && props.lastOrder.payment
                            ? checkSizeIsZero(
                                props.lastOrder?.paymentDescription!
                              )
                              ? "A Vista"
                              : props.lastOrder.payment +
                                "-" +
                                removeLeadingZero(
                                  props.lastOrder?.paymentDescription!
                                )
                            : "-"}{" "}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className={classes.bodyGridItem}>
                      <Box>
                        <Typography className={classes.typographyHead}>
                          Horário de faturamento
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.typographyBody}>
                          {props.lastOrder && props.lastOrder.deliveryDate
                            ? getHoursFromISODate(props.lastOrder.deliveryDate)
                            : "-"}{" "}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className={classes.bodyGridItem}>
                      <Box>
                        <Typography className={classes.typographyHead}>
                          Motorista
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.typographyBody}>
                          {props.lastOrder && props.lastOrder.driverName
                            ? formatAllFistLetterCapitalized(
                                props.lastOrder.driverName
                              )
                            : "-"}{" "}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className={classes.bodyGridItem}>
                      <Box>
                        <Typography className={classes.typographyHead}>
                          Placa CT
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.typographyBody}>
                          {props.lastOrder && props.lastOrder.vehiclePlate
                            ? props.lastOrder.vehiclePlate
                            : "-"}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className={classes.bodyGridItem}>
                      <Box>
                        <Typography className={classes.typographyHead}>
                          Data da entrega
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.typographyBody}>
                          {props.lastOrder && props.lastOrder.deliveryDate
                            ? formatDateWithoutHoursPlus(
                                props.lastOrder.deliveryDate
                              )
                            : "-"}{" "}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} className={classes.bodyGridItem}>
                      <Box>
                        <Typography className={classes.typographyHead}>
                          Frete
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.typographyBody}>
                          {props.lastOrder && props.lastOrder.freightType
                            ? props.lastOrder.freightType
                            : "-"}{" "}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                ) : (
                  <MapOrder
                    latitude={props.lastOrder?.latitude ?? 0}
                    longitude={props.lastOrder?.longitude ?? 0}
                  />
                )}
              </Grid>

              <Grid item style={{ padding: 0, border: "0px solid blue" }}>
                <Grid container spacing={0} style={{ gap: 20 }}>
                  <Grid
                    item
                    xs={12}
                    className={classes.bodyGridItemButton}
                    style={{
                      marginTop: "2rem",
                      padding: 0,
                      border: "0px solid blue",
                    }}
                  >
                    <Button
                      variant="contained"
                      className={classes.button}
                      style={{ boxShadow: "none" }}
                      onClick={props.handleOnClose}
                    >
                      OK
                    </Button>
                  </Grid>

                  {flags.user_home_show_gps.enabled && (
                    <Grid
                      item
                      xs={12}
                      className={classes.bodyGridItemButton}
                      style={{
                        padding: "0px 0px 0px 0px",
                        border: "0px solid red",
                      }}
                    >
                      <Button
                        disabled={isDisabledMap}
                        variant="contained"
                        className={classes.buttonSeeMap}
                        style={{
                          boxShadow: "none",
                          padding: 6,
                          borderRadius: 4,
                        }}
                        onClick={() =>
                          setIsShowTableLastOrder(!isShowTableLastOrder)
                        }
                      >
                        {isShowTableLastOrder ? "Ver mapa" : "Voltar"}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalLastOrders;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "96vh",
    padding: "12px",
    borderRadius: 6,

    [theme.breakpoints.up("xs")]: {
      width: "95%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "50%",
    },
  },
  buttonSeeMap: {
    textTransform: "none",
    background: "transparent",
    color: "#D91F05",
    boxShadow: "none",
    fontFamily: "Montserrat",
    borderRadius: "2px",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    padding: "10px",
    "&:hover": {
      color: "#D91F05",
      background: "#fff",
    },
  },
  container: {
    color: theme.palette.text.primary,
    background: "#fff",
    border: "2px solid #fff",
    borderRadius: 16,
    justifyContent: "center",
    alignContent: "baseline",
    [theme.breakpoints.up("xs")]: {
      padding: "8px 0px 8px 0px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "16px 0px 16px 0px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "16px 0px 16px 0px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "24px 0px 24px 0px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "32px 0px 32px 0px",
    },
  },
  gridcontainerpadding: {
    [theme.breakpoints.only("xs")]: {
      padding: "0px 0px 0px 0px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "0px 4px 0px 0px",
    },
    [theme.breakpoints.only("md")]: {
      padding: "0px 24px 0px 0px",
    },
    [theme.breakpoints.only("lg")]: {
      padding: "0px 32px 0px 0px",
    },
    [theme.breakpoints.only("xl")]: {
      padding: "0px 48px 0px 0px",
    },
  },
  typographyTitle: {
    marginBottom: "0.3rem",
    fontWeight: 700,
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.9rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "2.6rem",
    },
  },
  typographySubtitle: {
    fontWeight: 500,
    opacity: 0.6,
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.5rem",
    },
  },
  bodyGridItem: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("xs")]: {
      padding: "7px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "5px !important",
    },
    [theme.breakpoints.up("md")]: {
      padding: "6px !important",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "9px !important",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "10px !important",
    },
  },
  typographyHead: {
    fontWeight: 700,
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.6rem",
    },
  },
  typographyBody: {
    fontWeight: 500,
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.6rem",
    },
  },
  bodyGridItemButton: {
    padding: "0.5rem",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primaryLight.main,
    fontWeight: 700,
    padding: "0.6rem 3rem 0.6rem 3rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
    },
  },
  closeIcon: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "1rem !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.3rem !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.7rem !important",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.7rem !important",
    },
  },
}));
