import HeaderMobile from "@components/HeaderMobile";
import Sidebar from "@components/Sidebar";
import {
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateAreas: "'appbar' 'content' 'footer'",
      gridTemplateRows: "6.5rem 1fr auto",
      height: "100%",
      marginTop: "-110px",
    },
    content: {
      overflowY: "scroll",
      backgroundColor: "#F5F5F5",
      width: "",
    },
  })
);

const LayoutDashPosh: React.FC = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <>
      {isMobile ? (
        <>
          <HeaderMobile />
          <div className={classes.container}>
            <div></div>
            <div className={classes.content}>{children}</div>
          </div>
        </>
      ) : (
        <Sidebar>
          <div className={classes.container}>
            <div></div>
            <div className={classes.content}>{children}</div>
          </div>
        </Sidebar>
      )}
    </>
  );
};

export default LayoutDashPosh;
