import { useFlags } from "flagsmith/react";

import BreadcrumbCustom, {
  BreadcrumbChild,
} from "@components/BreadcrumbCustom";
import { ShoppingCartOrder } from "@components/Icons";
import PageNotFound from "@components/PageNotFound/PageNotFound";
import PaginationDynamic from "@components/PaginationDynamic/PaginationDynamic";
import ROUTES from "@config/routes";
import {
  Box,
  Grid,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import MyOrdersTemporary from "@pages/User/MyOrdersTemporary";
import { FormQueryProviderTemporary } from "@pages/User/MyOrdersTemporary/FormQueryContext";
import { convertDateInFormatZ, firstDayOfMonth } from "@utils/index";
import {
  addFlagHasQueryResult,
  addValuesFilters,
  deleteFlagHasQueryResult,
  deleteValuesFilters,
  getFlagHasQueryResult,
  getValuesFilters,
} from "@utils/localStorageUtils";
import { calcPagesQuantity } from "@utils/pagination";
import { startOfMonth } from "date-fns";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Layout from "../../../components/Layout";
import { useIoCContext } from "../../../context/IoCContext/IoCContext";
import { Types } from "../../../ioc/types";
import { IQueryOrdersService } from "../../../modules/orders/models/IQueryOrdersService";
import FormQuery from "./FormQuery";
import { FormQueryProvider, useFormQuery } from "./FormQueryContext";
import FormQueryValidationSchema from "./FormQueryValidationSchema";
import SkeletonTableOrder from "./SkeletonTableOrder";
import TableOrders from "./TableOrders";
import { IQuery } from "./interface";
import { LocalStorageKeysOrders } from "./utilsOrders/localStorageOrders";
import { useUserState } from "@context/UserContext";
import { IQueryOrdersDTO } from "@modules/orders/dtos/IQueryOrdersDTO";

const useStyles = makeStyles((theme) =>
  createStyles({
    formTitle: {
      fontWeight: "bold",
      marginBottom: "2rem",
    },
    TitleOrder: {
      color: "#3E3D3D",
      fontWeight: "bold",
      fontFamily: "Montserrat",
      fontSize: "3rem",
      paddingBottom: "20px",
    },
    TilteFooter: {
      fontFamily: "Montserrat",
      color: "#3E3D3D",
    },
    CountItensFooter: {
      fontFamily: "Montserrat",
      color: "#FFB03A",
    },
    SubTilteFooter: {
      padding: 0,
      margin: 0,
      opacity: 0.6,
      fontFamily: "Montserrat",
      color: "#3E3D3D",
    },
  })
);

export const ITEMS_PER_PAGE = 10;

const allowedStatuses: string[] = [
  "FT",
  "LIB",
  "BL",
  "DEV",
  "EST",
  "CANCELADO",
];

const MyOrders: React.FC = () => {
  const classes = useStyles();
  const userState = useUserState();
  const { enqueueSnackbar } = useSnackbar();
  const formQueryContext = useFormQuery();
  const iocContext = useIoCContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const hasQueryResultStorage = getFlagHasQueryResult("hasQueryResult");
  const valuesFiltersStorage = getValuesFilters("valuesFilters");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const allCNPJOptions = userState.listCNPJ.map((ele) => ele.BpID || ele.CNPJ);

  const allstatusSaleOptionsArray = [
    "FT",
    "LIB",
    "BL",
    "DEV",
    "EST",
    "CANCELADO",
  ];
  const allstatusSaleOptions = allstatusSaleOptionsArray.join(",");

  const initialValues: IQuery = {
    CNPJ: allCNPJOptions,
    filterBy: "deliveryDate",
    statusSale: allstatusSaleOptionsArray,
    startDate: startOfMonth(new Date()),
    endDate: new Date(),
    dateRange: [startOfMonth(new Date()), new Date()],
  };
  const queryOrdersService = iocContext.serviceContainer.get<
    IQueryOrdersService
  >(Types.Orders.IQueryOrdersService);
  const firstItemIndex = (currentPage - 1) * ITEMS_PER_PAGE + 1;
  const lastItemIndex = Math.min(currentPage * ITEMS_PER_PAGE, totalCount);

  const deliveryDateFromCreatedOrder = localStorage.getItem(
    LocalStorageKeysOrders.DateDelivery
  );

  const deliveryDateFromNewOrderStorage = deliveryDateFromCreatedOrder
    ? convertDateInFormatZ(deliveryDateFromCreatedOrder || "")
    : "";

  const CNPJ_FROM_NEW_ORDER =
    localStorage.getItem(LocalStorageKeysOrders.CNPJFromNewOrder) || "";

  const firstDateOfMonthStorageNewOrderStorage = deliveryDateFromCreatedOrder
    ? firstDayOfMonth(deliveryDateFromCreatedOrder || "")
    : "";
  const cnpjFromNewOrderStorage: string[] = [CNPJ_FROM_NEW_ORDER];

  const [hasQueryResultByNewOrder, setHasQueryResultByNewOrder] = useState<
    boolean
  >(false);

  const hascnpjFromNewOrderStorage = cnpjFromNewOrderStorage[0] !== "";

  const isOrderFromNewRequestStorage = localStorage.getItem(
    LocalStorageKeysOrders.isOrderFromNewRequest
  );

  const handleFormSubmit = useCallback(
    async (values, page) => {
      try {
        const isQueryValid = !Array.isArray(values);
        const newOrderWasCreated = cnpjFromNewOrderStorage[0] !== "";

        if (!formQueryContext.openModalMap) {
          formQueryContext.setLoadingQuery(true);
        }

        let queryResult;
        let payload: IQueryOrdersDTO;

        if (newOrderWasCreated) {
          payload = {
            companies: cnpjFromNewOrderStorage,
            beginDate: firstDateOfMonthStorageNewOrderStorage,
            endDate: deliveryDateFromNewOrderStorage,
            filterBy: "deliveryDate",
            statusPortalCliente: allstatusSaleOptions,
            page: page > 1 ? page : 1,
            limit: ITEMS_PER_PAGE,
          };

          queryResult = await queryOrdersService.execute(payload);
          formQueryContext.setDataQuery(queryResult.orders);
          setTotalCount(queryResult.total);
          handleQueryNewOrderStorage(queryResult);
        } else if (isQueryValid) {
          const queryValues = values && FormQueryValidationSchema.cast(values);
          if (!queryValues) return;

          const filteredStatusSale = queryValues?.statusSale
            ? queryValues.statusSale.filter(
                (status): status is string =>
                  status !== undefined && allowedStatuses.includes(status)
              )
            : [];

          payload = {
            companies: queryValues.CNPJ as string[],
            beginDate: queryValues.dateRange[0],
            endDate: queryValues.dateRange[1],
            filterBy: "deliveryDate",
            statusPortalCliente: filteredStatusSale.join(","),
            page: page > 1 ? page : 1,
            limit: ITEMS_PER_PAGE,
          };

          queryResult = await queryOrdersService.execute(payload);

          setHasQueryResultByNewOrder(false);
          formQueryContext.setDataQuery(queryResult.orders);
          setTotalCount(queryResult.total);
          handleQueryStorage(queryResult, queryValues);
        } else {
          payload = {
            companies: allCNPJOptions,
            beginDate: initialValues.startDate
              ? convertDateInFormatZ(initialValues.startDate)
              : undefined,
            endDate: initialValues.endDate
              ? convertDateInFormatZ(initialValues.endDate)
              : undefined,
            filterBy: "deliveryDate",
            statusPortalCliente: allstatusSaleOptions,
            page: page > 1 ? page : 1,
            limit: ITEMS_PER_PAGE,
          };

          queryResult = await queryOrdersService.execute(payload);

          formQueryContext.setDataQuery(queryResult.orders);
          setTotalCount(queryResult.total);

          const queryValues = {
            CNPJ: allCNPJOptions,
            dateRange: initialValues.dateRange,
            startDate: initialValues.startDate,
            endDate: initialValues.endDate,
            filterBy: "deliveryDate",
            statusSale: allstatusSaleOptionsArray,
            page: page > 1 ? page : 1,
            limit: ITEMS_PER_PAGE,
          };

          handleQueryStorage(queryResult, queryValues);
        }
      } catch (error) {
        console.log("🚀 ~ error:", error);
        enqueueSnackbar("Ocorreu um erro ao buscar pedidos", {
          variant: "error",
        });
      } finally {
        formQueryContext.setLoadingQuery(false);
        formQueryContext.setSearchFirstTime(false);
        formQueryContext.setIsClickedFilter(false);
        localStorage.removeItem(LocalStorageKeysOrders.CNPJFromNewOrder);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      formQueryContext,
      iocContext.serviceContainer,
      firstDateOfMonthStorageNewOrderStorage,
      enqueueSnackbar,
    ]
  );

  const handleQueryNewOrderStorage = (queryResult) => {
    if (queryResult.orders.length > 0) {
      addFlagHasQueryResult("hasQueryResult", "true");
      setHasQueryResultByNewOrder(true);
    } else {
      localStorage.removeItem(LocalStorageKeysOrders.CNPJFromNewOrder);
      localStorage.removeItem(LocalStorageKeysOrders.DateDelivery);
      deleteFlagHasQueryResult("hasQueryResult");
    }
  };

  const handleQueryStorage = (queryResult, queryValues) => {
    if (queryResult.orders.length > 0) {
      addFlagHasQueryResult("hasQueryResult", "true");
      const filtersInString = JSON.stringify(queryValues);
      addValuesFilters("valuesFilters", filtersInString);
    } else {
      deleteFlagHasQueryResult("hasQueryResult");
      deleteValuesFilters("valuesFilters");
    }
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => {
    setCurrentPage(newPage + 1);
    handleFormSubmit(valuesFiltersStorage, newPage + 1);
  };

  const handleFormSubmitRef = useRef<any>();
  useEffect(() => {
    handleFormSubmitRef.current = handleFormSubmit;
    // Só faz inumeras chamadas na API se o modal de mapa estiver aberto
    if (formQueryContext.openModalMap) {
      const interval = setInterval(() => {
        if (handleFormSubmitRef.current) {
          handleFormSubmitRef.current(
            valuesFiltersStorage ? JSON.parse(valuesFiltersStorage) : "",
            currentPage
          );
        }
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [
    currentPage,
    formQueryContext.openModalMap,
    handleFormSubmit,
    valuesFiltersStorage,
  ]);

  useEffect(() => {
    handleFormSubmit([], currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //NOTE: Desabilitar storage do Pesquisa de Pedidos
  // useEffect(() => {
  //   if (Boolean(hasQueryResultStorage)) {
  //     handleFormSubmit(
  //       valuesFiltersStorage ? JSON.parse(valuesFiltersStorage) : "",
  //       currentPage
  //     );
  //   } else if (cnpjFromNewOrderStorage[0] !== "") {
  //     handleFormSubmit([], currentPage);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [hasQueryResultStorage]);

  const IS_FIRST_MESSAGE_APRESENTATION_ORDER =
    formQueryContext.searchFirstTime &&
    !formQueryContext.loadingQuery &&
    !hasQueryResultStorage &&
    !hasQueryResultByNewOrder;
  const IS_NOT_FOUND_IN_ROUTE_ORDERS =
    formQueryContext.dataQuery.length === 0 &&
    !getFlagHasQueryResult("hasQueryResult") &&
    !formQueryContext.searchFirstTime &&
    !formQueryContext.loadingQuery &&
    !hascnpjFromNewOrderStorage &&
    isOrderFromNewRequestStorage === "false";

  const IS_NOT_FOUND_AFTER_NEW_ORDER =
    !formQueryContext.loadingQuery &&
    !hasQueryResultByNewOrder &&
    formQueryContext.dataQuery.length === 0 &&
    !formQueryContext.searchFirstTime &&
    isOrderFromNewRequestStorage === "true";

  const HAS_RESULT_ORDERS =
    !formQueryContext.loadingQuery && formQueryContext.dataQuery.length > 0;

  const breadcrumbDataOrders: BreadcrumbChild[] = [
    {
      link: ROUTES.USER_ROUTES.HOME,
      label: "Início",
    },
    {
      label: "Seus Pedidos",
    },
  ];

  return (
    <Layout>
      <div
        style={{
          margin: isMobile ? 0 : "0 7rem",
          width: isMobile ? "100%" : "",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={FormQueryValidationSchema}
        >
          <Form>
            <Grid xs={10} style={{ padding: 8, marginLeft: "-20px" }}>
              <BreadcrumbCustom data={breadcrumbDataOrders} />
            </Grid>
            <Typography
              className={classes.TitleOrder}
              style={{ fontSize: isMobile ? "2.0rem" : "" }}
            >
              Seus pedidos
            </Typography>

            <FormQuery />

            {IS_FIRST_MESSAGE_APRESENTATION_ORDER && (
              <PageNotFound
                title={"Não há nada para mostrar"}
                subtitle={
                  "Faça uma busca pelos filtros para consultar seus pedidos"
                }
                icon={<ShoppingCartOrder />}
              />
            )}

            {formQueryContext.loadingQuery && (
              <SkeletonTableOrder
                rows={isMobile ? 6 : 6}
                cols={isMobile ? 2 : 10}
              />
            )}

            {/* Cenário extremo mapeado tur-504 no qual o usuário seleciona uma data de entrega para um mês longe que não tem nenhum pedido */}
            {IS_NOT_FOUND_AFTER_NEW_ORDER && (
              <PageNotFound
                title={
                  "Seu Pedido está sendo processado em breve irá ser exibido na listagem."
                }
                subtitle={
                  "Enquanto isso, você pode visualizar os pedidos anteriores deste mês abaixo."
                }
                icon={<ShoppingCartOrder />}
              />
            )}

            {IS_NOT_FOUND_IN_ROUTE_ORDERS && (
              <PageNotFound
                title="Não houve resultado para o filtro da pesquisa."
                subtitle="Por favor tente outro filtro."
              />
            )}

            {HAS_RESULT_ORDERS && (
              <Box>
                <TableOrders page={currentPage} />
                <div style={{ height: "20px" }}></div>
                {isMobile ? (
                  <Grid
                    container
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        justifyItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <PaginationDynamic
                        onPageChange={handlePageChange}
                        pagesTotal={calcPagesQuantity(
                          ITEMS_PER_PAGE,
                          totalCount
                        )}
                        showFirstButton={true}
                        showLastButton={true}
                        page={currentPage}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ alignContent: "center", alignItems: "center" }}
                    >
                      <span
                        className={classes.TilteFooter}
                        style={{ fontSize: "1.3rem" }}
                      >
                        <b>
                          Mostrando itens{" "}
                          <span className={classes.CountItensFooter}>
                            {" "}
                            {firstItemIndex}-{lastItemIndex}
                          </span>
                        </b>
                        <p className={classes.SubTilteFooter}>
                          Total de {totalCount}
                        </p>
                      </span>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid item xs={6}>
                      <span className={classes.TilteFooter}>
                        <b>
                          Mostrando itens{" "}
                          <span className={classes.CountItensFooter}>
                            {" "}
                            {firstItemIndex}-{lastItemIndex}
                          </span>
                        </b>
                        <p className={classes.SubTilteFooter}>
                          Total de {totalCount}
                        </p>
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      {" "}
                      <PaginationDynamic
                        onPageChange={handlePageChange}
                        pagesTotal={calcPagesQuantity(
                          ITEMS_PER_PAGE,
                          totalCount
                        )}
                        showFirstButton={true}
                        showLastButton={true}
                        page={currentPage}
                      />
                    </Grid>
                  </Grid>
                )}
              </Box>
            )}
          </Form>
        </Formik>
      </div>
    </Layout>
  );
};

const Container: React.FC = () => {
  const flag_your_orders = useFlags(["user_home_card_your_orders"]);
  return (
    <>
      {flag_your_orders.user_home_card_your_orders.enabled ? (
        <FormQueryProvider>
          <MyOrders />
        </FormQueryProvider>
      ) : (
        <FormQueryProviderTemporary>
          <MyOrdersTemporary />
        </FormQueryProviderTemporary>
      )}
    </>
  );
};

export default Container;
