import { formatCurrency, maskCNPJ } from "@utils/index";
import { format } from "date-fns";
import { injectable } from "inversify";
import groupBy from "lodash.groupby";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { StyleDictionary, TDocumentDefinitions } from "pdfmake/interfaces";
import logoATEM from "../../../assets/logo-atem.png";
import { IQueryTitlesDataDTO } from "../dtos/IQueryTitlesDataDTO";
import { ICreatePDFTitlesService } from "../models/ICreatePDFTitlesService";

@injectable()
export class CreatePDFTitlesService implements ICreatePDFTitlesService {
  private tableTitlesHeader() {
    return [
      { text: "#", style: "header" },
      { text: "Título", style: "header" },
      { text: "Emissão", style: "header" },
      { text: "Vencimento", style: "header" },
      { text: "Valor", style: "header" },
      { text: "Situação", style: "header" },
      { text: "CNPJ", style: "header" },
      { text: "Razão social", style: "header" },
      { text: "Baixa", style: "header" },
    ];
  }

  private stylesDocument(): StyleDictionary {
    return {
      header: {
        bold: true,
        fontSize: 12,
        alignment: "center",
      },
      tableCell: {
        fontSize: 10,
        alignment: "center",
      },
      CNPJStyle: {
        bold: true,
        margin: [0, 30, 0, 0],
      },
    };
  }

  private totalClosed(data: IQueryTitlesDataDTO[]) {
    return data
      .filter((title) => title.situation === "closed")
      .reduce((a, b) => a + b.value, 0);
  }

  private totalOpen(data: IQueryTitlesDataDTO[]) {
    return data
      .filter((title) => title.situation === "open")
      .reduce((a, b) => a + b.value, 0);
  }

  private totalPartial(data: IQueryTitlesDataDTO[]) {
    return data
      .filter((title) => title.situation === "partial")
      .reduce((a, b) => a + b.value, 0);
  }

  execute(
    data: IQueryTitlesDataDTO[],
    queryData: { beginDate: Date; endDate: Date }
  ): Promise<void> {
    (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

    const groupedCNPJ = groupBy(data, "CNPJ");

    const docDefinition: TDocumentDefinitions = {
      pageOrientation: "landscape",
      pageSize: "A4",
      content: [
        {
          image: "logoATEM",
          height: 35,
          width: 73,
          alignment: "center",
        },
        {
          text: "Títulos Financeiros",
          alignment: "center",
          bold: true,
          fontSize: 18,
          margin: [0, 5],
        },
        {
          text: `Títulos por cliente de ${format(
            queryData.beginDate,
            "dd/MM/yyyy"
          )} a ${format(queryData.endDate, "dd/MM/yyyy")}`,
          alignment: "center",
          bold: true,
          margin: [0, 5],
        },
        {
          table: {
            headerRows: 1,
            widths: ["auto", "*", "*", "*", "*", "auto", "*", "*", "*"],
            body: [
              this.tableTitlesHeader(),
              ...data.map((title, idx) => {
                const emissionDate = new Date(title.emissionDate);
                const emissionDateDtOnly = new Date(
                  emissionDate.valueOf() +
                    emissionDate.getTimezoneOffset() * 60 * 1000
                );

                const dueDate = new Date(title.dueDate);
                const dueDateDtOnly = new Date(
                  dueDate.valueOf() + dueDate.getTimezoneOffset() * 60 * 1000
                );

                return [
                  { text: idx, style: "tableCell" },
                  { text: title.id, style: "tableCell" },
                  {
                    text: format(emissionDateDtOnly, "dd/MM/yyyy"),
                    style: "tableCell",
                  },
                  {
                    text: format(dueDateDtOnly, "dd/MM/yyyy"),
                    style: "tableCell",
                  },
                  { text: formatCurrency(title.value), style: "tableCell" },
                  {
                    text:
                      title.situation === "open"
                        ? "Aberto"
                        : title.situation === "closed"
                        ? "Baixado"
                        : "Parcial",
                    style: "tableCell",
                  },
                  { text: maskCNPJ(title.CNPJ), style: "tableCell" },
                  { text: title.companyName, style: "tableCell" },
                  {
                    text: title.closed
                      ? format(new Date(title.closed), "dd/MM/yyyy")
                      : "--",
                    style: "tableCell",
                  },
                ];
              }),
            ],
          },
        },
        { text: "Total relatório", style: "CNPJStyle" },
        {
          table: {
            widths: ["auto", "auto", "auto", "auto"],
            headerRows: 1,
            body: [
              [
                { text: "Total aberto", style: "header" },
                { text: "Total baixado", style: "header" },
                { text: "Total parcial", style: "header" },
                { text: "Total", style: "header" },
              ],
              [
                {
                  text: formatCurrency(this.totalOpen(data)),
                  style: "tableCell",
                },
                {
                  text: formatCurrency(this.totalClosed(data)),
                  style: "tableCell",
                },
                {
                  text: formatCurrency(this.totalPartial(data)),
                  style: "tableCell",
                },
                {
                  text: formatCurrency(
                    this.totalOpen(data) +
                      this.totalClosed(data) +
                      this.totalPartial(data)
                  ),
                  style: "tableCell",
                },
              ],
            ],
          },
        },
        Object.keys(groupedCNPJ).map((key) => {
          const totalOpen = this.totalOpen(groupedCNPJ[key]);
          const totalClosed = this.totalClosed(groupedCNPJ[key]);
          const totalPartial = this.totalPartial(groupedCNPJ[key]);

          return [
            {
              text: `${maskCNPJ(key)} (${groupedCNPJ[key][0].companyName})`,
              style: "CNPJStyle",
            },
            {
              table: {
                headerRows: 1,
                widths: ["auto", "auto", "auto", "auto"],
                body: [
                  [
                    { text: "Total aberto", style: "header" },
                    { text: "Total baixado", style: "header" },
                    { text: "Total parcial", style: "header" },
                    { text: "Total", style: "header" },
                  ],
                  [
                    { text: formatCurrency(totalOpen), style: "tableCell" },
                    { text: formatCurrency(totalClosed), style: "tableCell" },
                    { text: formatCurrency(totalPartial), style: "tableCell" },
                    {
                      text: formatCurrency(
                        totalOpen + totalClosed + totalPartial
                      ),
                      style: "tableCell",
                    },
                  ],
                ],
              },
            },
          ];
        }),
      ],
      styles: this.stylesDocument(),
      images: {
        logoATEM,
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    return new Promise<void>((resolve, reject) => {
      pdfDocGenerator.download("titles.pdf", () => resolve());
    });
  }
}
