import { ArrowLeftIconGray, ArrowRightIconGray } from "@components/Icons";
import {
  Theme,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { range } from "@utils/index";
import { getMonth, getYear } from "date-fns";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { monthsBR, ptbr } from "./utilsDatePicker/utils";

interface IDatePickerGeneric {
  minDate?: Date | null;
  dateSelected: Date | null;
  onDateChange: (date: Date) => void;
  disableSundays?: boolean;
}

const DatePickerGeneric: React.FC<IDatePickerGeneric> = ({
  minDate,
  dateSelected,
  onDateChange,
  disableSundays = false,
}) => {
  const [selectedDate, setDateSelected] = useState<Date | null>(
    dateSelected || new Date()
  );
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const years = range(2022, 2031).sort((a, b) => b - a);

  const isSunday = (date: Date) => {
    return date.getDay() !== 0;
  };

  const CustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    changeYear,
    changeMonth,
  }) => (
    <div
      className="react-datepicker__custom-header"
      style={{
        display: "flex",
        justifyContent: isMobile ? "center" : "space-between",
      }}
    >
      <select
        className="react-datepicker__selectyears--custom"
        value={getYear(date)}
        style={{
          fontSize: isMobile ? "1.2rem" : "1.6rem",
          marginRight: isMobile ? "2.5rem" : "0px",
        }}
        onChange={({ target: { value } }) => changeYear(parseInt(value))}
      >
        {years.map((option) => (
          <option
            key={option}
            value={option}
            className="react-datepicker__option"
            style={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: getYear(date) === option ? 700 : 500,
              color: theme.palette.primary.highlightedplus,
              padding: "5px 0",
            }}
          >
            {option}
          </option>
        ))}
      </select>

      <div className="react-datepicker__boxselectmonths--custom">
        <button
          type="button"
          className="react-datepicker__button--custom"
          onClick={decreaseMonth}
          style={{ cursor: "pointer" }}
          disabled={prevMonthButtonDisabled}
        >
          <ArrowLeftIconGray />
        </button>

        <select
          className="react-datepicker__selectmonths--custom"
          value={monthsBR[getMonth(date)]}
          onChange={({ target: { value } }) =>
            changeMonth(monthsBR.indexOf(value))
          }
          style={{ fontSize: isMobile ? "1.2rem" : "1.6rem" }}
        >
          {monthsBR.map((option) => (
            <option
              key={option}
              value={option}
              style={{
                fontFamily: theme.typography.fontFamily,
                fontWeight: monthsBR[getMonth(date)] === option ? 700 : 500,
                color: theme.palette.primary.highlightedplus,
                padding: "5px 0",
              }}
            >
              {option}
            </option>
          ))}
        </select>

        <button
          className="react-datepicker__button--custom"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          type="button"
          style={{ cursor: "pointer" }}
        >
          <ArrowRightIconGray />
        </button>
      </div>
    </div>
  );

  useEffect(() => {
    setDateSelected(selectedDate || new Date());
  }, [selectedDate]);

  return (
    <DatePicker
      calendarClassName={classes.datePicker}
      wrapperClassName="datePicker"
      selected={selectedDate}
      onChange={(newDate) => {
        setDateSelected(newDate as Date);
        if (onDateChange) {
          onDateChange(newDate as Date);
        }
      }}
      inline
      locale={ptbr}
      renderCustomHeader={CustomHeader}
      minDate={minDate ? minDate : new Date()}
      filterDate={disableSundays ? isSunday : undefined}
    />
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datePicker: {
      border: "0px solid gainsboro",
      borderRadius: "1rem",
      fontSize: "1.1rem",
      height: "10px",
      "& .react-datepicker__month-container": {
        height: "80%",
      },
      "& .react-datepicker__month": {
        marginBottom: 24,
      },
      "& .react-datepicker__header--custom": {
        borderBottom: "0px solid #aeaeae",
        backgroundColor: "#fff",
      },
      "& .react-datepicker__year-select": {
        lineHeight: 2,
      },
      "& .react-datepicker__selectyears--custom": {
        fontFamily: theme.typography.fontFamily,
        color: "#58595B",
        backgroundColor: "#fff",
        border: "none",
        fontWeight: 600,
        marginLeft: 10,
      },
      "& .react-datepicker__button--custom": {
        color: theme.palette.primary.main,
        backgroundColor: "#fff",
        border: "none",
        padding: 0,
      },
      "& .react-datepicker__boxselectmonths--custom": {
        marginRight: 10,
      },
      "& .react-datepicker__selectmonths--custom": {
        fontFamily: theme.typography.fontFamily,
        color: "#58595B",
        fontSize: "1.6rem",
        fontWeight: 600,
        border: "none",
        appearance: "none",
        textAlign: "center",
        padding: "0 4px 0 4px",
      },
      "& .react-datepicker__day-names": {
        marginBottom: 0,
        marginTop: 16,
        [theme.breakpoints.only("xs")]: {
          marginTop: 6,
        },
      },
      "& .react-datepicker__day-name": {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.primary.light,
        width: "2.1rem", // altera o tamanho da seção dos dias seg, terç, quart
        fontWeight: 700,
        margin: "0.9rem",
        [theme.breakpoints.only("xs")]: {
          width: "1.4rem",
          fontSize: "11px",
          paddindRight: "0.6rem",
        },
      },
      "& .react-datepicker__day": {
        width: "32px",
        height: "32px",
        margin: "3px",
        padding: "8px 2px",
        borderRadius: "100px",
        fontSize: "13px",
        [theme.breakpoints.only("xs")]: {
          // altera o tamanho dos dias
          width: "25px",
          height: "25px",
          margin: "3px",
          padding: "5px 4px",
          borderRadius: "100px",
        },
      },
      "& .react-datepicker__day--selected": {
        filter:
          "drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.06)) drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.08))",
        background: "white",
        color: "#D91F05",
        fontWeight: "bold",
        borderRadius: "100px",
        width: "32px",
        height: "32px",
        margin: "3px",
        padding: "8px 2px",
        fontSize: "13px",
        [theme.breakpoints.only("xs")]: {
          width: "25px",
          height: "25px",
          margin: "3px",
          padding: "5px 4px",
          borderRadius: "100px",
        },
      },

      "& .react-datepicker__day:hover": {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.highlighted,
        borderRadius: "100px",
      },

      "& .react-datepicker__day--keyboard-selected": {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.highlighted,
        borderRadius: "100px",
      },

      "& .react-datepicker__day--range-start": {
        color: `${theme.palette.primary.main} !important`,
      },
      "& .react-datepicker__day--in-range": {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.highlighted,
      },

      "& .react-datepicker__day--outside-month": {
        color: `${theme.palette.text.primary} !important`,
        pointerEvents: "none",
        opacity: 0.5,
      },
    },
  })
);

export default DatePickerGeneric;
