import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import {
  Table as TableV5,
  TableCell as TableCellV5,
  TableContainer as TableContanerV5,
  TableHead as TableHeadV5,
} from "@mui/material";

const TableContainer = styled(TableContanerV5)({});
const TableHead = styled(TableHeadV5)({});

const Table = styled(TableV5)({
  borderSpacing: "0 16px",
  marginTop: "-8px",
  "&.MuiTable-root": {
    borderCollapse: "separate",
    backgroundColor: "#F5F5F5",
    margin: 0,
  },
  "&.MuiTable-root:hover": {
    color: "#000",
    backgroundColor: "#f5f5f5",
  },
});

const TableCell = styled(TableCellV5)({
  background: "#fff",
  borderTop: "1px solid #fff",
  borderBottom: "1px solid #fff",
  height: "24px",
  paddingLeft: "24px",
  "&.MuiTableCell-head": {
    background: "#fff",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  "&:first-child": {
    background: "#fff",
    borderLeft: "1px solid #fff",
    borderBottomLeftRadius: "0.6rem",
    borderTopLeftRadius: "0.6rem",
  },
  "&:last-child": {
    background: "#fff",
    borderRight: "1px solid #fff",
    borderBottomRightRadius: "0.6rem",
    borderTopRightRadius: "0.6rem",
    paddingRight: "32px",
  },
});

export { Table, TableCell, TableContainer, TableHead };

export const useStyles = makeStyles(() => {
  return {
    table: {
      borderSpacing: "0 16px !important",
      marginTop: "-8px !important",
      "&.MuiTable-root": {
        borderCollapse: "separate",
        backgroundColor: "#F5F5F5",
        margin: 0,
      },
      "&.MuiTable-root:hover": {
        color: "#000",
        backgroundColor: "#f5f5f5 !important",
      },
    },
    tableCell: {
      background: "#fff !important",
      borderTop: "1px solid #fff",
      borderBottom: "1px solid #fff !important",
      height: "24px",
      paddingLeft: "24px !important",

      "&.MuiTableCell-head": {
        background: "#fff !important",
        paddingTop: "10px",
        paddingBottom: "10px",
      },
      "&:first-child": {
        // Aplica arredondamento nas bordas apenas na primeira celula de cada linha
        background: "#fff !important",
        borderLeft: "1px solid #fff",
        borderBottomLeftRadius: "0.6rem",
        borderTopLeftRadius: "0.6rem",
      },
      "&:last-child": {
        // Aplica arredondamento nas bordas apenas na ultima celula de cada linha
        background: "#fff !important",
        borderRight: "1px solid #fff",
        borderBottomRightRadius: "0.6rem",
        borderTopRightRadius: "0.6rem",
        paddingRight: "32px",
      },
    },
  };
});
