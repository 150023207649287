/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useIoCContext } from "@context/IoCContext/IoCContext";
import {
  INozzleAdmin,
  INozzleAdminQuery,
  INozzleCompanyModel,
  INozzleRejectModel,
  INozzleValidate,
} from "@pages/Admin/Nozzle/model";

import {
  IGetNozzlesService,
  INozzleAdminResponse,
  INozzleApprovedRequest,
} from "@modules/nozzles/models";

import useDialogAlert from "@hooks/useDialogAlert";
import { Types } from "@ioc/types";
import { IOptionTextFieldSelect } from "@pages/Admin/Nozzle/resource/field/TextFieldSelect";
import AppError from "@utils/AppError";

interface INozzleAdminContext {
  optionsProduct: IOptionTextFieldSelect[];
  loadTable: boolean;
  nozzlesValidate: INozzleValidate[] | undefined;
  nozzlesAdmin: INozzleAdmin[] | undefined;
  nozzleAdminCompany: INozzleCompanyModel[];
  handleFetchApprovedNozzles: (
    nozzleApproved: INozzleApprovedRequest
  ) => Promise<void>;
  handleFetchRejectedNozzles: (
    nozzleRejected: INozzleRejectModel
  ) => Promise<void>;
  handleFetchNozzlesValidate: (cnpj: string) => Promise<void>;
  handleFetchAllNozzlesAdmin: () => Promise<void>;
  handleFetchByFilterNozzlesAdmin: (query: INozzleAdminQuery) => Promise<void>;
}

const NozzleAdminContext = createContext<INozzleAdminContext | undefined>(
  undefined
);

const NozzleAdminProvider: React.FC = ({ children }) => {
  const { snackbar } = useDialogAlert();

  const iocContext = useIoCContext();
  const nozzlesService = iocContext.serviceContainer.get<IGetNozzlesService>(
    Types.Nozzles.IGetNozzlesService
  );

  const [nozzlesAdmin, setNozzlesAdmin] = useState<INozzleAdmin[] | undefined>(
    []
  );

  const [nozzleAdminCompany, setNozzleAdminCompany] = useState<
    INozzleCompanyModel[]
  >([]);

  const [nozzlesValidate, setNozzlesValidate] = useState<
    INozzleValidate[] | undefined
  >([]);

  const [optionsProduct, setOptionsProduct] = useState<
    IOptionTextFieldSelect[]
  >([]);

  const [loadTable, setLoadTable] = useState<boolean>(false);
  const [selectedCNPJ, setSelectedCNPJ] = useState<string | null>(null);
  const [shouldFetchNozzlesValidate, setShouldFetchNozzlesValidate] = useState<
    boolean
  >(false);

  useEffect(() => {
    handleFetchAllNozzlesAdmin();
    handleFetchProductNozzles();
    handleFetchAllCompany();
  }, []);

  const handleFetchAllCompany = useCallback(async () => {
    try {
      const nozzleAdminCompany = await nozzlesService.getNozzlesAdminCompany();
      setNozzleAdminCompany(nozzleAdminCompany);
    } catch (err) {
      if (err instanceof AppError) {
        showErrorSnackbar(
          `Não foi possível buscar COMPANY no modo admin  - ${err.message}`
        );
      }
    }
  }, []);

  const NozzleAdminNormalized = (
    nozzleAdminResponse: INozzleAdminResponse
  ): INozzleAdmin[] => {
    return nozzleAdminResponse.content.map((item) => {
      return {
        companyID: item.company_id ?? "-",
        companyName: item.fantasy_name ?? "-",
        cnpj: item.document ?? "-",
        requestingUser: item.requester_name ?? "-",
        status: item.status ?? "-",
        registrationDate: item.updated_at ?? "-",
        authorizedBy: item.admin_name ?? "-",
        reason: item.reason ?? "-",
      };
    });
  };

  const handleFetchAllNozzlesAdmin = useCallback(async () => {
    try {
      setLoadTable(true);

      const nozzleAdminResponse = await nozzlesService.getNozzlesAdmin();
      const nozzleAdminNormalized = NozzleAdminNormalized(nozzleAdminResponse);

      setNozzlesAdmin(nozzleAdminNormalized);
    } catch (err) {
      if (err instanceof AppError) {
        showErrorSnackbar(
          `Não foi possível buscar os bicos no modo admin  - ${err.message}`
        );
      }
    } finally {
      setLoadTable(false);
    }
  }, []);

  const handleFetchByFilterNozzlesAdmin = useCallback(
    async (query: INozzleAdminQuery) => {
      try {
        setLoadTable(true);

        const nozzleAdminResponse = await nozzlesService.getNozzlesAdmin(query);
        const nozzleAdminNormalized = NozzleAdminNormalized(
          nozzleAdminResponse
        );

        setNozzlesAdmin(nozzleAdminNormalized);
        snackbar({
          message: "Filtro Aplicado com sucesso",
          variant: "success",
        });
      } catch (err) {
        if (err instanceof AppError) {
          showErrorSnackbar(
            `Nao foi possivel FILTRAR! os bicos no modo admin  - ${err.message}`
          );
        }
      } finally {
        setLoadTable(false);
      }
    },
    []
  );

  const handleFetchApprovedNozzles = useCallback(
    async (nozzleApproved: INozzleApprovedRequest) => {
      try {
        await nozzlesService.approvedNozzles(nozzleApproved);
        showSuccessSnackbar(`Solicitação aprovada com sucesso`);
        setShouldFetchNozzlesValidate(true);
      } catch (err) {
        if (err instanceof AppError) {
          showErrorSnackbar(
            `Nao foi possivel concluir a operacao APROVAR bico e produto, ${err.message} (${err.status})`
          );
        }
      }
    },
    []
  );

  useEffect(() => {
    if (selectedCNPJ) {
      // Atualiza a tabela de bicos apos solicitacao de aprovacao ou apos rejeitar
      handleFetchNozzlesValidate(selectedCNPJ);
    }
  }, [shouldFetchNozzlesValidate]);

  const handleFetchNozzlesValidate = useCallback(async (cnpj: string) => {
    try {
      setLoadTable(true);

      const nozzleValidateResponse = await nozzlesService.getNozzles({
        document: cnpj,
      });
      const groupByNozzle = nozzleValidateResponse.map((item) => item.group);
      const nozzleValidadeNormalized = nozzleValidateResponse.map(
        (nozzleValidate, index) => {
          return {
            idPumpNozzle: nozzleValidate.request_id ?? "",
            image: nozzleValidate.image_src ?? "",
            status: nozzleValidate.status ?? "",
            registrationDate:
              nozzleValidate.nozzles.find(
                (item) => item.group === groupByNozzle[index]
              )?.createdAt ?? "",
            pumpNozzle: {
              idGroup: groupByNozzle[index],
              nozzles: nozzleValidate.nozzles
                .filter((item) => item.group === groupByNozzle[index])
                .map((item) => ({
                  idNozzle: item?.nozzle ?? "undefined",
                  productDescription: item?.product?.description ?? "undefined",
                })),
            },
            reject_reason: nozzleValidate.reject_reason ?? "",
          };
        }
      );

      setNozzlesValidate(nozzleValidadeNormalized);
    } catch (err) {
      if (err instanceof AppError) {
        showErrorSnackbar(
          `Erro ao buscar bicos para validacao - ${err.message}`
        );
      }
    } finally {
      setLoadTable(false);
      setSelectedCNPJ(cnpj);
      setShouldFetchNozzlesValidate(false); // Finaliza o fluxo de Atualizacao da tabela de bicos apos a acao de aprovar ou rejeitar
    }
  }, []);

  const handleFetchRejectedNozzles = useCallback(
    async (nozzleRejected: INozzleRejectModel) => {
      try {
        await nozzlesService.rejectNozzles(nozzleRejected);
        showSuccessSnackbar(`Solicitação rejeitada com sucesso`);
        setShouldFetchNozzlesValidate(true);
      } catch (err) {
        if (err instanceof AppError) {
          showErrorSnackbar(
            `Nao foi possivel concluir a operacao REJEITAR bico e produto, ${err.message} (${err.status})`
          );
        }
      }
    },
    []
  );

  const handleFetchProductNozzles = useCallback(async () => {
    try {
      const productResponse = await nozzlesService.getNozzleProduct();
      const productNormalized = productResponse.map((product) => ({
        value: product.id,
        label: product.description,
      }));
      setOptionsProduct(productNormalized);
    } catch (err) {
      if (err instanceof AppError) {
        showErrorSnackbar(
          `Nao foi possivel concluir retornar a lista de produtos - ${err.message}`
        );
      }
    }
  }, []);

  const showSuccessSnackbar = (message: string) => {
    snackbar({
      message,
      variant: "success",
    });
  };

  const showErrorSnackbar = (message: string) => {
    snackbar({
      message,
      variant: "error",
    });
  };

  return (
    <NozzleAdminContext.Provider
      value={{
        nozzlesAdmin,
        nozzlesValidate,
        loadTable,
        optionsProduct,
        nozzleAdminCompany,
        handleFetchAllNozzlesAdmin,
        handleFetchByFilterNozzlesAdmin,
        handleFetchNozzlesValidate,
        handleFetchApprovedNozzles,
        handleFetchRejectedNozzles,
      }}
    >
      {children}
    </NozzleAdminContext.Provider>
  );
};

const useNozzleAdmin = (): INozzleAdminContext => {
  const context = useContext(NozzleAdminContext);
  if (!context) {
    throw new Error(
      "useNozzleAdmin deve ser utilizado dentro de um NozzleAdminProvider"
    );
  }
  return context;
};

export { NozzleAdminProvider, useNozzleAdmin };
