import { Button } from "@material-ui/core";
import { Typography } from "@mui/material";
import React from "react";

import { useNozzleAdmin } from "@pages/Admin/Nozzle/context/NozzleAdminContext";
import { INozzleAdmin, STATUSNOZZLE } from "@pages/Admin/Nozzle/model";

interface Props {
  children?: React.ReactNode;
  nozzleAdmin: INozzleAdmin;
  handle: (cnpjSelected: string) => void;
}

const ActionTableNozzleAdmin: React.FC<Props> = ({
  children,
  nozzleAdmin,
  handle,
}) => {
  const { handleFetchNozzlesValidate } = useNozzleAdmin();

  const handleValidate = (cnpj: string) => {
    handle(cnpj);
    handleFetchNozzlesValidate(cnpj);
  };

  function getStatusMessage(status: string): string {
    switch (status) {
      case STATUSNOZZLE.APPROVED:
      case STATUSNOZZLE.BLOCK:
        return "Ver detalhes";
      case STATUSNOZZLE.WAITING:
        return "Validar";
      default:
        return "Ver detalhes";
    }
  }

  return (
    <Button
      onClick={() => handleValidate(nozzleAdmin?.cnpj)}
      style={{
        color: "#D91F05",
        backgroundColor: "#fff",
        textTransform: "none",
        textAlign: "center",
      }}
    >
      <Typography
        style={{
          fontWeight: "bold",
          fontSize: "12px",
          fontFamily: "Montserrat",
        }}
      >
        {getStatusMessage(nozzleAdmin.status)}
      </Typography>
    </Button>
  );
};

export default ActionTableNozzleAdmin;
