import React from "react";

import GenericPaginatedTable, {
  Column,
} from "@components/TableGenericPagination";

import { IEmployee } from "@pages/User/Employee/model";
import { useAuth } from "@context/auth/AuthContext";
import { TypesPermissions } from "@components/Routes/TypesPermissions";
import { Roles } from "@components/Sidebar/model";

interface TableEmployeeActiveProps {
  data: IEmployee[];
  rowsPerPage: number;
  onRowsPerPageChange: (e) => void;
  onClick: (e) => void;
  labelButton?: string;
  hasLoading?: boolean;
}

const TableEmployeeActive: React.FC<TableEmployeeActiveProps> = ({
  ...props
}) => {
  const theadEmployee: Column<IEmployee>[] = [
    { title: "Id", field: "identifid", align: "left" },
    { title: "Nome", field: "name", align: "left" },
    { title: "Data de nascimento", field: "birthdate", align: "center" },
    { title: "Telefone", field: "telephone", align: "left" },
    { title: "E-mail", field: "email", align: "left" },
    { title: "Posto", field: "fantasy_name", align: "left" },
    { title: "Data de cadastro", field: "createdAt", align: "center" },
    { title: "", field: "blank", align: "center" }, // Coluna Criada para inserir um botao
  ];

  const dataEmpty: IEmployee[] = [
    {
      identifid: "-",
      name: "-",
      birthdate: "-",
      telephone: "-",
      email: "-",
      fantasy_name: "-",
      createdAt: "-",
    },
  ];

  const handleOnClick = (e) => {
    return props.onClick && props.onClick(e);
  };

  const { authByPermission, permissionSet } = useAuth();
  const conditionPermission = authByPermission(
    permissionSet?.SYSTEM_MODULES,
    permissionSet.ROLES?.includes(Roles.ADMIN) || false,
    TypesPermissions.EMPLOYEE
  );

  return (
    <GenericPaginatedTable
      columns={theadEmployee}
      data={props.data.length > 0 ? props.data : dataEmpty}
      rowsPerPage={props.rowsPerPage}
      onRowsPerPageChange={props.onRowsPerPageChange}
      onClick={(e) => handleOnClick(e)}
      labelButton={props.labelButton}
      hasLoading={props.hasLoading}
      hasDisabled={!conditionPermission}
    />
  );
};

export default TableEmployeeActive;
