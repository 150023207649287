import { IGetProjectionSalesDTO } from "@modules/projectionSales/dtos/IGetProjectionSalesDTO";
import { IGetSalesPerformanceDTO } from "@modules/salesPerformance/dtos/IGetSalesPerformanceDTO";
import { IGetSalesVolumeDTO } from "@modules/salesVolume/dto/IGetSalesVolumeDTO";
import { IGetYourSalesIndicatorsDTO } from "@modules/yourSales/dtos/IGetYourSalesDTO";

export const initialIndicators: IGetYourSalesIndicatorsDTO = {
  peakTime: {
    hour: "",
    countTransaction: "",
  },
  dailyAverage: 0,
  salesProjection: 0,
};

export const initialProjectionSales: IGetProjectionSalesDTO = {
  id: "",
  period_at: "",
  value: "",
  createdAt: "",
  updatedAt: "",
  deletedAt: null,
  company_id: "",
  product_id: null,
  remaining_days: 0,
  remaining_sales_per_day: 0,
};

export const initialSalesVolume: IGetSalesVolumeDTO = {
  product_id: "",
  description: "",
  date: "",
  totalVolume: 0,
  totalBilling: 0,
  avgPrice: 0,
  countTransaction: 0,
  avgVolume: 0,
};

export const initialSalesPerformance: IGetSalesPerformanceDTO = {
  employee_id: null,
  name: null,
  identifier: null,
  totalVolume: 0.0,
  totalBilling: 0.0,
};

export interface ISalesVolumePeriodSelectedDTO {
  label?: string;
  value?: string;
}

export interface ISalesVolumeSelectedDTO {
  label?: string;
  value?: string;
}

export interface ISalesPerformanceSelectedDTO {
  label?: string;
  value?: string;
}

//  TODO: Padronizar todas as interfaces para ISelect pois são os mesmos parâmetros
export interface ISelect {
  label?: string;
  value?: string;
}
