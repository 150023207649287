import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { IDriveCustomerService } from "../model/IDriverCustomerService";
import { IQueryTableDriveCustomerDTO } from "../dto/IQueryTableDriveCustomerDTO";
import { IDriveCustomerResponse } from "../dto/IDriveCustomerResponse";

@injectable()
export class DriverCustomerService implements IDriveCustomerService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(
    data: IQueryTableDriveCustomerDTO
  ): Promise<IDriveCustomerResponse> {
    try {
      const salesPerformanceResponse = await this.httpInstance.get<
        IDriveCustomerResponse
      >(`/customer?page=${data.page}&limit=${data.limit}`);

      return salesPerformanceResponse;
    } catch (err) {
      throw err;
    }
  }
}
