import {
  INozzleAdminCompanyResponse,
  INozzleAdminResponse,
  INozzleApprovedRequest,
  INozzleProductResponse,
  INozzleRejectRequest,
} from "@modules/nozzles/models";

export interface INozzleApprovedModel extends INozzleApprovedRequest {}
export interface INozzleRejectModel extends INozzleRejectRequest {}
export interface INozzleAdminModel extends INozzleAdminResponse {}
export interface INozzleProductModel extends INozzleProductResponse {}
export interface INozzleCompanyModel extends INozzleAdminCompanyResponse {}

export interface INozzleAdmin {
  companyID?: string;
  companyName: string;
  cnpj: string;
  requestingUser: string;
  registrationDate: string;
  authorizedBy: string;
  status: string;
  reason?: string;
}

export interface INozzleGroup {
  idNozzle: string;
  productDescription: string;
}

interface PumpNozzle {
  idGroup: string;
  nozzles: INozzleGroup[];
}

// objeto usado na tabela de validacao
export interface INozzleValidate {
  idPumpNozzle: string;
  pumpNozzle: PumpNozzle; // bocal da bomba = bico
  image: string;
  status: string;
  registrationDate?: string; // Data de Cadastro
  reject_reason?: string; // Motivo da recusa
}

export interface INozzleAdminQuery {
  cnpj?: string[];
  status?: string;
  registrationDate?: [null, null];
}

export enum STATUSNOZZLE {
  BLOCK = "REJECTED",
  WAITING = "IN_PROGRESS",
  APPROVED = "DONE",
}

export const nozzlesMock: INozzleAdmin[] = [
  {
    companyName: "Posto Ponta Negra",
    cnpj: "31860017000142",
    requestingUser: "John Doe",
    registrationDate: "2024-01-17T12:34:56.789Z",
    authorizedBy: "Admin",
    status: "IN_PROGRESS",
  },
  {
    companyName: "Company B",
    cnpj: "23456789012345",
    requestingUser: "Jane Smith",
    registrationDate: "2024-02-15T12:34:56.789Z",
    authorizedBy: "Manager",
    status: "REJECTED",
    reason:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica e de impressos, e vem sendo utilizado desde o século XVI, quando um impressor desconhecido pegou uma bandeja de tipos e os embaralhou para fazer um livro de modelos de tipos.",
  },
  {
    companyName: "Company C",
    cnpj: "34567890123456",
    requestingUser: "Bob Johnson",
    registrationDate: "2024-03-30T12:34:56.789Z",
    authorizedBy: "Supervisor",
    status: "DONE",
  },
  {
    companyName: "Company D",
    cnpj: "45678901234567",
    requestingUser: "Alice Brown",
    registrationDate: "2024-04-12T12:34:56.789Z",
    authorizedBy: "Admin",
    status: "IN_PROGRESS",
  },
  {
    companyName: "Company E",
    cnpj: "56789012345678",
    requestingUser: "Charlie Davis",
    registrationDate: "2024-05-20T12:34:56.789Z",
    authorizedBy: "Manager",
    status: "REJECTED",
  },
  {
    companyName: "Company F",
    cnpj: "56789012345678",
    requestingUser: "Davis Charlie",
    registrationDate: "2024-05-20T12:34:56.789Z",
    authorizedBy: "Manager",
    status: "REJECTED",
  },
];

export const nozzleValidateMock: INozzleValidate[] = [
  {
    idPumpNozzle: "1",
    registrationDate: "2024-05-20T12:34:56.789Z",
    pumpNozzle: {
      idGroup: "C2",
      nozzles: [
        {
          idNozzle: "22",
          productDescription: "Gasolina comum",
        },
        {
          idNozzle: "23",
          productDescription: "Diesel S-10",
        },
        {
          idNozzle: "24",
          productDescription: "Etanol Hidratado",
        },
      ],
    },
    status: "DONE",
    image:
      "https://atem-nozzles.s3.amazonaws.com/image_nozzles/cb97363a-5446-489f-9fdd-54ba9ff562fc.png",
  },
  {
    idPumpNozzle: "2",
    pumpNozzle: {
      idGroup: "B2",
      nozzles: [
        {
          idNozzle: "9",
          productDescription: "Diesel s10",
        },
        {
          idNozzle: "10",
          productDescription: "Etanol Hidratado",
        },
        {
          idNozzle: "11",
          productDescription: "Gasolina Aditivada",
        },
      ],
    },
    status: "REJECTED",
    image:
      "https://atem-nozzles.s3.amazonaws.com/image_nozzles/faea9c58-6698-41ec-837e-d1e81503701b",
  },
  {
    idPumpNozzle: "3",
    pumpNozzle: {
      idGroup: "D1",
      nozzles: [
        {
          idNozzle: "25",
          productDescription: "Gasolina Comum",
        },
      ],
    },
    status: "IN_PROGRESS",
    image:
      "https://atem-nozzles.s3.amazonaws.com/image_nozzles/de11a572-48d4-46ff-83d5-0463d53862dd.png",
  },
];
