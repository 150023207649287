import React, { useState } from "react";
import { TrashIcon } from "@components/Icons";
import ModalButtonDropOne from "@pages/Admin/Communications/resource/ModalButtonDropOne";

interface PhotoCommunicationProps {
  src: string;
  alt: string;
  onClick: () => void;
}

const PhotoCommunication: React.FC<PhotoCommunicationProps> = ({
  src,
  alt,
  onClick,
}) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        maxWidth: "100%",
        maxHeight: "70vh",
        cursor: "pointer",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {hovered && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "50%",
            padding: "10px",
            zIndex: 1,
          }}
        >
          <ModalButtonDropOne onClick={onClick}>
            <TrashIcon
              style={{
                fontSize: 35,
                color: "red",
                fill: "white",
              }}
            />
          </ModalButtonDropOne>
        </div>
      )}
      <img
        src={src}
        alt={alt}
        style={{
          borderRadius: 10,
          width: "inherit",
          maxWidth: "100%",
          maxHeight: "70vh",
          opacity: hovered ? 0.3 : 1,
        }}
      />
    </div>
  );
};

export default PhotoCommunication;
