import { ReclamationUserProvider } from "@context/ReclamationContext/ReclamationUserContext";
import React from "react";
import Layout from "@components/Layout";
import DetailsReclamationByUser from "./Sections/DetailsReclamationByUser";

const ReclamationDetails: React.FC = () => {
  return (
    <Layout disableToolbar={true}>
      <DetailsReclamationByUser />
    </Layout>
  );
};

const Container: React.FC = () => {
  return (
    <ReclamationUserProvider>
      <ReclamationDetails />
    </ReclamationUserProvider>
  );
};

export default Container;
