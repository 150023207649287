import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  FormHelperText,
  InputBase,
  makeStyles,
  MenuItem,
  Select,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import React, { ReactNode, useCallback, useEffect, useState } from "react";

import {
  IFormSimulateOrder,
  SimulationProduct,
} from "../../../../utils/interfaces";
import { useForm } from "../FormContext";
import { v4 as uuidv4 } from "uuid";
import { useUserState } from "@context/UserContext";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { IGetClientInfoService } from "@modules/orders/models/IGetClientInfoService";
import { Types } from "@ioc/types";
import { useSnackbar } from "notistack";
import {
  IGetCompartmentsResponseDto,
  CompartmentDto,
  IGetSimulationResultDto,
} from "@modules/simulation/dtos/ISimulateLoadDTO";
import { ISimulateLoadService } from "@modules/simulation/models/ISimulateLoadService";
import {
  maskCNPJ,
  maskVehiclePlateMercoSul,
  validatePlate,
} from "@utils/index";
import { TemporaryTableProduct } from "./TemporaryTableProduct";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    color: "#626166",
    padding: "1.6rem",
    border: "none",
    borderRadius: "0.4rem",
    fontSize: 14,
    fontFamily: "Open Sans, sans-serif",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
    },
  },
}));

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: "2.4rem",
      fontWeight: "bold",
    },
    container: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    formContainer: {},
    itemSelect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",

      "& :nth-child(1)": {
        fontSize: "1.6rem",
        fontWeight: "bold",
      },
      "& :nth-child(2)": {
        fontSize: "1.2rem",
      },
    },
    iconsDisabled: {
      opacity: "0.12",
    },
    iconNotDisabled: {
      opacity: "0.6",
    },
  })
);

interface IProductSelecteToExclude {
  [propName: string]: boolean;
}

function missingNo(array: number[], begin = 1) {
  const sorted = array.sort();

  let pivot = 0;

  for (const it of sorted) {
    if (it - pivot > 1) {
      return pivot + 1;
    }
    pivot = it;
  }

  return pivot + 1;
}

interface FormItemsProps {
  handleModal: (resultDTO: IGetSimulationResultDto) => void;
}

const TemporaryFormItems: React.FC<FormItemsProps> = ({ handleModal }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [, setProductSelectedToExclude] = useState<IProductSelecteToExclude>(
    {}
  );
  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<IFormSimulateOrder>();

  const formContext = useForm();

  const userState = useUserState();

  const iocContext = useIoCContext();

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const [products, setProducts] = useState<SimulationProduct[]>([]);
  const [compartments, setCompartments] = useState<
    IGetCompartmentsResponseDto[]
  >([]);
  const [nextAvailableCompartment, setNextAvailableCompartment] = useState<
    number
  >(1);
  const [inverseCompartments, setInverseCompartments] = useState<
    Record<string, IGetCompartmentsResponseDto>
  >({});
  const [inverseProducts, setInverseProducts] = useState<
    Record<string, string>
  >({});
  const [inverseCNPJ, setInverseCNPJ] = useState<Record<string, string>>({});

  const [selectedCenter, setSelectedCenter] = useState<string | null>(null);

  const simulateLoadService = iocContext.serviceContainer.get<
    ISimulateLoadService
  >(Types.Simulation.ISimulateLoad);

  const removeProducts = () => {
    setProductSelectedToExclude({});
    setFieldValue("products", []);
  };

  useEffect(() => {
    setProductSelectedToExclude({});
    setFieldValue("products", []);
  }, [setFieldValue]);

  const simulate = useCallback(async () => {
    try {
      formContext.setLoadingSimulation(true);
      const compartments = values.products.map((e) => {
        if (!e.compartment) {
          throw new Error(`Compartimento não selecionado em um dos itens`);
        }

        if (!e.quantity) {
          throw new Error(
            `Quantidade inválida no compartimento #${e.compartment}`
          );
        }

        return {
          id: parseInt(e.compartment),
          quantity: e.quantity,
          productID: `${e.id}`,
          seq: ("000" + e.compartment).slice(-3),
        } as CompartmentDto;
      });

      if (!values.placa) {
        throw new Error("Você deve selecionar um veículo");
      }

      if (!selectedCenter) {
        throw new Error("Você deve selecionar um CNPJ");
      }

      const selectedCompartments = values.products.map((p) => p.compartment);

      if (new Set(selectedCompartments).size < selectedCompartments.length) {
        throw new Error("Você selecionou mais de um produto por compartimento");
      }

      if (selectedCompartments.length < compartments.length) {
        throw new Error("Você selecionou mais compartimentos do que possui");
      }

      if (selectedCompartments.length < 1) {
        throw new Error("Você não selecionou nenhum compartimento");
      }

      const response = await simulateLoadService.getSimulation({
        center: selectedCenter as string,
        plate: values.placa.replace(/\W+/g, ""),
        compartments,
      });

      handleModal(response);
    } catch (error) {
      enqueueSnackbar((error as Error).message, {
        variant: "error",
      });
    } finally {
      formContext.setLoadingSimulation(false);
    }
  }, [
    enqueueSnackbar,
    formContext,
    handleModal,
    selectedCenter,
    simulateLoadService,
    values.placa,
    values.products,
  ]);

  useEffect(() => {
    setNextAvailableCompartment(
      missingNo(values.products.map((p) => parseInt(p.compartment || "1")))
    );
    values.products.forEach((p) => {
      if (p.compartment) {
        const dueCompartment = inverseCompartments[p.compartment];
        p.arrows = dueCompartment.arrows;
        p.current_arrow = p.current_arrow || 0;

        p.quantity = p.quantity || p.arrows[p.current_arrow];
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.products]);

  useEffect(() => {
    setInverseCNPJ(
      Object.fromEntries(userState.listCNPJ.map((l) => [l.CNPJ, l.companyName]))
    );
  }, [userState.listCNPJ]);

  useEffect(() => {
    async function execute() {
      try {
        const plate = values.placa;
        if (validatePlate(plate)) {
          setFieldValue("products", []);
          setCompartments([]);
          setLoadingPLATE(true);
          const data = await simulateLoadService.getCompartments(plate);
          setCompartments(data);
        }
      } catch (err) {
        enqueueSnackbar("Placa não encontrada.", {
          variant: "error",
        });
      } finally {
        setLoadingPLATE(false);
      }
    }
    execute();
  }, [enqueueSnackbar, setFieldValue, simulateLoadService, values.placa]);

  useEffect(() => {
    setInverseProducts(
      Object.fromEntries(
        products.map((p) => [p.id, `${p.id} - ${p.description}`])
      )
    );
  }, [products]);

  useEffect(() => {
    setInverseCompartments(
      Object.fromEntries(compartments.map((p) => [p.comNumber, p]))
    );
  }, [compartments]);

  useEffect(() => {
    async function fetch() {
      if (!values.CNPJ) return;
      setFieldValue("products", []);
      try {
        formContext.setLoading(true);

        const getClientInfoService = iocContext.serviceContainer.get<
          IGetClientInfoService
        >(Types.Orders.IGetClientInfoService);

        setLoadingCNPJ(true);
        setProducts([]);

        const CNPJData = await getClientInfoService.execute(values.CNPJ, true);

        setProducts(CNPJData.products);

        if (CNPJData.products.length === 0) {
          enqueueSnackbar("Esse CNPJ não possui produtos disponpiveis!", {
            variant: "warning",
          });
        }

        if (CNPJData.withdrawBasis.length === 0) {
          enqueueSnackbar("Esse CNPJ não possui endereços de entrega!", {
            variant: "warning",
          });
          setSelectedCenter(null);
        } else {
          setSelectedCenter(CNPJData.withdrawBasis[0].filialID);
        }

        setLoadingCNPJ(false);
        setFieldValue("freightType", CNPJData.freightType);
      } catch (error) {
        enqueueSnackbar(
          "Ocorreu um erro ao baixar dados do CNPJ selecionado, recarregando página ...",
          {
            variant: "error",
          }
        );
      } finally {
        formContext.setLoading(false);
      }
    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.CNPJ]);

  const [loadingCNPJ, setLoadingCNPJ] = useState(false);
  const [loadingPLATE, setLoadingPLATE] = useState(false);

  const handleAddProduct = (productSelected: string) => {
    const product = (products.find(
      (ele) => ele.id === productSelected
    ) as unknown) as SimulationProduct;

    try {
      product.uuid = uuidv4();
      setFieldValue("products", [
        ...values.products,
        {
          ...product,
          compartment: `${nextAvailableCompartment}`,
        } as SimulationProduct,
      ]);
    } catch (error) {}
  };

  useEffect(() => {
    setProductSelectedToExclude({});
    setFieldValue("products", []);
  }, [setFieldValue]);

  const renderCPNJ = () => {
    return userState.listCNPJ.map((ele) => {
      return (
        <MenuItem
          key={ele.CNPJ}
          value={ele.CNPJ}
          className={classes.itemSelect}
        >
          <Typography>{ele.companyName}</Typography>
          <Typography>{maskCNPJ(ele.CNPJ)}</Typography>
        </MenuItem>
      );
    });
  };

  return (
    <div>
      <Box
        sx={{
          paddingTop: "3.2rem",
          display: isMobile ? "block" : "flex",
          flexDirection: "row",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: isMobile ? "block" : "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: isMobile ? "100%" : "40%",
                marginTop: "2em",
                marginRight: "5em",
              }}
            >
              <FormControl>
                <label>
                  <Typography variant={"body1"} align="left">
                    Razão social
                  </Typography>
                </label>
                <Select
                  id="CNPJ"
                  aria-placeholder="Selecione a razão social"
                  placeholder="Selecione um produto"
                  error={!!errors.CNPJ && !!touched.CNPJ}
                  onBlur={() => setFieldTouched("CNPJ", true)}
                  value={values.CNPJ}
                  displayEmpty={true}
                  renderValue={(selected) =>
                    (selected as { length: number })?.length ? (
                      Array.isArray(selected) ? (
                        (selected.map(maskCNPJ).join(", ") as ReactNode)
                      ) : (
                        <MenuItem
                          key=""
                          value={selected as string}
                          className={classes.itemSelect}
                        >
                          <Typography>
                            {inverseCNPJ[selected as string]}
                          </Typography>
                          <Typography>
                            {maskCNPJ(selected as string)}
                          </Typography>
                        </MenuItem>
                      )
                    ) : (
                      <Typography>Escolha a razão social</Typography>
                    )
                  }
                  onChange={({ target }) => {
                    setFieldValue("CNPJ", target.value);
                  }}
                  name="CNPJ"
                  input={<BootstrapInput style={{ margin: 0 }} />}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem disabled value="">
                    <em>Escolha uma razão social</em>
                  </MenuItem>
                  {renderCPNJ()}
                </Select>
                <FormHelperText error={!!errors.CNPJ && !!touched.CNPJ}>
                  {!!touched.CNPJ && errors.CNPJ}
                </FormHelperText>
                {!loadingCNPJ && !selectedCenter && values.CNPJ && (
                  <Box>
                    <Typography style={{ color: "#D91F05" }}>
                      Esse CNPJ não possui endereços de entrega
                    </Typography>
                  </Box>
                )}
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: isMobile ? "100%" : "40%",
                marginTop: "2em",
              }}
            >
              <label>
                <Typography variant={"body1"} align="left">
                  Placa
                </Typography>
              </label>

              <div
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <Field
                  id="placa"
                  name="placa"
                  placeholder={"Informe a placa do veículo"}
                  onChange={(e: { target: { value: string } }) =>
                    setFieldValue(
                      "placa",
                      maskVehiclePlateMercoSul(e.target.value)
                    )
                  }
                  style={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: "1.6rem",
                    backgroundColor: "white",
                    color: "#626166",
                    padding: "1.6rem",
                    border: "none",
                    borderRadius: "0.4rem",
                    outline: "none",
                    width: isMobile ? "100%" : "70%",
                  }}
                />

                {loadingPLATE && <CircularProgress variant="indeterminate" />}
              </div>
              {touched.placa && errors.placa && (
                <div
                  style={{
                    color: "#D91F05",
                    fontSize: "1.2rem",
                    paddingTop: "0.8rem",
                  }}
                >
                  {errors.placa}
                </div>
              )}
              {compartments.length > 0 && (
                <Box>
                  <Typography>
                    O veículo selecionado possui {compartments.length}{" "}
                    compartimento(s).
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "1.5rem",
              marginTop: "2em",
              marginBottom: "1em",
            }}
          >
            <FormControl>
              <label
                style={{
                  color: "#626166",
                  fontWeight: 600,
                }}
              >
                <Typography variant={"body1"} align="left">
                  Produtos
                </Typography>
              </label>

              <div
                style={{
                  maxWidth: isMobile ? "100%" : "30%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Select
                  aria-placeholder="Selecione um produto"
                  disabled={
                    !compartments ||
                    !products ||
                    (compartments.length === 0 &&
                      products &&
                      products.length === 0)
                  }
                  name="productSelected"
                  id="productSelected"
                  displayEmpty={true}
                  onChange={(event) => {
                    const e = event as { target: { value: string } };
                    setFieldValue("productSelected", e.target.value);

                    if (values.products.length < compartments.length) {
                      handleAddProduct(e.target.value);
                    }
                  }}
                  input={<BootstrapInput />}
                  renderValue={(selected) =>
                    (selected as { length: number })?.length ? (
                      Array.isArray(selected) ? (
                        (selected
                          .map((p) => inverseProducts[p])
                          .join(", ") as ReactNode)
                      ) : (
                        (inverseProducts[selected as string] as ReactNode)
                      )
                    ) : (
                      <Typography>Escolha o produto</Typography>
                    )
                  }
                  placeholder={
                    !loadingCNPJ
                      ? "Escolha um produto"
                      : "Carregando produtos..."
                  }
                  style={{ width: "100%" }}
                >
                  <MenuItem disabled value="">
                    <em>Escolha um produto</em>
                  </MenuItem>
                  {products.map((prod, index) => (
                    <MenuItem key={index} value={prod.id}>
                      {prod.description}
                    </MenuItem>
                  ))}
                </Select>
                {loadingCNPJ && <CircularProgress variant="indeterminate" />}
              </div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingTop: "2.4rem",
                }}
              >
                <Button
                  disabled={values.products.length >= compartments.length}
                  onClick={() => handleAddProduct(values.productSelected)}
                  variant="contained"
                  color="primary"
                  onKeyPress={(e) => {
                    if (e.keyCode === 13 || e.which === 13) {
                      e.preventDefault();
                      return false;
                    }
                  }}
                  style={{ width: "22rem", textTransform: "uppercase" }}
                >
                  Adicionar Produto
                </Button>
              </Box>
            </FormControl>
          </Box>

          <Box sx={{ paddingTop: "2.8rem" }}>
            <TemporaryTableProduct slots={compartments.length} />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              paddingTop: "2.4rem",
            }}
          >
            <Button
              variant="text"
              color="primary"
              onClick={() => removeProducts()}
            >
              LIMPAR
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: isMobile ? "center" : "left",
              paddingTop: "4.8rem",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={
                !Boolean(values.products.length) ||
                !selectedCenter ||
                !values.placa
              }
              onClick={() => simulate()}
            >
              <Typography>
                {formContext.loadingSimulation
                  ? "Simulando carregamento, aguarde..."
                  : "Simular Carregamento"}
              </Typography>
              {formContext.loadingSimulation && (
                <CircularProgress
                  color="secondary"
                  style={{
                    marginLeft: "1rem",
                    height: "2rem",
                    width: "2rem",
                  }}
                />
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default TemporaryFormItems;
