import { CloseIcon, WarnIcon } from "@components/Icons";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { listSegmentsDescription } from "../ListSegmentsBlock";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      borderRadius: 8,
      padding: theme.spacing(4),
      outline: "none",
      display: "flex",
      flexDirection: "column",
      width: 480,
      [theme.breakpoints.down("xs")]: {
        width: "80%",
      },

      maxHeight: "95vh",
      overflowY: "auto",
    },

    icon: {
      fontSize: "3rem",
      color: theme.palette.error.main,
    },
    columnCheckBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flexStart",
      marginTop: "2rem",
    },
    actionButton: {
      marginTop: theme.spacing(2),
      color: theme.palette.common.white,
      fontWeight: 700,
      textTransform: "none",
      borderRadius: 0,
      marginLeft: "2.4rem",
      "&.block": {
        backgroundColor: theme.palette.primary.main,
      },
      "&.unblock": {
        backgroundColor: theme.palette.success.main,
        "&:hover": {
          backgroundColor: theme.palette.success.dark,
        },
      },
    },
    cancelButton: {
      marginTop: theme.spacing(2),
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      textTransform: "none",
    },
    titleModal: {
      color: theme.palette.text.primary,
      fontWeight: 700,
      fontSize: "2.4rem",
      marginTop: "1rem",
    },
    subTitle: {
      color: theme.palette.text.primary,
      marginTop: "2.4rem",
    },
    description: {
      color: theme.palette.text.primary,
      opacity: 0.5,
    },
    boxDescription: {
      alignItems: "center",
      textAlign: "center",
    },
    containerCloseButton: {},
    smallCheckbox: {
      "& svg": {
        fontSize: 16,
      },
    },
    label: {
      fontSize: "14px",
    },
  })
);

const ProductBlockModal = ({ open, onClose, productInfo, onConfirm }) => {
  const classes = useStyles();
  const isBlocked = productInfo.isBlocked;
  const segmentsSelected = productInfo.segmentsSelected;
  const segmentsAll = productInfo.segmentsAll;
  const actionText = isBlocked ? "Desbloquear" : "Bloquear";

  const [checkedSegments, setCheckedSegments] = useState<
    Record<string, boolean>
  >({});

  const handleCheckboxClick = (segment) => {
    setCheckedSegments((prevState) => ({
      ...prevState,
      [segment]: !prevState[segment],
    }));
  };

  useEffect(() => {
    if (segmentsAll) {
      const initialCheckedSegments: Record<string, boolean> = {};

      // NOTE: Inicializa todos os segmentos como false
      segmentsAll.forEach((segment) => {
        initialCheckedSegments[segment.id] = false;
      });

      // NOTE: Marca como true os segmentos selecionados
      segmentsAll.forEach((segment) => {
        if (segmentsSelected.includes(segment.id)) {
          initialCheckedSegments[segment.id] = true;
        }
      });

      setCheckedSegments(initialCheckedSegments);
    }
  }, [segmentsAll, segmentsSelected]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modal}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            padding: "1.6rem",
            margin: "1.6rem",
          }}
        >
          <CloseIcon onClick={onClose} style={{ cursor: "pointer" }} />{" "}
        </Box>

        <Box className={classes.boxDescription}>
          <Box>
            <WarnIcon
              style={{
                fontSize: "8rem",
                border: "0px solid red",
                marginTop: "1.5rem",
                marginBottom: "1.5rem",
              }}
            />
          </Box>
          <Typography className={classes.titleModal}>
            {`Deseja ${actionText.toLowerCase()} ${productInfo.product}?`}
          </Typography>
          <Typography variant="subtitle1" className={classes.subTitle}>
            <span style={{ fontWeight: 600 }}>{productInfo.product}</span>{" "}
            <span style={{ opacity: 0.5 }}> para afilial de </span>
            <span style={{ fontWeight: 600 }}>{productInfo.branch}</span>
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.description}
          >
            Essa ação poderá ser desfeita posteriormente.
          </Typography>

          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.subTitle}
          >
            <span style={{ fontWeight: 600 }}>
              Quais os segmentos devem ser bloqueados?
            </span>
          </Typography>
        </Box>

        <Box className={classes.columnCheckBox}>
          {segmentsAll.map((segment) => (
            <FormControlLabel
              key={segment.id}
              control={
                <Checkbox
                  className={classes.smallCheckbox}
                  style={{ color: "#D91F05" }}
                  color="primary"
                  value={segment.desc}
                  onChange={() => handleCheckboxClick(segment.id)}
                  checked={checkedSegments[segment.id] || false}
                />
              }
              label={listSegmentsDescription[segment.id]}
              classes={{ label: classes.label }}
            />
          ))}
        </Box>

        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "2.4rem",
            justifyContent: "center",
          }}
        >
          <Button
            variant="text"
            className={classes.cancelButton}
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            className={`${classes.actionButton} ${
              isBlocked ? "unblock" : "block"
            }`}
            onClick={() =>
              onConfirm(
                productInfo.branchIndex,
                productInfo.productIndex,
                isBlocked,
                checkedSegments
              )
            }
          >
            {actionText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProductBlockModal;
