import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";

interface IClearGoalChart {
  yourSales?: number;
  accumulatedGoal?: number;
}

interface IMixGoalChart {
  addedWidth?: number;
  mixGoalWidth?: number;
}

interface ISummaryChart extends IClearGoalChart, IMixGoalChart {}

const ClearGoalChart: React.FC<IClearGoalChart> = ({ ...props }) => {
  const sales = `${props.yourSales}%`;
  const goal = `${props.accumulatedGoal}%`;

  const width = props.yourSales && props.yourSales <= 99.99 ? sales : "99.99%";
  const borderRadius =
    props.yourSales && props.yourSales <= 98.5
      ? "8px 0 0 8px"
      : "8px 8px 8px 8px";

  const classes = useStyles();
  return (
    <Box display="block">
      <Box className={classes.row} display="flex">
        <Box width={width}>
          <Typography className={classes.textDescription}>Sua venda</Typography>
        </Box>
        <Box
          width={goal}
          className={classes.textDescription}
          style={{ marginLeft: `-${sales}` }}
        >
          <Typography
            className={classes.textDescription}
            style={{ textAlign: "right" }}
          >
            Meta acumulada
          </Typography>
        </Box>
      </Box>
      <Box className={classes.row} display="flex">
        <Box width={width} className={classes.yourSaleHeader} />
        <Box
          width={goal}
          className={classes.accumulatedGoalHeader}
          style={{ marginLeft: `-${sales}` }}
        ></Box>
      </Box>
      <Box className={classes.row}>
        <Box className={classes.containerClearGoalChart}>
          <Box
            className={classes.containerYourSale}
            width={width}
            borderRadius={borderRadius}
          >
            <Typography className={classes.textPercentWhite}>
              {sales}
            </Typography>
          </Box>
          <Box
            className={classes.containerAccumulatedGoal}
            width={goal}
            style={{ marginLeft: `-${sales}` }}
          ></Box>
          <Typography className={classes.textPercentOrange}>{goal}</Typography>
        </Box>
      </Box>
      <Box className={classes.row}>
        <Box className={classes.containerEmptyFooter}></Box>
      </Box>
      <Box className={classes.row}>
        <Box className={classes.containerFooter}>
          <Typography className={classes.textDescription}>
            Meta de claros
          </Typography>
        </Box>
      </Box>
      {props.yourSales && props.yourSales > 100 ? (
        <Box className={classes.row}>
          <Box className={classes.containerFooter}>
            <Typography className={classes.sucessGoal}>
              Voce ultrapassou a meta de claros
            </Typography>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

const MixGoalChart: React.FC<IMixGoalChart> = ({ ...props }) => {
  const addedWidth = `${props.addedWidth}%`;
  const mixGoalWidth = `${props.mixGoalWidth}%`;

  const classes = useStyles();
  return (
    <Box display="block">
      <Box className={classes.row} display="flex">
        <Box width={addedWidth}>
          <Typography className={classes.textDescription}>Aditivada</Typography>
        </Box>
        <Box
          className={classes.textDescription}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Typography className={classes.textDescription}>Meta mix</Typography>
        </Box>
      </Box>
      <Box className={classes.row} display="flex">
        <Box width="100%" className={classes.addedHeader} />
      </Box>
      <Box className={classes.row}>
        <Box className={classes.containerClearGoalChart}>
          <Box className={classes.containerAdded} width={addedWidth}>
            <Typography className={classes.textPercentWhite}>
              {addedWidth}
            </Typography>
          </Box>
          <Box
            style={{
              marginLeft: `-${addedWidth}`,
              display: "flex-end",
              backgroundColor: "transparent",
              width: "100%",
            }}
          >
            <Typography
              className={classes.textPercentRed}
              style={{ paddingRight: "9px" }}
            >
              {mixGoalWidth}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SummaryChart: React.FC<ISummaryChart> = ({ ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <Box
      className={classes.containerMain}
      display={isMobile ? "block" : "flex"}
    >
      <Box width={isMobile ? "100%" : "54%"}>
        <ClearGoalChart
          yourSales={props.yourSales ? props.yourSales * 100 : 0}
          accumulatedGoal={
            props.accumulatedGoal ? props.accumulatedGoal * 100 : 0
          }
        />
      </Box>
      <Box width={isMobile ? "100%" : "42%"}>
        <MixGoalChart
          addedWidth={props.addedWidth ? props.addedWidth * 100 : 0}
          mixGoalWidth={props.mixGoalWidth ? props.mixGoalWidth * 100 : 0}
        />
      </Box>
    </Box>
  );
};

export default SummaryChart;

const useStyles = makeStyles(() =>
  createStyles({
    row: {
      width: "100%",
      height: "auto",
    },
    containerMain: {
      justifyContent: "space-between",
      width: "100%",
    },
    yourSaleHeader: {
      borderLeft: "1px solid #D91F05",
      height: "22px",
      marginBottom: "-6.2px",
      position: "relative",
    },
    addedHeader: {
      borderLeft: "1px solid  #FF9900",
      borderRight: "1px solid #D91F05",
      height: "22px",
      marginBottom: "-6.2px",
      position: "relative",
    },
    accumulatedGoalHeader: {
      position: "relative",
      borderRight: "1px dashed #FF9900",
    },
    containerClearGoalChart: {
      display: "flex",
      height: "60px",
      borderRadius: "8px",
      border: "1px solid #D91F05",
      alignItems: "center",
      textAlign: "right",
    },
    containerMixGoalChart: {
      display: "flex",
      height: "60px",
      borderRadius: "8px",
      border: "1px solid #D91F05",
      alignItems: "center",
    },
    containerYourSale: {
      backgroundColor: "#D91F05",
      height: "100%",
      display: "flex",
      textAlign: "left",
      alignItems: "center",
    },
    containerAdded: {
      backgroundColor: "#FF9900",
      height: "100%",
      borderRadius: "8px 0 0 8px",
      display: "flex",
      alignItems: "center",
    },
    containerAccumulatedGoal: {
      backgroundColor: "transparent",
      height: "100%",
      borderRadius: "8px 0 0 8px",
      borderRight: "1px dashed  #FF9900",
      textAlign: "right",
      alignItems: "center",
    },
    textDescription: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#3E3D3D",
    },
    sucessGoal: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "18px",
      color: "#0BB873",
    },
    textPercentWhite: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "20px",
      color: "#FFFFFF",
      paddingLeft: "12px",
    },
    textPercentRed: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "20px",
      color: "#D91F05",
    },
    textPercentOrange: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "20px",
      color: "#FF9900",
      paddingLeft: "9px",
    },
    containerEmptyFooter: {
      width: "100%",
      borderRight: "1px solid #D91F05",
      height: "20px",
      marginTop: "-6.2px",
      position: "relative",
    },
    containerFooter: {
      textAlign: "right",
      width: "100%",
    },
  })
);
