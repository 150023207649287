import React from "react";

import { Button, ButtonProps, Typography } from "@mui/material";
import { useTheme } from "@material-ui/core";

interface BasicButtonProps extends ButtonProps {
  children?: React.ReactNode;
}

const ContainedButton: React.FC<BasicButtonProps> = ({
  children,
  ...props
}) => {
  const theme = useTheme();

  const colorDefault = {
    white: theme.palette.background.paper, // "#F5F5F5"
    red: theme.palette.primary.main, // "#D91F05"
    redDark: "#7f0e00",
  };

  return (
    <Button
      {...props}
      variant="contained"
      sx={{
        textTransform: "capitalize",
        backgroundColor: colorDefault.red,
        padding: "10px 32px",
        "&:hover": { backgroundColor: colorDefault.redDark },
      }}
    >
      <Typography
        variant="body1"
        sx={{
          fontSize: { xs: "10px", sm: "11px", md: "11px", lg: "12px" },
          fontFamily: theme.typography.fontFamily,
          color: colorDefault.white,
          fontWeight: 600,
        }}
      >
        {children}
      </Typography>
    </Button>
  );
};

export default ContainedButton;
