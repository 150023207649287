import { IDataOneReclamationUser } from "@modules/reclamation/dtos/IDataOneReclamationUser";
import { IFilterAllReclamationDTO } from "@modules/reclamation/dtos/IFilterAllReclamationDTO";
import { IFilterAllReclamationData } from "@modules/reclamation/dtos/IFilterAllReclamationData";
import { getValueInLocalStorage } from "@utils/localStorageUtils";
import { endOfMonth, startOfMonth } from "date-fns";
import React, { createContext, useContext, useState } from "react";

interface ReclamationUserContext {
  uploadedImages: File[];
  setUploadedImages: (value: File[]) => void;
  loadingSend: boolean;
  setLoadingSend: (flag: boolean) => void;
  loadingSendAdmin: boolean;
  setLoadingSendAdmin: (flag: boolean) => void;
  loadingDataFilterReclamation: boolean;
  setLoadingDataFilterReclamation: (value: boolean) => void;
  dataQueryReclamationUser: IFilterAllReclamationData;
  setDataQueryReclamationUser(data: IFilterAllReclamationData): void;
  tabActive: number;
  setTabActive: (tab: number) => void;
  totalCount: number;
  setTotalCount: (total: number) => void;
  detailReclamationUser: IDataOneReclamationUser;
  setDetailReclamationUser: (data: IDataOneReclamationUser) => void;
  filterUser: IFilterAllReclamationDTO;
  setFilterUser(filter: IFilterAllReclamationDTO): void;
  filterAdmin: IFilterAllReclamationDTO;
  setFilterAdmin(filter: IFilterAllReclamationDTO): void;
  rangeDate: Date[];
  setRangeDate: React.Dispatch<React.SetStateAction<Date[]>>;
  idRef: string;
  setIdRef: (idRef: string) => void;
  cnpjSelected: string[];
  setCNPJSelected: (cnpj: string[]) => void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const ReclamationUserContext = createContext<ReclamationUserContext>(
  {} as ReclamationUserContext
);
export const IS_RECLAMATION_FINALIZED_LOCAL_STORAGE = "reclamationFinish";

const ReclamationUserProvider: React.FC = ({ children }) => {
  const [rangeDate, setRangeDate] = useState<Date[]>([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ]);
  const [idRef, setIdRef] = useState<string>("");
  const [cnpjSelected, setCNPJSelected] = useState<string[]>([]);
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [loadingSend, setLoadingSend] = useState<boolean>(false);
  const [loadingSendAdmin, setLoadingSendAdmin] = useState<boolean>(false);
  const [
    loadingDataFilterReclamation,
    setLoadingDataFilterReclamation,
  ] = useState<boolean>(false);
  // Só inicia na guia de finalizados se o admin acabou de criar uma reclamação
  const [tabActive, setTabActive] = React.useState<number>(
    getValueInLocalStorage(IS_RECLAMATION_FINALIZED_LOCAL_STORAGE) ? 1 : 0
  );
  const [totalCount, setTotalCount] = useState<number>(0);

  const [dataQueryReclamationUser, setDataQueryReclamationUser] = useState<
    IFilterAllReclamationData
  >({
    content: [],
    total: 0,
    page: 1,
  });

  const [detailReclamationUser, setDetailReclamationUser] = useState<
    IDataOneReclamationUser
  >({
    fullName: "",
    telephoneNumber: "",
    cnpj: "",
    recordType: "",
    observation: "",
    companyName: "",
  });

  const [filterUser, setFilterUser] = useState<IFilterAllReclamationDTO>({
    cnpj: [],
    beginDate: "",
    endDate: "",
    status: "",
    ref_id: "",
    order: "",
  });

  const [filterAdmin, setFilterAdmin] = useState<IFilterAllReclamationDTO>({
    cnpj: [],
    beginDate: "",
    endDate: "",
    status: "",
    ref_id: "",
    order: "",
  });

  return (
    <ReclamationUserContext.Provider
      value={{
        uploadedImages,
        setUploadedImages,
        loadingSend,
        setLoadingSend,
        loadingSendAdmin,
        setLoadingSendAdmin,
        loadingDataFilterReclamation,
        setLoadingDataFilterReclamation,
        dataQueryReclamationUser,
        setDataQueryReclamationUser,
        tabActive,
        setTabActive,
        totalCount,
        setTotalCount,
        detailReclamationUser,
        setDetailReclamationUser,
        filterUser,
        setFilterUser,
        filterAdmin,
        setFilterAdmin,
        rangeDate,
        setRangeDate,
        idRef,
        setIdRef,
        setCNPJSelected,
        cnpjSelected,
      }}
    >
      {children}
    </ReclamationUserContext.Provider>
  );
};

const useReclamationContext = () => {
  const context = useContext(ReclamationUserContext);
  if (Object.values(context).length === 0) {
    throw new Error(
      "useReclamationContext deve ser utilizado dentro de um ReclamationUserProvider"
    );
  }
  return context;
};

export { useReclamationContext, ReclamationUserProvider };
