import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";

import CalendarRange from "@components/CalendarRange";
import { useCommissionCalculatorContext } from "@context/ComissionCalculatorContext/ComissionCalculatorContext";
import { formatNumberToCurrency } from "../../UtilsPerfomanceManagement/utilsPerformanceManagment";

export interface IFilterCommissionCalculator {
  startDate: Date | null;
  date_range: [Date | null, Date | null] | null | undefined;
  endDate: Date | null;
  gasolinaAditivada: number;
  gasolinaComum: number;
  diesels10: number;
  diesels500: number;
  etanol: number;
}

const FilterCommissionCalculator: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<IFilterCommissionCalculator>();

  const {
    setRangeDate,
    isCalendarError,
    setIsCalendarError,
    loadingDataFilterComission,
    setIsFirstSearch,
  } = useCommissionCalculatorContext();

  const [clearValueCalendar, setClearValueCalendar] = useState<boolean>(false);

  const numericValue = (event) => {
    const inputValue = event.target.value.replace(/\D/g, "");
    const numericValue = inputValue === "" ? 0 : parseInt(inputValue, 10);
    return numericValue;
  };

  const handleOnChangeGasolinaAditivada = (event) => {
    setFieldValue("gasolinaAditivada", numericValue(event));
  };

  const handleOnChangeGasolinaComum = (event) => {
    setFieldValue("gasolinaComum", numericValue(event));
  };

  const handleOnChangeDieselS10 = (event) => {
    setFieldValue("diesels10", numericValue(event));
  };

  const handleOnChangeDieselS500 = (event) => {
    setFieldValue("diesels500", numericValue(event));
  };

  const handleOnChangeEtanol = (event) => {
    setFieldValue("etanol", numericValue(event));
  };

  const clearInputsCalculator = () => {
    setIsFirstSearch(true);
    setFieldValue("gasolinaAditivada", 0);
    setFieldValue("gasolinaComum", 0);
    setFieldValue("diesels10", 0);
    setFieldValue("diesels500", 0);
    setFieldValue("etanol", 0);
    setFieldValue("date_range", [null, null]);
    setClearValueCalendar(true);
    setIsCalendarError(false);
  };

  const focusOnInput = (ref) => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  };

  const gasolinaComumRef = useRef(null);
  const gasolinaAditivadaRef = useRef(null);
  const dieselS10Ref = useRef(null);
  const dieselS500Ref = useRef(null);
  const etanolRef = useRef(null);

  useEffect(() => {
    focusOnInput(gasolinaComumRef);
  }, [values.gasolinaComum]);

  useEffect(() => {
    focusOnInput(gasolinaAditivadaRef);
  }, [values.gasolinaAditivada]);

  useEffect(() => {
    focusOnInput(dieselS10Ref);
  }, [values.diesels10]);

  useEffect(() => {
    focusOnInput(dieselS500Ref);
  }, [values.diesels500]);

  useEffect(() => {
    focusOnInput(etanolRef);
  }, [values.etanol]);

  return (
    <Container>
      <Grid
        container
        style={{
          width: "auto",
        }}
      >
        <Grid container spacing={3}>
          <Grid item md={3} xs={12}>
            <CalendarRange
              //@ts-ignore
              name="date_range"
              label="Data ou período"
              value={values.date_range}
              clearValueCalendar={clearValueCalendar}
              onChange={(date_range) => {
                setFieldValue("date_range", date_range);
                setFieldTouched("startDate", false);
                setFieldTouched("endDate", false);
                setRangeDate(
                  date_range?.map((cont) => (cont ? cont : new Date())) ?? []
                );
                setIsCalendarError(false);
              }}
              onBlur={() => {
                setFieldTouched("startDate", true);
                setFieldTouched("endDate", true);
              }}
            />
            {isCalendarError === true && (
              <FormHelperText error={true} style={{ marginLeft: "14px" }}>
                Campo de data é obrigatório
              </FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl
              fullWidth
              className={classes.formControl}
              variant="outlined"
            >
              <TextField
                className={classes.textfield}
                label="Gasolina Comum"
                variant="outlined"
                id="gasolinaComum"
                type="text"
                value={formatNumberToCurrency(values.gasolinaComum)}
                onChange={handleOnChangeGasolinaComum}
                onBlur={() => setFieldTouched("gasolinaComum", true)}
                error={touched.gasolinaComum && Boolean(errors.gasolinaComum)}
                helperText={touched.gasolinaComum && errors.gasolinaComum}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                ref={gasolinaComumRef}
              />
            </FormControl>
            <FormHelperText
              error={!!errors.gasolinaComum && !!touched.gasolinaComum}
              style={{ marginLeft: "14px" }}
            >
              {!!touched.gasolinaComum && errors.gasolinaComum}
            </FormHelperText>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl
              fullWidth
              className={classes.formControl}
              variant="outlined"
            >
              <TextField
                className={classes.textfield}
                label="Gasolina Aditivada"
                variant="outlined"
                id="gasolinaAditivada"
                type="text"
                value={formatNumberToCurrency(values.gasolinaAditivada)}
                onChange={handleOnChangeGasolinaAditivada}
                onBlur={() => setFieldTouched("gasolinaAditivada", true)}
                error={
                  touched.gasolinaAditivada && Boolean(errors.gasolinaAditivada)
                }
                helperText={
                  touched.gasolinaAditivada && errors.gasolinaAditivada
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                ref={gasolinaAditivadaRef}
              />
            </FormControl>
            <FormHelperText
              error={!!errors.gasolinaAditivada && !!touched.gasolinaAditivada}
              style={{ marginLeft: "14px" }}
            >
              {!!touched.gasolinaAditivada && errors.gasolinaAditivada}
            </FormHelperText>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            style={{
              marginBottom: isMobile || isTablet ? "15px" : "",
            }}
          >
            <FormControl
              fullWidth
              className={classes.formControl}
              variant="outlined"
            >
              <TextField
                className={classes.textfield}
                label="Diesel S10"
                variant="outlined"
                id="diesels10"
                type="text"
                value={formatNumberToCurrency(values.diesels10)}
                onChange={handleOnChangeDieselS10}
                onBlur={() => setFieldTouched("diesels10", true)}
                error={touched.diesels10 && Boolean(errors.diesels10)}
                helperText={touched.diesels10 && errors.diesels10}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                ref={dieselS10Ref}
              />
            </FormControl>
            <FormHelperText
              error={!!errors.diesels10 && !!touched.diesels10}
              style={{ marginLeft: "14px" }}
            >
              {!!touched.diesels10 && errors.diesels10}
            </FormHelperText>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: isMobile ? "0px" : "20px",
          }}
        >
          <Grid item xs={12} md={3}>
            <FormControl
              fullWidth
              className={classes.formControl}
              variant="outlined"
            >
              <TextField
                className={classes.textfield}
                label="Diesel S500"
                variant="outlined"
                id="diesels500"
                type="text"
                value={formatNumberToCurrency(values.diesels500)}
                onChange={handleOnChangeDieselS500}
                onBlur={() => setFieldTouched("diesels500", true)}
                error={touched.diesels500 && Boolean(errors.diesels500)}
                helperText={touched.diesels500 && errors.diesels500}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                ref={dieselS500Ref}
              />
            </FormControl>
            <FormHelperText
              error={!!errors.diesels500 && !!touched.diesels500}
              style={{ marginLeft: "14px" }}
            >
              {!!touched.diesels500 && errors.diesels500}
            </FormHelperText>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl
              fullWidth
              className={classes.formControl}
              variant="outlined"
            >
              <TextField
                className={classes.textfield}
                label="Etanol"
                variant="outlined"
                id="etanol"
                type="text"
                value={formatNumberToCurrency(values.etanol)}
                onChange={handleOnChangeEtanol}
                onBlur={() => setFieldTouched("etanol", true)}
                error={touched.etanol && Boolean(errors.etanol)}
                helperText={touched.etanol && errors.etanol}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                ref={etanolRef}
              />
            </FormControl>
            <FormHelperText
              error={!!errors.etanol && !!touched.etanol}
              style={{ marginLeft: "14px" }}
            >
              {!!touched.etanol && errors.etanol}
            </FormHelperText>
          </Grid>

          <Grid item xs={12} md={3}>
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                justifyContent: isMobile ? "center" : "flex-end",
              }}
            >
              <Grid item xs={5} md={6}>
                <Button
                  type="button"
                  onClick={clearInputsCalculator}
                  style={{
                    fontFamily: "Montserrat",
                    borderRadius: "2px",
                    fontWeight: "bold",
                    textTransform: "none",
                    background: "white",
                    color: "#D91F05",
                    width: isMobile ? "100px" : "140px",
                    height: "56px",
                    boxShadow: "none",
                    fontSize: isMobile ? "11px" : "14px",
                  }}
                >
                  Limpar
                </Button>
              </Grid>

              <Grid item xs={6} md={6}>
                <Button
                  type="submit"
                  style={{
                    fontFamily: "Montserrat",
                    borderRadius: "2px",
                    fontWeight: "bold",
                    textTransform: "none",
                    background: "#D91F05",
                    color: "#FFF",
                    width: isMobile ? "100px" : "140px",
                    height: "56px",
                    boxShadow: "none",
                    fontSize: isMobile ? "11px" : "14px",
                  }}
                  startIcon={
                    loadingDataFilterComission && (
                      <CircularProgress
                        color="secondary"
                        style={{ height: "1.5rem", width: "1.5rem" }}
                      />
                    )
                  }
                >
                  {loadingDataFilterComission ? "Calculando" : "Calcular"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FilterCommissionCalculator;

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "bold",
      lineHeight: "normal",
      paddingTop: "20px",
    },
    inputLabel: {
      position: "absolute",
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "1.6rem",
      backgroundColor: "#fff",
      paddingLeft: "0.2rem",
      paddingRight: "0.8rem",
      marginLeft: "0rem",
      width: "auto",
    },
    textfield: {
      width: "100%",
      fontFamily: "Montserrat",
      "& label": {
        color: "#3E3D3D",
        fontWeight: 400,
        fontSize: "1.6rem",
        borderRadius: "8px",
        opacity: "0.6",
      },
      "& .MuiInputLabel-shrink": {
        fontSize: "1.6rem",
        fontWeight: 700,
        color: "#3E3D3D",
        opacity: 1,
        backgroundColor: "#fff",
        paddingRight: 6,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        opacity: "0.6",
      },
    },
    itemSelect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    itemSelectTitle: {
      fontSize: "1.6rem",
      fontWeight: "bold",
    },
    itemSelectSubTitle: {
      fontSize: "1.2rem",
    },
    customSelect: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      marginTop: "0",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
      paddingLeft: "14px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(114, 101, 81, 0.20)",
      },

      "& .MuiSvgIcon-root": {
        fill: "#D91F05",
      },

      "&:focus": {
        backgroundColor: "#FFFF",
      },
      borderRadius: "8px",
    },
    formControl: {
      borderRadius: "8px",
      height: "56px",
      width: "100%",
    },
    gridInput: {
      marginRight: "2rem",
      marginBottom: "2rem",
      height: "50px",
    },

    snackbar: {
      backgroundColor: "orange",
    },
  })
);
