import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  createStyles,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import clsx from "clsx";
import { isBefore, isSameDay } from "date-fns";
import { useFormikContext } from "formik";
import React from "react";
import razaoSocialImg from "../../../assets/razao-social.png";
import { useUserState } from "../../../context/UserContext";
import { maskCNPJ } from "../../../utils";
import { useFormQuery } from "./FormQueryContext";
import { IQuery } from "./interface";

const useStyles = makeStyles((theme) =>
  createStyles({
    itemSelect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    itemSelectTitle: {
      fontSize: "1.6rem",
      fontWeight: "bold",
    },
    itemSelectSubTitle: {
      fontSize: "1.2rem",
    },
    iconsDisabled: {
      opacity: "0.12",
    },
    iconNotDisabled: {
      opacity: "0.6",
    },
    selectedChip: {
      backgroundColor: theme.palette.primaryLight.main,
      color: theme.palette.primary.main,
      "&:focus": {
        backgroundColor: theme.palette.primaryLight.main,
      },
    },
    labelChip: { marginBottom: "0.5rem" },
    chip: {
      margin: "1rem",
    },
  })
);

const FormQuery: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<IQuery>();

  const userState = useUserState();
  const formQueryContext = useFormQuery();

  const renderCPNJ = () => {
    return userState.listCNPJ.map((ele, idx) => {
      return (
        <MenuItem
          key={ele.BpID ? ele.BpID : ele.CNPJ}
          value={ele.BpID ? ele.BpID : ele.CNPJ}
          className={classes.itemSelect}
        >
          <Grid container>
            <Grid item xs={12}>
              <Checkbox
                checked={
                  ele.BpID
                    ? values.CNPJ.indexOf(ele.BpID) > -1
                    : values.CNPJ.indexOf(ele.CNPJ) > -1
                }
              />
            </Grid>
            <Grid item>
              <Typography className={classes.itemSelectTitle}>
                {ele.companyName}
              </Typography>
              <Typography className={classes.itemSelectSubTitle}>
                {maskCNPJ(ele.CNPJ)}
              </Typography>
              {ele.BpID && (
                <Typography className={classes.itemSelectSubTitle}>
                  BP: {ele.BpID}
                </Typography>
              )}
            </Grid>
          </Grid>
        </MenuItem>
      );
    });
  };

  return (
    <Grid container justify="center" alignItems="center" spacing={2}>
      <Grid item xs={isMobile ? 12 : 9}>
        <FormControl fullWidth>
          <InputLabel>Razão social</InputLabel>
          <Select
            error={!!errors.CNPJ && !!touched.CNPJ}
            onBlur={() => setFieldTouched("CNPJ", true)}
            value={values.CNPJ}
            multiple
            renderValue={(selected) => (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                }}
              >
                {(selected as string[]).map((value) => {
                  return (
                    <Chip
                      style={{ margin: "0.5rem" }}
                      key={value}
                      label={
                        userState.listCNPJ.find((CNPJ) =>
                          CNPJ.BpID ? CNPJ.BpID === value : CNPJ.CNPJ === value
                        )?.companyName
                      }
                    />
                  );
                })}
              </div>
            )}
            onChange={({ target }) => {
              setFieldValue("CNPJ", target.value);
            }}
            name="CNPJ"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            startAdornment={
              <InputAdornment position="start">
                <img
                  width="24"
                  src={razaoSocialImg}
                  alt="razão social ícone"
                  className={classes.iconNotDisabled}
                />
              </InputAdornment>
            }
          >
            {renderCPNJ()}
          </Select>
          <FormHelperText error={!!errors.CNPJ && !!touched.CNPJ}>
            {!!touched.CNPJ && errors.CNPJ}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={isMobile ? 12 : 3}>
        <InputLabel className={classes.labelChip}>Filtrar por</InputLabel>
        <Grid container justify="space-between">
          <Chip
            label="Data de entrega"
            className={clsx(classes.chip, {
              [classes.selectedChip]: values.filterBy === "deliveryDate",
            })}
            onClick={() => setFieldValue("filterBy", "deliveryDate")}
          />
          <Chip
            label="Data de implantação"
            className={clsx(classes.chip, {
              [classes.selectedChip]: values.filterBy === "implantationDate",
            })}
            onClick={() => setFieldValue("filterBy", "implantationDate")}
          />
          <FormHelperText error={!!errors.filterBy && !!touched.filterBy}>
            {!!touched.filterBy && errors.filterBy}
          </FormHelperText>
        </Grid>
      </Grid>

      <Grid item xs={isMobile ? 12 : 2}>
        <KeyboardDatePicker
          autoOk
          fullWidth
          format="dd/MM/yyyy"
          label="Início"
          value={values.startDate}
          onChange={(date) => {
            setFieldTouched("startDate", true);
            setFieldValue("startDate", date);
          }}
          error={!!errors.startDate && !!touched.startDate}
          helperText={!!touched.startDate && errors.startDate}
          onBlur={() => setFieldTouched("startDate", true)}
        />
      </Grid>
      <Grid item xs={isMobile ? 12 : 2}>
        <KeyboardDatePicker
          fullWidth
          label="Fim"
          autoOk
          format="dd/MM/yyyy"
          value={values.endDate}
          onChange={(date) => {
            setFieldTouched("endDate", true);
            setFieldValue("endDate", date);
          }}
          disabled={!values.startDate}
          shouldDisableDate={(day) => {
            if (day && values.startDate) {
              if (isSameDay(day, values.startDate)) {
                return false;
              }
              return isBefore(day, values.startDate);
            }
            return false;
          }}
          error={!!errors.endDate && !!touched.endDate}
          helperText={!!touched.endDate && errors.endDate}
          onBlur={() => setFieldTouched("endDate", true)}
        />
      </Grid>
      <Grid item xs={isMobile ? 12 : 3}>
        <FormControl fullWidth>
          <InputLabel>Status financeiro</InputLabel>
          <Select
            error={!!errors.financialStatus && !!touched.financialStatus}
            onBlur={() => setFieldTouched("financialStatus", true)}
            value={values.financialStatus}
            onChange={({ target }) => {
              setFieldValue("financialStatus", target.value);
            }}
            name="financialStatus"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            startAdornment={
              <InputAdornment position="start">
                <img
                  width="24"
                  src={razaoSocialImg}
                  alt="razão social ícone"
                  className={classes.iconNotDisabled}
                />
              </InputAdornment>
            }
          >
            <MenuItem value="all">Todos</MenuItem>
            <MenuItem value="approved">Aprovado</MenuItem>
            <MenuItem value="blocked">Bloqueado</MenuItem>
          </Select>
          <FormHelperText
            error={!!errors.financialStatus && !!touched.financialStatus}
          >
            {!!touched.financialStatus && errors.financialStatus}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={isMobile ? 12 : 3}>
        <FormControl fullWidth>
          <InputLabel>Status logístico</InputLabel>
          <Select
            error={!!errors.logisticStatus && !!touched.logisticStatus}
            onBlur={() => setFieldTouched("logisticStatus", true)}
            value={values.logisticStatus}
            onChange={({ target }) => {
              setFieldValue("logisticStatus", target.value);
            }}
            name="logisticStatus"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            startAdornment={
              <InputAdornment position="start">
                <img
                  width="24"
                  src={razaoSocialImg}
                  alt="razão social ícone"
                  className={classes.iconNotDisabled}
                />
              </InputAdornment>
            }
          >
            <MenuItem value="all">Todos</MenuItem>
            <MenuItem value="toBill">Não faturado</MenuItem>
            <MenuItem value="billed">Faturado</MenuItem>
          </Select>
          <FormHelperText
            error={!!errors.logisticStatus && !!touched.logisticStatus}
          >
            {!!touched.logisticStatus && errors.logisticStatus}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item container xs={12}>
        <Button
          color="primary"
          type="submit"
          variant="contained"
          startIcon={
            formQueryContext.loadingQuery && (
              <CircularProgress
                color="secondary"
                style={{ height: 20, width: 20 }}
              />
            )
          }
        >
          Consultar
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormQuery;
