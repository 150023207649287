import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IUpdateOrderDTO } from "../dtos/IUpdateOrderDTO";
import { IUpdateOrderService } from "../models/IUpdateOrderService";

@injectable()
export class UpdateOrderService implements IUpdateOrderService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(query: IUpdateOrderDTO): Promise<{ message: string }> {
    const updateData = {
      requestedDeliveryDate: query.requestedDeliveryDate,
    };
    return this.httpInstance.patch<{ message: string }>(
      `/orders/${query.orderId}`,
      updateData
    );
  }
}
