import React, { useState } from "react";
import { useTheme } from "@material-ui/core";
import {
  TableBody,
  TableHead,
  TableRow,
  Tooltip,
  useMediaQuery,
} from "@mui/material";

import {
  IRoadBlockedLoad,
  IRoadInvoicedLoad,
  IRoadNextLoad,
  IRoadNotLoad,
} from "@pages/User/RoadLoad/model";

import {
  limitRowsPerPageDefault,
  useRoadLoad,
} from "@pages/User/RoadLoad/context/RoadLoadContext";
import { TypographyCaption } from "@pages/User/RoadLoad/resource/typography";
import { Table, TableCell, TableContainer } from "@components/Table";
import PaginationCustom from "@components/Pagination";

import { IQueryRoadLoad } from "@modules/roadLoad/model";

import {
  BlockedRow,
  formatEntry,
  formatHeadEntry,
  multipleHeaders,
  UnavailableRow,
} from "./tableUtils";
import { STATUSROADLOAD } from "@utils/enum";

import { TableLoadsSkeleton } from "./TableSalesOrderSkeleton";
import TableSalesOrderMobile from "./TableSalesOrderMobile";
import TableEmpty from "./TableEmpty";

export interface TableSalesOrderProps {
  children?: React.ReactNode;
  status: STATUSROADLOAD;
  loading?: boolean;
  headers: {
    key: string;
    label: string | (() => React.JSX.Element);
    align?: string;
    width?: string;
  }[];
  rowsCount: number;
  rows:
    | IRoadNextLoad[]
    | IRoadNotLoad[]
    | IRoadInvoicedLoad[]
    | IRoadBlockedLoad[];
  handleFetchRoadLoad?: (query?: IQueryRoadLoad) => void;
  multiple?: boolean;
  hiddenScheduleButton?: boolean;
  amountSalesOrder?: number;
}

// NOTE: Tabela de ordens de venda <> TableRoadLoad <> TableSaleOrder
const TableSalesOrder: React.FC<TableSalesOrderProps> = React.memo((props) => {
  const { headers, rows, loading, status } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return loading ? (
    <TableLoadsSkeleton numberOfSkeletonCol={headers.length} />
  ) : rows.length === 0 ? (
    <TableEmpty status={status} />
  ) : isMobile ? (
    <TableSalesOrderMobile {...props} />
  ) : (
    <TableSalesOrderDesktop {...props} />
  );
});

// Note: renderiza as tabelas de "Nao agendados" e "Bloqueados"
const TableSalesOrderDesktop: React.FC<TableSalesOrderProps> = (props) => {
  const {
    handleFetchRoadLoad,
    multiple,
    headers,
    hiddenScheduleButton,
    rows,
    rowsCount,
    status,
    amountSalesOrder,
  } = props;

  const theme = useTheme();

  const { roadsSelected, updateRoadSelected } = useRoadLoad();

  const [currentPage, setCurrentPage] = useState(
    parseInt(sessionStorage.getItem(`currentPage-${status}`) || "0")
  );

  const handleOnChangePaginationDesktop = (_, desktopPageNext: number) => {
    setCurrentPage(desktopPageNext - 1);
    sessionStorage.setItem(
      `currentPage-${status}`,
      (desktopPageNext - 1).toString()
    );
    handleFetchRoadLoad &&
      handleFetchRoadLoad({
        page: desktopPageNext,
        limit: limitRowsPerPageDefault,
      });
  };

  return (
    <TableContainer sx={{ border: "0px solid red" }}>
      <Table className={"TableRoadLoad-Table"}>
        <TableHead>
          <TableRow>
            {multipleHeaders(multiple, headers, hiddenScheduleButton).map(
              (head, indexHead) => (
                <TableCell
                  key={indexHead}
                  sx={{
                    textAlign: head?.align ?? "left",
                    width: head?.width ?? "auto",
                  }}
                  className={`TableRoadLoad-TableHead-cell-${head.label}`}
                >
                  {formatHeadEntry(head.key, head.label)}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => {
            const unavailableRow = UnavailableRow(row?.schedulable, status);
            const blockRow = BlockedRow(
              roadsSelected,
              row,
              amountSalesOrder ?? 0
            );
            const isDisabled = unavailableRow || blockRow;

            return (
              <TableRow
                key={rowIndex}
                sx={{
                  pointer: "cursor",
                }}
              >
                {multipleHeaders(multiple, headers, hiddenScheduleButton).map(
                  (col, indexCol) => {
                    const key = col.key;
                    const value = row[col.key];

                    return (
                      <TableCell
                        key={indexCol}
                        sx={{
                          textAlign: col?.align ?? "left",
                          border: "0px solid red",
                        }}
                        className={`TableRoadLoad-TableBody-cell-${col.label}`}
                      >
                        {indexCol === 1 && !unavailableRow && blockRow && (
                          <Tooltip
                            open={true}
                            PopperProps={{
                              disablePortal: true,
                              sx: {
                                "& .MuiTooltip-tooltipPlacementTop": {
                                  position: "relative",
                                  top: "50px",
                                  maxWidth: "500px",
                                },
                              },
                            }}
                            title={
                              <TypographyCaption sx={{ fontSize: 14 }}>
                                {" "}
                                Limite maximo de 10 ordens vendas selecionadas
                                atingido !
                              </TypographyCaption>
                            }
                            placement="top"
                          >
                            <span></span>
                          </Tooltip>
                        )}

                        <div style={{ opacity: isDisabled ? 0.4 : 1 }}>
                          {formatEntry(
                            key,
                            value ?? col.label,
                            rowIndex,
                            rows,
                            roadsSelected,
                            updateRoadSelected,
                            status,
                            hiddenScheduleButton,
                            multiple,
                            theme,
                            unavailableRow,
                            isDisabled
                          )}
                        </div>
                      </TableCell>
                    );
                  }
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <PaginationCustom
        className="TableSalesOrder-pagination"
        count={rowsCount}
        page={currentPage}
        rowsPerPage={limitRowsPerPageDefault}
        onChangeHandle={handleOnChangePaginationDesktop}
      />
    </TableContainer>
  );
};

export { TableSalesOrder };
