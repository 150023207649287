import React, { ReactElement, useState } from "react";
import { useTheme } from "@material-ui/core";

import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import ModalCancelScheduling from "../modal/ModalCancelScheduling";
import ModalSchedule from "../modal/ModalSchedule";

import { TypographyCaption } from "../typography";
import { IScheduledLoad } from "../../model";
import { STATUSROADLOAD, STATUSTYPE } from "@utils/enum";
import ModalEditDriver from "../modal/ModalEditDriver";

interface Props<T> {
  row: T;
  rowIndex: number;
  rescheduleAllowed: boolean;
  handleCancelScheduling: (scheduleGroupID: string) => Promise<void>;
  cancelSchedulingAllowed: boolean;
  editDriverAllowed?: boolean;
  status: STATUSROADLOAD;
}

const LongMenu: <T>(props: Props<T>) => ReactElement = (props) => {
  const {
    row,
    rowIndex,
    rescheduleAllowed,
    handleCancelScheduling,
    cancelSchedulingAllowed,
    editDriverAllowed,
  } = props;

  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    {
      allowed: rescheduleAllowed,
      onComponent: (
        <ModalSchedule
          key={rowIndex}
          roadLoadScheduled={(row as IScheduledLoad) ?? undefined}
          isDisable={!row?.["reschedulable"]}
          hiddenIcon
        >
          <TypographyCaption
            sx={{ color: theme.palette.text.primary, ml: "-8px" }}
          >
            Reagendar
          </TypographyCaption>
        </ModalSchedule>
      ),
    },
    {
      allowed: cancelSchedulingAllowed,
      onComponent: (
        <ModalCancelScheduling
          isDisabled={row["salesOrder"].some(
            (item) => item.status === STATUSTYPE.FATURADO
          )}
          scheduleID={row["Ref"]}
          handleCancelScheduling={() =>
            handleCancelScheduling(row["scheduleGroupID"])
          }
          hiddenIcon
        >
          <TypographyCaption
            sx={{ color: theme.palette.text.primary, ml: "-2px" }}
          >
            Cancelar Agendamento
          </TypographyCaption>
        </ModalCancelScheduling>
      ),
    },
    {
      allowed: editDriverAllowed,
      onComponent: (
        <ModalEditDriver schedule={row as IScheduledLoad} hiddenIcon>
          <TypographyCaption
            sx={{ color: theme.palette.text.primary, ml: "0px" }}
          >
            Editar motorista
          </TypographyCaption>
        </ModalEditDriver>
      ),
    },
  ];

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          color: theme.palette.primary.main,
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{ "aria-labelledby": "long-button" }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        sx={{
          "& .MuiMenu-list": {
            border: `1px solid ${theme.palette.background.default}`,
            borderRadius: 2,
          },
        }}
      >
        {options.map((option, index) => (
          <React.Fragment>
            <MenuItem
              key={index}
              disabled={!option.allowed}
              className={`menu-item-${index}`}
            >
              {option.onComponent}
            </MenuItem>
          </React.Fragment>
        ))}
      </Menu>
    </div>
  );
};

export default LongMenu;
