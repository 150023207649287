import CardOption from "@components/CardOption";
import CardOrder from "@components/CardOrder";
import LoadingCardOrder from "@components/CardOrder/LoadingCardOrder";
import CardTitle from "@components/CardTitle";
import LoadingCardTitle from "@components/CardTitle/LoadingCardTitle";
import LoadingCardTitleMobile from "@components/CardTitle/LoadingCardTitleMobile";
import CardTitleMobile from "@components/CardTitleMobile";
import CarouselOptionsService from "@components/CarouselOptionsService";
import ModalLastOrders from "@components/ModalLastOrders";
import { useHomeUserContext } from "@context/HomeUserContext";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Box,
  Button,
  LinearProgress,
  Link,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Theme, useTheme } from "@material-ui/core/styles";
import { IGetCardTitleDTO } from "@modules/cardTitles/dtos/IGetCardTitleDTO";
import { IGetCardTitleService } from "@modules/cardTitles/models/IGetCardTitleService";
import { ICommunicationService } from "@modules/communications/model/ICommunication";
import { IGetLastOrdersDTO } from "@modules/lastOrders/dtos/IGetLastOrdersDTO";
import { IGetLastOrdersService } from "@modules/lastOrders/models/IGetLastOrdersService";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link as LinkRouter } from "react-router-dom";
import newsImg from "../../../assets/campanhaFeriasTurbinadasOpaca.png";
import cardNotasImg from "../../../assets/card_notas.png";
import cardNovoPedidoImg from "../../../assets/card_novo_pedido.png";
import cardPedidosImg from "../../../assets/card_pedidos.png";
import cardTitulosImg from "../../../assets/card_titulos.png";
import logoAtem from "../../../assets/logo-atem.svg";
import Layout from "../../../components/Layout";
import ROUTES from "../../../config/routes";

import CarouselBasic, { CarouselBasicItem } from "@components/CarouselBasic";
import { CactusIcon } from "@components/Icons";
import { TypesPermissions } from "@components/Routes/TypesPermissions";
import { useAuth } from "@context/auth/AuthContext";
import AppError from "@utils/AppError";
import { generatePermissionMatcher } from "@utils/index";
import { useFlags } from "flagsmith/react";

const services = [
  {
    image: cardNovoPedidoImg,
    title: "Novo Pedido",
    description: "Faça um novo pedido agora mesmo",
    link: ROUTES.USER_ROUTES.NEW_ORDER,
  },
  {
    image: cardPedidosImg,
    title: "Seus pedidos",
    description: "Acompanhe todos os seus pedidos já realizados.",
    link: ROUTES.USER_ROUTES.MY_ORDERS,
  },
  {
    image: cardTitulosImg,
    title: "Consultar títulos",
    description: "Consulte as vias dos seus boletos que estão em aberto.",
    link: ROUTES.USER_ROUTES.APPOINTMENT_TITLE,
  },
  {
    image: cardNotasImg,
    title: "Consultar notas fiscais",
    description: "Veja as suas notas fiscais.",
    link: ROUTES.USER_ROUTES.APPOINTMENT_INVOICES,
  },
];

const HomePage = () => {
  const { permissionSet } = useAuth();
  const conditionAuthGuardTitle = permissionSet.SYSTEM_MODULES.some(
    generatePermissionMatcher(TypesPermissions.APPOINTMENTS_TITLE)
  );
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const flag_modo_user_communique = useFlags([
    "user_home_see_banner_communique",
  ]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const iocContext = useIoCContext();
  const { documentList } = useHomeUserContext();
  const {
    isShowTableLastOrder,
    setIsShowTableLastOrder,
  } = useHomeUserContext();

  const [lastOrders, setLastOrders] = useState<IGetLastOrdersDTO[]>([]);
  const [cardTitles, setCardTitles] = useState<IGetCardTitleDTO[]>([]);

  const [isLoadingOrders, setIsLoadingOrders] = useState(true);
  const [isLoadingTitles, setIsLoadingTitles] = useState(true);
  const [openModalLastOrders, setOpenModalLastOrders] = React.useState(false);
  const [indexModalSelect, setIndexModalSelect] = React.useState(-1);

  const [loadingComunication, setLoadingComunication] = useState<boolean>(
    false
  );
  const [communicateds, setCommunicateds] = useState<CarouselBasicItem[]>([]);

  const hasCommunication = communicateds.length > 0;

  let itemSelected = documentList.filter((item) => item.isSelected)[0];
  let documentItemSelect = itemSelected?.cnpj;

  const getLastOrdersService = iocContext.serviceContainer.get<
    IGetLastOrdersService
  >(Types.LastOrders.IGetLastOrdersService);
  const getCardTitleService = iocContext.serviceContainer.get<
    IGetCardTitleService
  >(Types.CardTitle.IGetCardTitleService);

  const fetchAllComunication = useCallback(async () => {
    try {
      setLoadingComunication(true);
      const comunicationdService = iocContext.serviceContainer.get<
        ICommunicationService
      >(Types.Communication.ICommunicationService);
      const payload = await comunicationdService.findAll([documentItemSelect]);
      const communication = payload.map((curr) => ({ imageUrl: curr.path }));
      setCommunicateds(communication);
    } catch (error) {
      console.error(error);
      if (error instanceof AppError) {
        console.error(error.message);
      }
      setCommunicateds([]);
    } finally {
      setLoadingComunication(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentItemSelect]);

  const getLastOrders = useCallback(async () => {
    try {
      if (isShowTableLastOrder) {
        setIsLoadingOrders(true);
      }
      const data = await getLastOrdersService.execute(documentItemSelect);

      setLastOrders(data);
    } catch (error) {
      console.info("Ocorreu um erro ao buscar os últimos pedidos");
    } finally {
      setIsLoadingOrders(false);
    }
  }, [isShowTableLastOrder, getLastOrdersService, documentItemSelect]);

  const getCardTitle = useCallback(async () => {
    try {
      setIsLoadingTitles(true);
      const data = await getCardTitleService.execute(documentItemSelect);
      setCardTitles(data);
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro ao buscar os últimos títulos", {
        variant: "error",
      });
    } finally {
      setIsLoadingTitles(false);
    }
  }, [getCardTitleService, documentItemSelect, enqueueSnackbar]);

  useEffect(() => {
    getLastOrders();
    getCardTitle();
    fetchAllComunication();
  }, [getLastOrders, getCardTitle, fetchAllComunication]);

  const getLastOrdersRef = useRef<any>();
  useEffect(() => {
    getLastOrdersRef.current = getLastOrders;

    if (!isShowTableLastOrder) {
      const interval = setInterval(() => {
        if (getLastOrdersRef.current) {
          getLastOrdersRef.current();
        }
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [getLastOrders, isShowTableLastOrder]);

  const handleOpenModalLastOrders = (index: number) => (event) => {
    setIndexModalSelect(index);
    setOpenModalLastOrders(true);
  };

  const handleCloseModalLastOrders = () => {
    setOpenModalLastOrders(false);
    setIsShowTableLastOrder(true);
  };

  const CommunicationLayer: React.FC = () => {
    return (
      <div
        className={"card-comunicado"}
        style={{ border: "0px solid red", background: "#F5F5F5" }}
      >
        {loadingComunication ? (
          <LinearProgress />
        ) : hasCommunication ? (
          <CarouselBasic items={communicateds} />
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  return (
    <Layout disableFooter>
      {isMobile ? (
        <Grid
          container
          style={{ border: "0px solid red", width: "auto", margin: 14 }}
        >
          <Grid item xs={12} style={{ border: "0px solid blue" }}>
            <Typography
              style={{
                opacity: 0.6,
                padding: 0,
                fontSize: "1.2rem",
                fontWeight: 400,
                color: theme.palette.text.secondary,
              }}
            >
              Bem vindo ao
            </Typography>

            <Typography
              style={{
                fontSize: "2rem",
                fontWeight: 700,
                color: theme.palette.text.primary,
              }}
            >
              Portal do revendedor
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ border: "0px solid blue" }}>
            <CarouselOptionsService items={services} />
          </Grid>
        </Grid>
      ) : (
        <>
          <Box className={classes.containerTitle}>
            <Typography variant="h5" className={classes.title}>
              PORTAL DO REVENDEDOR
            </Typography>
          </Box>
          <Box className={classes.containerOptions}>
            {services.map((value, index) => (
              <CardOption
                image={value.image}
                key={index}
                title={value.title}
                description={value.description}
                link={value.link}
              />
            ))}
          </Box>
        </>
      )}

      <Box className={classes.containerSection}>
        <Grid
          container
          justifyContent="center"
          alignItems={hasCommunication ? "flex-start" : "center"}
        >
          <Box width={isMobile ? "97%" : !hasCommunication ? "99%" : "74%"}>
            <Box className={isMobile ? classes.paperMobile : classes.paper}>
              <Typography
                className={
                  isMobile ? classes.titleSectionMobile : classes.titleSection
                }
                style={{ marginBottom: "20px" }}
              >
                Últimos pedidos
              </Typography>

              {isLoadingOrders && <LoadingCardOrder />}

              {lastOrders.length > 0 &&
                !isLoadingOrders &&
                lastOrders.map((value, index) => (
                  <Box key={index} style={{ marginBottom: 24 }}>
                    <Button
                      type="button"
                      onClick={handleOpenModalLastOrders(index)}
                      className={classes.button}
                    >
                      <CardOrder
                        key={index}
                        numberOrder={value.numberOrder}
                        implantationDate={value.implantationDate}
                        deliveryDate={value.deliveryDate}
                        companyName={value.companyName}
                        vehiclePlate={value.vehiclePlate}
                        statusAtem={value.statusAtem}
                        driverName={value.driverName}
                      />
                    </Button>
                  </Box>
                ))}

              {lastOrders.length === 0 && !isLoadingOrders && (
                <>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      paddingTop:
                        hasCommunication && !isMobile
                          ? "100px"
                          : !hasCommunication && !lastOrders.length
                          ? "30px"
                          : "0px",
                    }}
                  >
                    <CactusIcon
                      style={{
                        width: isMobile ? "50px" : "10rem",
                        height: isMobile ? "50px" : "10rem",
                      }}
                    />
                  </Grid>

                  <Grid container justifyContent="center" alignItems="center">
                    <Typography
                      style={{
                        color: "#3E3D3D",
                        fontFamily: "DM Sans",
                        fontSize: isMobile ? "12px" : "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        marginTop: isMobile
                          ? "20px"
                          : hasCommunication
                          ? "30px"
                          : "40px",
                        marginBottom: isMobile ? "30px" : "40px",
                        textAlign: "center",
                      }}
                    >
                      Nada para exibir nos últimos pedidos do CNPJ selecionado.
                    </Typography>
                  </Grid>
                </>
              )}

              <ModalLastOrders
                open={openModalLastOrders}
                handleOnClose={handleCloseModalLastOrders}
                lastOrder={lastOrders[indexModalSelect]}
              />
              <Box
                style={{
                  textAlign: isMobile
                    ? "center"
                    : !isMobile && !lastOrders.length
                    ? "center"
                    : "right",
                  marginBottom: isMobile ? "30px" : "",
                  marginTop: isMobile ? "0px" : "",
                }}
              >
                <Link
                  component={LinkRouter}
                  to={ROUTES.USER_ROUTES.MY_ORDERS}
                  className={classes.link}
                  underline="none"
                >
                  <Typography className={classes.linkOrdersUnderTable}>
                    Ver todos os pedidos
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>

          {hasCommunication && (
            <Box width={"23%"} display={isMobile ? "none" : "block"}>
              <Box className={isMobile ? classes.paperMobile : classes.paper}>
                <Typography
                  className={
                    isMobile ? classes.titleSectionMobile : classes.titleSection
                  }
                  style={{ marginBottom: "0px", textAlign: "center" }}
                >
                  Comunicados
                </Typography>
                {flag_modo_user_communique.user_home_see_banner_communique
                  .enabled ? (
                  <CommunicationLayer />
                ) : (
                  <img
                    src={newsImg}
                    alt="Imagem Aviso Atem"
                    width={"100%"}
                    height={"390px"}
                    style={{ marginTop: "5px" }}
                  />
                )}
              </Box>
            </Box>
          )}
        </Grid>
      </Box>

      {!conditionAuthGuardTitle ? (
        <></>
      ) : isMobile ? (
        isLoadingTitles ? (
          <LoadingCardTitleMobile />
        ) : (
          <CardTitleMobile title="Títulos" cards={cardTitles} />
        )
      ) : (
        <Box className={classes.containerSection}>
          <Grid container>
            <Box width={"92%"}>
              <Box className={classes.paper}>
                <Typography
                  className={classes.titleSection}
                  style={{ marginBottom: "25px" }}
                >
                  Títulos
                </Typography>
              </Box>
            </Box>
            <Box width={"97.6%"} style={{ marginTop: "-2%" }}>
              {cardTitles.length > 0 ? (
                <>
                  <Box className={classes.cardTitle}>
                    {isLoadingTitles ? (
                      <LoadingCardTitle />
                    ) : (
                      cardTitles.map((value, index) => (
                        <CardTitle
                          key={index}
                          customerName={value.customerName}
                          statusDoc={value.statusDoc}
                          value={value.value}
                          reference={value.reference}
                        />
                      ))
                    )}
                  </Box>
                  <Box style={{ textAlign: "right", width: "97.6%" }}>
                    <Link
                      component={LinkRouter}
                      to={ROUTES.USER_ROUTES.APPOINTMENT_TITLE}
                      className={classes.link}
                      underline="none"
                    >
                      <Typography className={classes.linkTitlesUnderTable}>
                        Ver todos os titulos
                      </Typography>
                    </Link>
                  </Box>
                </>
              ) : (
                !isLoadingTitles && (
                  <Box style={{ textAlign: "left" }}>
                    <Typography className={classes.msgEmptyTitles}>
                      Você não possui títulos em aberto.
                    </Typography>
                  </Box>
                )
              )}
              {isLoadingTitles && (
                <Box style={{ textAlign: "left", display: "flex" }}>
                  <LoadingCardTitle />
                </Box>
              )}
            </Box>
          </Grid>
        </Box>
      )}

      {isMobile &&
      flag_modo_user_communique.user_home_see_banner_communique.enabled ? (
        <CommunicationLayer />
      ) : (
        <>
          {isMobile && (
            <Box>
              <Box className={isMobile ? classes.paperMobile : classes.paper}>
                <Typography
                  className={classes.titleSection}
                  style={{ marginBottom: "2px", paddingLeft: "15px" }}
                  variant="subtitle1"
                >
                  Comunicados
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <img
                  src={newsImg}
                  alt="Imagem Aviso Atem"
                  width={"90%"}
                  style={{ textAlign: "center", marginBottom: "20px" }}
                />
              </Box>
            </Box>
          )}
        </>
      )}

      {isMobile && (
        <Box
          width="100%"
          display="flex"
          textAlign="center"
          alignItems="center"
          justifyContent="center"
          paddingBottom="50px"
        >
          <img alt={"logoAtem"} src={logoAtem} width={37} height={27} />
        </Box>
      )}
    </Layout>
  );
};

export default HomePage;

const marginLeftDefault = "2.4%";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerSection: {
      maxWidth: "98.6%",
      width: "98.6%",
      marginLeft: marginLeftDefault,
      justifyContent: "space-between",
    },
    imgHome: {
      width: "100%",
      height: "100%",
      "& img": {
        width: "50%",
      },
    },
    link: {
      textDecoration: "none",
      fontSize: "2.4rem",
    },
    section: {
      marginTop: "4.8rem",
      "& h6": {
        marginTop: "0.8rem",
      },
    },
    firstSection: {
      marginTop: "0.8rem",
      "& h6": {
        marginTop: "0.8rem",
      },
    },

    paper: {
      padding: theme.spacing(2),
      border: "none",
      width: "100%",
    },
    paperMobile: {
      paddingLeft: 0,
      border: "none",
      width: "100%",
    },
    news: {
      padding: 0,
      border: "none",
      marginRight: 0,
      textAlign: "right",
      height: "354px",
    },

    title: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "56px",
      color: "#3E3D3D",
      paddingBottom: "30px",
      paddingTop: theme.spacing(0.6),
    },
    titleSection: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "22px",
      lineHeight: "31px",
      color: "#3E3D3D",
      paddingLeft: 0,
    },
    titleSectionMobile: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "21px",
      color: " #3E3D3D",
      paddingLeft: "8px",
    },
    subtitleSection: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#3E3D3D",
      opacity: 0.7,
    },
    containerTitle: {
      maxWidth: "98.6%",
      width: "98.6%",
      marginLeft: marginLeftDefault,
      justifyContent: "space-between",
      paddingLeft: theme.spacing(2),
    },
    containerOptions: {
      maxWidth: "98.6%",
      width: "98.6%",
      marginLeft: marginLeftDefault,
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(2),
      height: "auto",
    },
    cardTitle: {
      display: "flex",
      marginLeft: "20px",
    },
    linkOrdersUnderTable: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#D91F05",
      spacing: 12,
    },
    linkTitlesUnderTable: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#D91F05",
      width: "100%",
      marginBottom: "50px",
    },
    msgEmptyTitles: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#3E3D3D",
      opacity: 0.7,
      marginBottom: "50px",
      marginLeft: "20px",
    },
    button: {
      width: "100%",
      textTransform: "inherit",
      padding: 5,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
        borderRadius: 10,
      },
    },
  })
);
