export const TitlesTypes = {
  IQueryTitlesService: Symbol("IQueryTitlesService"),
  IDownloadPDFBoletoTitlesService: Symbol("IDownloadPDFBoletoTitlesService"),
  IGetTitleImagesNotSeenService: Symbol("IGetTitleImagesNotSeenService"),
  IGetTitleImagesSeenOrNotService: Symbol("IGetTitleImagesSeenOrNotService"),
  IMarkImageAsSeenService: Symbol("IMarkImageAsSeenService"),
  IUploadTitleImageService: Symbol("IUploadTitleImageService"),
  ICreatePDFTitlesService: Symbol("ICreatePDFTitlesService"),
  IDownloadAllReportTitlesService: Symbol("IDownloadAllReportTitlesService"),
};
