import React from "react";
import { Skeleton, Grid, Hidden } from "@mui/material";

const TableLoadsSkeleton = ({
  numberOfSkeletons = 5,
  numberOfSkeletonCol = 8,
}) => {
  const flexWidth = numberOfSkeletonCol >= 7 ? "120px" : "150px";

  const renderDesktopSkeleton = () => {
    return (
      <Grid
        container
        className="TableLoadsSkeleton-desktop"
        sx={{
          gap: { xs: 3, lg: 6 },
          justifyContent: "center",
          border: "0px solid red",
          display: "block",
        }}
      >
        {Array.from({ length: numberOfSkeletons }, (_, indexRow) => {
          return (
            <Grid item xs={11.8} key={indexRow}>
              <Grid
                container
                sx={{
                  border: "0px solid blue",
                  justifyContent: "space-around",
                  background: "#fff",
                  p: 2,
                  flexWrap: "nowrap",
                  overflowX: "hidden",
                  gap: 8,
                }}
              >
                {Array.from({ length: numberOfSkeletonCol }, (_, indexCol) => {
                  return (
                    <Grid item key={indexCol}>
                      <Skeleton
                        variant="rectangular"
                        width={flexWidth}
                        height={25}
                        sx={{ border: "0px solid purple" }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const renderMobileSkeleton = () => {
    return (
      <Grid
        container
        className="TableLoadsMobileSkeleton-mobile"
        sx={{
          gap: 2,
          p: 2,
          justifyContent: "center",
        }}
      >
        {Array.from({ length: numberOfSkeletons }, (_, indexRow) => (
          <Grid item xs={12} key={indexRow}>
            <Grid
              container
              sx={{
                border: "0px solid red",
                justifyContent: "center",
                background: "#fff",
                p: 2,
              }}
            >
              <Grid item xs={12} sx={{ textAlign: "center", mb: 1 }}>
                <Skeleton variant="rectangular" width={40} height={40} />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center", mb: 1 }}>
                <Skeleton variant="rectangular" width={150} height={20} />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Skeleton variant="rectangular" width={200} height={20} />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      <Hidden mdUp>{renderMobileSkeleton()}</Hidden>
      <Hidden mdDown>{renderDesktopSkeleton()}</Hidden>
    </>
  );
};

export { TableLoadsSkeleton };
