import {
  IS_RECLAMATION_FINALIZED_LOCAL_STORAGE,
  ReclamationUserProvider,
} from "@context/ReclamationContext/ReclamationUserContext";
import React, { useState } from "react";
import Layout from "@components/Layout";
import DetailsReclamationByUser from "./Sections/DetailsReclamationByUser";
import { Form, Formik } from "formik";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { ISendReclamationCloseService } from "@modules/reclamation/models/ISendReclamationCloseService";
import { Types } from "@ioc/types";
import { enqueueSnackbar } from "notistack";
import AppError from "@utils/AppError";
import ValidationResponseReclamation from "../ReclamationConsultAdminMode/ValidationsYup/ValidationResponseReclamation";
import { ModalSendSuccess } from "@pages/User/Reclamation/Sections/ModalSendSuccess";
import { useHistory } from "react-router-dom";
import ROUTES from "@config/routes";
import { addValueToLocal } from "@utils/localStorageUtils";

const ReclamationDetailsAdminMode: React.FC = () => {
  const iocContext = useIoCContext();
  const history = useHistory();

  const initialValues = {
    responseAdmin: "",
  };

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const responseReclamationClose = iocContext.serviceContainer.get<
    ISendReclamationCloseService
  >(Types.Reclamation.ISendReclamationCloseService);

  const handleSubmit = async (values) => {
    try {
      await responseReclamationClose.execute({
        actionTaken: values.responseAdmin,
      });
      enqueueSnackbar("Reclamação resolvido com sucesso!", {
        variant: "success",
      });
      openModal();
      setTimeout(function () {
        history.replace(ROUTES.ADMIN_ROUTES.RECLAMATION_ANALYSIS);
      }, 3000);
      addValueToLocal(
        IS_RECLAMATION_FINALIZED_LOCAL_STORAGE,
        "GuiaFinalizadosAtiva"
      );
    } catch (error) {
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, { variant: error.variant });
      }
      enqueueSnackbar("Não foi possível fechar reclamação", {
        variant: "error",
      });
    }
  };

  return (
    <Layout disableToolbar={true} disableToolbarMobile={true}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={ValidationResponseReclamation}
      >
        <Form>
          <DetailsReclamationByUser />

          <ModalSendSuccess
            open={modalOpen}
            closeModal={closeModal}
            text={"Registro finalizado com sucesso!"}
            subtext="Seu registro foi finalizado com sucesso. Veja na tabela de registros finalizados."
          />
        </Form>
      </Formik>
    </Layout>
  );
};

const Container: React.FC = () => {
  return (
    <ReclamationUserProvider>
      <ReclamationDetailsAdminMode />
    </ReclamationUserProvider>
  );
};

export default Container;
