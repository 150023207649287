import React, { useState } from "react";
import { useTheme } from "@material-ui/core";
import { Grid, Tooltip } from "@mui/material";

import { RoadLoadRedFullIcon } from "@components/Icons";
import PaginationCustom from "@components/Pagination";
import Checkbox from "@components/CheckBox";

import {
  limitRowsPerPageDefault,
  useRoadLoad,
} from "../../context/RoadLoadContext";
import { TableSalesOrderProps } from "./TableSalesOrder";
import TableEmpty from "./TableEmpty";

import { TableLoadsSkeleton } from "./TableSalesOrderSkeleton";
import {
  BlockedRow,
  formatEntry,
  formatHeadEntry,
  UnavailableRow,
} from "./tableUtils";

import { STATUSROADLOAD } from "@utils/enum";
import ModalCreatedOrder from "../modal/ModalCreatedOrder";
import { TypographyCaption } from "../typography";

interface Props extends TableSalesOrderProps {
  children?: React.ReactNode;
  amountSalesOrder?: number;
}

const TableSalesOrderMobile: React.FC<Props> = (props) => {
  const {
    rows,
    headers,
    status,
    rowsCount,
    loading,
    handleFetchRoadLoad,
    amountSalesOrder,
  } = props;

  const {
    roadsSelected,
    updateRoadSelected,
    roadCreated,
    handleRoadLoadMethod,
  } = useRoadLoad();

  const theme = useTheme();

  // Estado para re-renderização quando a página atual muda.
  const [currentPage, setCurrentPage] = useState(
    parseInt(sessionStorage.getItem(`currentPage-${status}`) || "0")
  );

  const handleOnChangePaginationMobile = (_, pageNext: number) => {
    setCurrentPage(pageNext - 1);
    sessionStorage.setItem(`currentPage-${status}`, (pageNext - 1).toString());
    handleFetchRoadLoad &&
      handleFetchRoadLoad({
        page: pageNext,
        limit: limitRowsPerPageDefault,
      });
  };

  const handleCardClick = (row) => {
    if (isUnscheduled) {
      updateRoadSelected(row);
    }
  };

  const handleCheckboxClick = (event, row) => {
    event.stopPropagation();
    handleCardClick(row);
  };

  const isUnscheduled = status === STATUSROADLOAD.WAITING;

  return loading ? (
    <TableLoadsSkeleton numberOfSkeletonCol={headers.length} />
  ) : rows?.length === 0 ? (
    <TableEmpty status={status} />
  ) : (
    <Grid
      container
      sx={{
        p: 0.8,
        gap: 2.5,
      }}
    >
      {rows.map((row, rowIndex) => {
        const wasSelected = roadsSelected?.some(
          (selectedRow) => selectedRow.order === row.order
        );

        const unavailableRow = UnavailableRow(row?.schedulable, status);
        const blockRow = BlockedRow(roadsSelected, row, amountSalesOrder);

        const isDisabled = unavailableRow || blockRow;

        return (
          <Grid
            item
            xs={12}
            key={rowIndex}
            sx={{
              p: 0.8,
              background: "#fff",
              borderRadius: "8px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              border:
                wasSelected && isUnscheduled
                  ? `1px solid ${theme.palette.primary.main}`
                  : "1px solid #fff",
              cursor: "pointer",
            }}
            onClick={() => !isDisabled && handleCardClick(row)}
          >
            {!unavailableRow && blockRow && (
              <Tooltip
                open={true}
                PopperProps={{
                  disablePortal: true,
                  sx: {
                    "& .MuiTooltip-tooltipPlacementTop": {
                      position: "relative",
                      top: "30px",
                    },
                  },
                }}
                title={
                  <TypographyCaption sx={{ fontSize: 10 }}>
                    {" "}
                    Limite maximo de 10 ordens vendas atingido !
                  </TypographyCaption>
                }
                placement="top"
              >
                <span></span>
              </Tooltip>
            )}

            <Grid
              container
              className={"multipleContainer-mobile"}
              sx={{
                justifyContent: "space-around",
                alignItems: "center",
                gap: 1,
                opacity: isDisabled ? 0.5 : 1,
              }}
            >
              {unavailableRow && (
                <Grid item xs={12} sx={{ height: 10 }}>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      borderBottom: "3px solid red",
                      position: "relative",
                      right: "18px",
                      top: "6px",
                      transform: "rotate(-35deg)",
                      background: "#000",
                      color: "#fff",
                      borderRadius: "4px",
                      textAlign: "center",
                      display: "inline-table",
                      px: 0.4,
                    }}
                  >
                    <TypographyCaption>Indisponivel</TypographyCaption>
                  </Grid>
                </Grid>
              )}

              {isUnscheduled && (
                <Grid item xs={1.5}>
                  <Checkbox
                    checked={wasSelected}
                    onChange={(event) => handleCheckboxClick(event, row)}
                    onClick={(event) => event.stopPropagation()}
                    disabled={isDisabled}
                  />
                </Grid>
              )}

              <Grid item xs={2}>
                {" "}
                <RoadLoadRedFullIcon />{" "}
              </Grid>

              <Grid
                item
                xs={7.5}
                key={rowIndex}
                sx={{ border: "0px solid blue" }}
              >
                {headers.map((head, colIndex) => {
                  return (
                    <Grid container key={colIndex}>
                      <Grid item xs={12}>
                        {formatHeadEntry(head?.key, head?.label)}
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        className={"tableBody-mobile"}
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "scroll",
                          scrollbarWidth: "thin",
                          scrollbarColor: "rgba(0,0,0, 0.05) #fff",
                        }}
                      >
                        {typeof head?.label !== "undefined" &&
                          formatEntry(
                            head?.key,
                            row[head?.key] ?? head?.label,
                            rowIndex,
                            rows,
                            roadsSelected,
                            updateRoadSelected,
                            undefined,
                            undefined,
                            undefined,
                            theme
                          )}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        );
      })}

      {roadCreated && (
        <ModalCreatedOrder
          hasBeenEdit={roadCreated.hasBeenEdit}
          open={roadCreated?.active}
          order={roadCreated?.ref}
          scheduleDateTime={roadCreated?.scheduleResolvedDateTime}
          onClick={handleRoadLoadMethod?.resetRoadCreated}
        />
      )}

      <Grid item xs={12}>
        <Grid container sx={{ justifyContent: "center" }}>
          <Grid item sx={{ pt: 4, pb: 2 }}>
            <PaginationCustom
              className="TableSalesOrderMobile-pagination"
              count={rowsCount}
              page={currentPage}
              rowsPerPage={limitRowsPerPageDefault}
              onChangeHandle={handleOnChangePaginationMobile}
              siblingCount={0}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TableSalesOrderMobile;
