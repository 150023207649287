import React, { useState } from "react";

import { Box, Typography, useMediaQuery } from "@material-ui/core";

import ButtonLinkGeneric from "@components/ButtonLinkGeneric/ButtonLinkGeneric";
import { EndGoalIcon, GoalVolumeIcon, SaleDayIcon } from "@components/Icons";
import TableProjectionSales from "@components/TableProjectionSales";
import { usePerformanceManagement } from "@context/PerformanceManagementContext";
import { useTheme } from "@material-ui/core/styles";
import { Grid, Skeleton } from "@mui/material";
import { getStringMonthYear, numberFormatter } from "@utils/index";
import { useStylesPerformanceManagement } from "..";
import { ModalSalesPerformance } from "./ModalSalesPerformance";

const SalesProjection: React.FC = () => {
  const classes = useStylesPerformanceManagement();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [openModal, setOpenModal] = useState(false);

  const {
    projectionSales,
    isLoadingProjectionSales,
  } = usePerformanceManagement();

  let skeleton = <Skeleton width={120} animation="wave" />;
  let emptyGoal = <span className={classes.emptyGoal}>Sem meta</span>;
  let emptyData = <span className={classes.emptyGoal}>Não cadastrado</span>;

  let value = Number.parseFloat(`${projectionSales?.value}`);

  let goal = projectionSales?.value
    ? `${numberFormatter(value ?? 0)} litros`
    : emptyGoal;

  let salesPerDay = projectionSales?.remaining_sales_per_day
    ? numberFormatter(projectionSales.remaining_sales_per_day ?? 0)
    : emptyData;

  let remainingDays = projectionSales?.remaining_days
    ? `${projectionSales?.remaining_days} dias`
    : emptyData;

  let remainingSalesPerDay = projectionSales?.remaining_sales_per_day
    ? `${salesPerDay} litros`
    : emptyData;

  let dateProjection =
    projectionSales?.period_at &&
    getStringMonthYear(projectionSales?.period_at);

  const dataProjectionSales = [
    {
      icon: <GoalVolumeIcon />,
      title: "Sua meta de volume",
      subtitle: isLoadingProjectionSales ? skeleton : goal,
    },
    {
      icon: <EndGoalIcon />,
      title: "Dias para o fim dessa meta",
      subtitle: isLoadingProjectionSales ? skeleton : remainingDays,
    },
    {
      icon: <SaleDayIcon />,
      title: "Você deve vender por dia",
      subtitle: isLoadingProjectionSales ? skeleton : remainingSalesPerDay,
    },
  ];

  return (
    <>
      <Box className={classes.containerSection}>
        <Typography
          className={classes.titleSection}
          style={{ display: "block" }}
          variant={isMobile ? "subtitle1" : "h5"}
        >
          Projeção de vendas
        </Typography>
        <Typography
          style={{
            color: theme.palette.text.primary,
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20px",
            marginTop: -10,
          }}
        >
          {isLoadingProjectionSales ? skeleton : dateProjection}
        </Typography>

        <Grid style={{ display: "flex" }}>
          <Grid item>
            <TableProjectionSales data={dataProjectionSales} />
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "flex-start",
              paddingTop: "1.8rem",
            }}
          >
            {!isLoadingProjectionSales ? (
              <ButtonLinkGeneric
                text="Editar"
                link="#"
                onChange={() => setOpenModal(true)}
                onClick={() => setOpenModal(true)}
              />
            ) : (
              <Skeleton animation="wave" width={60} />
            )}
          </Grid>
        </Grid>
      </Box>

      <ModalSalesPerformance
        open={openModal}
        closeModal={() => {
          setOpenModal(false);
        }}
      />
    </>
  );
};

export default SalesProjection;
