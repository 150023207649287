import { Icon } from "leaflet";
import "leaflet-rotatedmarker";
import "leaflet/dist/leaflet.css";

import React, { useEffect, useRef, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";

interface IMapOrder {
  latitude: number;
  longitude: number;
}

const MapOrder: React.FC<IMapOrder> = ({ latitude, longitude }) => {
  const [rotationAngle, setRotationAngle] = useState<number>(0);
  const [markerKey, setMarkerKey] = useState<number>(0);
  const prevCoords = useRef<{ latitude: number; longitude: number }>({
    latitude,
    longitude,
  });

  function calculateRotationAngle(
    prevLat: number,
    prevLng: number,
    currLat: number,
    currLng: number
  ): number {
    // Converte as diferenças de latitude e longitude em radianos
    const dLat = (currLat - prevLat) * (Math.PI / 180);
    const dLng = (currLng - prevLng) * (Math.PI / 180);

    // Calcula o ângulo usando a função atan2, que retorna o ângulo entre o eixo x positivo e o ponto (dLng, dLat)
    let angle = Math.atan2(dLng, dLat) * (180 / Math.PI);

    // Ajusta o ângulo para que 0 graus aponte para o norte
    angle = (angle + 360) % 360;

    return angle;
  }

  useEffect(() => {
    const newRotationAngle = calculateRotationAngle(
      prevCoords.current.latitude,
      prevCoords.current.longitude,
      latitude,
      longitude
    );
    setRotationAngle(newRotationAngle);
    prevCoords.current = { latitude, longitude };
  }, [latitude, longitude]);

  useEffect(() => {
    // Atualizar a chave do Marker para forçar a atualização quando o ângulo mudar
    setMarkerKey((prevKey) => prevKey + 1);
  }, [rotationAngle]);

  const iconPinMap = new Icon({
    iconUrl:
      "https://atem-public-assets-files.s3.amazonaws.com/caminhaoAtem.svg",
    iconSize: [30, 80],
    iconAnchor: [15, 70],
    popupAnchor: [-3, -76],
  });

  return (
    <MapContainer
      center={[latitude, longitude]}
      zoom={13}
      style={{ width: "100%", height: "50vh" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker
        key={markerKey}
        position={[latitude, longitude]}
        icon={iconPinMap}
        // @ts-ignore
        rotationAngle={rotationAngle}
        alt="icone caminhão"
      >
        <Popup>Rastreamento da entrega</Popup>
      </Marker>

      <MapUpdater center={[latitude, longitude]} />
    </MapContainer>
  );
};

// Componente interno para atualizar a visão do mapa
// Este componente não renderiza nada no DOM
const MapUpdater: React.FC<{ center: [number, number] }> = ({ center }) => {
  const map = useMap();

  useEffect(() => {
    map.flyTo(center, 14);
  }, [center, map]);

  return null;
};

export default MapOrder;
