import { CloseIcon, DocIcon } from "@components/Icons";
import PaginationDynamic from "@components/PaginationDynamic/PaginationDynamic";
import {
  Box,
  Checkbox,
  Fade,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { CheckOutlined } from "@material-ui/icons";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { formatCurrency, maskCNPJ } from "@utils/index";
import { calcPagesQuantity } from "@utils/pagination";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useMemo, useState } from "react";
import { useFormQueryInvoices } from "./FormQueryInvoicesContext";
import ModalTableInvoices from "./Sections/ModalTableInvoices";
import { TableHeader } from "./TableHeader";

const useStyles = makeStyles((theme) =>
  createStyles({
    paperTable: {
      overflow: "hidden",
      overflowX: "scroll",

      "&::-webkit-scrollbar": {
        height: "0.7rem",
        borderRadius: ".4rem",
        visibility: "hidden",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.grey["300"],
        borderRadius: ".4rem",
        visibility: "visible",
      },
    },
    toolbarOpen: {
      backgroundColor: theme.palette.secondary.main,
      "& p": {
        color: "white",
      },
    },
    paperSituation: {
      padding: "0 1rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paperSituationClosed: {
      color: "#78C357",
      backgroundColor: "rgba(120,195,87,0.12)",
    },
    paperSituationOpen: {
      color: theme.palette.primary.main,
      backgroundColor: "rgba(195,12,12,0.12)",
    },
    selectInvoices: {
      color: "#D91F05",
      fontWeight: 600,
      fontSize: "1.5rem",
      cursor: "pointer",
    },
    footerTextTable: {
      fontWeight: "bold",

      display: "flex",

      "& span": {
        display: "block",
        marginLeft: "1rem",
        fontWeight: "bold",
      },
    },
    footerTextColorClosed: {
      color: "#78C357",
    },
    footerTextColorTotal: {
      color: "#000",
    },
  })
);

const TableInvoices: React.FC = () => {
  const classes = useStyles();
  const formQueryInvoicesContext = useFormQueryInvoices();
  const [openSelected, setOpenSelected] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const { enqueueSnackbar } = useSnackbar();
  const rowsPerPage: number = 10;
  const [openRows, setOpenRows] = useState<boolean[]>([]);
  const [indexClicked, setIndexClicked] = useState<number>(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const {
    invoicesSelectedToDownload,
    setInvoicesSelectedToDownload,
    setCheckAfterDownload,
    checkAfterDownload,
    invoicesSelected,
  } = formQueryInvoicesContext;

  const [openDialog, setOpenDialog] = useState(false);

  const toggleRow = (index: number) => {
    const newOpenRows = [...openRows];
    newOpenRows[index] = !newOpenRows[index];
    setOpenRows(newOpenRows);
  };

  const isSomeSelected = () => {
    const numberOfProductsSelectedToExclude = Object.keys(
      invoicesSelectedToDownload
    ).reduce((previusValue, nextValue) => {
      if (invoicesSelectedToDownload[nextValue]) {
        previusValue += 1;
        return previusValue;
      } else {
        return previusValue;
      }
    }, 0);
    return (
      !!numberOfProductsSelectedToExclude &&
      formQueryInvoicesContext.dataQuery.length >
        numberOfProductsSelectedToExclude
    );
  };

  const selectAll = () => {
    const products = formQueryInvoicesContext.dataQuery.map((ele) => ele.id);
    let excludeAll = { ...invoicesSelectedToDownload };
    for (let i = 0; i < products.length; i++) {
      if (isCheckedAll()) {
        excludeAll = {
          ...excludeAll,
          [products[i]]: false,
        };
        formQueryInvoicesContext.setCheckAllNFE(false);
        setCheckAfterDownload([]);
      } else {
        excludeAll = {
          ...excludeAll,
          [products[i]]: true,
        };
        formQueryInvoicesContext.setCheckAllNFE(true);
        setCheckAfterDownload([...products]);
      }
    }
    setInvoicesSelectedToDownload(excludeAll);
  };

  const isCheckedAll = () => {
    const numberOfProductsSelectedToExclude = Object.keys(
      invoicesSelectedToDownload
    ).reduce((previusValue, nextValue) => {
      if (invoicesSelectedToDownload[nextValue]) {
        previusValue += 1;
        return previusValue;
      } else {
        return previusValue;
      }
    }, 0);
    if (formQueryInvoicesContext.dataQuery.length === 0) return false;
    return (
      formQueryInvoicesContext.dataQuery.length ===
      numberOfProductsSelectedToExclude
    );
  };

  const isSelectedInvoice = (): boolean => {
    return Object.values(invoicesSelectedToDownload).some((invoice) => invoice);
  };

  const handleDownloadSomeSelected = () => {
    if (isSelectedInvoice()) {
      isSomeSelected();
      formQueryInvoicesContext.setOpenModalDownload(true);
    } else {
      enqueueSnackbar("Selecione pelo menos uma nota fiscal", {
        variant: "warning",
      });
    }
  };

  const invoicesToDownload = (item: string) => {
    if (checkAfterDownload.includes(item)) {
      const filteredInvoice = checkAfterDownload.filter(
        (invoice) => invoice !== item
      );
      setCheckAfterDownload(filteredInvoice);
    } else {
      setCheckAfterDownload([...checkAfterDownload, item]);
    }
  };

  const invoicesToDownloadWithoutFilter = (item: string) => {
    setCheckAfterDownload([...checkAfterDownload, item]);
  };

  const countItens = useMemo((): number => {
    return formQueryInvoicesContext.dataQuery.map((ele) => ele.id).length;
  }, [formQueryInvoicesContext.dataQuery]);

  const startIndex = page * rowsPerPage + 1;
  const endIndex = Math.min(startIndex + rowsPerPage - 1, countItens);

  const calculateHeight = () => {
    if (isMobile) {
      return "auto";
    }

    const contentLength = formQueryInvoicesContext.dataQuery?.length || 0;
    return contentLength < 5 ? "auto" : "73vh";
  };

  const removeOptionCheckAll = () => {
    setOpenSelected(false);
  };

  return (
    <div
      className={classes.paperTable}
      style={{
        marginTop: isMobile ? "2.5rem" : "5rem",
        marginBottom: isMobile ? "" : "10px",
      }}
    >
      <Grid
        container
        style={{
          display: "flex",
          gridTemplateColumns: "auto",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Grid item>
          <Typography
            style={{
              fontSize: isMobile ? "1.3rem" : "1.4",
              whiteSpace: "nowrap",
              marginTop: "10px",
              marginBottom: "10px",
            }}
            className={classes.selectInvoices}
            onClick={() => {
              !openSelected
                ? setOpenSelected(!openSelected)
                : handleDownloadSomeSelected();
            }}
          >
            {!openSelected ? `Selecionar` : `Baixar seleções`}
          </Typography>
        </Grid>

        <Grid item>
          {openSelected && (
            <IconButton
              onClick={removeOptionCheckAll}
              style={{
                marginLeft: "10px",
              }}
            >
              <CloseIcon
                style={{
                  color: "#D91F05",
                }}
              />
            </IconButton>
          )}
        </Grid>
      </Grid>

      <TableContainer style={{ height: calculateHeight() }}>
        <Table stickyHeader>
          <TableHead
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <TableRow
              style={{
                backgroundColor: "#FFF",
              }}
            >
              {openSelected && (
                <TableCell
                  style={{
                    backgroundColor: "#FFF",
                  }}
                >
                  <Checkbox
                    style={{
                      color: "#D91F05",
                      padding: isMobile ? "0px" : "",
                    }}
                    color="primary"
                    onClick={() => {
                      selectAll();
                    }}
                    checked={formQueryInvoicesContext.checkAllNFE}
                  />
                </TableCell>
              )}
              {TableHeader.map((item) => (
                <>
                  {isMobile && item.isVisibleMobile && (
                    <TableCell
                      style={{
                        fontSize: isMobile ? "13px" : "14px",
                        backgroundColor: "#FFF",
                      }}
                      align={item.align}
                      colSpan={item.name === "Nº da nota" ? 2 : 1}
                    >
                      {item.name}
                    </TableCell>
                  )}

                  {!isMobile && (
                    <TableCell
                      align={item.align}
                      style={{
                        fontSize: isMobile ? "13px" : "14px",
                        backgroundColor: "#FFF",
                      }}
                    >
                      {item.name}
                    </TableCell>
                  )}
                </>
              ))}
            </TableRow>
            <br />
          </TableHead>

          <TableBody>
            {formQueryInvoicesContext.dataQuery
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((ele, index) => {
                return (
                  <Fade
                    key={ele.id}
                    in={Boolean(formQueryInvoicesContext.dataQuery.length)}
                    timeout={1000}
                  >
                    <>
                      <TableRow style={{ backgroundColor: "#FFF" }}>
                        {openSelected && (
                          <TableCell
                            style={{
                              border: "none",
                              whiteSpace: "nowrap",
                              paddingRight: isMobile ? "0px" : "",
                            }}
                          >
                            <Checkbox
                              style={{
                                color: "#D91F05",
                                paddingLeft: isMobile ? "0px" : "",
                              }}
                              onClick={() => {
                                setInvoicesSelectedToDownload({
                                  ...invoicesSelectedToDownload,
                                  [ele.id]: !invoicesSelectedToDownload[ele.id],
                                });
                                invoicesSelected.includes(ele.id)
                                  ? invoicesToDownloadWithoutFilter(ele.id)
                                  : invoicesToDownload(ele.id);
                              }}
                              checked={
                                invoicesSelectedToDownload[ele.id]
                                  ? true
                                  : false
                              }
                              color="primary"
                            />
                          </TableCell>
                        )}
                        <TableCell
                          style={{ border: "none", whiteSpace: "nowrap" }}
                          align="center"
                        >
                          {!openSelected ? (
                            <DocIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                formQueryInvoicesContext.setOpenModalDownload(
                                  true
                                );
                                setInvoicesSelectedToDownload({
                                  [ele.id]: !invoicesSelectedToDownload[ele.id],
                                });
                              }}
                            />
                          ) : (
                            <>
                              {invoicesSelected.includes(ele.id) ? (
                                <CheckOutlined style={{ color: "#1D8348" }} />
                              ) : (
                                <DocIcon />
                              )}
                            </>
                          )}
                        </TableCell>
                        <TableCell style={{ border: "none" }} align="left">
                          {ele.id}
                        </TableCell>
                        {!isMobile && (
                          <>
                            <TableCell
                              style={{ border: "none" }}
                              align="center"
                            >
                              {format(new Date(ele.emissionDate), "dd/MM/yyyy")}
                            </TableCell>
                            <TableCell
                              style={{ border: "none" }}
                              align="center"
                            >
                              {ele.orderID}
                            </TableCell>
                            <TableCell
                              style={{ border: "none", whiteSpace: "nowrap" }}
                              align="left"
                            >
                              {ele.companyName}
                            </TableCell>
                            <TableCell
                              style={{ border: "none", whiteSpace: "nowrap" }}
                              align="left"
                            >
                              {maskCNPJ(ele.CNPJ)}
                            </TableCell>
                            <TableCell style={{ border: "none" }} align="right">
                              {formatCurrency(ele.value)}
                            </TableCell>
                            <TableCell style={{ border: "none" }}></TableCell>
                          </>
                        )}
                        {isMobile && (
                          <TableCell
                            align="center"
                            style={{
                              border: "none",
                              paddingRight: "0px",
                              paddingLeft: "0px",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                toggleRow(index);
                                setIndexClicked(index);
                                setOpenDialog(true);
                              }}
                            >
                              <ArrowForwardIosIcon color="primary" />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                      <br />
                    </>
                  </Fade>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <ModalTableInvoices
        open={openDialog}
        dataInvoice={(formQueryInvoicesContext.dataQuery || [])[indexClicked]}
        handleOnClose={() => setOpenDialog(false)}
      />

      {isMobile ? (
        <Grid container>
          <Grid
            xs={12}
            item
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <PaginationDynamic
              onPageChange={(event, page) => setPage(page)}
              pagesTotal={calcPagesQuantity(rowsPerPage, countItens)}
              page={page + 1}
            />
          </Grid>

          <Grid xs={12} item style={{ textAlign: "center" }}>
            <Typography
              style={{
                color: "#3E3D3D",
                fontWeight: 600,
                fontSize: "14px",
                marginTop: "10px",
              }}
            >
              {`Mostrando itens `}
              <span style={{ color: "#FFB03A", fontWeight: 600 }}>
                {`${startIndex}-${endIndex}`}
              </span>

              <Typography style={{ fontSize: "1.3rem", color: "#3E3D3D4D" }}>
                {`Total de ${countItens} itens`}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Box
          component="div"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <Typography style={{ color: "#3E3D3D", fontWeight: 600 }}>
            {`Mostrando itens `}
            <span style={{ color: "#FFB03A", fontWeight: 600 }}>
              {`${startIndex}-${endIndex}`}
            </span>

            <Typography style={{ fontSize: "1.3rem", color: "#3E3D3D4D" }}>
              {`Total de ${countItens} itens`}
            </Typography>
          </Typography>

          <PaginationDynamic
            onPageChange={(event, page) => setPage(page)}
            pagesTotal={calcPagesQuantity(rowsPerPage, countItens)}
            page={page + 1}
          />
        </Box>
      )}
    </div>
  );
};

export default TableInvoices;
