import Actions from "./actions/actions";
import State from "./State";

export default function UserReducer(state: State, action: Actions): State {
  switch (action.type) {
    case "APP_SET_USER_LIST_CNPJ": {
      return { ...state, listCNPJ: action.payload.listCNPJ };
    }
    case "TOGGLE_ADMIN": {
      return { ...state, adminMode: !state.adminMode };
    }

    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}
