import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { ICreateOrderDataDTO } from "../dtos/ICreateOrderDataDTO";
import { ICreateOrderDTO } from "../dtos/ICreateOrderDTO";
import { ICreateOrderService } from "../models/ICreateOrderService";

@injectable()
export class CreateOrderService implements ICreateOrderService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: ICreateOrderDTO) {
    const order = await this.httpInstance.post<ICreateOrderDataDTO>(
      "/orders/",
      data
    );
    return order;
  }

  public async executeV2(data: ICreateOrderDTO) {
    const order = await this.httpInstance.post<ICreateOrderDataDTO>(
      "/orders/v2",
      data
    );
    return order;
  }
}
