import { useTheme } from "@material-ui/core";
import { Drawer, Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";

import FormikFilter from "@pages/Admin/Nozzle/resource/filter/FormikFilter";

import ButtonDefault from "@components/ButtonDefault";
import { CloseIcon, FilterIcon } from "@components/Icons";

interface Props {
  children?: React.ReactNode;
}

const DrawerFilterNozzles: React.FC<Props> = ({ children }) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const TypographyHeader = ({ children }) => (
    <Typography
      variant="h3"
      sx={{
        fontFamily: theme.typography.fontFamily,
        fontSize: { xs: 14, md: 16, lg: 22, xl: 26 },
        fontWeight: 700,
      }}
    >
      {children}
    </Typography>
  );

  return (
    <Grid container className="drawer-filter-container">
      <ButtonDefault
        variant="text"
        style={{ fontSize: 16 }}
        onClick={handleOpen}
      >
        {children}
      </ButtonDefault>
      <Drawer
        elevation={16}
        anchor="right"
        open={open}
        onClose={handleClose}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{ border: "0px solid red", zIndex: 1201 }}
      >
        <Grid
          container
          sx={{
            width: { lg: 480, xl: 500 },
            p: 4,
            border: "0px solid blue",
            gap: { xs: 1, md: 2, lg: 3 },
          }}
          style={{ fontSize: 16 }}
        >
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "right", p: 0.5 }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <TypographyHeader>Filtrar dados</TypographyHeader>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent={"center"} sx={{ p: 3 }}>
              <Grid item>
                <FilterIcon style={{ height: 100, width: 100 }} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FormikFilter />
          </Grid>
        </Grid>
      </Drawer>
    </Grid>
  );
};

export default DrawerFilterNozzles;
