import React from "react";
import ContentLoader from "react-content-loader";

const TableLoader: React.FC = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 800 400"
      backgroundColor="#e6e6e6"
      foregroundColor="#fcdede"
    >
      <rect x="10" y="10" rx="3" ry="3" width="180" height="54" />
      <rect x="210" y="10" rx="3" ry="3" width="180" height="54" />
      <rect x="410" y="10" rx="3" ry="3" width="180" height="54" />
      <rect x="610" y="10" rx="3" ry="3" width="180" height="54" />

      <rect x="10" y="80" rx="3" ry="3" width="180" height="54" />
      <rect x="210" y="80" rx="3" ry="3" width="180" height="54" />
      <rect x="410" y="80" rx="3" ry="3" width="180" height="54" />
      <rect x="610" y="80" rx="3" ry="3" width="180" height="54" />

      <rect x="10" y="150" rx="3" ry="3" width="180" height="54" />
      <rect x="210" y="150" rx="3" ry="3" width="180" height="54" />
      <rect x="410" y="150" rx="3" ry="3" width="180" height="54" />
      <rect x="610" y="150" rx="3" ry="3" width="180" height="54" />

      <rect x="10" y="218" rx="3" ry="3" width="180" height="54" />
      <rect x="210" y="218" rx="3" ry="3" width="180" height="54" />
      <rect x="410" y="218" rx="3" ry="3" width="180" height="54" />
      <rect x="610" y="218" rx="3" ry="3" width="180" height="54" />

      <rect x="10" y="290" rx="3" ry="3" width="180" height="54" />
      <rect x="210" y="290" rx="3" ry="3" width="180" height="54" />
      <rect x="410" y="290" rx="3" ry="3" width="180" height="54" />
      <rect x="610" y="290" rx="3" ry="3" width="180" height="54" />
    </ContentLoader>
  );
};

export default TableLoader;
