import React, { useCallback } from "react";
import { Grid } from "@mui/material";

import PhotoCommunication from "@pages/Admin/Communications/resource/PhotoCommunication";
import { ICommunicationData } from "@pages/Admin/Communications/model";

import TextFieldMultiSelect from "@components/TextFieldMultiSelect";
import CalendarPickerCustom from "@components/CalendarCustom";

import { FilialOptionsList, dayPlusOne } from "@utils/interfaces";

interface CardCommunicationProps {
  children?: React.ReactNode;
  index: number;
  communication: ICommunicationData;
  onChange: (newCommunication: ICommunicationData) => void;
  onClickDelete: (index: number) => void;
}

const CardCommunication: React.FC<CardCommunicationProps> = React.memo(
  ({ communication, onChange, index, onClickDelete }) => {
    const handleChangeCenter = useCallback(
      (newCenters) => {
        const newCenter = newCenters.reduce((acc, curr) => {
          acc.push(curr.value);
          return acc;
        }, []);
        onChange({ ...communication, centers: newCenter });
      },
      [communication, onChange]
    );

    const handleChangeDate = useCallback(
      (dateProps) => {
        onChange({ ...communication, expiresIn: dateProps });
      },
      [communication, onChange]
    );

    const convertStringToOptions = (centers: string[]) => {
      const centersSelected = FilialOptionsList.filter((op) =>
        centers.includes(op.value)
      );
      return centersSelected ? centersSelected : [];
    };

    return (
      <Grid
        container
        className={"CardCommunication-container"}
        sx={{ gap: { lg: 3 } }}
      >
        <Grid item xs={12}>
          <TextFieldMultiSelect
            index={index}
            label={"Filial"}
            optionsSelected={convertStringToOptions(communication.centers)}
            options={FilialOptionsList}
            onChange={handleChangeCenter}
          />
        </Grid>

        <Grid item xs={12}>
          <CalendarPickerCustom
            id="expirationDate"
            name={"expirationDate"}
            label={"Data da expiração"}
            value={communication?.expiresIn || null}
            onChange={(e) => handleChangeDate(e)}
            minDate={dayPlusOne}
          />
        </Grid>

        <Grid item xs={12}>
          <PhotoCommunication
            src={communication.imageUrl || ""}
            alt={communication.file.name}
            onClick={() => onClickDelete(index)}
          />
        </Grid>
      </Grid>
    );
  }
);

export default CardCommunication;
