import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

interface Props {
  children?: React.ReactNode;
  RowPerPage: number;
  rowPerPageControl: number;
  RowPerPageOptionsDefault: number[];
  onRowPerPageChange: (event: any) => void;
}

const RowPerPageOptions: React.FC<Props> = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel
        id="demo-simple-select-outlined-label"
        className={classes.inputLabel}
      >
        Número de linhas por página
      </InputLabel>
      <Select
        className={classes.select}
        IconComponent={ExpandMoreIcon}
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={props.rowPerPageControl}
        onChange={props.onRowPerPageChange}
        label="Número de linhas por página"
        renderValue={(value) => {
          return <>{value}</>;
        }}
      >
        {props.RowPerPageOptionsDefault?.map((item, index) => {
          return (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default RowPerPageOptions;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: 0,
      minWidth: 240,
    },
    inputLabel: {},
    select: {
      padding: 8,
      borderRadius: 10,
      "& .MuiSelect-icon": {
        color: "#D91F05",
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "initial",
      },
      "& .MuiSelect-selectMenu": {
        fontSize: "1.2rem",
        padding: 4,
      },
    },
  })
);
