import SkeletonCardOrder from "@components/SkeletonCardOrder";
import React from "react";
const LoadingCardOrder: React.FC = () => {
  return (
    <>
      <SkeletonCardOrder />
      <SkeletonCardOrder />
      <SkeletonCardOrder />
      <SkeletonCardOrder />
    </>
  );
};

export default LoadingCardOrder;
