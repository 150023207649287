export enum statusOrders {
  BLOQUEADO = "BL",
  LIBERADO = "LIB",
  AGUARDANDO_CARREGAMENTO = "aguardando carregamento",
  EM_CARREGAMENTO = "em carregamento",
  CARREGAMENTO_FINALIZADO = "carregamento finalizado",
  FATURADO = "FT",
  DEVOLUCAO = "DEV",
  ESTORNADO = "EST",
  CARRAGAMENTO_CANCELADO = "CANCELADO",
  DEFAULT = "default",
}

export const optionsFinancialStatusTemporary = [
  { value: "all", label: "Todos" },
  { value: "approved", label: "Aprovado" },
  { value: "blocked", label: "Bloqueado" },
];

export const optionsLogisticalStatus = [
  { value: "all", label: "Todos" },
  { value: "toBill", label: "Não faturado" },
  { value: "billed", label: "Faturado" },
];

export const optionsFinancialStatus = [
  { value: statusOrders.LIBERADO, label: "Liberado" },
  { value: statusOrders.FATURADO, label: "Faturado e Carregado" },

  { value: statusOrders.BLOQUEADO, label: "Bloqueado" },
  { value: statusOrders.DEVOLUCAO, label: "Devolvido" },
  { value: statusOrders.ESTORNADO, label: "Estornado" },
  {
    value: statusOrders.CARRAGAMENTO_CANCELADO,
    label: "Carregamento Cancelado",
  },
];
