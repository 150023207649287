export const addToLocalStorageCNPJ = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    throw new Error("Error adding cnpj to localStorage: " + error);
  }
};

export const getCNPJinLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const addFlagHasQueryResult = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    throw new Error("Error adding flag query result to localStorage: " + error);
  }
};

export const getFlagHasQueryResult = (key) => {
  return localStorage.getItem(key);
};

export const deleteFlagHasQueryResult = (key) => {
  return localStorage.removeItem(key);
};

export const getValuesFilters = (key) => {
  return localStorage.getItem(key);
};

export const addValuesFilters = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    throw new Error("Error adding flag query result to localStorage: " + error);
  }
};

export const deleteValuesFilters = (key) => {
  return localStorage.removeItem(key);
};

export const addRefIdToLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    throw new Error("Error adding flag query result to localStorage: " + error);
  }
};

export const getRefIdinLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const deleteRefIdinLocalStorage = (key) => {
  return localStorage.removeItem(key);
};

export const addValueToLocal = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    throw new Error("Error adding flag query result to localStorage: " + error);
  }
};

export const getValueInLocalStorage = (key) => {
  return localStorage.getItem(key);
};
