import React from "react";

import Layout from "@components/Layout";
import { NozzleProvider } from "@pages/User/Nozzle/context";
import NozzleView from "@pages/User/Nozzle/view";

const Nozzle = () => {
  return (
    <Layout disableFooter>
      <NozzleProvider>
        <NozzleView />
      </NozzleProvider>
    </Layout>
  );
};

export default Nozzle;
