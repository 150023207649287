import { inject, named, injectable } from "inversify";
import { Types } from "@ioc/types";
import * as IHttpService from "@modules/infra/http/models/IHttpService";
import { IGetProjectionSalesService } from "../models/IGetProjectionSalesService";
import { IGetProjectionSalesDTO } from "../dtos/IGetProjectionSalesDTO";

@injectable()
export class GetProjectionSalesService implements IGetProjectionSalesService {
  @inject(Types.IHttpService)
  @named("pump")
  private httpInstance!: IHttpService.IHttpService;

  public async execute(cnpj: string): Promise<IGetProjectionSalesDTO> {
    try {
      const projectionSalesResponse = await this.httpInstance.get<
        IGetProjectionSalesDTO
      >(`/goal/dash?document=${cnpj}`);
      return projectionSalesResponse;
    } catch (err) {
      throw err;
    }
  }

  public setAuthorization(token: string): void {
    this.httpInstance.setAuthorization(token);
  }
}
