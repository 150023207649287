import React from "react";
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";
import Carousel from "react-material-ui-carousel";
import {
  Button,
  Card,
  Link,
  CardMedia,
  CardContent,
  Typography,
  Box,
  useMediaQuery,
  IconButton,
  Grid,
} from "@material-ui/core";
import { Link as LinkRouter } from "react-router-dom";
import arrowImg from "../../assets/mini_arrow_to_right.svg";

interface Props {
  children?: React.ReactNode;
  items: {
    id?: number;
    title: string;
    description: string;
    image: string;
    link: string;
  }[];
}

const CarouselOptionsService: React.FC<Props> = ({ children, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const [activeIndex, setActiveIndex] = React.useState(0);

  const handlePrev = () => {
    setActiveIndex(
      activeIndex === 0 ? props.items.length - 1 : activeIndex - 1
    );
  };

  const handleNext = () => {
    setActiveIndex(
      activeIndex === props.items.length - 1 ? 0 : activeIndex + 1
    );
  };

  return (
    <Grid container direction={"column"} style={{ padding: "8px 0px" }}>
      <Grid item xs={12}>
        <Carousel
          index={activeIndex}
          autoPlay={true}
          interval={10000} // 10 segundos
          animation="slide"
          className={`${classes.carouselContainer}`}
          navButtonsAlwaysInvisible={true}
          indicatorContainerProps={{
            className: classes.indicator,
            style: {
              marginTop: 8,
              marginBottom: 4,
              border: "0px solid red",
            },
          }}
          //@ts-ignore
          indicatorIconButtonProps={{
            className: `${classes.indicator} indicador-customizado radio-button-corrousel`,
            style: {
              fill: "none",
              margin: "2px 6px 2px 6px",
              border: `1px solid ${theme.palette.text.primary}`,
              borderRadius: "1rem",
              opacity: 0.3,
              fontSize: 8,
            },
          }}
          activeIndicatorIconButtonProps={{
            className: classes.indicator,
            style: {
              fill: "currentColor",
              margin: "0px 6px 0px 6px",
              fontSize: 12,
              opacity: 1,
              color: theme.palette.primary.main,
            },
          }}
        >
          {props.items.map((item, index) => {
            return (
              <div
                className="item-links-card"
                key={index}
                style={{ border: "0px solid red" }}
              >
                <Link
                  component={LinkRouter}
                  to={item.link}
                  className={classes.link}
                  underline="none"
                >
                  <Card key={index} className={classes.root}>
                    <CardMedia
                      className={classes.media}
                      image={item.image}
                      title={item.title}
                    />

                    <CardContent style={{ padding: "12px 24px 8px 16px" }}>
                      <Typography
                        className={classes.title}
                        variant={isXs ? "h6" : "h5"}
                        gutterBottom
                      >
                        {item.title}
                      </Typography>

                      <Typography
                        style={{ display: "block" }}
                        className={classes.description}
                        variant={isXs ? "caption" : "body1"}
                        color="textSecondary"
                      >
                        {item.description}
                      </Typography>

                      <Box sx={{ display: "flex", justifyContent: "end" }}>
                        <IconButton
                          aria-label="next"
                          className={classes.arrow}
                          style={{
                            padding: 0,
                            justifyContent: "end",
                            margin: "1rem",
                          }}
                          onClick={handleNext}
                        >
                          <img alt={"arrowimg"} src={arrowImg} />
                        </IconButton>
                      </Box>
                    </CardContent>
                  </Card>
                </Link>
              </div>
            );
          })}
        </Carousel>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 12, border: "0px solid red" }}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          sx={{ marginTop: -8, border: "0px solid red" }}
        >
          <Button
            onClick={handlePrev}
            className={classes.buttonCardOptions}
            style={{ padding: 0 }}
          >
            <Typography
              variant="caption"
              className={classes.typographyOptionsCard}
            >
              Anterior
            </Typography>
          </Button>

          <Button
            onClick={handleNext}
            className={classes.buttonCardOptions}
            style={{ padding: 0 }}
          >
            <Typography
              variant="caption"
              className={classes.typographyOptionsCard}
              style={{ color: theme.palette.primary.main, opacity: 1 }}
            >
              Próximo
            </Typography>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CarouselOptionsService;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    carouselContainer: {
      margin: 0,
      padding: 0,
    },
    root: {
      boxShadow: "0rem 0.25rem 0.90rem 0rem rgba(176, 193, 225, 0.25)",
      width: "100%",
      maxHeight: 386,
      margin: "auto",
      position: "relative",
    },
    media: {
      height: 200,
    },
    title: {
      color: theme.palette.primary.main,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 700,
      marginBottom: 0,
    },
    description: {
      fontFamily: "DM Sans",
      fontWeight: 400,
      color: theme.palette.text.primary,
      opacity: 0.7,
    },
    arrow: {},
    typographyOptionsCard: {
      color: theme.palette.text.primary,
      fontFamily: "DM Sans",
      textTransform: "capitalize",
      fontWeight: 700,
      opacity: 0.4,
      padding: 0,
    },
    indicator: {},
    link: {
      textDecoration: "none",
      fontSize: "2.4rem",
    },
    buttonCardOptions: {
      minWidth: "fit-content",
    },
  })
);
