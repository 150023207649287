import React from "react";

import { CircularProgress, Grid } from "@mui/material";
import { Typography, useTheme } from "@material-ui/core";

import PhotoCommunication from "@pages/Admin/Communications/resource/PhotoCommunication";
import ModalFilialDetails from "@pages/Admin/Communications/resource/ModalFilialDetails";
import ContainedButton from "@pages/Admin/Communications/resource/ContainedButton";
import ModalButton from "@pages/Admin/Communications/resource/ModalButton";
import TextButton from "@pages/Admin/Communications/resource/TextButton";
import { useCommunication } from "@pages/Admin/Communications/context";
import TitlePage from "@pages/Admin/Communications/resource/TitlePage";
import { ICommunicatedAll } from "@pages/Admin/Communications/model";

import { BellNotification, ImagePictureIcon } from "@components/Icons";

import { filialLabel } from "@utils/interfaces";
import { hexToRgba } from "@utils/index";

const AllCommunicated: React.FC = () => {
  const theme = useTheme();

  const {
    allCommunication,
    loadingCommunication,
    handleSetShowAddCommunicatedPage,
    handleDeleteAllComunication,
    handleDeleteOneCommunication,
    handleDeleteOneCenterAllComunications,
  } = useCommunication();

  const colorDefault = {
    white: theme.palette.background.paper, // "#F5F5F5"
    red: theme.palette.primary.main, // "#D91F05"
    redLight: hexToRgba(theme.palette.primaryLight.main, 0.5), // "#F7E1E1"
    redDark: hexToRgba("#7f0e00", 1),
    yellow: theme.palette.secondary.main, // "#FFA722"
  };

  const CardCommunication: React.FC<{
    index: number;
    communicated: ICommunicatedAll;
  }> = ({ ...props }) => {
    const centerSize = props.communicated?.centers?.length;

    return (
      <Grid container className="card-container" sx={{ gap: { lg: 3 } }}>
        <Grid item xs={12}>
          <Grid
            container
            className="card-title"
            sx={{
              alignItems: "center",
              backgroundColor: colorDefault.white,
              borderRadius: 2,
              justifyContent: "space-between",
              padding: { lg: 1 },
            }}
          >
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="body2"
                style={{ fontWeight: 500, padding: "6px 8px" }}
              >
                {centerSize > 0
                  ? filialLabel(props.communicated.centers[0])
                  : "Nao informado"}
              </Typography>

              {centerSize > 1 && (
                <Typography
                  variant="caption"
                  style={{
                    backgroundColor: colorDefault.redLight,
                    color: colorDefault.red,
                    border: "0px solid red",
                    borderRadius: 20,
                    padding: "3px 6px",
                    fontWeight: 700,
                    fontSize: "10px",
                  }}
                >
                  +{centerSize - 1}
                </Typography>
              )}
            </Grid>

            <Grid item>
              {centerSize <= 1 ? (
                <></>
              ) : loadingCommunication ? (
                <CircularProgress
                  sx={{
                    marginRight: {
                      xs: "0.4",
                      sm: "1rem",
                      md: "1.5rem",
                      lg: "2rem",
                    },
                    color: colorDefault.red,
                    width: { lg: "20px !important" },
                    height: { lg: "20px !important" },
                  }}
                />
              ) : (
                <ModalFilialDetails
                  filiais={props.communicated}
                  onClickRemoveCenters={(indexCenter: number[]) =>
                    handleDeleteOneCenterAllComunications(
                      props.index,
                      indexCenter
                    )
                  }
                >
                  Ver Todos
                </ModalFilialDetails>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <PhotoCommunication
            src={props.communicated.path}
            alt={`${props.communicated.path}-${props.index}`}
            onClick={() => handleDeleteOneCommunication(props.index)}
          />
        </Grid>
      </Grid>
    );
  };

  const EmptyPage = () => {
    return (
      <Grid
        container
        className="EmptyPage-container"
        justifyContent={"center"}
        sx={{ gap: { xs: 0.4, sm: 1, md: 2, lg: 8 } }}
      >
        <Grid
          item
          xs={12}
          sx={{ backgroundColor: colorDefault.white, borderRadius: 2 }}
        >
          <Grid
            container
            textAlign={"center"}
            sx={{
              gap: { xs: 0.5, sm: 2, md: 3, lg: 4 },
              padding: { xs: 0.4, sm: 1, md: 2, lg: 6 },
            }}
          >
            <Grid item xs={12}>
              <ImagePictureIcon
                sx={{
                  fontSize: { xs: "32px", sm: "32px", md: "64px", lg: "64px" },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                Não há nada para mostrar
              </Typography>
              <Typography variant="caption">
                Novos comunicados ainda não foram adicionados.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent={"right"}>
            <Grid item>
              <ContainedButton
                onClick={() => handleSetShowAddCommunicatedPage()}
              >
                Enviar novo comunicado
              </ContainedButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const ContentPage = () => {
    return (
      <Grid
        container
        className="ContentPage-container"
        sx={{ gap: { xs: 0.4, sm: 1, md: 2, lg: 4 } }}
      >
        {/* Send new Communicated button */}
        <Grid item xs={12}>
          <Grid container justifyContent={"right"}>
            <Grid item>
              <TextButton onClick={() => handleSetShowAddCommunicatedPage()}>
                Enviar Novo Comunicado
              </TextButton>
            </Grid>
          </Grid>
        </Grid>

        {/* Cards Communicated */}
        <Grid item xs={12}>
          <Grid
            container
            className="cards-container"
            justifyContent={"flex-start"}
            sx={{ gap: { lg: 1.5 } }}
          >
            {allCommunication.map((card, index) => (
              <Grid
                key={index}
                item
                xs={2.9}
                sx={{
                  border: "0px solid red",
                  marginTop: index >= 4 ? "1.5rem" : "0px",
                }}
              >
                <CardCommunication index={index} communicated={card} />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Typography variant="caption" style={{ fontWeight: 700 }}>
                Total de{" "}
                <b style={{ color: colorDefault.yellow }}>
                  {allCommunication?.length}
                </b>{" "}
                itens
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Buttons - delete and change  */}
        <Grid item xs={12}>
          <Grid
            container
            sx={{
              gap: { lg: 2 },
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <Grid item>
              <ModalButton
                colorTextButton={colorDefault.red}
                onClick={handleDeleteAllComunication} // handleResetAllComunications
              >
                Excluir todos
              </ModalButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      className="AllCommunicated-container"
      sx={{
        border: "0px solid red",
        gap: { lg: 2.5 },
        padding: { lg: "8px 32px 64px 32px" },
      }}
    >
      {/* Bell Notification */}
      <Grid item xs={12}>
        <Grid
          container
          className="bell-notification-container"
          justifyContent={"right"}
        >
          <Grid
            item
            sx={{
              borderRadius: 2,
              backgroundColor: colorDefault.white,
              display: "flex",
              alignItems: "center",
              padding: 1,
            }}
          >
            <BellNotification sx={{ fontSize: { lg: 26 } }} />
          </Grid>
        </Grid>
      </Grid>

      {/* Title */}
      <Grid item xs={12}>
        <Grid
          container
          className="title-container"
          justifyContent={"space-between"}
          alignItems={"flex-start"}
        >
          <Grid item>
            <TitlePage>Todos os comunicados</TitlePage>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ marginTop: { lg: 4 } }}>
        {allCommunication?.length === 0 ? (
          <EmptyPage></EmptyPage>
        ) : (
          <ContentPage></ContentPage>
        )}
      </Grid>
    </Grid>
  );
};

export default AllCommunicated;
