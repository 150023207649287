/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */

import React, { createContext, useContext, useEffect, useState } from "react";

import {
  IEmployee,
  IEmployeeInactive,
  IEmployeeUnregistered,
} from "@pages/User/Employee/model";

import {
  IEmployeeRegister,
  IEmployeeService,
} from "@modules/employee/models/IEmployeeService";

import { formatDatePlus, formatTelephone, formatText } from "@utils/index";
import AppError from "@utils/AppError";

import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useHomeUserContext } from "@context/HomeUserContext";

import useDialogAlert from "@hooks/useDialogAlert";

import { Types } from "@ioc/types";

interface IEmployeeContext {
  employeeActive: IEmployee[];
  employeeInactive: IEmployeeInactive[];
  employeeUnregistered: IEmployeeUnregistered[];
  hasLoading: boolean;
  handleOnClickTurnOffEmployee: (indexEmployeeActive: number) => void;
  handleOnClickCreateEmployee: (employeeTarget: IEmployeeRegister) => void;
}

const EmployeeContext = createContext<IEmployeeContext | undefined>(undefined);

const EmployeeProvider: React.FC = ({ children }) => {
  const { snackbar } = useDialogAlert();

  const { documentList } = useHomeUserContext();
  const [selectedCNPJ, setSelectedCNPJ] = React.useState(
    documentList.filter((item) => item.isSelected)[0]
  );

  useEffect(() => {
    if (selectedCNPJ !== documentList.filter((item) => item.isSelected)[0]) {
      setSelectedCNPJ(documentList.filter((item) => item.isSelected)[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentList]);

  const iocContext = useIoCContext();
  const employeeService = iocContext.serviceContainer.get<IEmployeeService>(
    Types.Employee.IEmployeeService
  );

  // Controla estado do objeto que vai ser recebido do backend para exibir todos empregados
  const [employeeActive, setEmployeeActive] = useState<IEmployee[]>([]);
  const [employeeInactive, setEmployeeInactive] = useState<IEmployeeInactive[]>(
    []
  );
  const [employeeUnregistered, setEmployeeUnregistered] = useState<
    IEmployeeUnregistered[]
  >([]);

  const [
    listenOnClickTurnOffEmployee,
    setListenOnClickTurnOffEmployee,
  ] = useState<boolean>(false);
  const [
    listenOnClickCreateEmployee,
    setListenOnClickCreateEmployee,
  ] = useState<boolean>(false);
  const [hasLoading, setHasLoading] = React.useState<boolean>(true);

  const fetchEmployeeActive = async () => {
    try {
      setHasLoading(true);

      const employeeActiveResponse = await employeeService.getEmployee({
        cnpj: selectedCNPJ.cnpj,
      });
      let employeeActiveNormalized = employeeActiveResponse.content.reduce(
        (acc, item) => {
          acc.push({
            id: item.id,
            identifid: item.identifid || "-",
            name: item.name || "-",
            birthdate: formatDatePlus(item?.birthdate) || "-",
            telephone: formatTelephone(item?.telephone) || "-",
            email: item.email || "-",
            fantasy_name: formatText(item?.company?.fantasy_name) || "-",
            createdAt: item.createdAt ? formatDatePlus(item.createdAt) : "-",
          });
          return acc;
        },
        [] as IEmployee[]
      );

      {
        /* Testes com dados Mockados - Funcionarios Ativos */
      }
      // employeeActiveNormalized = employeesActiveMock;

      setEmployeeActive(employeeActiveNormalized);
    } catch (err) {
      if (err instanceof AppError) {
        console.info(`error reason for employee active: ${err!.message}`);
        snackbar({
          message: `Erro ao carregar dados dos funcionarios ativos`,
          variant: "error",
        });
      }
    } finally {
      setHasLoading(false);
    }
  };

  const fetchEmployeeInactive = async () => {
    try {
      setHasLoading(true);

      const employeeInactiveResponse = await employeeService.getEmployee({
        cnpj: selectedCNPJ.cnpj,
        scopes: "inactives",
      });
      let employeeInactiveNormalized = employeeInactiveResponse.content.reduce(
        (acc, item) => {
          acc.push({
            id: item.id,
            identifid: item.identifid || "-",
            name: item.name || "-",
            birthdate: formatDatePlus(item?.birthdate) || "-",
            telephone: formatTelephone(item?.telephone) || "-",
            email: item.email || "-",
            fantasy_name: formatText(item?.company?.fantasy_name) || "-",
            createdAt: formatDatePlus(item?.createdAt) || "-",
            deletedAt: item.deletedAt || "-",
          });

          return acc;
        },
        [] as IEmployeeInactive[]
      );

      {
        /* Testes com dados Mockados - Funcionarios Inativos */
      }
      // employeeInactiveNormalized = employeesInactiveMock;

      setEmployeeInactive(employeeInactiveNormalized);
    } catch (err) {
      if (err instanceof AppError) {
        console.info(`error reason for employee inactive: ${err!.message}`);
        snackbar({
          message: `Erro ao carregar dados dos funcionarios INATIVOS`,
          variant: "error",
        });
      }
    } finally {
      setHasLoading(false);
    }
  };

  const fetchEmployeeUnregistered = async () => {
    try {
      setHasLoading(true);

      const employeeUnregisteredResponse = await employeeService.getEmployee({
        cnpj: selectedCNPJ.cnpj,
        action: "unregistered",
      });
      let employeeUnregisteredNormalized = employeeUnregisteredResponse.content.reduce(
        (acc, item) => {
          acc.push({
            identifid: item.identifid || "-",
            createdAt: formatDatePlus(item?.createdAt) || "-",
          });

          return acc;
        },
        [] as IEmployeeUnregistered[]
      );

      {
        /* Testes com dados Mockados - Funcionarios nao registrados */
      }
      // employeeUnregisteredNormalized = employeeUnregisteredMock;

      setEmployeeUnregistered(employeeUnregisteredNormalized);
    } catch (err) {
      if (err instanceof AppError) {
        console.info(`error reason for employee unregistered: ${err!.message}`);
        snackbar({
          message: `Erro ao carregar dados dos funcionarios NAO registrados`,
          variant: "error",
        });
      }
    } finally {
      setHasLoading(false);
    }
  };

  const handleOnClickTurnOffEmployee = async (indexEmployeeTarget: number) => {
    try {
      setHasLoading(true);

      const uuid: string | undefined = employeeActive[indexEmployeeTarget]?.id;

      if (uuid) {
        await employeeService.dropEmployee(uuid);
      }

      setListenOnClickTurnOffEmployee(!listenOnClickTurnOffEmployee);
    } catch (err) {
      if (err instanceof AppError) {
        console.info(
          `reason for the error when inactivating an active employee: ${
            err!.message
          }`
        );
        snackbar({
          message: `Erro ao inativar funcionarios ativo`,
          variant: "error",
        });
      }
    } finally {
      setHasLoading(false);
    }
  };

  const handleOnClickCreateEmployee = async (
    employeeTarget: IEmployeeRegister
  ) => {
    try {
      setHasLoading(true);

      await employeeService.addEmployee(employeeTarget);

      setListenOnClickCreateEmployee(!listenOnClickCreateEmployee);
    } catch (err) {
      if (err instanceof AppError) {
        console.info(
          `reason for the error when trying to "CREATE EMPLOYEE" ${
            err!.message
          }`
        );
        snackbar({
          message: `Erro ao criar um funcionario`,
          variant: "error",
        });
      }
    } finally {
      setHasLoading(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setHasLoading(true);

        await fetchEmployeeActive();
        await fetchEmployeeInactive();
        await fetchEmployeeUnregistered();
      } catch (err: any) {
        console.info(`error reason Employee all fetch Page: ${err!.message}`);
        snackbar({
          message: `${err!.message}`,
          variant: "error",
        });
      } finally {
        setHasLoading(false);
      }
    }

    fetchData();
  }, [selectedCNPJ, listenOnClickTurnOffEmployee, listenOnClickCreateEmployee]);

  return (
    <EmployeeContext.Provider
      value={{
        employeeActive,
        employeeInactive,
        employeeUnregistered,
        hasLoading,
        handleOnClickTurnOffEmployee,
        handleOnClickCreateEmployee,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
};

const useEmployee = (): IEmployeeContext => {
  const context = useContext(EmployeeContext);
  if (!context) {
    throw new Error(
      "useEmployee deve ser utilizado dentro de um EmployeeProvider"
    );
  }
  return context;
};

export { useEmployee, EmployeeProvider };
