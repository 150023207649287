import appConfig from "@config/appConfig";
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import CheckCircleSharpIcon from "@material-ui/icons/CheckCircleSharp";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import React from "react";
import { Link } from "react-router-dom";
import { CancelIcon } from "../../../components/Icons";
import Layout from "../../../components/Layout";
import ROUTES from "../../../config/routes";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateColumns: "65% min-content 1fr",
      width: "calc(100%-14rem)",
      margin: "0 7rem",
    },
    containerLeft: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      margin: "0 7rem",
    },
    errorOrSuccess: {
      display: "flex",
      alignItems: "center",
      margin: "1rem 0",
      "& p": {
        fontSize: "2.4rem",
        marginLeft: "1rem",
      },
    },
    successColor: {
      color: theme.palette.success.main,
    },
    title: {
      fontSize: "2.4rem",
      fontWeight: "bold",

      marginBottom: "3rem",
    },
    gridContacts: {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      gridGap: "1.6rem",
    },
    titleContactsText: {
      fontWeight: "bold",
    },
    infoContactsText: {},
    icon: {
      fontSize: "1.6rem",
    },
    centerIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

interface CreateOrderResponse {
  dataOrder: object;
  response: {
    status: "success" | "error";
    response: {
      status: "success" | "error" | "partialError";
      numberOrder?: string;
      product: {
        id: string;
        description: string;
      };
    }[];
  };
}

const OrderPlaced = () => {
  const classes = useStyles();
  const location = {
    state: JSON.parse(
      localStorage.getItem(appConfig.keyStorage.newOrderData) as string
    ) as CreateOrderResponse,
  };

  const ordersFailed = (orders: Pick<CreateOrderResponse, "response">) => {
    return orders.response.response.reduce((previousValue, nextValue) => {
      return nextValue.status === "error" ? previousValue + 1 : previousValue;
    }, 0);
  };

  return (
    <Layout>
      <div className={classes.containerLeft}>
        <div className={classes.errorOrSuccess}>
          {location.state.response.status === "success" ? (
            <>
              <CheckCircleSharpIcon className={classes.successColor} />
              <Typography>Sucesso!</Typography>
            </>
          ) : (
            <>
              <CancelIcon color="error" />
              <Typography>
                {ordersFailed(location.state) > 1
                  ? `${ordersFailed(location.state).toLocaleString("pt-BR", {
                      minimumIntegerDigits: 2,
                    })} Pedidos não efetuados!`
                  : `${ordersFailed(location.state).toLocaleString("pt-BR", {
                      minimumIntegerDigits: 2,
                    })} Pedido não efetuado!`}
              </Typography>
            </>
          )}
        </div>
        <Typography style={{ margin: "2rem 0 3.2rem" }}>
          {location.state.response.status === "success"
            ? "Seus pedidos foram efetuados com sucesso. Segue abaixo os números dos pedidos:"
            : ordersFailed(location.state) > 1
            ? `Infelizmente ${ordersFailed(location.state).toLocaleString(
                "pt-BR",
                {
                  minimumIntegerDigits: 2,
                }
              )} de seus pedidos não foram efetuados! Por favor, entre em contato com a Central de Atendimento e verifique o erro. O Restante foi efetuado com sucesso!`
            : `Infelizmente ${ordersFailed(location.state).toLocaleString(
                "pt-BR",
                {
                  minimumIntegerDigits: 2,
                }
              )} de seus pedidos não foi efetuado! Por favor, entre em contato com a Central de Atendimento e verifique o erro. O Restante foi efetuado com sucesso!`}
        </Typography>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Número do Pedido</TableCell>
                <TableCell>Descrição do Produto</TableCell>
                <TableCell>Código do Produto</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {location.state.response.response.map((ele) => {
                return (
                  <TableRow>
                    <TableCell>
                      {ele.status === "success" ? (
                        <DoneIcon className={classes.successColor} />
                      ) : ele.status === "partialError" ? (
                        <CloseIcon color="secondary" />
                      ) : (
                        <CloseIcon color="error" />
                      )}
                    </TableCell>
                    <TableCell>
                      {ele.numberOrder ? ele.numberOrder : "Não efetuado"}
                    </TableCell>
                    <TableCell>{ele.product.description}</TableCell>
                    <TableCell>{ele.product.id}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
        <Grid style={{ marginTop: "7.2rem" }} container justify="space-evenly">
          <Button variant="outlined" color="primary" component={Link} to="/">
            Voltar
          </Button>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={{
              pathname: ROUTES.USER_ROUTES.EDIT_ORDER,
              state: {
                formSubmited: location.state.dataOrder,
              },
            }}
          >
            Alterar pedido
          </Button>
        </Grid>
        <Typography
          variant="caption"
          align="center"
          style={{ marginTop: "8.8rem" }}
        >
          Para cancelar seus pedidos, entre em contato com a Central de
          Atendimento.
        </Typography>
      </div>
    </Layout>
  );
};

export default OrderPlaced;
