/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-lone-blocks */
import React, {
  useContext,
  useState,
  createContext,
  useCallback,
  useEffect,
} from "react";

import {
  ICommunicationService,
  IRequestUpdateComunicationDTO,
  IResponseComunicationDTO,
} from "@modules/communications/model/ICommunication";
import {
  ICommunicatedAll,
  ICommunicationData,
} from "@pages/Admin/Communications/model";

import { useIoCContext } from "@context/IoCContext/IoCContext";

import useDialogAlert from "@hooks/useDialogAlert";
import AppError from "@utils/AppError";

import { Types } from "@ioc/types";

interface ICommunicationContext {
  showAddCommunicatedPage: boolean;
  allCommunication: ICommunicatedAll[];
  communications: ICommunicationData[];
  loadingCommunication: boolean;
  handleSetShowAddCommunicatedPage: () => void;
  handleCreateCommunicated: (files: File[]) => void;
  handleUpdateCommunicated: (
    communication: ICommunicationData,
    index: number
  ) => void;
  handleDeleteCommunicated: (indexProps: number) => void;
  handleSendCommunication: () => void;
  handleResetCommunications: () => void;
  handleResetAllComunications: () => void;
  handleDeleteAllComunications: (index: number) => void;
  handleDeleteOneCenterAllComunications: (
    indexCommunicated: number,
    indexCenter: number[]
  ) => void;
  handleDeleteOneCommunication: (index: number) => void;
  handleDeleteAllComunication: () => void;
}

const CommunicationContext = createContext<ICommunicationContext | undefined>(
  undefined
);

const CommunicationProvider: React.FC = ({ children }) => {
  const { snackbar } = useDialogAlert();
  const iocContext = useIoCContext();

  const [loadingCommunication, setLoadingCommunication] = useState<boolean>(
    false
  );

  const [showAddCommunicatedPage, setShowAddCommunicatedPage] = useState<
    boolean
  >(false); //
  const [
    firstAutoRefreshCommunication,
    setFirstAutoRefreshCommunication,
  ] = useState<boolean>(true);

  // Controle de quando a pagina deve atualizar automaticamente apos finalizar um fluxo
  // Ex: A pagina deve atualizar apos usuario finalizar o "Fluxo de enviar comunicado"
  const [refreshFetchComunication, setRefreshFetchComunication] = useState<
    boolean
  >(false);

  // Controla estado do objeto que vai ser recebido do backend para exibir todos comunicados no componente AllComunicated
  const [allCommunication, setAllCommunication] = useState<ICommunicatedAll[]>(
    []
  ); // allCommunicatedMock

  // Controla estado do objeto que vai ser enviado para cadastrar o comunicado - Componente AddCommunicated
  const [communications, setCommunications] = useState<ICommunicationData[]>(
    []
  ); // Default: [] || Dev: allCommunicatedMock

  /* [INICIO] CRUD - ENVIAR COMUNICADO  */

  const handleCreateCommunicated = (files: File[]) => {
    if (files.length === 0) return;
    const newCommunicationData = files.map((file) => {
      const communicationDatas: ICommunicationData = {
        centers: [],
        file: file,
        imageUrl: URL.createObjectURL(file),
        expiresIn: "",
      };
      return communicationDatas;
    });

    setCommunications(newCommunicationData);
  };

  const handleUpdateCommunicated = useCallback(
    (communication: ICommunicationData, indexProps: number) => {
      setCommunications((((prev: ICommunicationData[]) => {
        const update = [...prev];
        update[indexProps] = communication;
        return update;
      }) as unknown) as ICommunicationData[]);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  const handleDeleteCommunicated = (indexProps: number) => {
    const removeCommunicated = [...communications];
    removeCommunicated.splice(indexProps, 1);
    setCommunications(removeCommunicated);
  };

  const handleFetchCommunication = async () => {
    try {
      setLoadingCommunication(true);

      const createHandoutService = iocContext.serviceContainer.get<
        ICommunicationService
      >(Types.Communication.ICommunicationService);
      const communicationResponse: IResponseComunicationDTO[] = await createHandoutService.findAll();
      const communitation: ICommunicatedAll[] = communicationResponse.map(
        (item) => {
          return {
            id: item.id,
            startDate: item.createdAt,
            endDate: item.expiresIn,
            centers: item.centers,
            path: item.path,
          };
        }
      );

      setAllCommunication(communitation);
    } catch (error) {
      if (error instanceof AppError) {
        snackbar({
          message: `Ocorreu um erro ao buscar os comunicados ! Motivo: ${error.message} | status: ${error.status}`,
          variant: "error",
        });
      }
    } finally {
      setLoadingCommunication(false);
      setRefreshFetchComunication(false);
      setFirstAutoRefreshCommunication(false);
    }
  };

  useEffect(() => {
    // Primeira Execucao - Auto Refresh na pagina
    if (firstAutoRefreshCommunication) {
      handleFetchCommunication();
    }

    // Recarregar a pagina apos finalizar fluxo de submissao de um novo comunicado
    if (refreshFetchComunication) {
      handleFetchCommunication();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshFetchComunication]);

  const handleSendCommunication = async () => {
    try {
      setLoadingCommunication(true);

      const createHandoutService = iocContext.serviceContainer.get<
        ICommunicationService
      >(Types.Communication.ICommunicationService);
      await createHandoutService.create(communications);

      const allCommunications = communications.reduce((acc, curr) => {
        acc.push({ centers: curr.centers, path: curr.imageUrl || "" });
        return acc;
      }, [] as ICommunicatedAll[]);

      setAllCommunication(allCommunications);

      snackbar({
        message: "Comunicado criado com sucesso!",
        variant: "success",
      });

      setShowAddCommunicatedPage(false); // Impede que a pagina de submissao de novo comunicados seja exibida
    } catch (error) {
      if (error instanceof AppError) {
        console.error(error.message);
        snackbar({
          message: "Ocorreu um erro ao enviar os comunicados !",
          variant: "error",
        });

        snackbar({ message: `Razao: ${error.message}`, variant: "error" });
      }
    } finally {
      setLoadingCommunication(false);
      setRefreshFetchComunication(true);
    }
  };

  const handleResetCommunications = () => {
    setCommunications([]);
  };

  /* [FIM] CRUD - ENVIAR COMUNICADO  */
  /* ---------------------------------------------  */

  // Remove 1 comunicado selecionado - rota (estatica) nao integrada com backend
  const handleDeleteAllComunications = (index: number) => {
    const removeAllComunicated = [...allCommunication];
    removeAllComunicated.splice(index, 1);
    setAllCommunication(removeAllComunicated);
  };

  const handleDeleteAllComunication = async () => {
    try {
      setLoadingCommunication(true);

      const idList = allCommunication.reduce((acc: string[], curr) => {
        if (curr.id) {
          acc.push(curr.id);
        }
        return acc;
      }, []);

      const communicationService = iocContext.serviceContainer.get<
        ICommunicationService
      >(Types.Communication.ICommunicationService);
      await communicationService.deleteAll(idList);
      setAllCommunication([]);

      snackbar({
        message: "Todos os Comunicados foram deletados com sucesso!",
        variant: "success",
      });
    } catch (error) {
      if (error instanceof AppError) {
        console.error(error.message);
        snackbar({
          message: "Ocorreu um erro ao deletar os comunicados !",
          variant: "error",
        });
      }
    } finally {
      setLoadingCommunication(false);
      setRefreshFetchComunication(true);
    }
  };

  // Remove 1 comunicado - rota integrada com backend
  const handleDeleteOneCommunication = async (index: number) => {
    try {
      setLoadingCommunication(true);

      const communicationService = iocContext.serviceContainer.get<
        ICommunicationService
      >(Types.Communication.ICommunicationService);
      await communicationService.deleteOne(allCommunication[index].id);

      const removeAllComunicated = [...allCommunication];
      removeAllComunicated.splice(index, 1);
      setAllCommunication(removeAllComunicated);

      snackbar({
        message: "Comunicado deletado com sucesso!",
        variant: "success",
      });
    } catch (error) {
      if (error instanceof AppError) {
        console.error(error.message);
        snackbar({
          message: "Ocorreu um erro ao deletar os comunicados !",
          variant: "error",
        });
      }
    } finally {
      setLoadingCommunication(false);
      setRefreshFetchComunication(true);
    }
  };

  // Remove centros selecionados com base no comunicado selecionado
  const handleDeleteOneCenterAllComunications = async (
    indexSelectedCommunicated: number,
    indexCenterToBeRemoved: number[]
  ) => {
    try {
      setLoadingCommunication(true);

      let updateCommunications = [...allCommunication];

      indexCenterToBeRemoved.forEach((center) => {
        updateCommunications[indexSelectedCommunicated].centers.splice(
          center,
          1
        );
      });

      const payload: IRequestUpdateComunicationDTO = {
        id: updateCommunications[indexSelectedCommunicated].id || "",
        centers: updateCommunications[indexSelectedCommunicated].centers,
      };

      const communicationService = iocContext.serviceContainer.get<
        ICommunicationService
      >(Types.Communication.ICommunicationService);
      await communicationService.update(payload);

      snackbar({
        message: "Comunicado Editado com sucesso!",
        variant: "success",
      });
    } catch (error) {
      if (error instanceof AppError) {
        console.error(error.message);
        snackbar({
          message: "Ocorreu um erro ao editar os comunicados !",
          variant: "error",
        });
      }
    } finally {
      setLoadingCommunication(false);
      setRefreshFetchComunication(true);
    }
  };

  const handleResetAllComunications = () => {
    setAllCommunication([]);
  };

  const handleSetShowAddCommunicatedPage = () => {
    setCommunications([]);
    setShowAddCommunicatedPage(!showAddCommunicatedPage);
  };

  return (
    <CommunicationContext.Provider
      value={{
        communications,
        allCommunication,
        loadingCommunication,
        showAddCommunicatedPage,
        handleCreateCommunicated,
        handleUpdateCommunicated,
        handleDeleteCommunicated,
        handleSendCommunication,
        handleResetCommunications,
        handleResetAllComunications,
        handleDeleteAllComunications,
        handleSetShowAddCommunicatedPage,
        handleDeleteOneCenterAllComunications,
        handleDeleteOneCommunication,
        handleDeleteAllComunication,
      }}
    >
      {children}
    </CommunicationContext.Provider>
  );
};

const useCommunication = (): ICommunicationContext => {
  const context = useContext(CommunicationContext);
  if (!context) {
    throw new Error(
      "useCommunication deve ser utilizado dentro de um CommunicationProvider"
    );
  }
  return context;
};

export { useCommunication, CommunicationProvider };
