import React from "react";
import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import { IGetCardTitleDTO } from "@modules/cardTitles/dtos/IGetCardTitleDTO";
import { formatCurrency, capitalizeFirstLetterFullString } from "@utils/index";

const docStatus = {
  CLOSE: {
    text: "Pago",
    color: "#0BB873",
  },
  EXPIRED: {
    text: "Vencido",
    color: "#F97A91",
  },
  NOT_EXPIRED: {
    text: "A Vencer",
    color: "#FFB800",
  },
  DEFAULT: {
    text: "",
    color: "#ccc",
  },
};

const CardTitle: React.FC<IGetCardTitleDTO> = ({ ...props }) => {
  const classes = useStyles();
  return (
    <Box className={classes.paper}>
      <Box display="flex">
        <Typography
          style={{
            width: "60%",
            color: docStatus[props.statusDoc ?? "DEFAULT"].color,
          }}
          className={classes.status}
        >
          {docStatus[props.statusDoc ?? "DEFAULT"].text}
        </Typography>
      </Box>
      <Box display="block" style={{ width: "100%" }}>
        <Typography style={{ width: "100%" }} className={classes.value}>
          {formatCurrency(props.value)}
        </Typography>
        <Box style={{ width: "100%", marginLeft: "32px" }}>
          <div
            className={classes.color}
            style={{
              background: docStatus[props.statusDoc ?? "DEFAULT"].color,
            }}
          ></div>
        </Box>
      </Box>

      <Box display="block" style={{ width: "100%" }}>
        <Typography style={{ marginRight: "10px" }} className={classes.name}>
          {capitalizeFirstLetterFullString(
            props.customerName ? props.customerName.toLowerCase() : ""
          )}
        </Typography>
        <Typography
          style={{ width: "100%", height: "10px" }}
          className={classes.reference}
        >
          Título {props.reference}
        </Typography>
      </Box>
    </Box>
  );
};

export default CardTitle;

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "31px",
      color: "#3E3D3D",
      background: "#FFFFFF",
      border: "1px solid rgba(114, 101, 81, 0.1)",
      borderRadius: "12px",
      height: "232px",
      width: "312px",
      display: "block",
      marginBottom: "24px",
      marginRight: "24px",
    },
    color: {
      width: "89px",
      height: "4px",

      borderRadius: "0px 0px 4px 4px",
    },
    status: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "20px",
      marginTop: "32px",
      marginLeft: "32px",
    },
    installments: {
      marginTop: "32px",
      marginRight: "32px",
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      textAlign: "right",
      color: "#3E3D3D",
      opacity: 0.7,
    },
    reference: {
      marginTop: "7px",
      marginLeft: "33px",
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "56%",
      lineHeight: "21px",
      color: "#3E3D3D",
      opacity: 0.7,
    },
    value: {
      marginTop: "43px",
      marginLeft: "32px",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "74%",
      lineHeight: "29px",
      color: "#3E3D3D",
      opacity: 0.7,
    },
    name: {
      marginTop: "18px",
      marginLeft: "32px",
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "60%",
      lineHeight: "21px",
      color: "#3E3D3D",
      opacity: "0.7",
    },
  })
);
