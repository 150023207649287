import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import { Types } from "@ioc/types";
import { IGetCustomersMarketingPlanDTO } from "@modules/user/dtos/IGetCustomersMarketingPlanDTO";
import { IListCustomersMarketingPlanService } from "@modules/user/models/IListCustomersMarketingPlanService";
import moment from "moment-timezone";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
interface DashPoshContext {
  customersTransformString?: string;
  setCustomersTransformString(customersTransformString: string): void;
  dateFilter: string;
  setDateFilter(dateFilter: string): void;
  dateToFilter: string;
  setDateToFilter(dateToFilter: string): void;
  typeDateTruncVolChart: string;
  setTypeDateTruncVolChart(typeDateTruncVolChart: string): void;
  typeDateTruncRegisterChart: string;
  setTypeDateTruncRegisterChart(typeDateTruncRegisterChart: string): void;
  document: string;
  setDocument: React.Dispatch<React.SetStateAction<string>>;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const DashPoshContext = createContext<DashPoshContext>({} as DashPoshContext);

const DashPoshProvider: React.FC = ({ children }) => {
  const userState = useUserState();
  const [document, setDocument] = useState<string>("");
  const [cnpj, setCnpj] = useState<string[]>([]);
  const [customers, setCustomers] = useState<IGetCustomersMarketingPlanDTO[]>(
    []
  );
  const [customersTransformString, setCustomersTransformString] = useState<
    string
  >();

  const [dateFilter, setDateFilter] = useState(
    moment().subtract(8, "months").startOf("month").format("YYYY-MM-DD")
  );
  const [dateToFilter, setDateToFilter] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [typeDateTruncVolChart, setTypeDateTruncVolChart] = useState("month");
  const [typeDateTruncRegisterChart, setTypeDateTruncRegisterChart] = useState(
    "month"
  );
  const iocContext = useIoCContext();

  const getCustomersService = iocContext.serviceContainer.get<
    IListCustomersMarketingPlanService
  >(Types.Users.IListCustomersMarketingPlanService);

  const mapListCNPJ = useMemo(() => {
    if (userState.listCNPJ.length > 0) {
      const map = userState.listCNPJ.map((item) => {
        return item.CNPJ;
      });

      setCnpj(map);
    }
  }, [userState.listCNPJ]);

  const getCustomers = useCallback(async () => {
    try {
      // setLoadingPrice(true);
      const customers = await getCustomersService.execute(cnpj.toString());
      setCustomers(customers);
    } catch (error) {
      // enqueueSnackbar("Ocorreu um erro ao buscar preço do produto", {
      //   variant: "error",
      // });
    } finally {
      // setLoadingPrice(false);
    }
  }, [cnpj, getCustomersService]);

  const transformString = useCallback(() => {
    const customersString = customers.map((e) => `'${e.id}'`);
    setCustomersTransformString(customersString.toString());
  }, [customers]);

  useEffect(() => {
    getCustomers();
  }, [getCustomers, mapListCNPJ]);

  useEffect(() => {
    transformString();
  }, [transformString]);

  return (
    <DashPoshContext.Provider
      value={{
        customersTransformString,
        setCustomersTransformString,
        dateFilter,
        setDateFilter,
        dateToFilter,
        setDateToFilter,
        typeDateTruncVolChart,
        setTypeDateTruncVolChart,
        typeDateTruncRegisterChart,
        setTypeDateTruncRegisterChart,
        document,
        setDocument,
      }}
    >
      {children}
    </DashPoshContext.Provider>
  );
};

const useDashPosh = (): DashPoshContext => {
  const context = useContext(DashPoshContext);
  if (!Object.values(context).length) {
    throw new Error(
      "useDashPosh deve ser utilizado dentro de um DashPoshProvider"
    );
  }
  return context;
};

export { useDashPosh, DashPoshProvider };
