import {
  IGetCompartmentsResponseDto,
  IGetSimulationRequestDto,
  IGetSimulationResultDto,
} from "../dtos/ISimulateLoadDTO";
import { ISimulateLoadService } from "../models/ISimulateLoadService";
import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";

@injectable()
export class SimulateLoadService implements ISimulateLoadService {
  getSimulation(
    dto: IGetSimulationRequestDto
  ): Promise<IGetSimulationResultDto> {
    return this.httpInstance.post<IGetSimulationResultDto>(
      `/simulate-loading/checkOrder`,
      dto
    );
  }
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async getCompartments(
    plate: string
  ): Promise<IGetCompartmentsResponseDto[]> {
    return this.httpInstance
      .get<IGetCompartmentsResponseDto[]>(`/simulate-loading/vehicle/${plate}`)
      .then((compartmentArray) =>
        compartmentArray.map((compartmentEntry) => ({
          ...compartmentEntry,
          arrows: [...new Set(compartmentEntry.arrows)].sort(),
        }))
      );
  }
}
