import {
  Avatar,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import DraftsIcon from "@material-ui/icons/Drafts";
import EmailIcon from "@material-ui/icons/Email";
import clsx from "clsx";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useHandoutDispatch } from "../../context/HandoutContext";
import { useIoCContext } from "../../context/IoCContext/IoCContext";
import { useUserState } from "../../context/UserContext";
import { Types } from "../../ioc/types";
import { IGetHandoutsSeenOrNotService } from "../../modules/handouts/models/IGetHandoutsSeenOrNotService";
import { IRemoveHandoutService } from "../../modules/handouts/models/IRemoveHandoutService";
import AppError from "../../utils/AppError";

const useStyles = makeStyles((themes) =>
  createStyles({
    iconMessage: {
      width: "7rem",
      height: "7rem",
      "& svg": {
        fontSize: "4rem",
      },
    },
    isNotViewed: {
      backgroundColor: themes.palette.primary.main,
    },
    card: {
      position: "relative",
    },
    cardActions: {
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 100,
    },
    loading: {
      width: "8rem",
      height: "8rem",
      position: "absolute",
    },
  })
);

interface Props {
  id: string;
  title: string;
  viewed?: boolean;
  content: string;
  sendedAt: string;
}

const CardHandout: React.FC<Props> = ({
  title,
  content,
  viewed,
  sendedAt,
  id: handoutID,
}) => {
  const classes = useStyles();
  const handoutDispatch = useHandoutDispatch();
  const userState = useUserState();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const iocContext = useIoCContext();

  return (
    <Card className={classes.card}>
      {userState.adminMode && (
        <Tooltip title="Remover comunicado">
          <CardActions className={classes.cardActions}>
            <IconButton
              onClick={async () => {
                try {
                  //remove um handout
                  setLoading(true);

                  const removeHandoutService = iocContext.serviceContainer.get<
                    IRemoveHandoutService
                  >(Types.Handouts.IRemoveHandoutService);

                  const remove = await removeHandoutService.execute(handoutID);
                  enqueueSnackbar(remove.message, { variant: "success" });
                } catch (error) {
                  if (error instanceof AppError) {
                    return enqueueSnackbar(error.message, {
                      variant: error.variant,
                    });
                  }
                } finally {
                  setLoading(false);
                }

                try {
                  // atualiza a lista com novos handouts
                  const getHandoutsSeenOrNot = iocContext.serviceContainer.get<
                    IGetHandoutsSeenOrNotService
                  >(Types.Handouts.IGetHandoutsSeenOrNotService);

                  const handouts = await getHandoutsSeenOrNot.execute(
                    userState.userID
                  );

                  handoutDispatch({
                    type: "APP_SET_HANDOUTS_NOT_VIEWED",
                    payload: handouts,
                  });
                } catch (error) {
                  console.error(error);
                }
              }}
            >
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {loading && <CircularProgress className={classes.loading} />}
                <DeleteIcon />
              </div>
            </IconButton>
          </CardActions>
        </Tooltip>
      )}
      <CardActionArea
        onClick={() =>
          handoutDispatch({
            type: "APP_SHOW_HANDOUT",
            payload: {
              id: handoutID,
              content,
              createdAt: sendedAt,
              title,
              viewed: Boolean(viewed),
            },
          })
        }
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid container item xs={12} justify="center">
              <Avatar
                className={clsx(classes.iconMessage, {
                  [classes.isNotViewed]: !viewed,
                })}
              >
                {viewed ? <DraftsIcon /> : <EmailIcon />}
              </Avatar>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center">{title}</Typography>
              <Typography align="center">
                Enviado em: {format(new Date(sendedAt), "dd/MM/yyyy")}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardHandout;
