import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

interface HomeContext {
  loading: boolean;
  openModalPayload: { open: boolean; payload: string };
  numberOfItemsInList: number;
  page: number;
  setLoading(loading: boolean): void;
  setOpenModalPayload(value: { open: boolean; payload: string }): void;
  setPage(value: number): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const HomeContext = createContext<HomeContext>({} as HomeContext);

const HomeContextProvider: React.FC = ({ children }) => {
  const [loading, setLoadingState] = useState(false);
  const [openModalPayload, setOpenModalPayload] = useState({
    open: false,
    payload: "",
  });
  const [page, setPage] = useState(1);

  const setLoading = useCallback((loading: boolean) => {
    setLoadingState(loading);
  }, []);

  const numberOfItemsInList = useMemo(() => {
    return 10;
  }, []);

  return (
    <HomeContext.Provider
      value={{
        loading,
        openModalPayload,
        numberOfItemsInList,
        page,
        setLoading,
        setOpenModalPayload,
        setPage,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

const useHomeContext = (): HomeContext => {
  const context = useContext(HomeContext);
  if (!Object.values(context).length) {
    throw new Error(
      "useHomeContext deve ser utilizado dentro de um HomeContextProvider"
    );
  }
  return context;
};

export { useHomeContext, HomeContextProvider };
