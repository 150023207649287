import { useCommissionCalculatorContext } from "@context/ComissionCalculatorContext/ComissionCalculatorContext";
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Theme } from "@mui/material";
import { cutText, formatToLiter, formatToReal } from "@utils/index";
import React, { useState } from "react";
import { TableTitleHeadComissions } from "../models/TableHeadComissions";
import ModalTableCommissions from "./ModalTableCommissions";

const TableComissionAll: React.FC = () => {
  const classes = useStyles();
  const { dataQueryComission } = useCommissionCalculatorContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [openRows, setOpenRows] = useState<boolean[]>([]);
  const [indexClicked, setIndexClicked] = useState<number>(0);
  const [openDialog, setOpenDialog] = useState(false);

  const toggleRow = (index: number) => {
    const newOpenRows = [...openRows];
    newOpenRows[index] = !newOpenRows[index];
    setOpenRows(newOpenRows);
  };

  const calculateHeight = () => {
    if (isMobile) {
      return "auto";
    }

    const contentLength = dataQueryComission.employees?.length || 0;
    return contentLength < 5 ? "auto" : "82vh";
  };

  const cutNameEmployee = (name: string) => {
    let truncatedName: string;

    if (name.length > 16) {
      truncatedName = `${name.slice(0, 10)}...`;
    } else {
      truncatedName = name;
    }

    return truncatedName;
  };

  return (
    <TableContainer
      className={classes.TableContainer}
      style={{ height: calculateHeight() }}
    >
      <Table className={classes.Table} stickyHeader>
        <TableHead>
          <TableRow className={classes.TableRow}>
            {TableTitleHeadComissions.map((item) => (
              <>
                {isMobile && item.isVisibleMobile && (
                  <TableCell
                    align={item.align}
                    className={classes.TableCellHead}
                    style={{
                      backgroundColor: "white",
                      fontSize: isMobile ? "1.2rem" : "",
                      padding: item.field === "description" ? "16px 0px" : "",
                    }}
                    colSpan={item.field === "description" ? 2 : 1}
                  >
                    {item.title}
                  </TableCell>
                )}

                {!isMobile && (
                  <TableCell
                    align={item.align}
                    className={classes.TableCellHead}
                    style={{
                      backgroundColor: "white",
                    }}
                  >
                    {item.title}
                  </TableCell>
                )}
              </>
            ))}
          </TableRow>
        </TableHead>

        <Box style={{ padding: "10px" }}></Box>

        {/* No modo mobile abre modal de cada linha por frentista */}
        <ModalTableCommissions
          open={openDialog}
          dataCommission={(dataQueryComission.employees || [])[indexClicked]}
          handleOnClose={() => setOpenDialog(false)}
        />

        <TableBody>
          {dataQueryComission.employees.map((employee, index) => (
            <React.Fragment key={index}>
              {employee.products_and_comissions
                .slice(0, 1)
                .map((item, innerIndex) => (
                  <>
                    <TableRow key={innerIndex}>
                      {innerIndex === 0 && (
                        <TableCell
                          align="left"
                          rowSpan={1}
                          style={{
                            color: "#3E3D3D",
                            verticalAlign: "middle",
                            fontWeight: 500,
                            whiteSpace: "nowrap",
                            fontSize: isMobile ? "1.1rem" : "",
                          }}
                          className={classes.TableCellFromData}
                        >
                          {employee.name
                            ? isMobile
                              ? cutNameEmployee(employee.name)
                              : employee.name
                            : "Frentista não cadastrado"}
                        </TableCell>
                      )}
                      <TableCell
                        align="left"
                        className={classes.TableCellMoreLines}
                        style={{
                          fontSize: isMobile ? "1.1rem" : "",
                          whiteSpace: "nowrap",
                          padding: isMobile ? "16px 0px" : "",
                        }}
                      >
                        {isMobile
                          ? cutText(item.description, 10)
                          : item.description}
                      </TableCell>

                      {isMobile && (
                        <TableCell
                          align="center"
                          style={{
                            border: "none",
                            padding: "8px 0px",
                          }}
                          className={classes.TableCellFromData}
                        >
                          <IconButton
                            onClick={() => {
                              setIndexClicked(index);
                              setOpenDialog(true);
                            }}
                          >
                            <ArrowForwardIosIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      )}

                      {!isMobile && (
                        <>
                          <TableCell
                            align="right"
                            className={classes.TableCellMoreLines}
                            style={{
                              width: isMobile ? " " : "178px",
                              marginRight: isMobile ? "" : "16px",
                            }}
                          >
                            {formatToLiter(item.quantity)}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.TableCellMoreLines}
                            style={{ width: isMobile ? " " : "196.02px" }}
                          >
                            {formatToReal(item.commission)}
                          </TableCell>

                          {innerIndex === 0 && (
                            <TableCell
                              align="right"
                              rowSpan={1}
                              style={{
                                color: "#3E3D3D",
                                verticalAlign: "middle",
                                width: isMobile ? " " : "188.05px",
                              }}
                              className={classes.TableCellFromData}
                            >
                              {formatToLiter(employee.quantity_total)}
                            </TableCell>
                          )}

                          {innerIndex === 0 && (
                            <TableCell
                              align="right"
                              rowSpan={1}
                              style={{
                                color: "#3E3D3D",
                                verticalAlign: "middle",
                                width: isMobile ? " " : "142.31px",
                              }}
                              className={classes.TableCellFromData}
                            >
                              {formatToReal(employee.commission_total)}
                            </TableCell>
                          )}

                          {innerIndex === 0 &&
                          employee.products_and_comissions.length > 1 ? (
                            <TableCell
                              align="right"
                              rowSpan={1}
                              style={{
                                color: "#3E3D3D",
                                verticalAlign: "top",
                              }}
                              className={classes.TableCellFromData}
                            >
                              <IconButton
                                onClick={() => {
                                  toggleRow(index);
                                }}
                              >
                                {openRows[index]}
                                {openRows[index] ? (
                                  <ExpandMoreIcon
                                    style={{ color: "#D91F05" }}
                                  />
                                ) : (
                                  <ExpandLessIcon
                                    style={{ color: "#D91F05" }}
                                  />
                                )}
                              </IconButton>
                            </TableCell>
                          ) : (
                            <TableCell
                              className={classes.TableCellFromData}
                              rowSpan={1}
                              style={{
                                color: "#3E3D3D",
                                verticalAlign: "top",
                                width: "80px",
                              }}
                            ></TableCell>
                          )}
                        </>
                      )}
                    </TableRow>

                    {/* Subseção das linhas de cada frentista*/}

                    {openRows[index] && (
                      <TableRow>
                        <>
                          <TableCell
                            align={"left"}
                            className={classes.TableCellFromDataSubSection}
                          />
                          <TableCell
                            align={"left"}
                            className={classes.TableCellFromDataSubSection}
                          >
                            {employee.products_and_comissions
                              .slice(1, employee.products_and_comissions.length)
                              .map((item, innerIndex) => (
                                <Grid
                                  xs={12}
                                  style={{
                                    padding: "25px 10px 25px 16px",
                                  }}
                                >
                                  {item.description}
                                </Grid>
                              ))}
                          </TableCell>

                          <TableCell
                            align={"right"}
                            className={classes.TableCellFromDataSubSection}
                          >
                            {employee.products_and_comissions
                              .slice(1, employee.products_and_comissions.length)
                              .map((item, innerIndex) => (
                                <Grid
                                  xs={12}
                                  className={classes.GridFromSubSection}
                                >
                                  {formatToLiter(item.quantity)}
                                </Grid>
                              ))}
                          </TableCell>

                          <TableCell
                            align={"right"}
                            className={classes.TableCellFromDataSubSection}
                          >
                            {employee.products_and_comissions
                              .slice(1, employee.products_and_comissions.length)
                              .map((item, innerIndex) => (
                                <Grid
                                  xs={12}
                                  className={classes.GridFromSubSection}
                                >
                                  {formatToReal(item.commission)}
                                </Grid>
                              ))}
                          </TableCell>

                          <TableCell
                            colSpan={3}
                            align={"right"}
                            className={classes.TableCellFromDataSubSection}
                          />
                        </>
                      </TableRow>
                    )}
                  </>
                ))}

              <Box style={{ padding: "10px" }}></Box>
            </React.Fragment>
          ))}
        </TableBody>

        {!isMobile && (
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={3}
                className={classes.TableCellFromData}
                style={{ fontWeight: "bold" }}
              >
                Total Geral
              </TableCell>
              <TableCell align="right" className={classes.TableCellFromData}>
                {formatToReal(dataQueryComission.employees_commission_total)}
              </TableCell>
              <TableCell
                align="right"
                className={classes.TableCellFromData}
                style={{ fontWeight: "bold" }}
              >
                {formatToLiter(dataQueryComission.employees_quantity_total)}
              </TableCell>
              <TableCell
                colSpan={2}
                className={classes.TableCellFromData}
              ></TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default TableComissionAll;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    TableContainer: {
      width: "100%",
      boxShadow: "none",
    },

    TableRow: {
      background: "#fff",
    },
    TableCell: {
      padding: "6px 16px 6px 16px",
      marginBottom: "20px",
    },

    Table: {
      borderCollapse: "separate",
      marginBottom: 16,
    },

    TableCellHead: {
      fontWeight: "bold",
      whiteSpace: "nowrap",
      fontFamily: "DM Sans",
      borderBottom: "none",
      fontSize: "1.5rem",
      "&:first-child": {
        paddingLeft: "2.5rem",
        borderBottomLeftRadius: "0.6rem",
        borderTopLeftRadius: "0.6rem",
      },
      "&:last-child": {
        borderBottomRightRadius: "0.6rem",
        borderTopRightRadius: "0.6rem",
      },
    },

    TableCellFromData: {
      fontSize: "1.5rem",
      background: "#fff",
      marginBottom: "10px",
      borderBottom: "none",
      fontFamily: "Montserrat",
      "&:first-child": {
        paddingLeft: "2.5rem",
        borderBottomLeftRadius: "0.6rem",
        borderTopLeftRadius: "0.6rem",
      },
      "&:last-child": {
        borderBottomRightRadius: "0.6rem",
        borderTopRightRadius: "0.6rem",
      },
    },

    GridFromSubSection: {
      fontSize: "1.5rem",
      padding: "25px 10px 25px 16px",
    },

    TableCellFromDataSubSection: {
      fontSize: "1.5rem",
      background: "#fff",
      marginBottom: "10px",
      borderBottom: "none",
      fontFamily: "Montserrat",
      padding: "0px",
      "&:first-child": {
        paddingLeft: "2.5rem",
        borderBottomLeftRadius: "0.6rem",
      },
      "&:last-child": {
        borderBottomRightRadius: "0.6rem",
      },
    },

    TableCellMoreLines: {
      fontSize: "1.5rem",
      background: "#fff",
      borderBottom: "none",
      fontFamily: "Montserrat",
      "&:first-child": {
        borderBottomLeftRadius: "0.6rem",
        borderTopLeftRadius: "0.6rem",
      },
      "&:last-child": {
        borderBottomRightRadius: "0.6rem",
        borderTopRightRadius: "0.6rem",
      },
    },
  })
);
