import React from "react";
import {
  Grid,
  Box,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "bold",
      lineHeight: "normal",
      paddingTop: "20px",
    },
  })
);

interface ICardLayerActions {
  title: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
  marginTop?: string;
}
const CardLayerActions: React.FC<ICardLayerActions> = ({ ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));

  return (
    <Grid
      container
      style={{
        boxShadow: "0px 4px 4px 0px rgb(115, 97, 97,0.1)",
        backgroundColor: "#FFFFFF",
        width: "100%",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: isMobile ? "40px 20px" : "40px 50px",
        marginTop: props.marginTop,
      }}
    >
      <Grid
        item
        xs={11}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          className={classes.title}
          style={{
            fontSize: isMobile ? "1.5rem" : isTablet ? "1.6rem" : "1.8rem",
            paddingTop: "0px",
          }}
        >
          {props.title}
        </Typography>
      </Grid>

      <Grid
        item
        xs={1}
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box>{props.icon}</Box>
      </Grid>

      <Grid item xs={12} style={{ paddingTop: "40px" }}>
        {props.children}
      </Grid>
    </Grid>
  );
};

export default CardLayerActions;
