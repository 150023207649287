import React from "react";

import Layout from "@components/Layout";
import ViewCommunicated from "@pages/Admin/Communications/view";
import { CommunicationProvider } from "@pages/Admin/Communications/context";
import { useFlags } from "flagsmith/react";
import TemporaryAddCommunicationPage from "./view/TemporaryAddCommunicated";

const CommunicatedPage = () => {
  const flags = useFlags(["admin_sidebar_change_ui_send_communique"]);

  return (
    <Layout disableToolbar={true} disableToolbarMobile={true}>
      <CommunicationProvider>
        {flags.admin_sidebar_change_ui_send_communique.enabled ? (
          <ViewCommunicated />
        ) : (
          <TemporaryAddCommunicationPage />
        )}
      </CommunicationProvider>
    </Layout>
  );
};

export default CommunicatedPage;
