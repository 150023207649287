import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  CloseIconRadiusIcon,
  ImgEmptyIcon,
  RedTrashIcon,
  RightGrenIcon,
} from "@components/Icons";
import { cutText } from "@utils/index";
import { Grid } from "@mui/material";
import { useReclamationContext } from "../../../../context/ReclamationContext/ReclamationUserContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonAtacch: {
      color: "#FFF",
      background: "#D91F05",
      borderRadius: "2px",
      boxShadow: "5px 15px 30px 0px rgba(126, 126, 177, 0.10)",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "bold",
      textTransform: "none",
      textAlign: "center",

      "&:hover": {
        background: "#E95E4B",
      },
    },
    message: {
      paddingTop: "1.2rem",
      opacity: 0.37,
      color: theme.palette.text.primary,
      textAlign: "center",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "28px",
    },
    dropArea: {
      // border: "2px dashed #ccc",
      // backgroundColor: "#f7f7f7",
      color: " #888",
      textAlign: "center",
      padding: "20px 0",
      cursor: "pointer",
    },
    titleAttach: {
      color: theme.palette.text.primary,
      textAlign: "center",
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "28px",
      paddingTop: "10px ",
    },
    messageOr: {
      fontSize: "16px",
      color: " #888",
      fontFamily: "Montserrat",
      textAlign: "center",
    },
  })
);
interface IModalUploadImagemProps {
  isOpenModal: boolean;
  onClose: () => void;
}

const ModalUploadImagem: React.FC<IModalUploadImagemProps> = ({ ...props }) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isDisabledButtonSend, setIsDisabledButtonSend] = useState(false);

  const { uploadedImages, setUploadedImages } = useReclamationContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));

  const classes = useStyles();

  useEffect(() => {
    if (uploadedImages.length >= 3) {
      setIsDisabledButtonSend(true);
    } else {
      setIsDisabledButtonSend(false);
    }
  }, [uploadedImages]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const MAX_FILE_SIZE = 9 * 1024 * 1024; // 9MB em bytes
    const selectedFiles = event.target.files;

    if (selectedFiles) {
      const filesArray = Array.from(selectedFiles);
      const validFiles = filesArray.filter(
        (file) =>
          (file.type === "image/png" || file.type === "image/jpeg") &&
          file.size <= MAX_FILE_SIZE
      );

      if (validFiles.length > 0) {
        if (uploadedImages.length + validFiles.length <= 3) {
          setUploadedImages([...uploadedImages, ...validFiles]);
          setErrorMessage("");
        } else {
          setErrorMessage("Selecione até 3 imagens");
        }
      } else {
        setErrorMessage(
          "Os arquivos devem ser imagens PNG ou JPEG com até 9MB."
        );
      }
    }
  };

  const handleRemoveImage = (index: number) => {
    const updatedImages = uploadedImages.filter((_, i) => i !== index);
    setUploadedImages(updatedImages);
    setErrorMessage("");
  };

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const MAX_FILE_SIZE = 9 * 1024 * 1024; // 9MB em bytes
    const selectedFiles = event.dataTransfer.files;

    if (selectedFiles) {
      const filesArray = Array.from(selectedFiles);
      const validFiles = filesArray.filter(
        (file) =>
          file.type === "image/png" ||
          (file.type === "image/jpeg" && file.size <= MAX_FILE_SIZE)
      );

      if (validFiles.length > 0) {
        if (uploadedImages.length + validFiles.length <= 3) {
          setUploadedImages([...uploadedImages, ...validFiles]);
          setErrorMessage("");
        } else {
          setErrorMessage("Selecione até 3 imagens");
        }
      } else {
        setErrorMessage(
          "Os arquivos devem ser imagens PNG ou JPEG com até 9MB."
        );
      }
    }
  };

  return (
    <Dialog
      open={props.isOpenModal}
      PaperProps={{
        style: {
          borderRadius: "8px",
          padding: isMobile || isTablet ? "30px 0px" : "40px 0px",
          filter: "droShadow(0px 4px 4px rgba(176, 193, 225, 0.25))",
          width: isMobile || isTablet ? "95%" : "70%",
        },
      }}
      onClose={props.onClose}
    >
      <DialogActions>
        <Button
          onClick={props.onClose}
          style={{ marginTop: "-20px" }}
          type="button"
        >
          <CloseIconRadiusIcon style={{ fontSize: "20px" }} />
        </Button>
      </DialogActions>
      <DialogContent
        style={{
          padding: isMobile ? "0px" : "",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <input
          accept="image/png, image/jpeg"
          style={{ display: "none" }}
          id="fileInput"
          type="file"
          multiple
          onChange={handleFileChange}
        />

        <Grid container style={{ justifyContent: "center" }}>
          <Grid item xs={12}>
            {!isMobile && !isTablet && (
              <div
                className={classes.dropArea}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleFileDrop}
              >
                <ImgEmptyIcon style={{ fontSize: "60px" }} />
                <Typography className={classes.titleAttach}>
                  Arraste os arquivos aqui
                </Typography>
              </div>
            )}
          </Grid>

          {!isMobile && !isTablet && (
            <Grid item xs={12}>
              <Typography
                className={classes.messageOr}
                style={{ paddingBottom: "30px" }}
              >
                ou
              </Typography>
            </Grid>
          )}

          <Grid item>
            <label htmlFor={!isDisabledButtonSend ? "fileInput" : ""}>
              <Button
                className={classes.buttonAtacch}
                component="span"
                disabled={isDisabledButtonSend}
                style={{
                  background: isDisabledButtonSend ? "#AB3E2E" : "",
                  fontSize: isMobile || isTablet ? "15px" : "",
                  padding: isMobile || isTablet ? "5px 20px" : "10px 30px",
                }}
              >
                Escolher arquivo
              </Button>
            </label>
          </Grid>

          <Grid xs={12} item>
            <Typography
              className={classes.message}
              style={{ fontSize: isMobile ? "12px" : isTablet ? "15px" : "" }}
            >
              Escolha arquivos .png ou .jpeg. <br />
              {errorMessage === "" && isDisabledButtonSend === false && (
                <div style={{ padding: "0px 3px " }}>
                  Selecione até 3 imagens com tamanho até 9MB cada.
                </div>
              )}
            </Typography>
          </Grid>

          <Grid xs={12} item style={{ textAlign: "center" }}>
            {errorMessage && (
              <Typography color="error">{errorMessage}</Typography>
            )}
            {isDisabledButtonSend && (
              <Typography
                style={{
                  color: "#0BB873",
                  fontSize: isMobile ? "12px" : isTablet ? "15px" : "15px",
                }}
              >
                Limite de imagens atingido
                <RightGrenIcon
                  style={{
                    fontSize: isMobile ? "15px" : isTablet ? "16px" : "16px",
                    paddingLeft: "4px",
                  }}
                />
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid>
          <List>
            {uploadedImages.map((image, index) => (
              <ListItem key={index}>
                {image.type === "image/png" || image.type === "image/jpeg" ? (
                  <>
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Imagem ${index}`}
                      style={{
                        width: isMobile ? "20px" : isTablet ? "25px" : "30px",
                        height: isMobile ? "20px" : isTablet ? "25px" : "30px",
                      }}
                    />
                    <ListItemText>
                      <Typography
                        style={{
                          fontSize: isMobile
                            ? "11px"
                            : isTablet
                            ? "14px"
                            : "14px",
                          fontFamily: "DM Sans",
                          marginLeft: isMobile
                            ? "10px"
                            : isTablet
                            ? "20px"
                            : "20px",
                          marginRight: "10px",
                        }}
                      >
                        {cutText(
                          image.name,
                          isMobile ? 18 : isTablet ? 45 : 35
                        )}
                      </Typography>
                    </ListItemText>

                    <RightGrenIcon
                      style={{
                        fontSize: isMobile
                          ? "15px"
                          : isTablet
                          ? "18px"
                          : "20px",
                      }}
                    />
                    <IconButton onClick={() => handleRemoveImage(index)}>
                      <RedTrashIcon />
                    </IconButton>
                  </>
                ) : (
                  <ListItemText
                    primary={`Formato inválido - ${cutText(image.name, 35)}`}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalUploadImagem;
