import React, { useState } from "react";

import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import { useTheme } from "@material-ui/core";

import { TrashBaseIcon, WarnRedIcon } from "@components/Icons";
import Modal from "@components/Modal";
import Button from "@components/Button";

import { TypographyCaption, TypographyTitle } from "../typography";

interface Props {
  children?: React.ReactNode;
  disabled?: boolean;
  label?: string;
  order?: string;

  onClick: () => Promise<void>;
}

const ModalDeleteOrder: React.FC<Props> = ({ children, ...props }) => {
  const { disabled, onClick, label, order } = props;

  const theme = useTheme();
  const [load, setLoad] = useState(false);

  const handleOnClick = async () => {
    setLoad(true);
    await onClick();
    setLoad(false);
  };

  return (
    <Modal
      disableModalButtonOpen={disabled}
      ModalButton={
        <Tooltip
          title={
            disabled ? (
              <TypographyCaption sx={{ fontSize: 10 }}>
                {" "}
                Voce nao pode excluir a ultima ordem
              </TypographyCaption>
            ) : (
              ""
            )
          }
          placement="bottom"
        >
          <span>
            <IconButton
              disabled={disabled}
              sx={{
                opacity: disabled ? "0.4" : "1",
                gap: 1,
              }}
            >
              <TrashBaseIcon />{" "}
              <span style={{ color: "#000" }}>{label ?? ""}</span>
            </IconButton>
          </span>
        </Tooltip>
      }
    >
      {({ handleClose }) => {
        return (
          <Box
            className="modal-box-content"
            sx={{ width: { xs: "280px", md: "320px", lg: "400px" } }}
          >
            <Grid container sx={{ textAlign: "center", gap: 4 }}>
              <Grid item xs={12}>
                <WarnRedIcon />
              </Grid>
              <Grid item xs={12}>
                <TypographyTitle>
                  Deseja remover a ordem de venda{" "}
                  <b style={{ color: theme.palette.primary.main }}>{order}</b>{" "}
                  do agendamento?
                </TypographyTitle>
              </Grid>
              <Grid item xs={12}>
                <TypographyCaption>
                  Ao remover, a ordem de venda deixará de estar vinculado ao
                  agendamento.
                </TypographyCaption>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={2}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      disabled={load}
                      onClick={() => handleClose()}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      loading={load}
                      onClick={async () => {
                        await handleOnClick();
                        handleClose();
                      }}
                    >
                      Excluir
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        );
      }}
    </Modal>
  );
};

export default ModalDeleteOrder;
