import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button, { ButtonProps } from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  Grid,
  IconButton,
  Input,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { CheckedIcon, FileUploadIcon, TrashIcon } from "@components/Icons";

interface Props {
  onChangeUploadFile: (file: File | null) => void;
  handleDeleteFile: () => void;
  disabled: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 400,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f7f7f7",
    },
  },
  input: {
    display: "none",
  },
  bounceInDragging: {
    animationName: "$bounceIn",
    animationDuration: "1s",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
  },
  "@keyframes bounceIn": {
    "0%, 20%, 40%, 60%, 80%, 100%": {
      transitionTimingFunction: "cubic-bezier(0.215, 0.610, 0.355, 1.000)",
    },
    "0%": {
      opacity: 0,
      transform: "scale3d(.3, .3, .3)",
    },
    "20%": {
      transform: "scale3d(1.1, 1.1, 1.1)",
    },
    "40%": {
      transform: "scale3d(.9, .9, .9)",
    },
    "60%": {
      transform: "scale3d(1.03, 1.03, 1.03)",
    },
    "80%": {
      transform: "scale3d(.97, .97, .97)",
    },
    "100%": {
      transform: "scale3d(1, 1, 1)",
    },
  },
}));

const FileUpload: React.FC<Props> = ({ ...props }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
      props.onChangeUploadFile(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      setFile(event.dataTransfer.files[0]);
      props.onChangeUploadFile(file);
    }
  };

  const AppliedCloudIcon: React.FC = () => {
    return (
      <>
        {props.disabled ? (
          <></>
        ) : (
          <CloudUploadIcon className={classes.bounceInDragging} />
        )}
      </>
    );
  };

  const ButtonFile: React.FC<ButtonProps> = ({ children, onClick }) => {
    return (
      <Button
        variant="contained"
        color="primary"
        component="span" // Obrigatorio -> se remover para de funcionar
        startIcon={<AppliedCloudIcon />}
        onClick={onClick}
        style={{
          backgroundColor: theme.palette.primary.main,
          textTransform: "initial",
          padding: "8px 32px 8px 32px",
        }}
      >
        <Typography
          variant="subtitle2"
          style={{ color: theme.palette.text.hint, fontWeight: 600 }}
        >
          {children}
        </Typography>
      </Button>
    );
  };

  const updateUploadFile = React.useCallback(() => {
    props.onChangeUploadFile(file);
  }, [file, props]);

  React.useEffect(() => {
    updateUploadFile();
  }, [updateUploadFile]);

  return (
    <Grid container>
      <Grid item xs={12} onDragOver={handleDragOver} onDrop={handleDrop}>
        {file ? (
          <Grid
            container
            justifyContent="center"
            alignContent={"space-between"}
            style={{
              textAlign: "center",
              border: "0px solid black",
              height: "100%",
            }}
          >
            {/* BLOCO QUANDO EXISTE ARQUIVO */}
            <Grid
              item
              xs={12}
              style={{ border: "0px solid red", marginTop: 60, padding: 16 }}
            >
              <Grid
                container
                justifyContent="flex-start"
                style={{ border: "0px solid red" }}
              >
                {/* BLOCO ------ TITULO  */}
                <Grid item xs={12} style={{ marginBottom: 32 }}>
                  <Typography
                    variant="h5"
                    color="textPrimary"
                    style={{ fontWeight: 700, marginBottom: 4 }}
                  >
                    {" "}
                    Seus arquivos
                  </Typography>
                </Grid>

                <Grid item xs={1}>
                  <FileUploadIcon style={{ marginRight: 8, fontSize: "2rem" }}>
                    <img
                      // src={nozzle.image}
                      alt={"nozzle.product"}
                      height="40"
                    />
                  </FileUploadIcon>
                </Grid>

                {/* BLOCO ------ NOME DO ARQUIVO */}
                <Grid item xs={6} style={{ textAlign: "left", paddingLeft: 6 }}>
                  {/* <Typography variant="subtitle2" color="primary" >{file ? file.name : "-"}</Typography> */}
                  <Typography variant="caption" color="primary">
                    {file.name}
                  </Typography>
                </Grid>

                {/* BLOCO ------ ICONES DE ARQUIVO - ACAO DE DELETAR */}
                <Grid item xs={5} style={{ textAlign: "right" }}>
                  <IconButton style={{ padding: 4 }}>
                    {" "}
                    <CheckedIcon style={{ fontSize: "1.5rem" }} />{" "}
                  </IconButton>
                  <IconButton
                    onClick={() => setFile(null)}
                    style={{ padding: 4 }}
                  >
                    {" "}
                    <TrashIcon
                      style={{ fontSize: "1.5rem", fill: "none" }}
                    />{" "}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ marginTop: 50 }}>
              {/* <ButtonSend>Enviar</ButtonSend> */}
              {props.children}
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            {/* BLOCO  QUANDO NAO! EXISTE ARQUIVO */}
            <Grid item xs={12}>
              <FileUploadIcon style={{ fontSize: "8rem" }} />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="textPrimary"
                style={{ fontWeight: 700 }}
              >
                Arraste o arquivo aqui
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ opacity: props.disabled ? 0.3 : 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    style={{ fontWeight: 400, opacity: 0.7 }}
                  >
                    {" "}
                    ou
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    id="file-upload"
                    type="file"
                    className={classes.input}
                    onChange={handleFileChange}
                    disabled={props.disabled}
                  />
                  <InputLabel htmlFor="file-upload">
                    <ButtonFile>Escolher arquivo</ButtonFile>
                  </InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    style={{ fontWeight: 400, opacity: 0.7 }}
                  >
                    {" "}
                    Escolha arquivos .png ou .jpeg <br />
                    Quantidade máxima de até três arquivos.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default FileUpload;
