import { HomeUserContextProvider } from "@context/HomeUserContext";
import { IoCProvider } from "@context/IoCContext/IoCContext";
import { UserProvider } from "@context/UserContext";
import { AuthProvider } from "@context/auth/AuthContext";
import DateFnsUtils from "@date-io/date-fns";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { ThemeProvider as ThemeProviderV5 } from "@mui/material";

import Routes from "@components/Routes";
import Theme from "@components/Theme";
import Themev5 from "@components/Theme/themev5";
import { isDevelopment } from "@config/appConfig";
import { datadogRum } from "@datadog/browser-rum";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ptBRLocale from "date-fns/locale/pt-BR";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import { SnackbarProvider } from "notistack";
import React from "react";

const App: React.FC = () => {
  console.log("isDevelopment:", isDevelopment);

  return (
    <FlagsmithProvider
      options={{
        environmentID: isDevelopment
          ? "nSP8jo5BMTomNeukSDBXkR"
          : "SZZmKi4P2g8HBjKtnWVNtW",
        api: "https://flagsmith.ream.com.br/api/v1/",
        datadogRum: {
          client: datadogRum,
          trackTraits: true,
        },
      }}
      flagsmith={flagsmith}
    >
      <IoCProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
          <AuthProvider>
            <ThemeProvider theme={Theme}>
              <ThemeProviderV5 theme={Themev5}>
                <UserProvider>
                  <SnackbarProvider
                    autoHideDuration={5000}
                    maxSnack={3}
                    preventDuplicate
                  >
                    <CssBaseline />
                    <HomeUserContextProvider>
                      <Routes />
                    </HomeUserContextProvider>
                  </SnackbarProvider>
                </UserProvider>
              </ThemeProviderV5>
            </ThemeProvider>
          </AuthProvider>
        </MuiPickersUtilsProvider>
      </IoCProvider>
    </FlagsmithProvider>
  );
};

export default App;
