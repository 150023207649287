import React, { useState } from "react";

import { Drawer, Grid, IconButton } from "@mui/material";

import { TypographyTitle } from "@pages/User/RoadLoad/resource/typography";

import { CloseIcon, FilterIcon } from "@components/Icons";
import Button from "@components/Button";

import DrawerContentForm from "./DrawerContentForm";
import { useRoadLoad } from "../../context/RoadLoadContext";

interface DrawerFilterProps {
  children?: React.ReactNode;
}

const DrawerFilter: React.FC<DrawerFilterProps> = ({ children, ...props }) => {
  const { valuesFilterFormik, handleValueFilterFormik } = useRoadLoad();

  const [open, setOpen] = useState<boolean>(false);

  const closeDrawer = () => {
    setOpen(false);
  };

  const toggleOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <Grid container {...props}>
      <Button
        variant="text"
        sx={{
          py: 0,
          my: 0,
          fontWeight: 700,
          fontSize: { xs: 14, lg: 16, xl: 18 },
        }}
        onClick={toggleOpen}
      >
        {children}
      </Button>
      <Drawer
        className="DrawerFilter-roadLoad"
        open={open}
        onClose={toggleOpen}
        anchor="right"
        sx={{ zIndex: 1201, border: "0px solid red" }}
        PaperProps={{
          sx: {
            overflowX: "hidden",
            borderRadius: 2,
          },
        }}
      >
        <Grid
          container
          sx={{
            width: { xs: "80vw", lg: 470, xl: 600 },
            gap: { xs: 1, md: 2, lg: 2.5, xl: 3 },
            p: { xs: 1.5, lg: 3 },
          }}
        >
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} textAlign={"right"}>
                <IconButton onClick={toggleOpen}>
                  <CloseIcon />
                </IconButton>
              </Grid>

              <Grid item xs={12}>
                <TypographyTitle>Filtrar dados</TypographyTitle>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ pt: 4 }}>
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid item>
                <FilterIcon style={{ height: 70, width: 70 }} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <DrawerContentForm
              initialValuesFormik={valuesFilterFormik}
              handleValuesFormik={handleValueFilterFormik}
              handleCloseDrawer={() => closeDrawer()}
            />
          </Grid>
        </Grid>
      </Drawer>
    </Grid>
  );
};

export default DrawerFilter;
