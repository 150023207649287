import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { IHistoryUserRegister } from "@utils/interfaces";
import { inject, injectable } from "inversify";
import { IGetDataChartsDash } from "../dtos/IGetDataChartsDash";
import { IGetHistoryQtUserRegisterService } from "../models/IGetHistoryQtUserRegisterService";

@injectable()
export class GetHistoryQtUserRegisterService
  implements IGetHistoryQtUserRegisterService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  execute(data: IGetDataChartsDash): Promise<IHistoryUserRegister[]> {
    return this.httpInstance.get<IHistoryUserRegister[]>(
      `/dashboard-posh/taxReceipt?action=${data.action}&companies_id=${data.companies_id}&type_date_trunc=${data.type_date_trunc}&from=${data.from}&to=${data.to}`
    );
  }
}
