import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ROUTES from "../../config/routes";
import { Types } from "../../ioc/types";
import { IGetHandoutsSeenOrNotService } from "../../modules/handouts/models/IGetHandoutsSeenOrNotService";
import { useIoCContext } from "../IoCContext/IoCContext";
import { useUserState } from "../UserContext";
import Actions from "./actions/actions";
import HandoutReducer from "./reducer";
import State from "./State";

type Dispatch = (action: Actions) => void;

const HandoutStateContext = React.createContext<State | undefined>(undefined);
const HandoutDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

function useHandoutState() {
  const context = React.useContext(HandoutStateContext);
  if (!context) {
    throw Error(
      "useHandoutState deve ser utilizado dentro de um HandoutProvider"
    );
  }
  return context;
}

function useHandoutDispatch() {
  const context = React.useContext(HandoutDispatchContext);
  if (!context) {
    throw Error(
      "useHandoutDispatch deve ser utilizado dentro de um HandoutProvider"
    );
  }
  return context;
}

const HandoutProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(HandoutReducer, {
    showModal: false,
    showLoginModal: false,
    handoutsNotViewed: [],
  });
  const location = useLocation();
  const userState = useUserState();
  const iocContext = useIoCContext();

  useEffect(() => {
    async function fetch() {
      try {
        const getHandoutsSeenOrNot = iocContext.serviceContainer.get<
          IGetHandoutsSeenOrNotService
        >(Types.Handouts.IGetHandoutsSeenOrNotService);

        const handouts = await getHandoutsSeenOrNot.execute(userState.userID);

        const handoutsNotViewed = handouts.filter((ele) => !ele.handoutAck);
        if (
          location.pathname === ROUTES.USER_ROUTES.HOME &&
          handoutsNotViewed.length &&
          !userState.adminMode
        ) {
          dispatch({
            type: "APP_SET_HANDOUTS_NOT_VIEWED",
            payload: handoutsNotViewed,
          });
          dispatch({
            type: "APP_SHOW_HANDOUT_LOGIN",
            payload: {
              content: handoutsNotViewed[0].content,
              createdAt: handoutsNotViewed[0].createdAt,
              title: handoutsNotViewed[0].title,
              id: handoutsNotViewed[0].id,
              viewed: Boolean(handoutsNotViewed[0].handoutAck),
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetch();
  }, [
    iocContext.serviceContainer,
    location.pathname,
    userState.adminMode,
    userState.userID,
  ]);

  return (
    <HandoutStateContext.Provider value={state}>
      <HandoutDispatchContext.Provider value={dispatch}>
        {children}
      </HandoutDispatchContext.Provider>
    </HandoutStateContext.Provider>
  );
};

export { useHandoutState, useHandoutDispatch, HandoutProvider };
