import { useTheme } from "@material-ui/core";
import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";

import { useNozzleAdmin } from "@pages/Admin/Nozzle/context/NozzleAdminContext";
import DrawerFilterNozzles from "@pages/Admin/Nozzle/resource/filter/DraweFilter";
import TableNozzleAdmin, {
  SkeletonTable,
} from "@pages/Admin/Nozzle/resource/table/TableNozzleAdmin";
import TableNozzleValidate from "@pages/Admin/Nozzle/resource/table/TableNozzleValidate";

import ButtonDefault from "@components/ButtonDefault";
import { ArrowLeftIcon } from "@components/Icons";

const NozzleAdminView: React.FC = () => {
  const theme = useTheme();
  const { nozzlesAdmin, nozzlesValidate, loadTable } = useNozzleAdmin();

  const [showTableNozzleAdmin, setShowTableNozzleAdmin] = useState<boolean>(
    true
  );
  const [CNPJSelected, setCNPJSelected] = useState<string | null>(null);

  const handleCNPJSelected = (cnpj: string) => {
    setCNPJSelected(cnpj);
    handleShowTableNozzleValidate();
  };

  const handleShowTableNozzleAdmin = () => {
    setShowTableNozzleAdmin(true);
  };

  const handleShowTableNozzleValidate = () => {
    setShowTableNozzleAdmin(false);
  };

  return (
    <Grid
      container
      className="nozzle-admin"
      sx={{
        border: "0px solid blue",
        gap: { xs: 1, md: 2, lg: 3 },
        p: { xs: 1, md: 2, lg: 3 },
      }}
    >
      <Grid item xs={12} sx={{ border: "0px solid red" }}>
        <Typography
          variant={"h1"}
          sx={{
            fontFamily: theme.typography.fontFamily,
            fontSize: { xs: 16, md: 24, lg: 32, xl: 36 },
            fontWeight: 700,
          }}
        >
          Validar anexo de bico e produto
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          sx={{
            justifyContent: showTableNozzleAdmin ? "right" : "left",
            gap: 1,
          }}
        >
          <Grid
            item
            sx={{
              border: "0px solid red",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {showTableNozzleAdmin ? (
              <DrawerFilterNozzles>Filtrar</DrawerFilterNozzles>
            ) : (
              <>
                <ButtonDefault
                  variant={"text"}
                  style={{ padding: 0, margin: 0 }}
                  onClick={handleShowTableNozzleAdmin}
                >
                  <ArrowLeftIcon
                    style={{
                      paddingLeft: 6,
                      fontSize: 12,
                      marginRight: "10px",
                    }}
                  />
                  Voltar para lista de solicitações de cadastro
                </ButtonDefault>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            {loadTable ? (
              <SkeletonTable colSpan={10} />
            ) : showTableNozzleAdmin ? (
              <TableNozzleAdmin
                handleSelectedCNPJ={handleCNPJSelected}
                rows={nozzlesAdmin ?? []}
              />
            ) : (
              <TableNozzleValidate
                rows={nozzlesValidate ?? []}
                cnpjSelected={CNPJSelected}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NozzleAdminView;
