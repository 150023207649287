import { inject, injectable } from "inversify";
import { IDownloadAllReportTitlesService } from "../models/IDownloadAllReportTitlesService";
import { Types } from "@ioc/types";
import * as IHttpService from "@modules/infra/http/models/IHttpService";
import { IQueryDownloadTitlesDTO } from "../dtos/IQueryDownloadTitlesDTO";

@injectable()
export class DownloadAllReportTitlesService
  implements IDownloadAllReportTitlesService {
  @inject(Types.IHttpService)
  private httpInstance!: IHttpService.IHttpService;

  public async searchExcel(data: IQueryDownloadTitlesDTO): Promise<string> {
    try {
      const file = await this.httpInstance.get<BinaryType>(
        "/titles/v2/report",
        {
          params: data,
          responseType: "blob", // Certifique-se de definir o responseType como 'blob'
        }
      );

      const url = URL.createObjectURL(
        new Blob([file], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }) // Use o tipo MIME apropriado para arquivos Excel
      );

      return url;
    } catch (error) {
      throw error;
    }
  }
}
