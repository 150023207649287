import React from "react";
import { TextField, OutlinedTextFieldProps } from "@mui/material";
import { useTheme } from "@material-ui/core";

interface TextFieldCustomProps extends Omit<OutlinedTextFieldProps, "variant"> {
  variant?: "outlined"; // Torna a propriedade variant opcional
  id?: string;
  name: string;
  label?: string;
  value: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<any>) => void;
}

const TexFieldLoose: React.FC<TextFieldCustomProps> = ({
  variant = "outlined",
  ...props
}) => {
  const theme = useTheme();

  return (
    <TextField
      {...props}
      id={props.id}
      variant={variant}
      name={props.name}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      fullWidth
      helperText={props.helperText}
      sx={{
        width: "100%",
        "& label": {
          // Controle de estilo antes de digitar
          color: "#3E3D3D",
          fontFamily: "montserrat",
          fontWeight: 500,
          fontSize: "1.4rem",
          borderRadius: "1rem",
          opacity: "0.5",
          top: "-2px",
        },
        "& .MuiInputBase-input": {
          fontFamily: "montserrat",
          fontWeight: 500,
          fontSize: "1.4rem",
          textAlign: "left",
          color: "#3E3D3D",
          paddingTop: "18px",
          paddingBottom: "12px",
        },
        "& .MuiInputBase-input:focus": {
          fontFamily: "montserrat",
          fontWeight: 500,
          fontSize: "1.4rem",
          textAlign: "left",
          color: "#3E3D3D",
          paddingTop: "1.8rem",
          paddingBottom: "1rem",
        },
        "& input.Mui-focused": {}, // Controle de estilo durante digitacao
        "& .MuiInputLabel-shrink": {
          // LABEL DO INPUT
          // Controle de estilo apos campo digitado
          fontWeight: 700,
          color: "#3E3D3D !important",
          fontSize: "1.8rem",
          backgroundColor: "white",
          paddingRight: "8px",
          opacity: props.disabled ? 0.5 : 1,
          zIndex: 3,
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
          // Controla cor da borda durante Digitacao
          borderColor: "#FF9900 !important",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: props.error
            ? `1px solid ${theme.palette.error.light} !important`
            : `1px solid rgba(0,0,0,0,0.9) !important`, // Sinalizacao de campo incorreto -> "#F97A91"
          borderRadius: "1rem",
          opacity: "0.6",
          zIndex: 2,
        },
        "& .MuiSelect-select": {
          backgroundColor: "initial",
        },
        "& .MuiSelect-select:focus": {
          backgroundColor: "initial",
        },
        "& .MuiSelect-icon": {
          color: "transparent", // torna o ícone padrão transparente
        },
        "& .MuiFormHelperText-root.Mui-error.Mui-focused": {
          // Esconde a mensagem de erro enquanto usuario estiver digitando
          display: "none",
        },
        "& .MuiFormHelperText-root": {
          // Esconde a mensagem de erro enquanto usuario estiver digitando
          display: "none",
        },
        "& .MuiFormHelperText-root.Mui-error": {
          display: "block",
          fontSize: 12,
          color: theme.palette.error.light, // "#F97A91"
          opacity: 0.7,
        },
      }}
    />
  );
};

export default TexFieldLoose;
