import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { IGetCardTitleDTO } from "../dtos/IGetCardTitleDTO";
import { IGetCardTitleService } from "../models/IGetCardTitleService";

@injectable()
export class GetCardTitleService implements IGetCardTitleService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(cnpj: string) {
    const cardTitle = await this.httpInstance.get<IGetCardTitleDTO[]>(
      `/titles/v2?companies=${cnpj}&top=5&action=recent&attrs=customerName,statusDoc,value,item,reference`
    );

    return cardTitle;
  }
}
