import { StatusTagType } from "@components/StatusTagGeneric/StatusTagGeneric";
import {
  financialStatusAcronym,
  financialStatusDescriptionPTBR,
} from "../models/ENUM_MY_ORDERS";

export const getFinancialStatusPTBR = (status: string) => {
  switch (status) {
    case financialStatusAcronym.BL_BLOQUEADO: {
      return financialStatusDescriptionPTBR.BLOQUEADO;
    }
    case financialStatusAcronym.LIB_LIBERADO: {
      return financialStatusDescriptionPTBR.LIBERADO;
    }
    case financialStatusAcronym.FT_FATURADO: {
      return financialStatusDescriptionPTBR.FATURADO;
    }
    case financialStatusAcronym.DEV_DEVOLUCAO: {
      return financialStatusDescriptionPTBR.DEVOLUCAO;
    }
    default: {
      return "Indefinido";
    }
  }
};

export const getFinancialStatus = (statusFinancial: string) => {
  switch (statusFinancial) {
    case financialStatusAcronym.BL_BLOQUEADO: {
      return StatusTagType.ERROR;
    }
    case financialStatusAcronym.LIB_LIBERADO: {
      return StatusTagType.WARNING;
    }
    case financialStatusAcronym.FT_FATURADO: {
      return StatusTagType.SUCCESS;
    }
    case financialStatusAcronym.DEV_DEVOLUCAO: {
      return StatusTagType.NONE;
    }
    default: {
      return StatusTagType.NONE;
    }
  }
};

export const isBlockCalendarDelivery = (
  freightType: string,
  orderStatus?: string
): boolean => {
  if (orderStatus === "LIB") {
    return false;
  }

  // Para ordens bloqueadas, permita a alteração a qualquer momento.
  if (orderStatus === "BL") {
    return false;
  }

  if (orderStatus === "FT") {
    return true;
  }

  const currentHour = new Date().getHours();
  const isCIFBlocked = freightType === "CIF" && currentHour > 13;
  const isFOBBlocked = freightType === "FOB" && currentHour > 14;

  return isCIFBlocked || isFOBBlocked;
};

export const getStatusColorStatusFinancial = (status: string): string => {
  switch (status) {
    case "BL":
      return "#D91F05";
    case "LIB":
      return "#FFB800";
    case "FT":
      return "#0BB873";
    case "carregamento":
    case "aguardando carregamento":
    case "carregamento finalizado":
    case "carregamento cancelado":
    case "DEV":
      return "#3E3D3D";
    default:
      return "#3E3D3D";
  }
};
