import React from "react";

import { Typography } from "@material-ui/core";
import { CircularProgress, Grid } from "@mui/material";

import { useNozzle } from "@pages/User/Nozzle/context";
import ModalNozzle from "@pages/User/Nozzle/resource/ModalNozzle";

import TableNozzle from "@pages/User/Nozzle/resource/TableNozzle";

import ButtonDefault from "@components/ButtonDefault";
import SkeletonTableInvoicesImages from "@pages/Admin/ListInvoicesImages/SkeletonTableListInvoicesImages";

const NozzleView: React.FC = () => {
  const {
    nozzles,
    loading,
    selectedCNPJ,
    nozzlesSelectList,
    handleOnClickFetchNozzles,
    handleOnClickUpdateImage,
    handleOnClickSendImage,
    loadingFetchNozzles,
  } = useNozzle();

  return (
    <Grid
      className="nozzleview"
      container
      style={{
        border: "0px solid red",
        padding: 24,
        margin: 0,
        overflowY: "hidden",
      }}
      spacing={2}
    >
      <Grid item xs={12} style={{}}>
        <Typography
          variant="h5"
          style={{ fontWeight: 700, paddingRight: "2rem" }}
        >
          Bico e produto
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {nozzles.length === 0 && !loadingFetchNozzles ? (
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                textAlign: "center",
                padding: 0,
                marginTop: 48,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {!loadingFetchNozzles && (
                <ButtonDefault onClick={handleOnClickFetchNozzles}>
                  Solicitar registros de bicos
                </ButtonDefault>
              )}
            </Grid>
          </Grid>
        ) : loadingFetchNozzles ? (
          <Grid item xs={12}>
            <SkeletonTableInvoicesImages cols={5} rows={10} />
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12} style={{ textAlign: "center", padding: 0 }}>
              {loading ? (
                <CircularProgress />
              ) : (
                <ModalNozzle
                  handleNozzlesFirstRegister={handleOnClickSendImage}
                  cnpj={selectedCNPJ.cnpj}
                  nozzlesModal={nozzlesSelectList}
                  labelButton="Enviar fotos"
                  loading={loading}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TableNozzle
                handleNozzlesUpdate={handleOnClickUpdateImage}
                rows={nozzles}
                rowsPerPage={10}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default NozzleView;
