import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";

interface IPageNotFoundProps {
  title: string;
  subtitle?: string;
  icon?: JSX.Element;
}

const PageNotFound: React.FC<IPageNotFoundProps> = ({
  title,
  subtitle,
  icon,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Box
      sx={{
        padding: "10%",
        border: "1px dashed #D2D3D6",
        height: "100%",
        borderRadius: 1,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          maxWidth: "100%",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Box>{icon ? icon : ""}</Box>
        <Typography
          variant={isMobile ? "subtitle1" : "h4"}
          style={{
            paddingTop: "20px",
            fontFamily: "Montserrat",
            fontWeight: 700,
            fontSize: isMobile ? "15px" : "24px",
            lineHeight: "32px",
            color: "#3E3D3D",
          }}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant="body1"
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontSize: isMobile ? "13px" : "16px",
              lineHeight: "24px",
              color: "#3E3D3D",
              paddingTop: "20px",
            }}
          >
            {subtitle}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default PageNotFound;
