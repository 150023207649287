import React, { createContext, useCallback, useContext, useState } from "react";
import { IClient } from "../../../utils/interfaces";

interface FormContext {
  loading: boolean;
  loadingCreateOrder: boolean;
  dataCustomer: IClient | null;
  setLoading(loading: boolean): void;
  setLoadingCreateOrder(loading: boolean): void;
  setDataCustomer(data: IClient): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FormContext = createContext<FormContext>({} as FormContext);

const FormProviderRequestOrderOld: React.FC = ({ children }) => {
  const [loading, setLoadingState] = useState(false);
  const [loadingCreateOrder, setLoadingCreateOrderState] = useState(false);
  const [dataCustomer, setDataCustomer] = useState<IClient | null>(null);

  const setLoading = useCallback((loading: boolean) => {
    setLoadingState(loading);
  }, []);

  const setLoadingCreateOrder = useCallback((loading: boolean) => {
    setLoadingCreateOrderState(loading);
  }, []);

  return (
    <FormContext.Provider
      value={{
        loading,
        dataCustomer,
        setLoading,
        setLoadingCreateOrder,
        loadingCreateOrder,
        setDataCustomer,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

const useForm = (): FormContext => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error(
      "useForm deve ser utilizado dentro de um FormProviderRequestOrderOld"
    );
  }
  return context;
};

export { useForm, FormProviderRequestOrderOld };
