import { inject, named, injectable } from "inversify";
import { Types } from "@ioc/types";
import * as IHttpService from "@modules/infra/http/models/IHttpService";

import {
  IEmployeeResponse,
  IGetYourSalesService,
  IProduct,
  IYourSalesRequest,
  IYourSalesResponse,
} from "../models/IGetYourSalesService";

import { cleanParams, fileNameByHeaders } from "@utils/index";
import {
  IGetYourSalesDTO,
  IGetYourSalesIndicatorsDTO,
} from "../dtos/IGetYourSalesDTO";

@injectable()
export class GetYourSalesService implements IGetYourSalesService {
  @inject(Types.IHttpService)
  @named("pump")
  private httpInstance!: IHttpService.IHttpService;

  async getYourSales(data: IYourSalesRequest): Promise<IYourSalesResponse> {
    const params = {
      document: data.document,
      startdate: data.startdate,
      enddate: data.enddate,
      employee_id: data.employee_id,
      product_id: data.product_id,
      isDownload: data.hasDownload,
    } as IYourSalesRequest;

    try {
      const salesResponse = await this.httpInstance.get<IYourSalesResponse>(
        `/supply?&scopes=["whichEmployeeAndCompany"]&action=by_employee_product&limit=4000`,
        {
          params: cleanParams(params),
        }
      );

      return salesResponse;
    } catch (err) {
      throw err;
    }
  }

  async getYourSalesDownload(
    data: IYourSalesRequest
  ): Promise<[string, string | null]> {
    try {
      const {
        data: titleFile,
        headers,
      } = await this.httpInstance.getWithHeaders<BinaryType>(
        `/supply?scopes=["whichEmployeeAndCompany"]`,
        {
          params: {
            document: data.document,
            startdate: data.startdate,
            enddate: data.enddate,
            employee_id: data.employee_id,
            product_id: data.product_id,
            isDownload: data.hasDownload,
          },
          responseType: "arraybuffer",
        }
      );

      let url = window.URL.createObjectURL(
        new Blob([titleFile], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );

      return [url, fileNameByHeaders(headers)];
    } catch (err) {
      throw err;
    }
  }

  async getYourSalesProduct(): Promise<IProduct[]> {
    try {
      const products = await this.httpInstance.get<IProduct[]>("/product", {
        params: {},
      });
      return products;
    } catch (error) {
      throw error;
    }
  }

  async getYourSalesEmployee(cnpj: string): Promise<IEmployeeResponse> {
    try {
      const employees = await this.httpInstance.get<IEmployeeResponse>(
        `/employee?&scopes=["whichCompany"]`,
        {
          params: {
            document: cnpj,
          },
        }
      );
      return employees;
    } catch (error) {
      throw error;
    }
  }

  public async execute(cnpj?: string): Promise<IGetYourSalesDTO> {
    try {
      const yourSalesResponse = await this.httpInstance.get<IGetYourSalesDTO>(
        `/dash_volume_billing_price?target[]=sumVolume&target[]=sumBilling&document=${cnpj}&period=currentMonth`
      );

      return yourSalesResponse;
    } catch (err) {
      throw err;
    }
  }

  public async getIndicators(
    cnpj?: string
  ): Promise<IGetYourSalesIndicatorsDTO> {
    try {
      const indicatorsResponse = await this.httpInstance.get<
        IGetYourSalesIndicatorsDTO
      >(`dash_volume_billing_price/indicators?document=${cnpj}`);

      return indicatorsResponse;
    } catch (err) {
      throw err;
    }
  }

  public setAuthorization(token: string): void {
    this.httpInstance.setAuthorization(token);
  }
}
