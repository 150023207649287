import SkeletonCardTitle from "@components/SkeletonCardTitle";
import React from "react";

const LoadingCardTitleMobile: React.FC = () => {
  return (
    <>
      <SkeletonCardTitle />
    </>
  );
};

export default LoadingCardTitleMobile;
