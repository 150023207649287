import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Button, Grid, Typography, Skeleton } from "@mui/material";
import { FuelProductIcon } from "@components/Icons";
import TextFieldSelect from "@components/TextFieldSelect";
import {
  FormikInitialValuesNewRequest,
  FormikName,
} from "@pages/User/NewRequest/resources/form/IForm";
import {
  IPayment,
  IPaymentDays,
  IProductCard,
} from "@pages/User/NewRequest/Model";
import { useForm } from "@pages/User/NewRequest/resources/context/FormContext";
import {
  applyTaxAccordingFreight,
  capitalizeFirstLetterFullString,
  createOptionListAmount,
  formatPaymentValue,
  removeDots,
  subtotalApplyTaxAccordingFreight,
  toFixedParseString,
} from "@utils/index";

import TextFieldCheckBox from "@components/TextFieldCheckBox";

interface ProductCardProps extends ProductsCardsProps {
  productCard: IProductCard;
  index: number;
}

interface TableHeaderProductsProps {
  headers: ITableProductsColumn[];
}

interface ProductProps {
  products: IProductCard[];
}

interface ProductsCardsProps {
  children?: React.ReactNode;
  products?: IProductCard[];
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface IProductTable {
  icon: React.ReactNode;
  code: string;
  name: string;
  value: React.ReactNode;
  subtotal: React.ReactNode;
  payment: React.ReactNode;
  amount: React.ReactNode;
  term: React.ReactNode;
  iconRemove: React.ReactNode;
}

interface ITableProductsColumn {
  option: string;
  align: "left" | "right" | "inherit" | "center" | "justify" | undefined;
  key: keyof IProductTable;
}

const dataTableHeaders: ITableProductsColumn[] = [
  { option: "", align: "center", key: "icon" },
  { option: "Código", align: "left", key: "code" },
  { option: "Produto", align: "left", key: "name" },
  { option: "Valor do Litro", align: "left", key: "value" },
  { option: "Subtotal", align: "left", key: "subtotal" },
  { option: "Forma de pagamento", align: "center", key: "payment" },
  { option: "Quantidade", align: "center", key: "amount" },
  { option: "Prazo", align: "center", key: "term" },
  { option: "", align: "center", key: "iconRemove" },
];

enum paymentType {
  A_VISTA = "A vista",
  A_PRAZO = "A prazo",
}

const useStyles = makeStyles(({ ...theme }) =>
  createStyles({
    table: {
      borderCollapse: "collapse",
      borderRadius: "1.6rem",
      width: "100%",
      backgroundColor: "#FFFFFF",
    },
    TableContainer: {
      width: "100%",
      boxShadow: "none",
    },
    tableRowHeader: {
      backgroundColor: "transparent",
      transition: "all 0.25s ease",
      borderRadius: "1rem",
    },
    tableHeader: {
      backgroundColor: "#FFFFFF",
      padding: "4rem 1.6rem 0.8rem 1.6rem",
      fontWeight: 500,
      textAlign: "left",
      fontSize: "1.4rem",
      color: "#626166",
      "&:last-child, &:last-child": {
        borderTopLeftRadius: "1.6rem",
        borderTopRightRadius: "1.6rem",
      },
    },
    tableRowItems: {
      cursor: "auto",
      borderTop: "0.1rem solid #EAEAEA",
      "&:last-child, &:last-child": {
        borderTop: "0.1rem solid transparent",
      },
    },
    tableCell: {
      padding: "1.6rem 1.6rem",
      fontSize: "1.4rem",
      color: "grey",
    },
  })
);

const TableProducts: React.FC<ProductProps> = ({ products }) => {
  const classes = useStyles();
  return (
    <TableContainer className={classes.TableContainer}>
      <Table className={classes.table}>
        <TableHeaderProducts headers={dataTableHeaders} />
        {products.map((product, index) => (
          <TableBodyProducts
            key={product.id}
            index={index}
            productCard={product}
          />
        ))}
      </Table>
    </TableContainer>
  );
};

const TableHeaderProducts: React.FC<TableHeaderProductsProps> = ({
  headers,
}) => {
  const classes = useStyles();

  return (
    <TableHead className={classes.tableRowHeader}>
      <TableRow>
        {headers.map((header, index) => (
          <TableCell key={index}>{header.option}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TableBodyProducts: React.FC<ProductCardProps> = ({
  productCard,
  index,
}) => {
  const {
    removeProductCart,
    updateAmountInProductCart,
    updatePaymentInProductCart,
    updatePaymentDaysInProductCart,
    productCart,
    dataCustomer,
    lastPaymentProduct,
    loadingMapProductsCartPrice,
  } = useForm();

  const { errors, values, touched, handleBlur } = useFormikContext<
    FormikInitialValuesNewRequest
  >();

  //NOTE: Pagamento a vista
  const paymentByTransfer = "0";

  const [amount, setAmount] = React.useState<string>("");
  const [paymentDay, setPaymentDay] = React.useState<string>(
    productCart?.[index].paymentDayOptions?.[1]?.value ??
      dataCustomer?.payCond?.toString() ??
      ""
  );

  const [clickedTransfer, setClickedTransfer] = React.useState<boolean>(false);

  const classes = useStyles();

  useEffect(() => {
    if (lastPaymentProduct) {
      setPaymentDay(lastPaymentProduct);

      if (lastPaymentProduct === paymentByTransfer) setClickedTransfer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataCustomer?.payCond.toString() === paymentByTransfer) {
      setClickedTransfer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (amount === "") {
      setAmount("1.000");
      if (productCard) {
        updateAmountInProductCart(productCard, "1.000");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  const handleOnChangeAmount = (event) => {
    setAmount(event.target.value);

    if (productCard) {
      updateAmountInProductCart(productCard, event.target.value);
    }
  };

  const handleOnChangePaymentDay = (index: string) => {
    const paymentDayIndex = Number(index);
    const paymentByTransfer = 0;

    if (!productCard) return;
    if (!productCard.paymentDayOptions) return;
    if (!productCard.id) return;

    if (paymentDayIndex === paymentByTransfer) {
      handleOnClickTransfer();
    } else {
      handleOnClickTicket();
    }

    const paymentDaySelected = productCard.paymentDayOptions[index];
    setPaymentDay(paymentDaySelected.value);
    updatePaymentDaysInProductCart(productCard, paymentDaySelected.key); // atualiza apenas os dia de pagamento
  };

  const handleOnClickRemoveProductCart = () => {
    if (productCard) {
      removeProductCart(productCard);
    }
  };

  const handleOnClickTransfer = async () => {
    setClickedTransfer(true); // pagamento a vista "T"

    // Atualiza forma de pagamento no carrinho junto com dia de pagamento
    // Ex: Metodo: Pagamento a vista, Dia: 0000 | Metodo: boleto, Dia: 0002

    if (productCard) {
      updatePaymentInProductCart(
        productCard,
        IPayment.TRANSFER,
        IPaymentDays.CASH
      ); // atualiza metodo de pagamento e dia

      if (productCard && productCard.paymentDayOptions) {
        setPaymentDay(productCard.paymentDayOptions[0].value); // atualiza o valor do input no componente textfieldselect
      }
    }
  };

  const calculateSubtotal = (amount, freight, productCard) => {
    const value = parseFloat(removeDots(amount));
    const tax = subtotalApplyTaxAccordingFreight(
      value,
      freight,
      productCard?.value || "0",
      productCard?.priceFreight || "0"
    );
    return `R$ ${toFixedParseString(tax, 2).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
    })}`;
  };

  const handleOnClickTicket = () => {
    setClickedTransfer(false); // pagamento a prazo "B"

    // Atualiza forma de pagamento no carrinho
    if (productCard && productCard.paymentDays) {
      updatePaymentInProductCart(
        productCard,
        IPayment.TICKET,
        productCard.paymentDays
      );

      if (productCard && productCard.paymentDayOptions) {
        const index = productCard.paymentDayOptions.length - 1; // Encontra ultimo elemento da lista
        setPaymentDay(productCard.paymentDayOptions[index].value); // atualiza o valor do input no componente textfieldselect
      }
    }
  };

  const Icon = () => {
    return (
      <Grid
        item
        xs={12}
        sx={{
          border: "0px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "3rem",
        }}
      >
        <FuelProductIcon
          style={{ fontSize: "inherit", border: "0px solid black" }}
        />
      </Grid>
    );
  };

  const LiterPrice = () => {
    return (
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: { xs: "1.2rem", lg: "1.4rem" },
          }}
        >
          {" R$ " +
            applyTaxAccordingFreight(
              values.freight,
              productCard?.value || "0",
              productCard?.priceFreight || "0"
            )
              .toFixed(4)
              .replace(".", ",")}
          <span
            style={{
              fontSize: "1rem",
              color: "rgba(0,0,0,0.5)",
              padding: "0px 8px",
            }}
          >
            {!productCard?.priceFreight ? "( sem valor de frete )" : ""}
          </span>
        </Typography>
      </Grid>
    );
  };

  const Subtotal = () => {
    return (
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: { xs: "1.2rem", lg: "1.4rem" },
          }}
        >
          <span style={{ color: "#0BB873" }}>
            {` ${calculateSubtotal(amount, values.freight, productCard)}`}
          </span>
          <span
            style={{
              fontSize: "1rem",
              color: "rgba(0,0,0,0.5)",
              padding: "0px 8px",
            }}
          >
            {!productCard?.priceFreight ? "( sem valor de frete )" : ""}
          </span>
        </Typography>
      </Grid>
    );
  };

  const MethodOfPayment = () => {
    return (
      <Grid
        container
        sx={{
          border: "0px solid black",
          justifyContent: "center",
        }}
      >
        <Grid item>
          <TextFieldCheckBox
            id="payment"
            name={"payment"}
            value={clickedTransfer ? paymentType.A_VISTA : paymentType.A_PRAZO}
            options={
              paymentDay === "0"
                ? [paymentType.A_VISTA]
                : [paymentType.A_VISTA, paymentType.A_PRAZO]
            }
            onChange={() => {
              clickedTransfer ? handleOnClickTicket() : handleOnClickTransfer();
            }}
          />
        </Grid>
      </Grid>
    );
  };

  const Amount = () => {
    return (
      <Grid
        item
        className="quantidade-item"
        xs={12}
        sx={{ border: "0px solid red" }}
      >
        <TextFieldSelect
          id="amount"
          name={"amount"}
          value={amount}
          placeholder="Selecione uma opção"
          optionslist={createOptionListAmount(10)}
          onChange={handleOnChangeAmount}
          required
        />
      </Grid>
    );
  };

  const Term = () => {
    return (
      <Grid item xs={12} sx={{ border: "0px solid blue" }}>
        <Grid container justifyContent={"right"}>
          <Grid item xs={12}>
            <TextFieldSelect
              id="payment"
              name={FormikName.PAYMENT}
              value={
                paymentDay === paymentByTransfer
                  ? paymentType.A_VISTA
                  : paymentDay
              }
              placeholder="Selecione uma opção"
              optionslist={productCard?.paymentDayOptions}
              onChange={(e) => handleOnChangePaymentDay(e.target.name)}
              onBlur={handleBlur}
              error={touched.payment && Boolean(errors.payment)}
              helperText={errors.payment}
              sx={{
                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                  height: "4.5rem",
                },
                label: {
                  top: "-0.3rem !important",
                },
              }}
              SelectProps={{
                renderValue: (value) => formatPaymentValue(value),
              }}
              required
            />
          </Grid>{" "}
        </Grid>
      </Grid>
    );
  };

  const ButtonRemove = () => {
    return (
      <Grid item xs={12}>
        <Button
          onClick={() => handleOnClickRemoveProductCart()}
          variant="text"
          sx={{
            color: "#D91F05",
            fontWeight: 600,
            fontSize: 12,
            border: "0px solid blue",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "1.2rem",
                md: "1.3rem",
                lg: "1.3rem",
              },
              fontWeight: 600,
              textTransform: "capitalize",
            }}
          >
            Remover
          </Typography>
        </Button>
      </Grid>
    );
  };

  const dataTableBody: IProductTable = {
    icon: Icon(),
    code: productCard?.code || "",
    name: capitalizeFirstLetterFullString(productCard?.name || ""),
    value: LiterPrice(),
    subtotal: Subtotal(),
    payment: MethodOfPayment(),
    amount: Amount(),
    term: Term(),
    iconRemove: ButtonRemove(),
  };

  return (
    <>
      {loadingMapProductsCartPrice[index] ? (
        <TableRow className={classes.tableRowItems}>
          {dataTableHeaders.map((_, indexHeaders) => (
            <TableCell key={indexHeaders} className={classes.tableCell}>
              <Skeleton width={"110px"} height={"40px"} />
            </TableCell>
          ))}
        </TableRow>
      ) : (
        <TableBody>
          <TableRow className={classes.tableRowItems}>
            {dataTableHeaders.map((column, indexHeaders) => (
              <TableCell
                key={indexHeaders}
                align={column.align}
                className={classes.tableCell}
              >
                {dataTableBody[column.key as keyof typeof dataTableBody]}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      )}
    </>
  );
};

const ProductsCards: React.FC<ProductsCardsProps> = ({ products }) => {
  return (
    <Grid container className="ProductsCards" gap={4}>
      {products && <TableProducts products={products} />}
    </Grid>
  );
};

export default ProductsCards;
