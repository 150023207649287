import React from "react";

import {
  IconButton,
  MenuItem,
  SelectProps,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { ArrowDownIcon } from "@components/Icons";
import { transformTextNozzles } from "@utils/index";

interface SelectFieldCustomProps extends SelectProps {
  name: string;
  label: string;
  value?: string; // Gerenciar estado do valor selecionado pelo usuario -> selectedOption
  handleSetSelecOption: (e) => void;
  objects?: IObjectModalNozzle[];
}
export interface IObjectModalNozzle {
  key: string;
  value: string[];
}

const SelectField: React.FC<SelectFieldCustomProps> = ({ ...props }) => {
  const classes = useStyles();

  const [openMenu, setOpenMenu] = React.useState(false);

  const handleOptionChange = (event) => {
    props.handleSetSelecOption(event.nozzles);
  };

  const handleOnClickSelect = (event) => {
    setOpenMenu(!openMenu);
  };

  const handleOnClickSelected = (obj: IObjectModalNozzle) => (event) => {
    event.nozzles = obj;
    handleOptionChange(event);
  };

  const iconArrowDownCustom = () => (
    <IconButton
      style={{
        border: "0px solid red",
        display: "flex",
        marginRight: "1.5rem",
      }}
    >
      <ArrowDownIcon style={{ height: 14, width: 14 }}></ArrowDownIcon>
    </IconButton>
  );

  return (
    <TextField
      select
      label={props.label}
      value={props.value}
      onChange={handleOptionChange} // Controla as mudancas do field de acordo com a selecao feita pelo usuario
      onClick={handleOnClickSelect} // Necessario para funcionar com uso da InputProps -> Permite abrir o menu de selecao
      variant="outlined"
      SelectProps={{
        open: openMenu, //                  // Necessario para abrir o menu apos click no icone
        IconComponent: iconArrowDownCustom, // Substitui icone padrao do componente select
        MenuProps: { className: classes.menuBox },
      }}
      InputProps={{
        style: {
          borderRadius: 10,
        },
      }}
      fullWidth
    >
      {props.objects &&
        props.objects.length > 0 &&
        props.objects.map((object, index) => (
          <MenuItem
            key={object.key}
            value={object.value.toString()}
            style={{ border: "0px solid red" }}
            onClick={handleOnClickSelected(object)}
          >
            <Typography
              variant={"subtitle2"}
              className={classes.typographytitle}
            >
              {transformTextNozzles(object.value)}
            </Typography>
          </MenuItem>
        ))}
    </TextField>
  );
};

export default SelectField;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuBox: {
      "& .MuiPaper-root": {
        height: "auto",
        margin: "2rem 0px 0px 2rem !important",
      },
    },
    typographytitle: {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.primary,
      fontWeight: 500,
    },
    input: {},
  })
);
