import { Types } from "@ioc/types";
import * as IHttpService from "@modules/infra/http/models/IHttpService";
import { inject, injectable, named } from "inversify";
import { IGetSalesVolumeDTOHistory } from "../dto/IGetSalesVolumeDTOHistory";
import { IQuerySalesVolumeDTOHistory } from "../dto/IQuerySalesVolumeDTOHistory";
import { IGetSalesVolumeServiceHistory } from "../models/IGetSalesVolumeServiceHistory";

@injectable()
export class GetSalesVolumeServiceHistory
  implements IGetSalesVolumeServiceHistory {
  @inject(Types.IHttpService)
  @named("pump")
  private httpInstance!: IHttpService.IHttpService;

  public async execute(
    data: IQuerySalesVolumeDTOHistory
  ): Promise<IGetSalesVolumeDTOHistory[]> {
    try {
      const salesVolumeResponseHistory = await this.httpInstance.get<
        IGetSalesVolumeDTOHistory[]
      >(
        `/dash_volume_billing_price?${data.salesVolume}&document=${data.document}&groupBy[]=product&period=${data.period}&productIds=${data.productIds}&beginDate=${data.beginDate}&endDate=${data.endDate}&peakTimeHistory=${data.peakTimeHistory}`
      );

      return salesVolumeResponseHistory;
    } catch (err) {
      throw err;
    }
  }

  public setAuthorization(token: string): void {
    this.httpInstance.setAuthorization(token);
  }
}
