import { IQueryInvoicesDataDTO } from "@modules/invoices/dtos/IQueryInvoicesDataDTO";
import { IClient } from "@utils/interfaces";
import React, { createContext, useCallback, useContext, useState } from "react";

interface FormQueryInvoicesContext {
  loading: boolean;
  loadingQuery: boolean;
  dataCustomer: IClient | null;
  dataQuery: IQueryInvoicesDataDTO[];
  searchFirstTime: boolean;
  openModalDownload: boolean;
  invoicesSelectedToDownload: IInvoicesSelecteToDownload;
  checkAllNFE: boolean;
  changeIcon: boolean;
  invoicesSelected: string[];
  checkAfterDownload: string[];
  setLoading(loading: boolean): void;
  setLoadingQuery(loading: boolean): void;
  setDataCustomer(data: IClient): void;
  setDataQuery(data: IQueryInvoicesDataDTO[]): void;
  setSearchFirstTime(value: boolean): void;
  setOpenModalDownload(value: boolean): void;
  setInvoicesSelectedToDownload(data: IInvoicesSelecteToDownload): void;
  setCheckAllNFE(value: boolean): void;
  setChangeIcon(value: boolean): void;
  setInvoicesSelected(value: string[]): void;
  setCheckAfterDownload(value: string[]): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FormQueryInvoicesContext = createContext<FormQueryInvoicesContext>(
  {} as FormQueryInvoicesContext
);

interface IInvoicesSelecteToDownload {
  [propName: string]: boolean;
}

const FormQueryInvoicesProvider: React.FC = ({ children }) => {
  const [loading, setLoadingState] = useState(false);
  const [loadingQuery, setLoadingQueryState] = useState(false);
  const [dataCustomer, setDataCustomer] = useState<IClient | null>(null);
  const [dataQuery, setDataQuery] = useState<IQueryInvoicesDataDTO[]>([]);
  const [searchFirstTime, setSearchFirstTime] = useState(true);
  const [openModalDownload, setOpenModalDownload] = useState(false);
  const [checkAllNFE, setCheckAllNFE] = useState<boolean>(false);
  const [changeIcon, setChangeIcon] = useState<boolean>(false);
  const [invoicesSelected, setInvoicesSelected] = useState<string[]>([]);
  const [checkAfterDownload, setCheckAfterDownload] = useState<string[]>([]);

  const [invoicesSelectedToDownload, setInvoicesSelectedToDownload] = useState<
    IInvoicesSelecteToDownload
  >({});

  const setLoading = useCallback((loading: boolean) => {
    setLoadingState(loading);
  }, []);

  const setLoadingQuery = useCallback((loading: boolean) => {
    setLoadingQueryState(loading);
  }, []);

  return (
    <FormQueryInvoicesContext.Provider
      value={{
        loading,
        dataCustomer,
        loadingQuery,
        dataQuery,
        searchFirstTime,
        openModalDownload,
        invoicesSelectedToDownload,
        checkAllNFE,
        changeIcon,
        invoicesSelected,
        checkAfterDownload,
        setLoading,
        setLoadingQuery,
        setDataCustomer,
        setDataQuery,
        setSearchFirstTime,
        setOpenModalDownload,
        setInvoicesSelectedToDownload,
        setCheckAllNFE,
        setChangeIcon,
        setInvoicesSelected,
        setCheckAfterDownload,
      }}
    >
      {children}
    </FormQueryInvoicesContext.Provider>
  );
};

const useFormQueryInvoices = (): FormQueryInvoicesContext => {
  const context = useContext(FormQueryInvoicesContext);
  if (!Object.values(context).length) {
    throw new Error(
      "useFormQueryInvoices deve ser utilizado dentro de um FormQueryInvoicesContext"
    );
  }
  return context;
};

export { useFormQueryInvoices, FormQueryInvoicesProvider };
