import Layout from "@components/Layout";
import { Box, useMediaQuery, useTheme, Typography } from "@material-ui/core";
import React from "react";
import { FormProvider } from "./FormContext";
import FormSimulateOrder from "./FormSimulation";
import { createStyles, makeStyles } from "@material-ui/styles";
import TemporaryIndexFormSimulateOrder from "./FormSimulation/TemporaryIndexFormSimulateOrder";
import { useFlags } from "flagsmith/react";

export const reasons = [
  "1 - Carregamento Oliveira Energia ",
  "2 - Carregamento CIF - Posto",
  "3 - Carregamento CIF - Usina",
  "4 - Carregamento FOB",
  "5 - Abastecimento Consumo Interno - Geradores",
  "6 - Abastecimento Consumo Interno - Motor de combate a incêndio",
  "7 - Abastecimento - Outros",
  "8 - Complemento de carregamento CIF",
  "9 - Complemento de carregamento FOB",
  "10 - Complemento de descarga",
  "11 - Erro Ticket - Sem retorno do painel",
  "12 - Erro Ticket - Lado da plataforma de carregamento",
  "13 - Outros",
];

const useStyles = makeStyles((theme) =>
  createStyles({
    formTitle: {
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: 700,
      lineHeight: "46px",
      marginBottom: "2rem",
    },
  })
);

export const NewOrderPage: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const flag_simulate_loading = useFlags([
    "user_sidebar_change_ui_simulate_loading",
  ]);

  return (
    <Layout disableFooter>
      <Box
        sx={{
          margin: isMobile ? 0 : "0 7rem",
          width: isMobile ? "100%" : "",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <Typography
          variant={isMobile ? "subtitle1" : "h5"}
          align="left"
          className={classes.formTitle}
        >
          Simular carregamento
        </Typography>

        <FormProvider>
          {flag_simulate_loading.user_sidebar_change_ui_simulate_loading
            .enabled ? (
            <FormSimulateOrder />
          ) : (
            <TemporaryIndexFormSimulateOrder />
          )}
        </FormProvider>
      </Box>
    </Layout>
  );
};
