import { useTheme } from "@material-ui/core";
import { Grid, Hidden } from "@mui/material";
import React from "react";

import { useRoadLoad } from "@pages/User/RoadLoad/context/RoadLoadContext";
import {
  IRoadLoadAllNormalized,
  IRoadLoadType,
  IRoadRowCount,
} from "@pages/User/RoadLoad/model";
import {
  CardNextLoads,
  CardNotLoads,
} from "@pages/User/RoadLoad/resource/card";
import { CardLoadsSkeleton } from "@pages/User/RoadLoad/resource/card/CardLoadsSkeleton";
import { CardLoadRoads } from "@pages/User/RoadLoad/resource/card/CardRoadLoads";
import {
  TypographyCaption,
  TypographySubtitle,
} from "@pages/User/RoadLoad/resource/typography";

import CircularProgress from "@components/CircularProgress";
import {
  BlockFinancialIcon,
  CheckIcon,
  ClockIcon,
  RoadLoadRedIcon,
} from "@components/Icons";
import { useAuth } from "@context/auth/AuthContext";

import { formatMonthYear } from "@utils/index";

interface HomeRoadLoadProps {
  roadCount: IRoadRowCount;
  road: IRoadLoadAllNormalized;
  handleShowAllRoadLoad: (tabSelected: number) => void;
}

const HomeRoadLoad: React.FC<HomeRoadLoadProps> = (props) => {
  const { road, roadCount, handleShowAllRoadLoad } = props;

  const theme = useTheme();
  const user = useAuth();

  const { loading } = useRoadLoad();

  const roadLoadCards: IRoadLoadType[] = [
    {
      icon: <ClockIcon />,
      type: "Não agendados",
      subtitle: "Aguardando agendamento",
      quantity: roadCount.waitingRow ?? 0, // Carregamento nao programado
      loading: loading?.waiting ?? false,
    },
    {
      icon: <RoadLoadRedIcon />,
      type: "Agendados",
      subtitle: "Prontos para carregar",
      quantity: roadCount.scheduledRow ?? 0, // Carregamento Agendado
      loading: loading?.scheduled ?? false,
    },
    {
      icon: <CheckIcon />,
      type: "Concluídos",
      subtitle: "Carregamentos faturados",
      quantity: roadCount.finishedRow ?? 0,
      loading: loading?.finished ?? false,
    },
    {
      icon: <BlockFinancialIcon />,
      type: "Bloqueados",
      subtitle: "Não liberados",
      quantity: roadCount.blockedRow ?? 0, // Carregamento Bloqueado
      loading: loading?.blocked ?? false,
    },
  ];

  return (
    <Grid
      container
      className="HomeRoadLoad"
      sx={{
        gap: { xs: 2, md: 2, lg: 4, xl: 4 },
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          lineHeight: "normal",
          display: "inline-grid",
          gap: { xs: 1, lg: 2 },
        }}
      >
        <TypographySubtitle>
          Olá, <b style={{ color: theme.palette.primary.main }}>{user?.name}</b>
        </TypographySubtitle>
        <TypographyCaption>Confira os seus carregamentos</TypographyCaption>
      </Grid>

      <Grid item xs={12}>
        <Grid container sx={{ gap: 2 }}>
          <Grid item xs={12}>
            <TypographySubtitle>Carregamentos rodoviários</TypographySubtitle>
            <CardLoadRoads
              roadLoads={roadLoadCards}
              onClick={(tab) => handleShowAllRoadLoad(tab)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Hidden mdDown>
        <Grid item xs={12}>
          <Grid container sx={{ gap: { xs: 3, md: 1, xl: 2 } }}>
            <Grid item xs={12} sm={11} md={10} lg={5.5} xl={5.5}>
              <Grid container sx={{ gap: { xs: 1, lg: 3 } }}>
                <Grid item xs={12}>
                  <TypographySubtitle>
                    Próximos carregamentos (
                    {loading?.scheduled ? (
                      <CircularProgress />
                    ) : (
                      roadCount?.scheduledRow
                    )}
                    )
                  </TypographySubtitle>
                  <TypographyCaption>
                    {formatMonthYear(new Date())}
                  </TypographyCaption>
                </Grid>
                <Grid item xs={12}>
                  {loading?.scheduled ? (
                    <CardLoadsSkeleton numberOfSkeletons={3} />
                  ) : (
                    <CardNextLoads
                      nextLoads={road?.upcomingScheduled}
                      onClick={() => handleShowAllRoadLoad(1)}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={11} lg={6} className={"item-notload"}>
              <Grid
                container
                className={"item-container-notload"}
                sx={{ gap: { xs: 1, lg: 3 }, pl: { xs: 0, lg: 2 } }}
              >
                <Grid item xs={12}>
                  <TypographySubtitle>
                    Não agendados (
                    {loading?.waiting ? (
                      <CircularProgress />
                    ) : (
                      roadCount?.waitingRow
                    )}
                    )
                  </TypographySubtitle>
                  <TypographyCaption>Clique para agendar</TypographyCaption>
                </Grid>
                <Grid item xs={12}>
                  {loading?.waiting ? (
                    <CardLoadsSkeleton numberOfSkeletons={2} />
                  ) : (
                    <CardNotLoads
                      notLoads={road.waitingNormalized ?? []}
                      onClick={() => handleShowAllRoadLoad(0)}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default HomeRoadLoad;
