import CardTitleAndIcon from "@components/CardTitleAndIcon";
import {
  BlockedHomeIcon,
  GasolinaHomeIcon,
  NozzleCircleIcon,
  PaperHomeIcon,
  PaperTwoHomeIcon,
  SongHomeIcon,
  TelefoneHomeIcon,
  UserHomeIcon,
} from "@components/Icons";
import ROUTES from "@config/routes";
import { useUserDispatch } from "@context/UserContext";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { SIDEBAR_ADMIN_FLAGS } from "@utils/featureFlags";
import { useFlags } from "flagsmith/react";
import React, { useEffect, useState } from "react";

const CardsSection = () => {
  const theme = useTheme();
  const userDispatch = useUserDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));
  const isLaptopMini = useMediaQuery(theme.breakpoints.only("md"));

  const cardData = [
    {
      title: "Criação de Usuários",
      subTitle: "Crie novos usuários para o portal do revendedor",
      icon: UserHomeIcon,
      link: ROUTES.ADMIN_ROUTES.NEW_USER,
      flag: "admin_sidebar_create_new_user",
    },
    {
      title: "Lista de usuários",
      subTitle: "Veja a lista de usuários atuais do portal do revendedor",
      icon: PaperHomeIcon,
      link: ROUTES.ADMIN_ROUTES.LIST_USERS,
      flag: "admin_sidebar_list_user",
    },
    {
      title: "Consultar comprovantes",
      subTitle: "Confira os comprovantes de pagamento dos seus clientes",
      icon: PaperTwoHomeIcon,
      link: ROUTES.ADMIN_ROUTES.LIST_INVOICES_IMAGES,
      flag: "admin_sidebar_consult_check_receipts",
    },
    {
      title: "Bloqueio de produtos",
      subTitle: "Gerencie o acesso aos produtos atem",
      icon: BlockedHomeIcon,
      link: ROUTES.ADMIN_ROUTES.PRODUCT_BLOCK,
      flag: "admin_sidebar_block_products",
    },
    {
      title: "Enviar comunicados",
      subTitle: "Mantenhas seus clientes informados",
      icon: SongHomeIcon,
      link: ROUTES.ADMIN_ROUTES.ALL_COMMUNICATION,
      flag: "admin_sidebar_hide_send_communique",
    },
    {
      title: "Registros de atendimentos",
      subTitle: "Veja o que seus clientes pensam sobre o serviço",
      icon: TelefoneHomeIcon,
      link: ROUTES.ADMIN_ROUTES.RECLAMATION_ANALYSIS,
      flag: "admin_sidebar_contact_us",
    },

    {
      title: "Validar anexo de bico e produto",
      subTitle: "Tenha acesso ao portal do seu cliente",
      icon: NozzleCircleIcon,
      link: ROUTES.ADMIN_ROUTES.NOZZLE,
      flag: "admin_sidebar_nozzle",
    },
    {
      title: "Ir para o portal do revendedor",
      subTitle: "Tenha acesso ao portal do seu cliente",
      icon: GasolinaHomeIcon,
      link: ROUTES.USER_ROUTES.HOME,
      onClick: () => {
        userDispatch({ type: "TOGGLE_ADMIN" });
      },
      flag: "admin_sidebar_redirect_modo_user",
    },
  ];

  const [cardAdminList, setCardAdminList] = useState(cardData);
  const flags = useFlags(SIDEBAR_ADMIN_FLAGS);

  useEffect(() => {
    const cardsAdminWithFlag = cardAdminList.filter(
      (menuEntry) => flags[menuEntry.flag].enabled
    );

    setCardAdminList(cardsAdminWithFlag);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      container
      style={{
        paddingBottom: "30px",
        marginRight: isMobile
          ? "10px"
          : isTablet
          ? "5px"
          : isLaptopMini
          ? "28px"
          : "50px",
      }}
    >
      {cardAdminList.map((data, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <CardTitleAndIcon {...data} />
        </Grid>
      ))}
    </Grid>
  );
};

export default CardsSection;
