import React from "react";
import { Box, Fade, Grid, IconButton, Modal } from "@mui/material";

import {
  TypographySubtitle,
  TypographyTitle,
} from "@pages/User/RoadLoad/resource/typography";
import ModalSchedule from "@pages/User/RoadLoad/resource/modal/ModalSchedule";
import { CardNextLoads } from "@pages/User/RoadLoad/resource/card";
import { IScheduledLoad } from "@pages/User/RoadLoad/model";

import { CloseIcon, ScheduleIcon } from "@components/Icons";
import Button from "@components/Button";

interface ModalLoadScheduledProps {
  index?: number;
  children?: React.ReactNode;
  nextLoad?: IScheduledLoad;
  isModal?: boolean; // variavel util para saber se o modal é chamado dentro de outro modal
  isTable?: boolean;
}

const ModalLoadScheduled: React.FC<ModalLoadScheduledProps> = (props) => {
  const { children, nextLoad, isModal, isTable } = props;

  const [modalOpen, setModalOpen] = React.useState(false);

  const handleOpen = () => (!isModal ? setModalOpen(true) : null);
  const handleClose = () => setModalOpen(false);

  return (
    <div
      className="ModalLoadScheduled-div"
      style={{ width: "inherit", display: "flex" }}
    >
      {isTable ? (
        <Button
          variant="text"
          onClick={handleOpen}
          endIcon={
            <ScheduleIcon
              sx={{
                width: { xs: "18px !important", lg: "22px !important" },
              }}
            />
          }
          sx={{ px: 0.8, fontSize: { xs: 12, lg: 14, xl: 16 } }}
        >
          {children}
        </Button>
      ) : (
        <div
          // onClick={handleOpen} //Note: necessarios ajustes nesse modal
          style={{
            padding: 0,
            margin: 0,
            cursor: !isModal ? "pointer" : "default",
            display: "contents",
          }}
        >
          {children}
        </div>
      )}

      <Modal
        className="ModalLoadScheduled-modal"
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen} onExited={handleClose}>
          <Box
            // className={classes.root}
            sx={{
              width: { xs: 300, sm: 460, md: 865, lg: 890, xl: 1200 },
              minHeight: { xs: "auto" },
            }}
          >
            <Grid
              container
              sx={{
                gap: { xs: 0.8, lg: 1.2, xl: 2 },
                p: { xs: 0.8, lg: 1.5 },
                height: "fit-content",
              }}
            >
              <Grid item xs={12} sx={{ border: "0px solid black" }}>
                <Grid container sx={{ justifyContent: "right" }}>
                  <Grid item>
                    <IconButton
                      sx={{
                        border: { xs: "0px solid black", lg: "none" },
                        zIndex: 2,
                        height: "fit-content",
                        py: { xs: 1, md: 0 },
                      }}
                      onClick={handleClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                className={"CardNextLoad-Title-item"}
                sx={{
                  border: "0px solid red",
                  display: "flex",
                  justifyContent: "end",
                  p: { xs: 0, lg: 1 },
                }}
              >
                <Grid
                  container
                  sx={{
                    p: 0,
                    justifyContent: "center",
                    textAlign: "center",
                    gap: 2,
                  }}
                >
                  <Grid item>
                    <TypographyTitle
                      sx={{
                        pt: { xs: 1 },
                        fontSize: { xs: 21, lg: 18, xl: 22 },
                      }}
                    >
                      Carregamento rodoviário agendado
                    </TypographyTitle>
                  </Grid>
                  <Grid item xs={12}>
                    <TypographySubtitle
                      sx={{ fontSize: { xl: 18 }, color: "gray" }}
                    >
                      {nextLoad?.Ref
                        ? `Numero de Agendamento: ${nextLoad.Ref}`
                        : ""}
                    </TypographySubtitle>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ border: "0px solid purple", py: 1 }}>
                <Grid
                  container
                  className={"CardNextLoad-body-item"}
                  sx={{
                    border: "0px solid black",
                    py: 1,
                    pl: { xs: 0, lg: 1 },
                    pr: { xs: 1, lg: 1 },
                    gap: 2,
                  }}
                >
                  <Grid item xs={12} sx={{ border: "0px solid purple" }}>
                    <CardNextLoads
                      sx={{ gap: 0 }}
                      nextLoads={nextLoad ? [nextLoad] : []}
                      hiddenButon={true}
                      isModal={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      sx={{ justifyContent: "end", gap: { xs: 2, lg: 4 } }}
                    >
                      <Grid item>
                        <ModalSchedule
                          key={props.index}
                          roadLoadScheduled={nextLoad}
                          hiddenIcon={true}
                        >
                          Reagendar
                        </ModalSchedule>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" onClick={handleClose}>
                          Fechar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalLoadScheduled;
