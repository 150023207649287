import React from "react";
import { Skeleton, Grid, Hidden } from "@mui/material";

const CardLoadsSkeleton = ({
  numberOfSkeletons = 3,
  numberOfSkeletonCol = 5,
}) => {
  const renderDesktopSkeleton = () => {
    return (
      <Grid
        container
        className="CardLoadsSkeleton-desktop"
        sx={{
          gap: 4,
          justifyContent: "center",
        }}
      >
        {Array.from({ length: numberOfSkeletons }, (_, indexRow) => {
          return (
            <Grid item xs={11.8} key={indexRow}>
              <Grid
                container
                sx={{
                  border: "0px solid red",
                  justifyContent: "space-around",
                  background: "#fff",
                  p: 2,
                }}
              >
                {Array.from({ length: numberOfSkeletonCol }, (_, indexCol) => {
                  return (
                    <Grid item key={indexCol}>
                      <Skeleton
                        variant="rectangular"
                        width={100}
                        height={25}
                        sx={{ border: "0px solid red" }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const renderMobileSkeleton = () => {
    return (
      <Grid
        container
        className="CardLoadsSkeleton-mobile"
        sx={{
          gap: 2,
          p: 2,
          justifyContent: "center",
        }}
      >
        {Array.from({ length: numberOfSkeletons }, (_, indexRow) => (
          <Grid item xs={12} key={indexRow}>
            <Grid
              container
              sx={{
                border: "0px solid red",
                justifyContent: "center",
                background: "#fff",
                p: 2,
              }}
            >
              <Grid item xs={12} sx={{ textAlign: "center", mb: 1 }}>
                <Skeleton variant="rectangular" width={40} height={40} />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center", mb: 1 }}>
                <Skeleton variant="rectangular" width={150} height={20} />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Skeleton variant="rectangular" width={200} height={20} />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      <Hidden mdUp>{renderMobileSkeleton()}</Hidden>
      <Hidden mdDown>{renderDesktopSkeleton()}</Hidden>
    </>
  );
};

export { CardLoadsSkeleton };
