import React, { useState } from "react";
import Switch, { SwitchProps } from "@material-ui/core/Switch";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core";

interface CustomSwitchProps extends SwitchProps {
  customColor: string;
  label?: string;
  labelPosition?: "left" | "right";
  handle?: (checked: boolean) => void;
}

const createCustomSwitchStyles = (customColor: string) =>
  withStyles((theme: Theme) =>
    createStyles({
      switchBase: {
        color: "white",
        "&$checked": {
          color: customColor,
        },
        "&$checked + $track": {
          backgroundColor: customColor,
        },
      },
      checked: {},
      track: {},
    })
  )(Switch);

const CustomSwitch: React.FC<CustomSwitchProps> = ({
  customColor,
  label,
  labelPosition = "right",
  handle,
  ...props
}) => {
  const theme = useTheme();
  const CustomColoredSwitch = createCustomSwitchStyles(customColor);
  const [checked, setChecked] = useState(props.checked || false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (handle) {
      handle(event.target.checked);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {label && labelPosition === "left" && (
        <label
          style={{
            marginRight: 8,
            color: theme.palette.text.primary,
            fontFamily: "Montserrat",
            fontSize: 16,
            fontWeight: 400,
          }}
        >
          {label}
        </label>
      )}
      <CustomColoredSwitch
        {...props}
        checked={checked}
        onChange={handleChange}
      />
      {label && labelPosition === "right" && (
        <label
          style={{
            marginLeft: 8,
            color: theme.palette.text.primary,
            fontFamily: "Montserrat",
            fontSize: 16,
            fontWeight: 400,
          }}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default CustomSwitch;
