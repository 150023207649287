import Select, { SelectVariant } from "@components/Select";
import { Grid, useMediaQuery } from "@material-ui/core";
import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { usePerformanceManagement } from "@context/PerformanceManagementContext";
import { OptionsSelectPerformanceTable } from "../Model/OptionsSelectPerformanceTable";

const SelectToFilterTablePerformance: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const {
    salesPerformanceSelected,
    setSalesPerformanceSelected,
  } = usePerformanceManagement();

  const handleChangeSalesPerformance = (selectedOption) => {
    setSalesPerformanceSelected(selectedOption);
  };

  return (
    <>
      <Grid
        style={{
          display: isMobile ? "block" : "flex",
          width: "40%",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Select
          variant={SelectVariant.STANDARD}
          value={salesPerformanceSelected}
          options={OptionsSelectPerformanceTable}
          isDisabled={false}
          getOptionItemLabel={(item) => `${item.label}`}
          onChange={handleChangeSalesPerformance}
          getSelectedOptionLabel={(item) => `${item.label}`}
          emptyOptionsText="Lista vazia"
        />
      </Grid>
    </>
  );
};

export default SelectToFilterTablePerformance;
