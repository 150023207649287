import React, { ReactNode } from "react";
import { Box, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

interface ICardProjectionSales {
  icon: ReactNode;
  title: ReactNode;
  subtitle: ReactNode;
}

interface IGetData {
  icon: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
  title: ReactNode;
  subtitle: ReactNode;
  description?: ReactNode;
  percent?: string;
}
interface IGetTableData {
  data: IGetData[];
}

export const CardProjectionSales: React.FC<ICardProjectionSales> = ({
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <Box display="flex">
      <Box className={classes.iconContainer}>{props.icon}</Box>
      <Box display="block" className={classes.textContainer}>
        <Typography className={classes.title}>{props.title}</Typography>
        <Typography
          className={classes.subtitle}
          variant={isMobile ? "subtitle1" : "h5"}
        >
          {props.subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

export const TableProjectionSales = (props: IGetTableData) => {
  const dados = props.data;
  return (
    <Box display="block">
      {dados.length &&
        dados.map((value, index) => (
          <Box style={{ width: "100%", display: "flex" }} key={index}>
            <CardProjectionSales
              icon={value.icon}
              title={value.title}
              subtitle={value.subtitle}
            />
          </Box>
        ))}
    </Box>
  );
};

export default TableProjectionSales;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: 0,
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      display: "flex",
      alignItems: "center",
      color: "#3E3D3D",
    },
    subtitle: {
      padding: 0,
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
      color: "#3E3D3D",
    },
    iconContainer: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      width: 60,
      paddingTop: 12,
    },
    textContainer: {
      justifyContent: "center",
      paddingTop: 20,
      paddingBottom: 14,
      alignItems: "center",
      display: "block",
    },
  })
);
