import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  CircularProgress,
  createStyles,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";
import TodayIcon from "@material-ui/icons/Today";
import { DatePicker } from "@material-ui/pickers";
import { IGetClientInfoService } from "@modules/orders/models/IGetClientInfoService";
import { ITimeLimitLocationService } from "@modules/orders/models/ITimeLimitLocationService";
import clsx from "clsx";
import { format, parse } from "date-fns";
import { useFormikContext } from "formik";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import baseRetiradaImg from "../../../../assets/base-de-retirada.png";
import cepImg from "../../../../assets/cep.png";
import filialImg from "../../../../assets/filial.png";
import razaoSocialImg from "../../../../assets/razao-social.png";
import { useUserState } from "../../../../context/UserContext";
import { maskCNPJ } from "../../../../utils";
import { IFormRequestValues } from "../../../../utils/interfaces";
import { useForm } from "../FormContext";
import {
  addToLocalStorageCNPJ,
  getCNPJinLocalStorage,
} from "@utils/localStorageUtils";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontWeight: "bold",
      marginBottom: "2rem",
    },
    container: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    formContainer: {},
    itemSelect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",

      "& :nth-child(1)": {
        fontSize: "1.6rem",
        fontWeight: "bold",
      },
      "& :nth-child(2)": {
        fontSize: "1.2rem",
      },
    },
    iconsDisabled: {
      opacity: "0.12",
    },
    iconNotDisabled: {
      opacity: "0.6",
    },
  })
);

const PurchaseData: React.FC = () => {
  const classes = useStyles();
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<IFormRequestValues>();
  const { enqueueSnackbar } = useSnackbar();
  const userState = useUserState();
  const formContext = useForm();
  const history = useHistory();
  const iocContext = useIoCContext();
  const [disableDates, setDisableDates] = useState<{
    [propName: string]: boolean;
  }>({});
  const [loadingDisableDates, setLoadingDisableDates] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const timeLimitLocationService = iocContext.serviceContainer.get<
    ITimeLimitLocationService
  >(Types.Orders.ITimeLimitLocationService);

  const SELECTED_CNPJ_STORAGE = getCNPJinLocalStorage("cnpjSelected");

  const [selectedCNPJ, setSelectedCNPJ] = useState("");

  useEffect(() => {
    setSelectedCNPJ(SELECTED_CNPJ_STORAGE ?? "");
  }, [SELECTED_CNPJ_STORAGE]);

  const getDates = useCallback(
    (date?: Date) => {
      async function fetch() {
        try {
          setLoadingDisableDates(true);
          if (values.withdrawBasisID) {
            const tz = Number(
              formContext.dataCustomer?.withdrawBasis
                .find(
                  (withdrawBasis) =>
                    withdrawBasis.shippingPoint === values.withdrawBasisID
                )
                ?.timeZone.split("UTC")[1]
            );

            const dates = await timeLimitLocationService.execute({
              freighType: values.freightType,
              timeZone: tz,
            });

            const getFirstDateAvailable = Object.keys(dates).find(
              (key) => dates[key] === false
            );

            if (getFirstDateAvailable) {
              setFieldValue(
                "deliveryDate",
                parse(getFirstDateAvailable, "dd/MM/yyyy", new Date())
              );
            }
            setDisableDates(dates);
          }
        } catch (error) {
          enqueueSnackbar(
            "Ocorreu um erro ao sincronizar horario do servidor, recarregando página ...",
            {
              variant: "error",
            }
          );
          setTimeout(() => {
            history.go(0);
          }, 3000);
        } finally {
          setLoadingDisableDates(false);
        }
      }
      fetch();
    },
    [
      enqueueSnackbar,
      formContext.dataCustomer,
      history,
      setFieldValue,
      timeLimitLocationService,
      values.freightType,
      values.withdrawBasisID,
    ]
  );

  useEffect(() => {
    getDates();
  }, [getDates, timeLimitLocationService, values.freightType]);

  useEffect(() => {
    if (selectedCNPJ) {
      setFieldValue("CNPJ", selectedCNPJ);
      addToLocalStorageCNPJ("cnpjSelected", selectedCNPJ);
    }
  }, [selectedCNPJ, setFieldValue]);

  const renderAddress = () => {
    if (!formContext.dataCustomer) return null;
    return formContext.dataCustomer.address.map((ele) => {
      return (
        <MenuItem key={ele.id} value={ele.id} className={classes.itemSelect}>
          {ele.street} - {ele.city} - {ele.UF}
        </MenuItem>
      );
    });
  };

  const renderCPNJ = () => {
    return userState.listCNPJ.map((ele) => {
      return (
        <MenuItem
          key={ele?.CNPJ}
          value={ele?.CNPJ}
          className={classes.itemSelect}
        >
          <Typography>{ele.companyName}</Typography>
          <Typography>{maskCNPJ(ele?.CNPJ)}</Typography>
        </MenuItem>
      );
    });
  };

  const renderWithdrawBasis = () => {
    if (!formContext.dataCustomer) return null;
    const withdrawBasis = formContext.dataCustomer.withdrawBasis.find((ele) => {
      return ele.filialID === values.address?.filialIdDefault;
    });
    const branchesWithdrawBasis = formContext.dataCustomer.withdrawBasis.filter(
      (ele) => {
        return withdrawBasis?.branch === ele.branch;
      }
    );

    return branchesWithdrawBasis.map((ele) => {
      return (
        <MenuItem
          key={ele.shippingPoint}
          value={ele.shippingPoint}
          className={classes.itemSelect}
        >
          {ele.name}
        </MenuItem>
      );
    });
  };

  useEffect(() => {
    async function fetch() {
      if (!values?.CNPJ) return;
      try {
        formContext.setLoading(true);

        const getClientInfoService = iocContext.serviceContainer.get<
          IGetClientInfoService
        >(Types.Orders.IGetClientInfoService);

        const CNPJData = await getClientInfoService.execute(values?.CNPJ);
        formContext.setDataCustomer(CNPJData);
        setFieldValue("freightType", CNPJData.freightType);
      } catch (error) {
        enqueueSnackbar(
          "Ocorreu um erro ao baixar dados do CNPJ selecionado, recarregando página ...",
          {
            variant: "error",
          }
        );
        setTimeout(() => {
          history.go(0);
        }, 3000);
      } finally {
        formContext.setLoading(false);
      }
    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.CNPJ]);

  useEffect(() => {
    if (values?.CNPJ) {
      if (formContext.dataCustomer?.address.length === 1) {
        setFieldValue("address", formContext.dataCustomer.address[0]);
      }

      const withdrawBasis = formContext.dataCustomer?.withdrawBasis.find(
        (withdrawBasis) => withdrawBasis.shippingPoint.includes("D1")
      );
      setFieldValue("withdrawBasisID", withdrawBasis?.shippingPoint as string);
      setFieldValue("filialID", withdrawBasis?.filialID);
    } else {
      setFieldValue("address", null);
    }
  }, [values?.CNPJ, setFieldValue, formContext.dataCustomer]);

  return (
    <div className={classes.container}>
      <Typography
        className={classes.title}
        variant={isMobile ? "subtitle1" : "h6"}
      >
        Dados da Compra
      </Typography>
      <Grid container className={classes.formContainer} spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Razão social</InputLabel>
            <Select
              error={!!errors?.CNPJ && !!touched?.CNPJ}
              onBlur={() => setFieldTouched("CNPJ", true)}
              value={values?.CNPJ}
              onChange={({ target }) => {
                setFieldValue("CNPJ", target.value);
                addToLocalStorageCNPJ("cnpjSelected", target.value);
              }}
              name="CNPJ"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              startAdornment={
                <InputAdornment position="start">
                  <img
                    width="24"
                    src={razaoSocialImg}
                    alt="razão social ícone"
                    className={classes.iconNotDisabled}
                  />
                </InputAdornment>
              }
            >
              {renderCPNJ()}
            </Select>
            <FormHelperText error={!!errors?.CNPJ && !!touched?.CNPJ}>
              {!!touched?.CNPJ && errors?.CNPJ}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={isMobile ? 12 : 8}>
          <FormControl
            fullWidth
            disabled={
              !Boolean(values?.CNPJ) ||
              formContext.loading ||
              !formContext.dataCustomer?.hiddePrice
            }
          >
            <InputLabel>Endereço para entrega</InputLabel>
            <Select
              error={!!errors.address && !!touched.address}
              onBlur={() => setFieldTouched("address", true)}
              onChange={({ target }) => {
                if (formContext.dataCustomer) {
                  const address = formContext.dataCustomer.address.find(
                    (ele) => ele.id === target.value
                  );
                  setFieldValue("address", address);
                  if (address?.filialIdDefault) {
                    setFieldValue("filialID", address.filialIdDefault);
                  }
                }
              }}
              value={values.address ? values.address.id : ""}
              name="address"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              startAdornment={
                <InputAdornment position="start">
                  <RoomIcon />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  {formContext.loading && (
                    <CircularProgress style={{ width: 30, height: 30 }} />
                  )}
                </InputAdornment>
              }
            >
              {values?.CNPJ && renderAddress()}
            </Select>
            <FormHelperText error={!!errors.address && !!touched.address}>
              {!!touched.address && errors.address}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <TextField
            fullWidth
            label="CEP"
            value={values.address ? values.address.postalCode : ""}
            disabled
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    width="24"
                    src={cepImg}
                    alt="cep ícone"
                    className={classes.iconsDisabled}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <TextField
            fullWidth
            label="Bairro"
            value={values.address ? values.address.district : ""}
            disabled
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <TextField
            fullWidth
            label="Cidade"
            value={values.address ? values.address.city : ""}
            disabled
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <TextField
            fullWidth
            label="Estado"
            value={values.address ? values.address.UF : ""}
            disabled
          />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <TextField
            fullWidth
            label="Assessor"
            value={
              values?.CNPJ
                ? formContext.dataCustomer
                  ? formContext.dataCustomer.accessor.name
                  : ""
                : ""
            }
            InputProps={{
              startAdornment: <AccountCircleIcon />,
            }}
            disabled
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <TextField
            fullWidth
            label="Telefone"
            value={
              values?.CNPJ ? (values.address ? values.address.phone : "") : ""
            }
            InputProps={{
              startAdornment: <PhoneIcon />,
            }}
            disabled
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <TextField
            fullWidth
            label="E-mail"
            value={
              values?.CNPJ ? (values.address ? values.address.email : "") : ""
            }
            InputProps={{
              startAdornment: <EmailIcon />,
            }}
            disabled
          />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <FormControl
            disabled={
              !Boolean(values?.CNPJ) ||
              formContext.loading ||
              !formContext.dataCustomer?.hiddePrice
            }
          >
            <FormLabel component="legend">Tipo de Frete</FormLabel>
            <RadioGroup
              aria-label="freightType"
              name="freightType"
              value={values.freightType}
              onChange={({ target }) =>
                setFieldValue("freightType", target.value)
              }
              row
            >
              <FormControlLabel
                value="CIF"
                control={<Radio color="primary" />}
                label="CIF"
              />
              <FormControlLabel
                value="FOB"
                control={<Radio color="primary" />}
                label="FOB"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 8}>
          <FormControl
            fullWidth
            disabled={
              !Boolean(values?.CNPJ) ||
              formContext.loading ||
              !formContext.dataCustomer?.hiddePrice
            }
          >
            <InputLabel>Base de retirada</InputLabel>
            <Select
              error={!!errors.withdrawBasisID && !!touched.withdrawBasisID}
              onBlur={() => setFieldTouched("withdrawBasisID", true)}
              value={values.withdrawBasisID}
              onChange={({ target }) => {
                setFieldValue("withdrawBasisID", target.value);
                const withdrawBasisID = target.value;
                const withdrawBasis = formContext.dataCustomer?.withdrawBasis.find(
                  (ele) => ele.shippingPoint === withdrawBasisID
                );
                setFieldValue("filialID", withdrawBasis?.filialID);
              }}
              name="withdrawBasisID"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              startAdornment={
                <InputAdornment position="start">
                  <img
                    width="24"
                    src={baseRetiradaImg}
                    alt="base de retirada ícone"
                    className={clsx({
                      [classes.iconNotDisabled]:
                        Boolean(values?.CNPJ) &&
                        !formContext.loading &&
                        !formContext.dataCustomer?.hiddePrice === false,
                      [classes.iconsDisabled]:
                        !Boolean(values?.CNPJ) ||
                        formContext.loading ||
                        !formContext.dataCustomer?.hiddePrice,
                    })}
                  />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  {formContext.loading && (
                    <CircularProgress style={{ width: 30, height: 30 }} />
                  )}
                </InputAdornment>
              }
            >
              {renderWithdrawBasis()}
            </Select>
            <FormHelperText
              error={!!errors.withdrawBasisID && !!touched.withdrawBasisID}
            >
              {!!touched.withdrawBasisID && errors.withdrawBasisID}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={isMobile ? 12 : 8}>
          <TextField
            fullWidth
            label="Filial"
            value={
              values?.CNPJ
                ? values.address
                  ? formContext.dataCustomer?.withdrawBasis.find(
                      (ele) => values.withdrawBasisID === ele.shippingPoint
                    )?.branchName
                  : ""
                : ""
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    width="24"
                    src={filialImg}
                    alt="filial ícone"
                    className={classes.iconsDisabled}
                  />
                </InputAdornment>
              ),
            }}
            disabled
          />
        </Grid>
        <Grid
          item
          xs={isMobile ? 12 : 4}
          container
          justify="center"
          alignItems="center"
        >
          {loadingDisableDates ? (
            <CircularProgress
              color="secondary"
              style={{ height: "2.5rem", width: "2.5rem" }}
            />
          ) : (
            <DatePicker
              value={values.deliveryDate}
              onChange={(date) => {
                setFieldTouched("deliveryDate", true);
                setFieldValue("deliveryDate", date);
              }}
              shouldDisableDate={(day) => {
                if (day) {
                  return disableDates[format(day, "dd/MM/yyyy")];
                }
                return false;
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <TodayIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoOk
              format="dd/MM/yyyy"
              label="Data de entrega"
              error={!!errors.deliveryDate && !!touched.deliveryDate}
              helperText={!!touched.deliveryDate && errors.deliveryDate}
              onBlur={() => setFieldTouched("deliveryDate", true)}
              disabled={
                !formContext.dataCustomer?.hiddePrice && loadingDisableDates
              }
              onMonthChange={(date) => {
                if (date) {
                  return new Promise((resolve) => {
                    async function fetch() {
                      try {
                        if (values.withdrawBasisID) {
                          const tz = Number(
                            formContext.dataCustomer?.withdrawBasis
                              .find(
                                (withdrawBasis) =>
                                  withdrawBasis.shippingPoint ===
                                  values.withdrawBasisID
                              )
                              ?.timeZone.split("UTC")[1]
                          );

                          const dates = await timeLimitLocationService.execute({
                            date: date as Date,
                            freighType: values.freightType,
                            timeZone: tz,
                          });

                          setDisableDates(dates);
                        }
                      } catch (error) {
                        enqueueSnackbar(
                          "Ocorreu um erro ao sincronizar horario do servidor, recarregando página ...",
                          {
                            variant: "error",
                          }
                        );
                        setTimeout(() => {
                          history.go(0);
                        }, 3000);
                      } finally {
                        resolve();
                      }
                    }
                    fetch();
                  });
                }
              }}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography
            align="center"
            className={classes.title}
            variant={isMobile ? "subtitle1" : "h6"}
          >
            Observações
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={values.comments}
            onChange={({ target }) => setFieldValue("comments", target.value)}
            multiline
            variant="outlined"
            rows={4}
            disabled={!formContext.dataCustomer?.hiddePrice}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PurchaseData;
