type Variant = "error" | "warning" | "success";

class AppError {
  public readonly message: string;

  public readonly variant: Variant;

  public readonly status: string | undefined;

  constructor(message: string, variant: Variant, status?: string) {
    this.message = message;
    this.variant = variant;
    this.status = status;
  }
}

export default AppError;
