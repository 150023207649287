import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import React, { useState } from "react";

import DrawerCustom from "@components/DrawerCustom";
import ModalInactiveEmployee from "@components/Employee/modal";
import PaginationCustom from "@components/Pagination";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SkeletonTableInvoicesImages from "@pages/Admin/ListInvoicesImages/SkeletonTableListInvoicesImages";
import FormCreateEmployee from "@pages/User/Employee/resource/FormCreateEmployee";
import { formatDateWithSlash, formatHours, isValidIsoDate } from "@utils/index";

export interface Column<T> {
  title: string;
  field: keyof T;
  align: "center" | "left" | "right";
  width?: string;
  isExpandable?: boolean;
  renderExpandedComponent?: (data: T) => React.ReactNode;
  isVisibleMobile?: boolean;
}

interface Props<T> {
  columns: Column<T>[];
  data: T[];
  rowsPerPage: number;
  labelButton?: string;
  hasDrawer?: boolean;
  hasLoading?: boolean;
  hasDateWithHours?: boolean;
  hasDisabled?: boolean;
  icon?: JSX.Element;

  onClick?: (index: number) => void;
  onRowsPerPageChange?:
    | ((
        event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
      ) => void)
    | undefined;
}

const GenericPaginatedTable = <T extends {}>({ ...props }: Props<T>) => {
  const classes = useStyles();

  const theme = useTheme();
  const isFullHD = useMediaQuery(theme.breakpoints.only("xl"));

  const [page, setPage] = useState(0);

  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());

  const toggleRow = (rowIndex: number) => {
    const newExpandedRows = new Set(expandedRows);
    if (expandedRows.has(rowIndex)) {
      newExpandedRows.delete(rowIndex);
    } else {
      newExpandedRows.add(rowIndex);
    }
    setExpandedRows(newExpandedRows);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleOnChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage - 1);
  };

  function getPropertyValue<T, K extends keyof T>(obj: T, key: K): string {
    return (obj[key] as unknown) as string;
  }

  const handleOnClick = (e) => {
    return props.onClick && props.onClick(e.key);
  };

  return (
    <TableContainer component={Paper} className={classes.container}>
      {props.hasLoading ? (
        <SkeletonTableInvoicesImages cols={8} rows={10} />
      ) : (
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {props.columns.map((column, indexTHead) => (
                <STableCell
                  key={column.title}
                  className={classes.tableCellHead}
                  align={props.columns[indexTHead].align}
                  width={props.columns[indexTHead].width}
                  style={{ fontSize: isFullHD ? "1.6rem" : "1.2rem" }}
                >
                  <Grid container style={{ border: "0px solid black" }}>
                    <Grid item xs={12} style={{ border: "0px solid blue" }}>
                      <Typography
                        variant={"caption"}
                        style={{ fontWeight: 600 }}
                      >
                        {column.title}
                      </Typography>
                    </Grid>
                  </Grid>
                </STableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data
              .slice(
                page * props.rowsPerPage,
                page * props.rowsPerPage + props.rowsPerPage
              )
              .map((row, indexRow) => (
                <>
                  <TableRow key={indexRow}>
                    {props.columns.map((column, indexTBody) => (
                      <STableCell
                        key={column.title}
                        className={classes.tableCellBody}
                        style={{ fontSize: isFullHD ? "1.6rem" : "1.2rem" }}
                        align={props.columns[indexTBody].align}
                      >
                        {column.isExpandable ? (
                          <IconButton onClick={() => toggleRow(indexRow)}>
                            {expandedRows.has(indexRow) ? (
                              <ExpandLessIcon color="primary" />
                            ) : (
                              <ExpandMoreIcon color="primary" />
                            )}
                          </IconButton>
                        ) : indexTBody === 0 && props.icon ? (
                          <Grid container style={{ border: "0px solid black" }}>
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                border: "0px solid blue",
                              }}
                            >
                              <Box>{props.icon}</Box>
                              <Typography variant="caption">
                                {row[column.field]}
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : (
                          <>
                            {props.hasDateWithHours &&
                            isValidIsoDate(
                              getPropertyValue(
                                row,
                                props.columns[indexTBody].field
                              )
                            ) ? (
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography variant="caption">
                                    {formatDateWithSlash(
                                      `${row[column.field]}`
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="caption"
                                    className={classes.typography}
                                  >
                                    {formatHours(`${row[column.field]}`)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid
                                container
                                style={{ border: "0px solid purple" }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    lineHeight: "2.5rem",
                                    width: "fit-content",
                                    border: "0px solid red",
                                  }}
                                >
                                  <Typography variant="caption">
                                    {row[column.field]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                          </>
                        )}

                        {indexTBody === props.columns.length - 1 &&
                        props.labelButton ? (
                          <>
                            {props.hasDrawer ? (
                              <>
                                <DrawerCustom
                                  disabled={
                                    getPropertyValue(
                                      row,
                                      props.columns[1].field
                                    ) === "-"
                                      ? true
                                      : false
                                  }
                                  label={props.labelButton}
                                  anchortype="right"
                                >
                                  <FormCreateEmployee
                                    identifid={getPropertyValue(
                                      row,
                                      props.columns[1].field
                                    )}
                                  />
                                </DrawerCustom>
                              </>
                            ) : (
                              <ModalInactiveEmployee
                                key={indexTBody}
                                indexClicked={indexRow}
                                disabled={
                                  getPropertyValue(
                                    row,
                                    props.columns[0].field
                                  ) === "-"
                                    ? true
                                    : false ||
                                      (props?.hasDisabled
                                        ? props.hasDisabled
                                        : false)
                                }
                                onClick={(e) => handleOnClick(e)}
                                label={props.labelButton}
                              />
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </STableCell>
                    ))}
                  </TableRow>
                  {props.columns.some((col) => col.isExpandable) &&
                    expandedRows.has(indexRow) && (
                      <TableRow>
                        <STableCell colSpan={props.columns.length}>
                          {props.columns
                            .find((col) => col.isExpandable)
                            ?.renderExpandedComponent?.(row)}
                        </STableCell>
                      </TableRow>
                    )}
                </>
              ))}
          </TableBody>
        </Table>
      )}

      {!props.hasLoading && (
        <PaginationCustom
          count={props.data.length} //////// Quantidade total de registros
          page={page} ////////////////////// Informa qual pagina o usuario esta no momento
          rowsPerPage={props.rowsPerPage} // Informa quantas linhas deve ser exibido por pagina
          onChangeHandle={handleOnChange} // Controla qual pagina o usuario esta dentro do componte pagination
          onPageChange={handleChangePage} // Controla qual pagina o usuario - obrigatorio para o componente TablePagination
        />
      )}
    </TableContainer>
  );
};

export default GenericPaginatedTable;

const STableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#FFFFFF",
      fontSize: "1.3rem",
      borderBottom: "none",
      //padding: 0,
      "&:first-child": {
        // Aplica arredondamento nas bordas apenas na primeira celula de cada linha
        paddingLeft: "2.5rem",
        borderBottomLeftRadius: "0.6rem",
        borderTopLeftRadius: "0.6rem",
      },
      "&:last-child": {
        // Aplica arredondamento nas bordas apenas na ultima celula de cada linha
        borderBottomRightRadius: "0.6rem",
        borderTopRightRadius: "0.6rem",
      },
    },
    head: {
      fontWeight: 700,
    },
    body: {
      fontWeight: 400,
    },
  })
)((props: TableCellProps) => <TableCell {...props} />);

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: "100%",
      overflowX: "hidden", // esconde scroll horizontal
      boxShadow: "none",
      backgroundColor: theme.palette.background.default,
    },
    table: {
      borderSpacing: "0px 1rem", // Aplica espacamento entre as linhas
      borderCollapse: "separate", // Aplica espacamento entre as linhas
    },
    tableHead: {},
    tableRow: {
      backgroundColor: "#FFFFFF", // Aplica background Branco em cada Celula
    },
    tableCellHead: {
      padding: "1rem 1.5rem 1rem 1.5rem",
    },
    tableCellBody: {
      padding: "1.8rem 1.4rem 1.8rem 1.4rem", // !important
      lineHeight: 0,
    },
    buttonTable: {
      color: theme.palette.primary.main,
      textTransform: "initial",
      fontWeight: 700,
      paddingRight: "1rem",
      padding: 0,
      margin: 0,
      lineHeight: 0,
    },
    typography: {
      lineHeight: "1.4rem",
    },
  })
);
