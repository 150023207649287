import { inject, named, injectable } from "inversify";
import { Types } from "@ioc/types";
import * as IHttpService from "@modules/infra/http/models/IHttpService";
import { ISendProjectionSalesService } from "../models/ISendProjectionSalesService";
import { ISendProjectionSalesDTO } from "../dtos/ISendProjectionSalesDTO";

@injectable()
export class SendProjectionSalesService implements ISendProjectionSalesService {
  @inject(Types.IHttpService)
  @named("pump")
  private httpInstance!: IHttpService.IHttpService;

  public async execute(data: ISendProjectionSalesDTO) {
    const sendData = await this.httpInstance.post<ISendProjectionSalesDTO>(
      "/goal",
      data
    );
    return sendData;
  }

  public setAuthorization(token: string): void {
    this.httpInstance.setAuthorization(token);
  }
}
