import React, { useState } from "react";
import { Grid, Hidden, IconButton } from "@mui/material";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import { IRoadLoadType } from "@pages/User/RoadLoad/model";
import CircularProgress from "@components/CircularProgress";

import {
  CardTitleTypography,
  CardBodyTypography,
} from "@pages/User/RoadLoad/resource/typography";

interface CardLoadRoadsProps {
  roadLoads: IRoadLoadType[];
  onClick: (tabSelected: number) => void;
}

const CardLoadRoads: React.FC<CardLoadRoadsProps> = (props) => {
  const { roadLoads, onClick } = props;

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const applyBoxShadow = (index: number) => {
    return hoveredIndex === index ? "0px 3px 6px rgba(0, 0, 0, 0.5)" : "none";
  };

  const renderIconArrow = () => {
    return (
      <IconButton
        sx={{
          p: 0,
          "@keyframes moveRight": {
            "0%": { transform: "translateX(0)" },
            "50%": { transform: "translateX(10px)" },
            "100%": { transform: "translateX(0)" },
          },
          animation: "moveRight 1.5s linear infinite",
        }}
      >
        <ArrowRightAltIcon
          sx={{
            transition: "transform 0.3s ease",
          }}
          fontSize="large"
        />
      </IconButton>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          container
          className="CardLoadRoad-container-main"
          id="cardLoadContainer"
          sx={{
            // border: "1px solid black",
            width: { xs: "fit-content", md: "inherit" }, //Note: necessario para evitar overflow
            display: { xs: "flex", lg: "flex" },
            flexWrap: { xs: "wrap", md: "nowrap" },
            overflowX: "auto",
            gap: { xs: 4, lg: 2.5 },
            justifyContent: { lg: "flex-start" },
            alignItems: "center",
          }}
        >
          {roadLoads.map((item, index) => {
            return (
              <Grid
                className="CardLoadRoad-item-map"
                item
                key={index}
                xs={12}
                md={5.9}
                lg={3.2}
                onClick={() => onClick(index)}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                sx={{
                  // border: "1px solid purple",
                  width: { xs: "inherit", md: "min-content" },
                  py: { xs: 1.2 },
                  cursor: "pointer",
                  borderRadius: 1.5,
                  backgroundColor: "#fff",
                  boxShadow: applyBoxShadow(index),
                  transition: "box-shadow 0.3s ease", // Adiciona transição suave para a sombra
                }}
              >
                <Grid
                  container
                  className="CardLoadRoad-container"
                  sx={{
                    // border: "1px solid blue",
                    display: "flex",
                    flexWrap: "nowrap",
                    p: { xs: 0.9, lg: 1.2, xl: 2.5 },
                    gap: { xs: 2, lg: 1.4 },
                    alignItems: "center",
                    maxHeight: 89,
                    width: { xs: "auto", lg: "fit-content" },
                    whiteSpace: "nowrap",
                    justifyContent: { xs: "start", lg: "flex-start" },
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                    }}
                  >
                    {item.icon}
                  </Grid>
                  <Grid item>
                    <CardTitleTypography>
                      {item.loading ? <CircularProgress /> : item?.quantity}{" "}
                      {item.type}
                    </CardTitleTypography>
                    <CardBodyTypography>{item.subtitle}</CardBodyTypography>
                  </Grid>
                  <Hidden smUp>
                    <Grid
                      item
                      xs={6}
                      sx={{ textAlign: "right", paddingRight: 1.4 }}
                    >
                      {renderIconArrow()}
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export { CardLoadRoads };
