import React from "react";

import {
  Modal,
  Backdrop,
  makeStyles,
  Fade,
  Grid,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { CloseIcon, WarnIcon } from "@components/Icons";

interface Props {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  key: number;
  indexClicked: number;
  disabled: boolean;
  label: string;
}

const ModalInactiveEmployee: React.FC<Props> = ({ children, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isFullHD = useMediaQuery(theme.breakpoints.only("xl"));

  const [openModal, setOpenModal] = React.useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpeModal = () => {
    setOpenModal(true);
  };

  const handleOnClickTurnOffEmployee = (event) => {
    event.key = props.indexClicked;
    return props.onClick && props.onClick(event);
  };

  return (
    <>
      <Button
        key={props.key}
        className={classes.button}
        onClick={handleOpeModal}
        style={{ fontSize: isFullHD ? "1.6rem" : "1.2rem", padding: "0px" }}
        disabled={props.disabled}
      >
        <Typography
          variant={"body2"}
          style={{
            fontWeight: 600,
            color: props.disabled
              ? theme.palette.primary.light
              : theme.palette.primary.main,
            opacity: props.disabled ? "0.5" : "1",
          }}
        >
          {props.label}
        </Typography>
      </Button>
      <Modal
        className={classes.modal}
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Grid
            container
            spacing={4}
            className={classes.container}
            style={{ textAlign: "center" }}
          >
            <Grid item xs={12} sm={11} md={11} lg={11} xl={11}>
              <Grid container spacing={4}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    border: "0px solid blue",
                    paddingBottom: 28,
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    style={{
                      justifyContent: "end",
                      width: "fit-content",
                      border: "0px solid red",
                    }}
                  >
                    <CloseIcon className={classes.closeIcon} />
                  </Button>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ border: "0px solid blue", paddingTop: 0 }}
                >
                  <WarnIcon
                    style={{ fontSize: "10rem", border: "0px solid red" }}
                  ></WarnIcon>
                </Grid>

                <Grid item xs={12} style={{ padding: "8px 32px 8px 32px" }}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 700, marginBottom: 4 }}
                  >
                    Inativar funcionário?
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{
                      fontWeight: 500,
                      color: theme.palette.primary.highlightedplus,
                      opacity: 0.6,
                    }}
                  >
                    Você tem certeza de que deseja inativar funcionário?
                  </Typography>
                </Grid>

                <Grid item xs={12} style={{ paddingBottom: 42 }}>
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                      <Button
                        onClick={handleCloseModal}
                        style={{
                          backgroundColor: theme.palette.primary.main,
                          padding: "8px 32px 8px 32px",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          style={{
                            fontWeight: 700,
                            color: theme.palette.text.hint,
                            textTransform: "capitalize",
                          }}
                        >
                          Não
                        </Typography>
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        onClick={handleOnClickTurnOffEmployee}
                        style={{
                          backgroundColor: theme.palette.background.paper,
                          padding: "8px 32px 8px 32px",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          style={{
                            fontWeight: 700,
                            color: theme.palette.primary.main,
                            textTransform: "capitalize",
                          }}
                        >
                          Inativar
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalInactiveEmployee;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    color: theme.palette.text.primary,
    background: "#fff",
    border: "2px solid #fff",
    borderRadius: 16,
    justifyContent: "center",
    alignContent: "baseline",
    [theme.breakpoints.up("xs")]: {
      width: "90%",
      padding: "8px 0px 8px 0px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80%",
      padding: "16px 0px 16px 0px",
    },
    [theme.breakpoints.up("md")]: {
      width: "80%",
      padding: "16px 0px 16px 0px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "30%",
      padding: "24px 0px 24px 0px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "60%",
      padding: "32px 0px 32px 0px",
    },
  },
  button: {
    //   backgroundColor: theme.palette.primary.main,
    color: theme.palette.primaryLight.main,
    fontWeight: 700,
    textTransform: "initial",
    paddingRight: "1rem",
    //   padding: 0,
    margin: 0,
    lineHeight: 0,
    padding: "0.6rem 3rem 0.6rem 3rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
    },
  },
  closeIcon: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "1rem !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.3rem !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.7rem !important",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.5rem !important",
    },
  },
}));
