import { CloseIcon, CommissionIcon, LiterIcon } from "@components/Icons";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { formatToLiter, formatToReal } from "@utils/index";
import React from "react";

interface Props {
  open: boolean;
  handleOnClose: () => void;
  dataCommission: any;
}
const ModalTableCommissions: React.FC<Props> = ({ ...props }) => {
  const classes = useStyles();
  const [, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    props.handleOnClose();
  };

  return (
    <>
      <Modal
        className={classes.modal}
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Grid container spacing={4} className={classes.container}>
            <Grid
              item
              xs={12}
              sm={11}
              md={11}
              lg={11}
              xl={11}
              style={{ paddingTop: 0, paddingBottom: 24 }}
            >
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  border: "0px solid blue",
                }}
              >
                <Button
                  onClick={props.handleOnClose}
                  style={{
                    justifyContent: "end",
                    width: "fit-content",
                    border: "0px solid red",
                    paddingTop: 14,
                  }}
                >
                  <CloseIcon className={classes.closeIcon} />
                </Button>
              </Grid>

              <Box style={{ textAlign: "center" }}>
                <Typography className={classes.typographyTitle}>
                  <span style={{ fontWeight: "bold" }}>
                    {props.dataCommission?.name
                      ? props.dataCommission?.name
                      : "Frentista não cadastrado"}
                  </span>
                </Typography>
              </Box>
            </Grid>

            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid item style={{ marginRight: "15px" }}>
                <Grid>
                  <CommissionIcon></CommissionIcon>
                </Grid>
                <Grid style={{ width: "20px", height: "20px" }}></Grid>
                <Grid>
                  <LiterIcon></LiterIcon>
                </Grid>
              </Grid>
              <Grid item>
                <Grid>
                  <Typography className={classes.commissionTotal}>
                    {props.dataCommission?.commission_total
                      ? "R$ " +
                        formatToReal(props.dataCommission?.commission_total)
                      : "-"}
                  </Typography>
                  <Typography className={classes.descriptionCard}>
                    Comissão total
                  </Typography>
                </Grid>

                <Grid style={{ marginTop: "10px" }}>
                  <Typography className={classes.volumeTotal}>
                    {props.dataCommission?.quantity_total
                      ? "R$ " +
                        formatToReal(props.dataCommission?.quantity_total)
                      : "-"}
                  </Typography>
                  <Typography className={classes.descriptionCard}>
                    Volume Vendido Total
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* LINHA PONTILHADA */}
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Grid
                item
                xs={1}
                style={{
                  width: "100%",
                  height: "2px",
                  padding: "2px",
                  borderTop: "1px solid #D2D3D6",
                  alignContent: "center",
                  display: "flex",
                  alignItems: "center",
                  marginRight: "5px",
                }}
              ></Grid>
              <Grid item>
                <Typography
                  style={{
                    fontSize: "1.1rem",
                    fontFamily: "DM Sans",
                    color: "rgba(0, 0, 0, 0.57);",
                    textAlign: "center",
                  }}
                >
                  Valores detalhados por produto*
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  width: "100%",
                  height: "2px",
                  padding: "2px",
                  borderTop: "1px solid #D2D3D6",
                  alignContent: "center",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "5px",
                }}
              ></Grid>
            </Grid>

            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <Table>
                <TableHead>
                  <TableCell
                    className={classes.tableHeadCommission}
                    style={{
                      padding: "8px 0px",
                    }}
                  >
                    Produto
                  </TableCell>
                  <TableCell
                    className={classes.tableHeadCommission}
                    style={{
                      padding: "8px 10px",
                    }}
                  >
                    Volume (L)
                  </TableCell>
                  <TableCell
                    className={classes.tableHeadCommission}
                    style={{
                      padding: "8px 10px",
                    }}
                  >
                    Comissão (R$)
                  </TableCell>
                </TableHead>

                <TableBody>
                  {props.dataCommission.products_and_comissions.map(
                    (ele, index) => {
                      return (
                        <TableRow>
                          <TableCell
                            className={classes.lineOfTable}
                            style={{
                              padding: "16px 0px",
                              fontWeight: 500,
                              fontSize: "1.1rem",
                            }}
                          >
                            {ele.description ? ele.description : "-"}
                          </TableCell>
                          <TableCell
                            className={classes.lineOfTable}
                            style={{
                              padding: "16px 10px",
                            }}
                          >
                            {ele.quantity ? formatToLiter(ele.quantity) : "-"}
                          </TableCell>
                          <TableCell
                            className={classes.lineOfTable}
                            style={{
                              padding: "16px 10px",
                            }}
                          >
                            {ele.commission
                              ? formatToReal(ele.commission)
                              : "-"}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalTableCommissions;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "DM Sans",
  },
  commissionTotal: {
    fontSize: "2.0rem",
    fontWeight: "bold",
    color: "#0BB873",
    fontFamily: "DM Sans",
  },

  volumeTotal: {
    fontSize: "2.0rem",
    fontWeight: "bold",
    color: "#3d3d3d",
    fontFamily: "DM Sans",
  },
  descriptionCard: {
    fontSize: "1.4rem",
    fontWeight: 500,
    color: "#3d3d3d",
    fontFamily: "DM Sans",
  },
  tableHeadCommission: {
    color: "#3d3d3d",
    fontFamily: "DM Sans",
    fontSize: "1.1rem",
    fontWeight: 700,
    border: "none",
    whiteSpace: "nowrap",
  },
  lineOfTable: {
    color: "#3d3d3d",
    fontFamily: "DM Sans",
    fontSize: "1.2rem",
    border: "none",
  },
  container: {
    color: theme.palette.text.primary,
    background: "#fff",
    border: "2px solid #fff",
    borderRadius: 16,
    justifyContent: "center",
    alignContent: "baseline",
    fontFamily: "DM Sans",
    [theme.breakpoints.up("xs")]: {
      width: "90%",
      padding: "8px 0px 8px 0px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80%",
      padding: "16px 0px 16px 0px",
    },
    [theme.breakpoints.up("md")]: {
      width: "80%",
      padding: "16px 0px 16px 0px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "70%",
      padding: "24px 0px 24px 0px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "60%",
      padding: "32px 0px 32px 0px",
    },
  },
  typographyTitle: {
    fontFamily: "DM Sans",
    marginBottom: "0.3rem",
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.7rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "2.6rem",
    },
  },
  bodyGridItem: {
    fontFamily: "DM Sans",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("xs")]: {
      padding: "7px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "5px !important",
    },
    [theme.breakpoints.up("md")]: {
      padding: "6px !important",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "9px !important",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "10px !important",
    },
  },
  typographyHead: {
    fontFamily: "DM Sans",
    fontWeight: 700,
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.6rem",
    },
  },
  typographyBody: {
    fontFamily: "DM Sans",
    fontWeight: 500,
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.6rem",
    },
  },
  bodyGridItemButton: {
    padding: "1rem",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primaryLight.main,
    fontWeight: 700,
    padding: "0.6rem 3rem 0.6rem 3rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
    },
  },
  closeIcon: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "1rem !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.3rem !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.7rem !important",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.7rem !important",
    },
  },
}));
