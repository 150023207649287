import * as Yup from "yup";

const ValidationFilterReclamation = Yup.object().shape({
  date_range: Yup.array()
    .of(Yup.date().typeError("Selecione uma data válida"))
    .test(
      "start-date-before-end-date",
      "A data final não pode ser anterior à inicial",
      function (value) {
        if (!value || value.length !== 2) {
          return false; // Invalid array length
        }
        const [startDate, endDate] = value;
        return !startDate || !endDate || startDate <= endDate;
      }
    )
    .required("Selecione a data de início e a data final"),
});

export default ValidationFilterReclamation;
