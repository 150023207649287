import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Layout from "@components/Layout";
import { Typography, Container, useMediaQuery } from "@material-ui/core";
import { PerformanceManagementProvider } from "@context/PerformanceManagementContext";
import SalesVolumeChart from "./Sections/SalesVolumeChart";
import SalesProjection from "./Sections/SalesProjection";
import SalesPerformance from "./Sections/SalesPerformance";
import YourSalesDash from "./Sections/YourSalesDash";

const PerformanceManagement: React.FC = () => {
  const classes = useStylesPerformanceManagement();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Layout disableFooter>
      <Container>
        <Typography className={classes.title} variant={isMobile ? "h6" : "h5"}>
          Gestão de desempenho
        </Typography>
        <YourSalesDash />
        <SalesVolumeChart />
        <Grid item xs={12} className={isMobile ? classes.block : classes.flex}>
          <Grid item xs={12} md={6}>
            <SalesProjection />
          </Grid>
          <Grid item xs={12} className={classes.alignRight}>
            <SalesPerformance />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

const ContainerPerformanceManagement: React.FC = () => {
  return (
    <PerformanceManagementProvider>
      <PerformanceManagement />
    </PerformanceManagementProvider>
  );
};

export default ContainerPerformanceManagement;

export const useStylesPerformanceManagement = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      border: "none",
      paddingLeft: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    flex: {
      display: "flex",
    },
    block: {
      display: "block",
    },
    containerSection: {
      width: "98%",
      paddingTop: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      overflow: "hidden",
      marginBottom: "2.6rem",
    },
    alignRight: { display: "flex", justifyContent: "flex-end" },
    title: {
      color: theme.palette.text.primary,
      fontFamily: "Montserrat",
      fontWeight: 700,
      lineHeight: "46px",
    },
    titleSection: {
      color: theme.palette.text.primary,
      fontFamily: "DM Sans",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "46px",
      paddingTop: "1.8rem",
    },
    subtitleSection: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "20px",
      color: theme.palette.text.primary,
      opacity: 0.7,
    },
    description: {
      color: theme.palette.text.primary,
      fontFamily: "DM Sans",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "20px",
    },
    emptyGoal: {
      color: "#3E3D3D",
      opacity: 0.3,
    },
    titleModal: {
      color: "#3E3D3D",
      fontSize: "2.2rem",
      fontFamily: "Montserrat",
      fontWeight: "bold",
      textAlign: "center",
      paddingTop: "30px",
    },
    subTitleModal: {
      color: "#3E3D3D",
      fontSize: "1.6rem",
      opacity: "0.6",
      fontFamily: "Montserrat",
      textAlign: "center",
      margin: "20px 16px",
    },
    buttonReturnHome: {
      textTransform: "none",
      background: "transparent",
      fontFamily: "Montserrat",
      color: "#D91F05",
      boxShadow: "none",
      fontWeight: "bold",
      whiteSpace: "nowrap",
      padding: "10px 30px",

      "&:hover": {
        background: "#FFF",
        opacity: "0.8",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
      },
    },

    buttonCancel: {
      textTransform: "none",
      background: "#D91F05",
      color: "#FFF",
      boxShadow: "none",
      fontFamily: "Montserrat",
      borderRadius: "2px",
      fontWeight: "bold",
      whiteSpace: "nowrap",
      padding: "10px 40px",
      "&:hover": {
        opacity: "0.7",
        background: "#D91F05",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
      },
    },
    closeButtonTop: {
      backgroudn: "transparent",
      paddingTop: "20px",
      paddingBottom: "20px",
      borderRadius: "30px",
    },
    buttonLinkChart: {
      fontFamily: "Montserrat, sans-serif",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      color: "#D91F05",
      "&:disabled": {
        color: "#F4A199",
      },
      marginBottom: "0px",
      whiteSpace: "nowrap",
      marginRight: "10px",
      textDecoration: "none",
      textTransform: "none",
    },
    iconArrowButton: {
      fontSize: 8,
      marginRight: 3,
      marginLeft: 3,
      marginTop: 1,
      color: "#3E3D3D",
      fontWeight: "bold",
    },
    textfield: {
      width: "100%",
      fontFamily: "Montserrat",
      "& label": {
        // Controle de estilo antes de digitar
        color: "#3E3D3D",
        fontWeight: 400,
        fontSize: "1.6rem",
        borderRadius: "8px",
        opacity: "0.6",
      },
      // "& input.Mui-focused": { border: "2px solid blue" }, // Controle de estilo durante digitacao
      "& .MuiInputLabel-shrink": {
        // Controle de estilo apos campo digitado
        fontSize: "1.6rem",
        fontWeight: 700,
        color: "#3E3D3D",
        opacity: 1,
        backgroundColor: "#fff",
        paddingRight: 6,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        opacity: "0.6",
      },
    },
  })
);
