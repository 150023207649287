import ButtonGeneric from "@components/ButtonGeneric/ButtonGeneric";
import { CloseIconRadiusIcon } from "@components/Icons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";

interface ModalGeneralProps {
  open: boolean;
  closeModal: () => void;
  titleHeaderModal?: string;
  title?: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  marginLeftSubmit?: string;
  resetPadding?: boolean;
  width?: string;
  height?: string;
  labelButtonCancel?: string;
  labelButtonSubmit?: string;
  onClickSubmit?: () => void;
  hasDialogActions?: boolean;
  isDisabledSubmitted?: boolean;
  isLoading?: boolean;
}

const ModalGeneral: React.FC<ModalGeneralProps> = ({
  open,
  icon,
  closeModal,
  titleHeaderModal,
  title,
  children,
  marginLeftSubmit = "10px",
  resetPadding,
  width,
  height,
  labelButtonCancel = "Cancelar",
  labelButtonSubmit = "Enviar",
  isDisabledSubmitted = false,
  onClickSubmit,
  hasDialogActions = false,
  isLoading,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Dialog
      open={open}
      maxWidth={!width ? "md" : undefined}
      PaperProps={{
        style: {
          borderRadius: "8px",
          padding: resetPadding
            ? "20px 10px"
            : isMobile
            ? "18px 10px"
            : "30px 40px",
          width: width,
          height: height,
        },
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          container
          justify="flex-end"
          alignItems="center"
          style={{ padding: "0px" }}
        >
          <Button
            onClick={() => closeModal()}
            className={classes.closeButtonTop}
            style={{ padding: isMobile ? "0px" : "20px" }}
          >
            <CloseIconRadiusIcon style={{ width: "14px", height: "14px" }} />
          </Button>
        </Grid>
      </Grid>

      <DialogContent style={{ padding: "0px" }}>
        <Grid container>
          {icon && (
            <Grid
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {icon}
            </Grid>
          )}

          <Grid
            xs={12}
            style={{
              display: "flex",
              justifyContent: isMobile ? "left" : "center",
            }}
          >
            {titleHeaderModal && (
              <Typography
                className={classes.titleHeaderModal}
                style={{
                  fontSize: isMobile ? "1.3rem" : "2.2rem",
                  marginTop: isMobile ? "-18px" : "-45px",
                  marginLeft: isMobile ? "10px" : "0px",
                }}
              >
                {titleHeaderModal}
              </Typography>
            )}

            <Typography
              className={classes.titleModal}
              style={{ paddingTop: icon ? "30px" : "10px" }}
            >
              {title}
            </Typography>
          </Grid>

          {children && (
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} style={{ width: "302px" }}>
                {children}
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      {hasDialogActions && (
        <DialogActions style={{ justifyContent: "center" }}>
          <Grid container justify="center" alignItems="center">
            <Grid item md={4} xs={4}>
              <ButtonGeneric
                typeButton="outlined"
                onClick={() => closeModal()}
                text={(labelButtonCancel = "Cancelar")}
              />
            </Grid>

            <Grid item style={{ width: "8px" }} />
            <Grid item md={4} xs={4}>
              <ButtonGeneric
                typeButton="submit"
                onClick={() => (onClickSubmit ? onClickSubmit() : {})}
                text={isLoading ? "Carregando " : labelButtonSubmit}
                disabled={isDisabledSubmitted || isLoading}
              />
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
};
const useStyles = makeStyles((theme) =>
  createStyles({
    titleHeaderModal: {
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontWeight: "bold",
      textAlign: "center",
      position: "absolute",
    },
    titleModal: {
      color: "#3E3D3D",
      fontSize: "2.2rem",
      fontFamily: "Montserrat",
      fontWeight: "bold",
      textAlign: "center",
      [theme.breakpoints.only("xs")]: {
        fontSize: "1.7rem",
      },
    },
    closeButtonTop: {
      backgroudn: "transparent",
      paddingTop: "20px",
      paddingBottom: "20px",
      borderRadius: "30px",
    },
  })
);
export { ModalGeneral };
