import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
} from "@material-ui/core";
import React from "react";

import { CloseIconRadiusIcon } from "@components/Icons";
import { useStylesPerformanceManagement } from "..";
import { usePerformanceManagement } from "@context/PerformanceManagementContext";
import {
  capitalizeFirstLetterFullString,
  maskCNPJ,
  numberFormatter,
} from "@utils/index";
import { Form, Formik } from "formik";
import FormQueryValidationSchemaProjection from "../ValidationYup/FormQueryValidationSchemaProjection";
import FormQueryProjection from "./FormQueryProjection";
import { useHomeUserContext } from "@context/HomeUserContext";
import TittleAndSubTitle from "@components/TittleAndSubTitle/TitleAndSubTitle";

interface Props {
  open: boolean;
  closeModal: () => void;
}

const ModalSalesPerformance: React.FC<Props> = ({ open, closeModal }) => {
  const classes = useStylesPerformanceManagement();
  const { documentList } = useHomeUserContext();

  const nameCenter = capitalizeFirstLetterFullString(
    documentList.filter((item) => item.isSelected)[0].label ?? ""
  );
  const cnpjCenter = maskCNPJ(
    documentList.filter((item) => item.isSelected)[0].cnpj ?? ""
  );

  const {
    projectionSales,
    handleSubmitSalesProjection,
  } = usePerformanceManagement();

  let goal = Number.parseFloat(`${projectionSales?.value}`);

  const initialValues = {
    value: projectionSales?.value ? numberFormatter(goal ?? 0) : "",
    document: documentList.filter((item) => item.isSelected)[0].cnpj,
  };

  return (
    <Dialog open={open} maxWidth="xs">
      <Grid
        style={{
          display: "flex",
          justifyContent: "flex-end",
          cursor: "pointer",
        }}
      >
        <Button onClick={() => closeModal()} className={classes.closeButtonTop}>
          <CloseIconRadiusIcon style={{ width: "14px", height: "14px" }} />
        </Button>
      </Grid>
      <DialogContent>
        <DialogTitle>
          <Box style={{ paddingBottom: 10 }}>
            <TittleAndSubTitle title={nameCenter} subTitle={cnpjCenter} />
          </Box>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmitSalesProjection}
            validationSchema={FormQueryValidationSchemaProjection}
          >
            <Form>
              <FormQueryProjection />
            </Form>
          </Formik>
        </DialogTitle>
      </DialogContent>
    </Dialog>
  );
};

export { ModalSalesPerformance };
