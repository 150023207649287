import React, { useEffect } from "react";
import { useFormikContext } from "formik";

import { CheckedIcon, TrashIcon } from "@components/Icons";
import {
  Box,
  Button,
  ButtonProps,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";

interface TextFieldUploadFileProps {
  helperText?: string | boolean;
  error?: boolean;
  children?: React.ReactNode;
  label: string;
  name: string;
  value: File | null;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e) => void;
}

const ButtonFile: React.FC<ButtonProps> = ({ children, onClick }) => {
  const theme = useTheme();

  return (
    <Button
      variant="contained"
      color="primary"
      component="span" // Obrigatorio -> se remover para de funcionar
      onClick={onClick}
      style={{
        backgroundColor: "currentcolor",
        textTransform: "initial",
        padding: "8px 32px 8px 32px",
        boxShadow: "none",
      }}
    >
      <Typography
        variant="subtitle2"
        style={{ color: theme.palette.primary.main, fontWeight: 600 }}
      >
        {children}
      </Typography>
    </Button>
  );
};

const TextFieldUploadFile: React.FC<TextFieldUploadFileProps> = ({
  children,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [fileName, setFileName] = React.useState<string | null>(null);
  const [fileUrl, setFileUrl] = React.useState<string | null>(null);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (props.value === null) {
      handleRemoveFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const handleOnChangeUpdateFile = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.currentTarget.files) {
      const selectedFile = event.currentTarget.files[0];

      setFieldValue(props.name, selectedFile);
      setFileName(selectedFile.name);

      const fileReader = new FileReader();
      fileReader.readAsDataURL(selectedFile);
      fileReader.onloadend = () => {
        setFileUrl(fileReader.result as string);
      };
    }
  };

  const handleRemoveFile = () => {
    setFieldValue(props.name, null);
    setFileName(null);
    setFileUrl(null);
  };

  const ErrorMessage: React.FC = () => {
    return (
      <Typography style={{ color: theme.palette.error.main, fontSize: 12 }}>
        {props.helperText}
      </Typography>
    );
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <Input
          id="file-upload"
          type="file"
          name={props.name}
          className={classes.input}
          onChange={handleOnChangeUpdateFile}
        />

        <InputLabel htmlFor="file-upload">
          <ButtonFile>{props.label}</ButtonFile> <br />
          {props.error && <ErrorMessage />}
        </InputLabel>

        <Box style={{ border: "0px solid red" }}>
          {fileName && fileUrl && (
            <Grid
              container
              alignItems="center"
              spacing={1}
              style={{ opacity: 0.5 }}
            >
              <Grid item xs={9} style={{ textAlign: "center" }}>
                <Typography variant={"caption"}>
                  Arquivo anexado: <a href={fileUrl}>{fileName}</a>{" "}
                </Typography>
              </Grid>
              <Grid item xs={2} style={{ textAlign: "right" }}>
                <IconButton style={{ padding: 4 }}>
                  {" "}
                  <CheckedIcon style={{ fontSize: "1.5rem" }} />{" "}
                </IconButton>
                <IconButton onClick={handleRemoveFile} style={{ padding: 4 }}>
                  {" "}
                  <TrashIcon
                    style={{ fontSize: "1.5rem", fill: "none" }}
                  />{" "}
                </IconButton>
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default TextFieldUploadFile;

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
}));
