import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import "./CarouselBasic.css";

export interface CarouselBasicItem {
  id?: number;
  title?: string;
  description?: string;
  imageUrl: string;
}

interface CarouselProps {
  items: CarouselBasicItem[];
}

const CarouselBasic: React.FC<CarouselProps> = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [transitioning, setTransitioning] = useState(false);

  const timerRef = useRef<NodeJS.Timeout | null>(null); // Corrigido o tipo da variável

  const handleNext = () => {
    if (!transitioning && items.length > 1) {
      setTransitioning(true);
      setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
        setTransitioning(false);
      }, 500); // Tempo de transição em milissegundos
    }
  };

  const handlePrev = () => {
    if (!transitioning && items.length > 1) {
      setTransitioning(true);
      setTimeout(() => {
        setActiveIndex(
          (prevIndex) => (prevIndex - 1 + items.length) % items.length
        );
        setTransitioning(false);
      }, 500); // Tempo de transição em milissegundos
    }
  };

  const currentImage = items[activeIndex];
  const nextImage = items[(activeIndex + 1) % items.length];
  const prevImage = items[(activeIndex - 1 + items.length) % items.length];

  // Inicie o intervalo ao montar o componente
  useEffect(() => {
    // Função para avançar automaticamente para o próximo item a cada 3 segundos
    const autoPlay = () => {
      handleNext();
    };

    // Inicie o intervalo e guarde a referência
    timerRef.current = setInterval(autoPlay, 5000);

    // Limpe o intervalo ao desmontar o componente
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      elevation={3}
      sx={{
        border: "0px solid red",
        padding: "4px",
        boxShadow: "none",
        position: "relative",
        backgroundColor: "#f5f5f5",
      }}
    >
      {prevImage && (
        <img
          src={prevImage.imageUrl || ""}
          alt={prevImage.title || ""}
          style={{
            display: "none",
            opacity: transitioning ? 0 : 1,
            transition: "opacity 0.5s",
          }}
          loading="lazy"
        />
      )}
      <img
        src={currentImage.imageUrl || ""}
        alt={currentImage.title || ""}
        style={{
          width: "100%",
          height: "auto",
          opacity: transitioning ? 0 : 1,
          transition: "opacity 0.5s",
        }}
        loading="lazy"
      />
      {nextImage && (
        <img
          src={nextImage.imageUrl || ""}
          alt={nextImage.title || ""}
          style={{
            display: "none",
            opacity: transitioning ? 0 : 1,
            transition: "opacity 0.5s",
          }}
          loading="lazy"
        />
      )}
      <Typography variant="h5" sx={{ mt: 2 }}>
        {items[activeIndex].title || ""}
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        {items[activeIndex].description || ""}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Button
          disabled={items.length > 1 ? false : true}
          onClick={handlePrev}
          variant="text"
          color="primary"
          style={{ color: "#3E3D3D", opacity: 0.5 }}
        >
          Anterior
        </Button>
        <div className="slider-container">
          <div className="slider-dots">
            {items.map((_, index) => (
              <span
                key={index}
                className={`slider-dot ${
                  index === activeIndex ? "active" : ""
                }`}
                onClick={() => setActiveIndex(index)}
              ></span>
            ))}
          </div>
        </div>

        <Button
          disabled={items.length > 1 ? false : true}
          onClick={handleNext}
          variant="text"
          color="primary"
          style={{ color: "#D91F05" }}
        >
          Próximo
        </Button>
      </Box>
    </Paper>
  );
};

export default CarouselBasic;
