import { getCorrectDate } from "@utils/index";
import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { IQueryOrderDataDTO } from "../dtos/IQueryOrderDataDTO";
import {
  IQueryOrdersDTO,
  IQueryOrdersDTOTemporary,
} from "../dtos/IQueryOrdersDTO";
import { IQueryOrdersService } from "../models/IQueryOrdersService";

interface IQueryOrdersResponse {
  content: IQueryOrderDataDTO[];
  total: number;
}
export interface IQueryOrdersResult {
  orders: IQueryOrderDataDTO[];
  total: number;
}

@injectable()
export class QueryOrdersService implements IQueryOrdersService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async executeTemporary(data: IQueryOrdersDTOTemporary) {
    const orders = await this.httpInstance.get<IQueryOrderDataDTO[]>(
      "/orders",
      {
        params: data,
      }
    );

    return orders.map((order) => {
      return {
        ...order,
        deliveryDate: getCorrectDate(new Date(order.deliveryDate)),
        implantationDate: getCorrectDate(new Date(order.implantationDate)),
      };
    });
  }

  public async execute(data: IQueryOrdersDTO): Promise<IQueryOrdersResult> {
    const response = await this.httpInstance.get<IQueryOrdersResponse>(
      "/orders/v2/sales",
      {
        params: data,
      }
    );

    const { content, total } = response;

    const mappedOrders = content.map((order) => {
      return {
        ...order,
        deliveryDate: getCorrectDate(new Date(order.deliveryDate)),
        implantationDate: getCorrectDate(new Date(order.implantationDate)),
      };
    });

    return { orders: mappedOrders, total };
  }
}
