import TableFooter from "@components/FooterTable";
import useTable from "@hooks/useTable";
import {
  createStyles,
  LinearProgress,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { maskCNPJ, performBRPhoneMask, replaceCPF } from "@utils/index";
import React, { useState } from "react";

const useStyles = makeStyles(({ ...theme }) =>
  createStyles({
    table: {
      borderCollapse: "collapse",
      borderRadius: "1.6rem",
      width: "100%",
      backgroundColor: "#FFFFFF",
    },
    tableRowHeader: {
      backgroundColor: "transparent",
      transition: "all 0.25s ease",
      borderRadius: "1rem",
    },
    tableHeader: {
      backgroundColor: "#FFFFFF",
      padding: "4rem 1.6rem 0.8rem 1.6rem",
      fontWeight: 500,
      textAlign: "left",
      fontSize: "1.4rem",
      color: "#626166",
      "&:last-child, &:last-child": {
        borderTopLeftRadius: "1.6rem",
        borderTopRightRadius: "1.6rem",
      },
    },
    tableRowItems: {
      cursor: "auto",
      borderTop: "0.1rem solid #EAEAEA",
      "&:last-child, &:last-child": {
        borderTop: "0.1rem solid transparent",
      },
    },
    tableCell: {
      padding: "1.6rem 1.6rem",
      fontSize: "1.4rem",
      color: "grey",
    },
  })
);

export interface Props {
  data: Object[];
  rowsPerPage: number;
}

function objectValues<T extends {}>(obj: T) {
  return Object.keys(obj).map((objKey) => obj[objKey as keyof T]);
}

type TableHeaders<T> = Record<keyof T, string>;

type CustomRenderers<T> = Partial<Record<keyof T, (it: T) => React.ReactNode>>;

interface TableProps<T> {
  items: Object[];
  headers: TableHeaders<T>;
  customRenderers?: CustomRenderers<T>;
  rowsPerPage: number;
  loading?: boolean;
}
// Component that acts as the table of instant prizes. Using the useTable hook. And using the TableFooter Component.

export default function TableGeneric<T>(props: TableProps<T>) {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(props.items, page, props.rowsPerPage);

  return (
    <>
      {props.loading && <LinearProgress variant="indeterminate" />}
      <Table className={classes.table}>
        <TableHead className={classes.tableRowHeader}>
          {objectValues(props.headers).map((headerValue, idx) => (
            <TableCell key={idx}>{headerValue}</TableCell>
          ))}
        </TableHead>
        <TableBody>
          {slice.map((el) => (
            <TableRow className={classes.tableRowItems} key={el.prize_id}>
              <TableCell className={classes.tableCell}>
                {el.user.name}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {el.user.phone
                  ? performBRPhoneMask(el.user.phone)
                  : "Não informado"}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {replaceCPF(el.user.document)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {el.taxReceipt.issue_date} <br /> {el.taxReceipt.issue_time}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {el.company.name}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {maskCNPJ(el.company.document)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Link
                  color="primary"
                  style={{
                    textTransform: "none",
                    fontWeight: 500,
                  }}
                  href={el.taxReceipt.original_resource}
                >
                  Acessar
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </>
  );
}
