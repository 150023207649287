import { ENUM_TARGET_PERFORMANCE } from ".";

export const OptionsSelectPerformanceTable = [
  {
    option: {
      label: "Vendas por frentistas",
      value: ENUM_TARGET_PERFORMANCE.SALES_BY_EMPLOYEE,
    },
  },
  {
    option: {
      label: "Volume de vendas",
      value: ENUM_TARGET_PERFORMANCE.SALES_VOLUME,
    },
  },
  {
    option: {
      label: "Vendas dos últimos meses",
      value: ENUM_TARGET_PERFORMANCE.LAST_MONTH,
    },
  },
];
