import * as IHttpService from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";

import { Types } from "@ioc/types";
import {
  IAvailableDateByTime,
  ICenters,
  ICreateSchedule,
  ICreateScheduleReponse,
  ICustomers,
  IDrivers,
  IProducts,
  IQueryRoadLoad,
  IRoadLoadResponse,
  IRoadLoadService,
  IScheduleReserveVacancies,
  IScheduleVacancies,
  IScheduleVacanciesResponse,
  NotificationHistory,
} from "@modules/roadLoad/model";
import { STATUSROADLOAD } from "@utils/enum";
import { cleanParams } from "@utils/index";

@injectable()
export class RoadLoadService implements IRoadLoadService {
  @inject(Types.IHttpService)
  private httpInstance!: IHttpService.IHttpService;

  async getRoadLoad(query?: IQueryRoadLoad): Promise<IRoadLoadResponse> {
    try {
      const params = query
        ? {
            customers: query?.customers ?? undefined,
            productsIds: query?.products ?? undefined,
            subsidiariesIds: query?.filiais ?? undefined,
            from: query?.from,
            to: query?.to,
            page: query?.page,
            status: query?.status,
            limit: query?.limit,
            noCaching: true,
            sort: query?.status === STATUSROADLOAD.FINISHED ? "DESC" : "ASC",
          }
        : {};

      const response = await this.httpInstance.post<IRoadLoadResponse>(
        "/schedules/query-schedules",
        {
          ...cleanParams(params),
        }
      );

      return response;
    } catch (error) {
      throw error;
    }
  }

  async scheduleQueryVacancies(
    query: IScheduleVacancies
  ): Promise<IScheduleVacanciesResponse> {
    try {
      const params = query
        ? {
            ordersIds: query.orderId.split(",").map((item) => item.trim()),
            date: query.date,
          }
        : {};

      const response = await this.httpInstance.post<IScheduleVacanciesResponse>(
        "/schedules/query-vacancies",
        {
          ...cleanParams(params),
        }
      );

      return response;
    } catch (error) {
      throw error;
    }
  }

  async scheduleReserveVacancies(
    query: IScheduleReserveVacancies,
    scheduleID: string
  ): Promise<void> {
    try {
      const body = {
        ordersIds: query.orderId.split(",").map((item) => item.trim()),
        date: query.date,
        time: query.time,
      };

      await this.httpInstance.post(
        `/schedules/reserve/${cleanParams(scheduleID) ?? ""}`,
        {
          ...cleanParams(body),
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async createSchedule(
    data: ICreateSchedule,
    scheduleID: string
  ): Promise<ICreateScheduleReponse> {
    try {
      const bodyCreate = {
        ordersIds: data?.orderId?.split(",").map((item) => item.trim()),
        time: data.time,
        date: data.date,
        driverId: data.driverId,
      };

      return scheduleID
        ? await this.httpInstance.patch(`/schedules/${scheduleID}`, {
            ...cleanParams(bodyCreate),
          })
        : await this.httpInstance.post(`/schedules/`, {
            ...cleanParams(bodyCreate),
          });
    } catch (error) {
      throw error;
    }
  }

  async scheduleAvailableDate(
    orderID: string,
    byTime?: boolean
  ): Promise<IAvailableDateByTime> {
    try {
      const includeVacancies = byTime ? true : false;

      return await this.httpInstance.post<IAvailableDateByTime>(
        `/schedules/available-dates`,
        {
          ordersIds: orderID.split(",").map((item) => item.trim()),
          includeVacancies: includeVacancies,
          includeAllocationType: true,
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async getProducts(): Promise<IProducts[]> {
    try {
      return await this.httpInstance.get<IProducts[]>(
        "/filters?action=product"
      );
    } catch (error) {
      throw error;
    }
  }

  async getCenters(): Promise<ICenters[]> {
    try {
      return await this.httpInstance.get<ICenters[]>("/filters?action=center");
    } catch (error) {
      throw error;
    }
  }

  async getCustomers(): Promise<ICustomers[]> {
    try {
      return await this.httpInstance.get<ICustomers[]>(
        "/filters?action=customer"
      );
    } catch (error) {
      throw error;
    }
  }

  async getDrivers(documents?: string[]): Promise<IDrivers[]> {
    try {
      return await this.httpInstance.get<IDrivers[]>(
        `/schedules/driver?name=`,
        {
          params: {
            ...cleanParams({
              document: documents?.join(","),
            }),
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async notificationCount(): Promise<{ count: number }> {
    try {
      return await this.httpInstance.get<{ count: number }>(
        "schedules/notifications/count"
      );
    } catch (error) {
      throw error;
    }
  }

  async notificationHistory(): Promise<NotificationHistory> {
    try {
      return await this.httpInstance.get(
        "schedules/notifications/history?sort=DESC"
      );
    } catch (error) {
      throw error;
    }
  }

  async notificationAck(id: string): Promise<void> {
    try {
      return await this.httpInstance.put(`schedules/notifications/${id}/ack`);
    } catch (error) {
      throw error;
    }
  }

  async downloadPdfByScheduled(id: string): Promise<Blob> {
    return await this.httpInstance.get(`/schedules/report/${id}`, {
      responseType: "blob",
    });
  }

  async cancelScheduling(id: string): Promise<void> {
    return await this.httpInstance.put(`schedules/${id}/cancel`);
  }
}
