import {
  Container,
  Tab,
  Tabs,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Theme } from "@mui/material";
import React from "react";

export interface ILabelTab {
  label: string; // o título de cada guia
  key: number; // a chave de cada objeto do label
  count: number; // Quantidade de registros por guia
}
interface ITabGenericProps {
  optionsTabLabel: ILabelTab[]; // do tipo array
  alignTabs: "center" | "right" | "left"; // alinhamento da tab deve ser especificado
  children: React.ReactNode;
  handleChange: (event: React.ChangeEvent<{}>, newTab: number) => void;
  tabActive: number;
}

const TabGeneric: React.FC<ITabGenericProps> = ({ ...props }) => {
  const classes = useStyles();

  return (
    <>
      <Container
        style={{
          padding: "0px",
          display: "flex",
          justifyContent:
            props.alignTabs === "left"
              ? "space-between"
              : props.alignTabs === "center"
              ? "center"
              : "flex-end",
          backgroundColor: "transparent",
        }}
      >
        <div>
          <Tabs
            value={props.tabActive}
            onChange={props.handleChange}
            indicatorColor="primary"
            textColor="primary"
            classes={{
              root: classes.tabsroot,
              indicator: "indicator",
            }}
            TabIndicatorProps={{ children: <span /> }}
          >
            {props.optionsTabLabel.map((tab) => (
              <Tab
                label={
                  tab.count !== 0
                    ? tab.label + " (" + tab.count + ")"
                    : tab.label
                }
                key={tab.key}
              />
            ))}
          </Tabs>
        </div>
      </Container>
      <div>
        {props.optionsTabLabel.map(
          (tab, index) =>
            props.tabActive === index && <div key={index}>{props.children}</div>
        )}
      </div>
    </>
  );
};

export default TabGeneric;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "59px",
      color: "#3E3D3D",
      paddingBottom: "1.4rem",
    },
    appBar: {
      background: "none",
      boxShadow: "none",
      display: "flex",
      flexDirection: "initial",
      justifyContent: "space-between",
      height: "6rem",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 290,
    },
    select: {
      borderRadius: 10,
      "& .MuiSelect-icon": {
        color: "#D91F05",
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "initial",
      },
    },
    tabsroot: {
      "& .MuiTab-wrapper": {
        textTransform: "none",
        fontSize: "1.4rem",
        fontWeight: "700",
      },
      "& .Mui-selected": {
        color: `${"#D91F05"} !important`,
      },
      "& .indicator": {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        color: "#D91F05",
        marginBottom: "4px",
        "& > span": {
          backgroundColor: "#D91F05",
          maxWidth: 62,
          height: 4,
          width: "inherit",
          borderRadius: 1,
        },
      },
    },
  })
);
