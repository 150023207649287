import BreadcrumbCustom, {
  BreadcrumbChild,
} from "@components/BreadcrumbCustom";
import ROUTES from "@config/routes";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { IQueryInvoicesService } from "@modules/invoices/models/IQueryInvoicesService";
import { endOfMonth, startOfMonth } from "date-fns/esm";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import Layout from "../../../../components/Layout";
import FormQuery from "./FormQuery";
import {
  FormQueryInvoicesProvider,
  useFormQueryInvoices,
} from "./FormQueryInvoicesContext";
import FormQueryInvoicesValidationSchema from "./FormQueryInvoicesValidationSchema";
import ModalDownload from "./ModalDownload";
import SkeletonTableInvoices from "./Sections/SkeletonTableInvoices";
import TableInvoices from "./TableInvoices";
import { IQuery } from "./interface";
import { useUserState } from "@context/UserContext";

const AppointmentInvoices: React.FC = () => {
  const iocContext = useIoCContext();
  const formQueryInvoicesContext = useFormQueryInvoices();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const userState = useUserState();

  const queryInvoicesService = iocContext.serviceContainer.get<
    IQueryInvoicesService
  >(Types.Invoices.IQueryInvoicesService);

  const optionsCNPJ = userState.listCNPJ.map((item) => item.CNPJ);

  const initialValues: IQuery = {
    CNPJ: optionsCNPJ,
    dateRange: [startOfMonth(new Date()), endOfMonth(new Date())],
    beginDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  };

  const breadcrumbDataInvoices: BreadcrumbChild[] = [
    {
      link: ROUTES.USER_ROUTES.HOME,
      label: "Início",
    },
    {
      label: "Notas fiscais",
    },
  ];

  useEffect(() => {
    if (initialValues.CNPJ) {
      handleSubmit(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values) => {
    try {
      if (!values) return;
      formQueryInvoicesContext.setLoadingQuery(true);

      const casted = FormQueryInvoicesValidationSchema.cast(values);
      if (!casted) return;

      const queries = casted.CNPJ.map((CNPJ) => {
        const query = {
          CNPJ: CNPJ as string,
          beginDate: casted.dateRange[0],
          endDate: casted.dateRange[1],
        };

        return query;
      });

      if (queries) {
        const queriesDispatched = queries.map(async (query) => {
          const queryResult = await queryInvoicesService.execute(query);
          return queryResult;
        });

        const queriesResults = await Promise.all(queriesDispatched);
        const flatten = queriesResults.flatMap((results) => results);
        formQueryInvoicesContext.setDataQuery(flatten);
      }
      formQueryInvoicesContext.setSearchFirstTime(false);

      if (isMobile) {
        setTimeout(() => {
          window.scrollTo({
            top: 400,
            left: 0,
            behavior: "smooth",
          });
        }, 100);
      } else {
        setTimeout(() => {
          window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: "smooth",
          });
        }, 100);
      }
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro ao buscar notas fiscais", {
        variant: "error",
      });
    } finally {
      formQueryInvoicesContext.setLoadingQuery(false);
      formQueryInvoicesContext.setSearchFirstTime(false);
    }
  };

  return (
    <Layout>
      <div
        style={{
          padding: isMobile ? "2rem" : "",
          height: "100%",
          overflowY: "hidden",
        }}
      >
        <Grid
          xs={10}
          style={{ padding: 8, marginLeft: isMobile ? "-12px" : "44px" }}
        >
          <BreadcrumbCustom data={breadcrumbDataInvoices} />
        </Grid>
        <Typography
          style={{
            color: "#3E3D3D",
            fontWeight: "bold",
            fontFamily: "Montserrat",
            fontSize: isMobile ? "2.0rem" : "3.0rem",
            paddingBottom: "20px",
            textAlign: "left",
            paddingLeft: isMobile ? "5px" : "58px",
          }}
        >
          Notas fiscais
        </Typography>
        <div
          style={{
            margin: isMobile ? 0 : "0 7rem",
            padding: isMobile ? "1.5rem" : "",
            minHeight: "50rem",
          }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
              await handleSubmit(values);
            }}
            validationSchema={FormQueryInvoicesValidationSchema}
          >
            <Form>
              <FormQuery />
            </Form>
          </Formik>

          {!formQueryInvoicesContext.loadingQuery &&
            formQueryInvoicesContext.searchFirstTime && (
              <Grid container justifyContent="center">
                <Typography
                  style={{
                    color: "#3E3D3D",
                    fontFamily: "DM Sans",
                    fontSize: isMobile ? "1.3rem" : "1.6rem",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    marginTop: "40px",
                    marginBottom: "40px",
                    textAlign: "center",
                  }}
                >
                  Para obter o filtro das notas fiscais. Por favor, selecione a
                  razão social e o período.
                </Typography>
              </Grid>
            )}

          {formQueryInvoicesContext.loadingQuery && (
            <Box style={{ paddingTop: isMobile ? "2.5rem" : "5rem" }}>
              <SkeletonTableInvoices
                cols={isMobile ? 3 : 7}
                rows={isMobile ? 5 : 7}
              />
            </Box>
          )}

          {formQueryInvoicesContext.dataQuery.length !== 0 &&
            !formQueryInvoicesContext.loadingQuery && <TableInvoices />}

          {formQueryInvoicesContext.dataQuery.length === 0 &&
            !formQueryInvoicesContext.loadingQuery &&
            !formQueryInvoicesContext.searchFirstTime && (
              <Grid container justifyContent="center">
                <Typography
                  style={{
                    color: "#3E3D3D",
                    fontFamily: "DM Sans",
                    fontSize: isMobile ? "1.3rem" : "1.6rem",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    marginTop: "40px",
                    marginBottom: "40px",
                    textAlign: "center",
                  }}
                >
                  Não houve resultado para o filtro da pesquisa. Por favor tente
                  outro filtro.
                </Typography>
              </Grid>
            )}

          <ModalDownload />
        </div>
      </div>
    </Layout>
  );
};

const Container: React.FC = () => {
  return (
    <FormQueryInvoicesProvider>
      <AppointmentInvoices />
    </FormQueryInvoicesProvider>
  );
};

export default Container;
