import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IProductBlockDTO } from "../dtos/IProductBlockDTO";
import { IProductBlockService } from "../models/IProductBlockService";
import { ISegmentsDTO } from "../dtos/ISegmentsDTO";

@injectable()
export class ProductBlockService implements IProductBlockService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: IProductBlockDTO): Promise<IProductBlockDTO> {
    const response = await this.httpInstance.post<IProductBlockDTO>(
      "product-block",
      data
    );
    return response;
  }

  public async getProduct(): Promise<IProductBlockDTO[]> {
    const response = await this.httpInstance.get("product-block");
    return response;
  }

  public async getSegments(): Promise<ISegmentsDTO[]> {
    const response = await this.httpInstance.get("filters?action=SEGMENT");
    return response;
  }

  public async deleteProduct(productId: string): Promise<IProductBlockDTO> {
    const response = await this.httpInstance.delete(
      `product-block/${productId}`
    );
    return response;
  }
}
