import { useHomeUserContext } from "@context/HomeUserContext";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import { IProductDTO } from "@modules/salesPerformance/dtos/IProductDTO";
import { IGetProductService } from "@modules/salesPerformance/models/IGetProductService";
import { IGetSalesVolumeDTOHistory } from "@modules/salesVolumeHistory/dto/IGetSalesVolumeDTOHistory";
import { IQuerySalesVolumeDTOHistory } from "@modules/salesVolumeHistory/dto/IQuerySalesVolumeDTOHistory";
import { IGetSalesVolumeServiceHistory } from "@modules/salesVolumeHistory/models/IGetSalesVolumeServiceHistory";
import { ENUM_TARGET_VOLUME_SALES } from "@pages/User/PerformanceManagement/Model";
import { transformData } from "@pages/User/PerformanceManagement/UtilsPerfomanceManagement/utilsPerformanceManagment";
import {
  convertYearMonthDay,
  getLastDayOfMonth,
  getTodayDate,
} from "@utils/index";
import { startOfMonth } from "date-fns";
import { endOfMonth } from "date-fns/esm";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ISalesVolumeSelectedDTO } from "./model/constants";
interface ISelectType {
  label: string;
  value: string;
}

interface PeakTimeHistoryContext {
  setIsSalesVolumeEmptyHistory: React.Dispatch<React.SetStateAction<boolean>>;
  isSalesVolumeEmptyHistory: boolean;
  isLoadingSalesVolumeHistory: boolean;
  product: IProductDTO[];
  setProduct(data: IProductDTO[]): void;
  selectedProducts: string[];
  setSelectedProducts(product: string[]): void;
  setIsLoadingSalesVolumeHistory: React.Dispatch<React.SetStateAction<boolean>>;
  salesVolumeSelected: ISalesVolumeSelectedDTO;
  setSalesVolumeSelected: React.Dispatch<
    React.SetStateAction<ISalesVolumeSelectedDTO>
  >;
  getProduct: () => Promise<void>;
  isLodingProduct: boolean;
  setIsLodingProduct: (value: boolean) => void;
  querySalesVolumeHistory: IQuerySalesVolumeDTOHistory;
  setQuerySalesVolumeHistory: React.Dispatch<
    React.SetStateAction<IQuerySalesVolumeDTOHistory>
  >;
  salesVolumeHistory: IGetSalesVolumeDTOHistory[];
  setSalesVolumeHistory: React.Dispatch<
    React.SetStateAction<IGetSalesVolumeDTOHistory[]>
  >;
  rangeDate: [Date | null, Date | null] | Date[];
  setRangeDate: React.Dispatch<
    React.SetStateAction<[Date | null, Date | null] | Date[]>
  >;

  isCalendarError: boolean;
  setIsCalendarError: (value: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const PeakTimeHistoryContext = createContext<PeakTimeHistoryContext>(
  {} as PeakTimeHistoryContext
);

const PeakTimeHistoryProvider: React.FC = ({ children }) => {
  const iocContext = useIoCContext();
  const { documentList } = useHomeUserContext();
  let itemSelected = documentList.filter((item) => item.isSelected)[0];
  let documentItemSelect = itemSelected?.cnpj;
  const [product, setProduct] = useState<IProductDTO[]>([]);
  const [rangeDate, setRangeDate] = useState<
    [Date | null, Date | null] | Date[]
  >([startOfMonth(new Date()), endOfMonth(new Date())]);

  let DATE_INITIAL_CALENDAR = rangeDate[0];
  let DATE_FINAL_CALENDAR = rangeDate[1];

  const [isLodingProduct, setIsLodingProduct] = useState<boolean>(false);
  const [isCalendarError, setIsCalendarError] = useState<boolean>(false);
  const [salesVolumeHistory, setSalesVolumeHistory] = useState<
    IGetSalesVolumeDTOHistory[]
  >([]);

  const [
    isLoadingSalesVolumeHistory,
    setIsLoadingSalesVolumeHistory,
  ] = useState(true);

  const [isSalesVolumeEmptyHistory, setIsSalesVolumeEmptyHistory] = useState(
    false
  );

  const OptionsProductFormated: ISelectType[] = transformData(product);

  const [selectedProducts, setSelectedProducts] = useState<string[]>(
    OptionsProductFormated.map((option) => option.value)
  );

  const DATE_INITIAL_CALENDAR_FORMATED =
    DATE_INITIAL_CALENDAR !== null
      ? convertYearMonthDay(DATE_INITIAL_CALENDAR)
      : getTodayDate();

  const DATE_FINAL_CALENDAR_FORMATED =
    DATE_FINAL_CALENDAR !== null
      ? convertYearMonthDay(DATE_FINAL_CALENDAR)
      : getLastDayOfMonth();

  useEffect(() => {
    if (product.length) {
      // Define todos os produtos como selecionados na inicialização assim que consumir a API de produtos
      setSelectedProducts(OptionsProductFormated.map((option) => option.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const [salesVolumeSelected, setSalesVolumeSelected] = useState<
    ISalesVolumeSelectedDTO
  >({
    label: "Volume de vendas",
    value: ENUM_TARGET_VOLUME_SALES.VOLUME,
  });

  const [querySalesVolumeHistory, setQuerySalesVolumeHistory] = useState<
    IQuerySalesVolumeDTOHistory
  >({
    document: documentItemSelect,
    period: "customized",
    salesVolume: salesVolumeSelected.value,
    productIds: selectedProducts ? selectedProducts : [""],
    beginDate: getTodayDate(),
    endDate: getLastDayOfMonth(),
    peakTimeHistory: true,
  });

  const getSalesVolumeServiceHistory = iocContext.serviceContainer.get<
    IGetSalesVolumeServiceHistory
  >(Types.SalesVolumeHistory.IGetSalesVolumeServiceHistory);

  const getProductService = iocContext.serviceContainer.get<IGetProductService>(
    Types.SalesPerformance.IGetProductService
  );

  const getSalesVolumeHistory = useCallback(async () => {
    const isCalendarNull = rangeDate[0] === null || rangeDate[1] === null;

    if (isCalendarNull) {
      setIsCalendarError(true);
    } else {
      try {
        setIsSalesVolumeEmptyHistory(false);
        setIsLoadingSalesVolumeHistory(true);

        if (
          querySalesVolumeHistory.document !== undefined &&
          rangeDate[0] !== null &&
          rangeDate[1] !== null
        ) {
          const data = await getSalesVolumeServiceHistory.execute(
            querySalesVolumeHistory
          );
          setSalesVolumeHistory(data);
        }
      } catch (error) {
        setSalesVolumeHistory([]);
        setIsSalesVolumeEmptyHistory(true);
      } finally {
        setIsLoadingSalesVolumeHistory(false);
      }
    }
  }, [getSalesVolumeServiceHistory, querySalesVolumeHistory, rangeDate]);

  const getProduct = useCallback(async () => {
    try {
      const responseProduct = await getProductService.execute();
      setProduct(responseProduct);
      setIsLodingProduct(true);
    } catch (error) {
    } finally {
      setIsLodingProduct(false);
    }
  }, [getProductService]);

  useEffect(() => {
    setQuerySalesVolumeHistory((prevQuery) => ({
      ...prevQuery,
      salesVolume: salesVolumeSelected.value,
      period: "customized",
      document: documentItemSelect,
      productIds: selectedProducts,
      beginDate: DATE_INITIAL_CALENDAR_FORMATED,
      endDate: DATE_FINAL_CALENDAR_FORMATED,
      peakTimeHistory: true,
    }));
  }, [
    salesVolumeSelected,
    documentItemSelect,
    selectedProducts,
    rangeDate,
    DATE_INITIAL_CALENDAR_FORMATED,
    DATE_FINAL_CALENDAR_FORMATED,
  ]);

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  useEffect(() => {
    getSalesVolumeHistory();
  }, [getSalesVolumeHistory]);

  return (
    <PeakTimeHistoryContext.Provider
      value={{
        isSalesVolumeEmptyHistory,
        setIsSalesVolumeEmptyHistory,
        product,
        isLoadingSalesVolumeHistory,
        setIsLoadingSalesVolumeHistory,
        setProduct,
        getProduct,
        salesVolumeSelected,
        setSalesVolumeSelected,
        isLodingProduct,
        setIsLodingProduct,
        selectedProducts,
        setSelectedProducts,
        querySalesVolumeHistory,
        setQuerySalesVolumeHistory,
        salesVolumeHistory,
        setSalesVolumeHistory,
        rangeDate,
        setRangeDate,
        isCalendarError,
        setIsCalendarError,
      }}
    >
      {children}
    </PeakTimeHistoryContext.Provider>
  );
};

const usePeakTimeHistory = (): PeakTimeHistoryContext => {
  const context = useContext(PeakTimeHistoryContext);
  if (!Object.values(context).length) {
    throw new Error(
      "usePeakTimeHistory deve ser utilizado dentro de um PeakTimeHistoryProvider"
    );
  }
  return context;
};

export { PeakTimeHistoryProvider, usePeakTimeHistory };
