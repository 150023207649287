import { StyledIcon } from "@components/StyledIcon";
import {
  Box,
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import SkeletonTableOrder from "../MyOrders/SkeletonTableOrder";
import { formatCurrencyPriceWithTwoDigit } from "@utils/index";
import { IDiscountProduct } from "@modules/product/dtos/IProductResponse";
import PageNotFound from "@components/PageNotFound/PageNotFound";
import { CactusIcon } from "@components/Icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    grid: { height: "90px", marginTop: 16, marginBottom: 16 },
    gridColumn: { height: "60px", marginTop: 16, marginBottom: 16 },
    gridItem: {
      display: "flex",
      alignItems: "center",
      color: "#3E3D3D",
      fontFamily: "DM Sans",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "20px",
    },
    gridItemColumn: {
      color: "#3E3D3D",
      fontFamily: "DM Sans",
      fontSize: "16px",
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
    },
    paperGrid: {
      borderRadius: "8px",
      padding: "0px 57px 0px 32px",
      boxShadow: `0px 4px 4px rgba(115, 97, 97, 0.10)`,
    },
    titleSearch: {
      color: "#3E3D3D",
      textAlign: "center",
      fontFamily: "DM Sans",
      fontSize: "16px",
      fontWeight: 400,
    },
    Button: {
      textTransform: "none",
      boxShadow: "none",

      [theme.breakpoints.up("xs")]: {
        margin: "1rem 0 0 0",
        width: "100%",
      },
      [theme.breakpoints.up("lg")]: {
        margin: "0 0 0 1rem",
        width: "auto",
      },
    },
  })
);

interface TablaRowProps {
  produdctId: string;
  productName: string;
  price: string;
  code: string;
  unity: string;
  product?: IDiscountProduct;
}

interface ProductTableProps {
  isLoading?: boolean;
  products: IDiscountProduct[];
  itemsPerPage?: number;
  onOpenModalClick?: (id: string) => void;
  onHandleEdit?: (product: IDiscountProduct) => void;
}

const ProductTable: React.FC<ProductTableProps> = ({
  isLoading,
  products,
  itemsPerPage,
  onOpenModalClick,
  onHandleEdit,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const TableRow: React.FC<TablaRowProps> = ({
    produdctId,
    productName,
    price,
    code,
    unity,
    product,
  }) => {
    return (
      <Paper className={classes.paperGrid}>
        <Grid
          container
          xs={12}
          style={{ display: "flex", fontWeight: "bold", paddingBottom: 0 }}
          className={classes.grid}
        >
          <Grid item xs={isMobile ? 4 : 5} className={classes.gridItem}>
            {productName}
          </Grid>
          <Grid
            item
            xs={2}
            className={classes.gridItem}
            style={{
              justifyContent: "center",
            }}
          >
            {price}
          </Grid>
          <Grid
            item
            xs={2}
            className={classes.gridItem}
            style={{
              justifyContent: "flex-end",
            }}
          >
            {code}
          </Grid>
          <Grid
            item
            xs={isMobile ? 2 : 1}
            className={classes.gridItem}
            style={{
              justifyContent: "center",
            }}
          >
            {unity}
          </Grid>

          <Grid
            item
            xs={1}
            className={classes.gridItem}
            style={{
              justifyContent: "center",
            }}
          >
            <Button
              className={classes.Button}
              variant="text"
              color="primary"
              onClick={() => product && onHandleEdit && onHandleEdit(product)}
            >
              Editar
            </Button>
          </Grid>
          <Grid
            item
            xs={1}
            className={classes.gridItem}
            style={{
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={() => {
                onOpenModalClick && onOpenModalClick(produdctId);
              }}
            >
              <StyledIcon
                iconType="trash"
                sx={{
                  width: "20px",
                  height: "20px",
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const TableHeader: React.FC = () => {
    return (
      <Paper className={classes.paperGrid}>
        <Grid
          xs={12}
          style={{ display: "flex", fontWeight: "bold" }}
          className={classes.gridColumn}
        >
          <Grid item xs={isMobile ? 4 : 5} className={classes.gridItemColumn}>
            Descrição
          </Grid>
          <Grid
            item
            xs={2}
            className={classes.gridItemColumn}
            style={{
              justifyContent: "center",
            }}
          >
            Preço
          </Grid>
          <Grid
            item
            xs={2}
            className={classes.gridItemColumn}
            style={{
              justifyContent: "flex-end",
            }}
          >
            Código PDV
          </Grid>
          <Grid
            item
            xs={isMobile ? 2 : 1}
            className={classes.gridItemColumn}
            style={{
              justifyContent: "center",
            }}
          >
            Unidade
          </Grid>
          <Grid
            item
            xs={1}
            className={classes.gridItemColumn}
            style={{
              justifyContent: "center",
            }}
          ></Grid>
          <Grid
            item
            xs={1}
            className={classes.gridItemColumn}
            style={{
              justifyContent: "center",
            }}
          ></Grid>
        </Grid>
      </Paper>
    );
  };

  const remaningQuantity = (itemsPerPage ?? 0) - products.length;

  const hasResult = Boolean(products.length);

  const LoadContent: React.FC = () => {
    if (isLoading) {
      return <SkeletonTableOrder rows={6} cols={isMobile ? 2 : 10} />;
    }

    if (hasResult) {
      return (
        <TableBody>
          {products.map((product) => (
            <TableRow
              produdctId={product.id ?? ""}
              productName={product.name ?? ""}
              price={formatCurrencyPriceWithTwoDigit(product.value ?? 0)}
              code={product.internal_code ?? ""}
              unity={product.unit ?? ""}
              product={product}
            />
          ))}
          {Array.from({ length: remaningQuantity }).map((item) => (
            <div
              style={{
                height: "9rem",
              }}
            ></div>
          ))}
        </TableBody>
      );
    }

    return (
      <PageNotFound
        title="Não foram encontrados resultados"
        subtitle="Nada para exibir na tabela. Tente recarregar a página."
        icon={<CactusIcon />}
      />
    );
  };

  return (
    <Table style={{ marginTop: "2rem" }}>
      {hasResult && (
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Typography className={classes.titleSearch}>
            Resultados da pesquisa
          </Typography>
        </Box>
      )}
      {hasResult && <TableHeader />}
      <LoadContent />
    </Table>
  );
};

export default ProductTable;
