import {
  OutlinedTextFieldProps,
  TextField,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React from "react";
interface TextFieldCustomProps extends OutlinedTextFieldProps {
  id?: string;
  label?: string;
  name: string;
  value: string;
  type?: string;
  hasTouched?: boolean;
  hasErros?: string;
  backgroundColor?: "black";
  height?: string;
}

const useStyles = makeStyles<Theme, TextFieldCustomProps>((theme) => {
  return createStyles({
    root: (props: TextFieldCustomProps) => ({
      "& .MuiInputBase-root.Mui-disabled": {
        opacity: 0.6,
      },
      "& fieldset": {
        backgroundColor: props?.disabled ? "rgba(0,0,0,0.15)" : "inherit",
      },
      "& input": {
        boxShadow: "0px 0px 0px 30px white inset",
      },
      "& label": {
        // Controle de estilo antes de digitar
        opacity: 0.6,
        fontSize: "1.4rem",
      },
      "& .MuiInputBase-root": {
        borderRadius: "1rem",
      },
      "& .MuiInputLabel-shrink": {
        // Controle de estilo da "label" apos campo digitado // -> Equivalencia ->  "& .Mui-focused"
        fontSize: "1.5rem",
        opacity: 1,
        color: theme.palette.text.primary,
        fontWeight: 700,
      },
      "& .MuiInputBase-input": {
        color: theme.palette.text.primary,
        fontWeight: 500,
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.secondary.light}`, // Controla cor da borda durante Digitacao "#FFB800"
      },
      "& .MuiFormHelperText-root.Mui-error.Mui-focused": {
        // Esconde a mensagem de erro enquanto usuario estiver digitando
        // opacity: 0,
        display: "none",
      },
    }),
    hasErrorFieldSet: {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.error.main}`, // Sinalizacao de campo incorreto -> "#F97A91"
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.secondary.light}`, // Controla cor da borda durante Digitacao "#FFB800"
      },
    },
  });
});

const TextFieldCustom: React.FC<TextFieldCustomProps> = (props) => {
  const classes = useStyles(props);

  return (
    <TextField
      {...props}
      type={props.type}
      name={props.name}
      className={classes.root}
      InputProps={{
        classes: {
          notchedOutline: props.error ? `${classes.hasErrorFieldSet}` : ``,
        },
      }}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      fullWidth
    />
  );
};

export default TextFieldCustom;
