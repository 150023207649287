import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React from "react";
import { useHistory } from "react-router-dom";

export interface BreadcrumbChild {
  link?: string;
  label?: string;
  icon?: React.ReactNode;
  isIcon?: boolean;
}

export interface BreadcrumbProps {
  data?: BreadcrumbChild[];
}

const BreadcrumbCustom: React.FC<BreadcrumbProps> = ({ data }) => {
  const history = useHistory();

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    link: string
  ) => {
    event.preventDefault();
    history.push(link);
  };

  const breadcrumbs = data?.map((child, idx) => {
    if (idx === data.length - 1 && data.length !== 1) {
      return (
        <Typography
          sx={{ fontWeight: 700, fontSize: { xs: 12, lg: 13 } }}
          key="3"
          color="text.primary"
        >
          {child.label && child.label.toUpperCase()}
        </Typography>
      );
    }
    return (
      <Link
        sx={{ fontWeight: 700, fontSize: 10, cursor: "pointer" }}
        underline="hover"
        key="2"
        color="inherit"
        onClick={(e) =>
          handleClick(
            e as React.MouseEvent<HTMLAnchorElement, MouseEvent>,
            child.link || ""
          )
        }
      >
        {child.isIcon && child.icon}
        {child.label && child.label.toUpperCase()}
      </Link>
    );
  });

  return (
    <Breadcrumbs
      sx={{ marginLeft: 0.5, marginTop: "20px" }}
      separator={
        <NavigateNextIcon sx={{ color: "#D2D3D6" }} fontSize="medium" />
      }
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
};

export default BreadcrumbCustom;
