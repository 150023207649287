import { IFilterComissionData } from "@modules/comissionCalculator/dtos/IFilterComissionData";
import React, { createContext, useContext, useState } from "react";

interface CommissionCalculatorContext {
  loadingDataFilterComission: boolean;
  setLoadingDataFilterComission: (value: boolean) => void;
  dataQueryComission: IFilterComissionData;
  setDataQueryComission(data: IFilterComissionData): void;
  rangeDate: [Date | null, Date | null] | Date[];
  setRangeDate: React.Dispatch<
    React.SetStateAction<[Date | null, Date | null] | Date[]>
  >;
  isErrorData: boolean;
  setErrorData: (value: boolean) => void;
  isCalendarError: boolean;
  setIsCalendarError: (value: boolean) => void;
  isFirstSearch: boolean;
  setIsFirstSearch: (value: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const CommissionCalculatorContext = createContext<CommissionCalculatorContext>(
  {} as CommissionCalculatorContext
);

const CommissionCalculatorProvider: React.FC = ({ children }) => {
  const [rangeDate, setRangeDate] = useState<
    [Date | null, Date | null] | Date[]
  >([null, null]);

  const [loadingDataFilterComission, setLoadingDataFilterComission] = useState<
    boolean
  >(false);

  const [isErrorData, setErrorData] = useState<boolean>(false);

  const [dataQueryComission, setDataQueryComission] = useState<
    IFilterComissionData
  >({
    employees_quantity_total: 0,
    employees_commission_total: 0,
    employees: [],
  });

  const [isFirstSearch, setIsFirstSearch] = useState(true);

  const [isCalendarError, setIsCalendarError] = useState<boolean>(false);

  return (
    <CommissionCalculatorContext.Provider
      value={{
        loadingDataFilterComission,
        setLoadingDataFilterComission,
        dataQueryComission,
        setDataQueryComission,
        rangeDate,
        setRangeDate,
        isErrorData,
        setErrorData,
        isCalendarError,
        setIsCalendarError,
        isFirstSearch,
        setIsFirstSearch,
      }}
    >
      {children}
    </CommissionCalculatorContext.Provider>
  );
};

const useCommissionCalculatorContext = () => {
  const context = useContext(CommissionCalculatorContext);
  if (Object.values(context).length === 0) {
    throw new Error(
      "useCommissionCalculatorContext deve ser utilizado dentro de um CommissionCalculatorProvider"
    );
  }
  return context;
};

export { CommissionCalculatorProvider, useCommissionCalculatorContext };
