import React from "react";

import { useTheme } from "@material-ui/core";
import { Box, Grid } from "@mui/material";

import { RoadLoadRedFullIcon } from "@components/Icons";
import Button from "@components/Button";
import Modal from "@components/Modal";

import { formatDateAndHourPTBR } from "@utils/index";

import { TypographyCaption, TypographyTitle } from "../typography";

interface Props {
  children?: React.ReactNode;
  order?: string;
  driverName?: string;
  open?: boolean;
  onClick: () => void;
  scheduleDateTime?: string;
  hasBeenEdit?: boolean;
  timezone?: string;
}

const ModalCreatedOrder: React.FC<Props> = ({ children, ...props }) => {
  const {
    order,
    open,
    onClick,
    scheduleDateTime,
    hasBeenEdit,
    driverName,
    timezone,
  } = props;

  const theme = useTheme();

  return (
    <Modal open={open} hiddenButton={true}>
      {({ handleClose }) => {
        return (
          <Box
            className="modal-box-content"
            sx={{ width: { xs: "260", sm: "320px", lg: "350px" } }}
          >
            <Grid container sx={{ gap: 4, textAlign: "center", pb: 2 }}>
              <Grid item xs={12}>
                <RoadLoadRedFullIcon
                  className="RoadIcon"
                  style={{ width: "70px" }}
                />
              </Grid>
              <Grid item>
                <TypographyTitle sx={{ fontSize: { xs: 24, lg: 26 } }}>
                  Agendamento{" "}
                  <b style={{ color: theme.palette.primary.main }}>
                    {order ?? ""}
                  </b>{" "}
                  {hasBeenEdit ? "Atualizado " : "Criado "} com sucesso
                </TypographyTitle>
              </Grid>
              <Grid item sx={{ px: { xs: 3, lg: 1 } }}>
                <TypographyCaption sx={{ fontSize: { xs: 14, lg: 15 } }}>
                  Seu horario foi agendado para{" "}
                  <b>{formatDateAndHourPTBR(scheduleDateTime, timezone)}</b> com
                  motorista <b>{driverName}</b>
                </TypographyCaption>
              </Grid>
              <Grid item xs={12}>
                <Grid container sx={{ justifyContent: "center" }}>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleClose();
                        onClick();
                      }}
                    >
                      Ok, entendi.
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        );
      }}
    </Modal>
  );
};

export default ModalCreatedOrder;
