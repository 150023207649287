import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { IDetailsInstantRewardPosh } from "@utils/interfaces";
import { inject, injectable } from "inversify";
import { IGetTaxReceiptWithActionsDTO } from "../dtos/IGetTaxReceiptWithActionsDTO";
import { IGetDetailsInstantRewardPoshService } from "../models/IGetDetailsInstantRewardPoshService";

@injectable()
export class GetDetailsInstantRewardPoshService
  implements IGetDetailsInstantRewardPoshService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  execute(data: IGetTaxReceiptWithActionsDTO): Promise<IDetailsInstantRewardPosh[]> {
    return this.httpInstance.get<IDetailsInstantRewardPosh[]>(
      `/dashboard-posh/reward?action=${data.action}&companies_id=${data.companies_id}&from=${data.from}&to=${data.to}`
    );
  }
}
