import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IGetReclamationAdminService } from "../models/IGetReclamationAdminService";
import { IDownloadReportsAdminDTO } from "../dtos/IDownloadReportsAdminDTO";
import { fileNameByHeaders } from "@utils/index";

@injectable()
export class GetReclamationAdminService implements IGetReclamationAdminService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  async downloadReports(data: IDownloadReportsAdminDTO): Promise<any> {
    try {
      const params = {};

      if (data.status) params["status"] = data.status;
      if (data.cnpj) params["cnpj"] = data.cnpj;
      if (data.beginDate) params["beginDate"] = data.beginDate;
      if (data.endDate) params["endDate"] = data.endDate;
      if (data.ref_id) params["ref_id"] = data.ref_id;
      if (data.order) params["order"] = data.order;

      const {
        data: titleFile,
        headers,
      } = await this.httpInstance.getWithHeaders<BinaryType>(
        `/reclamation/reports`,
        {
          params,
          responseType: "arraybuffer",
        }
      );

      let url = window.URL.createObjectURL(
        new Blob([titleFile], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );

      return [url, fileNameByHeaders(headers)];
    } catch (err) {
      throw err;
    }
  }
}
