import * as Yup from "yup";

export enum FormikName {
  /* Dados da compra */
  COMPANY = "companyName",
  DELIVERY = "deliveryAddress",
  CEP = "cep",
  DISTRICT = "district",
  FREIGHT = "freight",
  CITY = "city",
  WITHDRAWALBASE = "withDrawalBase",
  SUBSIDIARY = "subsidiary",
  DATEDELIVERY = "dateDelivery",

  /* Dados do responsável */
  ADVISOR = "advisor", // assessor
  TELEPHONE = "telephone",
  EMAIL = "email",

  /* Dados do Produto */
  PRODUCT = "product",
  PAYMENT = "payment",

  /* Dados do Observacao */
  OBSERVATION = "observation",
}

export const currentinitialValues = {
  /* Dados da compra */
  companyName: "",
  deliveryAddress: "",
  cep: "",
  district: "",
  city: "",
  freight: "", // Frete
  withDrawalBase: "", // Base de Retirada
  subsidiary: "", // Filial
  dateDelivery: "", // Data da entrega

  /* Dados do responsável */
  advisor: "", // assessor
  telephone: "",
  email: "",

  /* Dados do Produto */
  product: "",
  payment: "",

  /* Dados do Observacao */
  observation: "",
};

export interface FormikInitialValuesNewRequest {
  /* Dados da compra */
  companyName: string;
  deliveryAddress: string;
  cep: string;
  district: string;
  city: string;
  freight: string; // Frete
  withDrawalBase: string; // Base de Retirada
  subsidiary: string; // Filial
  dateDelivery: string; // Data da entrega

  /* Dados do responsável */
  advisor: string; // assessor
  telephone: string;
  email: string;

  /* Dados do Produto */
  product: string;
  payment: string;

  /* Dados do Observacao */
  observation: string;
}

export const validationSchemaNewRequest = Yup.object().shape({
  /* Dados da compra */
  companyName: Yup.string().required("Este campo é obrigatório"),
  deliveryAddress: Yup.string().required("Este campo é obrigatório"),
  cep: Yup.string().required("Este campo é obrigatório"),
  district: Yup.string().required("Este campo é obrigatório"),
  city: Yup.string().required("Este campo é obrigatório"),
  freight: Yup.string().required("Este campo é obrigatório"),
  withDrawalBase: Yup.string().required("Este campo é obrigatório"),
  subsidiary: Yup.string().required("Este campo é obrigatório"),
  dateDelivery: Yup.string().required("Este campo é obrigatório"),

  /* Dados do responsável */
  advisor: Yup.string().required("Este campo é obrigatório"),
  telephone: Yup.string().required("Este campo é obrigatório"),
  email: Yup.string().required("Este campo é obrigatório"),

  /* Dados do Produto */
});
