import {
  Grid,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import {
  HeaderTableDriveCustomer,
  ITableDriverCustomer,
} from "./models/HeaderTableDriverCustomer";

import iconPostFull from "../../../assets/driverVsCustomer/posto_full.svg";
import { AddUser, ThreeDots } from "@components/Icons";
import { useStylesDriverCustomer } from ".";
import SkeletonTableOrder from "@pages/User/MyOrders/SkeletonTableOrder";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { IDriveCustomerService } from "@modules/driverAndCustomer/model/IDriverCustomerService";
import { Types } from "@ioc/types";
import { IDriveCustomerResponse } from "@modules/driverAndCustomer/dto/IDriveCustomerResponse";
import PaginationDynamic from "@components/PaginationDynamic/PaginationDynamic";
import { calcPagesQuantity } from "@utils/pagination";

interface ITableOrdersProps {
  page?: number;
}

export const ITEMS_PER_PAGE = 10;

interface ICustomTableCell {
  align?: "left" | "center" | "right";
  children: React.ReactNode;
}

const CustomTableCell: React.FC<ICustomTableCell> = ({
  align = "left",

  children,
}) => {
  const classes = useStylesDriverCustomer();
  return (
    <TableCell className={classes.TableCellFromData} align={align}>
      {children}
    </TableCell>
  );
};

const TableDriverCustomer: React.FC<ITableOrdersProps> = () => {
  const [loadingData, setLoadingData] = useState(false);
  const classes = useStylesDriverCustomer();
  const [totalCount, setTotalCount] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [driverCustomer, setDriverCustomer] = useState<
    IDriveCustomerResponse
  >();
  const [currentPage, setCurrentPage] = useState(1);
  const firstItemIndex = (currentPage - 1) * ITEMS_PER_PAGE + 1;
  const lastItemIndex = Math.min(currentPage * ITEMS_PER_PAGE, totalCount);

  const iocContext = useIoCContext();

  const getDriverCustomer = iocContext.serviceContainer.get<
    IDriveCustomerService
  >(Types.DriverCustomer.IDriverCustomerService);

  const getDriverCustomerTable = useCallback(
    async (values, page) => {
      try {
        setLoadingData(true);

        const data = await getDriverCustomer.execute({
          limit: 10,
          page: page > 1 ? page : 1,
        });

        setDriverCustomer(data);

        setTotalCount(data.total);
      } catch (error) {
      } finally {
        setLoadingData(false);
      }
    },
    [getDriverCustomer]
  );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => {
    setCurrentPage(newPage + 1);
    getDriverCustomerTable("", newPage + 1);
  };

  useEffect(() => {
    getDriverCustomerTable("", "");
  }, [getDriverCustomerTable]);

  const getColumnsDriverCustomer = (): ITableDriverCustomer[] => {
    let titleColumnTableOrders: ITableDriverCustomer[] = HeaderTableDriveCustomer;
    return titleColumnTableOrders;
  };
  const columnsDriverCustomer: ITableDriverCustomer[] = getColumnsDriverCustomer();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {loadingData && (
        <SkeletonTableOrder rows={isMobile ? 6 : 6} cols={isMobile ? 2 : 10} />
      )}
      {!loadingData && (
        <>
          {" "}
          <Grid
            item
            xs={12}
            md={4}
            lg={12}
            style={{
              display: "block",
              justifyContent: "space-between",
            }}
          >
            <Table
              className={classes.Table}
              stickyHeader
              style={{ borderSpacing: isMobile ? "0px 13px" : "0px 20px" }}
            >
              <TableHead>
                <TableRow className={classes.TableRow}>
                  {columnsDriverCustomer.map((item, index) => (
                    <>
                      {item.isVisibleMobile && isMobile && (
                        <TableCell
                          key={index}
                          align={
                            item.id === "deliveryDate" ? "left" : item.align
                          }
                          className={classes.TableCellHead}
                          style={{
                            padding:
                              isMobile && item.id === "deliveryDate"
                                ? "16px 0px"
                                : "",
                            fontSize: "1.3rem",
                          }}
                          colSpan={item.id === "deliveryDate" ? 2 : 1}
                        >
                          {item.option}
                        </TableCell>
                      )}

                      {!isMobile && (
                        <TableCell
                          key={index}
                          align={item.align}
                          className={classes.TableCellHead}
                        >
                          {item.option}
                        </TableCell>
                      )}
                    </>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {driverCustomer?.content.map((item, index) => (
                  <TableRow key={index}>
                    <CustomTableCell>
                      <img
                        src={iconPostFull}
                        width="36px"
                        alt="ícone de caminhão"
                      />
                    </CustomTableCell>
                    <CustomTableCell>{item.salesArea}</CustomTableCell>
                    <CustomTableCell>{item.customerCNPJ}</CustomTableCell>
                    <CustomTableCell>{item.customerName}</CustomTableCell>
                    <CustomTableCell>{item.segmentDesc}</CustomTableCell>
                    <CustomTableCell>{item.city}</CustomTableCell>
                    <CustomTableCell align="left">
                      {item.advisorDesc}
                    </CustomTableCell>

                    <CustomTableCell align="center">
                      <Grid
                        item
                        xl={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Grid xl={10}>
                          <Typography
                            style={{
                              color: "#e56350",
                              lineHeight: "0px",
                              fontSize: "14px",
                              textAlign: "right",
                            }}
                          >
                            Associar
                          </Typography>
                        </Grid>
                        <Grid
                          xl={2}
                          item
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: 25,
                            paddingLeft: 3,
                          }}
                        >
                          <AddUser />
                        </Grid>
                      </Grid>
                    </CustomTableCell>

                    <CustomTableCell align="center">
                      <Grid
                        item
                        xl={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          width: 25,
                        }}
                        onClick={handleClick}
                      >
                        <ThreeDots />
                      </Grid>
                    </CustomTableCell>

                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      PaperProps={{
                        elevation: 0,
                        style: {
                          boxShadow: `0px 4px 6px 0px rgba(0, 0, 0, 0.02)`,
                          top: 10,
                        },
                      }}
                    >
                      <MenuItem onClick={() => {}}>
                        <Typography style={{ fontSize: 14 }}>
                          Associar motorista
                        </Typography>
                      </MenuItem>
                      <MenuItem onClick={() => {}}>
                        <Typography style={{ fontSize: 14 }}>
                          Ver motoristas associados
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Grid item xl={10}>
              <span className={classes.TilteFooter}>
                <b>
                  Mostrando itens{" "}
                  <span className={classes.CountItensFooter}>
                    {" "}
                    {firstItemIndex}-{lastItemIndex}
                  </span>
                </b>
                <p className={classes.SubTilteFooter}>Total de {totalCount}</p>
              </span>
            </Grid>

            <Grid item xl={2}>
              <PaginationDynamic
                onPageChange={handlePageChange}
                pagesTotal={calcPagesQuantity(ITEMS_PER_PAGE, totalCount)}
                showFirstButton={true}
                showLastButton={true}
                page={currentPage}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default TableDriverCustomer;
