import React from "react";

interface Props {
  bgcolor: string;
  progress: number;
  height: number;
}

const ProgressBar: React.FC<Props> = ({ bgcolor, progress, height }) => {
  const progresstext = {
    color: bgcolor,
    fontWeight: 900,
    paddingLeft: "0.8rem",
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <div
        style={{
          height: height,
          width: "100%",
          backgroundColor: "whitesmoke",
          borderRadius: 40,
        }}
      >
        <div
          style={{
            height: "100%",
            width: `${progress}%`,
            backgroundColor: bgcolor,
            borderRadius: 40,
            textAlign: "right",
          }}
        />
      </div>
      <span style={progresstext}>{`${progress}%`}</span>
    </div>
  );
};

export default ProgressBar;
