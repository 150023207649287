import NoData from "@components/NoData";
import {
  createStyles,
  Fade,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import DraftsIcon from "@material-ui/icons/Drafts";
import EmailIcon from "@material-ui/icons/Email";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import { Pagination } from "@material-ui/lab";
import { IQueryTitlesDataDTO } from "@modules/titles/dtos/IQueryTitlesDataDTO";
import { formatCurrency, maskCNPJ } from "@utils/index";
import clsx from "clsx";
import { format } from "date-fns";
import React from "react";
import { useListInvoicesImages } from "./ListInvoicesImagesContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    paperTable: {
      marginTop: "5rem",
      overflow: "hidden",
      overflowX: "scroll",

      "&::-webkit-scrollbar": {
        background: theme.palette.grey[300],
        height: "0.7rem",
        borderRadius: ".4rem",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.grey["900"],
        borderRadius: ".4rem",
      },
    },
    viewed: {
      backgroundColor: "rgba(242,245,245,0.8);",
    },
    notViewed: {
      "& td": {
        fontWeight: "bold",
      },
    },
    tableRow: {
      cursor: "pointer",
      "&:hover": {
        boxShadow:
          "inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)",
      },
    },
  })
);

const TableInvoices: React.FC = () => {
  const classes = useStyles();
  const listInvoicesImagesContext = useListInvoicesImages();

  const handleShowModalImage = (data: IQueryTitlesDataDTO) => {
    if (data.imageTitle) {
      listInvoicesImagesContext.setOpenModalImage(true);
      listInvoicesImagesContext.setImage({
        id: data.imageTitle.id,
        link: data.imageTitle.link,
        comments: data.imageTitle.comments,
      });
    }
  };

  return listInvoicesImagesContext.listTitle &&
    listInvoicesImagesContext.listTitle.content.length ? (
    <Paper className={classes.paperTable}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" />
            <TableCell align="center">Título</TableCell>
            <TableCell align="center">Emissão</TableCell>
            <TableCell align="center">Vencimento</TableCell>
            <TableCell align="right">Valor</TableCell>
            <TableCell align="center">CNPJ</TableCell>
            <TableCell align="center">Razão Social</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {listInvoicesImagesContext.listTitle &&
            listInvoicesImagesContext.listTitle.content.map((ele, idx) => {
              return (
                <Fade
                  key={ele.id}
                  in={Boolean(
                    listInvoicesImagesContext.listTitle
                      ? listInvoicesImagesContext.listTitle.content.length
                      : 0
                  )}
                  timeout={1000}
                >
                  <TableRow
                    className={clsx(classes.tableRow, {
                      [classes.viewed]: ele.imageTitle?.userSeen,
                      [classes.notViewed]: !ele.imageTitle?.userSeen,
                    })}
                    onClick={() => handleShowModalImage(ele)}
                  >
                    <TableCell align="center">
                      {ele.imageTitle?.userSeen ? (
                        <DraftsIcon />
                      ) : (
                        <EmailIcon />
                      )}
                    </TableCell>
                    <TableCell align="center">{ele.id}</TableCell>
                    <TableCell align="center">
                      {format(new Date(ele.emissionDate), "dd/MM/yyyy")}
                    </TableCell>
                    <TableCell align="center">
                      {format(new Date(ele.dueDate), "dd/MM/yyyy")}
                    </TableCell>
                    <TableCell align="right">
                      {formatCurrency(ele.value)}
                    </TableCell>

                    <TableCell align="center">{maskCNPJ(ele.CNPJ)}</TableCell>
                    <TableCell align="center">{ele.companyName}</TableCell>
                    <TableCell>
                      {ele.imageTitle && (
                        <Tooltip title="Ver comprovante" placement="top">
                          <IconButton onClick={() => handleShowModalImage(ele)}>
                            <PhotoLibraryIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                </Fade>
              );
            })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={7}>
              {listInvoicesImagesContext.listTitle && (
                <Pagination
                  showFirstButton
                  showLastButton
                  count={Math.ceil(
                    listInvoicesImagesContext.listTitle.count /
                      listInvoicesImagesContext.numberOfItemsInList
                  )}
                  page={listInvoicesImagesContext.page}
                  onChange={(event, page) =>
                    listInvoicesImagesContext.setPage(page)
                  }
                  color="primary"
                />
              )}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  ) : (
    <NoData>Ainda não foram enviados comprovantes.</NoData>
  );
};

export default TableInvoices;
