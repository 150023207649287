import React, { useState } from "react";
import { useTheme } from "@material-ui/core";

import {
  Grid,
  Hidden,
  Pagination,
  PaginationItem,
  useMediaQuery,
} from "@mui/material";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import {
  CardHeadTypography,
  CardBodyTypography,
} from "@pages/User/RoadLoad/resource/typography";
import { IRoadNotLoad, IScheduledLoad } from "@pages/User/RoadLoad/model";
import {
  cardNextLoadsColumns,
  cardNotLoadsColumns,
} from "@pages/User/RoadLoad/resource/card/header";

import ModalSchedule from "@pages/User/RoadLoad/resource/modal/ModalSchedule";

import { ClockIcon, RoadLoadRedIcon } from "@components/Icons";
import Button from "@components/Button";

import {
  formatAllFistLetterCapitalized,
  formatDateAndHourPTBR,
  formatDateWithSlash,
  formatToAmountLiters,
} from "@utils/index";
import { KEY } from "@utils/enum";

interface CardNextLoadsProps {
  nextLoads?: IScheduledLoad[];
  hiddenButon?: boolean;
  sx?;
  isModal?: boolean; // util para saber se o componente esta sendo chamado dentro de um modal
  onClick?: () => void;
}

interface CardNotLoadsProps {
  notLoads: IRoadNotLoad[];
  onClick?: () => void;
}

const CardNextLoads: React.FC<CardNextLoadsProps> = (props) => {
  const { nextLoads, hiddenButon, ...restProps } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 2;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const colorApply = (data: string): string => {
    const currentDate = new Date();
    const deliveryDate = new Date(data);

    // Define a data de entrega e a data de amanhã para comparar diretamente
    const isToday = deliveryDate.toDateString() === currentDate.toDateString();

    const tomorrow = new Date(currentDate);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const isTomorrow = deliveryDate.toDateString() === tomorrow.toDateString();

    // Utiliza switch case para melhorar a legibilidade
    switch (true) {
      case isToday:
        return theme.palette.success.light;

      case isTomorrow:
        return theme.palette.secondary.light;

      default:
        return theme.palette.text.primary;
    }
  };

  const applyTypography = (data) => {
    return <CardBodyTypography>{data ?? ""}</CardBodyTypography>;
  };

  const applyTypographyHead = (data) => {
    return <CardHeadTypography>{data ?? ""}</CardHeadTypography>;
  };

  const formatEntrys = (key: string, data: string, row: IScheduledLoad) => {
    switch (key) {
      case KEY.TRUCKDRIVER:
        return applyTypography(data);

      case KEY.SCHEDULEDATE:
        return applyTypography(
          <span style={{ color: colorApply(data) }}>
            {formatDateAndHourPTBR(data, row["timezone"])}
          </span>
        );
      case KEY.QUANTITY:
        return applyTypography(formatToAmountLiters(data));
      default:
        return applyTypography(formatAllFistLetterCapitalized(data));
    }
  };

  const sliceLogic = () => {
    return isMobile
      ? nextLoads?.slice(startIndex, endIndex)
      : nextLoads?.slice(0, 5);
  };

  const empty = nextLoads?.length === 0;

  return (
    <Grid
      container
      className="CardNextLoads"
      sx={{
        gap: 3,
        justifyContent: "center",
        ...restProps.sx,
      }}
    >
      {!empty &&
        sliceLogic()?.map((nextLoadItem, index) => {
          const { salesOrder } = nextLoadItem;
          const { product, ...restSalesOrder } = salesOrder?.[0] ?? {};
          const nextLoadItemHome = {
            Ref: nextLoadItem?.Ref,
            ...restSalesOrder,
            scheduleDate: nextLoadItem?.scheduleDate,
            quantity: nextLoadItem?.totalAmountLiters,
            truckDriver: nextLoadItem?.truckDriver,
          };

          return (
            <Grid
              item
              xs={12}
              key={index}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                background: "#fff",
                gap: 2,
                px: 0.8,
                py: 1.6,
                borderRadius: 2,
              }}
            >
              <Grid item>
                {" "}
                <RoadLoadRedIcon style={{ width: "35px" }} />{" "}
              </Grid>
              {cardNextLoadsColumns.map((col, colIndex) => {
                const key = col.key;
                const value = nextLoadItemHome[col.key];

                return (
                  <Grid
                    item
                    key={colIndex}
                    sx={{
                      textAlign: col.align ?? "auto",
                      border: "0px solid red",
                      minWidth: col.minWidth ?? "inherit",
                    }}
                  >
                    <Grid item>{applyTypographyHead(col.label)}</Grid>
                    <Grid item>{formatEntrys(key, value, nextLoadItem)}</Grid>
                  </Grid>
                );
              })}
            </Grid>
          );
        })}

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: {
            xs: "center",
            lg: nextLoads?.length === 0 ? "center" : "right",
          },
          color: "#D91F05",
        }}
      >
        {!hiddenButon && (
          <>
            <Hidden lgDown>
              <Button variant="text" onClick={props.onClick}>
                Ver Todos
              </Button>
            </Hidden>
            <Hidden lgUp>
              {nextLoads && (
                <Pagination
                  count={Math.ceil(nextLoads?.length / itemsPerPage)}
                  page={currentPage}
                  onChange={(_, page) => setCurrentPage(page)}
                  boundaryCount={1}
                  siblingCount={0}
                  renderItem={(item) => (
                    <PaginationItem
                      sx={{ fontSize: "12px" }}
                      slots={{
                        previous: ArrowBackIosIcon,
                        next: ArrowForwardIosIcon,
                      }}
                      {...item}
                    />
                  )}
                />
              )}
            </Hidden>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const CardNotLoads: React.FC<CardNotLoadsProps> = (props) => {
  const { notLoads } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 1;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const sliceLogic = () => {
    return isMobile
      ? notLoads.slice(startIndex, endIndex)
      : notLoads.slice(0, 5);
  };

  const applyTypographyHead = (data) => {
    return <CardHeadTypography>{data ?? "-"}</CardHeadTypography>;
  };

  const applyTypography = (data) => {
    return <CardBodyTypography>{data ?? "-"}</CardBodyTypography>;
  };

  const entryFormat = (key: string, value: string, index: number) => {
    switch (key) {
      case KEY.RELEASEDATE:
        return applyTypography(formatDateWithSlash(value));
      case KEY.ACTION:
        return (
          <ModalSchedule roadNotLoad={notLoads[index]}>Agendar</ModalSchedule>
        );
      case KEY.QUANTITY:
        return applyTypography(formatToAmountLiters(value));
      default:
        return applyTypography(formatAllFistLetterCapitalized(value));
    }
  };

  return (
    <Grid
      container
      className="CardNotLoads"
      sx={{
        gap: { xs: 2, lg: 2 },
        justifyContent: "center",
      }}
    >
      {sliceLogic().map((notLoad, indexNotLoad) => {
        return (
          <Grid item key={indexNotLoad} xs={12}>
            <Grid
              container
              sx={{
                borderRadius: 1.5,
                background: "#fff",
                gap: { xs: 1, lg: 2, xl: 2.2 },
                padding: { xs: 2, lg: 1.2, xl: 1.4 },
                paddingLeft: { lg: 1 },
                justifyContent: {
                  xs: "flex-start",
                  lg: "center",
                  xl: "space-evenly",
                },
                alignItems: "center",
              }}
            >
              <Grid item sx={{ border: "0px solid red" }}>
                <ClockIcon style={{ width: "32px" }} />
              </Grid>
              {cardNotLoadsColumns.map((col, index) => {
                return (
                  <Grid
                    item
                    key={index}
                    xs={12}
                    lg={"auto"}
                    sx={{
                      border: "0px solid red",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap", // NOTE: Serve para impedir a quebra de linha
                      overflow: "hidden", // NOTE: serve para conter o texto que ultrapassa o limite do tamanho maximo permitido
                      textAlign: col.align ?? "auto",
                      width: col.width ?? "auto",
                      maxWidth: col?.maxWidth ?? "auto",
                    }}
                  >
                    {col.label && applyTypographyHead(col.label)}
                    {entryFormat(
                      col.value,
                      notLoad[col.value],
                      isMobile ? startIndex : indexNotLoad
                    ) ?? "-"}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        );
      })}
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: {
            xs: "center",
            lg: notLoads.length === 0 ? "center" : "right",
          },
          color: theme.palette.primary.main,
        }}
      >
        <Hidden lgDown>
          <Button variant="text" onClick={props.onClick}>
            Ver Todos
          </Button>
        </Hidden>
        <Hidden lgUp>
          <Pagination
            count={Math.ceil(notLoads.length / itemsPerPage)}
            page={currentPage}
            boundaryCount={1}
            siblingCount={0}
            onChange={(_, page) => setCurrentPage(page)}
            renderItem={(item) => (
              <PaginationItem
                sx={{ fontSize: "12px" }}
                slots={{
                  previous: ArrowBackIosIcon,
                  next: ArrowForwardIosIcon,
                }}
                {...item}
              />
            )}
          />
        </Hidden>
      </Grid>
    </Grid>
  );
};

export { CardNextLoads, CardNotLoads };
