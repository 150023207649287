export const SIDEBAR_USER_FLAGS = [
  "user_sidebar_home_user",
  "user_sidebar_price_product_atem",
  "user_sidebar_employee",
  "user_sidebar_product_nozzle",
  "user_sidebar_your_sales",
  "user_sidebar_metrics_and_campaigns",
  "user_sidebar_goal_and_commissions",
  "user_sidebar_hide_performance_management",
  "user_sidebar_road_load",
  "user_sidebar_communique",
  "user_sidebar_contact_us",
  "user_sidebar_change_password",
  "user_sidebar_hide_simulate_loading",
  "user_sidebar_change_ui_simulate_loading",
  "user_sidebar_sidebar_redirect_admin",
  "user_sidebar_hide_discount",

  // FEATURES FLAGS ESPECÍFICA:
  "user_home_card_new_order",
  "user_home_card_your_orders",
  "user_home_card_consult_fiscal_notes",
  "user_home_see_banner_communique",

  "user_create_order_v2",
];

export const SIDEBAR_ADMIN_FLAGS = [
  "admin_sidebar_home",
  "admin_sidebar_create_new_user",
  "admin_sidebar_list_user",
  "admin_sidebar_consult_check_receipts",
  "admin_sidebar_change_ui_send_communique",
  "admin_sidebar_block_products",
  "admin_sidebar_contact_us",
  "admin_sidebar_redirect_modo_user",
  "admin_sidebar_hide_send_communique",
  "admin_sidebar_nozzle",
  "admin_sidebar_driver_and_customer",
];
