import {
  Button,
  CircularProgress,
  Container,
  createStyles,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useMemo, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useHandoutDispatch } from "@context/HandoutContext";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Colors } from "@pages/Admin/AddCommunication/Colors";
import Sizes from "@pages/Admin/AddCommunication/Sizes";
import Layout from "@components/Layout";
import Title from "@components/Title";
import FormHandoutValidationSchema from "@pages/Admin/AddCommunication/FormHandoutValidationSchema";
import { ICreateHandoutDTO } from "@modules/handouts/dtos/ICreateHandoutDTO";
import AppError from "@utils/AppError";
import { ICreateHandoutService } from "@modules/handouts/models/ICreateHandoutService";
import { Types } from "@ioc/types";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      margin: "1rem",
    },
    buttonPreview: {
      margin: "1rem 0",
    },
  })
);

const TemporaryAddCommunicationPage: React.FC = () => {
  const classes = useStyles();
  const handoutDispatch = useHandoutDispatch();

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const iocContext = useIoCContext();

  const toolBarOptions = useMemo(() => {
    return {
      toolbar: [
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }], // lists
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ align: ["", "right", "center", "justify"] }],
        Colors,
        [{ size: Sizes }],
        ["link", "image"],
        ["video"],
        ["clean"],
      ],
    };
  }, []);

  const initialValues = {
    content: "",
    title: "",
    expireAt: null,
  };

  return (
    <Layout>
      <Title title="Enviar Comunicado" />
      <div className={classes.container}>
        <Formik
          initialValues={initialValues}
          validationSchema={FormHandoutValidationSchema}
          onSubmit={async (values, formikProps) => {
            const casted = FormHandoutValidationSchema.cast(values);
            if (!casted) return;

            const transformed: ICreateHandoutDTO = {
              title: casted.title,
              content: casted?.content,
              expire_at: casted?.expireAt,
            };

            try {
              setLoading(true);

              const createHandoutService = iocContext.serviceContainer.get<
                ICreateHandoutService
              >(Types.Handouts.ICreateHandoutService);
              await createHandoutService.execute(transformed);

              enqueueSnackbar("Comunicado criado com sucesso!", {
                variant: "success",
              });
              formikProps.resetForm();
            } catch (error) {
              if (error instanceof AppError) {
                return enqueueSnackbar(error.message, {
                  variant: error.variant,
                });
              }
              console.error(error);
              enqueueSnackbar("Não foi possível enviar o comunicado", {
                variant: "error",
              });
            } finally {
              setLoading(false);
            }
          }}
        >
          {(props) => {
            return (
              <Form>
                <Grid
                  container
                  justify="center"
                  className={classes.buttonPreview}
                >
                  <Button
                    onClick={() =>
                      handoutDispatch({
                        type: "APP_SHOW_HANDOUT",
                        payload: {
                          id: "0",
                          content: props.values.content,
                          createdAt: new Date().toISOString(),
                          title: props.values.title,
                          viewed: false,
                        },
                      })
                    }
                    color="primary"
                    variant="contained"
                  >
                    Preview
                  </Button>
                </Grid>
                <Container>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                      <Field
                        name="title"
                        label="Título"
                        variant="outlined"
                        fullWidth
                        as={TextField}
                        error={props.touched.title && !!props.errors.title}
                        helperText={props.touched.title && props.errors.title}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <KeyboardDatePicker
                        fullWidth
                        inputVariant="outlined"
                        value={props.values.expireAt}
                        onChange={(date) => {
                          props.setFieldTouched("expireAt", true);
                          props.setFieldValue("expireAt", date);
                        }}
                        disablePast
                        autoOk
                        format="dd/MM/yyyy"
                        label="Data de expiração"
                        error={
                          !!props.errors.expireAt && !!props.touched.expireAt
                        }
                        helperText={
                          !!props.touched.expireAt && props.errors.expireAt
                        }
                        onBlur={() => props.setFieldTouched("expireAt", true)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ReactQuill
                        theme="snow"
                        value={props.values.content}
                        onChange={(change) =>
                          props.setFieldValue("content", change)
                        }
                        modules={toolBarOptions}
                        placeholder="Escreva o comunicado aqui"
                      />
                      <FormHelperText
                        error={props.touched.content && !!props.errors.content}
                      >
                        {props.touched.content && props.errors.content}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} container justify="center">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        startIcon={
                          loading && (
                            <CircularProgress
                              color="secondary"
                              style={{ height: "2.5rem", width: "2.5rem" }}
                            />
                          )
                        }
                      >
                        {loading ? "Enviando..." : "Enviar"}
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

export default TemporaryAddCommunicationPage;
