import React from "react";
import { Button, ButtonProps, CircularProgress } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { motion } from "framer-motion";

export interface StyledButtonProps extends ButtonProps<"button"> {
  loading?: boolean;
}

const StyledButton = styled(
  ({ children, loading, style, ...props }: StyledButtonProps) =>
    loading ? (
      // @ts-ignore
      <CircularProgress size={14}>{children}</CircularProgress>
    ) : (
      // @ts-ignore
      <Button
        style={{
          ...style,
          boxShadow: "none",
          fontWeight: 600,
          textTransform: "none",
          margin: 3,
          padding: "8px 40px 8px 40px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        component={motion.button}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        {...props}
      >
        {children}
      </Button>
    )
)(({ theme }) => ({}));

export { StyledButton };
