/**
 * Resposta do servidor ao criar um novo pedido
 */
export type SuccessOrError = "success" | "error";
export enum SuccessOrErrorEnum {
  SUCCESS = "success",
  ERROR = "error",
}

export enum AtemStatusEnum {
  LIB = "LIB",
  BL = "BL",
}

export type TypePayment = "T" | "B";
export enum TypePaymentEnum {
  TRANSFER = "T",
  TICKET = "B",
}

export interface ICreateOrderDataDTO {
  status: SuccessOrError;
  response: {
    atemStatus: string;
    deliveryDate: string;
    status: SuccessOrError;
    numberOrder: string;
    product: {
      id: string;
      deadlinePayment: number;
      payment: TypePayment;
      quantity: number;
    };
  }[];
}
