import React from "react";
import { useFormikContext } from "formik";

import {
  Box,
  IconButton,
  MenuItem,
  SelectProps,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { ArrowDownIcon } from "@components/Icons";

interface SelectCustomProps {
  id?: string;
  name?: string;
  price?: string;
  description?: string;
}
interface SelectFieldCustomProps extends SelectProps {
  name: string;
  label: string;
  value: string;
  objects?: SelectCustomProps[];
}

const SelectFieldCustom: React.FC<SelectFieldCustomProps> = ({ ...props }) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();

  const [openMenu, setOpenMenu] = React.useState(false);

  const handleChange = (event: any) => {
    if (event.object && event.object.id) {
      setFieldValue(props.name, event.object.id);
    }
    return props.onChange;
  };

  const iconArrowDownCustom = () => (
    <IconButton
      style={{
        border: "0px solid red",
        display: "flex",
        marginRight: "1.5rem",
      }}
    >
      <ArrowDownIcon style={{ height: 14, width: 14 }}></ArrowDownIcon>
    </IconButton>
  );

  const findProduct = (id: string) => {
    const findProductById =
      props.objects && props.objects.find((item) => item.id === id);
    return findProductById ? findProductById.description : "";
  };

  const handleOnBlur = () => {
    return props.onBlur;
  };

  const handleOnClick = (object: SelectCustomProps) => (event: any) => {
    event.object = object;
  };

  const handleOnClickSelect = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <STextField
      select
      variant="outlined"
      label={props.label}
      value={findProduct(props.value)}
      onChange={handleChange}
      onBlur={handleOnBlur}
      onClick={handleOnClickSelect}
      SelectProps={{
        open: openMenu,
        IconComponent: iconArrowDownCustom,
        MenuProps: { className: classes.menuBox },
      }}
      fullWidth
    >
      {props.objects &&
        props.objects.length > 0 &&
        props.objects.map((object, index) => (
          <MenuItem
            key={index}
            value={object.description ? object.description : ""}
            onClick={handleOnClick(object)}
            style={{ border: "0px solid red", margin: 8 }}
          >
            <Box className={classes.boxtypography}>
              <Typography
                variant={"subtitle2"}
                className={classes.typographytitle}
              >
                {object.description}
              </Typography>
            </Box>
          </MenuItem>
        ))}
    </STextField>
  );
};

const STextField = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      color: "#3E3D3D",
      "& label": {
        // Controle de estilo antes de digitar
        fontFamily: "montserrat",
        fontWeight: 400,
        fontSize: "1.4rem",
        borderRadius: "1rem",
        opacity: "0.6",
      },
      "& input.Mui-focused": {}, // Controle de estilo durante digitacao
      "& .MuiInputLabel-shrink": {
        // Controle de estilo apos campo digitado
        fontWeight: 700,
        color: theme.palette.text.primary,
        opacity: 1,
        fontSize: "1.8rem",
        backgroundColor: "white",
        paddingRight: 6,
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        // Controla cor da borda durante Digitacao
        border: "2px solid #FF9900",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "1rem",
        opacity: "0.6",
      },
      "& .MuiSelect-select": {
        backgroundColor: "initial",
        padding: " 17.5px 14px",
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "initial",
      },
    },
    label: {
      textTransform: "capitalize",
    },
  })
)((props: TextFieldProps) => <TextField {...props} />);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuBox: {
      "& .MuiPaper-root": {
        height: "40% !important",
        margin: "2rem 0px 0px 2rem !important",
      },
    },
    boxtypography: {
      display: "block",
    },
    typographytitle: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
    },
    typographySubtitle: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      opacity: 0.4,
      color: "##726551",
      fontSize: "1.2rem",
    },
    input: {},
  })
);

export default SelectFieldCustom;
