import React, { useCallback, useEffect } from "react";
import moment from "moment";

import {
  Grid,
  LinearProgress,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";

import YourSalesFilterFormik from "@components/YourSalesFilterFormik";
import ButtomBorderless from "@components/ButtonBorderless";
import ButtomDrawer from "@components/DrawerCustom";
import { FilterIcon } from "@components/Icons";
import Layout from "@components/Layout";

import { useHomeUserContext } from "@context/HomeUserContext";
import { useIoCContext } from "@context/IoCContext/IoCContext";

import {
  IGetYourSalesService,
  IYourSalesReduce,
  IYourSalesRequest,
} from "@modules/yourSales/models/IGetYourSalesService";

import RowPerPageOptions from "@components/TableGenericPagination/RowPerPageOptions";
import GenericPaginatedTable, {
  Column,
} from "@components/TableGenericPagination";

import { downloadFile, formatDate } from "@utils/index";
import useDialogAlert from "@hooks/useDialogAlert";
import { Types } from "@ioc/types";

interface IYourSales {
  vid?: string;
  employee_id?: string;
  employee_name?: string;
  dt_transaction?: string;
  tank?: number | string;
  product_id?: string;
  product_description?: string;
  refuel_volume?: string;
  refuel_unit_price?: string;
  refuel_value?: string;
  refuel_time?: string | number;
}

const YourSales: React.FC = ({ ...props }) => {
  const classes = useStyles();

  const defaultRowPerPage = 10;
  const defaultRowPerPageOptions = [10, 25, 50, 75, 100];

  const [rowPerPageControl, setRowPerPageControl] = React.useState(
    defaultRowPerPage
  );

  const handleRowPerPageControl = (event) => {
    setRowPerPageControl(event.target.value);
  };

  const { documentList } = useHomeUserContext();
  let itemSelected = documentList.filter((item) => item.isSelected)[0];
  let documentItemSelect = itemSelected?.cnpj;

  const [yourSales, setYourSales] = React.useState<IYourSalesReduce[]>([]);
  const [hasLoading, setHasLoading] = React.useState(true);
  const [
    hasLoadingReportDownload,
    setHasLoadingReportDownload,
  ] = React.useState(false);

  const iocContext = useIoCContext();
  const yourSalesService = iocContext.serviceContainer.get<
    IGetYourSalesService
  >(Types.YourSales.IGetYourSalesService);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const yourSalesEmpty: IYourSalesReduce[] = [
    {
      vid: "-",
      employee_name: "-",
      dt_transaction: "-",
      tank: "-",
      product_description: "-",
      refuel_volume: "-",
      refuel_unit_price: "-",
      refuel_value: "-",
      refuel_time: "-",
    },
  ];

  const fetchYourSales = useCallback(async () => {
    try {
      const yourSalesResponse = await yourSalesService.getYourSales({
        document: documentItemSelect!,
        startdate: formatDate(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
        ),
        enddate: formatDate(
          new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
        ),
      });

      let yourSalesReduce: IYourSalesReduce[] = [];
      if (yourSalesResponse && yourSalesResponse.content) {
        // eslint-disable-next-line array-callback-return
        yourSalesResponse.content.map((item, index) => {
          yourSalesReduce[index] = {
            id: item.id,
            employee_id: item.employee?.id,
            employee_name: item.employee?.name,
            dt_transaction: item.dt_transaction,
            tank: item.tank,
            product_id: item.product?.id ? item.product.id : "",
            product_description: item.product?.description
              ? item.product.description
              : "",
            refuel_volume: item.refuel_volume,
            refuel_unit_price: item.refuel_unit_price,
            refuel_value: item.refuel_value,
            refuel_time: item.refuel_time,
          };
        });
      }

      setYourSales(yourSalesReduce);
      setHasLoading(false);
    } catch (err) {
      throw err;
    }
  }, [yourSalesService, documentItemSelect]);

  const { snackbar } = useDialogAlert();

  useEffect(() => {
    async function fetchDataYourSales() {
      try {
        await fetchYourSales();
      } catch (err: any) {
        console.info(`error reason Your Sales Page: ${err!.message}`);
        snackbar({
          message: `${err!.message}`,
          variant: "error",
        });
      } finally {
      }
    }

    fetchDataYourSales();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchYourSales]);

  const handleUpdateYourSales = (yourSales: IYourSalesReduce[]) => {
    setYourSales(yourSales);
  };

  const handleReportDownload = async () => {
    try {
      setHasLoadingReportDownload(true);
      const payload = {
        document: documentItemSelect,
        startdate: moment(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
        ).format("YYYY-MM-DD"),
        enddate: moment(
          new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
        ).format("YYYY-MM-DD"),
        hasDownload: true,
      } as IYourSalesRequest;

      const [url, fileName] = await yourSalesService.getYourSalesDownload(
        payload
      );

      downloadFile(url, fileName);
      setHasLoadingReportDownload(false);
    } catch (error) {
      throw error;
    }
  };

  const theadYourSales: Column<IYourSales>[] = [
    { title: "Id", field: "vid", align: "left" },
    { title: "Frentista", field: "employee_name", align: "left" },
    { title: "Data de abastecimento", field: "dt_transaction", align: "right" },
    { title: "Tanque", field: "tank", align: "center" },
    { title: "Produto", field: "product_description", align: "left" },
    { title: "Volume (L)", field: "refuel_volume", align: "right" },
    { title: "Valor unitário", field: "refuel_unit_price", align: "right" },
    { title: "Valor total", field: "refuel_value", align: "right" },
    { title: "Tempo de abastecimento", field: "refuel_time", align: "center" },
  ];

  return (
    <Layout disableFooter>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.title}>
            Suas Vendas
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: 4,
          }}
        >
          <Grid container style={{ border: "0px solid red" }}>
            <Grid
              item
              xs={4}
              style={{ alignSelf: "center", border: "0px solid black" }}
            >
              <Grid item>
                {hasLoadingReportDownload ? (
                  <LinearProgress
                    variant="indeterminate"
                    style={{ width: "7rem" }}
                  />
                ) : (
                  <ButtomBorderless
                    label="Baixar arquivo em excel"
                    onClick={handleReportDownload}
                  />
                )}
              </Grid>
            </Grid>

            <Grid item xs={8} style={{ border: "0px solid blue" }}>
              <Grid
                container
                justifyContent="flex-end"
                style={{ border: "0px solid green" }}
                spacing={2}
              >
                <Grid item>
                  <RowPerPageOptions
                    RowPerPage={rowPerPageControl}
                    rowPerPageControl={rowPerPageControl}
                    RowPerPageOptionsDefault={defaultRowPerPageOptions}
                    onRowPerPageChange={handleRowPerPageControl}
                  />
                </Grid>
                <Grid item style={{ display: "flex", alignItems: "center" }}>
                  <ButtomDrawer label="Filtrar" anchortype="right">
                    <YourSalesFilterFormik
                      labelhead="Filtrar Dados"
                      avatar={<FilterIcon />}
                      handleUpdateSales={handleUpdateYourSales}
                    />
                  </ButtomDrawer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <GenericPaginatedTable
            columns={theadYourSales}
            data={yourSales ? yourSales : yourSalesEmpty}
            rowsPerPage={rowPerPageControl}
            onRowsPerPageChange={handleRowPerPageControl}
            hasLoading={hasLoading}
            hasDateWithHours={true}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default YourSales;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "block",
      maxWidth: "95%",
      marginLeft: "4rem",
    },
    title: {
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "59px",
      color: "#3E3D3D",
      paddingBottom: "0rem",
    },
    appBar: {
      background: "none",
      boxShadow: "none",
    },
    buttomBorderless: {
      paddingLeft: "1rem",
    },
  })
);
