import * as Yup from "yup";

const FormValidationFilterDashPosh = Yup.object().shape({
  startDate: Yup.date()
    .nullable()
    .typeError("Digite uma data válida")
    .required("Selecione a data de ínicio"),
  endDate: Yup.date()
    .min(Yup.ref("startDate"), "A data final não pode ser anterior a inicial")
    .nullable()
    .typeError("Digite uma data válida")
    .required("Selecione a data final"),
});

export default FormValidationFilterDashPosh;
