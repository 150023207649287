import { useTheme } from "@material-ui/core";
import {
  Avatar,
  Checkbox,
  CircularProgress,
  Fade,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import { useNozzleAdmin } from "@pages/Admin/Nozzle/context/NozzleAdminContext";
import {
  INozzleApprovedModel,
  INozzleGroup,
  INozzleRejectModel,
  INozzleValidate,
  STATUSNOZZLE,
} from "@pages/Admin/Nozzle/model";
import TextFieldSelect from "@pages/Admin/Nozzle/resource/field/TextFieldSelect";

import ButtonDefault from "@components/ButtonDefault";
import { CloseIcon } from "@components/Icons";
import GetAppIcon from "@material-ui/icons/GetApp";
import { formatDateAndHourPTBR } from "@utils/index";

interface ModalValidateNozzleProps {
  children?: React.ReactNode;
  nozzleValidate: INozzleValidate;
  cnpj: string;
  disabled?: boolean;
}

const ModalValidateNozzle: React.FC<ModalValidateNozzleProps> = ({
  children,
  nozzleValidate,
  cnpj,
  disabled,
}) => {
  const {
    nozzlesAdmin,
    optionsProduct,
    handleFetchApprovedNozzles,
    handleFetchRejectedNozzles,
  } = useNozzleAdmin();
  const theme = useTheme();
  const [loadApproved, setLoadApproved] = useState<boolean>(false);
  const [loadReject, setLoadReject] = useState<boolean>(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [productValues, setProductValues] = useState<INozzleGroup[]>(
    Array.from({ length: nozzleValidate.pumpNozzle.nozzles.length }, () => ({
      idNozzle: "",
      productDescription: "",
    }))
  );

  const [checked, setChecked] = useState(false);
  const [reasonRejected, setReasonRejected] = useState<string>("");

  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredConcluded, setIsHoveredConcluded] = useState(false);

  const handleReasonRejected = (e) => {
    setReasonRejected(e.target.value);
  };

  const conditionIsNotConcluded = productValues.some(
    (product) => product.productDescription.length === 0
  );

  const handleMouseEnterConcluded = () => {
    setIsHoveredConcluded(true);
  };

  const handleMouseLeaveConcluded = () => {
    setIsHoveredConcluded(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setReasonRejected("");
    setProductValues(
      productValues.map((prod) => ({
        ...prod,
        idNozzle: "",
        productDescription: "",
      }))
    );
    setChecked(false);
    handleMouseLeaveConcluded();
    handleMouseLeave();
    setModalOpen(false);
  };

  const handleApproveNozzles = async () => {
    try {
      setLoadApproved(true);

      const payloadNozzleApprovedModel: INozzleApprovedModel = {
        cnpj: cnpj,
        nozzleGroup: productValues.map((product) => ({
          nozzle: parseInt(product.idNozzle),
          product_id: product.productDescription,
        })),
      };

      await handleFetchApprovedNozzles(payloadNozzleApprovedModel);

      handleClose();
    } catch (error) {
      throw new Error("Nao foi possivel aprovar o bico e produto");
    } finally {
      setLoadApproved(false);
    }
  };

  const handleRejectNozzles = async () => {
    try {
      setLoadReject(true);
      const payloadNozzleRejectModel: INozzleRejectModel = {
        idGroup: nozzleValidate.idPumpNozzle,
        rejection_reason: reasonRejected,
        status: nozzleValidate.status,
      };

      await handleFetchRejectedNozzles(payloadNozzleRejectModel);

      handleClose();
    } catch (error) {
      throw new Error("Nao foi possivel Rejeitar o pedido do bico e produto");
    } finally {
      setLoadReject(false);
    }
  };

  return (
    <>
      <ButtonDefault
        variant={"text"}
        style={{ padding: 0, fontSize: 13 }}
        onClick={handleOpen}
        disabled={disabled ?? false}
      >
        {children}
      </ButtonDefault>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
          <Grid
            container
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              p: { xs: 1, md: 2, lg: 4, xl: 5 },
              gap: { xs: 1, md: 1, lg: 2, xl: 2 },
              width: { xs: 300, md: 400, lg: 600, xl: 800 },
              height: { xs: 300, md: 400, lg: 600, xl: 700 },
              borderRadius: 2,
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              textAlign: "center",
              justifyContent: "center",
              overflow: "scroll",
            }}
          >
            <Grid item xs={12} textAlign={"right"}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} textAlign={"left"}>
              <TypographyTitle>Ver Detalhes</TypographyTitle>
            </Grid>
            <Grid item xs={12}>
              <Grid container sx={{ gap: 1, alignItems: "center" }}>
                {/* IMAGEM / AVATAR */}
                <Grid item>
                  <Avatar
                    alt={`Grupo - ${nozzleValidate.pumpNozzle.idGroup}`}
                    src={nozzleValidate.image}
                  />
                </Grid>

                <Grid item xs={7} lg={10}>
                  <Grid
                    container
                    textAlign={"left"}
                    sx={{ border: "0px solid red" }}
                  >
                    <Grid item xs={12}>
                      <TypographySubtitleDescription>
                        <b>Razão social: </b>
                        {nozzlesAdmin?.find((nozzle) => nozzle.cnpj === cnpj)
                          ?.companyName ?? "Nao Definido"}
                      </TypographySubtitleDescription>
                    </Grid>

                    <Grid item xs={12} sx={{ border: "0px solid red" }}>
                      <TypographySubtitleDescription>
                        <b>Data de cadastro: </b>
                        {formatDateAndHourPTBR(nozzleValidate.registrationDate)}
                      </TypographySubtitleDescription>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <Grid
                container
                className="textFieldSelectGroup"
                sx={{ gap: { lg: 2, xl: 2 } }}
              >
                {nozzleValidate.pumpNozzle.nozzles.map((nozzle, index) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      key={index}
                      sx={{ border: "0px solid red" }}
                    >
                      <Grid
                        container
                        sx={{ border: "0px solid blue", gap: 2, m: 0, p: 0 }}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Grid item>
                          <p
                            style={{
                              border: "1px solid rgba(0,0,0,0.1)",
                              borderRadius: 10,
                              padding: 14,
                              margin: 2,
                            }}
                          >
                            {nozzle.idNozzle}
                          </p>
                        </Grid>
                        <Grid item xs={7}>
                          <TextFieldSelect
                            name={`${index}`}
                            label={`Produto do bico ${nozzle.idNozzle}`}
                            options={optionsProduct} // products
                            value={productValues[index].productDescription}
                            onChange={(event) => {
                              const newProductValues = [...productValues];
                              newProductValues[index] = {
                                idNozzle: nozzle.idNozzle,
                                productDescription: event.target.value,
                              };
                              setProductValues(newProductValues);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>

            <Grid item xs={10}>
              <Grid container>
                <Grid item>
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "500px",
                    }}
                    src={nozzleValidate.image}
                    alt={`Imagem do Grupo ${nozzleValidate.pumpNozzle.idGroup}`}
                  />
                </Grid>

                <Grid item xs={12}>
                  <a
                    href={nozzleValidate.image}
                    style={{
                      textDecoration: "auto",
                      color: "#D91F05",
                      fontWeight: "bold",
                    }}
                  >
                    <Grid
                      container
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        marginTop: "10px",
                        marginBottom: "15px",
                      }}
                    >
                      <Grid item>
                        <div>Baixar Imagem</div>
                      </Grid>
                      <Grid
                        item
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <GetAppIcon
                          style={{
                            width: "20px",
                            marginLeft: "10px",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </a>
                </Grid>
              </Grid>

              <Grid item>
                <FormGroup>
                  {nozzleValidate.status !== STATUSNOZZLE.BLOCK && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label={
                        <TypographySubtitleDescription>
                          Eu gostaria de recusar
                        </TypographySubtitleDescription>
                      }
                    />
                  )}

                  {nozzleValidate.status !== STATUSNOZZLE.BLOCK ? (
                    <TextField
                      multiline
                      rows={2}
                      value={reasonRejected}
                      onChange={handleReasonRejected}
                      placeholder={"Inserir Motivo da Recusa"}
                      InputProps={{
                        style: {
                          fontSize: "16px",
                        },
                      }}
                    />
                  ) : (
                    <>
                      <TypographySubtitle alignText={"left"}>
                        Motivo da recusa
                      </TypographySubtitle>
                      <TextField
                        multiline
                        rows={2}
                        disabled={true}
                        value={nozzleValidate.reject_reason}
                        onChange={handleReasonRejected}
                        InputProps={{
                          style: {
                            fontSize: "16px",
                          },
                        }}
                      />

                      <Typography style={{ paddingTop: "10px" }}>
                        * Para concluir verifique os produtos atribuídos acima.
                      </Typography>
                    </>
                  )}
                </FormGroup>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container sx={{ gap: 2, justifyContent: "center" }}>
                {nozzleValidate.status !== STATUSNOZZLE.BLOCK && (
                  <Tooltip
                    title={
                      <TypographySubtitleDescription>
                        O checkbox precisa estar habilitado e voce deve inserir
                        o motivo da recusa
                      </TypographySubtitleDescription>
                    }
                    open={
                      isHovered && (!checked || reasonRejected.length === 0)
                    }
                    arrow
                  >
                    <span>
                      {loadReject ? (
                        <CircularProgress />
                      ) : (
                        <ButtonDefault
                          style={{
                            backgroundColor: theme.palette.error.main,
                            opacity:
                              checked && reasonRejected.length > 0 ? 1 : 0.5,
                          }}
                          onClick={() =>
                            checked && reasonRejected.length > 0
                              ? handleRejectNozzles()
                              : {}
                          }
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          Recusar
                        </ButtonDefault>
                      )}
                    </span>
                  </Tooltip>
                )}

                <Tooltip
                  title={
                    <TypographySubtitleDescription>
                      Todos os bicos devem estar com seus respectivos produtos
                      atribuidos antes de seguir para aprovação
                    </TypographySubtitleDescription>
                  }
                  open={isHoveredConcluded && conditionIsNotConcluded}
                  arrow
                >
                  <span>
                    {loadApproved ? (
                      <CircularProgress />
                    ) : (
                      <ButtonDefault
                        style={{
                          backgroundColor: "#0BB873",
                          opacity: conditionIsNotConcluded ? 0.5 : 1,
                        }}
                        onClick={() =>
                          conditionIsNotConcluded ? {} : handleApproveNozzles()
                        }
                        onMouseEnter={handleMouseEnterConcluded}
                        onMouseLeave={handleMouseLeaveConcluded}
                      >
                        Concluir
                      </ButtonDefault>
                    )}
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalValidateNozzle;

export const TypographyTitle = ({ children }) => {
  const theme = useTheme();
  return (
    <Typography
      variant={"h1"}
      sx={{
        fontFamily: theme.typography.fontFamily,
        fontSize: { xs: 14, md: 16, lg: 18, xl: 24 },
        fontWeight: 700,
        p: 0.8,
      }}
    >
      {children}
    </Typography>
  );
};

export const TypographySubtitle = ({ children, alignText }) => {
  const theme = useTheme();
  return (
    <Typography
      variant={"body2"}
      sx={{
        fontFamily: theme.typography.fontFamily,
        fontSize: { xs: 10, md: 12, lg: 13, xl: 16 },
        fontWeight: 600,
        p: 0.4,
        textAlign: alignText || "",
      }}
    >
      {children}
    </Typography>
  );
};

export const TypographySubtitleDescription = ({ children }) => {
  const theme = useTheme();
  return (
    <Typography
      variant={"body2"}
      sx={{
        fontFamily: theme.typography.fontFamily,
        fontSize: { xs: 10, md: 12, lg: 13, xl: 14 },
        fontWeight: 400,
        p: 0.4,
      }}
    >
      {children}
    </Typography>
  );
};
