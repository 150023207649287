import "react-datepicker/dist/react-datepicker.css";

import React, { ReactNode, useEffect, useState } from "react";
import DatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from "react-datepicker";

import { makeStyles, Theme } from "@material-ui/core";
import { Grid, IconButton, Typography } from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";

import Button from "@components/Button";

import { compareDatesIgnoringTime, compareEqualHours } from "@utils/index";

import ptBR from "date-fns/locale/pt-BR";

interface ScheduleDataPickerProps
  extends Omit<ReactDatePickerProps, "onChange"> {
  hiddenIcon?: boolean;
  hidden?: boolean;
  variant?: "outlined" | "contained" | "text";
  children?: ReactNode;
  endIcon?: ReactNode;
  allowTimes?: { [time: string]: number };
  allowDates?: string[] | undefined;
  loading?: boolean;
  onChange(
    date: Date | null | [Date | null, Date | null],
    event: React.SyntheticEvent<any> | undefined,
    detectUpdate?: boolean
  ): void;
  onClickSchedule?: () => void;
  onClickCancel?: () => void;
}

const Schedule: React.FC<ScheduleDataPickerProps> = (props) => {
  const {
    children,
    allowTimes,
    loading,
    allowDates,
    onChange,
    selected,
  } = props;

  const classes = useStyles();

  const currentDate = new Date(); // representa o dia atual

  const [isOpen, setIsOpen] = useState<boolean>(!!props.open);

  const handleCalendarClose = () => {
    setIsOpen(false);
    props.onClickCancel && props.onClickCancel();
  };

  const handleInputClick = () => {
    setIsOpen(!isOpen);
  };

  // controle dos dias - Deve retornar true para os dias que devem ser permitidos permitidos e false para os dias bloqueados
  const checkDayAllowed = (date: Date): boolean => {
    return allowDates?.includes(date.toISOString().split("T")[0]) ?? false;
  };

  const checkTimeAllowed = (date: Date): boolean => {
    return allowTimes
      ? checkAllowTimes(allowTimes)?.some((item) =>
          compareEqualHours(item, date)
        ) ?? false
      : false;
  };

  // controle dos horarios <> permite bloquear os horarios nao permitidos
  const filterTimeControl = (date: Date): boolean => {
    // Qualquer horario fora do intervalo definido em allowTimes deve bloquear horario
    const conditionBlockOutRangeTime = !checkAllowTimes(
      allowTimes
    )?.some((item) => compareEqualHours(item, date));

    // se nao for horario permitido deve bloquear horario
    const conditionBlockByTime = !checkAllowTimes(allowTimes);

    // se o horario ja passou, deve bloquear horario
    const conditionExpiredTime = !checkSameTime(date);

    if (
      conditionBlockByTime ||
      conditionBlockOutRangeTime ||
      conditionExpiredTime
    ) {
      return false;
    }

    return true;
  };

  // controle do style dos horarios <> bonus: permite esconder os horarios nao permitidos
  const timeClasseNameCustom = (date: Date): string | null => {
    return loading
      ? classes.itemLoading
      : checkTimeAllowed(date)
      ? classes.itemShow
      : classes.itemHidden;
  };

  const dateClasseNameCustom = (date: Date): string | null => {
    return loading ? classes.itemLoading : checkDayAllowed(date) ? "" : "";
  };

  const checkAllowTimes = (allowTimes): string[] | undefined => {
    if (!allowTimes) return undefined;
    if (Object.keys(allowTimes).length === 0) return undefined;
    return Object.keys(allowTimes);
  };

  const checkSameTime = (dateSelected: Date | null): boolean => {
    //  Verifica se o horário selecionado é anterior ao horário atua
    return dateSelected
      ? dateSelected?.getTime() > currentDate?.getTime()
      : false;
  };

  const onChangeFormat = (
    dateSelected: Date | null,
    event?,
    hasAllowTimeUpdate?: boolean
  ) => {
    event?.preventDefault();

    if (!dateSelected) return null;

    let dateDefault: Date | null = dateSelected;

    const isDifferentDay = compareDatesIgnoringTime(dateSelected, selected);

    // Quando os dias forem diferentes precisa forcar o set do horario para o primeiro horario disponivel
    if (isDifferentDay || hasAllowTimeUpdate) {
      const time = allowTimes
        ? Object.entries(allowTimes).find(([key, value]) => value > 0)?.[0]
        : undefined;
      if (!time) {
        dateDefault = null;
      } else {
        const [hour, minute] = time.split(":").map(Number); // Divide a hora e os minutos
        dateDefault.setHours(hour, minute); // Configura a hora na data selecionada
      }
    }
    return onChange && onChange(dateDefault, event, hasAllowTimeUpdate);
  };

  const CustomHeaderCalendar = (
    params: ReactDatePickerCustomHeaderProps
  ): JSX.Element => {
    const { date, decreaseMonth, increaseMonth } = params;
    return (
      <Grid container sx={{ textAlign: "left", gap: 1.4 }}>
        <Grid item xs={12} sx={{ border: "0px solid red" }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 700, color: "#58595B", pl: { xs: 1.3, lg: 0 } }}
          >
            Agendar
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Grid item>
              <IconButton sx={{ borderRadius: 2 }} onClick={decreaseMonth}>
                <NavigateBeforeOutlinedIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 600,
                  p: 0,
                  m: 0,
                }}
              >
                {date.toLocaleString("pt-BR", {
                  month: "long",
                  year: "numeric",
                })}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton sx={{ borderRadius: 2 }} onClick={increaseMonth}>
                <NavigateNextOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const dateTimeUnavailable = () => {
    if (loading) return;

    const timeBox = document.querySelector(
      ".react-datepicker__time-box"
    ) as HTMLElement;
    const timeList = document.querySelector(
      ".react-datepicker__time-list"
    ) as HTMLElement;
    let message = document.querySelector(".messageUnavailable") as HTMLElement;

    // Verifica se ja existe se nao exitir deve criar o elemento
    if (!message) {
      message = document.createElement("ul");
      message.classList.add(
        "messageUnavailable",
        "react-datepicker__time-list-item"
      );
      message.innerText = "Não existem horários disponíveis";
      message.setAttribute("tabindex", "0");
    }

    if (timeBox) {
      if (allowTimes === undefined || Object.keys(allowTimes).length === 0) {
        if (timeList) timeList.style.display = "none";
        timeBox.style.overflow = "none";
        timeBox.appendChild(message);
      } else {
        if (message.parentElement === timeBox) {
          timeBox.style.overflow = "";
          timeBox.removeChild(message);
        }
      }
    }
  };

  useEffect(() => {
    if (selected) {
      onChangeFormat(selected, undefined, true);
    }

    setTimeout(dateTimeUnavailable, 0);
    controlPopperClassName();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowTimes]);

  const controlPopperClassName = () => {
    return allowTimes === undefined || Object.keys(allowTimes).length === 0
      ? classes.popperRootEmpty
      : classes.popperRoot;
  };

  return (
    <DatePicker
      {...props}
      inline
      showWeekNumbers={false} // NOTE: Desabilita a exibição do número da semana
      onCalendarClose={handleCalendarClose}
      onChange={onChangeFormat}
      onInputClick={handleInputClick}
      calendarClassName={classes.calendar}
      filterDate={checkDayAllowed} // NOTE: Controla dias permitidos(true) e dias proibidos(false)
      filterTime={filterTimeControl} // NOTE: controla horarios permitidos(true) e horarios proibidos(false)
      dateFormat="dd/MM/yyyy - HH:mm"
      showTimeSelect // NOTE: Habilita a seleção de horario
      timeCaption="Hora" // NOTE: Rótulo para a seção de tempo
      timeClassName={timeClasseNameCustom} // NOTE: Aplica logica no css para horarios validos e horarios invalidos
      dayClassName={dateClasseNameCustom} // NOTE: Aplica logica no css para dias validos e dias invalidos
      timeInputLabel={""} // NOTE: Necessario para customizar o label (area customizada)
      showTimeInput // NOTE: (area customizada)
      customTimeInput={<div style={{ display: "none" }}></div>} // NOTE: Necessario para evitar a exibição do input de tempo
      renderCustomHeader={(propsHeader) => (
        <CustomHeaderCalendar {...propsHeader} />
      )}
      customInput={
        <Button
          className="ButtomCustomized"
          sx={{
            display: props.hidden ? "none" : "block",
            "& span > .MuiButton-endIcon": {
              display: props.hiddenIcon ? "none" : "block",
            },
          }}
          variant={props.variant ?? "outlined"}
          endIcon={props.endIcon ?? <CalendarMonthOutlinedIcon />}
        >
          {children ?? "Agendar"}
        </Button>
      }
      locale={ptBR} // Define o idioma para português do Brasil
      placeholderText="Selecione a data e hora" // Texto de espaço reservado
      popperPlacement="top"
      popperClassName={controlPopperClassName()}
    />
  );
};

export default Schedule;

const useStyles = makeStyles((theme: Theme) => ({
  calendar: {
    "& .messageUnavailable": {
      border: "0px solid black !important",
      color: "red",
      position: "relative",
    },
    "& .react-datepicker__triangle": {
      display: "none",
    },
    "& .react-datepicker__month": {
      overflow: "hidden",
      maxHeight: "220px",
      [theme.breakpoints.down("sm")]: {
        maxHeight: "180px",
      },
    },
    "& .MuiTypography-root": {
      fontFamily: "'montserrat'",
    },
    "&.react-datepicker": {
      width: "inherit",
      opacity: 1,
      border: "0px solid red",
      borderRadius: 12,
      fontFamily: "'montserrat'",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "0px",
        padding: 0,
        margin: 0,
        display: "inline-grid",
        width: "85vw",
      },
      [theme.breakpoints.up("lg")]: {},
      [theme.breakpoints.up("xl")]: {},
    },
    "& .react-datepicker__time-container": {
      borderLeft: "1px solid rgba(0,0,0,0.2)",
      width: "inherit",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "16px",
      height: "320px",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        border: "none",
        padding: 0,
        margin: 0,
        marginTop: "8px",
        marginBottom: "8px",
      },
    },
    "& .react-datepicker__time": {
      display: "flex",
      border: "0px solid #000",
      padding: "8px",
      width: "auto",
      height: "inherit",
      zIndex: 1,
      [theme.breakpoints.down("sm")]: {
        width: "96%",
        padding: 0,
        margin: 0,
        height: "inherit",
      },
    },
    "& .react-datepicker__time-box": {
      overflowX: "hidden",
      overflowY: "hidden",
      border: "0px solid #1AF",
      width: "inherit !important",
      height: "inherit",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        padding: "10px 0px",
        minHeight: "100px",
      },
    },
    "& .react-datepicker__header": {
      // esconde o cabecalho
      background: "none",
      [theme.breakpoints.down("sm")]: {
        padding: 0,
        margin: 0,
      },
    },
    "& .react-datepicker__header--time": {
      // esconde o cabecalho
      display: "none !important",
    },
    "& .react-datepicker__time-list::-webkit-scrollbar": {
      width: "8px" /* largura da barra de rolagem */,
    },
    "& .react-datepicker__time-list::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "& .react-datepicker__time-list::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "18px",
    },
    "& .react-datepicker__time-list": {
      display: "grid",
      gridTemplateColumns:
        "repeat(3, 1fr)" /* Define 3 colunas com largura igual */,
      gap: "24px" /* Espaçamento entre os itens (ajuste conforme necessário) */,
      flexWrap:
        "wrap" /* Permite que os itens quebrem para a linha seguinte se necessário */,
      minHeight: "150px",
      minWidth: "240px",
      height: "320px !important",
      [theme.breakpoints.down("sm")]: {
        height: "auto !important",
        maxHeight: "220px",
        minHeight: "0px !important",
        width: "98% !important",
        margin: "4px !important",
      },
    },
    "& .react-datepicker__time-list-item": {
      fontSize: 12,
      border: "1px solid rgba(114, 101, 81, 0.10)",
      borderRadius: 8,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "2px",
      padding: "2px 16px !important",
      height: "30px",
      [theme.breakpoints.down("sm")]: {
        fontSize: 13, // tamanho da fonte dos horarios
        width: "95%",
        padding: "4px 0px !important",
        margin: 0,
      },
    },
    "& .react-datepicker__time-list-item--selected": {
      backgroundColor: "#D91F05 !important",
      borderRadius: 8,
    },
    "& .react-datepicker__current-month": {
      textTransform: "capitalize",
      fontSize: 14,
      padding: 10,
    },
    "& .react-datepicker__day": {
      fontSize: 13,
      padding: 4,
      margin: 8,
      paddingRight: 16,
      [theme.breakpoints.down("sm")]: {
        border: "0px solid red",
        fontSize: 14,
        margin: 10,
        marginTop: 8,
        marginBottom: 10,
        padding: 0,
      },
    },
    "& .react-datepicker__day-name": {
      fontFamily: "'montserrat'",
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: 9,
      padding: 5,
      margin: 3,
      border: "0px solid red",
      width: "auto",
      [theme.breakpoints.down("sm")]: {
        margin: 5,
      },
    },
    "& .react-datepicker__input-time-container": {
      // dia e horario selecionado
      border: "0px solid #1AF",
      position: "absolute",
      top: "345px",
      left: "0px",
      width: "100%",
      margin: 0,
      [theme.breakpoints.down("sm")]: {
        top: "545px",
        left: "0px",
        border: "0px solid blue",
        display: "flex",
        justifyContent: "center",
      },
    },
    "& .react-datepicker-time__input-container": {
      [theme.breakpoints.down("sm")]: {
        width: "inherit",
      },
    },
    "& .react-datepicker-time__input-container .react-datepicker-time__input": {
      margin: 0,
    },
    "& .react-datepicker-time__input": {
      [theme.breakpoints.down("sm")]: {
        width: "inherit !important",
        margin: "0px !important",
        display: "flex",
      },
    },
    "& .react-datepicker__day--today": {
      fontWeight: 400,
      color: "#000",
      backgroundColor: "unset",
    },
    "& .react-datepicker__day--keyboard-selected": {
      backgroundColor: "unset",
    },
    "& .react-datepicker__day--selected": {
      fontWeight: 700,
      color: "#E56350",
      backgroundColor: "#fff",
    },
  },
  popperContainer: {
    display: "none",
  },
  popperRoot: {
    border: "0px solid red",
    paddingBottom: "100px !important",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      transform: "none !important",
      inset: "30px 6px 10px 6px !important",
    },
  },
  popperRootEmpty: {
    paddingBottom: "55px !important",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "18px !important",
    },
  },
  itemHidden: {
    display: "none !important",
  },
  itemShow: {
    display: "flex !important",
  },
  itemLoading: {
    pointerEvents: "none",
    opacity: 0.8,
    color: theme.palette.primary.light,
    fontSize: 12,
    border: "1px solid rgba(114, 101, 81, 0.10)",
    position: "relative",
    overflow: "hidden",
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(to right, transparent 0%, #f0f0f0 50%, transparent 100%)",
      animation: "$loadingAnimation 1.5s infinite linear",
    },
  },
  "@keyframes loadingAnimation": {
    from: {
      left: "-100%",
    },
    to: {
      left: "100%",
    },
  },
}));
