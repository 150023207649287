import { useUserState } from "@context/UserContext";
import {
  IFilterTitlesDTO,
  ITitlesResponseDTO,
} from "@modules/titles/dtos/IQueryTitlesDataDTO";

import { IUploadTitleImageDTO } from "@modules/titles/dtos/IUploadTitleImageDTO";
import { IClient } from "@utils/interfaces";
import { endOfMonth, startOfMonth } from "date-fns";
import React, { createContext, useCallback, useContext, useState } from "react";
import { FILTER_BY_TYPE, SITUATION_TYPE } from "./utils/ENUMS_TITLES";

interface IOpenModalUpload {
  openModalUpload: boolean;
  data: Omit<IUploadTitleImageDTO, "file">;
}

interface Image {
  link: string;
  id: string;
}

interface FormQueryTitleContext {
  loading: boolean;
  loadingQuery: boolean;
  dataCustomer: IClient | null;
  dataQuery: ITitlesResponseDTO;
  searchFirstTime: boolean;
  openModalUpload: IOpenModalUpload;
  image: Image;
  openModalImage: boolean;
  setLoading(loading: boolean): void;
  setLoadingQuery(loading: boolean): void;
  setDataCustomer(data: IClient): void;
  setDataQuery(data: ITitlesResponseDTO): void;
  setSearchFirstTime(value: boolean): void;
  setOpenModalUpload(value: IOpenModalUpload): void;
  setImage(value: Image): void;
  setOpenModalImage(value: boolean): void;
  filterTitles: IFilterTitlesDTO;
  setFilterTitles(filter: IFilterTitlesDTO): void;
  isCnpj: string[];
  setCnpj: (cnpj: string[]) => void;
  isSituation:
    | SITUATION_TYPE.ALL
    | SITUATION_TYPE.OPEN
    | SITUATION_TYPE.CLOSED
    | undefined;
  setSituation: (
    situation:
      | SITUATION_TYPE.ALL
      | SITUATION_TYPE.OPEN
      | SITUATION_TYPE.CLOSED
      | undefined
  ) => void;
  isFilterBy: FILTER_BY_TYPE.DUE_DATE | FILTER_BY_TYPE.DOCUMENT_DATE;
  setFilterBy: (
    filterBy: FILTER_BY_TYPE.DUE_DATE | FILTER_BY_TYPE.DOCUMENT_DATE
  ) => void;
  rangeDate: Date[];
  setRangeDate: React.Dispatch<React.SetStateAction<Date[]>>;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FormQueryTitleContext = createContext<FormQueryTitleContext>(
  {} as FormQueryTitleContext
);

const FormQueryTitleProvider: React.FC = ({ children }) => {
  const [loading, setLoadingState] = useState(false);
  const [loadingQuery, setLoadingQueryState] = useState(false);
  const [dataCustomer, setDataCustomer] = useState<IClient | null>(null);
  const [dataQuery, setDataQuery] = useState<ITitlesResponseDTO>({
    content: [],
    total: 0,
    page: 1,
  });

  const [searchFirstTime, setSearchFirstTime] = useState(true);
  const [openModalImage, setOpenModalImage] = useState(false);
  const [openModalUpload, setOpenModalUpload] = useState<IOpenModalUpload>({
    data: { year: "", atemID: "", CNPJ: "", invoiceID: "" },
    openModalUpload: false,
  });
  const [image, setImage] = useState<Image>({} as Image);

  const setLoading = useCallback((loading: boolean) => {
    setLoadingState(loading);
  }, []);

  const setLoadingQuery = useCallback((loading: boolean) => {
    setLoadingQueryState(loading);
  }, []);
  const userState = useUserState();
  const [filterTitles, setFilterTitles] = useState<IFilterTitlesDTO>({
    CNPJ: [userState.listCNPJ[0].CNPJ],
    beginDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    filterBy: FILTER_BY_TYPE.DUE_DATE,
    situation: SITUATION_TYPE.ALL,
  });

  const [isCnpj, setCnpj] = useState<string[]>([]);
  const [rangeDate, setRangeDate] = useState<Date[]>([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ]);
  const [isFilterBy, setFilterBy] = useState<
    FILTER_BY_TYPE.DOCUMENT_DATE | FILTER_BY_TYPE.DUE_DATE
  >(FILTER_BY_TYPE.DUE_DATE);
  const [isSituation, setSituation] = useState<
    SITUATION_TYPE.ALL | SITUATION_TYPE.OPEN | SITUATION_TYPE.CLOSED | undefined
  >(SITUATION_TYPE.ALL);

  return (
    <FormQueryTitleContext.Provider
      value={{
        loading,
        dataCustomer,
        loadingQuery,
        dataQuery,
        searchFirstTime,
        openModalUpload,
        image,
        openModalImage,
        setLoading,
        setLoadingQuery,
        setDataCustomer,
        setDataQuery,
        setSearchFirstTime,
        setOpenModalUpload,
        setImage,
        setOpenModalImage,
        isCnpj,
        setCnpj,
        rangeDate,
        setRangeDate,
        isFilterBy,
        setFilterBy,
        isSituation,
        setSituation,
        filterTitles,
        setFilterTitles,
      }}
    >
      {children}
    </FormQueryTitleContext.Provider>
  );
};

const useFormQueryTitle = (): FormQueryTitleContext => {
  const context = useContext(FormQueryTitleContext);
  if (!Object.values(context).length) {
    throw new Error(
      "useFormQueryTitle deve ser utilizado dentro de um FormQueryTitleContext"
    );
  }
  return context;
};

export { useFormQueryTitle, FormQueryTitleProvider };


