import * as Yup from "yup";

const NewRequestValidationSchema = Yup.object().shape({
  CNPJ: Yup.string().required("Selecione um CNPJ"),
  address: Yup.object().nullable().required("Selecione um endereço"),
  freightType: Yup.string().oneOf(["CIF", "FOB"]).required(),
  comments: Yup.string().lowercase().trim().default(""),
  filialID: Yup.string().required("Selecione uma filial"),
  withdrawBasisID: Yup.string().required("Selecione uma base de retirada"),
  products: Yup.array().of(
    Yup.object().shape({
      quantity: Yup.number()
        .min(1, "O valor deve ser maior que zero")
        .typeError("Informe um valor válido")
        .required("Selecione uma quantidade"),
      deadlinePayment: Yup.number()
        .nullable()
        .required("Selecione o prazo de pagamento"),
    })
  ),
});

export default NewRequestValidationSchema;
