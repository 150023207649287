import React from "react";
import { Link as LinkRouter } from "react-router-dom";

import {
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";

import { IGetCardTitleDTO } from "@modules/cardTitles/dtos/IGetCardTitleDTO";
import { capitalizeFirstLetterFullString, formatCurrency } from "@utils/index";
import ROUTES from "@config/routes";

interface Props {
  children?: React.ReactNode;
  cards: IGetCardTitleDTO[];
  title: string;
}

const CardTitleMobile: React.FC<Props> = ({ children, ...props }) => {
  const cardSize = 230;
  const theme = useTheme();
  const classes = useStyles();

  const docStatus = {
    CLOSE: {
      text: "Pago",
      color: theme.palette.success.light, // light: "#0BB873",
    },
    EXPIRED: {
      text: "Vencido",
      color: theme.palette.error.light, // light: "#F97A91",
    },
    NOT_EXPIRED: {
      text: "A Vencer",
      color: theme.palette.secondary.light, // light: "#FFB800",
    },
    DEFAULT: {
      text: "",
      color: "#ccc",
    },
  };

  const scrollRef = React.useRef<HTMLDivElement>(null);

  const handlepreviousTitle = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft -= cardSize; // move 230 pixels para a direita
    }
  };

  const handleNextTitle = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += cardSize; // move 230 pixels para a direita
    }
  };

  return (
    <div style={{ overflow: "hidden", margin: "0px 14px 12px 14px" }}>
      <Typography
        variant="subtitle1"
        style={{ fontWeight: 700, margin: "12px 0px 12px 0px" }}
      >
        {props.title}
      </Typography>
      {props.cards.length > 0 ? (
        <Grid>
          <Grid
            container
            ref={scrollRef}
            spacing={1}
            className={classes.cardcontainer}
            style={{
              flexWrap: "initial",
              overflowX: "auto",
              paddingRight: 0,
              scrollBehavior: "smooth",
            }}
          >
            {props.cards.map((card, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <Card style={{ width: cardSize, marginRight: 8 }}>
                    <CardContent
                      style={{ padding: " 16px 16px 24px 16px;", margin: 0 }}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle1"
                            style={{
                              color:
                                docStatus[card.statusDoc ?? "DEFAULT"].color,
                              fontWeight: 700,
                              marginBottom: 16,
                            }}
                          >
                            {docStatus[card.statusDoc ?? "DEFAULT"].text}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            style={{
                              opacity: 0.7,
                              fontWeight: 700,
                              color: theme.palette.text.primary,
                            }}
                          >
                            {formatCurrency(card.value)}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          {" "}
                          {/* Uma linha em baixo do campo de R$XXX,XXXX */}
                          <div
                            style={{
                              background:
                                docStatus[card.statusDoc ?? "DEFAULT"].color,
                              marginTop: 4,
                              marginBottom: 22,
                              width: 100,
                              height: 4,
                              borderRadius: "0px 0px 4px 4px",
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            variant="caption"
                            style={{
                              color: theme.palette.text.primary,
                              // fontFamily: "DM Sans",
                              fontWeight: 700,
                              opacity: 0.7,
                            }}
                          >
                            {capitalizeFirstLetterFullString(
                              card.customerName
                                ? card.customerName.toLowerCase()
                                : ""
                            )}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="caption">
                            Título {card.reference}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                border: "0px solid blue",
                padding: "12px 0px 12px 0px",
              }}
            >
              <Grid item style={{ padding: 0, border: "0px solid red" }}>
                <Button
                  style={{ padding: 0 }}
                  size="small"
                  onClick={handlepreviousTitle}
                >
                  <Typography
                    variant="caption"
                    style={{
                      color: theme.palette.text.primary,
                      textTransform: "capitalize",
                      fontWeight: 600,
                      opacity: 0.4,
                    }}
                  >
                    Anterior
                  </Typography>
                </Button>
              </Grid>

              <Grid item style={{ padding: 0, border: "0px solid red" }}>
                <Button
                  style={{ padding: 0 }}
                  size="small"
                  onClick={handleNextTitle}
                >
                  <Typography
                    variant="caption"
                    style={{
                      color: theme.palette.primary.main,
                      textTransform: "capitalize",
                      fontWeight: 600,
                      // marginRight: 12,
                    }}
                  >
                    Próximo
                  </Typography>
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ padding: "0px 0px 12px 0px" }}>
              <Link
                component={LinkRouter}
                to={ROUTES.USER_ROUTES.APPOINTMENT_TITLE}
                underline="none"
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    color: theme.palette.primary.main,
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Ver todos os titulos
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container style={{ padding: 2 }}>
          <Grid item>
            <Typography
              variant="subtitle2"
              style={{
                color: theme.palette.text.primary,
                fontWeight: 600,
                textAlign: "center",
                opacity: 0.7,
                marginLeft: 12,
              }}
            >
              Você não possui títulos em aberto.
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default CardTitleMobile;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardcontainer: {
      "&::-webkit-scrollbar": {
        height: "0.5rem",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
      },
    },
  })
);
