import Layout from "@components/Layout";
import Title from "@components/Title";
import {
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";

import { Theme } from "@mui/material";

import TableDriverCustomer from "./TableDriverCustomer";

export const ITEMS_PER_PAGE = 10;

const DriverAndCustomer: React.FC = () => {
  const [loadingUsers] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [showFilter, setShowFilter] = useState(false);
  const [loadingQuery] = useState(false);

  const openMenuFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <Layout disableToolbar={true} disableToolbarMobile={true}>
      <Title title="Motorista x Cliente" />

      {loadingUsers && <LinearProgress color="secondary" />}
      <Grid
        container
        alignItems="center"
        spacing={2}
        style={{
          padding: "1rem 5rem",
          borderRadius: "4px",
        }}
      >
        <Grid item xs={12} container justifyContent="flex-end">
          <Button
            style={{
              margin: "2px",
              background: "none",
              color: "#D91F05",
              border: "none",
              boxShadow: "none",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              fontSize: isMobile ? "1.4rem" : "2.0rem",
              textTransform: "none",
              padding: "0px",
            }}
            disabled={false}
            variant="contained"
            color="primary"
            onClick={openMenuFilter}
          >
            {loadingQuery ? (
              <>
                Filtrando
                <CircularProgress
                  color="primary"
                  style={{
                    marginLeft: "10px",
                    height: "2.0rem",
                    width: "2.0rem",
                  }}
                />
              </>
            ) : (
              "Filtrar"
            )}
          </Button>
        </Grid>
        <TableDriverCustomer />
      </Grid>
    </Layout>
  );
};

export default DriverAndCustomer;

export const useStylesDriverCustomer = makeStyles((theme: Theme) =>
  createStyles({
    DescriptionChangeDeliveryCalendar: {
      fontFamily: "Montserrat",
      color: "#3E3D3D",
      textAlign: "center",
      opacity: "0.3",
    },
    DescriptionPermittedTimeDelivery: {
      fontSize: "1.4rem",
      paddingTop: "10px",
      fontFamily: "Montserrat",
      color: "#3E3D3D",
      textAlign: "center",
      fontWeight: "bold",
    },
    SpanSubTitleDelivery: {
      color: "#D91F05",
      fontWeight: "bold",
    },
    DescriptionSucessDelivery: {
      fontSize: "1.6rem",
      paddingTop: "10px",
      fontFamily: "Montserrat",
      color: "#3E3D3D",
      textAlign: "center",
      [theme.breakpoints.only("xs")]: {
        fontSize: "1.3rem",
      },
    },
    TitleOrder: {
      color: "#3E3D3D",
      fontWeight: "bold",
      fontFamily: "Montserrat",
      fontSize: "3rem",
      paddingBottom: "20px",
    },
    TableContainer: {
      width: "100%",
      boxShadow: "none",
    },
    Table: {
      borderCollapse: "separate",
    },
    TableRow: {
      background: "#fff",
    },
    TableCell: {
      padding: "6px 16px 6px 16px",
      marginBottom: "20px",
    },
    TableCellHead: {
      fontWeight: "bold",
      whiteSpace: "nowrap",
      fontFamily: "DM Sans",
      background: "#fff",
      "&:first-child": {
        paddingLeft: "2.5rem",
        borderBottomLeftRadius: "0.6rem",
        borderTopLeftRadius: "0.6rem",
      },
      "&:last-child": {
        borderBottomRightRadius: "0.6rem",
        borderTopRightRadius: "0.6rem",
      },
    },
    buttonWithIcon: {
      fontWeight: 600,
      color: "#D91F05",
      cursor: "pointer",
      border: "none",
      boxShadow: "none",
    },
    paperTable: {
      background: "#F5F5F5",
      overflowX: "scroll",

      "&::-webkit-scrollbar": {
        background: theme.palette.grey[300],
        height: "0.7rem",
        borderRadius: ".4rem",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.grey["900"],
        borderRadius: ".4rem",
      },
    },
    iconImg: {
      filter:
        "invert(45%) sepia(0%) saturate(1244%) hue-rotate(218deg) brightness(85%) contrast(92%);",
    },
    paperSituation: {
      padding: "0 1rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "bold",
      fontFamily: "Montserrat",
    },
    paperSituationSuccess: {
      color: "#0BB873",
      fontWeight: "bold",
      fontFamily: "Montserrat",
    },
    paperSituationError: {
      color: "#F97A91",
      fontWeight: "bold",
      fontFamily: "Montserrat",
    },
    TilteFooter: {
      fontFamily: "Montserrat",
      color: "#3E3D3D",
    },
    CountItensFooter: {
      fontFamily: "Montserrat",
      color: "#FFB03A",
    },
    SubTilteFooter: {
      padding: 0,
      margin: 0,
      opacity: 0.6,
      fontFamily: "Montserrat",
      color: "#3E3D3D",
    },

    TableCellFromData: {
      background: "#fff",
      marginBottom: "10px",
      borderBottom: "none",
      fontFamily: "DM Sans",
      "&:first-child": {
        paddingLeft: "2.5rem",
        borderBottomLeftRadius: "0.6rem",
        borderTopLeftRadius: "0.6rem",
      },
      "&:last-child": {
        borderBottomRightRadius: "0.6rem",
        borderTopRightRadius: "0.6rem",
      },
    },
  })
);
