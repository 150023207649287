import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IGetTaxReceiptWithActionsDTO } from "../dtos/IGetTaxReceiptWithActionsDTO";
import { IGetTotalTaxReceiptWithActionsService } from "../models/IGetTotalTaxReceiptWithActionsService";

@injectable()
export class GetTotalTaxReceiptWithActionsService
  implements IGetTotalTaxReceiptWithActionsService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  execute(data: IGetTaxReceiptWithActionsDTO): Promise<number> {
    return this.httpInstance.get<number>(
      `/dashboard-posh/taxReceipt?action=${data.action}&companies_id=${data.companies_id}&from=${data.from}&to=${data.to}`
    );
  }
}
