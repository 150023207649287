import React, { useEffect, useState } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import iconLupa from "../../assets/lupa.svg";
import logoAtem from "../../assets/logo-atem.svg";
import iconMenu from "../../assets/hamburguer_menu.svg";
import ROUTES from "../../config/routes";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ButtonProps,
  Button,
  Grid,
  Box,
} from "@material-ui/core";
import { useUserDispatch, useUserState } from "@context/UserContext";
import { Link, useHistory, useLocation } from "react-router-dom";
import clsx from "clsx";
import { useHomeUserContext } from "@context/HomeUserContext";
import Dropdown from "@components/Dropdown";
import { capitalizeFirstLetterFullString, maskCNPJ } from "@utils/index";
import {
  addToLocalStorageCNPJ,
  getCNPJinLocalStorage,
} from "@utils/localStorageUtils";
import { goToLogout } from "../../utils/index";
import { SIDEBAR_ADMIN_FLAGS } from "@utils/featureFlags";
import { useFlags } from "flagsmith/react";
import { ListDrawerUser } from "@components/Sidebar";
import SkeletonHeader from "@components/Header/SkeletonHeader/SkeletonHeader";
import Notification from "@components/Notification";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  containerIconMenuOpened: {
    width: "50%",
    textAlign: "left",
    marginLeft: "25px",
  },
  containerLogoMenuOpened: {
    width: "50%",
    textAlign: "right",
    marginRight: theme.spacing(1),
  },
  appBar: {
    backgroundColor: "#F5F5F5",
    boxShadow: "none",
    borderBottom: "1px solid rgba(114, 101, 81, 0.1)",
  },
  toolbar: {
    borderRadius: "8px 8px 8px 8px",
  },
  textMenuItem: {
    fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13.6px",
    lineHeight: "21px",
    color: "#3E3D3D",
    opacity: 0.7,
    marginLeft: "25px",
  },
  textMenuItemSelected: {
    fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13.6px",
    lineHeight: "21px",
    color: "#D91F05",
    marginLeft: "25px",
  },
  listItemSelected: {
    fontWeight: 500,
    fontSize: "16px",
    backgroundColor: `transparent !important`,
    color: "#D91F05",
    "& svg": {},
    "& img": {
      filter:
        "invert(17%) sepia(47%) saturate(5421%) hue-rotate(350deg) brightness(88%) contrast(110%)",
    },
  },
  listItem: {
    paddingLeft: 0,
    fontSize: "1.6rem",
    color: theme.palette.getContrastText(theme.palette.background.paper),
    marginBottom: "1.4rem",
    borderRadius: "0.5rem",
    "& img": {
      filter:
        "invert(45%) sepia(0%) saturate(1244%) hue-rotate(218deg) brightness(85%) contrast(92%)",
    },
  },
  listItemHover: {
    "&:hover": {
      backgroundColor: theme.palette.primaryLight.main,
      color: theme.palette.primary.main,
      "& img": {
        filter:
          "invert(17%) sepia(47%) saturate(5421%) hue-rotate(350deg) brightness(88%) contrast(110%)",
      },
      "& svg": {
        color: theme.palette.primary.main,
      },
    },
  },
  borderMenuNormal: {
    width: "5px",
    height: "25px",
    backgroundColor: "transparent",
    borderRadius: "8px",
    marginRight: "30px",
  },
  small: {
    width: "24px",
    height: "24px",
  },
}));

const ListDrawerAdmin: React.FC = () => {
  const location = useLocation();
  const classes = useStyles();
  const userDispatch = useUserDispatch();

  const menuListAdmin = [
    {
      text: "Home Admin",
      link: ROUTES.ADMIN_ROUTES.HOME,
      flag: "admin_sidebar_home",
    },
    {
      text: "Criar Usuário",
      link: ROUTES.ADMIN_ROUTES.NEW_USER,
      flag: "admin_sidebar_create_new_user",
    },
    {
      text: "Listar Usuários",
      link: ROUTES.ADMIN_ROUTES.LIST_USERS,
      flag: "admin_sidebar_list_user",
    },
    {
      text: "Consultar Comprovantes",
      link: ROUTES.ADMIN_ROUTES.LIST_INVOICES_IMAGES,
      flag: "admin_sidebar_consult_check_receipts",
    },
    {
      text: "Enviar Comunicado",
      link: ROUTES.ADMIN_ROUTES.ALL_COMMUNICATION,
      flag: "admin_sidebar_hide_send_communique",
    },
    {
      text: "Bloqueio de produtos",
      link: ROUTES.ADMIN_ROUTES.PRODUCT_BLOCK,
      flag: "admin_sidebar_block_products",
    },
    {
      text: "Fale conosco",
      link: ROUTES.ADMIN_ROUTES.RECLAMATION_ANALYSIS,
      flag: "admin_sidebar_contact_us",
    },
    {
      text: "Motorista vs Cliente",
      link: ROUTES.ADMIN_ROUTES.RECLAMATION_ANALYSIS,
      flag: "admin_sidebar_driver_and_customer",
    },
    {
      text: "Ir para modo Usuário",
      link: ROUTES.USER_ROUTES.HOME,
      onClick: () => {
        userDispatch({ type: "TOGGLE_ADMIN" });
      },
      flag: "admin_sidebar_redirect_modo_user",
    },
  ];

  const [menuListAdminSideBar, setListMenuAdmin] = useState(menuListAdmin);
  const flags_modo_admin = useFlags(SIDEBAR_ADMIN_FLAGS);

  useEffect(() => {
    const filteredMenusAdmin = menuListAdminSideBar.filter(
      (menuEntry) => flags_modo_admin[menuEntry.flag].enabled
    );

    setListMenuAdmin(filteredMenusAdmin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <List>
      {menuListAdminSideBar.map((item, index) => (
        <ListItem
          key={index}
          button
          className={clsx(classes.listItem)}
          classes={{
            selected: classes.listItemSelected,
            button: classes.listItemHover,
          }}
          selected={location.pathname === item.link}
          component={Link}
          to={item.link}
          onClick={item.onClick}
        >
          <Typography
            className={
              location.pathname === item.link
                ? classes.textMenuItemSelected
                : classes.textMenuItem
            }
          >
            {item.text}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};

const HeaderMobile: React.FC<{ invisibleToolbar?: boolean }> = ({
  invisibleToolbar,
}) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const userState = useUserState();
  const {
    documentList,
    setDocumentList,
    CNPJDetailsLoad,
    CNPJDetails,
    handleFecthCNPJDetails,
  } = useHomeUserContext();
  const history = useHistory();
  const [, setIsInvisible] = useState<Boolean>();
  const SELECTED_CNPJ_STORAGE = getCNPJinLocalStorage("cnpjSelected");

  const [cnpjSelected, setCNPJSelected] = useState(
    SELECTED_CNPJ_STORAGE ?? documentList.find((item) => item.isSelected)?.cnpj
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const routesToHide = [
    ROUTES.USER_ROUTES.MY_ORDERS,
    ROUTES.USER_ROUTES.NEW_ORDER,
    ROUTES.USER_ROUTES.APPOINTMENT_TITLE,
    ROUTES.USER_ROUTES.APPOINTMENT_INVOICES,
    ROUTES.USER_ROUTES.APPOINTMENT_PRICE,
    ROUTES.USER_ROUTES.HANDOUTS,
    ROUTES.USER_ROUTES.RECLAMATION,
    ROUTES.USER_ROUTES.DASH_POSH,
    ROUTES.USER_ROUTES.CHANGE_PASSWORD,
    ROUTES.USER_ROUTES.SIMULATE_ORDER,
    ROUTES.ADMIN_ROUTES.NEW_USER,
    ROUTES.ADMIN_ROUTES.LIST_USERS,
    ROUTES.ADMIN_ROUTES.LIST_INVOICES_IMAGES,
    ROUTES.ADMIN_ROUTES.ADD_COMMUNICATION,
    ROUTES.ADMIN_ROUTES.ADD_IMAGES_LOGIN,
    ROUTES.ADMIN_ROUTES.HANDOUTS,
    ROUTES.ADMIN_ROUTES.HOME,
  ];

  useEffect(() => {
    setIsInvisible(routesToHide.includes(history.location.pathname));
  }, [history.location.pathname, routesToHide]);

  useEffect(() => {
    if (
      cnpjSelected !== documentList.find((item) => item.isSelected)?.cnpj ||
      CNPJDetails === null
    ) {
      const newcnpjSelected = documentList.find((item) => item.isSelected)
        ?.cnpj;
      setCNPJSelected(newcnpjSelected ?? documentList[0]?.cnpj);
      handleFecthCNPJDetails(newcnpjSelected ?? documentList[0]?.cnpj);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentList]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <img alt={"iconMenu"} src={iconMenu} width="20px" height="16px" />
          </IconButton>
          <Grid container></Grid>
          {!invisibleToolbar ? (
            <>
              <CustomButton variant="contained">
                <Notification className={"iconBell"} />
              </CustomButton>
              <CustomButton variant="contained">
                <img alt={"iconLupa"} src={iconLupa} width="20px" />
              </CustomButton>
              {CNPJDetailsLoad ? (
                <SkeletonHeader />
              ) : (
                <Dropdown
                  option={
                    SELECTED_CNPJ_STORAGE
                      ? documentList.filter(
                          (item) => item.cnpj === SELECTED_CNPJ_STORAGE
                        )[0]
                      : documentList.filter((item) => item.isSelected)[0]
                  }
                  options={documentList}
                  onChange={(option) => {
                    const newDocumentList = documentList.map((item) => ({
                      ...item,
                      isSelected: option === item,
                    }));
                    addToLocalStorageCNPJ("cnpjSelected", option.cnpj);
                    setDocumentList(newDocumentList);
                  }}
                  getHeaderLabel={(option) =>
                    capitalizeFirstLetterFullString(option.label.toLowerCase())
                  }
                  getSelectItemLabel={(option) =>
                    capitalizeFirstLetterFullString(option.label.toLowerCase())
                  }
                  getHeaderSubLabel={(option) => maskCNPJ(option.cnpj) ?? ""}
                  getSelectItemSubLabel={(option) =>
                    maskCNPJ(option.cnpj) ?? ""
                  }
                />
              )}
            </>
          ) : (
            <></>
          )}
        </Toolbar>
      </AppBar>

      <Drawer open={drawerOpen} onClose={toggleDrawer}>
        <div
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
          className={classes.toolbar}
        >
          <List>
            <ListItem
              style={{
                alignItems: "center",
                display: "flex",
                textAlign: "center",
                width: "100%",
                margin: 0,
                padding: 0,
              }}
            >
              <Box className={classes.containerIconMenuOpened}>
                <img
                  alt={"iconMenu"}
                  src={iconMenu}
                  width="20px"
                  height="16px"
                />
              </Box>
              <Box className={classes.containerLogoMenuOpened}>
                <img alt={"logoAtem"} src={logoAtem} width={37} height={28} />
              </Box>
            </ListItem>

            {userState.adminMode ? <ListDrawerAdmin /> : <ListDrawerUser />}

            <ListItem
              button
              className={clsx(classes.listItem)}
              classes={{
                selected: classes.listItemSelected,
                button: classes.listItemHover,
              }}
              component="a"
              onClick={() => goToLogout()}
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "-7px",
              }}
            >
              <Typography className={classes.textMenuItem}>Sair</Typography>
            </ListItem>
          </List>
        </div>
      </Drawer>
    </div>
  );
};

export default HeaderMobile;

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  boxShadow: "none",
  maxWidth: "36px",
  maxHeight: "40px",
  minWidth: "36px",
  minHeight: "40px",
  paddingLeft: 0,
  paddingRight: 0,
  backgroundColor: "#f5f5f5",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
  marginRight: "24px",
}));
