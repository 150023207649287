import Layout from "@components/Layout";
import {
  createStyles,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { AppointmentPriceProvider } from "./AppointmentPriceContext";
import { FormQuery } from "./FormQuery";
import { FormQueryPricesValidationSchema } from "./FormQueryPricesValidationSchema";
import { IQuery } from "./interface";
import { TablePrices } from "./TablePrices";

const useStyles = makeStyles(() =>
  createStyles({
    formTitle: {
      fontWeight: "bold",
      marginBottom: "2rem",
      color: "#3E3D3D",
    },
  })
);

const PageContainer: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const initialValues: IQuery = {
    CNPJ: "",
    address: null,
    filialID: "",
    withdrawBasisID: "",
    withdrawBasisBranchName: "",
    freightType: "CIF",
  };

  return (
    <Layout>
      <Grid
        item
        xs={12}
        style={{
          margin: isMobile ? 0 : "0 7rem",
          width: isMobile ? "100%" : "",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <Typography
          variant={isMobile ? "subtitle1" : "h5"}
          align="left"
          className={classes.formTitle}
        >
          Preços de produtos Atem
        </Typography>
      </Grid>

      <div
        style={{
          margin: isMobile ? 0 : "0 7rem",
          width: isMobile ? "100%" : "",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={FormQueryPricesValidationSchema}
          onSubmit={async (values) => {}}
        >
          <Form>
            <FormQuery />
            <TablePrices />
          </Form>
        </Formik>
      </div>
    </Layout>
  );
};

const AppointmentPrice: React.FC = () => {
  return (
    <AppointmentPriceProvider>
      <PageContainer />
    </AppointmentPriceProvider>
  );
};

AppointmentPrice.displayName = "AppointmentPrice";

export { AppointmentPrice };
