import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { getValueInLocalStorage } from "@utils/localStorageUtils";
import { ISendReclamationClose } from "../dtos/IDataOneReclamationUser";
import { ISendReclamationCloseService } from "../models/ISendReclamationCloseService";

@injectable()
export class SendReclamationCloseService
  implements ISendReclamationCloseService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(actionTaken: ISendReclamationClose) {
    return await this.httpInstance.post<ISendReclamationClose>(
      "/reclamation/close/" + getValueInLocalStorage("reclamationClicked"),
      actionTaken
    );
  }
}
