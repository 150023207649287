import { Table, TableCell, TableRow } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

interface ISkeletonTableInvoicesImagesProps {
  rows?: number;
  cols?: number;
}

const SkeletonTableInvoicesImages: React.FC<ISkeletonTableInvoicesImagesProps> = ({
  ...props
}) => {
  const rows = 11;
  const cols = 7;

  const generateSkeletonRow = (key) => {
    return (
      <>
        <TableRow key={key}>
          {[...Array(cols).keys()].map((col) => (
            <TableCell key={col} height={"40px"}>
              <Skeleton variant="rect" width={"141px"} height={"40px"} />
            </TableCell>
          ))}
        </TableRow>
      </>
    );
  };

  return (
    <div style={{ padding: "20px" }}>
      <Table>
        <tbody>
          {[...Array(rows).keys()].map((row) => generateSkeletonRow(row))}
        </tbody>
      </Table>
    </div>
  );
};

export default SkeletonTableInvoicesImages;
