import React from "react";
import DatePicker from "react-datepicker";
import { useFormikContext } from "formik";

import moment from "moment";
import { getMonth, getYear } from "date-fns";

import { ArrowLeftIcon, ArrowRightIcon, CalendarIcon } from "@components/Icons";
import {
  FormGroup,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";

import "react-datepicker/dist/react-datepicker.css";
import { Grid } from "@mui/material";

const monthsBR = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const daysBR = ["Seg", "Ter", "Qua", "Qui", "Sex", "Sab", "Dom"];

const ptbr: Locale = {
  localize: {
    ordinalNumber: (...args: Array<any>) => "",
    era: (...args: Array<any>) => "",
    quarter: (...args: Array<any>) => "",
    day: (n: any) => daysBR[n],
    month: (n: any) => monthsBR[n],
    dayPeriod: (...args: Array<any>) => "",
  },
  formatLong: {
    date: () => "dd/mm/yyyy",
    time: (...args: Array<any>) => "",
    dateTime: (...args: Array<any>) => "",
  },
};

interface CalendarProps {
  name: string;
  label: string;
  value: Date | null;
  error?: boolean;
  helperText?: string | boolean;
  onChange: (e: string | React.ChangeEvent<any>) => void;
  onBlur: (e: React.FocusEvent<any>) => void;
}

const Calendar: React.FC<CalendarProps> = ({ ...props }) => {
  const theme = useTheme();
  const { setFieldValue } = useFormikContext();
  const classes = useStyles();

  const [dateSelected, setDateSelected] = React.useState<Date | null>();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleChange = (date: Date) => {
    setDateSelected(date);
    setFieldValue(props.name, date);
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const dateFormat = (valueDate: Date | null) => {
    const DatFormat = moment(valueDate).format("DD/MM/YYYY");
    return `${DatFormat}`;
  };

  function range(start: number, end: number) {
    return Array.from({ length: end - start }, (v, k) => k + start);
  }

  const years = range(1920, getYear(new Date()) + 1).sort((a, b) => b - a);

  const CustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    changeYear,
    changeMonth,
  }) => (
    <Grid
      container
      className="react-datepicker__custom-header"
      sx={{
        margin: "16px 0px 0px 0px",
        display: "flex",
        justifyContent: { xs: "space-evenly", lg: "space-between" },
      }}
    >
      <select
        className="react-datepicker__selectyears--custom"
        value={getYear(date)}
        onChange={({ target: { value } }) => changeYear(parseInt(value))}
      >
        {/** react-datepicker__option */}
        {/** react-datepicker__month-dropdown */}
        {years.map((option) => (
          <option
            key={option}
            value={option}
            className="react-datepicker__option"
            style={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: getYear(date) === option ? 700 : 500,
              color: theme.palette.primary.highlightedplus,
              padding: "5px 0",
            }}
          >
            {option}
          </option>
        ))}
      </select>

      <div className="react-datepicker__boxselectmonths--custom">
        <button
          className="react-datepicker__button--custom"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <ArrowLeftIcon />
        </button>

        <select
          className="react-datepicker__selectmonths--custom"
          value={monthsBR[getMonth(date)]}
          onChange={({ target: { value } }) =>
            changeMonth(monthsBR.indexOf(value))
          }
        >
          {monthsBR.map((option) => (
            <option
              key={option}
              value={option}
              style={{
                fontFamily: theme.typography.fontFamily,
                fontWeight: monthsBR[getMonth(date)] === option ? 700 : 500,
                color: theme.palette.primary.highlightedplus,
                padding: "5px 0",
              }}
            >
              {option}
            </option>
          ))}
        </select>

        <button
          className="react-datepicker__button--custom"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <ArrowRightIcon />
        </button>
      </div>
    </Grid>
  );

  const componenteRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    function handleClickFora(event) {
      if (event.target.localName === "path") return; // Nao faz nada se clicar no icone do calendario
      if (event.target.classList.contains("MuiInputBase-input")) return; // Nao faz nada se clicar no input
      if (event.target.classList.contains("MuiButtonBase-root")) return;

      if (!componenteRef.current?.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("click", handleClickFora);

    return () => {
      document.removeEventListener("click", handleClickFora);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormGroup>
      <TextField
        type="text"
        id="outlined-basic"
        variant="outlined"
        name={props.name}
        label={props.label}
        onClick={handleClick}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value !== null ? dateFormat(props.value) : ""}
        className={classes.textfield}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClick}>
                <CalendarIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputProps={{
          style: {
            WebkitBoxShadow: "0 0 0 1000px white inset",
            fontSize: "1.3rem",
          },
        }}
        error={props.error}
        helperText={props.helperText}
        fullWidth
      />

      <div style={{ position: "relative" }}>
        <div ref={componenteRef} style={{ position: "absolute", zIndex: 2 }}>
          {isOpen && (
            <DatePicker
              calendarClassName={classes.datePicker}
              wrapperClassName="datePicker"
              selected={dateSelected}
              locale={ptbr}
              onChange={handleChange}
              renderCustomHeader={CustomHeader}
              inline
              isClearable
            />
          )}
        </div>
      </div>
    </FormGroup>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textfield: {
      width: "100%",
      color: theme.palette.text.primary,
      "& label": {
        // Controle de estilo antes de digitar
        fontFamily: theme.typography.fontFamily,
        fontWeight: 400,
        fontSize: "1.3rem",
        borderRadius: "1rem",
        opacity: "0.6",
        margin: "-1px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "20px 0px 16px 20px",
      },
      "& .MuiIconButton-root": {
        paddingRight: "6px",
      },
      "& input.Mui-focused": {}, // Controle de estilo durante digitacao
      "& .MuiInputLabel-shrink": {
        // Controle de estilo apos campo digitado
        fontSize: "1.4rem",
        fontWeight: 700,
        color: "rgba(0, 0, 0, 0.50)",
        opacity: 1,
        backgroundColor: "#fff",
        paddingRight: 6,
        letterSpacing: "1.5px",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        // Controla cor da borda durante Digitacao
        border: `1px solid ${theme.palette.secondary.light}`, // "#FFB800",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "1rem",
      },
      "& .Mui-error .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.error.main}`,
        opacity: 1,
      },
    },
    datePicker: {
      overflow: "hidden",
      width: 400,
      left: 10,
      bottom: 15,
      border: "0px solid gainsboro",
      boxShadow: "0 0 6px 0px gainsboro",
      borderRadius: "1rem",
      fontSize: "1.1rem",
      transform: "scale(1)",
      [theme.breakpoints.down("sm")]: {
        left: "-70px",
        transform: "scale(0.8)",
      },
      "& .react-datepicker__month-container": {
        width: "inherit",
      },
      "& .react-datepicker__month": {
        marginBottom: 30,
        [theme.breakpoints.down("sm")]: {
          fontSize: "1.3rem",
          transform: "translate(-15px, 10px)",
        },
      },
      "& .react-datepicker__header--custom": {
        borderBottom: "0px solid #aeaeae",
        backgroundColor: "#fff",
      },
      "& .react-datepicker__year-select": {
        border: "2px solid red",
        lineHeight: 2,
      },
      "& .react-datepicker__selectyears--custom": {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.primary.main,
        fontSize: "1.6rem",
        backgroundColor: "#fff",
        border: "none",
        fontWeight: 600,
        marginLeft: 10,
      },
      "& .react-datepicker__button--custom": {
        color: theme.palette.primary.main,
        backgroundColor: "#fff",
        border: "none",
        padding: 0,
      },
      "& .react-datepicker__boxselectmonths--custom": {
        marginRight: 10,
      },
      "& .react-datepicker__selectmonths--custom": {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.primary.main,
        fontSize: "1.6rem",
        fontWeight: 600,
        border: "none",
        appearance: "none",
        textAlign: "center",
        padding: "0 4px 0 4px",
      },
      "& .react-datepicker__day-names": {
        marginBottom: 0,
        marginTop: 24,
        [theme.breakpoints.down("sm")]: {
          transform: "translate(-15px, 10px)",
        },
      },
      "& .react-datepicker__day-name": {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.primary.light,
        width: "3.4rem",
        fontWeight: 700,
        margin: "0.9rem",
        [theme.breakpoints.down("sm")]: {
          margin: "0.6rem",
        },
      },
      "& .react-datepicker__day": {
        width: "3.4rem",
        height: "2rem",
        margin: "0.9rem",
        [theme.breakpoints.down("sm")]: {
          margin: "1.4rem 0.6rem",
        },
      },
      "& .react-datepicker__day--selected": {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: theme.palette.primary.highlighted,
      },
      "& .react-datepicker__day--keyboard-selected": {
        // Controla a cor da primeira data selecionada - serve para sobrepor a cor default
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: theme.palette.primary.highlighted,
      },
      "& .react-datepicker__day--in-selecting-range": {
        // Controla a cor durante a selecao do intervalo das datas
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: theme.palette.primary.highlighted,
      },
      "& .react-datepicker__day--selecting-range-start": {
        // Estilo do botao antes de selecionar
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: theme.palette.primary.highlighted,
      },
      "& .react-datepicker__day--selecting-range": {
        backgroundColor: theme.palette.primary.highlighted,
      },
      "& .react-datepicker__day--range-start": {
        // Altera a cor da primeira data selecionada
        color: `${theme.palette.primary.main} !important`,
      },
      "& .react-datepicker__day--in-range": {
        // Altera a cor do intervalo apos selecionado data inicio e data fim pelo usuario
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.highlighted,
      },
      "& .react-datepicker__day--in-range:hover": {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.highlighted,
      },
      "& .react-datepicker__day--range-end": {
        // Altera a cor da data fim selecionada
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.highlighted,
      },
      "& .react-datepicker__day--outside-month": {
        // Altera a cor dos dias que estao fora do intervalo valido do mes selecionado
        color: `${theme.palette.text.primary} !important`,
        pointerEvents: "none",
        opacity: 0.5,
      },
    },
  })
);

export default Calendar;
