import React from "react";
import { IUser } from "@utils/interfaces";
import {
  Typography,
  Modal,
  Container,
  Paper,
  makeStyles,
  Button,
} from "@material-ui/core";
import { CloseIcon, EmployeesIconActive } from "@components/Icons";
import { maskCNPJ } from "@utils/index";
import { Link } from "react-router-dom";
import ROUTES from "@config/routes";

interface ModalListBusinessNameProps {
  business: boolean;
  handle: () => void;
  user: IUser;
  updateList: () => void;
}

const ModalListBusinessName = ({
  business,
  handle,
  user,
  updateList,
}: ModalListBusinessNameProps) => {
  const classes = useStyles();

  return (
    <Modal open={business}>
      <Paper className={classes.Paper}>
        <Container className={classes.ContainerUser}>
          <Typography style={{ fontWeight: 700 }}>
            Razões sociais deste usuário
          </Typography>
          <Button onClick={handle}>
            <CloseIcon />
          </Button>
        </Container>

        <Container className={classes.ContainerIcon}>
          <Container className={classes.Icon}>
            <EmployeesIconActive />
          </Container>

          <Container className={classes.ContainerUserInfo}>
            <Typography style={{ fontWeight: 600 }}>
              {user.firstName}
            </Typography>
            <Typography>Data de cadastro</Typography>
          </Container>
        </Container>
        <br />

        <Container className={classes.TitleInfo}>
          <Typography style={{ fontWeight: 600 }}>Razão social</Typography>
          <Typography style={{ fontWeight: 600 }}>CNPJ</Typography>
        </Container>

        <div className={classes.ListInfo}>
          {user.attributes.CNPJ.map((user) => (
            <Container className={classes.Info}>
              <Typography>{user.companyName}</Typography>
              <Typography>{maskCNPJ(user.CNPJ)}</Typography>
            </Container>
          ))}
        </div>

        <Container className={classes.ContainerButton}>
          <Button
            onClick={updateList}
            className={classes.Button}
            variant="contained"
            color="primary"
            component={Link}
            to={{
              state: {
                user,
              },
              pathname: ROUTES.ADMIN_ROUTES.EDIT_USER,
            }}
          >
            Atualizar
          </Button>
        </Container>
      </Paper>
    </Modal>
  );
};

const useStyles = makeStyles({
  Paper: {
    backgroundColor: "#FFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "fixed",
    borderRadius: "8px",
    padding: "5rem 3rem",
    width: "80rem",

    "@media screen and (max-width: 768px)": {
      width: "45rem",
    },
  },
  ContainerUser: {
    display: "flex",
    justifyContent: "space-between",
  },
  ContainerIcon: {
    display: "flex",
    paddingTop: "3rem",
  },
  Icon: {
    backgroundColor: "#FFF5F7",
    height: "5rem",
    width: "5rem",
    borderRadius: "50%",
    padding: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ContainerUserInfo: {
    display: "flex",
    flexDirection: "column",
  },
  TitleInfo: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #7265511A",
    borderRadius: "4px",
    padding: "1rem",
  },
  Info: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #7265511A",
    borderRadius: "4px",
    padding: "1rem",

    "&:not(:first-child)": {
      marginTop: "2rem",
    },
  },
  ListInfo: {
    maxHeight: "20rem",
    overflowY: "scroll",
    marginTop: "2rem",
  },
  ContainerButton: {
    display: "flex",
    justifyContent: "center",
  },
  Button: {
    textTransform: "capitalize",
    marginTop: "2rem",
    boxShadow: "none",
  },
});

export default ModalListBusinessName;
