import { SuccessOrError } from "@modules/orders/dtos/ICreateOrderDataDTO";
import { FreightType } from "@utils/interfaces";

export enum IPayment {
  TICKET = "B", // boleto
  TRANSFER = "T", // a vista
}

export enum IPaymentDays {
  CASH = "0000",
}

export interface IProductCard {
  id?: string;
  code?: string;
  name?: string;
  value?: string;
  amount?: string;
  priceFreight?: string;
  payment?: IPayment; // Metodo de Pagamento -> A vista ou Boleto | Ex: 0000 - A vista, 0001 - 1 Dia, 0002 - 2 Dias
  paymentDays?: string; // limite maximo de dias para pagamento
  paymentDaysSelected?: string; // Valor escolhido pelo usuario entre a vista e o prazo maximo para pagamento
  paymentDayOptions?: {
    key: string;
    value: string;
  }[];
  load?: boolean;
}

export interface INewRequestPayloadRequest {
  address: { id: string }; // {  id: "5664" }
  comments: string;
  customer: ICustomerPayloadRequest;
  deliveryDate: string; // "2023-07-27T04:00:00.000Z2"
  filialID: string; // "C001"
  freightType: FreightType; // "CIF"
  products: IProductPayloadRequest[];
  withdrawBasisID: string; // "01D1"
}

export interface ICustomerPayloadRequest {
  id: string; // "5664"
  CNPJ: string; // "31860017000142"
  distributionChannel: string; // "20"
  division: string; // "10"
}

export interface IProductPayloadRequest {
  id: string; //       "10076"
  deadlinePayment: number; //  5
  description: string; // "GASOLINA C COMUM"
  hashPrice: string; // "cc3e6cbc-d6c2-4783-9b7a-41685c97460f"
  payCond: number; //  5
  price: number; //    5.321932
  quantity: number; // 5000
  payment: "T" | "B";
}

export interface IProductPayloadResponse {
  id: string; //       "10076"
  deadlinePayment: number; //  5
  payment: "T" | "B";
  quantity: number; // 5000

  description?: string; // "GASOLINA C COMUM"
  payCond?: number; //  5
  price?: number; //    5.321932
}

export interface IOrders {
  id: string;
  status?: SuccessOrError; // "success" | "partialError"
  numberOrder: string;
  product: IProductPayloadResponse;
  subsidiary?: string;
  atemStatus?: string;
  implantationDate?: string;
  deliveryDate?: string;
}

export interface INewRequestPayloadResponse {
  // Resposta da Carga Util referente ao novo pedido
  orders: IOrders[];
}
