interface ProductBlockItens {
  name: string;
  code: string;
  id?: string;
}

export const ProductBlockList: ProductBlockItens[] = [
  {
    name: "Gasolina C",
    code: "10076",
  },
  {
    name: "Gasolina Aditivada",
    code: "10122",
  },
  {
    name: "Etanol",
    code: "10059",
  },
  {
    name: "Diesel S10",
    code: "10474",
  },
  {
    name: "Diesel S10 aditivado",
    code: "10488",
  },
  {
    name: "Diesel S500",
    code: "10335",
  },
  {
    name: "Diesel S500 aditivado",
    code: "10374",
  },
  {
    name: "Diesel Marítimo",
    code: "10277",
  },
];

export const Branch: ProductBlockItens[] = [
  {
    name: "Manaus",
    code: "C001",
  },
  {
    name: "Cruzeiro do Sul",
    code: "C002",
  },
  {
    name: "Porto Velho",
    code: "C003",
  },
  {
    name: "Vilhena",
    code: "C004",
  },
  {
    name: "Várzea Grande",
    code: "CDM5",
  },
  {
    name: "Itaituba",
    code: "C008",
  },
  {
    name: "Belém",
    code: "C009",
  },
  {
    name: "Araucária",
    code: "CD10",
  },
  {
    name: "Santarém",
    code: "C011",
  },
  {
    name: "Sinop",
    code: "C012",
  },
  {
    name: "Campo Grande",
    code: "CD14",
  },
  {
    name: "Porto Nacional",
    code: "CD16",
  },
  {
    name: "São Luís",
    code: "CD17",
  },
  {
    name: "Paulínia",
    code: "CD18",
  },
  {
    name: "Sen. Canedo",
    code: "CD19",
  },
  {
    name: "Itajaí",
    code: "CD21",
  },
  {
    name: "Sarandi",
    code: "CD23",
  },
];
