import React from "react";

import { Divider, Grid, Typography, TypographyProps } from "@mui/material";
import {
  ITableBody,
  ITableHead,
  TableCustomProps,
  applyStatusColor,
  applyStatusFontWeight,
} from "@pages/User/NewRequest/resources/table/TableCustom";
import { capitalizeFirstLetterFullString } from "@utils/index";

interface ITableHeadMobile extends ITableHead {
  key: keyof ITableBody;
}

interface Props extends TableCustomProps {
  THead: ITableHeadMobile[];
  children?: React.ReactNode;
}

const TableMobile: React.FC<Props> = ({ children, ...props }) => {
  const TypographyTitle: React.FC<TypographyProps> = ({
    children,
    ...props
  }) => {
    return (
      <Typography
        variant={"body1"}
        sx={{ fontWeight: 700, fontSize: "1rem" }}
        {...props}
      >
        {children}
      </Typography>
    );
  };

  const TypographyDescription: React.FC<TypographyProps> = ({
    children,
    ...props
  }) => {
    return (
      <Typography
        variant={"body1"}
        className="TypographyDescription"
        sx={{ fontWeight: 400, fontSize: "1rem", lineHeight: "normal" }}
        {...props} /* ...props Precisa ser aplicado na ultima posicao */
      >
        {children}
      </Typography>
    );
  };

  return (
    <Grid
      container
      className="tablemobile-container"
      sx={{ border: "0px solid red", gap: 4 }}
    >
      {props.TBody.map((itemBody, indexBody) => {
        return (
          <Grid
            item
            className="TableMobile-card-produto-item"
            xs={12}
            key={indexBody}
            sx={{
              border: "0px solid blue",
              p: 1,
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
              justifyContent: "space-between",
            }}
          >
            {props.THead.map((itemHead, indexHead) => {
              return (
                <Grid
                  container
                  className="TableMobile-card-produto-container"
                  key={indexHead}
                  sx={{
                    border: "1px solid #fff",
                    borderRadius: 1.5,
                    background: "#fff",
                    p: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item>
                    <TypographyTitle>{itemHead.label}</TypographyTitle>
                  </Grid>
                  <Grid
                    item
                    className="item-descriptiop"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <TypographyDescription
                      sx={{
                        color: applyStatusColor(itemBody[itemHead.key] || ""),
                        fontWeight: applyStatusFontWeight(
                          itemBody[itemHead.key] || ""
                        ),
                      }}
                    >
                      {capitalizeFirstLetterFullString(
                        itemBody[itemHead.key] || ""
                      ) ?? "-"}
                    </TypographyDescription>
                  </Grid>
                </Grid>
              );
            })}

            <Divider
              sx={{
                m: "24px 50px 0px 50px",
                border: "2px solid rgba(0,0,0,0.1)",
                borderRadius: 1,
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default TableMobile;
