import React from "react";
import { Checkbox as CheckBoxV5, CheckboxProps } from "@mui/material";
import { useTheme } from "@material-ui/core";

const Checkbox: React.FC<CheckboxProps> = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <CheckBoxV5
      sx={{
        "&.Mui-checked": {
          color: theme.palette.shadesOfDark.dark,
        },
        "& .MuiSvgIcon-root": { fontSize: 20 },
      }}
      {...props}
    />
  );
};

export default Checkbox;
