import React from "react";
import clsx from "clsx";
import { useFormikContext } from "formik";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

import {
  TextField,
  TextFieldProps,
  Theme,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { createStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    matchText: { color: theme.palette.primary.main },
    autocomplete: {
      width: "100%",
    },
    boxtypography: {
      display: "block",
      margin: 8,
    },
    typographytitle: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
    },
    typographySubtitle: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      opacity: 0.4,
      color: "##726551",
      fontSize: "1.2rem",
    },
    menuitem: {
      "& .MuiPaper-root": {
        minWidth: "475px !important",
        height: "300px !important",
        margin: "9rem 0 0 1.6rem !important",
        top: "45rem !important",
      },
    },
    listItem: {
      display: "flex",
      flexDirection: "column",
      "& span:last-child": {
        fontWeight: 400,
        fontSize: 12,
        color: "#B0C1E1",
      },
    },
  })
);
export interface renderOptionsProps<T> {
  option: string;
  value: T;
}

interface SelectCustomProps<T = unknown> {
  label: string;
  name: string;
  placeholder?: string;
  value: string;
  options: T[];
  getOptionLabel: (value: T) => string;
  renderSelected?: renderOptionsProps<T>[];
  onChange: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onDeleteItem?: (value: T) => void;
}

interface SelectCustomObjectProps {
  id?: string;
  description?: string;
}

export default function SelectCustom<T>(props: SelectCustomProps<T>) {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();

  const handleOnChange = (event, newValue: string) => {
    if (event) {
      setFieldValue(props.name, newValue); // Permite a dinamica de digitacao do usuario no campo
      if (
        event.object &&
        typeof event.object !== "undefined" &&
        typeof event.object.id !== "undefined"
      ) {
        setFieldValue(props.name, event.object.id);
      }

      return props.onChange;
    }
  };

  const handleOnClickMenuItem = (object: string) => (event) => {
    const mountObject = {
      id: object.split(":")[1],
      description: object.split(":")[0],
    };

    if (event) {
      event.object = mountObject;
    }

    setFieldValue(props.name, mountObject.id);
    return props.onChange;
  };

  const findEmployeeById = (id: string) => {
    const findEmployee =
      props.options &&
      (props.options.find((item) => {
        if ((item as SelectCustomObjectProps).id === id) {
          return item;
        }
        return null;
      }) as SelectCustomObjectProps);
    return findEmployee ? findEmployee.description : props.value;
  };

  return (
    <Autocomplete
      className={classes.autocomplete}
      fullWidth
      freeSolo
      disableCloseOnSelect
      options={props.options}
      noOptionsText="Sem opções"
      loadingText="Carregando opções"
      inputValue={findEmployeeById(props.value)}
      onInputChange={handleOnChange}
      renderInput={(params) => {
        return (
          <STextField
            {...params}
            variant={"outlined"}
            label={props.label}
            InputProps={{ style: { fontSize: "1.4rem" } }}
          />
        );
      }}
      getOptionLabel={props.getOptionLabel}
      renderOption={(option, { inputValue, selected }) => {
        const optionName = props.getOptionLabel(option);
        const matches = match(optionName, inputValue);
        const parts = parse(optionName, matches);

        return (
          <React.Fragment>
            <div>
              {parts.map((part, index) => (
                <div
                  className={clsx(
                    part.highlight && classes.matchText,
                    classes.listItem
                  )}
                  onClick={handleOnClickMenuItem(part.text)}
                >
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 600,
                      fontSize: 16,
                    }}
                  >
                    {part.text.split(":")[0]}
                  </span>
                  <span>{part.text.split(":")[1]}</span>
                </div>
              ))}
            </div>
          </React.Fragment>
        );
      }}
    />
  );
}

const STextField = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      color: "#3E3D3D",
      "& label": {
        // Controle de estilo antes de digitar
        fontFamily: "montserrat",
        fontWeight: 400,
        fontSize: "1.4rem",
        borderRadius: "1rem",
        opacity: "0.6",
      },
      "& input.Mui-focused": {}, // Controle de estilo durante digitacao
      "& .MuiInputLabel-shrink": {
        // Controle de estilo apos campo digitado
        fontWeight: 700,
        color: theme.palette.text.primary,
        opacity: 1,
        fontSize: "1.8rem",
        backgroundColor: "white",
        paddingRight: 6,
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        // Controla cor da borda durante Digitacao
        border: "2px solid #FF9900",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "1rem",
        opacity: "0.6",
      },
    },
    label: {
      textTransform: "capitalize",
    },
  })
)((props: TextFieldProps) => <TextField {...props} />);
