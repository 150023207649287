import * as Yup from "yup";
import { capitalizeWords } from "../../../../utils";

const FormAddUserValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Preencha o primeiro nome")
    .trim()
    .transform((firstName: string) => {
      return capitalizeWords(firstName);
    }),
  lastName: Yup.string()
    .required("Preencha o último nome")
    .trim()
    .transform((lastName: string) => {
      return capitalizeWords(lastName);
    }),
  email: Yup.string()
    .email("Digite um email válido")
    .lowercase()
    .trim()
    .required("Preencha o e-mail"),
  enabled: Yup.boolean().default(true),
  emailVerified: Yup.boolean().default(false),
  attributes: Yup.object().shape({
    CNPJ: Yup.array()
      .of(
        Yup.object().shape({
          CNPJ: Yup.string().required().trim(),
          companyName: Yup.string().uppercase().required().trim(),
        })
      )
      .required("Preencha pelo menos um CNPJ"),
  }),
  profile: Yup.string().required("Selecione um perfil"),
});

export default FormAddUserValidationSchema;
