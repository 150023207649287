import { MultipleItems } from "@components/Carousel";
import ProgressBar from "@components/ProgressBar";
import ROUTES from "@config/routes";
import { useDashPosh } from "@context/DashPoshContext";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Button,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { IGetDetailsInstantRewardPoshService } from "@modules/user/models/IGetDetailsInstantRewardPoshService";
import { IGetTotalAverageTicketService } from "@modules/user/models/IGetTotalAverageTicketService";
import { IGetTotalQTVolLTService } from "@modules/user/models/IGetTotalQTVolLTService";
import { IGetTotalTaxReceiptWithActionsService } from "@modules/user/models/IGetTotalTaxReceiptWithActionsService";
import {
  formatAmount,
  formatCurrency,
  formatDateWithTZ,
  formatDateWithTZPlusOneDay,
} from "@utils/index";
import { IDetailsInstantRewardPosh } from "@utils/interfaces";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles(({ ...theme }) =>
  createStyles({
    cardConsumers: {
      backgroundColor: "white",
      borderRadius: "5px",
      borderBottom: "3px solid #1A74CD",
      padding: "2.4rem",
      display: "flex",
      alignItems: "center",
      width: "34rem",
      marginRight: "4rem",
      height: "14rem",
      [theme.breakpoints.up("sm")]: {
        width: "34rem",
        height: "14rem",
        padding: "3.2rem",
      },
    },
    containerWithImage: {
      width: "4rem",
      height: "4rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "5px",
      [theme.breakpoints.up("sm")]: { width: "5.2rem", height: "5.2rem" },
    },
    titleCard: {
      fontSize: "2.4rem",
      fontWeight: "bold",
      [theme.breakpoints.up("sm")]: { fontSize: "3.2rem" },
    },
    descriptionCard: {
      color: "#B0C1E1",
      paddingBottom: "1.2rem",
      lineHeight: "1.4rem",
      [theme.breakpoints.up("sm")]: {
        lineHeight: "1.4rem",
      },
    },
  })
);

const CardsTotals = () => {
  const useDash = useDashPosh();
  const classes = useStyles();
  const [loadingInstantReward, setLoandigInstantReward] = useState(true);
  const iocContext = useIoCContext();

  const [qtUserRegister, setQtUserRegister] = useState<number>();
  const [totalCurrency, setTotalCurrency] = useState<number>();
  const [totalQtVolLT, setTotalQtVolLT] = useState<{
    quantityAtem: number;
    quantityPosh: number;
    rate: number;
  }>();
  const [totalAvarageTicket, setTotalAvarageTicket] = useState<{
    total: number;
    quantity: number;
    taxReceipts: number;
  }>();
  const [instantReward, setInstantReward] = useState<
    IDetailsInstantRewardPosh[]
  >([]);

  const getTotalsService = iocContext.serviceContainer.get<
    IGetTotalTaxReceiptWithActionsService
  >(Types.Users.IGetTotalTaxReceiptWithActionsService);

  const getTotalVolLT = iocContext.serviceContainer.get<
    IGetTotalQTVolLTService
  >(Types.Users.IGetTotalQTVolLTService);

  const getTotalAvarageTicket = iocContext.serviceContainer.get<
    IGetTotalAverageTicketService
  >(Types.Users.IGetTotalAverageTicketService);

  const getDetailsInstantReward = iocContext.serviceContainer.get<
    IGetDetailsInstantRewardPoshService
  >(Types.Users.IGetDetailsInstantRewardPoshService);

  const getTotalsTaxReceipt = useCallback(async () => {
    try {
      if (useDash.customersTransformString) {
        const totalsDistinctUser = await getTotalsService.execute({
          action: "count-distinct-user",
          companies_id: useDash.customersTransformString,
          from: formatDateWithTZ(useDash.dateFilter),
          to: formatDateWithTZPlusOneDay(useDash.dateToFilter),
        });

        setQtUserRegister(totalsDistinctUser);
      }
    } catch (error) {
    } finally {
    }
  }, [getTotalsService, useDash]);

  const fetchDetailsInstantReward = useCallback(async () => {
    try {
      setLoandigInstantReward(true);
      if (useDash.customersTransformString) {
        const details = await getDetailsInstantReward.execute({
          action: "count-instant-reward-by-company",
          companies_id: useDash.customersTransformString.replace(/'/g, ""),
          from: formatDateWithTZ(useDash.dateFilter),
          to: formatDateWithTZPlusOneDay(useDash.dateToFilter),
        });

        setInstantReward(details);
      }
    } catch (error) {
    } finally {
      setLoandigInstantReward(false);
    }
  }, [getDetailsInstantReward, useDash]);

  const getTotalsCurrency = useCallback(async () => {
    try {
      if (useDash.customersTransformString) {
        const totalsCurrency = await getTotalsService.execute({
          action: "sum-total-item",
          companies_id: useDash.customersTransformString,
          from: formatDateWithTZ(useDash.dateFilter),
          to: formatDateWithTZPlusOneDay(useDash.dateToFilter),
        });

        setTotalCurrency(totalsCurrency);
      }
    } catch (error) {
    } finally {
    }
  }, [getTotalsService, useDash]);

  const getTotalsVolLT = useCallback(async () => {
    try {
      if (useDash.customersTransformString) {
        const totalsVolLT = await getTotalVolLT.execute({
          action: "sum-quantity-item",
          companies_id: useDash.customersTransformString,
          from: formatDateWithTZ(useDash.dateFilter),
          to: formatDateWithTZPlusOneDay(useDash.dateToFilter),
        });
        setTotalQtVolLT(totalsVolLT);
      }
    } catch (error) {
    } finally {
    }
  }, [getTotalVolLT, useDash]);

  const fetchAvarageTicket = useCallback(async () => {
    try {
      if (useDash.customersTransformString) {
        const totalsAvarageTicket = await getTotalAvarageTicket.execute({
          action: "ticket-avg",
          companies_id: useDash.customersTransformString,
          from: formatDateWithTZ(useDash.dateFilter),
          to: formatDateWithTZPlusOneDay(useDash.dateToFilter),
        });
        setTotalAvarageTicket(totalsAvarageTicket);
      }
    } catch (error) {
    } finally {
    }
  }, [getTotalAvarageTicket, useDash]);

  useEffect(() => {
    getTotalsTaxReceipt();
  }, [getTotalsTaxReceipt]);

  useEffect(() => {
    getTotalsCurrency();
  }, [getTotalsCurrency]);

  useEffect(() => {
    getTotalsVolLT();
  }, [getTotalsVolLT]);

  useEffect(() => {
    fetchDetailsInstantReward();
  }, [fetchDetailsInstantReward]);

  useEffect(() => {
    fetchAvarageTicket();
  }, [fetchAvarageTicket]);

  return (
    <MultipleItems>
      <div style={{ display: "flex", marginRight: "3rem" }}>
        <div className={classes.cardConsumers}>
          <div
            style={{
              backgroundColor: "#C5E1F7",
            }}
            className={classes.containerWithImage}
          >
            <img src="consumers.png" alt="" />
          </div>

          <div style={{ paddingLeft: "1.6rem", height: "100px" }}>
            {loadingInstantReward ? (
              <div style={{ padding: "1.2rem 0rem" }}>
                <Skeleton variant="rect" height={32} />
              </div>
            ) : (
              <Typography className={classes.titleCard}>
                {qtUserRegister && formatAmount(qtUserRegister)}
              </Typography>
            )}

            <Typography className={classes.descriptionCard}>
              Consumidores cadastrados
            </Typography>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "inline-block",
          marginRight: "3rem",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "5px",
            borderBottom: "3px solid #30CAE9",
            padding: "3.2rem",
            display: "flex",
            alignItems: "center",
            marginRight: "4rem",
            width: "34rem",
            height: "14rem",
          }}
        >
          <div
            style={{
              backgroundColor: "#C2F5FF",
              width: "5.2rem",
              height: "5.2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
            }}
          >
            <img src="reward.png" alt="" />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              paddingTop: "1.6rem",
            }}
          >
            <div style={{ paddingLeft: "1.6rem" }}>
              {loadingInstantReward ? (
                <div style={{ padding: "1.2rem 0rem" }}>
                  <Skeleton variant="rect" height={32} />
                </div>
              ) : (
                <Typography style={{ fontSize: "3.2rem", fontWeight: "bold" }}>
                  {instantReward.length}{" "}
                  <span
                    style={{
                      color: "#B0C1E1",
                      fontSize: "1.2rem",
                      fontWeight: "normal",
                    }}
                  >
                    unidades
                  </span>
                </Typography>
              )}

              <Typography
                style={{
                  color: "#B0C1E1",
                  paddingBottom: "1.2rem",
                  lineHeight: "1.2rem",
                }}
              >
                Prêmios entregues
              </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "right" }}>
              <Button
                component={Link}
                to={`${
                  ROUTES.USER_ROUTES.INSTANT_REWARD
                }/${useDash.customersTransformString?.replace(/'/g, "")}`}
                style={{
                  textTransform: "none",
                  fontSize: "1.2rem",
                  color: "#D91F05",
                  paddingTop: "0.8rem",
                }}
              >
                Ver todos
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "inline-block" }}>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "5px",
            borderBottom: "3px solid #D91F05",
            padding: "3.2rem",
            display: "flex",
            alignItems: "center",
            marginRight: "4rem",
            width: "34rem",
            height: "14rem",
          }}
        >
          <div
            style={{
              backgroundColor: "#FFD8D2",
              width: "5.2rem",
              height: "5.2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
            }}
          >
            <img src="liters.png" alt="" />
          </div>

          <div
            style={{
              paddingLeft: "1.6rem",
              width: "100%",
              paddingTop: "2.4rem",
            }}
          >
            {loadingInstantReward ? (
              <div>
                <Skeleton variant="rect" height={32} />
              </div>
            ) : (
              <Typography
                style={{
                  fontSize: "3.2rem",
                  fontWeight: "bold",
                  lineHeight: "1.2rem",
                  paddingTop: "1.6rem",
                }}
              >
                {totalQtVolLT && totalQtVolLT.quantityPosh !== null
                  ? formatAmount(
                      parseFloat(totalQtVolLT.quantityPosh.toFixed())
                    )
                  : "---"}
                <span
                  style={{
                    color: "#B0C1E1",
                    fontSize: "1.2rem",
                    fontWeight: "normal",
                  }}
                >
                  litros
                </span>
              </Typography>
            )}

            {loadingInstantReward ? (
              <div style={{ padding: "1.2rem 0rem" }}>
                <Skeleton variant="rect" height={6} />
              </div>
            ) : (
              <Typography>
                {totalQtVolLT &&
                  formatAmount(parseFloat(totalQtVolLT.quantityAtem.toFixed()))}
              </Typography>
            )}

            <Typography
              style={{
                color: "#B0C1E1",
                lineHeight: "1.6rem",
              }}
            >
              Volume
            </Typography>
            <ProgressBar
              bgcolor="#D91F05"
              progress={
                totalQtVolLT
                  ? parseFloat((totalQtVolLT.rate * 100).toFixed(2))
                  : 0
              }
              height={8}
            />
          </div>
        </div>
      </div>
      <div style={{ display: "inline-block" }}>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "5px",
            borderBottom: "3px solid #0BB873",
            padding: "1.6rem",
            display: "flex",
            alignItems: "center",
            marginRight: "4rem",
            width: "34rem",
            height: "14rem",
          }}
        >
          <div
            style={{
              backgroundColor: "#D3FEED",
              width: "5.2rem",
              height: "5.2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
            }}
          >
            <img src="money.png" alt="" />
          </div>

          <div style={{ paddingLeft: "1.6rem", width: "100%" }}>
            {loadingInstantReward ? (
              <div style={{ padding: "1.2rem 0rem" }}>
                <Skeleton variant="rect" height={32} />
              </div>
            ) : (
              <Typography
                style={{
                  fontSize: "3.2rem",
                  fontWeight: "bold",
                }}
              >
                {totalCurrency && formatCurrency(totalCurrency)}
              </Typography>
            )}
            <Typography style={{ color: "#B0C1E1", lineHeight: "0.8rem" }}>
              Total
            </Typography>
          </div>
        </div>
      </div>
      <div style={{ display: "inline-block" }}>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "5px",
            borderBottom: "3px solid #FB7918",
            padding: "3.2rem",
            display: "flex",
            alignItems: "center",
            // width: "38rem",
            marginRight: "4rem",
            width: "34rem",
            height: "14rem",
          }}
        >
          <div
            style={{
              backgroundColor: "#FCCCA8",
              width: "5.2rem",
              height: "5.2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
            }}
          >
            <img src="ticket.png" alt="" />
          </div>

          <div style={{ paddingLeft: "1.6rem", width: "100%" }}>
            {loadingInstantReward ? (
              <div style={{ padding: "1.2rem 0rem" }}>
                <Skeleton variant="rect" height={32} />
              </div>
            ) : (
              <Typography style={{ fontSize: "3.2rem", fontWeight: "bold" }}>
                {totalAvarageTicket &&
                  formatAmount(totalAvarageTicket.taxReceipts)}{" "}
                <span
                  style={{
                    color: "#B0C1E1",
                    fontSize: "1.2rem",
                    fontWeight: "normal",
                  }}
                >
                  unidades
                </span>
              </Typography>
            )}

            <Typography
              style={{
                color: "#B0C1E1",
                paddingBottom: "1.2rem",
                lineHeight: "0.8rem",
              }}
            >
              Cupons escaneados
            </Typography>
          </div>
        </div>
      </div>
      <div style={{ display: "inline-block" }}>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "5px",
            borderBottom: "3px solid #FDC742",
            padding: "3.2rem",
            display: "flex",
            alignItems: "center",
            width: "34rem",
            height: "14rem",
          }}
        >
          <div
            style={{
              backgroundColor: "#FFF2D1",
              width: "5.2rem",
              height: "5.2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
            }}
          >
            <img src="subtract.png" alt="" />
          </div>

          <div style={{ paddingLeft: "1.6rem", width: "100%" }}>
            {loadingInstantReward ? (
              <div style={{ padding: "1.2rem 0rem" }}>
                <Skeleton variant="rect" height={32} />
              </div>
            ) : (
              <Typography
                style={{
                  fontSize: "3.2rem",
                  fontWeight: "bold",
                  lineHeight: "3rem",
                }}
              >
                {totalAvarageTicket && formatCurrency(totalAvarageTicket.total)}
              </Typography>
            )}

            {loadingInstantReward ? (
              <div style={{ padding: "1.2rem 0rem" }}>
                <Skeleton variant="rect" height={6} />
              </div>
            ) : (
              <Typography>
                {`  ${
                  totalAvarageTicket &&
                  formatAmount(
                    parseFloat(totalAvarageTicket.quantity.toFixed())
                  )
                } litros`}
              </Typography>
            )}

            <Typography style={{ color: "#B0C1E1", lineHeight: "1rem" }}>
              Ticket médio
            </Typography>
          </div>
        </div>
      </div>
    </MultipleItems>
  );
};

export default CardsTotals;
