import { Option } from "@components/TextFieldMultiSelect";

export interface IFormikValues {
  customer: Option[];
  scheduleDate: [Date | null, Date | null];
  product: Option[];
  filial: Option[];
}

export const initialValuesFormikDefault: IFormikValues = {
  customer: [],
  scheduleDate: [null, null],
  product: [],
  filial: [],
};

export enum VALUESNAME {
  CUSTOMER = "customer",
  SCHEDULEDATE = "scheduleDate",
  PRODUCT = "product",
  FILIAL = "filial",
}

export const optionsList = (roadLoadFilterOptions): Option[] => {
  return (
    roadLoadFilterOptions?.map((item) => {
      return {
        value: item.id,
        label: item.desc,
      };
    }) ?? []
  );
};
