import React from "react";
import {
  Button,
  Container,
  Modal,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { CloseIcon, WarnIcon } from "@components/Icons";

interface ModalInactivatedUserProps {
  showModal: () => void;
  handleButtonContained: () => void;
  handleButtonPrimary: () => void;
  title: string;
  subtitle: string;
  descriptionButtonPrimary: string;
  descriptionButtonContained: string;
  disabledPrimary?: boolean;
  disabledContained?: boolean;
}

const ModalCancel = ({
  showModal,
  handleButtonContained,
  handleButtonPrimary,
  title,
  subtitle,
  descriptionButtonPrimary,
  descriptionButtonContained,
  ...props
}: ModalInactivatedUserProps) => {
  const classes = useStyles();

  return (
    <Modal open={true} onClose={showModal}>
      <Paper className={classes.Paper}>
        <Container className={classes.CloseIcon}>
          <CloseIcon onClick={showModal} />
        </Container>
        <Container className={classes.Image}>
          <WarnIcon
            style={{
              fontSize: "8rem",
              border: "0px solid red",
            }}
          />
        </Container>

        <Typography className={classes.TitleInactivated}>{title}</Typography>
        <Typography className={classes.MessageConfirm}>{subtitle}</Typography>

        <Container className={classes.ContainerButton}>
          <Button
            className={classes.Button}
            variant="text"
            color="primary"
            disabled={props.disabledPrimary}
            onClick={() => {
              handleButtonPrimary();
            }}
          >
            {descriptionButtonPrimary}
          </Button>
          <Button
            className={classes.Button}
            variant="contained"
            color="primary"
            disabled={props.disabledContained}
            onClick={() => {
              handleButtonContained();
            }}
          >
            {descriptionButtonContained}
          </Button>
        </Container>
      </Paper>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  Paper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "none",
    height: "37rem",
    padding: "3rem",
    textAlign: "center",
    position: "fixed",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    borderRadius: "4px",
    [theme.breakpoints.up("xs")]: {
      width: "30rem",
      transform: "translate(-60%, -50%)",
    },
    [theme.breakpoints.up("md")]: {
      width: "40rem",
    },
    [theme.breakpoints.up("lg")]: {
      width: "60rem",
    },
  },
  Image: {
    backgroundColor: "#FFF5F7",
    borderRadius: "50%",
    height: "8rem",
    width: "8rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  TitleInactivated: {
    fontFamily: "Montserrat",
    fontWeight: 700,
  },
  MessageConfirm: {
    color: "#BABABA",
    fontSize: "1.3rem",
  },
  ContainerButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    [theme.breakpoints.up("xs")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  CloseIcon: {
    display: "flex",
    alignItems: "top",
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  Button: {
    textTransform: "none",
    boxShadow: "none",

    [theme.breakpoints.up("xs")]: {
      margin: "1rem 0 0 0",
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "0 0 0 1rem",
      width: "auto",
    },
  },
}));

export default ModalCancel;
