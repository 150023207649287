import React from "react";

import {
  Backdrop,
  Button,
  Fade,
  Grid,
  Modal,
  Typography,
  useTheme,
} from "@material-ui/core";
import { CircularProgress } from "@mui/material";

import { INozzlesModal } from "@pages/User/Nozzle/model";
import FileUpload from "@pages/User/Nozzle/resource/FileUpload";
import FormikRegisterImage from "@pages/User/Nozzle/resource/FormikRegisterImage";
import SelectField, {
  IObjectModalNozzle,
} from "@pages/User/Nozzle/resource/SelectField";

import { INozzlesFileRequest } from "@modules/nozzles/models";

import { CloseIcon } from "@components/Icons";

interface Props {
  children?: React.ReactNode;
  labelButton: string;
  nozzlesModal: INozzlesModal[];
  cnpj: string;
  loading: boolean;
  handleNozzlesFirstRegister: (e) => void;
}

const ModalNozzle: React.FC<Props> = ({ children, ...props }) => {
  const theme = useTheme();

  const [openModal, setOpenModal] = React.useState(false);
  const [file, setFile] = React.useState<File | null>(null);

  const initialStateModalSelection = { key: "", value: [] };

  // Controla o objeto selecionado na lista exibida no select
  const [selectedOption, setSelectedOption] = React.useState<
    IObjectModalNozzle
  >(initialStateModalSelection);

  // lista completa com todos os grupos e ilhas -> Ex: {"A1",[1,2,3]}
  const [nozzlesModal, setNozzlesModal] = React.useState<IObjectModalNozzle[]>(
    []
  );

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedOption(initialStateModalSelection);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleDeleteFile = () => {
    setFile(null);
  };

  const handleUpdateSelectOptions = (nozzleSelect) => {
    setSelectedOption(nozzleSelect);
  };

  const normalizedNozzles = React.useCallback(() => {
    const nozzlesObject = props.nozzlesModal.map((group) => {
      return {
        key: group.group,
        value: group.nozzles,
      };
    }) as IObjectModalNozzle[];
    setNozzlesModal(nozzlesObject);
  }, [props.nozzlesModal]);

  React.useEffect(() => {
    normalizedNozzles();
  }, [normalizedNozzles]);

  const handleUpdateUploadFile = (file: File | null) => {
    setFile(file);
  };

  const SelectNozzles: React.FC = () => {
    return (
      <SelectField
        name="nozzles"
        label="Bicos"
        objects={nozzlesModal}
        value={selectedOption.value.toString()}
        handleSetSelecOption={handleUpdateSelectOptions}
      />
    );
  };

  const LeftModalContent: React.FC = () => {
    return (
      <Grid
        container
        justifyContent="center"
        style={{ textAlign: "center" }}
        spacing={3}
      >
        <Grid item xs={12}>
          <Typography
            variant="h5"
            color="textPrimary"
            style={{ fontWeight: 700, marginBottom: 4 }}
          >
            {" "}
            Bicos
          </Typography>
          <Typography
            variant="subtitle2"
            color="textPrimary"
            style={{
              fontWeight: 500,
              opacity: 0.7,
              lineHeight: 1.8,
            }}
          >
            Escolha qual o agrupamento de bicos que você deseja para enviar a
            foto.
          </Typography>
        </Grid>

        <Grid
          item
          xs={10}
          style={{
            border: "0px solid red",
            textAlign: "center",
          }}
        >
          <SelectNozzles></SelectNozzles>
        </Grid>
      </Grid>
    );
  };

  const ButtonSendRegisterImage: React.FC = () => {
    const payloadSend = {
      document: props.cnpj,
      group: selectedOption && selectedOption.key,
      file: file,
    } as INozzlesFileRequest;

    return (
      <FormikRegisterImage
        handleNozzlesFirstRegister={props.handleNozzlesFirstRegister}
        nozzles={payloadSend}
        label={"Enviar"}
        onChangeModal={handleCloseModal}
      ></FormikRegisterImage>
    );
  };

  return (
    <Grid container justifyContent="center">
      <Button
        onClick={handleOpenModal}
        style={{
          backgroundColor: theme.palette.primary.main,
          textTransform: "initial",
          padding: "8px 24px 8px 24px",
        }}
      >
        <Typography
          variant="caption"
          style={{
            color: theme.palette.text.hint,
            fontWeight: 600,
          }}
        >
          {props.labelButton}
        </Typography>
      </Button>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            style={{
              border: "0px solid purple",
              position: "relative",
              top: "200px",
            }}
          >
            {/* BLOCO MODAL --------------------------- */}
            {props.loading ? (
              <CircularProgress></CircularProgress>
            ) : (
              <Grid
                item
                xs={6}
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid black",
                  borderRadius: 16,
                  padding: "16px 16px 16px 16px",
                }}
              >
                <Grid container style={{ border: "0px solid purple" }}>
                  {/* HEADER DO MODAL - BOTAO DE SAIR DO MODAL */}
                  <Grid
                    item
                    xs={12}
                    style={{
                      textAlign: "right",
                      margin: "12px 8px 24px 0px",
                      border: "0px solid black",
                    }}
                  >
                    <Button
                      onClick={handleCloseModal}
                      style={{ border: "0px solid black" }}
                    >
                      <CloseIcon />
                    </Button>
                  </Grid>

                  {/* BODY DO MODAL ------------------- */}
                  <Grid
                    item
                    xs={12}
                    style={{
                      margin: "0px 16px 16px 16px",
                      border: "0px solid red",
                    }}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      style={{ padding: 16, border: "0px solid green" }}
                      spacing={4}
                    >
                      {/* BLOCO DA ESQUERDA DO MODAL ------------------- */}
                      <Grid
                        item
                        xs={6}
                        style={{
                          border: "0px solid blue",
                          textAlign: "center",
                          marginTop: 80,
                        }}
                      >
                        <LeftModalContent />
                      </Grid>

                      {/* BLOCO DA DIREITA DO MODAL ------------------- */}
                      <Grid
                        item
                        xs={6}
                        style={{
                          alignSelf: "stretch",
                          border: "0px solid blue",
                        }}
                      >
                        <Grid
                          container
                          style={{ textAlign: "center" }}
                          spacing={2}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{ border: "0px solid black" }}
                          >
                            <FileUpload
                              onChangeUploadFile={handleUpdateUploadFile}
                              handleDeleteFile={handleDeleteFile}
                              disabled={
                                selectedOption.key.length === 0 ? true : false
                              }
                            >
                              <ButtonSendRegisterImage />
                            </FileUpload>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Fade>
      </Modal>
    </Grid>
  );
};

export default ModalNozzle;
