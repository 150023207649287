import React from "react";

import { Button, ButtonProps, Typography } from "@mui/material";
import { useTheme } from "@material-ui/core";

interface TextButtonProps extends ButtonProps {
  children?: React.ReactNode;
}

const TextButton: React.FC<TextButtonProps> = ({ children, ...props }) => {
  const theme = useTheme();

  const colorDefault = {
    white: theme.palette.background.paper, // "#F5F5F5"
    red: theme.palette.primary.main, // "#D91F05"
    redDark: "#7f0e00",
  };

  return (
    <Button
      {...props}
      variant="text"
      sx={{
        textTransform: "capitalize",
        padding: "4px 24px",
      }}
    >
      <Typography
        variant="body1"
        sx={{
          fontFamily: theme.typography.fontFamily,
          color: colorDefault.red,
          fontWeight: 600,
          fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" },
        }}
      >
        {children}
      </Typography>
    </Button>
  );
};

export default TextButton;
