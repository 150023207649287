import React from "react";

import { NozzleAdminProvider } from "@pages/Admin/Nozzle/context/NozzleAdminContext";
import NozzleAdminView from "@pages/Admin/Nozzle/view";
import Layout from "@components/Layout";

const NozzleAdmin = () => {
  return (
    <Layout disableToolbar={true} disableToolbarMobile={true}>
      <NozzleAdminProvider>
        <NozzleAdminView />
      </NozzleAdminProvider>
    </Layout>
  );
};

export default NozzleAdmin;
