import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IPaymentSchedule } from "@utils/interfaces";
import { IGetLastPaymentService } from "../models/IGetLastPaymentService";

@injectable()
export class GetLastPaymentService implements IGetLastPaymentService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(CNPJ: string): Promise<IPaymentSchedule> {
    const customer = await this.httpInstance.get<IPaymentSchedule>(
      `orders/company/${CNPJ}/last-payment-state`
    );
    return customer;
  }
}
