import React, { useCallback, useEffect, useState } from "react";
import { useTheme } from "@material-ui/core";
import {
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Typography,
  Box,
  BoxProps,
  ListSubheader,
  Grid,
  Tooltip,
} from "@mui/material";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";

import { BellNotification, BellSoft } from "@components/Icons";
import Modal from "@components/Modal";
import Button from "@components/Button";

import {
  IRoadLoadService,
  NotificationHistory,
  NotificationMessage,
} from "@modules/roadLoad/model";

import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";

import { formatDateDistanceNow } from "@utils/index";
import useDialogAlert from "@hooks/useDialogAlert";
import AppError from "@utils/AppError";
import { findAffiliatedSociety } from "@utils/affiliatedSocieties";
import { Filial } from "@utils/interfaces";

const Notification: React.FC<BoxProps> = () => {
  const theme = useTheme();
  const rosa = theme.palette.primaryLight.main;
  const branco = theme.palette.background.paper;
  const vermelho = theme.palette.primary.main;

  const iocContext = useIoCContext();
  const { snackbar } = useDialogAlert();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [
    notificationHistory,
    setNotificationHistory,
  ] = useState<NotificationHistory | null>(null);
  const [
    selectedMessage,
    setSelectedMessage,
  ] = useState<NotificationMessage | null>(null);
  const [messageCount, setMessageCount] = useState<number | null>(null);

  const notificationAck = useCallback(async (id: string) => {
    const roadLoadService = iocContext.serviceContainer.get<IRoadLoadService>(
      Types.RoadLoad.IRoadLoadService
    );

    await roadLoadService.notificationAck(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const roadLoadService = iocContext.serviceContainer.get<IRoadLoadService>(
      Types.RoadLoad.IRoadLoadService
    );

    const getNotificationCount = async () => {
      try {
        const { count } = await roadLoadService.notificationCount();
        setMessageCount(count);
      } catch (err) {
        if (err instanceof AppError) {
          snackbar({
            message: `Erro ao carregar as Notificações nao lidas - ${err.message}`,
            variant: "error",
          });
        }
      }
    };

    const getNotificationHistory = async () => {
      try {
        const notificationHistory = await roadLoadService.notificationHistory();
        setNotificationHistory(notificationHistory);
      } catch (err) {
        if (err instanceof AppError) {
          snackbar({
            message: `Erro ao carregar o historico de Notificações - ${err.message}`,
            variant: "error",
          });
        }
      }
    };

    getNotificationCount();
    getNotificationHistory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMessage]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenModal = (message: NotificationMessage) => {
    setSelectedMessage(message);
    notificationAck(message.id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    setSelectedMessage(null);
  };

  const hasNotification = notificationHistory?.rows.length !== 0;

  return (
    <React.Fragment>
      <Tooltip
        title={
          !hasNotification ? (
            <Typography sx={{ fontSize: 10 }}>
              {" "}
              Você nao pode possui notificações
            </Typography>
          ) : (
            ""
          )
        }
        placement="bottom"
      >
        <span>
          <IconButton
            id="positioned-button"
            aria-controls={Boolean(anchorEl) ? "positioned-menu" : undefined}
            aria-expanded={Boolean(anchorEl) ? "true" : undefined}
            aria-haspopup="true"
            color="info"
            onClick={handleOpenMenu}
            sx={{
              backgroundColor: "#fff",
              borderRadius: 1,
              opacity: !hasNotification ? "0.4" : "1",
            }}
          >
            <Badge
              badgeContent={messageCount}
              sx={{
                "& .MuiBadge-badge": {
                  right: 0,
                  top: 0,
                  color: "#fff",
                  border: `2px solid ${theme.palette.background.paper}`,
                  padding: "0 4px",
                  background: theme.palette.primary.main,
                },
              }}
            >
              <BellNotification sx={{ fontSize: "21px" }} />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>

      {hasNotification && (
        <React.Fragment>
          <Menu
            id="positioned-menu"
            aria-labelledby="positioned-button"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            elevation={0}
            sx={{}}
            slotProps={{
              paper: {
                sx: {
                  border: "0px solid blue",
                  width: { xs: "300px", md: "400px" },
                  maxWidth: { xs: "300px", md: "500px" },
                  marginTop: { xs: 2, md: 4 },
                },
              },
            }}
          >
            <ListSubheader>
              <Typography variant="h5" sx={{ fontWeight: 700, pb: 1.4 }}>
                Notificações
              </Typography>
            </ListSubheader>

            {notificationHistory?.rows?.map((row) => {
              const { id, title, message, read, sentAt, subsidiaryRef } = row;
              const filial: Filial | undefined = findAffiliatedSociety(
                subsidiaryRef
              );
              return (
                <MenuItem key={id} onClick={() => handleOpenModal(row)}>
                  <Grid
                    container
                    sx={{
                      gap: 1,
                      border: "1px solid rgba(0,0,0,0.1)",
                      borderRadius: 1,
                      p: 1,
                      background: read ? branco : rosa,
                    }}
                  >
                    <Grid item xs={10.8}>
                      <Grid container sx={{ gap: 0.8 }}>
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          {read ? (
                            <WarningAmberOutlinedIcon
                              sx={{
                                fontSize: 16,
                                color: vermelho,
                                background: rosa,
                                borderRadius: 4,
                                width: "22px",
                                height: "22px",
                                padding: "5px",
                              }}
                            />
                          ) : (
                            <WarningAmberOutlinedIcon
                              sx={{
                                fontSize: 16,
                                color: branco,
                                background: vermelho,
                                borderRadius: 4,
                                width: "22px",
                                height: "22px",
                                padding: "5px",
                              }}
                            />
                          )}

                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 700,
                              textOverflow: "ellipsis",
                              overflowX: "hidden",
                            }}
                          >
                            {title}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{}}>
                          <Typography
                            variant="body1"
                            sx={{
                              textOverflow: "ellipsis",
                              overflowX: "hidden",
                            }}
                          >
                            {message}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption">
                            {formatDateDistanceNow(sentAt)} -{" "}
                            <b style={{ paddingLeft: 1 }}>
                              Filial {filial?.name}{" "}
                            </b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </MenuItem>
              );
            })}
          </Menu>
          <Modal
            open={Boolean(selectedMessage)}
            onClose={handleCloseModal}
            aria-labelledby="message-title"
            aria-describedby="message-description"
            hiddenButton
          >
            <Box
              sx={{
                px: 1,
                pb: 2,
                backgroundColor: "white",
                margin: "auto",
                width: { xs: "295px", md: "400px" },
                maxHeight: "400px",
                m: 0,
                minWidth: "fit-content",
              }}
            >
              {selectedMessage && (
                <Grid
                  container
                  textAlign={"center"}
                  justifyContent={"center"}
                  gap={2.5}
                >
                  <Grid item xs={12}>
                    <BellSoft className="bellSoft" sx={{ fontSize: "80px" }} />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      id="message-title"
                      variant="h4"
                      component="h2"
                      sx={{ fontWeight: 700 }}
                    >
                      {selectedMessage.title}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      id="message-description"
                      variant="body1"
                      component="p"
                      sx={{ fontWeight: 500 }}
                    >
                      {selectedMessage.message}
                    </Typography>
                  </Grid>

                  <Grid item xs={10} sm={10} md={9} lg={7}>
                    {!selectedMessage.read && (
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => handleCloseModal()}
                        sx={{ fontSize: "11.5px" }}
                      >
                        Marcar como lida
                      </Button>
                    )}
                  </Grid>
                </Grid>
              )}
            </Box>
          </Modal>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Notification;
