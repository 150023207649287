import PaginationDynamic from "@components/PaginationDynamic/PaginationDynamic";
import { Grid } from "@material-ui/core";
import { calcPagesQuantity } from "@utils/pagination";
import React, { useEffect, useState } from "react";
import ProductTable from "../ProductTable";
import { useDiscountProductContext } from "../context/ProductContext";
import ProductDeleteModal from "../resource/ProductDeleteModal";

const ITEMS_PER_PAGE = 10;

const ProductContent: React.FC = () => {
  const {
    getProductList,
    productResponse,
    isProductListLoading,
    setProductQuery,
    productQuery,
    deleteProduct,
    isDeleteProductLoading,
    setOpenDrawer,
    setEditProduct,
  } = useDiscountProductContext();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [productId, setProductId] = useState("");

  useEffect(() => {
    getProductList();
  }, [getProductList]);

  const handleModalClose = () => {
    setOpenDeleteModal(false);
  };

  const handleModalOpen = () => {
    setOpenDeleteModal(true);
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => {
    setProductQuery((old) => ({
      ...old,
      page: newPage + 1,
    }));
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <ProductDeleteModal
          open={openDeleteModal}
          handleClose={handleModalClose}
          isLoading={isDeleteProductLoading}
          onDeleteClick={() => {
            deleteProduct(productId, handleModalClose);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ProductTable
          products={productResponse.content ?? []}
          isLoading={isProductListLoading}
          itemsPerPage={ITEMS_PER_PAGE}
          onOpenModalClick={(id) => {
            setProductId(id);
            handleModalOpen();
          }}
          onHandleEdit={(product) => {
            setEditProduct(product);
            setOpenDrawer(true);
          }}
        />
        {Boolean(productResponse.content?.length ?? 0) && (
          <Grid item xs={12}>
            <PaginationDynamic
              showFirstButton
              showLastButton
              onPageChange={handlePageChange}
              pagesTotal={calcPagesQuantity(
                ITEMS_PER_PAGE,
                productResponse.total ?? 0
              )}
              page={productQuery.page}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ProductContent;
