import CalendarRange from "@components/CalendarRange";
import CardDefault from "@components/CardDefault";
import { CheckListIcon } from "@components/Icons";
import { useUserState } from "@context/UserContext";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useFormikContext } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useFormQueryTitle } from "./formQueryTitleContext";
import { IQuery } from "./interface";
import { SITUATION_TYPE } from "./utils/ENUMS_TITLES";
import DropdownSelectAll from "@components/DropdownSelectAll";

const useStyles = makeStyles((theme) =>
  createStyles({
    itemSelect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      height: "6rem",
      paddingTop: "1rem",
    },
    itemSelectTitle: {
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
    },
    itemSelectSubTitle: {
      color: "#D5D1CB",
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "46px",
      padding: 0,
      marginTop: "-1.2rem",
    },
    iconNotDisabled: {
      opacity: "0.6",
    },
    iconsDisabled: {
      opacity: "0.12",
    },
    titlePage: { color: " #3E3D3D" },
    titleCard: {
      color: " #3E3D3D",
      fontFamily: "DM Sans",
      fontSize: "24px",
      fontWeight: 700,
      paddingTop: "2rem",
      paddingBottom: "1rem",
    },
    inputLabel: {
      position: "absolute",
      top: "-2px",
      left: "12px",
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 700,
      backgroundColor: "#fff",
      paddingLeft: "0.2rem",
      paddingRight: "0.8rem",
      marginLeft: "0rem",
      width: "auto",
    },
    customSelect: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      marginTop: "0",
      height: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
      paddingLeft: "14px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(114, 101, 81, 0.20)",
      },
      "& .MuiSvgIcon-root": {
        fill: "#D91F05",
      },
      borderRadius: "8px",
    },
    formControl: {
      borderRadius: "8px",
      width: "100%",
      height: "100%",
    },
    labelChip: { marginBottom: "0.5rem" },
    containerButton: {
      paddingRight: "16px",
      display: "flex",
      justifyContent: "right",
      marginTop: "2rem",
    },
    styleButton: {
      textTransform: "none",
      fontWeight: "bold",
      borderRadius: "2px",
      background: "#D91F05",
      boxShadow: "5px 15px 30px 0px rgba(126, 126, 177, 0.10)",
      "&:hover": {
        background: "#D91F31",
      },
    },
    gridInput: {
      marginBottom: "2rem",
      height: "75px",
    },
  })
);

const FormQuery: React.FC = () => {
  const classes = useStyles();
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<IQuery>();
  const formQueryTitleContext = useFormQueryTitle();
  const userState = useUserState();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const [firstLoading, setFirstLoading] = useState(true);

  useEffect(() => {
    if (firstLoading) {
      setFirstLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const optionsCNPJ = userState.listCNPJ.map((cnpj) => ({
    value: cnpj.CNPJ,
    label: cnpj.companyName,
  }));

  const handleChangeSelectedCompanies = useCallback(
    (event: string[]) => {
      if (!firstLoading) {
        setFieldValue("CNPJ", event);
      }
    },
    [setFieldValue, firstLoading]
  );

  return (
    <CardDefault>
      <Box width="100%" padding="3rem">
        <Grid item xs={12} style={{ display: "flex", paddingBottom: "1.2rem" }}>
          <Grid xs={10}>
            <Typography
              className={classes.titleCard}
              style={{ fontSize: isMobile ? "1.6rem" : "" }}
            >
              Consultar título
            </Typography>
          </Grid>
          <Grid
            xs={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: "1.6rem",
            }}
          >
            <CheckListIcon fontSize="small" />
          </Grid>
        </Grid>
        <Grid container style={{ display: "flex", width: "100%" }} spacing={2}>
          <Grid
            item
            xs={12}
            md={3}
            alignItems="center"
            className={classes.gridInput}
          >
            <DropdownSelectAll
              label="Razão social"
              options={optionsCNPJ}
              value={values.CNPJ}
              isCNPJ={true}
              isSubTitle={true}
              onChange={handleChangeSelectedCompanies}
              error={!!errors.CNPJ && !!touched.CNPJ}
              messageError={
                !!touched.CNPJ && typeof errors.CNPJ === "string"
                  ? errors.CNPJ
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12} md={3} className={classes.gridInput}>
            <CalendarRange
              //@ts-ignore
              style={{ height: "30px" }}
              name="dateRange"
              label="Data ou período"
              value={values.dateRange}
              onChange={(dateRange) => {
                setFieldValue("dateRange", dateRange);
                setFieldTouched("startDate", true);
                setFieldTouched("endDate", true);
                formQueryTitleContext.setRangeDate(
                  dateRange?.map((cont) => (cont ? cont : new Date())) ?? []
                );
              }}
              onBlur={() => {
                setFieldTouched("startDate", true);
                setFieldTouched("endDate", true);
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            alignItems="center"
            className={classes.gridInput}
          >
            <FormControl
              fullWidth
              className={classes.formControl}
              variant="outlined"
            >
              <InputLabel className={classes.inputLabel}> Situação</InputLabel>
              <Select
                className={classes.customSelect}
                aria-label="freightType"
                name="freightType"
                value={values.situation}
                onChange={({ target }) =>
                  setFieldValue("situation", target.value)
                }
                IconComponent={ExpandMoreIcon}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value={SITUATION_TYPE.ALL}>Todos</MenuItem>
                <MenuItem value={SITUATION_TYPE.OPEN}>A vencer</MenuItem>
                <MenuItem value={SITUATION_TYPE.DUE_DATE}>Vencido</MenuItem>
                <MenuItem value={SITUATION_TYPE.CLOSED}>Baixado</MenuItem>
              </Select>
            </FormControl>
            <FormHelperText error={!!errors.situation && !!touched.situation}>
              {!!touched.situation && errors.situation}
            </FormHelperText>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            alignItems="center"
            className={classes.gridInput}
          >
            <FormControl
              fullWidth
              className={classes.formControl}
              variant="outlined"
            >
              <InputLabel className={classes.inputLabel}> Filtro</InputLabel>
              <Select
                className={classes.customSelect}
                aria-label="freightType"
                name="freightType"
                value={values.filterBy}
                onChange={({ target }) =>
                  setFieldValue("filterBy", target.value)
                }
                IconComponent={ExpandMoreIcon}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value="documentDate">Emissão</MenuItem>
                <MenuItem value="dueDate">Vencimento</MenuItem>
              </Select>
            </FormControl>
            <FormHelperText error={!!errors.filterBy && !!touched.filterBy}>
              {!!touched.filterBy && errors.filterBy}
            </FormHelperText>
          </Grid>
        </Grid>

        <Grid item container xs={12} className={classes.containerButton}>
          <Button
            className={classes.styleButton}
            color="primary"
            type="submit"
            variant="contained"
            startIcon={
              formQueryTitleContext.loadingQuery && (
                <CircularProgress
                  color="secondary"
                  style={{ height: 20, width: 20 }}
                />
              )
            }
          >
            Consultar
          </Button>
        </Grid>
      </Box>
    </CardDefault>
  );
};

export default FormQuery;
