import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { getCorrectDate } from "@utils/index";
import { inject, injectable } from "inversify";
import { ITitlesResponseDTO } from "../dtos/IQueryTitlesDataDTO";
import { IQueryTitlesDTO } from "../dtos/IQueryTitlesDTO";
import { IQueryTitlesService } from "../models/IQueryTitlesService";

@injectable()
export class QueryTitlesService implements IQueryTitlesService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: IQueryTitlesDTO): Promise<ITitlesResponseDTO> {
    const { CNPJ, ...rest } = {
      ...data,
      beginDate: data.beginDate ? data.beginDate : undefined,
      endDate: data.endDate ? data.endDate : undefined,
    };

    const hasCNPJ = CNPJ.find((cnpj) => cnpj.length === 14);
    const hasBp = CNPJ.find((cnpj) => cnpj.length !== 14);

    let bpIDs: string[] = CNPJ.filter((cnpj) => cnpj.length !== 14);
    let CNPJs: string[] = CNPJ.filter((cnpj) => cnpj.length === 14);

    let titlesBp: ITitlesResponseDTO = {};
    let titlesCnpj: ITitlesResponseDTO = {};

    if (hasBp) {
      const responseBp = await this.httpInstance.get<ITitlesResponseDTO>(
        "/titles/v2",
        {
          params: { ...rest, CNPJ: bpIDs, action: "BP" },
        }
      );

      titlesBp = responseBp ?? {};
    }

    if (hasCNPJ) {
      const responseCNPJtitle = await this.httpInstance.get<ITitlesResponseDTO>(
        "/titles/v2",
        { params: { ...rest, CNPJ: CNPJs } }
      );
      titlesCnpj = responseCNPJtitle ?? {};
    }

    const titles: ITitlesResponseDTO = { ...titlesCnpj, ...titlesBp };

    const formattedTitles = titles.content?.map((title) => {
      return {
        ...title,
        dueDate: getCorrectDate(new Date(title.dueDate)),
        emissionDate: getCorrectDate(new Date(title.emissionDate)),
      };
    });

    return {
      content: formattedTitles,
      page: 1,
      total: titles.total,
    };
  }
}
