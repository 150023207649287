import React from "react";
import { Form, Formik } from "formik";
import { StyledButton } from "@components/StyledButton";
import { INozzlesFileRequest } from "@modules/nozzles/models";

interface Props {
  children?: React.ReactNode;
  label: string;
  nozzles: INozzlesFileRequest;
  onChangeModal: () => void;
  handleNozzlesFirstRegister: (e) => void;
}

const FormikRegisterImage: React.FC<Props> = ({ children, ...props }) => {
  const initialValues: INozzlesFileRequest = {
    document: props.nozzles.document,
    group: props.nozzles.group,
    file: props.nozzles.file,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        actions.resetForm();

        props.handleNozzlesFirstRegister(initialValues);

        props.onChangeModal && props.onChangeModal();
      }}
    >
      {(propsformik) => {
        return (
          <Form>
            <StyledButton
              type="submit"
              variant="contained"
              color="primary"
              style={{ color: "white" }}
              loading={propsformik.isSubmitting}
            >
              {props.label}
            </StyledButton>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormikRegisterImage;
