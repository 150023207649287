import { Column } from "@components/TableGenericPagination";

export interface ITableTitleHeadComissionsProps {
  name: string; // nome do frentista
  description: string; // nome do produto
  quantity: number; // Volume por produto em litros
  commission: number; // Comissão por produto em R$
  quantity_total: number; // Volume vendido total em litros
  commission_total: number; // Comissão total em R$
  blank?: string;
}

export const TableTitleHeadComissions: Column<
  ITableTitleHeadComissionsProps
>[] = [
  {
    title: "Nome do frentista",
    field: "name",
    align: "left",
    isVisibleMobile: true,
  },
  {
    title: "Produto",
    field: "description",
    align: "left",
    isVisibleMobile: true,
  },
  {
    title: "Volume por produto (L)",
    field: "quantity",
    align: "right",
    isVisibleMobile: false,
  },
  {
    title: "Comissão por produto (R$)",
    field: "commission",
    align: "right",
    isVisibleMobile: false,
  },
  {
    title: "Volume vendido total (L)",
    field: "quantity_total",
    align: "right",
    isVisibleMobile: false,
  },
  {
    title: "Comissão total (R$)",
    field: "commission_total",
    align: "right",
    isVisibleMobile: false,
  },
  {
    title: "",
    field: "blank",
    align: "center",
    isVisibleMobile: false,
  },
];
