import React from "react";
import {
  LabelDisplayedRowsArgs,
  TablePagination,
  TablePaginationBaseProps,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableRow,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

interface PaginationCustomProps extends TablePaginationBaseProps {
  count: number;
  page: number;
  rowsPerPage: number;
  siblingCount?: number; // Note: limita o numero de paginas vizinhas
  onPageChange?: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  onRowsPerPageChange?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  onChangeHandle?: (event: React.ChangeEvent<unknown>, newPage: number) => void;
  labelDisplayedRows?: (
    paginationInfo: LabelDisplayedRowsArgs
  ) => React.ReactNode;
}

const defaultLabelDisplayedRows: React.FC<LabelDisplayedRowsArgs> = (props) => {
  return (
    <span>
      <b>
        {" "}
        Mostrando itens{" "}
        <span style={{ color: "#FFB03A" }}>
          {props.from > 0 && props.from < 10 && props.to > 0 && props.to < 10
            ? `0${props.from}-0${props.to}`
            : props.from >= 10 && props.to >= 10
            ? `${props.from}-${props.to}`
            : props.from > 0 && props.from < 10 && props.to >= 10
            ? `0${props.from}-${props.to}`
            : `${props.from}-0${props.to}`}
        </span>
      </b>{" "}
      <br></br>
      <span style={{ padding: 0, margin: 0, opacity: 0.6 }}>
        Total de {props.count} itens
      </span>
    </span>
  );
};

const PaginationCustom: React.FC<PaginationCustomProps> = (props) => {
  const classes = useStyles();
  const { onPageChange, onChangeHandle } = props;

  const handlePageDefault = (event, newPage) => {
    if (onPageChange) {
      return onPageChange(event, newPage);
    }
    return onChangeHandle && onChangeHandle(event, newPage);
  };

  return (
    <div>
      <Table>
        <TableBody>
          <TableRow>
            <TablePagination
              style={{ margin: 0, border: "0px solid blue" }}
              className={`${classes.root} TablePagination-wrapper `}
              count={props.count} //          // Uso obrigatorio de acordo com a interface da lib
              page={props.page} //            // Uso obrigatorio de acordo com a interface da lib
              rowsPerPage={props.rowsPerPage} // Uso obrigatorio de acordo com a interface da lib
              rowsPerPageOptions={[]} //      // oculta o select default  <> Esses parametros nao sao utilizados porem precisam ser setados
              labelRowsPerPage={""} //        // Altera a label do select <> Esses parametros nao sao utilizados porem precisam ser setados
              labelDisplayedRows={({ from, to, count, page }) => {
                return defaultLabelDisplayedRows({ from, to, count, page });
              }}
              onPageChange={handlePageDefault} // props.onPageChange <> Uso obrigatorio de acordo com a interface da lib
              ActionsComponent={() => {
                return (
                  <div
                    style={{ border: "0px solid red" }}
                    className="Pagination-wrapper-div"
                  >
                    <Pagination
                      className={`${classes.rootPagination} TablePagination-wrapper `}
                      style={{ width: "fit-content", margin: 0 }}
                      count={Math.ceil(
                        Math.fround(props.count / props.rowsPerPage)
                      )}
                      defaultPage={1}
                      siblingCount={props.siblingCount ?? 1}
                      boundaryCount={1} // Limita Quantidade de elementos da ultima pagina
                      page={props.page + 1}
                      onChange={props.onChangeHandle}
                    />
                  </div>
                );
              }}
            />
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default PaginationCustom;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: "100%",
      display: "block",
      margin: 4,
      borderBottom: "none",
      backgroundColor: theme.palette.background.default,
      "& .Mui-selected": {
        // Aplica estilos apenas no botao selecionado - [componente de paginacao]
        backgroundColor: "#fff",
        borderRadius: 0,
        color: "#D91F05",
        fontWeight: "bold",
      },
      "& .MuiPaginationItem-page": {
        // Aplica estilos em todos os botoes - [componente de paginacao]
        margin: 0,
        Width: "3rem",
        height: "3rem",
        borderRadius: "0.5rem",
      },

      "& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected)": {
        // Aplica estilo nos botoes NAO selecionados
        fontWeight: 600,
        color: "#3E3D3D",
      },

      "& .MuiTablePagination-spacer": {
        display: "none",
      },

      "& .MuiToolbar-gutters": {
        display: "flex",
        justifyContent: "space-between",
        padding: 0,
        margin: 0,
        [theme.breakpoints.down("sm")]: {
          display: "inline-grid",
          gap: "16px",
        },
      },
    },
    rootPagination: {
      "& .MuiPagination-ul": {
        gap: "8px",
      },
      "& .MuiPaginationItem-ellipsis": {
        minWidth: "10px",
      },
    },
  })
);
