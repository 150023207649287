import * as Yup from "yup";

const SUPPORTED_FORMATS = ["image/*"];

const FormUploadImageValidationSchema = Yup.object().shape({
  file: Yup.mixed()
    .test("fileType", "Tipo de arquivo não suportado", (value) => {
      if (value) {
        for (let patternStr of SUPPORTED_FORMATS) {
          let pattern = new RegExp(patternStr);
          const test = pattern.test(value.type);
          return test;
        }
      }
      return false;
    })
    .required("Anexe um arquivo válido"),
});

export default FormUploadImageValidationSchema;
