import { ITableHeader } from "./interface";

export const TableHeader: ITableHeader[] = [
  { name: "", isVisibleMobile: true, align: "left" },
  { name: "Nº da nota", isVisibleMobile: true, align: "left" },
  { name: "Emissão", isVisibleMobile: false, align: "left" },
  { name: "Número de pedido", isVisibleMobile: false, align: "left" },
  { name: "Razão Social", isVisibleMobile: false, align: "left" },
  { name: "CNPJ", isVisibleMobile: false, align: "left" },
  { name: "Valor", isVisibleMobile: false, align: "right" },
  { name: "", isVisibleMobile: false, align: "left" },
];
