import {
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import AppError from "@utils/AppError";
import { useFormikContext } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ShoppingCartIcon } from "../../../../components/Icons";
import {
  IFormRequestValues,
  IProductQuantity,
} from "../../../../utils/interfaces";
import { useForm } from "../FormContext";
import { TableProducts } from "./TableProducts";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      marginBottom: "2rem",
      fontWeight: "bold",
    },
    container: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    itemSelect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    titleProduct: {
      display: "flex",

      "& p": {
        fontSize: "1.6rem",
        fontWeight: "bold",
      },
      "& :not(:first-child)": {
        marginLeft: "1rem",
      },
    },
    priceProduct: {
      fontSize: "1.2rem",
    },
    cartIcon: {
      fontSize: "2rem",
    },
  })
);

interface IProductSelecteToExclude {
  [propName: string]: boolean;
}

const FormItems: React.FC = () => {
  const classes = useStyles();
  const [, setProductSelectedToExclude] = useState<IProductSelecteToExclude>(
    {}
  );
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = useFormikContext<IFormRequestValues>();
  const { enqueueSnackbar } = useSnackbar();
  const formContext = useForm();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  useEffect(() => {
    setProductSelectedToExclude({});
    setFieldValue("products", []);
  }, [values.CNPJ, setFieldValue]);

  const handleAddProduct = async () => {
    const product = formContext.dataCustomer?.products.find(
      (ele) => ele.id === values.productSelected
    ) as IProductQuantity;

    try {
      product.quantity = null;
      product.hashPrice = uuidv4();
      product.deadlinePayment = product.payCond;

      setFieldValue("products", [...values.products, { ...product }]);
    } catch (error) {
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, { variant: error.variant });
      }
      enqueueSnackbar(
        "Erro ao baixar preço do produto selecionado, tente novamente",
        { variant: "error" }
      );
    }
  };

  const renderProducts = () => {
    if (!formContext.dataCustomer) return [];
    if (!values.address) return [];

    return formContext.dataCustomer.products.map((ele) => {
      return (
        <MenuItem key={ele.id} value={ele.id} className={classes.itemSelect}>
          <div className={classes.titleProduct}>
            <Typography variant="button">{ele.id}</Typography>
            <Typography variant="button">{ele.description}</Typography>
          </div>
        </MenuItem>
      );
    });
  };

  return (
    <div className={classes.container}>
      <Typography
        className={classes.title}
        variant={isMobile ? "subtitle1" : "h6"}
      >
        Itens
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            disabled={!formContext.dataCustomer?.hiddePrice}
          >
            <InputLabel>Descrição do produto</InputLabel>

            <Select
              error={
                !!touched.CNPJ && errors.CNPJ
                  ? !!errors.CNPJ
                  : !!touched.address && !!errors.address
              }
              onBlur={() => {
                setFieldTouched("CNPJ", true);
                setFieldTouched("address", true);
              }}
              name="productSelected"
              id="productSelected"
              onChange={({ target }) => {
                setFieldValue("productSelected", target.value);
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              startAdornment={
                <InputAdornment position="start">
                  <ShoppingCartIcon className={classes.cartIcon} />
                </InputAdornment>
              }
            >
              {renderProducts()}
            </Select>
            <FormHelperText
              error={
                !!touched.CNPJ && errors.CNPJ
                  ? !!errors.CNPJ
                  : !!touched.address && !!errors.address
              }
            >
              {!!touched.CNPJ && errors.CNPJ
                ? errors.CNPJ
                : !!touched.address && errors.address}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} container justify="center">
          <Button
            onClick={handleAddProduct}
            variant="contained"
            color="primary"
          >
            Adicionar
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ overflowX: "auto", display: "inline-flex" }}
        >
          <TableProducts />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="space-evenly">
            <Button
              variant="outlined"
              color="primary"
              disabled={!Boolean(values.products.length)}
              onClick={() => resetForm()}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!Boolean(values.products.length)}
            >
              <Typography>
                {formContext.loadingCreateOrder
                  ? "Criando pedido, aguarde..."
                  : "Concluir"}
              </Typography>
              {formContext.loadingCreateOrder && (
                <CircularProgress
                  color="secondary"
                  style={{
                    marginLeft: "1rem",
                    height: "2rem",
                    width: "2rem",
                  }}
                />
              )}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center">
            Sugerimos a programação com 24 horas de antecedência.
          </Typography>
          <Typography align="center">
            Os pedidos programados poderão sofrer alterações de preços e
            sujeitos a confirmação.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default FormItems;
