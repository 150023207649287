import React from "react";
import {
  Box,
  createStyles,
  LinearProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const SkeletonCardTitle: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.paper}>
      <Box display="flex">
        <Typography
          style={{
            width: "60%",
          }}
          className={classes.status}
        >
          <Skeleton animation="wave" width={45} height={26} />
        </Typography>
      </Box>
      <Box display="block" style={{ width: "100%", marginTop: "-5px" }}>
        <Typography
          style={{ width: "100%", display: "flex" }}
          className={classes.value}
        >
          R$
          <Skeleton
            variant="text"
            width={"60%"}
            height={29}
            style={{ marginLeft: "5%" }}
          />
        </Typography>
        <Box style={{ width: "40%", marginLeft: "32px" }}>
          <LinearProgress color="secondary" />
        </Box>
      </Box>

      <Box display="block" style={{ width: "100%" }}>
        <Skeleton
          variant="text"
          width={"60%"}
          height={29}
          style={{ marginLeft: "12%", marginTop: "6%" }}
        />

        <Box
          style={{ width: "100%", height: "10px", display: "flex" }}
          className={classes.reference}
        >
          Título
          <Skeleton
            variant="text"
            width={"40%"}
            height={21}
            style={{ marginLeft: "6%" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SkeletonCardTitle;

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "31px",
      color: "#3E3D3D",
      background: "#FFFFFF",
      border: "1px solid rgba(114, 101, 81, 0.1)",
      borderRadius: "12px",
      height: "232px",
      width: "312px",
      display: "block",
      marginBottom: "24px",
      marginRight: "24px",
    },
    color: {
      width: "89px",
      height: "4px",

      borderRadius: "0px 0px 4px 4px",
    },
    status: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "20px",
      marginTop: "32px",
      marginLeft: "32px",
    },
    installments: {
      marginTop: "32px",
      marginRight: "32px",
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      textAlign: "right",
      color: "#3E3D3D",
      opacity: 0.7,
    },
    reference: {
      marginTop: "7px",
      marginLeft: "33px",
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "56%",
      lineHeight: "21px",
      color: "#3E3D3D",
      opacity: 0.7,
    },
    value: {
      marginTop: "43px",
      marginLeft: "32px",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "74%",
      lineHeight: "29px",
      color: "#3E3D3D",
      opacity: 0.7,
    },
    name: {
      marginTop: "18px",
      marginLeft: "32px",
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "60%",
      lineHeight: "21px",
      color: "#3E3D3D",
      opacity: "0.7",
    },
  })
);
