import {
  Box,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import union from "../../assets/union.svg";

const SkeletonCardOrder: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Box className={isMobile ? classes.paperMobile : classes.paper}>
      <Box
        className={isMobile ? classes.imgOrderMobile : classes.imgOrder}
        style={{
          width: isMobile ? "20%" : widthIcon,
        }}
      >
        <Skeleton
          variant="circle"
          width={isMobile ? 28 : 36}
          height={isMobile ? 28 : 36}
        />
      </Box>
      <Box
        className={classes.order}
        style={{
          width: isMobile ? "18%" : widthOrder,
          textAlign: isMobile ? "left" : "center",
        }}
      >
        <Typography className={isMobile ? classes.titleMobile : classes.title}>
          Pedido
        </Typography>
        <Skeleton
          variant="rect"
          className={isMobile ? classes.valueMobile : classes.value}
        />
      </Box>
      {!isMobile && (
        <>
          <Box
            className={classes.order}
            style={{
              width: widthDateCreate,
              textAlign: "right",
            }}
          >
            <Typography className={classes.title}>Data de criação</Typography>
            <Skeleton variant="rect" className={classes.value} />
          </Box>
          <Box
            className={classes.order}
            style={{
              width: widthDateDelivery,
              textAlign: "right",
            }}
          >
            <Typography className={classes.title}>Data de entrega</Typography>

            <Skeleton variant="rect" className={classes.value} />
          </Box>
        </>
      )}

      <Box
        className={classes.order}
        style={{
          width: isMobile ? "36%" : widthSocialReason,
          textAlign: isMobile ? "left" : "center",
        }}
      >
        <Typography className={isMobile ? classes.titleMobile : classes.title}>
          Razão social
        </Typography>
        <Skeleton variant="rect" className={classes.value} />
      </Box>
      {!isMobile && (
        <>
          <Box
            className={classes.order}
            style={{
              width: widthDriver,
            }}
          >
            <Typography className={classes.title}>Motorista</Typography>
            <Skeleton variant="rect" className={classes.value} />
          </Box>
          <Box
            className={classes.order}
            style={{
              width: widthPlate,
            }}
          >
            <Typography className={classes.title}>Placa</Typography>
            <Skeleton variant="rect" className={classes.value} />
          </Box>
          <Box
            className={classes.order}
            style={{
              width: widthStatus,
            }}
          >
            <Typography className={classes.title}>Status Financeiro</Typography>
            <Skeleton variant="rect" className={classes.value} />
          </Box>
        </>
      )}
      {isMobile && (
        <Box
          className={classes.order}
          style={{
            width: "10%",
          }}
        >
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <img src={union} width="8px" height="14px" alt="ícone de seta" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default SkeletonCardOrder;

const widthIcon = "5.5%";
const widthOrder = "5.5%";
const widthDateCreate = "13.2%";
const widthDateDelivery = "13%";
const widthSocialReason = "13.8%";
const widthDriver = "15.8%";
const widthPlate = "8%";
const widthStatus = "15%";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "31px",
      color: "#3E3D3D",
      background: "#FFFFFF",
      border: "1px solid rgba(114, 101, 81, 0.1)",
      borderRadius: "12px",
      width: "100%",
      display: "flex",
      marginBottom: "24px",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: theme.spacing(1.4),
      paddingBottom: theme.spacing(1.4),
    },
    paperMobile: {
      height: "56px",
      backgroundColor: "transparent",
      border: "none",
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "31px",
      color: "#3E3D3D",
      borderRadius: "12px",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: theme.spacing(1.4),
      paddingBottom: theme.spacing(1.4),
    },
    order: {
      display: "block",
    },
    imgOrder: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(1.4),
    },
    imgOrderMobile: {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      backgroundColor: "#FFFFFF",
      borderRadius: "12px",
      height: "56px",
      justifyContent: "center",
      border: "1px solid rgba(114, 101, 81, 0.1)",
    },
    title: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "13.6px",
      lineHeight: "21px",
      color: "#3E3D3D",
      opacity: 0.7,
      paddingRight: "1px",
    },
    value: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "13.6px",
      lineHeight: "21px",
      color: "#3E3D3D",
      opacity: 0.7,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    titleMobile: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "16px",
      color: "#3E3D3D",
      opacity: 0.7,
    },
    valueMobile: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      color: "#3E3D3D",
      opacity: 0.7,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
  })
);
