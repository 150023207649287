import { Grid, Table, TableCell, TableRow } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

interface ISkeletonTableListUsersProps {
  rows?: number;
  cols?: number;
}

const SkeletonTableListUsers: React.FC<ISkeletonTableListUsersProps> = ({
  ...props
}) => {
  const rows = 11;
  const cols = 7;

  const generateSkeletonRow = (key) => {
    return (
      <>
        <TableRow key={key}>
          {[...Array(cols).keys()].map((col) => (
            <TableCell key={col} height={"40px"}>
              <Skeleton variant="rect" width={"141px"} height={"40px"} />
            </TableCell>
          ))}
        </TableRow>
      </>
    );
  };

  return (
    <div style={{ padding: "20px" }}>
      <Grid container style={{ padding: "16px" }}>
        <Grid item xs={2}>
          <Skeleton variant="rect" width={"160px"} height={"40px"}></Skeleton>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            justifyContent: "start",
            marginLeft: "-10px",
          }}
        >
          <Skeleton variant="rect" width={"160px"} height={"40px"}></Skeleton>
        </Grid>
        <Grid
          item
          xs={8}
          style={{
            display: "flex",
            justifyContent: "end",
            marginLeft: "-20px",
          }}
        >
          <Skeleton variant="rect" width={"100px"} height={"40px"}></Skeleton>
        </Grid>
      </Grid>

      <Table>
        <tbody>
          {[...Array(rows).keys()].map((row) => generateSkeletonRow(row))}
        </tbody>
      </Table>
    </div>
  );
};

export default SkeletonTableListUsers;
