import BreadcrumbCustom, {
  BreadcrumbChild,
} from "@components/BreadcrumbCustom";
import CardLayerActions from "@components/CardLayerActions";
import { CheckListIcon } from "@components/Icons";
import Layout from "@components/Layout";
import ROUTES from "@config/routes";
import { PeakTimeHistoryProvider } from "@context/PeakTimeHistoryContext";
import {
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ValidationFilterReclamation from "@pages/User/ReclamationConsult/ValidationYup/ValidationFilterReclamation";
import { endOfMonth, startOfMonth } from "date-fns";
import { Form, Formik } from "formik";
import React from "react";
import FilterPeakTimeHistory from "./FilterPeakTimeHistory";
import SalesVolumeChartHistory from "./SalesVolumeChartHistory";

const PeakTimeHistory: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const initalValues = {
    price: "",
    products: [],
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    date_range: [startOfMonth(new Date()), endOfMonth(new Date())],
  };

  const breadcrumbData: BreadcrumbChild[] = [
    {
      link: ROUTES.USER_ROUTES.PERFORMANCE_MANAGEMENT,
      label: "Gestão de performance",
    },
    {
      label: "Horário de pico ",
    },
  ];

  return (
    <Layout disableFooter>
      <Grid
        container
        style={{
          width: "auto",
          padding: isMobile ? "0px 20px" : "0px 130px",
        }}
      >
        <Grid xs={10} style={{ padding: 8 }}>
          <BreadcrumbCustom data={breadcrumbData} />
        </Grid>
        <CardLayerActions
          title="Consultar histórico de horário de pico"
          icon={CheckListIcon}
        >
          <Formik
            initialValues={initalValues}
            onSubmit={() => {}}
            validationSchema={ValidationFilterReclamation}
          >
            <Form>
              <FilterPeakTimeHistory />
            </Form>
          </Formik>
        </CardLayerActions>

        <Grid container justifyContent="center">
          <Typography
            style={{
              color: "#3E3D3D",
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            Produtos para simulação de carregamento
          </Typography>
        </Grid>

        <Container>
          <Grid container style={{ paddingTop: 20, paddingBottom: 60 }}>
            <Grid container xs={12}>
              <SalesVolumeChartHistory />
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Layout>
  );
};

const ContainerPeakTimeHistory: React.FC = () => {
  return (
    <PeakTimeHistoryProvider>
      <PeakTimeHistory />
    </PeakTimeHistoryProvider>
  );
};

export default ContainerPeakTimeHistory;
