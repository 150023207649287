import React from "react";
import ApexCharts from "react-apexcharts";
import { Box } from "@material-ui/core";

export type SeriesType = {
  name: string;
  data: number[];
};

export type CategoriesType = string[];

type ChartProps<T> = {
  series: T[];
  categories: CategoriesType;
  isDecimal?: boolean;
};

class GenericColumnChart<T> extends React.Component<ChartProps<T>> {
  render() {
    const { series, categories, isDecimal } = this.props;

    const options = {
      series,
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 2,
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories,
      },
      yaxis: {
        title: {
          text: "",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            if (isDecimal) {
              return new Intl.NumberFormat("pt-BR", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 3,
              }).format(val);
            }
            return new Intl.NumberFormat("pt-BR", {
              style: "decimal",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(val);
          },
        },
      },
      colors: ["#9F1313", "#D91F05", "#F16539", "#FF9900", "#C2862D"],
    };

    return (
      <Box>
        {/* @ts-ignore */}
        <ApexCharts options={options} series={series} type="bar" height={350} />
      </Box>
    );
  }
}

export default GenericColumnChart;
