import { inject, injectable } from "inversify";
import * as IHttpService from "@modules/infra/http/models/IHttpService";

import {
  ICommunicationService,
  IRequestCreateCommunication,
  IRequestUpdateComunicationDTO,
  IResponseComunicationDTO,
} from "@modules/communications/model/ICommunication";

import { Types } from "@ioc/types";

@injectable()
export class CommunicationService implements ICommunicationService {
  @inject(Types.IHttpService)
  private httpInstance!: IHttpService.IHttpService;

  public async create(data: IRequestCreateCommunication[]): Promise<void> {
    try {
      const formData = new FormData();

      // Transforma array [0]['C001']-[1]['C002'] em matriz de string '[['C001'],['C002']]'
      const dataCenter = data.reduce((acc, curr) => {
        acc.push(curr.centers);
        return acc;
      }, [] as string[][]);

      const dataCenterFormat = JSON.stringify(dataCenter);
      formData.append("centers", dataCenterFormat);

      data.forEach((item) => {
        formData.append("images", item.file);
      }, []);

      const dataExpiration = data.reduce((acc, curr) => {
        acc.push(curr?.expiresIn);
        return acc;
      }, [] as string[]);
      formData.append("expiresIn", JSON.stringify(dataExpiration));

      await this.httpInstance.post("/announcement", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Certifique-se de definir o tipo de conteúdo como 'multipart/form-data'
        },
      });
    } catch (error) {
      throw error;
    }
  }

  public async findAll(data?: string[]): Promise<IResponseComunicationDTO[]> {
    try {
      const cnpjs = data && data.length > 0 ? data.join(",") : "";

      const queryParams = {
        customerCNPJ: cnpjs,
      };

      const response: IResponseComunicationDTO[] = await this.httpInstance.get(
        "/announcement",
        {
          params: cnpjs.length === 0 ? "" : queryParams,
        }
      );

      return response;
    } catch (error) {
      throw error;
    }
  }

  public async update(data: IRequestUpdateComunicationDTO): Promise<void> {
    try {
      const bodyParams = {
        centers: data.centers,
      };

      await this.httpInstance.put(`/announcement/${data.id}`, bodyParams);
    } catch (error) {
      throw error;
    }
  }

  public async deleteAll(ids: string[]): Promise<void> {
    try {
      const config = {
        data: {
          announcementIds: ids,
        },
      };

      return this.httpInstance.delete("/announcement", config);
    } catch (error) {
      throw new Error(`[deleteAll]:CommunicationService Error: ${error}`);
    }
  }

  public async deleteOne(id?: string): Promise<void> {
    try {
      if (!id) throw new Error("CommunicationService Error: id is undefined");
      if (id.length === 0)
        throw new Error("CommunicationService Error: id is empty");

      const config = {
        data: {
          announcementIds: [id],
        },
      };

      return this.httpInstance.delete("/announcement/", config);
    } catch (error) {
      throw error;
    }
  }
}
