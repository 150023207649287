import React from "react";
import { useState, useMemo, useCallback, useEffect } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTheme } from "@mui/material/styles";
import {
  createStyles,
  makeStyles,
  Grid,
  useMediaQuery,
  MenuItem,
  Checkbox,
  Typography,
  Select,
  FormHelperText,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { capitalizeFirstLetterFullString, maskCNPJ } from "@utils/index";

interface Option {
  value: string;
  label: string;
}

interface Props {
  options: Option[];
  value: string[];
  label: string;
  onChange: (values: string[]) => void;
  isDisabled?: boolean;
  isCNPJ?: boolean;
  isSubTitle?: boolean;
  error?: boolean;
  messageError?: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    itemSelectTitle: {
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
    },
    itemSelectSubTitle: {
      color: "#D5D1CB",
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "46px",
      padding: 0,
      marginTop: "-1.2rem",
    },
    itemSelect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      height: "6rem",
      paddingTop: "1rem",
    },
    customSelect: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      marginTop: "0",
      minHeight: "5rem",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
      paddingLeft: "14px",

      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(114, 101, 81, 0.20)",
      },
      "& .MuiSvgIcon-root": {
        fill: "#D91F05",
      },
      borderRadius: "8px",
    },
    checkbox: {
      padding: 4,
      "& .MuiSvgIcon-root": {
        width: 18,
        height: 18,
      },
    },
    formControl: {
      borderRadius: "8px",
      width: "100%",
      height: "100%",
    },
    inputLabel: {
      position: "absolute",
      top: "-2px",
      left: "12px",
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 700,
      backgroundColor: "#fff",
      paddingLeft: "0.2rem",
      paddingRight: "0.8rem",
      marginLeft: "0rem",
      width: "auto",
    },
  })
);

const DropdownSelectAll: React.FC<Props> = ({
  options,
  value,
  error,
  messageError,
  onChange,
  isDisabled = false,
  isCNPJ = false,
  isSubTitle = false,
  label,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  useEffect(() => {
    setSelectedValues(value);
  }, [value]);

  useEffect(() => {
    onChange(selectedValues);
  }, [selectedValues, onChange]);

  const handleSelectAll = useCallback(() => {
    if (selectAll) {
      setSelectedValues([]);
      setSelectAll(false);
    } else {
      setSelectedValues(options.map((option) => option.value));
      setSelectAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectAll, selectAll]);

  const handleOptionChange = useCallback(
    (optionValue: string, optionSelected: boolean) => {
      setSelectedValues((prevSelectedValues) => {
        if (optionSelected) {
          return [...prevSelectedValues, optionValue];
        } else {
          return prevSelectedValues.filter((value) => value !== optionValue);
        }
      });
    },
    []
  );

  const allOptionsSelected = useMemo(
    () => selectedValues.length === options.length,
    [selectedValues, options]
  );

  const someOptionsSelected = useMemo(
    () => selectedValues?.length > 0 && !allOptionsSelected,
    [selectedValues, allOptionsSelected]
  );

  const handleLabels = useCallback(
    (selected: string[]) => {
      const selectedLabels = options
        .filter((option) => selected.includes(option.value))
        .map((option) => capitalizeFirstLetterFullString(option.label));
      if (selected.length === 0) {
        return "Selecione";
      } else if (allOptionsSelected) {
        return "Todos";
      } else {
        return selectedLabels.join(", ");
      }
    },
    [options, allOptionsSelected]
  );

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newValues = e.target.value as string[];
    if (JSON.stringify(newValues) !== JSON.stringify(selectedValues)) {
      setSelectedValues(newValues);
    }
  };

  return (
    <FormControl fullWidth className={classes.formControl} variant="outlined">
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <Select
        className={classes.customSelect}
        multiple
        displayEmpty
        fullWidth
        disabled={isDisabled}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        value={selectedValues}
        renderValue={(selected) => handleLabels(selected as string[])}
        IconComponent={ExpandMoreIcon}
        onChange={handleChange}
      >
        <MenuItem
          key="select-all"
          className={classes.itemSelect}
          style={{ pointerEvents: "none" }}
        >
          <Grid container>
            <Grid item alignContent="center" style={{ pointerEvents: "auto" }}>
              <Checkbox
                checked={allOptionsSelected}
                indeterminate={someOptionsSelected}
                onChange={handleSelectAll}
                className={!isMobile ? undefined : classes.checkbox}
                style={{
                  color: "#D91F05",
                  width: isMobile ? "10px" : "",
                  paddingLeft: isMobile ? "5px" : "",
                  marginRight: isMobile ? "10px" : "",
                }}
              />
            </Grid>
            <Grid item alignContent="center">
              <Typography
                className={classes.itemSelectTitle}
                style={{ fontSize: isMobile ? "12px" : "" }}
              >
                Todos
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
        {options?.map((option) => (
          <MenuItem
            className={classes.itemSelect}
            key={option.value}
            value={option.value}
            onClick={() => {
              handleOptionChange(
                option.value,
                !selectedValues?.includes(option.value)
              );
            }}
          >
            <Grid container>
              <Grid item alignContent="center">
                <Checkbox
                  checked={selectedValues?.includes(option.value)}
                  className={!isMobile ? undefined : classes.checkbox}
                  style={{
                    color: "#D91F05",
                    width: isMobile ? "10px" : "",
                    paddingLeft: isMobile ? "5px" : "",
                    marginRight: isMobile ? "10px" : "",
                  }}
                />
              </Grid>
              <Grid item alignContent="center">
                <Typography
                  className={classes.itemSelectTitle}
                  style={{ fontSize: isMobile ? "12px" : "" }}
                >
                  {capitalizeFirstLetterFullString(option.label)}
                </Typography>
                {isSubTitle && (
                  <Typography className={classes.itemSelectSubTitle}>
                    {isCNPJ ? maskCNPJ(option.value) : option.value}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={error}>{messageError}</FormHelperText>
    </FormControl>
  );
};

export default DropdownSelectAll;
