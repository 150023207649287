export interface ITableOrder {
  option: string;
  align: "left" | "right" | "inherit" | "center" | "justify" | undefined;
  id: string;
  isVisibleMobile: boolean;
}

export const ITableOrderTitles: ITableOrder[] = [
  { option: "", align: "center", id: "downloadNf", isVisibleMobile: false },
  { option: "Pedido", align: "left", id: "orderNumber", isVisibleMobile: true },
  {
    option: "Data da Entrega",
    align: "right",
    id: "deliveryDate",
    isVisibleMobile: true,
  },

  { option: "Filial", align: "center", id: "filial", isVisibleMobile: false },
  {
    option: "Razão social",
    align: "left",
    id: "companyName",
    isVisibleMobile: false,
  },
  { option: "Produto", align: "left", id: "product", isVisibleMobile: false },
  {
    option: "Quantidade",
    align: "right",
    id: "quantity",
    isVisibleMobile: false,
  },
  {
    option: "Valor unitário",
    align: "right",
    id: "unitaryValue",
    isVisibleMobile: false,
  },
  {
    option: "Valor total",
    align: "right",
    id: "freightType",
    isVisibleMobile: false,
  },
  {
    option: "Condição de pagamento",
    align: "center",
    id: "payment",
    isVisibleMobile: false,
  },
  {
    option: "Frete",
    align: "center",
    id: "totalValue",
    isVisibleMobile: false,
  },
  {
    option: "Status Financeiro",
    align: "center",
    id: "financialStatus",
    isVisibleMobile: false,
  },
  {
    option: "Status do Pedido",
    align: "center",
    id: "orderStatus",
    isVisibleMobile: false,
  },
  { option: "", align: "center", id: "cancelOrder", isVisibleMobile: false },
  {
    option: "",
    align: "center",
    id: "calendarUpdateDelivery",
    isVisibleMobile: false,
  },
];
