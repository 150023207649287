import React, { ReactNode } from "react";
import { Button, ButtonProps, Typography } from "@material-ui/core";

interface ButtonDefaultProps extends ButtonProps {
  children?: ReactNode;
}

const ButtonDefault: React.FC<ButtonDefaultProps> = ({
  children,
  style,
  ...props
}) => {
  return (
    <Button
      {...props}
      variant={props.variant ? props.variant : "contained"}
      color="primary"
      style={{
        textTransform: "initial",
        padding: "8px 32px 8px 32px",
        opacity: props.disabled ? 0.5 : 1,
        ...style,
      }}
      onClick={props.onClick}
    >
      <Typography
        component={"span"}
        variant="subtitle2"
        style={{
          fontWeight: 700,
          fontSize: "inherit",
        }}
      >
        {children}
      </Typography>
    </Button>
  );
};

export default ButtonDefault;
