import Layout from "@components/Layout";
import Title from "@components/Title";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import { Types } from "@ioc/types";
import { createStyles, LinearProgress, makeStyles } from "@material-ui/core";
import { IGetTitleImagesSeenOrNotService } from "@modules/titles/models/IGetTitleImagesSeenOrNotService";
import AppError from "@utils/AppError";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import {
  ListInvoicesImagesProvider,
  useListInvoicesImages,
} from "./ListInvoicesImagesContext";
import ModalImage from "./ModalImage";
import SkeletonTableInvoicesImages from "./SkeletonTableListInvoicesImages";
import TableInvoices from "./TableInvoices";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      margin: "0 5rem",
    },
  })
);

const ListInvoicesImages: React.FC = () => {
  const classes = useStyles();

  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();
  const { userID } = useUserState();
  const listInvoicesImagesContext = useListInvoicesImages();

  const [loadingGetTitles, setLoadingGetTitles] = useState(false);

  useEffect(() => {
    async function fetch() {
      try {
        setLoadingGetTitles(true);
        const listTitleImagesNotSeenService = iocContext.serviceContainer.get<
          IGetTitleImagesSeenOrNotService
        >(Types.Titles.IGetTitleImagesSeenOrNotService);

        const titles = await listTitleImagesNotSeenService.execute({
          userID,
          limit: listInvoicesImagesContext.numberOfItemsInList,
          offset: listInvoicesImagesContext.page - 1,
        });
        listInvoicesImagesContext.setListTitle(titles);
      } catch (error) {
        if (error instanceof AppError) {
          enqueueSnackbar(error.message, { variant: error.variant });
        }
      } finally {
        setLoadingGetTitles(false);
      }
    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    enqueueSnackbar,
    iocContext.serviceContainer,
    userID,
    listInvoicesImagesContext.page,
  ]);

  return (
    <Layout disableToolbar={true} disableToolbarMobile={true}>
      {loadingGetTitles && <LinearProgress color="secondary" />}
      <Title title="Comprovantes" />
      <div className={classes.container}>
        {loadingGetTitles ? (
          <SkeletonTableInvoicesImages cols={8} rows={10} />
        ) : (
          <TableInvoices />
        )}
      </div>

      <ModalImage />
    </Layout>
  );
};

const Container: React.FC = () => {
  return (
    <ListInvoicesImagesProvider>
      <ListInvoicesImages />
    </ListInvoicesImagesProvider>
  );
};

export default Container;
