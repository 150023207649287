import React from "react";
import {
  Button,
  Container,
  Modal,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { CloseIcon, WarningIcon } from "@components/Icons";

interface ModalInactivatedUserProps {
  showModal: () => void;
  blockUser: (userID: string) => void;
  unlockUser: (userID: string) => void;
  userID: string;
  enabled: boolean;
}

const ModalInactivatedUser = ({
  showModal,
  blockUser,
  userID,
  enabled,
  unlockUser,
}: ModalInactivatedUserProps) => {
  const classes = useStyles();

  return (
    <Modal open={true} onClose={showModal}>
      <Paper className={classes.Paper}>
        <Container className={classes.CloseIcon}>
          <CloseIcon onClick={showModal} />
        </Container>
        <Container className={classes.Image}>
          <WarningIcon />
        </Container>

        <Typography className={classes.TitleInactivated}>
          {`${enabled ? "Inativar" : "Ativar"} usuário`}
        </Typography>

        <Typography className={classes.MessageConfirm}>
          {`Você tem certeza que deseja ${
            enabled ? "inativar" : "ativar"
          } usuário?`}
        </Typography>

        <Container className={classes.ContainerButton}>
          <Button
            className={classes.Button}
            variant="contained"
            color="primary"
            onClick={showModal}
          >
            Não
          </Button>

          <Button
            className={classes.Button}
            variant="text"
            color="primary"
            onClick={
              enabled ? () => blockUser(userID) : () => unlockUser(userID)
            }
          >
            {enabled ? "Inativar" : "Ativar"}
          </Button>
        </Container>
      </Paper>
    </Modal>
  );
};

const useStyles = makeStyles({
  Paper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "none",
    height: "37rem",
    width: "35rem",
    padding: "3rem",
    textAlign: "center",
    position: "fixed",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    borderRadius: "4px",
  },
  Image: {
    backgroundColor: "#FFF5F7",
    borderRadius: "50%",
    height: "8rem",
    width: "8rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  TitleInactivated: {
    fontFamily: "Montserrat",
    fontWeight: 700,
  },
  MessageConfirm: {
    color: "#BABABA",
    fontSize: "1.3rem",
  },
  ContainerButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  CloseIcon: {
    display: "flex",
    alignItems: "top",
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  Button: {
    textTransform: "capitalize",
    boxShadow: "none",
  },
});

export default ModalInactivatedUser;
