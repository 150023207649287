import { inject, named, injectable } from "inversify";

import { Types } from "@ioc/types";
import { cleanParams } from "@utils/index";
import * as IHttpService from "@modules/infra/http/models/IHttpService";

import {
  IGetNozzlesService,
  INozzleAdminCompanyResponse,
  INozzleAdminRequest,
  INozzleAdminResponse,
  INozzleApprovedRequest,
  INozzleProductResponse,
  INozzleRejectRequest,
  INozzlesFileRequest,
  INozzlesFileResponse,
  INozzlesRequest,
  INozzlesResponse,
} from "@modules/nozzles/models";

@injectable()
export class GetNozzlesService implements IGetNozzlesService {
  @inject(Types.IHttpService)
  @named("pump")
  private httpInstance!: IHttpService.IHttpService;

  async getNozzles(data: INozzlesRequest): Promise<INozzlesResponse[]> {
    const params = {
      document: data.document,
      scopes: `["whichProduct"]`,
      action: `list_by_group`,
    };
    try {
      const nozzlesResponse = await this.httpInstance.get<INozzlesResponse[]>(
        `/product_nozzle/`,
        {
          params: cleanParams(params),
        }
      );
      return nozzlesResponse;
    } catch (err) {
      throw err;
    }
  }

  async addNozzlesFile(
    _data: INozzlesFileRequest
  ): Promise<INozzlesFileResponse> {
    const data = new FormData();

    data.append("document", _data.document);
    data.append("group", _data.group!);
    data.append("imageNozzles", _data.file!);

    try {
      const nozzlesFileResponse = await this.httpInstance.post<
        INozzlesResponse
      >(`/request_product/`, data);

      return nozzlesFileResponse;
    } catch (err) {
      throw err;
    }
  }

  async updateNozzlesFile(
    _data: INozzlesFileRequest
  ): Promise<INozzlesFileResponse> {
    const data = new FormData();

    data.append("document", _data.document);
    data.append("group", _data.group!);
    data.append("imageNozzles", _data.file!);
    data.append("title", _data.title!);
    data.append("description", _data.description!);

    try {
      const nozzlesFileResponse = await this.httpInstance.post<
        INozzlesResponse
      >(`/request_product/`, data, {
        headers: { "content-type": `multipart/form-data;` },
      });

      return nozzlesFileResponse;
    } catch (err) {
      throw err;
    }
  }

  async approvedNozzles(data: INozzleApprovedRequest): Promise<void> {
    try {
      const params = {
        document: data.cnpj,
        action: `update_bulk_nozzles`,
      };

      const body = data.nozzleGroup.map((item) => ({
        product_id: item.product_id,
        nozzle: item.nozzle,
      }));

      await this.httpInstance.patch<void>(`/product_nozzle`, body, {
        params: cleanParams(params),
      });
    } catch (err) {
      throw err;
    }
  }

  async rejectNozzles(data: INozzleRejectRequest): Promise<void> {
    try {
      const body = {
        reject_reason: data.rejection_reason,
        status: "REJECTED",
      };
      await this.httpInstance.patch<void>(
        `/request_product/${data.idGroup}`,
        body
      );
    } catch (err) {
      throw err;
    }
  }

  async getNozzlesAdmin(
    data?: INozzleAdminRequest
  ): Promise<INozzleAdminResponse> {
    try {
      const params = {
        status: data?.status,
        document: data?.cnpj?.join(","),
        beginDate: data?.registrationDate?.[0],
        endDate: data?.registrationDate?.[1],
      };

      const response = await this.httpInstance.get<INozzleAdminResponse>(
        `/request_product/summary`,
        {
          params: cleanParams(params),
        }
      );

      return response;
    } catch (err) {
      throw err;
    }
  }

  async getNozzlesAdminCompany(): Promise<INozzleAdminCompanyResponse[]> {
    try {
      const response = await this.httpInstance.get<
        INozzleAdminCompanyResponse[]
      >(`/company`);
      return response;
    } catch (err) {
      throw err;
    }
  }

  async getNozzleProduct(): Promise<INozzleProductResponse[]> {
    try {
      return await this.httpInstance.get<INozzleProductResponse[]>(
        `/product`,
        {}
      );
    } catch (err) {
      throw err;
    }
  }
}
