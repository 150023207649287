import { Grid, Portal, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";

import {
  IRoadLoadAllNormalized,
  IRoadRowCount,
} from "@pages/User/RoadLoad/model";
import { useRoadLoad } from "@pages/User/RoadLoad/context/RoadLoadContext";
import HomeRoadLoad from "@pages/User/RoadLoad/view/HomeRoadLoad";
import AllRoadLoad from "@pages/User/RoadLoad/view/AllRoadLoad";

import BreadcrumbCustom, {
  BreadcrumbChild,
} from "@components/BreadcrumbCustom";
import Notification from "@components/Notification";

import ROUTES from "@config/routes";

const ViewLoadRoad: React.FC = () => {
  const {
    roadLoad,
    normalizedRoadLoad,
    handleRoadLoadMethod,
    onlyDriver,
  } = useRoadLoad();

  const roadNormalized: IRoadLoadAllNormalized = [
    "upcomingScheduled",
    "scheduledNormalized",
    "waitingNormalized",
    "finishedNormalized",
    "blockedNormalized",
    "canceledNormalized",
  ].reduce((acc, key) => {
    acc[key] = normalizedRoadLoad(roadLoad)?.[key];
    return acc;
  }, {} as IRoadLoadAllNormalized);

  const roadRowsCount: IRoadRowCount = [
    "waiting",
    "scheduled",
    "finished",
    "blocked",
    "canceled",
  ].reduce((acc, key) => {
    acc[`${key}Row`] = roadLoad?.[key]?.total ?? 0;
    return acc;
  }, {} as IRoadRowCount);

  const sessionSheetPageRoadLoad = sessionStorage.getItem("sheetRoadLoad");
  const parsedSheetPageRoadLoad =
    sessionSheetPageRoadLoad !== "undefined"
      ? JSON.parse(sessionSheetPageRoadLoad || "false")
      : false;

  const [sheetControl, setSheetControl] = useState(parsedSheetPageRoadLoad);

  const handleShowAllLoads = (tab: number): void => {
    handleRoadLoadMethod.handleSetTabSelected(tab);
    setSheetControl(true);
    sessionStorage.setItem("sheetRoadLoad", JSON.stringify(true));
  };

  const handleHiddenAllLoads = (): void => {
    setSheetControl(false);
    sessionStorage.setItem("sheetRoadLoad", JSON.stringify(false));
  };

  const breadcrumbDataRoadLoad: BreadcrumbChild[] = [
    {
      link: ROUTES.USER_ROUTES.HOME,
      label: "Início",
    },
    {
      label: "Agenda e Vem",
    },
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Grid
      container
      className="ViewLoadRoad"
      sx={{
        border: "0px solid red",
        gap: { xs: 2, lg: 2.5 },
        p: { xs: 1.5, lg: 3 },
        pl: { lg: 5 },
        position: "relative",
        zIndex: 1201,
      }}
    >
      {isMobile ? (
        <Portal>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: { xs: "right", md: "left" },
              height: "30px",
              position: { xs: "absolute", md: "" },
              top: 10,
              right: 20,
              zIndex: 1201,
            }}
          >
            <Notification />
          </Grid>
        </Portal>
      ) : (
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "right",
            height: 0,
            pr: 2,
          }}
        >
          <Notification />
        </Grid>
      )}

      {!onlyDriver && (
        <Grid item xs={12} className="breadcrumbDataRoadLoad-item">
          <BreadcrumbCustom data={breadcrumbDataRoadLoad}></BreadcrumbCustom>
        </Grid>
      )}

      <Grid item xs={12} className="AllRoadLoad-HomeRoadLoad-item">
        {sheetControl ? (
          <AllRoadLoad
            handleHiddenAllRoadLoad={handleHiddenAllLoads}
            road={roadNormalized}
            roadCount={roadRowsCount}
          />
        ) : (
          <HomeRoadLoad
            handleShowAllRoadLoad={handleShowAllLoads}
            road={roadNormalized}
            roadCount={roadRowsCount}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ViewLoadRoad;
