import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  makeStyles,
  createStyles,
  DialogContent,
  Grid,
} from "@material-ui/core";
import { IQueryOrderDataDTO } from "@modules/orders/dtos/IQueryOrderDataDTO";
import { ICancelOrderService } from "@modules/orders/models/ICancelOrderService";
import AppError from "@utils/AppError";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useFormQuery } from "./FormQueryContext";
import { CloseIconRadiusIcon, WarningIcon } from "@components/Icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    titleModal: {
      color: "#3E3D3D",
      fontSize: "2.2rem",
      fontFamily: "Montserrat",
      fontWeight: "bold",
      textAlign: "center",
      paddingTop: "30px",
    },
    subTitleModal: {
      color: "#3E3D3D",
      fontSize: "1.6rem",
      opacity: "0.6",
      fontFamily: "Montserrat",
      textAlign: "center",
      margin: "20px 16px",
    },
    buttonReturnHome: {
      textTransform: "none",
      background: "transparent",
      fontFamily: "Montserrat",
      color: "#D91F05",
      boxShadow: "none",
      fontWeight: "bold",
      whiteSpace: "nowrap",
      padding: "10px 30px",

      "&:hover": {
        background: "#FFF",
        opacity: "0.8",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
      },
    },
    buttonCancel: {
      textTransform: "none",
      background: "#D91F05",
      color: "#FFF",
      boxShadow: "none",
      fontFamily: "Montserrat",
      borderRadius: "2px",
      fontWeight: "bold",
      whiteSpace: "nowrap",
      padding: "10px 40px",
      "&:hover": {
        opacity: "0.7",
        background: "#D91F05",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
      },
    },
    closeButtonTop: {
      backgroudn: "transparent",
      paddingTop: "20px",
      paddingBottom: "20px",
      borderRadius: "30px",
    },
  })
);

interface Props {
  orderData: IQueryOrderDataDTO;
  open: boolean;
  closeModal: () => void;
}

const ModalCancelOrder: React.FC<Props> = ({ orderData, open, closeModal }) => {
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();
  const formQueryContext = useFormQuery();
  const classes = useStyles();

  const cancelOrderService = iocContext.serviceContainer.get<
    ICancelOrderService
  >(Types.Orders.ICancelOrderService);

  const [loading, setLoading] = useState(false);

  async function cancelOrder() {
    try {
      setLoading(true);
      await cancelOrderService.execute(orderData.numberOrder);
      enqueueSnackbar(`Ordem ${orderData.numberOrder} cancelada com sucesso!`, {
        variant: "success",
      });

      const dataQuery = formQueryContext.dataQuery.filter(
        (order) => order.numberOrder !== orderData.numberOrder
      );

      formQueryContext.setDataQuery(dataQuery);

      closeModal();
    } catch (error) {
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, { variant: error.variant });
      }
      enqueueSnackbar(
        `Ocorreu um erro ao cancelar a ordem ${orderData.numberOrder}`,
        { variant: "error" }
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      PaperProps={{ style: { borderRadius: "8px", padding: "40px 0px" } }}
    >
      <Grid
        xs={12}
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginRight: "30px",
          cursor: "pointer",
        }}
      >
        <Button onClick={() => closeModal()} className={classes.closeButtonTop}>
          <CloseIconRadiusIcon style={{ width: "14px", height: "14px" }} />
        </Button>
      </Grid>
      <DialogContent>
        <DialogTitle>
          <Grid container>
            <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
              <WarningIcon />
            </Grid>
            <Grid xs={12}>
              <Typography className={classes.titleModal}>
                Cancelar este pedido?
              </Typography>
            </Grid>
          </Grid>
          <Typography className={classes.subTitleModal}>
            Ao cancelar este pedido, você não poderá mais recuperá-lo. Tem
            certeza?
          </Typography>
        </DialogTitle>

        <DialogActions>
          <Button
            onClick={() => closeModal()}
            variant="contained"
            className={classes.buttonReturnHome}
          >
            Voltar
          </Button>
          <Button
            style={{ marginLeft: "20px" }}
            variant="contained"
            startIcon={
              loading && (
                <CircularProgress
                  style={{
                    height: "1.7rem",
                    width: "1.7rem",
                    color: "#FFF",
                  }}
                />
              )
            }
            onClick={() => {
              cancelOrder();
            }}
            className={classes.buttonCancel}
          >
            Cancelar pedido
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export { ModalCancelOrder };
