import {
  createStyles,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    backgroundTitle: {
      margin: "1rem 0 1rem 8rem",
      color: "#3E3D3D",
      fontWeight: 700,
      fontSize: "3rem",

      "@media screen and (max-width: 768px)": {
        margin: "0 0 0 1rem",
      },
    },
    container: {
      position: "relative",
      display: "flex",
      justifyContent: "left",
    },
  })
);

interface Props {
  title: string;
}

const Title: React.FC<Props> = ({ title }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <div
      className={classes.container}
      style={{ marginTop: isMobile ? "2rem" : "0" }}
    >
      <Typography
        className={classes.backgroundTitle}
        variant={isMobile ? "h5" : "h3"}
      >
        {title}
      </Typography>
    </div>
  );
};

export default Title;
