import { Box } from "@material-ui/core";
import { TabPanelProps } from "@material-ui/lab";
import React from "react";

interface MyTabPanelProps extends TabPanelProps {
  index: number;
  value: any;
}

const TabPanel: React.FC<MyTabPanelProps> = (props) => {
  return (
    <Box
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`simple-tabpanel-${props.index}`}
      aria-labelledby={`simple-tab-${props.index}`}
      {...props}
    >
      {props.value === props.index && (
        <Box p={3} style={{ padding: 0 }}>
          {props.children}
        </Box>
      )}
    </Box>
  );
};

export default TabPanel;
