import useTable from "@hooks/useTable";
import {
  createStyles,
  LinearProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { capitalizeFirstLetter, formatAmount } from "@utils/index";
import React, { useState } from "react";

const useStyles = makeStyles(({ ...theme }) =>
  createStyles({
    table: {
      borderCollapse: "collapse",
      borderRadius: "1.6rem",
      width: "100%",
      backgroundColor: "#FFFFFF",
      height: 440,
    },
    tableRowHeader: {
      backgroundColor: "transparent",
      transition: "all 0.25s ease",
      borderRadius: "1.6rem",
    },
    tableHeader: {
      padding: "4rem 1.6rem 0.8rem 1.6rem",
      fontWeight: 500,
      fontSize: "1.4rem",
      color: "#626166",

      // "&:last-child": {
      //   // borderTopLeftRadius: "1.6rem",
      //   // borderTopRightRadius: "1.6rem",
      // },
    },
    tableRowItems: {
      cursor: "auto",
      borderTop: "0.1rem solid #EAEAEA",
      "&:last-child": {
        // borderTop: "0.1rem solid transparent",
      },
    },
    tableCell: {
      padding: "1.6rem 1.6rem",
      fontSize: "1.4rem",
      color: "grey",
    },
  })
);

export interface Props {
  data: Object[];
  rowsPerPage: number;
}

type CustomRenderers<T> = Partial<Record<keyof T, (it: T) => React.ReactNode>>;

interface TableProps<T> {
  items: Object[];
  customRenderers?: CustomRenderers<T>;
  rowsPerPage: number;
  loading?: boolean;
}
// Component that acts as the table of instant prizes. Using the useTable hook. And using the TableFooter Component.

export default function TableVolPerProduct<T>(props: TableProps<T>) {
  const classes = useStyles();
  const [page] = useState(1);
  const { slice } = useTable(props.items, page, props.rowsPerPage);

  return (
    <>
      {props.loading && <LinearProgress variant="indeterminate" />}
      <Table className={classes.table}>
        <TableHead className={classes.tableRowHeader}>
          <TableRow>
            <TableCell className={classes.tableHeader}>Produto</TableCell>
            <TableCell className={classes.tableHeader} align="right">
              Volume Posh (LT)
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              Volume Atem (LT)
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              Porcentagem
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {slice
            .sort((a, b) => {
              if (a.quantityAtem > b.quantityAtem) return -1;

              if (a.quantityAtem < b.quantityAtem) return 1;

              return 0;
            })
            .map((el) => (
              <TableRow className={classes.tableRowItems} key={el.product}>
                <TableCell className={classes.tableCell}>
                  {capitalizeFirstLetter(el.product.toLowerCase())}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {formatAmount(parseFloat(el.quantityPosh.toFixed()))}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {formatAmount(parseFloat(el.quantityAtem.toFixed()))}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {parseFloat((el.rate * 100).toFixed(2))} %
                </TableCell>
              </TableRow>
            ))}
          <TableRow style={{ height: "40%" }}></TableRow>
        </TableBody>
      </Table>
    </>
  );
}
