import React from "react";
import Layout from "@components/Layout";

import ViewLoadRoad from "@pages/User/RoadLoad/view";
import { RoadLoadProvider } from "@pages/User/RoadLoad/context/RoadLoadContext";

const RoadLoad = () => {
  return (
    <Layout disableToolbar={true} disableToolbarMobile={true}>
      <RoadLoadProvider>
        <ViewLoadRoad />
      </RoadLoadProvider>
    </Layout>
  );
};

export default RoadLoad;
