import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { IGetLastOrdersDTO } from "../dtos/IGetLastOrdersDTO";
import { IGetLastOrdersService } from "../models/IGetLastOrdersService";

@injectable()
export class GetLastOrdersService implements IGetLastOrdersService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;
  public async execute(cnpj: string) {
    const attrs =
      "numberOrder,companyName,quantity,productID,productName,financialStatus,invoice,price,totalPrice,payment,driverName,vehiclePlate,deliveryDate,freightType,implantationDate,statusAtem,paymentDescription,latitude,longitude";
    const lastOrder = await this.httpInstance.get<IGetLastOrdersDTO[]>(
      `/orders/recentOrders?companies=${cnpj}&top=6&attrs=${attrs}`
    );
    return lastOrder;
  }
}
