export interface ITableHeader {
  option: string;
  align: "left" | "right" | "inherit" | "center" | "justify" | undefined;
  isVisibleMobile: boolean;
}

export const columnsTitleMocked: ITableHeader[] = [
  { option: "", align: "center", isVisibleMobile: true },
  { option: "Título", align: "left", isVisibleMobile: true },
  { option: "Parcela", align: "right", isVisibleMobile: true },
  { option: "Emissão", align: "right", isVisibleMobile: false },
  { option: "Vencimento", align: "right", isVisibleMobile: false },
  { option: "Razão social", align: "left", isVisibleMobile: false },
  { option: "CNPJ", align: "center", isVisibleMobile: false },
  { option: "Referência", align: "center", isVisibleMobile: false },
  { option: "Valor total", align: "right", isVisibleMobile: false },
  { option: "Situação", align: "center", isVisibleMobile: false },
  { option: "", align: "center", isVisibleMobile: false },
];
