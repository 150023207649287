import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Avatar,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import BackupIcon from "@material-ui/icons/Backup";
import CloseIcon from "@material-ui/icons/Close";
import { IListLoginImageService } from "@modules/loginImages/models/IListLoginImageService";
import { IRemoveLoginImageService } from "@modules/loginImages/models/IRemoveLoginImageService";
import { IUploadLoginImageService } from "@modules/loginImages/models/IUploadLoginImageService";
import AppError from "@utils/AppError";
import clsx from "clsx";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import DragInDropBox from "../../../components/DragInDropBox";
import ImagePaper from "../../../components/ImagePaper";
import Layout from "../../../components/Layout";
import Title from "../../../components/Title";
import FormUploadImageValidationSchema from "./FormUploadImagesValidationSchema";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      margin: "2rem",
    },
    qtdImagesTitle: {
      fontSize: "2rem",
    },
    qtdImages: {
      fontSize: "3.6rem",
      color: "#094a9e",
    },
    thumbnailSize: {
      width: "10rem",
      height: "10rem",
    },
    thumbnail: {
      cursor: "pointer",
      position: "relative",

      "& img": {
        transition: ".5s",
      },
      "&:hover": {
        "& svg": {
          opacity: 1,
        },
        "& img": {
          opacity: 0.3,
        },
      },
    },
    iconDeleteImage: {
      transition: ".5s",
      transform: "translate(-50%, -50%)",
      position: "absolute",
      top: "50%",
      left: "50%",
      "& svg": {
        transition: ".5s",
        opacity: 0,
        fontSize: "5rem",
      },
    },
    divider: {
      margin: "2rem 0",
    },
  })
);

const AddImagesLoginPage: React.FC = () => {
  const classes = useStyles();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingSendImage, setLoadingSendImage] = useState(false);
  const [images, setImages] = useState<
    {
      id: string;
      link: string;
      thumbnail: string;
    }[]
  >([]);
  const [deleteImg, setDeleteImg] = useState<{
    id: string;
    thumbnail: string;
  } | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const iocContext = useIoCContext();

  const listLoginImagesService = iocContext.serviceContainer.get<
    IListLoginImageService
  >(Types.LoginImages.IListLoginImageService);

  const removeLoginImagesService = iocContext.serviceContainer.get<
    IRemoveLoginImageService
  >(Types.LoginImages.IRemoveLoginImageService);

  const uploadLoginImagesService = iocContext.serviceContainer.get<
    IUploadLoginImageService
  >(Types.LoginImages.IUploadLoginImageService);

  useEffect(() => {
    async function fetch() {
      try {
        const images = await listLoginImagesService.execute();
        const imagesTransformed = images.map((image) => {
          const linkSquare = image.link
            .split("upload")
            .join("upload/ar_1:1,c_fill,g_auto,w_1000");

          const linkThumbnail = image.link
            .split("upload")
            .join("upload/c_thumb,w_300,g_face");
          return {
            id: image.id,
            link: linkSquare,
            thumbnail: linkThumbnail,
          };
        });
        setImages(imagesTransformed);
      } catch (error) {
        if (error instanceof AppError) {
          return enqueueSnackbar(error.message, { variant: error.variant });
        }
        enqueueSnackbar("Não foi possível baixar as imagens", {
          variant: "error",
        });
      }
    }
    fetch();
  }, [
    showModalDelete,
    loadingSendImage,
    listLoginImagesService,
    enqueueSnackbar,
  ]);

  const deleteImage = useCallback(
    (imageID: string) => {
      async function fetch() {
        try {
          setLoadingDelete(true);
          const { message } = await removeLoginImagesService.execute(imageID);
          enqueueSnackbar(message, { variant: "success" });
          setShowModalDelete(false);
        } catch (error) {
          console.error(error);
          if (error instanceof AppError) {
            return enqueueSnackbar(error.message, { variant: error.variant });
          }
          enqueueSnackbar("Não foi possível remover a imagem", {
            variant: "error",
          });
        } finally {
          setLoadingDelete(false);
        }
      }
      fetch();
    },
    [enqueueSnackbar, removeLoginImagesService]
  );

  const initialValues = {
    file: null,
  };

  return (
    <Layout>
      <div className={classes.container}>
        <Title title="Adicionar imagens Login" />
        <Divider className={classes.divider} />

        <div style={{ padding: "2.5rem" }}>
          <Grid container spacing={5}>
            <Grid item xs={12} lg={6}>
              <Typography className={classes.qtdImagesTitle}>
                Imagens
              </Typography>
              <Typography className={classes.qtdImages}>
                {images.length}
              </Typography>

              <Divider className={classes.divider} />
              <div style={{ padding: "2.5rem" }}>
                <Grid container spacing={5} justify="center">
                  {images.map((item) => (
                    <Grid item xs={6} md={4} lg={3} container justify="center">
                      <div
                        className={classes.thumbnail}
                        onClick={() => {
                          setDeleteImg({
                            id: item.id,
                            thumbnail: item.thumbnail,
                          });
                          setShowModalDelete(true);
                        }}
                      >
                        <Avatar
                          className={clsx(classes.thumbnailSize)}
                          src={item.thumbnail}
                        />
                        <div className={classes.iconDeleteImage}>
                          <CloseIcon />
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
              <Divider className={classes.divider} />

              <Formik
                initialValues={initialValues}
                onSubmit={async (values, props) => {
                  try {
                    setLoadingSendImage(true);

                    await uploadLoginImagesService.execute(
                      (values.file as unknown) as File
                    );

                    enqueueSnackbar("Imagem criada com sucesso!", {
                      variant: "success",
                    });
                    props.resetForm();
                  } catch (error) {
                    console.error(error);
                    if (error instanceof AppError) {
                      return enqueueSnackbar(error.message, {
                        variant: error.variant,
                      });
                    }
                    enqueueSnackbar("Falha ao enviar imagem", {
                      variant: "error",
                    });
                  } finally {
                    setLoadingSendImage(false);
                  }
                }}
                validationSchema={FormUploadImageValidationSchema}
              >
                {(props) => {
                  return (
                    <Form id="sendImg">
                      <DragInDropBox
                        error={
                          Boolean(props.touched.file) &&
                          Boolean(props.errors.file)
                        }
                        touched={Boolean(props.touched.file)}
                        helperText={
                          props.touched.file && props.errors.file
                            ? String(props.errors.file)
                            : ""
                        }
                        onTouch={() => {
                          props.setFieldTouched("file", true);
                        }}
                        onChange={(file: File) =>
                          props.setFieldValue("file", file)
                        }
                        value={props.values.file}
                      />
                    </Form>
                  );
                }}
              </Formik>

              <Grid style={{ marginTop: "2rem" }} container justify="center">
                <Button
                  variant="contained"
                  color="primary"
                  form="sendImg"
                  type="submit"
                  startIcon={
                    loadingSendImage ? (
                      <CircularProgress
                        style={{ width: "2.5rem", height: "2.5rem" }}
                        color="secondary"
                      />
                    ) : (
                      <BackupIcon />
                    )
                  }
                >
                  {loadingSendImage ? "Enviando imagem..." : "Enviar imagem"}
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Carousel>
                {images.map((item, idx) => (
                  <ImagePaper link={item.link} key={idx} />
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog
        open={showModalDelete && Boolean(deleteImg)}
        onClose={() => setShowModalDelete(false)}
      >
        <DialogTitle>Deseja remover a imagem?</DialogTitle>
        <DialogContent>
          <Grid container justify="center">
            <Avatar
              src={deleteImg ? deleteImg.thumbnail : ""}
              className={classes.thumbnailSize}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              deleteImg && deleteImage(deleteImg.id);
            }}
            startIcon={
              loadingDelete && (
                <CircularProgress
                  style={{ width: "2.5rem", height: "2.5rem" }}
                  color="primary"
                />
              )
            }
          >
            {loadingDelete ? "Apagando..." : "Sim"}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowModalDelete(false)}
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default AddImagesLoginPage;
