import { CloseIcon, ImgFileIcon } from "@components/Icons";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as FolderIcon } from "../../assets/folder.svg";
import notFileImg from "../../assets/nao-compativel.png";

const useStyles = makeStyles((theme) =>
  createStyles({
    boxUpload: {
      "& svg": {
        fontSize: "4rem",
      },
    },
    boxUploadInvalidFile: {
      border: "0.2rem dashed rgba(0,0,0,0.12)",
      borderColor: theme.palette.primary.main,
    },
    bounceInDragging: {
      animationName: "$bounceIn",
      animationDuration: "1s",
      animationIterationCount: "infinite",
      animationDirection: "alternate",
    },
    centerBox: {
      paddingTop: "3rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "@keyframes bounce": {
      from: {
        transform: "translateY(0px)",
      },
      to: {
        transform: "translateY(-15px)",
      },
    },
    "@keyframes bounceIn": {
      "0%, 20%, 40%, 60%, 80%, 100%": {
        transitionTimingFunction: "cubic-bezier(0.215, 0.610, 0.355, 1.000)",
      },
      "0%": {
        opacity: 0,
        transform: "scale3d(.3, .3, .3)",
      },
      "20%": {
        transform: "scale3d(1.1, 1.1, 1.1)",
      },
      "40%": {
        transform: "scale3d(.9, .9, .9)",
      },
      "60%": {
        transform: "scale3d(1.03, 1.03, 1.03)",
      },
      "80%": {
        transform: "scale3d(.97, .97, .97)",
      },
      "100%": {
        transform: "scale3d(1, 1, 1)",
      },
    },
    input: {
      display: "none",
    },
    notFile: {
      width: "6rem",
      height: "6rem",
      backgroundColor: "#E0E0E0",
      "& svg": {
        width: "3rem",
        height: "3rem",
        fill: theme.palette.text.primary,
      },
    },
    divider: {
      width: "25%",
      margin: "2rem",
    },
    title: {
      color: "theme.palette.text.primary",
      textAlign: "center",
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "28px",
    },
    message: {
      paddingTop: "1.2rem",
      paddingBottom: "1.2rem",
      opacity: 0.37,
      color: "theme.palette.text.primary",
      textAlign: "center",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "28px",
    },
  })
);

interface Props {
  onChange(file: File | null): void;
  onTouch(): void;
  touched: boolean;
  error: boolean;
  helperText: string;
  value: File | null;
}

const DragInDropBox: React.FC<Props> = ({
  onChange,
  error,
  helperText,
  onTouch,
  value,
}) => {
  const classes = useStyles();
  const [isDragging, setIsDragging] = useState(false);

  const removeFile = useCallback(() => {
    onChange(null);
    onTouch();
  }, [onChange, onTouch]);

  const handleDragIn = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  }, []);

  const handleDragOut = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.length) {
        onChange(e.target.files[0]);
        onTouch();
      }
    },
    [onChange, onTouch]
  );

  const handleDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      if (e.dataTransfer.files.length) {
        onChange(e.dataTransfer.files[0]);
        onTouch();
      }
      setIsDragging(false);
    },
    [onChange, onTouch]
  );

  useEffect(() => {
    if (value && error) {
      onTouch();
    }
  }, [value, error, onTouch]);

  return (
    <>
      <div
        onDragOver={handleDragIn}
        onDragLeave={handleDragOut}
        onDrop={handleDrop}
        className={clsx(classes.boxUpload, {
          [classes.boxUploadInvalidFile]: error,
        })}
      >
        <input
          className={classes.input}
          id="file"
          name="file"
          type="file"
          accept="image/png, image/jpeg, .pdf"
          onChange={handleChange}
        />
        <Collapse in={!isDragging}>
          <div className={classes.centerBox}>
            {value && (
              <>
                {error && (
                  <Avatar src={notFileImg} className={classes.notFile} />
                )}
                {!error && (
                  <Avatar className={classes.notFile}>
                    <FolderIcon />
                  </Avatar>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <Typography>{value.name}</Typography>
                  <CloseIcon
                    onClick={removeFile}
                    style={{ cursor: "pointer", marginLeft: "10px" }}
                  />
                </div>
                <Divider className={classes.divider} />
              </>
            )}
            {!value && (
              <Box
                style={{
                  height: "100px",
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ImgFileIcon style={{ width: "80px", height: "80px" }} />
              </Box>
            )}
            <Typography className={classes.title}>
              Arraste o arquivo aqui
            </Typography>
            <Typography className={classes.message}>ou</Typography>

            <label htmlFor="file">
              <Button
                component="span"
                color="primary"
                variant="contained"
                style={{ textTransform: "none" }}
              >
                Escolher arquivo
              </Button>
            </label>
          </div>
        </Collapse>
        <Collapse in={isDragging}>
          <div className={classes.centerBox}>
            <FileCopyIcon className={classes.bounceInDragging} />
            <Typography>Solte o seu arquivo para enviar</Typography>
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default DragInDropBox;
