import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Box,
  CircularProgress,
  Collapse,
  createStyles,
  FormControl,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { IGetPriceProductsService } from "@modules/orders/models/IGetPriceProductsService";
import AppError from "@utils/AppError";
import { formatCurrencyPriceProduct } from "@utils/index";
import { IProduct, Price } from "@utils/interfaces";
import { useFormikContext } from "formik";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useAppointmentPriceContext } from "./AppointmentPriceContext";
import { IQuery } from "./interface";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) =>
  createStyles({
    paperTable: {
      width: "100%",
      marginTop: "5rem",
      overflow: "hidden",
      overflowX: "scroll",

      "&::-webkit-scrollbar": {
        background: theme.palette.grey[300],
        height: "0.7rem",
        borderRadius: ".4rem",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.grey[900],
        borderRadius: ".4rem",
      },
    },
    itemSelect: {
      display: "flex",
    },
    titleColumn: {
      fontWeight: 700,
    },
    tableRow: {
      marginTop: "2rem",
    },
    grid: { height: "90px", marginTop: 16, marginBottom: 16 },
    gridColumn: { height: "60px", marginTop: 16, marginBottom: 16 },
    gridItem: {
      display: "flex",
      alignItems: "center",
      color: "#3E3D3D",
      fontFamily: "DM Sans",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "20px",
    },
    gridItemColumn: {
      color: "#3E3D3D",
      fontFamily: "DM Sans",
      fontSize: "16px",
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
    },

    formControl: {
      borderRadius: "8px",
      width: "408px",
      height: "60px",
    },
    customSelect: {
      justifyContent: "center",
      alignItems: "left",
      display: "flex",
      marginTop: "0",
      height: "100%",
      width: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(114, 101, 81, 0.20)",
      },
      "& .MuiSvgIcon-root": {
        fill: "#D91F05",
      },
      borderRadius: "8px",
    },
    paperGrid: {
      borderRadius: "8px",
      padding: "0px 57px 0px 32px",
      boxShadow: `0px 4px 4px rgba(115, 97, 97, 0.10)`,
    },
    titleSearch: {
      color: "#3E3D3D",
      textAlign: "center",
      fontFamily: "DM Sans",
      fontSize: "16px",
      fontWeight: 400,
    },
  })
);

interface TableRowScopedProps {
  product: IProduct;
}

const TableRowScoped: React.FC<TableRowScopedProps> = ({ product }) => {
  const appointmentContext = useAppointmentPriceContext();
  const { values } = useFormikContext<IQuery>();
  const { enqueueSnackbar } = useSnackbar();
  const iocContext = useIoCContext();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const getPriceProductService = iocContext.serviceContainer.get<
    IGetPriceProductsService
  >(Types.Orders.IGetPriceProductsService);

  const [loadingPrice, setLoadingPrice] = useState(true);
  const [deadlinePayment, setDeadlinePayment] = useState(
    product.payCond
      ? product.payCond
      : appointmentContext.dataCustomer
      ? appointmentContext.dataCustomer.payCond
      : 0
  );
  const [price, setPrice] = useState<Price | null>(null);

  const getPrice = useCallback(async () => {
    if (!appointmentContext.dataCustomer) return;
    if (!values.address) return;
    if (!values.filialID) return;
    try {
      setLoadingPrice(true);
      const price = await getPriceProductService.execute({
        addressID: values.address.id,
        customerID: appointmentContext.dataCustomer.id,
        deadlinePayment,
        filialID: values.filialID,
        productID: product.id,
        transportationZone: values.address?.transportationZone,
      });
      setPrice(price);
    } catch (error) {
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, {
          variant: error.variant,
        });
      }
      enqueueSnackbar("Ocorreu um erro ao consultar preço, tente novamente", {
        variant: "error",
      });
    } finally {
      setLoadingPrice(false);
    }
  }, [
    appointmentContext.dataCustomer,
    deadlinePayment,
    enqueueSnackbar,
    getPriceProductService,
    product.id,
    values.address,
    values.filialID,
  ]);

  useEffect(() => {
    getPrice();
  }, [getPrice]);

  const renderDeadlinePayment = (deadline: number) => {
    if (deadline === 0) {
      return (
        <MenuItem value={0} className={classes.itemSelect}>
          à vista
        </MenuItem>
      );
    }
    const values = Array.from(Array(deadline + 1).keys());

    return values.map((ele) => {
      return ele !== 0 ? (
        <MenuItem value={ele} className={classes.itemSelect}>
          <div style={{ width: "auto", marginRight: "2rem" }}>
            {`${ele} ${ele !== 1 ? "dias" : "dia"}`}
          </div>
        </MenuItem>
      ) : (
        <MenuItem value={ele} className={classes.itemSelect}>
          <div style={{ width: "auto", marginRight: "2rem" }}> à vista</div>
        </MenuItem>
      );
    });
  };

  return (
    <Paper className={classes.paperGrid}>
      <Grid xs={12} style={{ display: "flex" }} className={classes.grid}>
        <Grid item xs={1} className={classes.gridItem}>
          {product.id}
        </Grid>
        <Grid item xs={isMobile ? 4 : 6} className={classes.gridItem}>
          {product.description}
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            justifyContent: "center",
            paddingRight: "10rem",
          }}
          className={classes.gridItem}
        >
          {formatCurrencyPriceProduct(
            price
              ? values.freightType === "FOB"
                ? price.price
                : price.price + price.freight
              : 0
          )}
        </Grid>
        <Grid item xs={isMobile ? 4 : 2} className={classes.gridItem}>
          <FormControl
            fullWidth
            disabled={loadingPrice}
            className={classes.formControl}
            variant="outlined"
          >
            <Select
              className={classes.customSelect}
              onChange={({ target }) =>
                setDeadlinePayment((target.value as unknown) as number)
              }
              MenuProps={{
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                getContentAnchorEl: null,
              }}
              value={price ? Number(price.payCond) : 0}
              endAdornment={
                appointmentContext.loadingCustomerData && (
                  <InputAdornment position="end">
                    <CircularProgress
                      style={{ width: 20, height: 20, marginRight: -5 }}
                    />
                  </InputAdornment>
                )
              }
              IconComponent={ExpandMoreIcon}
            >
              {renderDeadlinePayment(
                appointmentContext.dataCustomer
                  ? (appointmentContext.dataCustomer.products.find(
                      (ele) => ele.id === product.id
                    )?.payCond as number)
                  : 0
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
};

const TablePrices: React.FC = () => {
  const classes = useStyles();
  const appointmentContext = useAppointmentPriceContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  if (appointmentContext.loadingFirstQuery) {
    return <></>;
  } else {
    if (appointmentContext.dataCustomer?.hiddePrice === false) {
      return (
        <Typography className={classes.titleSearch}>
          Não houve resultados nessa pesquisa
        </Typography>
      );
    }
  }

  return (
    <Collapse
      in={
        !appointmentContext.loadingPrices &&
        !appointmentContext.loadingFirstQuery
      }
    >
      <Box style={{ overflowX: isMobile ? "auto" : "hidden" }}>
        <Table>
          <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
            <Typography className={classes.titleSearch}>
              Resultados da pesquisa
            </Typography>
          </Grid>
          <Paper className={classes.paperGrid}>
            <Grid
              xs={12}
              style={{ display: "flex", fontWeight: "bold" }}
              className={classes.gridColumn}
            >
              <Grid item xs={1} className={classes.gridItemColumn}>
                ID
              </Grid>
              <Grid
                item
                xs={isMobile ? 4 : 6}
                className={classes.gridItemColumn}
              >
                Produto
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.gridItemColumn}
                style={{
                  justifyContent: "center",
                  paddingRight: "10rem",
                }}
              >
                Preço
              </Grid>
              <Grid
                item
                xs={isMobile ? 3 : 2}
                className={classes.gridItemColumn}
                style={{
                  justifyContent: "center",
                }}
              >
                Prazo
              </Grid>
            </Grid>
          </Paper>

          <TableBody style={{ overflowX: "auto" }}>
            {appointmentContext.dataCustomer?.products.map((product) => {
              return <TableRowScoped product={product} key={product.id} />;
            })}
          </TableBody>
        </Table>
      </Box>
    </Collapse>
  );
};

export { TablePrices };
