import { Table, TableCell, TableRow } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

interface ISkeletonTableInvoicesProps {
  rows?: number;
  cols?: number;
}

const SkeletonTableInvoices: React.FC<ISkeletonTableInvoicesProps> = ({
  ...props
}) => {
  const generateSkeletonRow = (key) => {
    return (
      <>
        <TableRow key={key}>
          {[...Array(props.cols).keys()].map((col) => (
            <TableCell key={col} height={"40px"} style={{ paddingLeft: "0px" }}>
              <Skeleton variant="rect" width={"90%"} height={"40px"} />
            </TableCell>
          ))}
        </TableRow>
      </>
    );
  };

  return (
    <>
      <Skeleton
        variant="rect"
        width={"100px"}
        height={"40px"}
        style={{ marginLeft: "0px", marginBottom: "10px" }}
      />

      <Skeleton
        variant="rect"
        width={"97%"}
        height={"40px"}
        style={{ marginLeft: "0px" }}
      />

      <Table>
        <tbody>
          {[...Array(props.rows).keys()].map((row) => generateSkeletonRow(row))}
        </tbody>
      </Table>
    </>
  );
};

export default SkeletonTableInvoices;
