/* eslint-disable no-lone-blocks */
import axios from "axios";
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  AuthCogCookieKeys,
  AuthenticationContextData,
  AuthenticationResponse,
  CognitoParsedToken,
} from "@context/auth/IAuthContext";
import { useIoCContext } from "@context/IoCContext/IoCContext";

import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { toReplaceAsterisk } from "@utils/index";

import DashLoading from "@components/DashLoading";
import appConfig from "@config/appConfig";
import { datadogRum } from "@datadog/browser-rum";
import { Types } from "@ioc/types";
import { TypesProfile } from "./enum";

// Constantes para valores literais usados no código
const BUFFER_TIME_MS = 59 * 60 * 1e3; // 59 * 60 * 1 = 3549 ms = 3,54 segundos
const TOKEN_REFRESH_BEFORE_EXPIRATION_TIME_MS = 30e3; //  30e3 = 30 segundos

export const AuthContext = createContext<AuthenticationContextData | null>(
  null
);

const authServiceStandalone = axios.create({
  baseURL:
    process.env.REACT_APP_ENV === "development" ||
    window.location.hostname === "localhost"
      ? appConfig.api.urlCognito.development
      : Boolean(window.location.origin.split(".")[0].match(/-dev$/))
      ? appConfig.api.urlCognito.homologation
      : appConfig.api.urlCognito.production,

  timeout: appConfig.api.timeout,
});

interface PropsAuthProvider {
  children?: React.ReactNode;
}

export const AuthProvider: React.FC<PropsAuthProvider> = (
  props: PropsAuthProvider
) => {
  const [
    currentAuthState,
    setCurrentAuthState,
  ] = useState<AuthenticationContextData | null>(null);

  const iocContext = useIoCContext();
  const httpService = iocContext.serviceContainer.get<IHttpService>(
    Types.IHttpService
  );

  // Função para fazer a solicitação de atualização do token
  const refreshTokenRequest = async (
    currentTokenRefresh: string
  ): Promise<AuthenticationResponse> => {
    const { data: refreshTokenResponse } = await authServiceStandalone.post<
      AuthenticationResponse
    >("/auth/refresh", {
      token: currentTokenRefresh,
    });

    return refreshTokenResponse;
  };

  // Função para fazer de atualização do token
  const setTokenExpiration = useCallback(
    async (nextTokenRefreshTime: number): Promise<string | null> => {
      try {
        const data = await tokenRefresh(
          nextTokenRefreshTime - TOKEN_REFRESH_BEFORE_EXPIRATION_TIME_MS
        );
        if (data) {
          const builtToken = `${data.TokenType} ${data.AccessToken}`;
          httpService.setAuthorization(builtToken);
          return builtToken;
        }
        return null;
      } catch (error) {
        console.error(
          "[setTokenExpiration]: Failed to refresh access token in setTokenExpirationStrategy",
          error
        );
        return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Função principal de atualização do token
  const tokenRefresh = useCallback(
    async (refreshTokenExpirationTime?: number) => {
      try {
        const cookies = new Cookies();
        const cookiesTokenRefresh = cookies.get(AuthCogCookieKeys.refreshToken);

        // Verifica se o token atual existe no cookies da sessao
        if (!cookiesTokenRefresh) {
          throw new Error("Refresh token not found");
        }

        // Verifica se o token do cookie atual da sessao ainda não expirou
        if (
          refreshTokenExpirationTime &&
          +new Date() <= refreshTokenExpirationTime
        ) {
          return null;
        }

        const refreshTokenResponse = await refreshTokenRequest(
          cookiesTokenRefresh
        );

        const nameLogin = refreshTokenResponse.meta.name;
        localStorage.setItem("nameLogin", nameLogin);

        const nextTokenRefreshTime =
          jwtDecode<CognitoParsedToken>(refreshTokenResponse.AccessToken).exp *
            1e3 -
          BUFFER_TIME_MS; // 1681917158000 ms
        httpService.setTokenExpirationStrategy(
          async () => await setTokenExpiration(nextTokenRefreshTime)
        );
        return refreshTokenResponse;
      } catch (error) {
        console.error("[tokenRefresh]: Failed to refresh access token", error);
        return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const logout = useCallback(async () => {
    const hostname = window.location.hostname;
    if (
      process.env.REACT_APP_ENV === "development" ||
      hostname === "localhost"
    ) {
      window.location.href = appConfig.cognito.urlLogout.development;
    } else if (Boolean(window.location.origin.split(".")[0].match(/-dev$/))) {
      window.location.href = appConfig.cognito.urlLogout.homologation;
    } else {
      window.location.href = appConfig.cognito.urlLogout.production;
    }
  }, []);

  const bootstrapAuthentication = useCallback(async () => {
    try {
      const data = await tokenRefresh();
      if (!data) {
        await logout();
        return;
      }

      httpService.setAuthorization(`${data.TokenType} ${data.AccessToken}`);

      const { ROLES } = data.meta.permissionSet;

      let authData: AuthenticationContextData = {
        email: data.meta.email,
        name: data.meta.name,
        groups: data.meta.groups,
        refreshToken: data.RefreshToken,
        listCNPJ: data.meta.permissionSet.CNPJ,
        subject: data.meta.id,
        token: data.AccessToken,
        adminMode:
          ROLES.includes(TypesProfile.ADMIN) ||
          ROLES.includes(TypesProfile.ADMIN_PORTAL_CLIENTE),
        username: data.meta.username,
        userID: data.meta.id,
        permissionSet: data.meta.permissionSet,
        logout,
        authByPermission,
      };

      setCurrentAuthState(addRoles(authData));

      if (window.location.hostname !== "localhost") {
        datadogRum.init({
          applicationId: "a6616d84-69ab-40dd-9884-5d06a5f8527a",
          clientToken: "pub131712352a5d4778468b1776f5f57afa",
          // `site` refers to the Datadog site parameter of your organization
          // see https://docs.datadoghq.com/getting_started/site/
          site: "datadoghq.com",
          service: "portal-cliente-atem",
          env: "<ENV_NAME>",
          // Specify a version number to identify the deployed version of your application in Datadog
          // version: '1.0.0',
          sessionSampleRate: 100,
          sessionReplaySampleRate: 20,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: "mask-user-input",
        });

        datadogRum.setUser({
          id: data.meta.id,
          name: data.meta.name,
          email: data.meta.email,
        });

        datadogRum.startSessionReplayRecording();
      }
    } catch (error) {
      console.error(
        "[bootstrapAuthentication]: Failed to refresh access token",
        error
      );
      await logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  {
    /* Note: Manipula estaticamente as permissoes [Ainda Em fase de Desenvolvimento - 24/11/2023] | [18/02/2024] */
    /* Note: A funcao abaixo auxilia na manipulucao dos modulos, facilitando o desenvolvimento <> mantida em [23/07/2024] */
  }
  const addRoles = (authData: AuthenticationContextData) => {
    const {
      SYSTEM_MODULES,
      ROLES,
      ...restPermissionSet
    } = authData.permissionSet;

    // Note: O objeto abaixo se faz necessario para testes de permissao em diferentes perfis no ambiente de desenvolvimento

    const auth: AuthenticationContextData = {
      ...authData, // const ROLESMOCK = ["admin"]
      adminMode: authData.adminMode, // ROLESMOCK.includes(TypesProfile.ADMIN_PORTAL_CLIENTE) || ROLESMOCK.includes(TypesProfile.ADMIN),
      permissionSet: {
        ...restPermissionSet,
        SYSTEM_MODULES,
        ROLES, // ROLESMOCK,
      },
    };

    return auth;
  };

  const authByPermission = useCallback(
    (
      userPermission: string[],
      isAdmin: boolean,
      checkPermission: string
    ): boolean => {
      if (!userPermission) return false; // tratativa para quando userPermission for undefined

      const replacedAsterik = toReplaceAsterisk(checkPermission);
      const hasUserPermission = userPermission.filter((permissionUser) =>
        replacedAsterik.includes(permissionUser)
      );
      const checkHasPermission = hasUserPermission.map((r) =>
        r.endsWith("write")
      );
      let [checked] =
        checkHasPermission.length === 0 ? [false] : checkHasPermission;

      return isAdmin || checked;
    },
    []
  );

  useEffect(() => {
    bootstrapAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={currentAuthState}>
      {currentAuthState && currentAuthState.token ? (
        props.children
      ) : (
        <DashLoading />
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth não pode ser utilizado fora de um AuthProvider");
  }
  return context;
};
