import { Types } from "@ioc/types";
import * as IHttpService from "@modules/infra/http/models/IHttpService";
import { inject, injectable, named } from "inversify";
import { IGetSalesPerformanceDTO } from "../dtos/IGetSalesPerformanceDTO";
import { IQuerySalesPerformanceDTO } from "../dtos/IQuerySalesPerformanceDTO";
import { IGetSalesPerformanceService } from "../models/IGetSalesPerformanceService";

@injectable()
export class GetSalesPerformanceService implements IGetSalesPerformanceService {
  @inject(Types.IHttpService)
  @named("pump")
  private httpInstance!: IHttpService.IHttpService;

  public async execute(
    data: IQuerySalesPerformanceDTO
  ): Promise<IGetSalesPerformanceDTO> {
    try {
      const salesPerformanceResponse = await this.httpInstance.get<
        IGetSalesPerformanceDTO
      >(`/dash_volume_billing_price?${data.target}&document=${data.cnpj}`);

      return salesPerformanceResponse;
    } catch (err) {
      throw err;
    }
  }

  public setAuthorization(token: string): void {
    this.httpInstance.setAuthorization(token);
  }
}
