import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { ISendReclamationDTO } from "../dtos/ISendReclamationDTO";
import { ISendReclamationService } from "../models/ISendReclamationService";
@injectable()
export class SendReclamationService implements ISendReclamationService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(
    data: ISendReclamationDTO
  ): Promise<ISendReclamationDTO> {
    const formData = new FormData();
    if (data.images) {
      for (const images of data.images) {
        formData.append("images", images);
      }
    }
    formData.append("fullName", data.fullName);
    formData.append("telephoneNumber", data.telephoneNumber);
    formData.append("cnpj", data.cnpj);
    formData.append("recordType", data.recordType);
    formData.append("observation", data.observation);
    formData.append("companyName", data.companyName);

    const response = await this.httpInstance.post<ISendReclamationDTO>(
      "/reclamation",
      formData
    );

    return response;
  }
}
