export interface AuthenticationContextData {
  username: string;
  userID: string;
  token: string;
  name: string;
  refreshToken: string;
  subject: string;
  email: string;
  groups: string[];
  adminMode: boolean;
  listCNPJ: CNPJS[];

  sub?: string;
  firstName?: string;
  fullName?: string;
  roles?: string[];

  permissionSet: PermissionSet;

  logout: () => Promise<void>;
  authByPermission: (
    user: string[],
    admin: boolean,
    checkPermission: string
  ) => boolean;
}

export interface PermissionSet {
  BPID: string;
  ROLES: string[];
  SYSTEM_MODULES: string[];
  CNPJ: CNPJS[];
}

export enum AuthCogCookieKeys {
  refreshToken = "refreshToken",
}

export interface CNPJS {
  CNPJ: string;
  companyName: string;
  BpID?: string;
}

export interface AuthenticationResponseMeta {
  id: string;
  email: string;
  name: string;
  username: string;
  roles: string[];
  groups: string[];
  adminMode: boolean;
  custom_attributes: {
    portfolioIDs?: string[];
    CNPJs: CNPJS[];
  };
  permissionSet: PermissionSet;
}

export interface AuthenticationResponse {
  AccessToken: string;
  ExpiresIn: number;
  TokenType: "Bearer";
  RefreshToken: string;
  meta: AuthenticationResponseMeta;
}

export interface CognitoParsedToken {
  sub: string;
  "cognito:groups": string[];
  iss: string;
  client_id: string;
  origin_jti: string;
  event_id: string;
  token_use: string;
  scope: string;
  auth_time: number;
  exp: number;
  iat: number;
  jti: string;
  username: string;
}
