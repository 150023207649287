import { ITableReclamationColumn } from "@components/TableReclamation/TableReclamation";

export const TableHeaderReclamation: ITableReclamationColumn[] = [
  { option: "ID do registro", align: "left", key: "1" },
  { option: "Razão social", align: "left", key: "2" },
  { option: "CNPJ", align: "center", key: "3" },
  { option: "Data da Solicitação", align: "right", key: "4" },
  { option: "Usuário Solicitante", align: "left", key: "5" },
  { option: "Status", align: "center", key: "6" },
  { option: "", align: undefined, key: "7" },
];
