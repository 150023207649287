import { Quill } from "react-quill";

const Sizes = ["14px", "16px", "18px", "20px", "22px", "26px", "28px", "30px"];

/**
 * @see https://stackoverflow.com/questions/38623716/how-to-add-custom-font-sizes-to-quilljs-editor/43808486#43808486
 */
const Size = Quill.import("attributors/style/size");
Size.whitelist = Sizes;
Quill.register(Size, true);

export default Sizes;
