import React, { useState } from "react";
import { Button, Modal, Fade, Paper, ButtonProps, Grid } from "@mui/material";
import { useTheme, Typography } from "@material-ui/core";

import TextButton from "@pages/Admin/Communications/resource/TextButton";
import ContainedButton from "@pages/Admin/Communications/resource/ContainedButton";

import { AttentionIcon, CloseIcon } from "@components/Icons";

interface ModalButtonProps extends ButtonProps {
  children?: React.ReactNode;
  colorTextButton?: string;
  onClick: () => void;
}

const ModalButtonDropOne: React.FC<ModalButtonProps> = ({
  children,
  ...props
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <TextButton onClick={handleOpen}>{children}</TextButton>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={open}>
          <Paper
            sx={{
              padding: { xs: "0.8rem", sm: "1.5rem", md: "1.5rem", lg: "3rem" },
              border: "1px solid white",
              borderRadius: 2,
              opacity: 0.9,
              backgroundColor: `${theme.palette.background.paper}`,
              boxShadow: theme.shadows[5],
              width: { xs: "95%", sm: "70%", md: "60%", lg: "40%" },
            }}
          >
            <Grid
              container
              className="modal-container"
              justifyContent={"center"}
              sx={{
                border: "0px solid blue",
                gap: { xs: 0.4, sm: 0.8, md: 1, lg: 4 },
              }}
            >
              <Grid item xs={12}>
                <Grid
                  container
                  sx={{ paddingTop: "8px" }}
                  className="modal-CloseIcon-container"
                  justifyContent={"right"}
                >
                  <Button sx={{ padding: 1 }} onClick={() => handleClose()}>
                    <CloseIcon></CloseIcon>
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs={9}>
                <Grid
                  container
                  className="modal-attentionIcon-container"
                  textAlign={"center"}
                  sx={{
                    border: "0px solid black",
                    gap: { xs: 0.6, sm: 1, md: 1.5, lg: 4 },
                  }}
                >
                  <Grid item xs={12}>
                    <AttentionIcon
                      sx={{ fontSize: { xs: 20, sm: 30, md: 40, lg: 100 } }}
                    ></AttentionIcon>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h5" style={{ fontWeight: 700 }}>
                      {" "}
                      Excluir Comunicado?{" "}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 400,
                        color: "#3E3D3D",
                        fontSize: "12px",
                      }}
                    >
                      Você tem certeza que deseja excluir este comunicado?{" "}
                      <br></br>
                      Essa ação não poderá ser desfeita.
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid
                      container
                      className="modal-groupButton-container"
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={2}
                      sx={{ mb: 2 }}
                    >
                      <Grid item>
                        <TextButton onClick={handleClose}>Cancelar</TextButton>
                      </Grid>

                      <Grid item>
                        <ContainedButton onClick={() => props.onClick()}>
                          Excluir
                        </ContainedButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalButtonDropOne;
