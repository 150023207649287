import React from "react";
import { Link as LinkRouter } from "react-router-dom";
import { Link, Typography, makeStyles } from "@material-ui/core";

interface IButtonLinkGenericProps {
  text: string;
  link: string;
  onChange?: (id: unknown) => void;
  onClick?: () => void;
}

const useStyles = makeStyles({
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    color: "#D91F05",
    marginBottom: "0px",
    whiteSpace: "nowrap",
    marginRight: "10px",
  },
  link: {
    textDecoration: "none",
  },
});

const ButtonLinkGeneric: React.FC<IButtonLinkGenericProps> = ({ ...props }) => {
  const classes = useStyles();

  return (
    <Link
      component={LinkRouter}
      to={props.link}
      className={classes.link}
      underline="none"
      onChange={props.onChange}
      onClick={props.onClick}
      type="button"
    >
      <Typography gutterBottom className={classes.title}>
        {props.text}
      </Typography>
    </Link>
  );
};

export default ButtonLinkGeneric;
