import React from "react";
import { useTheme } from "@material-ui/core";
import {
  CircularProgressProps,
  CircularProgress as CircularProgressV5,
} from "@mui/material";

interface Props extends CircularProgressProps {
  children?: React.ReactNode;
}

const CircularProgress: React.FC<Props> = ({ children, ...props }) => {
  const theme = useTheme();
  const { sx, ...restProps } = props;
  return (
    <CircularProgressV5
      size={"small"}
      sx={{
        width: "20px",
        height: "20px",
        color: theme.palette.primary.main,
        ...sx,
      }}
      {...restProps}
    />
  );
};

export default CircularProgress;
