import React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Typography } from "@material-ui/core";

import { TypeAlign } from "@utils/index";

export interface ITableBody {
  numberOrder: string;
  product: string;
  quantity: string;
  subsidiary?: string;
  price: string;
  payment?: string;
  status?: string;
  atemStatus?: string;
  implantationDate?: string;
  deliveryDate?: string;
}

export interface ITableHead {
  label: string;
  key: keyof ITableBody;
  align: TypeAlign;
}

export interface TableCustomProps {
  THead: ITableHead[];
  TBody: ITableBody[];
}

export enum typeStatusEnum {
  LIBERADO = "Liberado",
  BLOQUEADO = "Bloqueado",
  LIB = "Liberado",
  BL = "Bloqueado",
}

export const applyStatusColor = (status: string) => {
  if (status === (typeStatusEnum.LIBERADO as string)) return "#0BB873";
  if (status === (typeStatusEnum.BLOQUEADO as string)) return "#F97A91";
  return "#3E3D3D";
};

export const applyStatusFontWeight = (status: string): string => {
  if (status === typeStatusEnum.LIBERADO) return "700";
  if (status === typeStatusEnum.BLOQUEADO) return "700";
  return "500";
};

const TableCustom: React.FC<TableCustomProps> = ({ THead, TBody }) => {
  const styleBorderRadiusLeft = "4px 0px 0px 4px";
  const styleBorderRadiusRight = "0px 4px 4px 0px";

  const applyBorderRadius = (indexAtributo: number) => {
    const start = 0;
    const end = THead.length - 1;
    if (indexAtributo === start) return styleBorderRadiusLeft;
    if (indexAtributo === end) return styleBorderRadiusRight;
    return "0";
  };

  return (
    <Table sx={{ borderSpacing: "0px 1rem", borderCollapse: "separate" }}>
      <TableHead>
        <TableRow sx={{ backgroundColor: "#FFFFFF" }}>
          {THead.map((head, headIndex) => {
            return (
              <TableCell
                key={headIndex}
                sx={{
                  textAlign: head.align ?? "center",
                  paddingY: "1rem",
                  border: "1px solid white",
                  borderRadius:
                    headIndex === 0
                      ? styleBorderRadiusLeft
                      : headIndex === THead.length - 1
                      ? styleBorderRadiusRight
                      : "0",
                }}
              >
                <Typography style={{ fontWeight: 700, fontSize: 10 }}>
                  {head.label}
                </Typography>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>

      <TableBody>
        {TBody.map((bodyData, bodyIndex) => {
          const keys = Object.keys(bodyData);
          return (
            <TableRow
              sx={{
                backgroundColor: "#FFFFFF",
                border: "1px solid white",
                fontSize: 20,
              }}
              key={bodyIndex}
            >
              {keys.map((atributo, indexAtributo) => {
                return (
                  <TableCell
                    key={indexAtributo}
                    sx={{
                      textAlign: THead[indexAtributo]?.align,
                      paddingY: "2rem",
                      borderRadius: applyBorderRadius(indexAtributo),
                      color: applyStatusColor(bodyData[atributo]),
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: applyStatusFontWeight(bodyData[atributo]),
                        fontSize: 10,
                      }}
                    >
                      {bodyData[atributo]}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default TableCustom;
