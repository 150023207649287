import { usePerformanceManagement } from "@context/PerformanceManagementContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { IGetSalesPerformanceDTO } from "@modules/salesPerformance/dtos/IGetSalesPerformanceDTO";
import { ENUM_TARGET_PERFORMANCE } from "@pages/User/PerformanceManagement/Model";
import { numberFormatter } from "@utils/index";
import React from "react";

interface IGetTableData {
  data: IGetSalesPerformanceDTO[];
}
const TableSalesPerformance = (props: IGetTableData) => {
  const { salesPerformanceSelected } = usePerformanceManagement();

  const COLUMN_TABLE = {
    [ENUM_TARGET_PERFORMANCE.SALES_BY_EMPLOYEE]: [
      "Frentista",
      "Contagem de abastecimento",
      "Volume (L)",
      "Faturamento (R$)",
    ],
    [ENUM_TARGET_PERFORMANCE.SALES_VOLUME]: [
      "Nome do produto",
      "Volume (L)",
      "Preço médio (R$)",
    ],

    [ENUM_TARGET_PERFORMANCE.LAST_MONTH]: [
      "Mês",
      "Volume (L)",
      "Faturamento (R$)",
      "Preço médio (R$)",
      "Média diária (L)",
    ],
  };

  let columnTable = (salesPerformanceSelected.value &&
    COLUMN_TABLE[salesPerformanceSelected.value]) || [""];

  const isFrentista = columnTable[0] === "Frentista";
  const isProductDescription = columnTable[0] === "Nome do produto";

  const classes = useStyle();
  const dados = props.data;

  const getAlignColumn = (titleColumn: string) => {
    if (
      titleColumn === "Frentista" ||
      titleColumn === "Nome do produto" ||
      titleColumn === "Mês"
    ) {
      return "left";
    } else {
      return "right";
    }
  };

  const getNoBreakLine = (item) => {
    return item === "Volume (L)" ||
      item === "Preço médio (R$)" ||
      item === "Faturamento (R$)"
      ? "nowrap"
      : undefined;
  };

  return (
    <TableContainer style={{ height: "300px" }}>
      <Table className={classes.Table} stickyHeader>
        <TableHead>
          <TableRow>
            {columnTable.map((item, index) => (
              <TableCell
                key={index}
                className={classes.TableTitleColumn}
                align={getAlignColumn(item)}
                style={{
                  whiteSpace: getNoBreakLine(item),
                  background: "#F5F5F5",
                }}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {dados.map((value) => (
            <TableRow>
              {isFrentista && (
                <TableCell className={classes.TableCellFromData}>
                  {value.name ? value.name : "Frentista não cadastrado"}
                </TableCell>
              )}

              {isProductDescription && (
                <TableCell className={classes.TableCellFromData}>
                  {value.description
                    ? value.description
                    : "Produto não cadastrado"}
                </TableCell>
              )}

              {value.countTransaction && (
                <TableCell className={classes.TableCellFromData} align="right">
                  {value.countTransaction ? value.countTransaction : "-"}
                </TableCell>
              )}

              {value.date && (
                <TableCell className={classes.TableCellFromData}>
                  {value.date ? value.date : "-"}
                </TableCell>
              )}

              {value.totalVolume && (
                <TableCell
                  align="right"
                  className={classes.TableCellFromData}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {value.totalVolume ? numberFormatter(value.totalVolume) : "-"}
                </TableCell>
              )}

              {value.totalBilling && (
                <TableCell
                  align="right"
                  className={classes.TableCellFromData}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {value.totalBilling
                    ? numberFormatter(value.totalBilling)
                    : "-"}
                </TableCell>
              )}

              {value.avgPrice && (
                <TableCell className={classes.TableCellFromData} align="right">
                  {value.avgPrice ? numberFormatter(value.avgPrice) : "-"}
                </TableCell>
              )}

              {value.avgVolume && (
                <TableCell className={classes.TableCellFromData} align="right">
                  {value.avgVolume ? numberFormatter(value.avgVolume) : "-"}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSalesPerformance;

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    Table: {
      marginBottom: "0px",
    },
    TableTitleColumn: {
      background: "transparent",
      marginBottom: "10px",
      borderBottom: "none",
      fontSize: "1.6rem",
      fontFamily: "DM Sans",
      fontWeight: "bold",
    },
    TableCellFromData: {
      background: "transparent",
      marginBottom: "10px",
      borderBottom: "none",
      fontSize: "1.6rem",
      fontFamily: "DM Sans",
    },
  })
);
