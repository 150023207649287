import React from "react";
import {
  Discount,
  MappedDiscount,
  PaymentMethod,
} from "@modules/discount/model/IDiscountService";
import { ModalData } from "./ModalDiscount";
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { formatCurrency } from "@utils/index";

interface TableRowScopedProps {
  discount: Discount;
  onOpen?: (discount?: ModalData | null) => void;
}

const TableRowScoped: React.FC<TableRowScopedProps> = ({
  discount,
  onOpen,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const { discounts } = discount;

  return (
    <Paper className={classes.paperGrid}>
      <Grid
        xs={12}
        style={{ display: "flex" }}
        className={classes.gridCustomColumn}
      >
        <Grid item xs={3} className={classes.gridItem}>
          {discount.description}
        </Grid>
        <Grid item xs={isMobile ? 4 : 1} className={classes.gridItem}>
          {discount.internalCode}
        </Grid>
        <Grid
          item
          xs={1}
          className={classes.gridItem}
          style={{
            justifyContent: "center",
          }}
        >
          {discount.unit}
        </Grid>
        <Grid
          item
          xs={isMobile ? 4 : 2}
          className={classes.gridItem}
          style={{
            justifyContent: "center",
          }}
        >
          {formatCurrency(Number(discount.value))}
        </Grid>
        <Grid item xs={isMobile ? 4 : 2} className={classes.internColumn}>
          {discounts.map((discount: MappedDiscount) => (
            <Grid className={classes.internColumnGrid}>
              <Typography className={classes.discountTypograph}>
                {formatCurrency(Number(discount?.discount))}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={isMobile ? 4 : 2} className={classes.internColumn}>
          {discounts.map((discount: MappedDiscount) => (
            <Grid className={classes.internColumnGrid}>
              <Typography>{PaymentMethod[discount.paymentMethod]}</Typography>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={isMobile ? 4 : 1} className={classes.internColumn}>
          {discounts.map((disc: MappedDiscount) => (
            <Grid className={classes.internColumnGrid}>
              <Button
                type="submit"
                className={classes.editButton}
                style={{
                  padding: isMobile ? "10px 30px" : "10px 30px",
                  fontSize: isMobile ? "12px" : "",
                }}
                onClick={() =>
                  onOpen &&
                  onOpen({
                    ...disc,
                    description: discount.description || "",
                    value: discount.value,
                    productId: discount.productId,
                  })
                }
              >
                Editar
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TableRowScoped;

const useStyles = makeStyles((theme) =>
  createStyles({
    tableRow: {
      marginTop: "2rem",
    },
    grid: { height: "90px", marginTop: 16, marginBottom: 16 },
    gridColumn: { height: "60px", marginTop: 16, marginBottom: 16 },
    gridCustomColumn: { marginTop: 16, marginBottom: 16 },
    gridItem: {
      display: "flex",
      color: theme.palette.primary.highlightedplus,
      fontFamily: "DM Sans",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "20px",
      padding: "23px 0",
    },
    gridItemColumn: {
      color: theme.palette.primary.highlightedplus,
      fontFamily: "DM Sans",
      fontSize: "16px",
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
    },
    paperGrid: {
      borderRadius: "8px",
      padding: "0px 57px 0px 32px",
      boxShadow: `0px 4px 4px rgba(115, 97, 97, 0.10)`,
    },
    internColumn: {
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
    },
    internColumnGrid: {
      height: 65,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    discountTypograph: {
      color: theme.palette.primary.main,
      fontWeight: 700,
      textAlign: "center",
    },
    editButton: {
      fontFamily: "Montserrat",
      borderRadius: "2px",
      textTransform: "none",
      color: theme.palette.primary.main,
      background: theme.palette.text.hint,
      boxShadow: "none",
      height: "56px",
    },
  })
);
