import { Types } from "@ioc/types";
import * as IHttpService from "@modules/infra/http/models/IHttpService";
import { inject, injectable, named } from "inversify";
import { IGetSalesVolumeDTO } from "../dto/IGetSalesVolumeDTO";
import { IQuerySalesVolumeDTO } from "../dto/IQuerySalesVolumeDTO";
import { IGetSalesVolumeService } from "../models/IGetSalesVolumeService";

@injectable()
export class GetSalesVolumeService implements IGetSalesVolumeService {
  @inject(Types.IHttpService)
  @named("pump")
  private httpInstance!: IHttpService.IHttpService;

  public async execute(
    data: IQuerySalesVolumeDTO,
    showByProduct: boolean
  ): Promise<IGetSalesVolumeDTO[]> {
    try {
      let url = `/dash_volume_billing_price?${data.salesVolume}&document=${data.cnpj}&${data.period}&productIds=${data.productIds}`;
      if (showByProduct) {
        url += "&groupBy[]=product";
      }
      const salesVolumeResponse = await this.httpInstance.get<
        IGetSalesVolumeDTO[]
      >(url);

      return salesVolumeResponse;
    } catch (err) {
      throw err;
    }
  }

  public setAuthorization(token: string): void {
    this.httpInstance.setAuthorization(token);
  }
}
