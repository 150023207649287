/* eslint-disable no-lone-blocks */
import React, { useState } from "react";

import { Box, Button, Grid, Input, Typography } from "@mui/material";
import { useTheme } from "@material-ui/core";

import { UploadIcon } from "@components/Icons";

interface DragInDropProps {
  onChange: (files: File[]) => void;
  amountFilesLimit?: number;
}

const DragInDrop: React.FC<DragInDropProps> = ({ ...props }) => {
  const theme = useTheme();

  const [files, setFiles] = useState<File[]>([]);
  const [dragging, setDragging] = useState(false);

  const TitleDragInDrop = ({ children }) => {
    return (
      <Typography
        sx={{
          fontFamily: theme.typography.fontFamily,
          fontWeight: 900,
          fontSize: { xs: 10, md: 15, sm: 15, lg: 19 },
        }}
      >
        {/* Arraste o arquivo aqui */}
        {children}
      </Typography>
    );
  };

  const DescriptionDragInDrop = ({ children }) => {
    return (
      <Typography
        sx={{
          fontSize: { xs: 8, md: 11, sm: 11, lg: 12 },
          fontFamily: theme.typography.fontFamily,
          fontWeight: 500,
          opacity: 0.5,
        }}
      >
        {children}
      </Typography>
    );
  };

  const ButtonDragInDrop = ({ children, ...props }) => {
    return (
      <div>
        <Input
          id="fileInput"
          type="file"
          inputProps={{ multiple: true, accept: ".png, .svg, .jpeg, .jpg" }}
          onChange={props.onChange}
          style={{ display: "none" }}
        />
        <label htmlFor="fileInput" style={{ border: "0px solid blue" }}>
          <Button
            component="span"
            variant="contained"
            size="large"
            style={{
              fontFamily: theme.typography.fontFamily,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.text.hint,
              textTransform: "capitalize",
            }}
          >
            <Typography sx={{ fontSize: { lg: 12 }, fontWeight: 500 }}>
              {/* Enviar arquivo / escolher arquivo */}
              {children}
            </Typography>
          </Button>
        </label>
      </div>
    );
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  {
    /* Manipula Evento de Arrastar e soltar os arquivos na caixa de upload */
  }
  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const newFiles = Array.from(e.dataTransfer.files as FileList);

    if (!validateFileTypePNG(newFiles)) return;
    if (!validateFileAmount(newFiles.length)) return;

    setFiles([...files, ...newFiles]);
    props.onChange && props.onChange(newFiles);
  };

  {
    /* Manipula Evento do botao de "enviar arquivos" na caixa de upload */
  }
  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files as FileList);

    if (!validateFileTypePNG(newFiles)) return;
    if (!validateFileAmount(newFiles.length)) return;

    setFiles([...files, ...newFiles]);
    props.onChange && props.onChange(newFiles);
  };

  const validateFileTypePNG = (newFiles: File[]): Boolean => {
    const allowedExtensions = [".png", ".svg", ".jpeg", ".jpg"];

    const invalidFiles = newFiles.filter((file) => {
      const fileExtension = file.name
        .slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2)
        .toLowerCase();
      return !allowedExtensions.includes("." + fileExtension);
    });

    if (invalidFiles.length > 0) {
      alert("Por favor, selecione arquivos .png ou .svg ou .jpeg ou .jpg");
      return false;
    } else {
      return true;
    }
  };

  const validateFileAmount = (size: number): Boolean => {
    if (props && props.amountFilesLimit && size > props.amountFilesLimit) {
      alert(
        `Quantidade limite de arquivos ${props.amountFilesLimit} atingida! | voce tentou submeter ${size}`
      );
      return false;
    }
    return true;
  };

  return (
    <Box
      sx={{
        border: "2px dashed #ccc",
        textAlign: "center",
        backgroundColor: dragging ? "#f0f0f0" : "#fff",
        cursor: "pointer",
        height: "100%",
        width: "100%",
        padding: { lg: 10 },
      }}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <Grid container gap={1}>
        <Grid item xs={12}>
          <UploadIcon sx={{ fontSize: { xs: 40, sm: 40, md: 50, lg: 60 } }} />
        </Grid>
        <Grid item xs={12}>
          <TitleDragInDrop>Arraste e solte arquivos aqui</TitleDragInDrop>
        </Grid>
        <Grid item xs={12}>
          <DescriptionDragInDrop>ou</DescriptionDragInDrop>
        </Grid>
        <Grid item xs={12}>
          <ButtonDragInDrop onChange={handleFileChange}>
            Enviar arquivo
          </ButtonDragInDrop>
        </Grid>
        <Grid item xs={12}>
          <DescriptionDragInDrop>
            Escolha arquivos .png ou .svg ou .jpg
          </DescriptionDragInDrop>
          <DescriptionDragInDrop>
            Quantidade máxima de até seis arquivos.
          </DescriptionDragInDrop>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DragInDrop;
