import { Grid, GridSpacing } from "@material-ui/core";
import React, { ReactNode } from "react";

interface CardDefaultProps {
  children: ReactNode;
  style?: React.CSSProperties;
  spacing?: GridSpacing | undefined;
}

const CardDefault: React.FC<CardDefaultProps> = ({
  children,
  style,
  spacing = 2,
}) => {
  return (
    <Grid
      container
      spacing={spacing}
      style={{
        boxShadow: `0px 4px 4px rgba(115, 97, 97, 0.10)`,
        marginBottom: "3rem",
        paddingBottom: "3rem",
        borderRadius: 8,
        background: "#fff",
        width: "100%",
        height: "auto",
        ...style,
      }}
    >
      {children}
    </Grid>
  );
};

export default CardDefault;
