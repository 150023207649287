import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import ApexCharts from "react-apexcharts";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";

interface ChartProps {
  chartType: string;
  height: number;
  width: string;
  title?: string;
  categories: string[];
  data: number[];
}

const ColumnChart: React.FC<ChartProps> = ({
  chartType,
  height,
  width,
  title,
  categories,
  data,
}) => {
  const [showTable, setShowTable] = useState(false);
  const classes = useStyle();
  const options = {
    chart: {
      type: chartType,
      height: height,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        columnWidth: "20%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      title: {
        text: "",
      },
      min: 0,
      max: Math.max(...data),
      tickAmount: 6,
      labels: {
        formatter: function (val: number) {
          return val.toFixed(0);
        },
      },
    },
    grid: {
      strokeDashArray: 5,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
    fill: {
      colors: ["#D91F05"],
    },
    title: {
      text: title,
      align: "center",
      style: {
        color: "#3E3D3D",
      },
    },
  };

  const series = [
    {
      name: "Valor",
      data: data,
      stroke: {
        colors: ["#D91F05"],
        width: 3,
        dashArray: "5, 3",
      },
    },
  ];
  const toggleTable = () => {
    setShowTable(!showTable);
  };

  if (showTable) {
    return (
      <Box width="100%">
        <Box className={classes.containerButton}>
          <button onClick={toggleTable} className={classes.button}>
            Visualizar gráfico
          </button>
        </Box>

        <Table style={{ width: "100%", borderBottom: "none" }}>
          <TableHead>
            <TableRow
              style={{
                justifyContent: "space-between",
              }}
            >
              <TableCell
                className={`${classes.textTable} ${classes.titleColumn}`}
              >
                Nome
              </TableCell>
              <TableCell
                className={`${classes.textTable} ${classes.titleColumn}`}
                style={{ textAlign: "right" }}
              >
                Gasolina Aditivada (Qtd)
              </TableCell>
              <TableCell
                className={`${classes.textTable} ${classes.titleColumn}`}
                style={{ textAlign: "right" }}
              >
                Comissão (R$)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category, index) => (
              <TableRow key={category} className={classes.tableRow}>
                <TableCell
                  className={classes.textTable}
                  style={{ width: "33.33%" }}
                >
                  {category}
                </TableCell>
                <TableCell
                  className={classes.textTable}
                  style={{ width: "30%", textAlign: "right" }}
                >
                  {data[index]} litros
                </TableCell>
                <TableCell
                  className={classes.textTable}
                  style={{ width: "33.33%", textAlign: "right" }}
                >
                  Comissão (R$)
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    );
  } else {
    return (
      <Box width="100%">
        <Box className={classes.containerButton}>
          <button onClick={toggleTable} className={classes.button}>
            Visualizar tabela
          </button>
        </Box>
        <Box className={classes.containerText}>
          <Typography className={classes.descriptionChart} variant="subtitle2">
            Meta (em litros)
          </Typography>
        </Box>
        <Box id="chart">
          {/* @ts-ignore */}
          <ApexCharts
            options={options}
            series={series}
            type={chartType}
            height={height}
            width={width}
          />
        </Box>
      </Box>
    );
  }
};

export default ColumnChart;

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    containerButton: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    containerText: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
    },
    descriptionChart: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWight: 500,
      lineHeight: "20px",
      display: "flex",
      alignItems: "center",
      color: "#3E3D3D",
      marginLeft: "1%",
    },
    button: {
      marginTop: "65px",
      marginBottom: "5px",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      display: "flex",
      alignItems: "center",
      textAlign: "right",
      color: theme.palette.primary.main,
      border: "none",
      backgroundColor: "transparent",
      "&:hover": { textDecorator: "underline", cursor: "pointer" },
    },
    tableRow: {
      "& > *": {
        borderBottom: "1px dashed #ccc",
        borderTop: "none",
      },
    },
    textTable: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px",
      padding: 12,
    },
    titleColumn: {
      fontWeight: 500,
      borderBottom: "none",
    },
  })
);
