import { Filial } from "./interfaces";

const affiliatedSocieties: Filial[] = [
  { id: "C001", name: "Manaus" },
  { id: "C002", name: "Cruzeiro do Sul" },
  { id: "C003", name: "Porto Velho" },
  { id: "C004", name: "Vilhena" },
  { id: "C005", name: "Várzea Grande" },
  { id: "C006", name: "Rio Branco" },
  { id: "C008", name: "Itaituba" },
  { id: "C009", name: "Belém" },
  { id: "C010", name: "Paranaguá" },
  { id: "C011", name: "Santarém" },
  { id: "C012", name: "Sinop" },
  { id: "C013", name: "Caracaraí" },
  { id: "C080", name: "Campo Grande 1" },
  { id: "C081", name: "Campo Grande 2" },
  { id: "C082", name: "Paulínia" },
];

export default affiliatedSocieties;

export const findAffiliatedSociety = (id: string): Filial | undefined => {
  return affiliatedSocieties.find((society) => society.id === id);
};
