import {
  OutlinedTextFieldProps,
  TextField,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React from "react";
interface TextFieldCustomProps extends OutlinedTextFieldProps {
  id?: string;
  label?: string;
  name: string;
  value: string;
  type: string;
  hasTouched?: boolean;
  hasErros?: string;
  backgroundColor?: "black";
  height?: string;
}

const useStyles = makeStyles((theme: Theme & TextFieldCustomProps) =>
  createStyles({
    root: {
      "& input": {
        boxShadow: "0px 0px 0px 30px white inset",
      },
      "& label": {
        opacity: 0.6,
        fontSize: "1.4rem",
      },
      "& .MuiInputBase-root": {
        borderRadius: "1rem",
      },
      "& .MuiInputLabel-shrink": {
        fontSize: "1.5rem",
        opacity: 1,
        color: theme.palette.text.primary,
        fontWeight: 700,
      },
      "& .MuiInputBase-input": {
        color: theme.palette.text.primary,
        fontWeight: 500,
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.secondary.light}`,
      },
      "& .MuiFormHelperText-root.Mui-error.Mui-focused": {
        display: "none",
      },
    },
    hasErrorFieldSet: {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.error.main}`,
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.secondary.light}`,
      },
    },
  })
);

const TexFieldCustomPassword: React.FC<TextFieldCustomProps> = (props) => {
  const classes = useStyles(props);

  return (
    <TextField
      {...props}
      type={props.type}
      name={props.name}
      className={classes.root}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      fullWidth
    />
  );
};

export default TexFieldCustomPassword;
