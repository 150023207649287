import { createStyles, Grid, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import CardHandout from "../../../components/CardHandout";
import Layout from "../../../components/Layout";
import Title from "../../../components/Title";
import {
  useHandoutDispatch,
  useHandoutState,
} from "../../../context/HandoutContext";
import { useIoCContext } from "../../../context/IoCContext/IoCContext";
import { useUserState } from "../../../context/UserContext";
import { Types } from "../../../ioc/types";
import { IGetHandoutsSeenOrNotService } from "../../../modules/handouts/models/IGetHandoutsSeenOrNotService";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: "2rem",
    },
  })
);

const HandoutsPage: React.FC = () => {
  const classes = useStyles();
  const userState = useUserState();
  const { enqueueSnackbar } = useSnackbar();
  const handoutState = useHandoutState();
  const handoutDispatch = useHandoutDispatch();
  const iocContext = useIoCContext();

  useEffect(() => {
    async function fetch() {
      try {
        const getHandoutsSeenOrNot = iocContext.serviceContainer.get<
          IGetHandoutsSeenOrNotService
        >(Types.Handouts.IGetHandoutsSeenOrNotService);
        const handouts = await getHandoutsSeenOrNot.execute(userState.userID);

        handoutDispatch({
          type: "APP_SET_HANDOUTS_NOT_VIEWED",
          payload: handouts,
        });
      } catch (error) {
        console.log("Ocorreu um erro ao baixar comunicados");
      }
    }
    fetch();
  }, [
    enqueueSnackbar,
    handoutDispatch,
    userState.userID,
    handoutState.showModal,
    iocContext.serviceContainer,
  ]);

  return (
    <Layout>
      <div style={{ gridArea: "content" }} className={classes.container}>
        <Title title="Comunicados" />
        <Grid container justify="center" spacing={2}>
          {handoutState.handoutsNotViewed.map((ele) => {
            return (
              <Grid key={ele.id} item xs={12} sm={6} md={4} lg={3}>
                <CardHandout
                  id={ele.id}
                  content={ele.content}
                  title={ele.title}
                  sendedAt={ele.createdAt}
                  viewed={Boolean(ele.handoutAck)}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Layout>
  );
};

export default HandoutsPage;
