import { inject, injectable, named } from "inversify";
import jwt_decode from "jwt-decode";
import { Types } from "../../../ioc/types";
import {
  IAuthService,
  LoginAuthService,
  LoginAuthServiceResponse,
  TokenDecoded,
} from "../models/IAuthService";
import * as IHttpService from "@modules/infra/http/models/IHttpService";

@injectable()
export class AuthService implements IAuthService {
  @inject(Types.IHttpService)
  @named("cognito")
  private httpInstance!: IHttpService.IHttpService;

  private decodeToken(token: string) {
    const decodedAccessToken = jwt_decode<TokenDecoded>(token);
    return decodedAccessToken;
  }

  public async login(
    data: LoginAuthService
  ): Promise<LoginAuthServiceResponse> {
    const loginResponse = await this.httpInstance.post<
      LoginAuthServiceResponse
    >("/auth/login", data);

    return {
      ...loginResponse,
      decodeAccessToken: this.decodeToken(loginResponse.AccessToken),
      isLoginyQrCode: false,
    };
  }

  public setAuthorization(token: string): void {
    this.httpInstance.setAuthorization(token);
  }
}
