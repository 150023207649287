import React from "react";

import { Box, Drawer, DrawerProps } from "@material-ui/core";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import ButtonDefault from "@components/ButtonDefault";

type Anchor = "top" | "left" | "bottom" | "right";

interface DrawerCustomProps extends DrawerProps {
  key?: string;
  label: string;
  anchortype: Anchor;
  disabled?: boolean;
}

interface ChildrenWithProps {
  handle?: () => void;
}

const DrawerCustom: React.FC<DrawerCustomProps> = (props) => {
  const classes = useStyles();
  const [hasFilter, setHasFilter] = React.useState(false);

  const handleOnClickOpenDrawer = () => {
    setHasFilter(true);
  };

  const handleOnClickClosedDrawer = () => {
    setHasFilter(false);
  };

  const propsChild: Partial<ChildrenWithProps> = {
    handle: handleOnClickClosedDrawer,
  };

  const chidrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, propsChild);
    }
  });

  return (
    <React.Fragment>
      <ButtonDefault
        variant={"text"}
        style={{ padding: 0, fontSize: 14 }}
        onClick={handleOnClickOpenDrawer}
        disabled={props.disabled}
      >
        {props.label}
      </ButtonDefault>

      <Drawer anchor={props.anchortype} open={hasFilter}>
        <Box className={classes.drawerBox}>{chidrenWithProps}</Box>
      </Drawer>
    </React.Fragment>
  );
};

export default DrawerCustom;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerBox: {
      width: "600px !important",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "end",
      width: "auto",
      overflowX: "hidden",
      margin: "3rem",
    },
    boxCloseIcon: {
      alignSelf: "end",
      margin: "1rem 5rem 0 0",
    },
    title: {
      color: theme.palette.text.primary,
      fontWeight: 700,
      fontSize: "2.2rem",
      margin: "0 0 0 4rem",
    },
    boxAvatarIcon: {
      alignSelf: "center",
      marginTop: "8rem",
      marginBottom: "8rem",
      textAlign: "center",
    },
    avatar: {
      height: "fit-content",
      width: "fit-content",
      marginLeft: "auto",
      marginRight: "auto",
    },
    gridContainerInput: {
      flexDirection: "column",
      width: "auto",
      margin: "0 3rem 0 3rem",
    },
    gridBoxMarket: {
      display: "flex",
      justifyContent: "center",
      margin: "5.5rem 0 5.5rem 0",
    },
    boxMarketIcon: {
      height: "fit-content",
      width: "fit-content",
      marginRight: "1.5rem",
      marginTop: "0.6rem",
    },
    button: {
      color: theme.palette.primary.main,
      textTransform: "initial",
      fontWeight: 700,
      marginLeft: "5rem",
      padding: 0,
      lineHeight: 0,
      marginBottom: "0.5rem",
      marginTop: "0.5rem",
    },
  })
);
