import { SITUATION_TYPE } from "./ENUMS_TITLES";

export const getSituation = (situation) => {
  switch (situation) {
    case SITUATION_TYPE.CLOSED:
      return "success";
    case "partial":
      return "middle-warning";
    case SITUATION_TYPE.OPEN:
      return "warning";
    default:
      return "error";
  }
};
