import ButtonLinkGeneric from "@components/ButtonLinkGeneric/ButtonLinkGeneric";
import { CactusIcon } from "@components/Icons";
import PageNotFound from "@components/PageNotFound/PageNotFound";
import TableLoader from "@components/TableLoader";
import TableSalesPerformance from "@components/TableSalesPerformance";
import ROUTES from "@config/routes";
import { usePerformanceManagement } from "@context/PerformanceManagementContext";
import { Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React from "react";
import { useStylesPerformanceManagement } from "..";
import { ENUM_TARGET_PERFORMANCE } from "../Model";
import SelectToFilterTablePerformance from "./SelectToFilterTablePerformance";

const SalesPerformance: React.FC = () => {
  const classes = useStylesPerformanceManagement();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const {
    isSalesPerformanceEmpty,
    isLoadingSalesPerformance,
    salesPerformance,
    salesPerformanceSelected,
  } = usePerformanceManagement();

  const TITLE_MAP = {
    [ENUM_TARGET_PERFORMANCE.SALES_BY_EMPLOYEE]:
      "Performance de vendas por frentistas",
    [ENUM_TARGET_PERFORMANCE.SALES_VOLUME]: "Volume de vendas",
    [ENUM_TARGET_PERFORMANCE.LAST_MONTH]: "Vendas dos últimos meses",
  };

  let titleTable =
    (salesPerformanceSelected.value &&
      TITLE_MAP[salesPerformanceSelected.value]) ||
    "";

  const isValidSalesData =
    Array.isArray(salesPerformance) && salesPerformance.length > 0;

  const hasUndesiredData =
    isValidSalesData &&
    salesPerformance.some(
      (item) =>
        Object.values(item).every((value) => value === null) ||
        Object.values(item).every((value) => value === "")
    );

  const hasSalesPerformanceData = isValidSalesData && !hasUndesiredData;
  return (
    <Box className={classes.containerSection}>
      <Box
        style={{
          display: isMobile ? "block" : "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          marginBottom: "0.8rem",
        }}
      >
        <Typography
          className={classes.titleSection}
          variant={isMobile ? "subtitle1" : "h5"}
        >
          {titleTable}
        </Typography>

        <SelectToFilterTablePerformance />
      </Box>

      {isLoadingSalesPerformance ? (
        <Grid container>
          <Grid item xs={12}>
            <TableLoader />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: isMobile ? "auto" : "-40px",
              marginLeft: "10px",
            }}
          >
            <ButtonLinkGeneric
              text="Calculadora de comissão"
              link={ROUTES.USER_ROUTES.COMMISSION_CALCULATOR}
            />
          </Grid>
        </Grid>
      ) : isSalesPerformanceEmpty || !hasSalesPerformanceData ? (
        <PageNotFound
          title="Tabela vazia"
          subtitle="Não houve resultado para o filtro da pesquisa. Por favor tente outro filtro."
          icon={<CactusIcon />}
        />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <TableSalesPerformance data={salesPerformance} />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginLeft: "15px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <ButtonLinkGeneric
              text="Calculadora de comissão"
              link={ROUTES.USER_ROUTES.COMMISSION_CALCULATOR}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SalesPerformance;
