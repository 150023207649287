import { Box, createStyles, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";

const useStyles = makeStyles(({ ...theme }) =>
  createStyles({
    tableFooter: {
      backgroundColor: "transparent",
      padding: "3.2rem 0rem",
      width: "100%",
      fontWeight: 500,
      textAlign: "left",
      fontSize: "1.6rem",
      color: "#2c3e50",
      borderBottomLeftRadius: "1.6rem",
      borderBottomRightRadius: "1.6rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
    button: {
      border: "none",
      width: "3.2rem",
      height: "3.2rem",
      borderRadius: "0.4rem",
      cursor: "pointer",
      marginRight: "0.4rem",
      marginLeft: "0.4rem",
      fontSize: "1.6rem",
      fontWeight: 500,
    },
    activeButton: {
      color: "#D91F05",
      background: "white",
    },
    inactiveButton: {
      color: "#B0C1E1",
      background: "transparent",
    },
  })
);

// Props that will be passed to the tableFooter component 
export interface IPropsTableFooter {
  range: number[];
  setPage: (page: number) => void;
  page: number;
  slice: any[];
}

const TableFooter: React.FC<IPropsTableFooter> = ({
  range,
  setPage,
  page,
  slice,
}) => {
  const classes = useStyles();

  // useEffect that checks the size of the slice (array that traverses the items filled by the data) is less than 1 and if page is different from 1.
  // If it is, update the setPage with page - 1
  useEffect(() => {
    if (slice.length < 1 && page !== 1) setPage(page - 1);
  }, [slice, page, setPage]);

  return (
    <Box className={classes.tableFooter}>
      {range.map((ele, index) => (
        <button
          key={index}
          className={`${classes.button} ${
            page === ele ? classes.activeButton : classes.inactiveButton
          }`}
          onClick={() => setPage(ele)}
        >
          {ele}
        </button>
      ))}
    </Box>
  );
};

export default TableFooter;
