import React from "react";

import Layout from "@components/Layout";
import PageControler from "@pages/User/NewRequest/resources";
import { FormProvider } from "@pages/User/NewRequest/resources/context/FormContext";
import FormNewRequest from "../NewRequestOld/FormNewRequest";
import { useFlags } from "flagsmith/react";
import { FormProviderRequestOrderOld } from "../NewRequestOld/FormContext";
import Title from "@components/Title";

const NewRequest = () => {
  const flags = useFlags(["user_home_card_new_order"]);

  return (
    <Layout>
      <div style={{ overflowY: "hidden" }}>
        {flags.user_home_card_new_order.enabled ? (
          <FormProvider>
            <PageControler />
          </FormProvider>
        ) : (
          <>
            <Title title="Novo Pedido" />
            <FormProviderRequestOrderOld>
              <FormNewRequest />
            </FormProviderRequestOrderOld>
          </>
        )}
      </div>
    </Layout>
  );
};

NewRequest.displayName = "NewRequest";

export default NewRequest;
