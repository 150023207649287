import { inject, named, injectable } from "inversify";
import { Types } from "@ioc/types";
import * as IHttpService from "@modules/infra/http/models/IHttpService";
import {
  IGetSummaryChartDTO,
  IGetSummaryChartService,
} from "@modules/summaryChart/model";

@injectable()
export class GetSummaryChartService implements IGetSummaryChartService {
  @inject(Types.IHttpService)
  @named("pump")
  private httpInstance!: IHttpService.IHttpService;

  public async execute(cnpj: string): Promise<IGetSummaryChartDTO> {
    try {
      const summaryChartResponse = await this.httpInstance.get<
        IGetSummaryChartDTO
      >(`dashboard?document=${cnpj}&action=sales_summary`);
      return summaryChartResponse;
    } catch (err) {
      throw err;
    }
  }

  public setAuthorization(token: string): void {
    this.httpInstance.setAuthorization(token);
  }
}
