import React from "react";
import {
  ButtonProps,
  Button as ButtonV5,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@material-ui/core";

export interface ButtonCustomProps extends ButtonProps {
  loading?: boolean;
}
const Button: React.ForwardRefExoticComponent<ButtonCustomProps> = React.forwardRef<
  HTMLButtonElement,
  ButtonCustomProps
>((props, ref) => {
  const theme = useTheme();
  const { sx, style, loading, ...rest } = props;

  return props.loading ? (
    <Button
      variant="outlined"
      disabled={true}
      sx={{ px: { xs: 3.5, lg: 5, xl: 6 }, py: { xs: 1, lg: 1.2 } }}
    >
      <CircularProgress color="error" size="20px" />
    </Button>
  ) : (
    <ButtonV5
      ref={ref}
      style={{
        fontFamily: theme.typography.fontFamily,
        textTransform: "capitalize",
        ...style,
      }}
      sx={{
        opacity: props.disabled ? "0.5" : "1",
        "&.MuiButton-outlined": {
          border: `1px solid ${theme.palette.primary.main}`,
        },
        "&.MuiButton-contained": {
          bgcolor: theme.palette.primary.main, // "#D91F05"
          color: theme.palette.text.hint, // "#faff"
        },
        bgcolor: "transparent",
        color: theme.palette.primary.main,
        fontSize: { xs: 14, sm: 10, md: 10, lg: 12, xl: 14 },
        paddingX: 2,
        paddingY: 1,
        ...sx,
      }}
      {...rest}
    >
      {props.children}
    </ButtonV5>
  );
});

export default Button;
