import * as Yup from "yup";

const FormQueryValidationSchema = Yup.object().shape({
  CNPJ: Yup.array().of(Yup.string()).required("Selecione um CNPJ"),
  logisticStatus: Yup.string()
    .oneOf(["billed", "toBill", "all"], "Selecione uma das 4 opções")
    .required("Selecione uma situação logística"),
  financialStatus: Yup.string()
    .oneOf(["blocked", "approved", "all"], "Selecione uma das 5 opções")
    .required("Selecione uma situação financeira"),
  filterBy: Yup.string()
    .oneOf(["deliveryDate", "implantationDate"], "Selecione uma das 2 opções")
    .required("Selecione uma ordernação"),
  startDate: Yup.date()
    .nullable()
    .typeError("Digite uma data válida")
    .required("Selecione a data de ínicio"),
  endDate: Yup.date()
    .min(Yup.ref("startDate"), "A data final não pode ser anterior a inicial")
    .nullable()
    .typeError("Digite uma data válida")
    .required("Selecione a data final"),
});

export default FormQueryValidationSchema;
