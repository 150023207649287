import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useCallback } from "react";

import BreadcrumbCustom from "@components/BreadcrumbCustom";
import CardLayerActions from "@components/CardLayerActions";
import { CheckListIcon } from "@components/Icons";
import Layout from "@components/Layout";
import {
  CommissionCalculatorProvider,
  useCommissionCalculatorContext,
} from "@context/ComissionCalculatorContext/ComissionCalculatorContext";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import { IFilterComissionService } from "@modules/comissionCalculator/models/IFilterComissionService";
import { convertYearMonthDay } from "@utils/index";
import { getCNPJinLocalStorage } from "@utils/localStorageUtils";
import { useSnackbar } from "notistack";
import { formatNumberToFloat } from "../../UtilsPerfomanceManagement/utilsPerformanceManagment";
import FilterCommissionCalculator from "./FilterCommissionCalculator";
import SkeletonTableComission from "./SectionsTable/SkeletonTableComission";
import TableComissionAll from "./SectionsTable/TableComissionAll";
import { breadcrumbDataComission } from "./models/BreadCrumbs";
import { ENUM_PRODUCT_ID_BY_NAME } from "./models/ENUM_PRODUCT_ID_BY_NAME";

const ComissionCalculator: React.FC = () => {
  const CNPJ_LOCAL_DROPDOWN = getCNPJinLocalStorage("cnpjSelected");
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const queryComissions = iocContext.serviceContainer.get<
    IFilterComissionService
  >(Types.Comission.IFilterComissionService);

  const {
    setDataQueryComission,
    setLoadingDataFilterComission,
    loadingDataFilterComission,
    dataQueryComission,
    setErrorData,
    isErrorData,
    setIsCalendarError,
    setIsFirstSearch,
    isFirstSearch,
  } = useCommissionCalculatorContext();

  const initialValues = {
    startDate: null,
    endDate: null,
    date_range: [null, null],
    gasolinaAditivada: 0,
    gasolinaComum: 0,
    diesels10: 0,
    diesels500: 0,
    etanol: 0,
  };

  const showSnackbar = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      style: {
        marginTop: "50px",
      },
    });
  };

  const handleSubmit = useCallback(
    async (values) => {
      const productsTypes = [
        "gasolinaAditivada",
        "gasolinaComum",
        "diesels10",
        "diesels500",
        "etanol",
      ];
      const isFuelTypeEmpty = (productType) => values[productType] === 0;

      const isAllProductNull = productsTypes.every(isFuelTypeEmpty);

      const isCalendarNull =
        values.date_range[0] === null && values.date_range[1] === null;

      if (isCalendarNull) {
        setIsCalendarError(true);
        showSnackbar("Informe o período para calcular a comissão.");
      } else if (isAllProductNull) {
        setIsCalendarError(false);
        showSnackbar(
          "Informe pelo menos o valor de um produto, como Gasolina, Etanol ou Diesel, para calcular a comissão."
        );
      } else {
        try {
          setErrorData(false);
          setLoadingDataFilterComission(true);

          const productsAndCommissions = [
            {
              productId: ENUM_PRODUCT_ID_BY_NAME.GASOLINA_COMUM,
              commission: formatNumberToFloat(values.gasolinaComum),
            },
            {
              productId: ENUM_PRODUCT_ID_BY_NAME.GASOLINA_ADITIVADA,
              commission: formatNumberToFloat(values.gasolinaAditivada),
            },
            {
              productId: ENUM_PRODUCT_ID_BY_NAME.DIESEL_S10,
              commission: formatNumberToFloat(values.diesels10),
            },
            {
              productId: ENUM_PRODUCT_ID_BY_NAME.DIESEL_S500,
              commission: formatNumberToFloat(values.diesels500),
            },
            {
              productId: ENUM_PRODUCT_ID_BY_NAME.ETANOL_HIDRATADO,
              commission: formatNumberToFloat(values.etanol),
            },
          ];

          const filteredProductsAndCommissions = productsAndCommissions.filter(
            (item) => item.commission !== 0
          );

          const response = await queryComissions.execute({
            document: CNPJ_LOCAL_DROPDOWN,
            beginDate: convertYearMonthDay(values.date_range[0]),
            endDate: convertYearMonthDay(values.date_range[1]),
            productsAndCommissions: filteredProductsAndCommissions,
          });

          setDataQueryComission(response);
          setIsFirstSearch(false);

          window.scrollTo({
            top: 390,
            left: 0,
            behavior: "smooth",
          });
        } catch (error) {
          showSnackbar("Houve um problema ao calcular comissão..");
          setErrorData(true);
        } finally {
          setLoadingDataFilterComission(false);
          setIsFirstSearch(false);
          setIsFirstSearch(false);
          setIsCalendarError(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      CNPJ_LOCAL_DROPDOWN,
      enqueueSnackbar,
      queryComissions,
      setDataQueryComission,
      setErrorData,
      setLoadingDataFilterComission,
    ]
  );

  return (
    <Layout disableFooter>
      <Container>
        <Grid
          container
          style={{
            width: "auto",
            height: "100%",
            paddingBottom: isFirstSearch ? "70px" : "0px",
          }}
        >
          <Grid xs={10} style={{ padding: 8 }}>
            <BreadcrumbCustom data={breadcrumbDataComission} />
          </Grid>

          <CardLayerActions
            title="Calculadora de comissão"
            icon={CheckListIcon}
          >
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              <Form>
                <FilterCommissionCalculator />
              </Form>
            </Formik>
          </CardLayerActions>

          <Grid container justifyContent="center">
            <Typography
              style={{
                color: "#3E3D3D",
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                marginTop: "40px",
                marginBottom: "30px",
              }}
            >
              {dataQueryComission.employees.length > 0 &&
                !isFirstSearch &&
                !loadingDataFilterComission &&
                "Resultados da busca"}

              {dataQueryComission.employees.length === 0 &&
                !isFirstSearch &&
                !loadingDataFilterComission &&
                !isErrorData &&
                "Não houve resultado da comissão para o período e valores de produtos informados."}

              {loadingDataFilterComission && "Calculando comissão..."}

              {isFirstSearch &&
                !loadingDataFilterComission &&
                "Para obter o resultado da comissão, por favor, insira o período e pelo menos um dos valores do produto."}

              {!isFirstSearch &&
                !loadingDataFilterComission &&
                isErrorData &&
                "Ocorreu um erro ao tentar calcular a comissão para os valores informados."}
            </Typography>
          </Grid>

          {loadingDataFilterComission && (
            <SkeletonTableComission
              cols={isMobile ? 2 : 6}
              rows={isMobile ? 10 : 10}
            />
          )}

          {dataQueryComission.employees.length > 0 &&
            !loadingDataFilterComission &&
            !isFirstSearch && <TableComissionAll />}

          <Box
            style={{
              padding: dataQueryComission.employees.length ? "15px" : "100px",
            }}
          ></Box>
        </Grid>
      </Container>
    </Layout>
  );
};

const ContainerComissionCalculator: React.FC = () => {
  return (
    <CommissionCalculatorProvider>
      <ComissionCalculator />
    </CommissionCalculatorProvider>
  );
};

export default ContainerComissionCalculator;
