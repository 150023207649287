import { Box, Modal } from "@material-ui/core";
import { IGetSimulationResultDto } from "@modules/simulation/dtos/ISimulateLoadDTO";
import { Form, Formik } from "formik";
import React, { useCallback } from "react";
import { useState } from "react";
import * as Yup from "yup";
import { IFormSimulateOrder } from "../../../../utils/interfaces";
import { validatePlate } from "@utils/index";
import TemporaryFormItems from "./TemporaryFormItems";
import { useFlags } from "flagsmith/react";
import FormItems from "./FormItems";

const style = {
  position: "absolute" as "absolute",
  textAlign: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1020,
  bgcolor: "background.paper",
  pt: 4,
  px: 6,
  pb: 6,
  borderRadius: "1.5rem",
};

export const NewOrderValidationSchema = Yup.object().shape({
  operacao: Yup.string().required("Este campo é obrigatório."),
  id_erp: Yup.string(),
  placa: Yup.string()
    .required("Este campo é obrigatório.")
    .test("validCPF", "Precisamos de uma placa válida", (value) =>
      value ? validatePlate(value) : false
    ),
  reason: Yup.string().required("Este campo é obrigatório."),
  comment: Yup.string().matches(
    /^[a-zA-Z1-9 -]*$/,
    "Apenas letras ou números. Verifique se contém acentos e/ou ç."
  ),
});

const TemporaryIndexFormSimulateOrder = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [success, setSuccess] = useState<boolean>(true);
  const [message, setMessage] = useState<string>("");

  const initialValues: IFormSimulateOrder = {
    placa: "",
    products: [],
    productSelected: "",
  };

  const handleModal = useCallback((resultDTO: IGetSimulationResultDto) => {
    setSuccess(resultDTO.status === "SUCCESS");
    setMessage(resultDTO.message);
    handleOpen();
  }, []);

  const flag_simulate_loading = useFlags(["user_sidebar_simulate_loading"]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={NewOrderValidationSchema}
      onSubmit={() => {}}
    >
      <Form style={{ paddingBottom: "5%" }}>
        {flag_simulate_loading.user_sidebar_simulate_loading.enabled ? (
          <FormItems handleModal={handleModal} />
        ) : (
          <TemporaryFormItems handleModal={handleModal} />
        )}

        <Modal open={open} onClose={handleClose}>
          <Box sx={{ ...style, width: 540 }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "right",
                paddingBottom: "5.6rem",
              }}
            >
              <button
                onClick={handleClose}
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                }}
              >
                <img src="/close-icon.svg" alt="close icon" />
              </button>
            </div>
            <img
              src={success ? "/success-icon.png" : "/error-icon.png"}
              alt="success icon"
            />
            <h2
              style={{
                fontWeight: 700,
                color: "#313B45",
                fontFamily: "Noto Sans, sans-serif",
                paddingBottom: "1.6rem",
              }}
            >
              {message}
            </h2>
          </Box>
        </Modal>
      </Form>
    </Formik>
  );
};

export default TemporaryIndexFormSimulateOrder;
