import {
  Box,
  createStyles,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import Layout from "../../../components/Layout";
import { HomeContextProvider } from "./HomeContext";
import { Grid } from "@mui/material";
import postoAtemImagem from "../../../assets/postoAtemImagem.png";
import logoAtem from "../../../assets/logo-atem.svg";
import CardsSection from "./CardsSection";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "bold",
      lineHeight: "normal",
      paddingTop: "20px",
    },
    subTitle: {
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "bold",
      lineHeight: "normal",
      paddingTop: "20px",
    },
    subTitleAbove: {
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "normal",
      lineHeight: "normal",
    },
    marginItemOfCards: {
      margin: "10px",
    },
  })
);

const HomePage: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));

  return (
    <Layout disableToolbar={true} disableToolbarMobile={true}>
      <Grid
        container
        style={{
          marginLeft: isMobile ? "10px" : "20px",
          marginRight: isMobile ? "10px" : "20px",
        }}
      >
        <Grid item xs={12}>
          <Typography
            className={classes.title}
            style={{
              marginBottom: "20px",
              fontSize: isMobile ? "2rem" : isTablet ? "2.5rem" : "2.5rem",
              marginLeft: isMobile ? "10px" : "0px",
            }}
          >
            Portal do administrador
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              alignItems={"center"}
              display={"flex"}
              style={{
                background: "#FFF",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: isMobile ? "20px" : "0",
                borderBottomLeftRadius: isMobile || isTablet ? "0" : "20px",
              }}
            >
              <Box
                style={{
                  margin: isMobile || isTablet ? "30px 20px" : "30px 60px",
                }}
              >
                <Box>
                  <img
                    src={logoAtem}
                    alt="logoAtem"
                    style={{
                      backgroundSize: "cover",
                      width: isMobile ? "50px" : "80px",
                    }}
                  />
                </Box>
                <Typography
                  className={classes.title}
                  style={{
                    padding:
                      isMobile || isTablet ? "25px 0 15px 0" : "60px 0 20px 0",
                    fontSize: isMobile ? "16px" : isTablet ? "18px" : "3.5rem",
                  }}
                >
                  Gerencie as principais features{" "}
                  {!isMobile && !isTablet && <br />} dos seus clientes
                </Typography>
                <Typography
                  className={classes.subTitleAbove}
                  style={{
                    fontSize: isMobile ? "12px" : isTablet ? "14px" : "1.7rem",
                  }}
                >
                  Todas as funcionalidades do portal do revendedor sob seu olhar
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <img
                src={postoAtemImagem}
                alt="Background Posto Atem"
                width={isMobile ? "100%" : isTablet ? "100%" : "90%"}
                height={"100%"}
                style={{ backgroundSize: "cover" }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: "30px" }}>
          <Typography
            className={classes.subTitle}
            style={{ fontSize: isMobile ? "16px" : "1.9rem" }}
          >
            Para você
          </Typography>
          <Typography
            className={classes.subTitleAbove}
            style={{
              fontSize: isMobile ? "12px" : "1.5rem",
              marginTop: isMobile ? "5px" : "8px",
            }}
          >
            Seus principais tópicos{" "}
          </Typography>
        </Grid>

        <CardsSection />
      </Grid>
    </Layout>
  );
};

const Container = () => {
  return (
    <HomeContextProvider>
      <HomePage />
    </HomeContextProvider>
  );
};

export default Container;
