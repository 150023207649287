import GenericColumnChart, {
  CategoriesType,
  SeriesType,
} from "@components/GenericColumnChart";
import {
  ArrowLeftDarkIcon,
  ArrowRightDarkIcon,
  CactusIcon,
} from "@components/Icons";
import PageNotFound from "@components/PageNotFound/PageNotFound";
import SkeletonBarChart from "@components/SkeletonBarChart";
import { usePeakTimeHistory } from "@context/PeakTimeHistoryContext";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { IGetSalesVolumeDTOHistory } from "@modules/salesVolumeHistory/dto/IGetSalesVolumeDTOHistory";
import { getRelevantValue } from "@utils/index";
import React, { useCallback, useMemo, useState } from "react";
import { useStylesPerformanceManagement } from "../..";
import { ENUM_TARGET_VOLUME_SALES } from "../../Model";
import SelectToFilterSaleVolumeHistory from "./Sections/SelectToFilterSaleVolumeHistory";

type ChartData = {
  [date: string]: {
    product_id: string;
    description: string;
    totalVolume: number;
    totalBilling: number;
    avgPrice: number;
  }[];
};

const SalesVolumeChartHistory: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const classes = useStylesPerformanceManagement();
  const {
    salesVolumeHistory,
    isLoadingSalesVolumeHistory,
    salesVolumeSelected,
  } = usePeakTimeHistory();

  const [carouselIndex, setCarouselIndex] = useState(0);

  const isValidSalesData =
    Array.isArray(salesVolumeHistory) && salesVolumeHistory.length > 0;

  const hasUndesiredData =
    isValidSalesData &&
    salesVolumeHistory.some(
      (item) =>
        Object.values(item).every((value) => value === null) ||
        Object.values(item).every((value) => value === "")
    );

  const hasSalesData = isValidSalesData && !hasUndesiredData;

  const TITLE_MAP = {
    [ENUM_TARGET_VOLUME_SALES.VOLUME]: {
      label: "Volume de vendas (em litros)",
      isDecimal: false,
    },
    [ENUM_TARGET_VOLUME_SALES.BILLING]: {
      label: "Faturamento (em R$)",
      isDecimal: false,
    },
    [ENUM_TARGET_VOLUME_SALES.PRICE]: {
      label: "Preço médio (em R$)",
      isDecimal: true,
    },
  };

  let titleChart =
    (salesVolumeSelected.value && TITLE_MAP[salesVolumeSelected.value].label) ||
    "";

  let isDecimal =
    (salesVolumeSelected.value &&
      TITLE_MAP[salesVolumeSelected.value].isDecimal) ||
    false;

  const transformData = (data: IGetSalesVolumeDTOHistory[]): ChartData => {
    const transformedData: ChartData = {};
    data.forEach((entry) => {
      if (!transformedData[entry.hour]) {
        transformedData[entry.hour] = [];
      }

      transformedData[entry.hour].push({
        product_id: entry.product_id || "",
        description: entry.description || "",
        totalVolume: entry.totalVolume,
        totalBilling: entry.totalBilling,
        avgPrice: entry.avgPrice,
      });
    });

    return transformedData;
  };

  const chartData = useMemo(() => transformData(salesVolumeHistory), [
    salesVolumeHistory,
  ]);

  const getUniqueProducts = useCallback((data: ChartData): string[] => {
    return Array.from(
      new Set(
        Object.values(data).flatMap((entries) =>
          entries.map((entry) => entry.description)
        )
      )
    );
  }, []);

  const createSeries = useCallback(
    (data: ChartData, categories: CategoriesType) => {
      const products = getUniqueProducts(data);
      return products.map((product) => ({
        name: product,
        data: categories.map((category) => {
          const entry = data[category]?.find((e) => e.description === product);
          return entry ? getRelevantValue(entry) : 0;
        }),
      }));
    },
    [getUniqueProducts]
  );

  const handleNext = () => {
    setCarouselIndex((prevIndex) =>
      Math.min(prevIndex + 7, Object.keys(chartData).length - 7)
    );
  };

  const handlePrevious = () => {
    setCarouselIndex((prevIndex) => Math.max(prevIndex - 7, 0));
  };

  const filteredChartData = useMemo(() => {
    const dates = Object.keys(chartData);
    const start = carouselIndex;
    const end = Math.min(start + 7, dates.length);
    return dates.slice(start, end).reduce((acc, date) => {
      acc[date] = chartData[date];
      return acc;
    }, {});
  }, [carouselIndex, chartData]);

  const categories: CategoriesType = useMemo(
    () => Object.keys(filteredChartData),
    [filteredChartData]
  );
  const series: SeriesType[] = useMemo(
    () => createSeries(filteredChartData, categories),
    [filteredChartData, categories, createSeries]
  );

  const areChartDataValid = series.length > 0 && categories.length > 0;

  let showPagination: boolean = Object.keys(chartData).length > 7;

  return (
    <Grid item xs={12} style={{ display: "block" }}>
      <Grid item>
        <Box
          style={{
            display: isMobile ? "block" : "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Typography
            className={classes.titleSection}
            variant={isMobile ? "subtitle1" : "h5"}
          >
            Gráfico de volume de vendas
          </Typography>

          <SelectToFilterSaleVolumeHistory />
        </Box>
        <Typography className={classes.description}>{titleChart}</Typography>
      </Grid>
      <Grid item>
        {isLoadingSalesVolumeHistory ? (
          <SkeletonBarChart />
        ) : hasSalesData && areChartDataValid ? (
          <>
            <GenericColumnChart
              series={series}
              categories={categories}
              isDecimal={isDecimal}
            />
            <Box
              display={showPagination ? "flex" : "none"}
              justifyContent="flex-end"
              my={isMobile ? 0 : -4}
              mb={isMobile ? -2 : 2}
            >
              <Button
                onClick={handlePrevious}
                disabled={carouselIndex === 0}
                className={classes.buttonLinkChart}
              >
                <ArrowLeftDarkIcon className={classes.iconArrowButton} />
                Anterior
              </Button>
              <Button
                onClick={handleNext}
                disabled={carouselIndex >= Object.keys(chartData).length - 7}
                className={classes.buttonLinkChart}
              >
                Próximo
                <ArrowRightDarkIcon className={classes.iconArrowButton} />
              </Button>
            </Box>
          </>
        ) : (
          <PageNotFound
            title="Não foram encontrados resultados"
            subtitle="Nada para exibir no gráfico. Tente aplicar novos filtros."
            icon={<CactusIcon />}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default SalesVolumeChartHistory;
