import { IProductDTO } from "@modules/salesPerformance/dtos/IProductDTO";
export interface ISelectType {
  label: string;
  value: string;
}

export const transformData = (originalData: IProductDTO[]): ISelectType[] => {
  const transformedData: ISelectType[] = [];
  for (const item of originalData) {
    if (item.group === "GNV") {
      continue;
    }
    const transformedItem: ISelectType = {
      label: item.description,
      value: item.id,
    };
    transformedData.push(transformedItem);
  }
  return transformedData;
};

export const formatNumberToCurrency = (value) => {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });

  const formattedValue = formatter.format(value / 100);
  const valueWithoutCurrencySymbol = formattedValue.replace(/R\$/g, "");

  return valueWithoutCurrencySymbol.trim();
};

export const formatNumberToFloat = (value: number | string): number => {
  const numericValue: number =
    typeof value === "string" ? parseFloat(value) : value;

  const formattedValue: number = numericValue / 100;

  return formattedValue;
};
