import { Table, TableCell, TableRow } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

interface ISkeletonTableComissionProps {
  rows?: number;
  cols?: number;
}

const SkeletonTableComission: React.FC<ISkeletonTableComissionProps> = ({
  ...props
}) => {
  const generateSkeletonRow = (key) => {
    return (
      <>
        <TableRow key={key}>
          {[...Array(props.cols).keys()].map((col) => (
            <TableCell key={col} height={"40px"}>
              <Skeleton variant="rect" width={"90%"} height={"40px"} />
            </TableCell>
          ))}
        </TableRow>
      </>
    );
  };

  return (
    <>
      <Table>
        <tbody>
          {[...Array(props.rows).keys()].map((row) => generateSkeletonRow(row))}
        </tbody>
      </Table>
    </>
  );
};

export default SkeletonTableComission;
