import * as Yup from "yup";

const FormQueryInvoicesValidationSchema = Yup.object().shape({
  CNPJ: Yup.array().of(Yup.string()).required("Selecione um CNPJ"),
  dateRange: Yup.array()
    .of(Yup.date().typeError("Selecione uma data válida"))
    .test(
      "start-date-before-end-date",
      "A data final não pode ser anterior à inicial",
      function (value) {
        if (!value || value.length !== 2) {
          return false;
        }
        const [startDate, endDate] = value;
        return !startDate || !endDate || startDate <= endDate;
      }
    )
    .required("Selecione a data de início e a data final"),
});

export default FormQueryInvoicesValidationSchema;
