import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@material-ui/core";

const TitlePage: React.FC = ({ children }) => {
  const theme = useTheme();
  return (
    <Box>
      <Typography
        sx={{
          fontSize: { xs: 18, sm: 22, md: 22, lg: 26 },
          fontFamily: theme.typography.fontFamily,
          fontWeight: 700,
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default TitlePage;
