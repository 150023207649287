import { inject, injectable } from "inversify";
import {
  DiscountResponse,
  GasStationProduct,
  IDiscountService,
  NewDiscount,
} from "../model/IDiscountService";
import * as IHttpService from "@modules/infra/http/models/IHttpService";
import { Types } from "@ioc/types";

@injectable()
export class DiscountService implements IDiscountService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async listDiscounts(cnpj: string): Promise<GasStationProduct> {
    return await this.httpInstance.get<any>(`/discount/company/${cnpj}`);
  }

  public async createDiscount(
    cnpj: string,
    discount: NewDiscount
  ): Promise<DiscountResponse> {
    return await this.httpInstance.post<any>(
      `/discount/company/${cnpj}`,
      discount
    );
  }
}
