import React, { useEffect } from "react";

import { IconButton, Typography } from "@material-ui/core";
import { Grid } from "@mui/material";

import {
  CircleBlockedIcon,
  CircleCheckDisabledIcon,
  CircleCheckIcon,
  LocatorIcon,
  LocatorIconDisabled,
} from "@components/Icons";
import { statusOrders } from "@utils/MyOrders/ENUM_STATUS_ORDER";
import { useFormQuery } from "./FormQueryContext";
import ModalTrackOrder from "./TrackOrderMap/ModalTrackOrder";
import { financialStatusAcronym } from "./models/ENUM_MY_ORDERS";

interface DetailsOrdersStatusProps {
  children?: React.ReactNode;
  placa?: string;
  tipoFrete?: string;
  status?: statusType;
  latitude: number;
  longitude: number;
  orderNumber?: string;
  productName?: string;
  productID?: string;
  quantity?: number; //Volume do produto
  companyName?: string;
}

export type statusType =
  | statusOrders.BLOQUEADO
  | statusOrders.LIBERADO
  | statusOrders.AGUARDANDO_CARREGAMENTO
  | statusOrders.EM_CARREGAMENTO
  | statusOrders.CARREGAMENTO_FINALIZADO
  | statusOrders.FATURADO
  | statusOrders.DEVOLUCAO
  | statusOrders.CARRAGAMENTO_CANCELADO;

enum statusTitle {
  FINANCEIRO = "Financeiro",
  AGUARDANDO_CAMINHAO = "Aguardando Caminhão",
  AGUARDANDO_CARREGAMENTO = "Aguardando Carregamento",
  EM_CARREGAMENTO = "Em Carregamento",
  CARREGAMENTO_FINALIZADO = "Carregamento Finalizado",
  FATURADO = "Faturado e Carregado",
}

interface OrderTrackingStatus {
  title: string;
  icon: React.ReactNode;
  flag: string;
}

const CircleCheckedIcon: React.FC = () => {
  return <CircleCheckIcon style={{ fontSize: "50px" }} />;
};

const CircleDisableIcon: React.FC = () => {
  return <CircleCheckDisabledIcon style={{ fontSize: "50px" }} />;
};

const CircleBlockIcon: React.FC = () => {
  return <CircleBlockedIcon style={{ fontSize: "50px" }} />;
};

const orderTrackingStatusDefault: OrderTrackingStatus[] = [
  {
    title: statusTitle.FINANCEIRO,
    icon: <CircleCheckedIcon />,
    flag: "FAT",
  },
  {
    title: statusTitle.AGUARDANDO_CARREGAMENTO,
    icon: <CircleBlockIcon />,
    flag: "ACR",
  },
  {
    title: statusTitle.EM_CARREGAMENTO,
    icon: <CircleBlockIcon />,
    flag: "ECA",
  },
  {
    title: statusTitle.CARREGAMENTO_FINALIZADO,
    icon: <CircleBlockIcon />,
    flag: "CF",
  },
  {
    title: statusTitle.FATURADO,
    icon: <CircleDisableIcon />,
    flag: "FAT",
  },
];

const DetailsOrdersStatus: React.FC<DetailsOrdersStatusProps> = (props) => {
  const { status, placa, tipoFrete } = props;
  const formQueryContext = useFormQuery();

  const isDisabledIconMap = props.latitude === null || props.longitude === null;

  const [orderTrackingStatus, setOrderTrackingStatus] = React.useState<
    OrderTrackingStatus[]
  >(orderTrackingStatusDefault);

  useEffect(() => {
    if (!status) return;
    switch (status) {
      case statusOrders.BLOQUEADO:
        const orderTrackingStatusBl = orderTrackingStatus.map((item) => {
          if (item.title === statusTitle.FINANCEIRO) {
            return {
              ...item,
              icon: <CircleBlockIcon />,
              flag: "BL",
            };
          } else {
            return {
              ...item,
              icon: <CircleDisableIcon />,
              flag: "BL",
            };
          }
        });
        setOrderTrackingStatus(orderTrackingStatusBl);
        break;
      case statusOrders.LIBERADO:
        const orderTrackingStatusLB = orderTrackingStatus.map((item) => {
          if (item.title === statusTitle.FINANCEIRO) {
            return {
              ...item,
              icon: <CircleCheckedIcon />,
              flag: "LB",
            };
          } else {
            return {
              ...item,
              icon: <CircleDisableIcon />,
              flag: "LB",
            };
          }
        });
        setOrderTrackingStatus(orderTrackingStatusLB);
        break;
      case statusOrders.AGUARDANDO_CARREGAMENTO:
        const orderTrackingStatusAC = orderTrackingStatus.map((item) => {
          if (
            item.title === statusTitle.FINANCEIRO ||
            item.title === statusTitle.AGUARDANDO_CARREGAMENTO
          ) {
            return {
              ...item,
              icon: <CircleCheckedIcon />,
              flag: "AC",
            };
          } else {
            return {
              ...item,
              icon: <CircleDisableIcon />,
              flag: "AC",
            };
          }
        });
        setOrderTrackingStatus(orderTrackingStatusAC);
        break;
      case statusOrders.EM_CARREGAMENTO:
        const orderTrackingStatusEC = orderTrackingStatus.map((item) => {
          if (
            item.title === statusTitle.FINANCEIRO ||
            item.title === statusTitle.AGUARDANDO_CARREGAMENTO ||
            item.title === statusTitle.EM_CARREGAMENTO
          ) {
            return {
              ...item,
              icon: <CircleCheckedIcon />,
              flag: "EC",
            };
          } else {
            return {
              ...item,
              icon: <CircleDisableIcon />,
              flag: "EC",
            };
          }
        });
        setOrderTrackingStatus(orderTrackingStatusEC);
        break;
      case statusOrders.CARREGAMENTO_FINALIZADO:
        const orderTrackingStatusCF = orderTrackingStatus.map((item) => {
          if (
            item.title === statusTitle.FINANCEIRO ||
            item.title === statusTitle.AGUARDANDO_CARREGAMENTO ||
            item.title === statusTitle.EM_CARREGAMENTO ||
            item.title === statusTitle.CARREGAMENTO_FINALIZADO
          ) {
            return {
              ...item,
              icon: <CircleCheckedIcon />,
              flag: "CF",
            };
          } else {
            return {
              ...item,
              icon: <CircleDisableIcon />,
              flag: "CF",
            };
          }
        });
        setOrderTrackingStatus(orderTrackingStatusCF);
        break;
      case statusOrders.FATURADO:
        const orderTrackingStatusFT = orderTrackingStatus.map((item) => {
          if (
            item.title === statusTitle.FINANCEIRO ||
            item.title === statusTitle.AGUARDANDO_CARREGAMENTO ||
            item.title === statusTitle.EM_CARREGAMENTO ||
            item.title === statusTitle.CARREGAMENTO_FINALIZADO ||
            item.title === statusTitle.FATURADO
          ) {
            return {
              ...item,
              icon: <CircleCheckedIcon />,
              flag: financialStatusAcronym.FT_FATURADO,
            };
          } else {
            return {
              ...item,
              icon: <CircleDisableIcon />,
              flag: financialStatusAcronym.FT_FATURADO,
            };
          }
        });
        setOrderTrackingStatus(orderTrackingStatusFT);
        break;
      case statusOrders.CARRAGAMENTO_CANCELADO:
        const orderTrackingStatusCCA = orderTrackingStatus.map((item) => {
          if (
            item.title === statusTitle.FINANCEIRO ||
            item.title === statusTitle.AGUARDANDO_CARREGAMENTO ||
            item.title === statusTitle.EM_CARREGAMENTO
          ) {
            return {
              ...item,
              icon: <CircleCheckedIcon />,
              flag: "CCA",
            };
          }
          if (item.title === statusTitle.CARREGAMENTO_FINALIZADO) {
            return {
              ...item,
              icon: <CircleBlockIcon />,
              flag: "CCA",
            };
          } else {
            return {
              ...item,
              icon: <CircleDisableIcon />,
              flag: "CCA",
            };
          }
        });
        setOrderTrackingStatus(orderTrackingStatusCCA);
        break;
      case statusOrders.DEVOLUCAO:
        const orderTrackingStatusDEV = orderTrackingStatus.map((item) => {
          if (item.title === statusTitle.FATURADO) {
            return {
              ...item,
              icon: <CircleBlockIcon />,
              flag: "DEV",
            };
          } else {
            return {
              ...item,
              icon: <CircleCheckedIcon />,
              flag: "DEV",
            };
          }
        });
        setOrderTrackingStatus(orderTrackingStatusDEV);
        break;
      default:
        const orderTrackingStatusDF = orderTrackingStatus.map((item) => {
          return {
            ...item,
            icon: <CircleDisableIcon />,
            flag: statusOrders.DEFAULT,
          };
        });
        setOrderTrackingStatus(orderTrackingStatusDF);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <Grid container sx={{ backgroundColor: "#fff", justifyContent: "center" }}>
      <Grid item xs={12}>
        <Grid container justifyContent={"space-between"}>
          <Grid item sx={{ p: 3 }}>
            <Typography variant="body1" style={{ fontWeight: 700 }}>
              Placa
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 400 }}>
              {placa}
            </Typography>
          </Grid>
          <Grid item sx={{ p: 3 }}>
            <Typography variant="body1" style={{ fontWeight: 700 }}>
              Acompanhe seu pedido
            </Typography>
          </Grid>
          <Grid item sx={{ p: 3 }}>
            <Typography variant="body1" style={{ fontWeight: 700 }}>
              Tipo de Frete
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 400 }}>
              {tipoFrete}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={11} sx={{ pb: 5 }}>
        <Grid
          container
          textAlign={"center"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            border: "0px solid blue",
            gap: { xs: 1, sm: 2, md: 2, lg: 4, xl: 5 },
          }}
        >
          {orderTrackingStatus.map((item, index) => {
            return (
              <Grid item key={index} sx={{ border: "0px solid red" }}>
                {item.icon}
                <Typography style={{ paddingTop: 2 }}>{item.title}</Typography>
              </Grid>
            );
          })}
        </Grid>
      </Grid>

      <Grid
        xs={1}
        style={{
          display: "flex",
          alignItems: "end",
          justifyContent: "end",
          marginBottom: "10px",
        }}
      >
        <IconButton
          disabled={!isDisabledIconMap}
          onClick={() => {
            formQueryContext.setOpenModalMap(true);
          }}
        >
          {!isDisabledIconMap ? <LocatorIconDisabled /> : <LocatorIcon />}
        </IconButton>
      </Grid>

      <ModalTrackOrder
        open={formQueryContext.openModalMap}
        productName={props.productName}
        productID={props.productID}
        quantityLiter={props.quantity}
        orderNumber={props.orderNumber}
        companyName={props.companyName}
        latitude={props.latitude}
        longitude={props.longitude}
        closeModal={() => {
          formQueryContext.setOpenModalMap(false);
        }}
      />
    </Grid>
  );
};

export default DetailsOrdersStatus;
