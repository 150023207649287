import React, { useEffect } from "react";

import { CalendarIcon } from "@components/Icons";
import { makeStyles, withStyles } from "@material-ui/core";
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";

interface CalendarPickerProps extends KeyboardDatePickerProps {
  name: string;
  label: string;
  value: string | null;
  minDate?: Date;
}

const CalendarPickerCustom: React.FC<CalendarPickerProps> = ({ ...props }) => {
  const initValue =
    props.value?.length === 0
      ? null
      : props.value !== null
      ? new Date(props.value)
      : null;
  const [
    selectedDate,
    setSelectedDate,
  ] = React.useState<MaterialUiPickersDate | null>(initValue);

  const handleDateChange = (date: MaterialUiPickersDate) => {
    setSelectedDate(date);
    props.onChange(date);
  };

  useEffect(() => {
    if (props.value === "") {
      setSelectedDate(null);
    }
    if (props.value) {
      setSelectedDate(new Date(props.value));
    }
  }, [props.value]);

  const popoverClasses = usePopoverStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR} {...props}>
      <SKeyboardDatePicker
        className={`custom-SKeyboardDatePicker`}
        error={props.error}
        helperText={props.helperText}
        name={props.name}
        label={props.label}
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format="dd/MM/yyyy"
        margin="normal"
        id="date-picker-inline"
        value={selectedDate}
        onChange={handleDateChange}
        onBlur={props.onBlur}
        KeyboardButtonProps={{ "aria-label": "change date" }}
        invalidDateMessage={"Verifique o formato de data inserido"}
        keyboardIcon={<CalendarIcon />}
        minDate={props.minDate}
        PopoverProps={{ classes: popoverClasses }}
        inputProps={{ readOnly: true }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default CalendarPickerCustom;

const usePopoverStyles = makeStyles({
  root: {
    "& .MuiPickersDay-dayDisabled": {
      color: "black",
      opacity: "0.3",
    },
  },
});

const SKeyboardDatePicker = withStyles({
  root: {
    margin: 0,
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0.6rem",
    },
    "& .MuiInput-underline:before": {},
    "& .MuiIconButton-root": {
      border: "0px solid red",
      color: "red",
      padding: "12px",
    },
    "& label": {
      // Controle de estilo antes de digitar
      border: "0px solid red",
      opacity: "0.6",
      fontWeight: 400,
      fontSize: "1.4rem",
      borderRadius: "1rem",
    },
    "& input.Mui-focused": {}, // Controle de estilo durante digitacao
    "& .MuiInputLabel-shrink": {
      // Controle de estilo apos campo digitado
      fontWeight: 900,
      color: "#000",
      fontSize: "1.8rem",
      backgroundColor: "white",
      paddingRight: 6,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      // Controla cor da borda durante Digitacao
      border: "2px solid #FF9900",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      // Fieldset
      border: "1px solid rgba(0,0,0,0.3)",
      borderRadius: "1rem",
      opacity: "0.6",
    },
    "& .MuiOutlinedInput-input": {
      border: "0px solid red",
      padding: "16px 14px",
    },
    /* ATENCAO: A propriedade @media screen Precisa ficar no ultimo bloco para sobreescrever o css anterior */
    "@media screen and (max-width: 600px)": {
      "& .MuiIconButton-root": {
        border: "0px solid blue",
        padding: 0,
      },
      "& label": {
        border: "0px solid blue",
        fontSize: "1.2rem",
      },
      "& .MuiInputLabel-shrink": {
        // Controle de estilo apos campo digitado
        fontWeight: 900,
        color: "#000",
        fontSize: "1.4rem",
        backgroundColor: "white",
        paddingRight: 6,
      },
    },
  },
})(KeyboardDatePicker);
