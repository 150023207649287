import { CheckListIcon } from "@components/Icons";
import ROUTES from "@config/routes";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextFieldProps,
  Theme,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import { DeleteForeverOutlined, KeyboardArrowDown } from "@material-ui/icons";
import { maskCNPJ, maskCNPJFromNewUser } from "@utils/index";
import { Field, useFormikContext } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import ProfileType from "./ENUM_ADD_USER";
import IFormAddUser from "./IFormAddUser";

interface Props {
  loading: boolean;
}

const FormAddUser: React.FC<Props> = ({ loading }) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    touched,
    errors,
    setFieldValue,
    values,
    setFieldTouched,
  } = useFormikContext<IFormAddUser>();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const handleGoBack = (): void => {
    history.push(ROUTES.ADMIN_ROUTES.HOME);
  };

  const handleChange = (fieldName) => (event) => {
    setFieldValue(fieldName, event.target.value);
  };

  const handleBlur = (fieldName) => () => {
    setFieldTouched(fieldName, true);
  };

  return (
    <Grid
      className={classes.gridContainer}
      container
      spacing={2}
      style={{
        padding: isMobile ? "1.6rem" : "",
        margin: isMobile ? "0.5rem" : "",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        xs={12}
      >
        <Typography
          className={classes.titleDataUser}
          style={{ fontSize: isMobile ? "1.6rem" : "" }}
        >
          Dados do novo usuário
        </Typography>
        <CheckListIcon style={{ height: "2rem" }} />
      </Grid>

      <Grid container spacing={2}>
        <Grid
          xs={12}
          md={4}
          lg={4}
          item
          style={{
            marginTop: isMobile ? "1.5rem" : "3rem",
            display: "flex",
            justifyContent: "top",
            alignItems: "flex-start",
          }}
        >
          <STextField
            id="standard-input"
            name="firstName"
            label="Primeiro nome"
            variant="outlined"
            type="input"
            value={values.firstName}
            onChange={handleChange("firstName")}
            onBlur={handleBlur("firstName")}
            error={touched.firstName && !!errors.firstName}
            helperText={touched.firstName && errors.firstName}
          />
        </Grid>
        <Grid
          xs={12}
          md={4}
          lg={4}
          item
          style={{
            marginTop: isMobile ? "" : "3rem",
            display: "flex",
            justifyContent: "top",
            alignItems: "flex-start",
          }}
        >
          <STextField
            id="standard-input"
            name="lastName"
            label="Sobrenome"
            variant="outlined"
            type="input"
            value={values.lastName}
            onChange={handleChange("lastName")}
            onBlur={handleBlur("lastName")}
            error={touched.lastName && !!errors.lastName}
            helperText={touched.lastName && errors.lastName}
          />
        </Grid>

        <Grid xs={12} md={8} lg={8} item>
          <STextField
            id="standard-input"
            name="email"
            label="E-mail"
            variant="outlined"
            type="input"
            value={values.email}
            onChange={handleChange("email")}
            onBlur={handleBlur("email")}
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
          />
        </Grid>
        <Grid xs={12} md={8} lg={8} item>
          <FormControl fullWidth variant="outlined">
            <InputLabel
              htmlFor="profile"
              className={classes.inputLabel}
              style={{ fontWeight: !!touched.profile ? "bold" : "none" }}
            >
              Tipo de usuário
            </InputLabel>

            <Field
              name="profile"
              label="Perfil"
              as={Select}
              fullWidth
              error={touched.profile && !!errors.profile}
              helperText={touched.profile && errors.profile}
              IconComponent={KeyboardArrowDown}
              className={classes.Field}
            >
              <MenuItem value={ProfileType.CUSTOMER}>Cliente</MenuItem>
              <MenuItem value={ProfileType.DRIVER}>Motorista</MenuItem>
            </Field>
            {touched.profile && errors.profile && (
              <Typography variant="caption" color="error">
                {errors.profile}
              </Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          marginTop: isMobile ? "2.2rem" : "4rem",
          display: "flex",
          alignItems: "center",
          zIndex: 2,
        }}
      >
        <Box
          style={{
            background: "#fff",
            marginBottom: "-13px",
            marginLeft: "10px",
            padding: "3px",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              color: "#3d3d3d",
              fontSize: isMobile ? "1.3rem" : "1.5rem",
            }}
          >
            {isMobile
              ? "Infome pelo menos um CNPJ"
              : "Infome pelo menos um CNPJ para criar o usuário"}
          </Typography>
        </Box>
      </Grid>

      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          border: "1px dashed  #e3e1e1",
          borderRadius: "8px",
          padding: "20px",
          paddingTop: "40px",
          borderSpacing: "10px",
        }}
        item
        xs={12}
        md={8}
        lg={8}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <STextField
              id="standard-input"
              name="CNPJ"
              variant="outlined"
              label="CNPJ"
              type="input"
              value={values.CNPJ}
              onChange={({ target }) => {
                const notMasked = target.value.replace(/\D/g, "");
                const masked = maskCNPJFromNewUser(notMasked);
                setFieldValue("CNPJ", masked);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <STextField
              id="standard-input"
              name="companyName"
              variant="outlined"
              label="Razão Social"
              type="input"
              value={values.companyName}
              onChange={handleChange("companyName")}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{ display: "flex", justifyContent: "end" }}
          >
            <Button
              onClick={() => {
                if (!values.CNPJ || !values.companyName) return;

                const notMasked = values.CNPJ.replace(/\D/g, "");
                if (notMasked.length < 14) return;

                const data = {
                  CNPJ: notMasked,
                  companyName: values.companyName,
                };

                const newCNPJ = [...values.attributes.CNPJ, data];
                setFieldValue("attributes.CNPJ", newCNPJ);
                setFieldValue("CNPJ", "");
                setFieldValue("companyName", "");
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "#D91F05",
                  textTransform: "none",
                  fontSize: isMobile ? "1.4rem" : "1.5rem",
                }}
              >
                + Adicionar CNPJ
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {values.attributes.CNPJ.length > 0 && (
        <Grid container style={{ marginTop: "4rem" }}>
          <Grid item xs={12} md={6} lg={8} className={classes.tableCompany}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">CNPJ</TableCell>
                  <TableCell align="center">Razão Social</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.attributes.CNPJ.map((dataCNPJ, idx) => {
                  return (
                    <TableRow key={dataCNPJ.CNPJ}>
                      <TableCell align="center">
                        {maskCNPJ(dataCNPJ.CNPJ)}
                      </TableCell>
                      <TableCell align="center">
                        {dataCNPJ.companyName}
                      </TableCell>
                      <TableCell>
                        <Grid container justify="center">
                          <IconButton
                            onClick={() => {
                              const newCNPJ = [...values.attributes.CNPJ];
                              newCNPJ.splice(idx, 1);
                              setFieldValue("attributes.CNPJ", newCNPJ);
                            }}
                          >
                            <Tooltip title="Remover CNPJ">
                              <DeleteForeverOutlined />
                            </Tooltip>
                          </IconButton>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Typography align="center" color="error">
              {errors.attributes?.CNPJ}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid
        container
        style={{
          marginTop: isMobile ? "2rem" : "5rem",
          display: "flex",
        }}
        spacing={2}
      >
        <Grid
          item
          xs={6}
          md={10}
          lg={10}
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            className={classes.button}
            onClick={handleGoBack}
            variant="text"
            color="primary"
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            type="submit"
            disabled={values.attributes.CNPJ.length === 0}
            endIcon={
              loading && (
                <CircularProgress
                  color="secondary"
                  style={{ width: "2rem", height: "2rem" }}
                />
              )
            }
          >
            {loading ? "Finalizando..." : "Finalizar"}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormAddUser;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleDataUser: {
      fontFamily: "DM Sans",
      fontWeight: 700,
      fontSize: "20px",
      color: "#3E3D3D",
    },
    gridContainer: {
      backgroundColor: "#fff",
      padding: "3rem",
      margin: "0 0 10px 3rem",
      borderRadius: "4px",
      width: "95%",
    },
    button: {
      textTransform: "capitalize",
      boxShadow: "none",
    },
    listItem: {
      display: "flex",
      flexDirection: "column",
      marginTop: "1.5rem",
    },
    secondaryItem: {
      fontSize: "1.3rem",
      color: "#CCCCCC",
    },
    lastName: {
      marginLeft: ".7rem",

      "@media screen and (max-width: 768px)": {
        marginLeft: 0,
      },
    },
    tableCompany: {
      border: "1px solid #72655133",
      borderRadius: "4px",
    },
    Field: {
      borderRadius: "8px",
    },
    textfield: {
      width: "100%",
      fontFamily: "Montserrat",
      "& label": {
        color: "#3E3D3D",
        fontWeight: 400,
        fontSize: "1.6rem",
        borderRadius: "8px",
        opacity: "0.6",
      },
      "& .MuiInputLabel-shrink": {
        fontSize: "1.6rem",
        fontWeight: 700,
        color: "#3E3D3D",
        opacity: 1,
        backgroundColor: "#fff",
        paddingRight: 6,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        opacity: "0.6",
      },
    },
    inputLabel: {
      position: "absolute",
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "1.6rem",
      backgroundColor: "#fff",
      paddingLeft: "0.2rem",
      paddingRight: "0.8rem",
      marginLeft: "0rem",
      width: "auto",
    },
  })
);

const STextField = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "& label": {
        fontFamily: "montserrat",
        fontWeight: 400,
        fontSize: "1.4rem",
        borderRadius: "1rem",
      },
      "& .MuiInputLabel-shrink": {
        fontWeight: 900,
        color: "#3d3d3d",
        fontSize: "1.8rem",
        backgroundColor: "white",
        paddingRight: 6,
      },

      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "1rem",
      },
    },
  })
)((props: TextFieldProps) => <TextField {...props} />);
