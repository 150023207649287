import React, { useState } from "react";
import { Form, Formik } from "formik";
import { CircularProgress, Grid } from "@mui/material";

import TextFieldSelect from "@pages/Admin/Nozzle/resource/field/TextFieldSelect";
import { useNozzleAdmin } from "@pages/Admin/Nozzle/context/NozzleAdminContext";
import { INozzleCompanyModel, STATUSNOZZLE } from "@pages/Admin/Nozzle/model";

import TextFieldMultiSelect from "@components/TextFieldMultiSelect";
import ButtonDefault from "@components/ButtonDefault";
import CalendarRange from "@components/CalendarRange";

interface FormikFilterProps {
  children?: React.ReactNode;
}

interface IFilter {
  cnpj: { value: string; label: string }[];
  status: string;
  registrationDate: [null, null];
}

const FormikFilter: React.FC<FormikFilterProps> = () => {
  const {
    nozzleAdminCompany,
    handleFetchByFilterNozzlesAdmin,
  } = useNozzleAdmin();

  const [loadFilter, setLoadFilter] = useState(false);

  const adminNozzleTooptionListCNPJ = (nozzleAdmin: INozzleCompanyModel[]) => {
    const filteredOptions = nozzleAdmin.filter(
      (nozzle) => nozzle.fantasy_name !== "None"
    );

    const options = filteredOptions.map((nozzle) => ({
      value: nozzle.document,
      label: nozzle.fantasy_name,
    }));

    // Encontrar labels duplicadas
    const labelCounts: Record<string, number> = {};
    options.forEach((option) => {
      labelCounts[option.label] = (labelCounts[option.label] || 0) + 1;
    });

    // Adicionar índice nas labels duplicadas
    const indexedOptions = options.map((option) => {
      if (labelCounts[option.label] > 1) {
        const index = labelCounts[option.label]--;
        return {
          value: option.value,
          label: `${option.label} (${index})`,
        };
      } else {
        return option;
      }
    });

    indexedOptions.sort((a, b) => a.label.localeCompare(b.label)); // ordena em ordem alfabetica
    return indexedOptions;
  };

  return (
    <Formik
      initialValues={
        {
          cnpj: [],
          status: "",
          registrationDate: [null, null],
        } as IFilter
      }
      onSubmit={async (values, action) => {
        setLoadFilter(true);

        const payload = {
          cnpj: values.cnpj.map((cnpj) => cnpj.value), // lista de CNPJ selecionados pelo usuario
          status: values.status,
          registrationDate: values.registrationDate,
        };

        await handleFetchByFilterNozzlesAdmin(payload);

        action.resetForm();
        setLoadFilter(false);
      }}
    >
      {(formik) => {
        const handleRegistrationDate = (e) => {
          formik.setFieldValue("registrationDate", e);
        };
        return (
          <Form>
            <Grid
              container
              sx={{
                p: { xs: 1, lg: 2 },
                gap: { xs: 3, md: 4, lg: 3 },
                textAlign: "center",
              }}
            >
              <Grid item xs={12}>
                <TextFieldMultiSelect
                  index="cnpj"
                  label="Razao social"
                  optionsSelected={formik.values.cnpj}
                  options={adminNozzleTooptionListCNPJ(
                    nozzleAdminCompany ?? []
                  )}
                  onChange={(e) => formik.setFieldValue("cnpj", e)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldSelect
                  fullWidth
                  id="status"
                  name="status"
                  label="Status"
                  options={[
                    {
                      value: STATUSNOZZLE.WAITING,
                      label: "Aguardando validação",
                    },
                    { value: STATUSNOZZLE.APPROVED, label: "Concluido" },
                    { value: STATUSNOZZLE.BLOCK, label: "Recusado" },
                  ]}
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.status && Boolean(formik.errors.status)}
                  helperText={formik.touched.status && formik.errors.status}
                />
              </Grid>

              <Grid item xs={12}>
                <CalendarRange
                  name="registrationDate"
                  label="Data de Cadastro"
                  value={[
                    formik.values.registrationDate[0],
                    formik.values.registrationDate[1],
                  ]}
                  onChange={handleRegistrationDate}
                  onBlur={formik.handleBlur}
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 5 }}>
                <Grid container sx={{ justifyContent: "center", gap: 3 }}>
                  <Grid item>
                    <ButtonDefault
                      variant="text"
                      onClick={() => formik.resetForm()}
                    >
                      Limpar filtro
                    </ButtonDefault>
                  </Grid>
                  <Grid item>
                    {loadFilter ? (
                      <CircularProgress />
                    ) : (
                      <ButtonDefault type="submit">Filtrar</ButtonDefault>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormikFilter;
