import React from "react";
import { Grid, CircularProgress } from "@mui/material";

import AllCommunicated from "@pages/Admin/Communications/view/AllCommunicated";
import AddCommunicated from "@pages/Admin/Communications/view/AddCommunicated";
import { useCommunication } from "@pages/Admin/Communications/context";

const ViewCommunicated: React.FC = () => {
  const { showAddCommunicatedPage, loadingCommunication } = useCommunication();

  const styleAlignCenter = {
    height: "50vh",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div>
      {loadingCommunication ? (
        <Grid container sx={styleAlignCenter}>
          <CircularProgress />
        </Grid>
      ) : showAddCommunicatedPage ? (
        <AddCommunicated></AddCommunicated>
      ) : (
        <AllCommunicated></AllCommunicated>
      )}
    </div>
  );
};

export default ViewCommunicated;
