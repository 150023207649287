import React, { useState } from "react";

import {
  Grid,
  MenuItem,
  OutlinedTextFieldProps,
  TextField,
  Typography,
} from "@mui/material";

import { useTheme } from "@material-ui/core";

import { ArrowDownIconPlus } from "@components/Icons";
import { formatAllFistLetterCapitalized, maskCNPJ } from "@utils/index";

export interface OptionsSelectTextField {
  key: string;
  value: string;
}

interface Props extends Omit<OutlinedTextFieldProps, "variant"> {
  id?: string;
  children?: React.ReactNode;
  label?: string;
  name?: string;
  value: string;
  placeholder?: string;
  optionslist?: OptionsSelectTextField[];
  error?: boolean;
  showkey?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<any>) => void;
  itsWithoutCNPJ?: boolean;
}

const TextFieldSelect: React.FC<Props> = ({ ...props }) => {
  const theme = useTheme();

  const [showOptions, setShowOptions] = useState(false); // Estado para controlar a exibição das opções
  const [isIconClicked, setIsIconClicked] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleBlurCustom = (event) => {
    if (!event.target.value) {
      setHasError(true);
    }
    if (props.value && props.value.length > 0) {
      setHasError(false);
    }
    props.onBlur && props.onBlur(event);
  };

  const CustomIcon = () => {
    const handleOnClickCustomIcon = (e) => {
      if (props.disabled) return;
      setShowOptions(!showOptions); // Inverte o valor do estado quando o ícone é clicado
      setIsIconClicked(true);
    };

    return (
      <ArrowDownIconPlus
        display={"none"}
        onClick={(e) => {
          handleOnClickCustomIcon(e);
        }}
        onBlur={(e) => {
          handleBlurCustom(e);
        }}
        sx={{
          border: "0px solid red",
          zIndex: 1,
          cursor: "pointer",
          fontSize: { xs: "1rem", sm: "1.2rem" },
          marginRight: { xs: "1.4rem", sm: "1.4rem", md: "1.4rem", lg: "2rem" },
          marginTop: { xs: "0.4rem", sm: "0rem", md: "0rem", lg: "0rem" },
          opacity: props.disabled ? 0.5 : 1,
        }}
      />
    );
  };

  const handleOnClickTextField = (event) => {
    if (isIconClicked) {
      setShowOptions(!showOptions); // Inverte o valor do estado quando o TextField é clicado
      setIsIconClicked(false);
    }
  };

  const handleOnChangeTextField = (event) => {
    const index = props?.optionslist?.findIndex(
      (option) => option.value === event.target.value
    );
    event.target.name = index;
    props.onChange && props.onChange(event);
  };

  const renderValueCustom = (value) => {
    if (props.showkey) {
      return (
        <Grid
          container
          justifyContent={"space-between"}
          sx={{ border: "0px solid red", alignItems: "baseline" }}
        >
          <Grid item xs={7.5}>
            <Typography
              sx={{
                border: "0px solid black",
                fontFamily: "montserrat",
                opacity: 1,
                color: "#3E3D3D",
                fontWeight: 500,
                fontSize: "1.4rem",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {formatAllFistLetterCapitalized(value as string)}
            </Typography>
          </Grid>
          <Grid item xs={4.3}>
            <Typography
              sx={{
                border: "0px solid red",
                fontFamily: "montserrat",
                color: "#3E3D3D",
                opacity: "0.5",
                textAlign: "right",
              }}
            >
              {maskCNPJ(
                props.optionslist?.find((option) => option.value === value)
                  ?.key!
              )}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return <div>{formatAllFistLetterCapitalized(value)}</div>;
  };

  return (
    <TextField
      select
      id={props.id}
      label={props.label}
      value={props.value}
      onChange={(e) => handleOnChangeTextField(e)}
      onBlur={(e) => {
        handleBlurCustom(e);
      }}
      onClick={(e) => {
        handleOnClickTextField(e);
      }}
      variant="outlined"
      SelectProps={{
        IconComponent: CustomIcon,
        open: showOptions, // Define o estado para a propriedade 'open' do TextField
        onClose: () => setShowOptions(false), // Fecha as opções quando o TextField perde o foco
        onOpen: () => setShowOptions(true), // Abre as opções quando o TextField recebe o foco
        renderValue: (value) => renderValueCustom(value),
        ...props.SelectProps,
      }}
      InputLabelProps={{
        htmlFor: props.id,
        id: props.id,
      }}
      sx={{
        ...props.sx,
        width: "100%",
        "& label": {
          // Controle de estilo antes de digitar
          border: "0px solid red",
          color: "#3E3D3D",
          fontFamily: "montserrat",
          fontWeight: 500,
          fontSize: "1.4rem",
          borderRadius: "1rem",
          opacity: "0.5",
          top: "-2px",
        },
        "& .MuiInputBase-input": {
          border: "0px solid red",
          fontFamily: "montserrat",
          fontWeight: 500,
          fontSize: { xs: "1.1rem", sm: "1.1rem", md: "1.3rem", lg: "1.4rem" },
          textAlign: "left",
          color: "#3E3D3D",
          paddingTop: "18px",
          paddingBottom: "12px",
        },
        "& .MuiInputBase-input:focus": {
          fontFamily: "montserrat",
          fontWeight: 500,
          fontSize: "1.4rem",
          textAlign: "left",
          color: "#3E3D3D",
          paddingTop: "1.8rem",
          paddingBottom: "1rem",
        },
        "& input.Mui-focused": {}, // Controle de estilo durante digitacao
        "& .MuiInputLabel-shrink": {
          // Controle de estilo apos campo digitado
          border: "0px solid red",
          fontWeight: 700,
          color: "#3E3D3D !important",
          fontSize: { xs: "1.3rem", sm: "1.3rem", md: "1.4rem", lg: "1.8rem" },
          backgroundColor: "white",
          paddingRight: "8px",
          opacity: 1,
          zIndex: 3,
          transform: `translate(12px, -2px) scale(0.75)`,
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
          // Controla cor da borda durante Digitacao
          borderColor: "#FF9900 !important",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border:
            props.error || hasError
              ? `1px solid ${theme.palette.error.light} !important`
              : `1px solid rgba(0,0,0,0,0.9) !important`, // Sinalizacao de campo incorreto -> "#F97A91"
          borderRadius: "1rem",
          opacity: "0.7",
          zIndex: 2,
        },
        "& .MuiSelect-select": {
          backgroundColor: "initial",
        },
        "& .MuiSelect-select:focus": {
          backgroundColor: "initial",
        },
        "& .MuiSelect-icon": {
          color: "transparent", // torna o ícone padrão transparente
        },
        "& .MuiInputLabel-root.Mui-error": {
          color: theme.palette.error.light,
        },
        "& .MuiFormHelperText-root.Mui-error.Mui-focused": {
          // Esconde a mensagem de erro enquanto usuario estiver digitando
          display: "none",
        },
        "& .MuiFormHelperText-root": {
          // Esconde a mensagem de erro enquanto usuario estiver digitando
          display: "none",
        },
        "& .MuiFormHelperText-root.Mui-error": {
          display: "block",
          fontSize: 12,
          color: theme.palette.error.light, // "#F97A91"
          opacity: 0.7,
        },
      }}
    >
      {props.optionslist?.map((option: OptionsSelectTextField, index) => (
        <MenuItem
          key={option.key}
          value={option.value}
          sx={{
            display: "block",
            fontFamily: "montserrat",
            fontSize: "1.4rem",
            margin: "1.2rem",
            marginBottom: "1.5rem",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontFamily: "montserrat",
                  fontSize: "1.4rem",
                  color: "#3E3D3D",
                  opacity: 1,
                }}
              >
                {formatAllFistLetterCapitalized(option.value)}
              </Typography>
            </Grid>
            {!props.itsWithoutCNPJ && props.showkey && (
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontFamily: "montserrat",
                    fontSize: "1rem",
                    color: "#3E3D3D",
                    opacity: 0.5,
                  }}
                >
                  {maskCNPJ(option.key)}
                </Typography>
              </Grid>
            )}
          </Grid>
        </MenuItem>
      ))}
    </TextField>
  );
};

export default TextFieldSelect;
