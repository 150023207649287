import React, { createContext, useCallback, useContext, useState } from "react";
import { IQueryOrderDataDTO } from "../../../modules/orders/dtos/IQueryOrderDataDTO";
import { IClient } from "../../../utils/interfaces";

interface FormQueryContext {
  loading: boolean;
  loadingQuery: boolean;
  dataCustomer: IClient | null;
  dataQuery: IQueryOrderDataDTO[];
  searchFirstTime: boolean;
  setLoading(loading: boolean): void;
  setLoadingQuery(loading: boolean): void;
  setDataCustomer(data: IClient): void;
  setDataQuery(data: IQueryOrderDataDTO[]): void;
  setSearchFirstTime(value: boolean): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FormQueryContext = createContext<FormQueryContext>(
  {} as FormQueryContext
);

const FormQueryProviderTemporary: React.FC = ({ children }) => {
  const [loading, setLoadingState] = useState(false);
  const [loadingQuery, setLoadingQueryState] = useState(false);
  const [dataCustomer, setDataCustomer] = useState<IClient | null>(null);
  const [dataQuery, setDataQuery] = useState<IQueryOrderDataDTO[]>([]);
  const [searchFirstTime, setSearchFirstTime] = useState(true);

  const setLoading = useCallback((loading: boolean) => {
    setLoadingState(loading);
  }, []);

  const setLoadingQuery = useCallback((loading: boolean) => {
    setLoadingQueryState(loading);
  }, []);

  return (
    <FormQueryContext.Provider
      value={{
        loading,
        dataCustomer,
        loadingQuery,
        dataQuery,
        searchFirstTime,
        setLoading,
        setLoadingQuery,
        setDataCustomer,
        setDataQuery,
        setSearchFirstTime,
      }}
    >
      {children}
    </FormQueryContext.Provider>
  );
};

const useFormQuery = (): FormQueryContext => {
  const context = useContext(FormQueryContext);
  if (!Object.values(context).length) {
    throw new Error(
      "useFormQuery deve ser utilizado dentro de um FormQueryProviderTemporary"
    );
  }
  return context;
};

export { useFormQuery, FormQueryProviderTemporary };
