import React from "react";
import RequestValidate from "@pages/User/NewRequest/resources/form/FormRequestValidate";

const PageControler: React.FC = () => {
  return (
    <div className="PageControler" style={{ overflowX: "hidden" }}>
      <RequestValidate />
    </div>
  );
};

export default PageControler;
