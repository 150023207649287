export const monthsBR = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

export const daysBR = ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SÁB"];

export const ptbr: Locale = {
  localize: {
    ordinalNumber: (...args: Array<any>) => "",
    era: (...args: Array<any>) => "",
    quarter: (...args: Array<any>) => "",
    day: (n: any) => daysBR[n],
    month: (n: any) => monthsBR[n],
    dayPeriod: (...args: Array<any>) => "",
  },
  formatLong: {
    date: () => "dd/mm/yyyy",
    time: (...args: Array<any>) => "",
    dateTime: (...args: Array<any>) => "",
  },
};
