import { createStyles, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    image: {
      objectFit: "contain",
      width: "100%",
      height: "50rem",
    },
  })
);

interface Props {
  link: string;
}

const ImagePaper: React.FC<Props> = ({ link }) => {
  const classes = useStyles();
  return <img className={classes.image} src={link} alt="" />;
};

export default ImagePaper;
