import React from "react";
import { Container, Table, TableRow, TableCell, Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

interface ISkeletonTableProps {
  rows?: number;
  cols?: number;
}

const SkeletonTable: React.FC<ISkeletonTableProps> = ({ ...props }) => {
  const rows = 11;
  const cols = 7;

  const generateSkeletonRow = (key) => {
    return (
      <>
        <TableRow key={key}>
          {[...Array(cols).keys()].map((col) => (
            <TableCell key={col} height={"40px"}>
              <Skeleton variant="rect" width={"141px"} height={"40px"} />
            </TableCell>
          ))}
        </TableRow>
      </>
    );
  };

  return (
    <Container>
      <Grid container style={{ padding: "16px" }}>
        <Grid item xs={3}>
          <Skeleton variant="rect" width={"70%"} height={"40px"}></Skeleton>
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            display: "flex",
            justifyContent: "start",
          }}
        >
          <Skeleton variant="rect" width={"70%"} height={"40px"}></Skeleton>
        </Grid>
        <Grid item xs={6} style={{ display: "flex", justifyContent: "end" }}>
          <Skeleton variant="rect" width={"50%"} height={"40px"}></Skeleton>
        </Grid>
      </Grid>

      <Table>
        <tbody>
          {[...Array(rows).keys()].map((row) => generateSkeletonRow(row))}
        </tbody>
      </Table>
    </Container>
  );
};

export default SkeletonTable;
