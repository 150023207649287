import React from "react";

import { EmployeeProvider } from "@pages/User/Employee/context";
import EmployeeView from "@pages/User/Employee/view";

import Layout from "@components/Layout";

const Employee: React.FC = () => {
  return (
    <Layout disableFooter>
      <EmployeeProvider>
        <EmployeeView />
      </EmployeeProvider>
    </Layout>
  );
};

export default Employee;
