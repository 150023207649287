import moment from "moment";

export function downloadThisWindow(data: any, type: string, query?: any) {
  let fileName = "";
  let file: Blob = new Blob();

  switch (type) {
    case "pdf":
      fileName = `${query.codes[0]}.pdf`;
      file = new Blob([data], { type: "application/pdf" });
      break;
    case "xml":
      fileName = `${query.codes[0]}.xml`;
      file = new Blob([data], { type: "text/xml" });
      break;
    default:
      break;
  }

  const fileURL = URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = fileURL;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(fileURL);
}

export function DownloadPDF(fileURL: string, nameFile?: string) {
  const dateBase = new Date();
  const formattedDate = moment(dateBase).format("DD/MM/YYYY-HH:mm");
  const fileName = nameFile
    ? `${nameFile}-${formattedDate}`
    : `relatorio-${formattedDate}`;

  const link = document.createElement("a");
  link.href = fileURL;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(fileURL);
}
