import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IGetTaxReceiptWithActionsDTO } from "../dtos/IGetTaxReceiptWithActionsDTO";
import { IGetTotalAverageTicketService } from "../models/IGetTotalAverageTicketService";

@injectable()
export class GetTotalAverageTicketService
  implements IGetTotalAverageTicketService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  execute(
    data: IGetTaxReceiptWithActionsDTO
  ): Promise<{ total: number; quantity: number; taxReceipts: number }> {
    return this.httpInstance.get<{
      total: number;
      quantity: number;
      taxReceipts: number;
    }>(
      `/dashboard-posh/taxReceipt?action=${data.action}&companies_id=${data.companies_id}&from=${data.from}&to=${data.to}`
    );
  }
}
