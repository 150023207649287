import React from "react";
import ContentLoader from "react-content-loader";

const SkeletonBarChart = () => {
  const loaders = Array.from({ length: 6 }, (_, index) => (
    <ContentLoader
      key={index}
      width={200}
      height={200}
      viewBox="0 0 200 200"
      backgroundColor="#bfbebe"
      foregroundColor="#ffd8d8"
    >
      <rect x="0" y="160" rx="0" ry="0" width="25" height="40" />
      <rect x="30" y="145" rx="0" ry="0" width="25" height="55" />
      <rect x="60" y="126" rx="0" ry="0" width="25" height="74" />
      <rect x="90" y="80" rx="0" ry="0" width="25" height="120" />
      <rect x="120" y="142" rx="0" ry="0" width="25" height="58" />
    </ContentLoader>
  ));

  return <>{loaders}</>;
};

SkeletonBarChart.metadata = {
  name: "Phuong Dao",
  github: "dao-phuong",
  description: "Bar Chart",
  filename: "SkeletonBarChart",
};

export default SkeletonBarChart;
