import {
  Button,
  ButtonProps,
  Theme,
  Typography,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";

interface ButtomBorderlessProps extends ButtonProps {
  label?: string;
}

const ButtomBorderless: React.FC<ButtomBorderlessProps> = (props) => {
  return (
    <SButtomBorderless {...props} onClick={props.onClick}>
      <Typography variant={"subtitle2"} style={{ fontWeight: 600 }}>
        {props.label}
      </Typography>
    </SButtomBorderless>
  );
};

const SButtomBorderless = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.primary.main,
      textTransform: "initial",
      padding: 0,
    },
  })
)((props: ButtomBorderlessProps) => <Button {...props} />);

export default ButtomBorderless;
