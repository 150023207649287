import React from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from "react-router-dom";

import ROUTES from "../../config/routes";
import { useUserState } from "../../context/UserContext";
import { TypesProfile } from "@context/auth/enum";

interface Props extends RouteProps {
  component: React.ComponentType<RouteComponentProps>;
}

const ProfileRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  const userState = useUserState();

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        const isPathAdmin = routeProps.location.pathname
          .split("/")
          .find(
            (path) =>
              path === TypesProfile.ADMIN ||
              path === TypesProfile.ADMIN_PORTAL_CLIENTE
          );
        const isPathUser = routeProps.location.pathname
          .split("/")
          .find((path) => path === "user");

        if (isPathAdmin && userState.adminMode) {
          return <Component {...routeProps} />;
        }

        if (isPathUser) {
          return <Component {...routeProps} />;
        } else {
          return <Redirect path="*" to={ROUTES.ADMIN_ROUTES.HOME} />;
        }
      }}
    />
  );
};

export default ProfileRoute;
