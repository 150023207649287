import TableReclamation from "@components/TableReclamation/TableReclamation";
import React from "react";
import { TableHeaderReclamation } from "../models/TableHeaderReclamation";
import ROUTES from "@config/routes";
import { IFilterAllReclamationData } from "@modules/reclamation/dtos/IFilterAllReclamationData";

interface ITableReclamationAllProps {
  queryDataReclamationUser?: IFilterAllReclamationData;
  status: string;
}
const TableReclamationAll: React.FC<ITableReclamationAllProps> = ({
  ...props
}) => {
  return (
    <TableReclamation
      tableHeaderColumn={TableHeaderReclamation}
      tableData={props.queryDataReclamationUser}
      positionStatusTag={6}
      positionButtonSeeDetails={6}
      positionID={5}
      hasButtonSeeDetails={true}
      linkRouteReclamation={ROUTES.USER_ROUTES.RECLAMATION_DETAILS}
      isListAdmin={false}
    />
  );
};

export default TableReclamationAll;
