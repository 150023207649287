export const listSegmentsDescription = {
  "01": " ATEM",
  "02": "Consumidor Final",
  "04": "Congenere",
  "06": "POSBB",
  "10": "TRR",
  "11": "TRRNI",
};

export const initiaSegments = [
  {
    id: "",
    desc: "",
  },
];

enum ActiveSegmentsIds {
  ATEM = "01",
  COF_CONS_FINAL = "02",
  COG_CONGENERE = "04",
  POSBB_POST_BAND_BRAN = "06",
  TRR = "10",
  TRRNI = "11",
}
export const listActiveSegmentsIds: ActiveSegmentsIds[] = [
  ActiveSegmentsIds.ATEM,
  ActiveSegmentsIds.COF_CONS_FINAL,
  ActiveSegmentsIds.COG_CONGENERE,
  ActiveSegmentsIds.POSBB_POST_BAND_BRAN,
  ActiveSegmentsIds.TRR,
  ActiveSegmentsIds.TRRNI,
];
