import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IGetTitleImageSeenOrNotDTO } from "../dtos/IGetTitleImageSeenOrNotDTO";
import { IQueryTitlesDataDTO } from "../dtos/IQueryTitlesDataDTO";
import { IGetTitleImagesSeenOrNotService } from "../models/IGetTitleImagesSeenOrNotService";

@injectable()
export class GetTitleImagesSeenOrNotService
  implements IGetTitleImagesSeenOrNotService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: IGetTitleImageSeenOrNotDTO) {
    const titles = await this.httpInstance.get<{
      content: IQueryTitlesDataDTO[];
      count: number;
    }>("/titles", {
      params: {
        action: "titles-images-seen-or-not",
        userID: data.userID,
        limit: data.limit,
        offset: data.offset,
      },
    });
    return titles;
  }
}
