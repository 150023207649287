import { IGetTitleImagesSeenOrNotDataDTO } from "@modules/titles/dtos/IGetTitleImagesSeenOrNotDataDTO";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

interface Image {
  link: string;
  id: string;
  comments?: string;
}

interface ListInvoicesImagesContext {
  loading: boolean;
  openModalImage: boolean;
  image: Image;
  listTitle: IGetTitleImagesSeenOrNotDataDTO | null;
  numberOfItemsInList: number;
  page: number;
  setLoading(loading: boolean): void;
  setOpenModalImage(value: boolean): void;
  setImage(value: Image): void;
  setListTitle(data: IGetTitleImagesSeenOrNotDataDTO): void;
  setPage(value: number): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const ListInvoicesImagesContext = createContext<ListInvoicesImagesContext>(
  {} as ListInvoicesImagesContext
);

const ListInvoicesImagesProvider: React.FC = ({ children }) => {
  const [loading, setLoadingState] = useState(false);
  const [image, setImage] = useState<Image>({} as Image);
  const [openModalImage, setOpenModalImage] = useState(false);
  const [
    listTitle,
    setListTitle,
  ] = useState<IGetTitleImagesSeenOrNotDataDTO | null>(null);
  const [page, setPage] = useState(1);

  const setLoading = useCallback((loading: boolean) => {
    setLoadingState(loading);
  }, []);

  const numberOfItemsInList = useMemo(() => {
    return 10;
  }, []);

  return (
    <ListInvoicesImagesContext.Provider
      value={{
        loading,
        openModalImage,
        image,
        listTitle,
        numberOfItemsInList,
        page,
        setLoading,
        setOpenModalImage,
        setImage,
        setListTitle,
        setPage,
      }}
    >
      {children}
    </ListInvoicesImagesContext.Provider>
  );
};

const useListInvoicesImages = (): ListInvoicesImagesContext => {
  const context = useContext(ListInvoicesImagesContext);
  if (!Object.values(context).length) {
    throw new Error(
      "useListInvoicesImages deve ser utilizado dentro de um ListInvoicesImagesProvider"
    );
  }
  return context;
};

export { useListInvoicesImages, ListInvoicesImagesProvider };
