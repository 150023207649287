import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Grid } from "@material-ui/core";

function SampleArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        backgroundColor: "#B0C1E1",
        borderRadius: "60%",
      }}
      onClick={onClick}
    />
  );
}

export const MultipleItems: React.FC = ({ children }) => {
  const sliderRef = React.useRef<Slider | null>(null);

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleArrow className="slick-next" onClick={nextSlide} />,
    prevArrow: <SampleArrow className="slick-prev" onClick={prevSlide} />,
    responsive: [
      {
        breakpoint: 1429,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2.8,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 830,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Grid
        item
        xs={12}
        container
        style={{ display: "block", position: "relative" }}
      >
        <Box
          style={{
            position: "absolute",
            transform: "translateX(-20%)",
            marginTop: "-20px",
            right: "3%",
          }}
        >
          <SampleArrow className="slick-prev" onClick={prevSlide} />
          <SampleArrow className="slick-next" onClick={nextSlide} />
        </Box>
        <Box
          style={{
            marginRight: "-10%",
            overflowX: "hidden",
            marginTop: "3%",
          }}
        >
          <Slider {...settings} ref={sliderRef}>
            {children}
          </Slider>
        </Box>
      </Grid>
    </>
  );
};
