import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import {
  AppBar,
  Avatar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Toolbar,
  Typography,
} from "@material-ui/core";
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";

import {
  CampaignMetricsIcon,
  CampaignMetricsIconActive,
  ChangePasswordIcon,
  ChangePasswordIconActive,
  CheckListIcon,
  CheckListIconPrimary,
  ColetaIcon,
  ColetaIconActive,
  ContactUsIcon,
  ContactUsIconActive,
  EditIcon,
  EditIconActive,
  EmployeesIcon,
  EmployeesIconActive,
  GoalsIcon,
  GoalsIconActive,
  HomeIcon,
  HomeIconActive,
  ListIcon,
  ListIconActive,
  LogoutIcon,
  NozzleActiveIcon,
  NozzleIcon,
  PriceIcon,
  PriceIconActive,
  RoadLoadIcon,
  SimulationIcon,
  SimulationIconActive,
  UnionIcon,
  UnionIconActive,
} from "@components/Icons";
import homeIconCollapse from "../../assets/icon_collapse_sidebar.svg";
import iconMenu from "../../assets/hamburguer_menu.svg";
import logoAtem from "../../assets/logo-atem.svg";

import { useUserState } from "@context/UserContext";
import { useAuth } from "@context/auth/AuthContext";

import { SIDEBAR_ADMIN_FLAGS, SIDEBAR_USER_FLAGS } from "@utils/featureFlags";
import { generatePermissionMatcher, goToLogout } from "@utils/index";

import { useFlags } from "flagsmith/react";

import { TypesPermissions } from "@components/Routes/TypesPermissions";
import { Roles } from "@components/Sidebar/model";
import Header from "@components/Header";

import ROUTES from "@config/routes";

const drawerWidth = 268;

interface MenuList {
  icon: React.JSX.Element;
  iconActive: React.JSX.Element;
  text: string;
  link: string;
  flag: string;
  hasPermission?: (permissionRef: string) => boolean;
  render?: () => boolean;
  onClick?: () => void;
}

export const ListDrawerUser: React.FC = () => {
  const location = useLocation();
  const classes = useStyles();
  const userState = useUserState();

  const { permissionSet } = useAuth();

  const menuListUser: MenuList[] = [
    {
      icon: <HomeIcon className={classes.icon} />,
      iconActive: <HomeIconActive className={classes.icon} />,
      text: "Início",
      link: ROUTES.USER_ROUTES.HOME,
      flag: "user_sidebar_home_user",
      hasPermission: generatePermissionMatcher(TypesPermissions.HOME),
    },
    {
      icon: <PriceIcon className={classes.icon} />,
      iconActive: <PriceIconActive className={classes.icon} />,
      text: "Preços de produtos ATEM",
      link: ROUTES.USER_ROUTES.APPOINTMENT_PRICE,
      flag: "user_sidebar_price_product_atem",
      hasPermission: generatePermissionMatcher(TypesPermissions.PRODUCT_PRICE),
    },
    {
      icon: <RoadLoadIcon className={classes.icon} />,
      iconActive: <RoadLoadIcon className={classes.icon} />,
      text: "Agenda e Vem",
      link: ROUTES.USER_ROUTES.ROADLOAD,
      flag: "user_sidebar_road_load",
      hasPermission: generatePermissionMatcher(TypesPermissions.DRIVER),
      onClick: () => {
        sessionStorage.setItem("sheetRoadLoad", JSON.stringify(false));
      },
    },
    {
      icon: <SimulationIcon className={classes.icon} />,
      iconActive: <SimulationIconActive className={classes.icon} />,
      text: "Simular carregamento",
      link: ROUTES.USER_ROUTES.SIMULATE_ORDER,
      flag: "user_sidebar_hide_simulate_loading",
      hasPermission: generatePermissionMatcher(TypesPermissions.SIMULATION),
    },
    {
      icon: <ContactUsIcon className={classes.icon} />,
      iconActive: <ContactUsIconActive className={classes.icon} />,
      text: "Fale conosco",
      link: ROUTES.USER_ROUTES.RECLAMATION_CREATE,
      flag: "user_sidebar_contact_us",
      hasPermission: generatePermissionMatcher(TypesPermissions.CONTACT),
    },
    {
      icon: <CampaignMetricsIcon width={24} height={24} />,
      iconActive: <CampaignMetricsIconActive className={classes.icon} />,
      text: "Métricas de campanhas",
      link: ROUTES.USER_ROUTES.DASH_POSH,
      flag: "user_sidebar_metrics_and_campaigns",
      hasPermission: generatePermissionMatcher(
        TypesPermissions.CAMPAIGN_METRICS
      ),
    },
    {
      icon: <GoalsIcon className={classes.icon} />,
      iconActive: <GoalsIconActive className={classes.icon} />,
      text: "Gestão de desempenho",
      link: ROUTES.USER_ROUTES.PERFORMANCE_MANAGEMENT,
      flag: "user_sidebar_hide_performance_management",
      hasPermission: generatePermissionMatcher(
        TypesPermissions.PERFORMANCE_MANAGEMENT
      ),
    },
    {
      icon: <EmployeesIcon className={classes.icon} />,
      iconActive: <EmployeesIconActive className={classes.icon} />,
      text: "Funcionários",
      link: ROUTES.USER_ROUTES.EMPLOYEE,
      flag: "user_sidebar_employee",
      hasPermission: generatePermissionMatcher(TypesPermissions.EMPLOYEE),
    },
    {
      icon: <NozzleIcon className={classes.icon} />,
      iconActive: <NozzleActiveIcon className={classes.icon} />,
      text: "Bico e Produto",
      link: ROUTES.USER_ROUTES.NOZZLE,
      flag: "user_sidebar_product_nozzle",
      hasPermission: generatePermissionMatcher(TypesPermissions.NOZZLES),
    },
    {
      icon: <ColetaIcon className={classes.icon} />,
      iconActive: <ColetaIconActive className={classes.icon} />,
      text: "Suas vendas",
      link: ROUTES.USER_ROUTES.SALES,
      flag: "user_sidebar_your_sales",
      hasPermission: generatePermissionMatcher(TypesPermissions.COLETA),
    },
    {
      icon: <GoalsIcon className={classes.icon} />,
      iconActive: <GoalsIconActive className={classes.icon} />,
      text: "Desconto",
      link: ROUTES.USER_ROUTES.DISCOUNT,
      flag: "user_sidebar_hide_discount",
      hasPermission: generatePermissionMatcher(TypesPermissions.HOME),
    },
    {
      icon: <GoalsIcon className={classes.icon} />,
      iconActive: <GoalsIconActive className={classes.icon} />,
      text: "Metas e comissões",
      link: ROUTES.USER_ROUTES.GOALS_AND_COMMISSIONS,
      flag: "user_sidebar_goal_and_commissions",
      hasPermission: generatePermissionMatcher(
        TypesPermissions.GOALS_AND_COMMISSIONS
      ),
    },
    // {
    //   icon: <AnnouncementsIcon className={classes.icon} />,
    //   iconActive: <AnnouncementsIconActive className={classes.icon} />,
    //   text: "Comunicados",
    //   link: ROUTES.USER_ROUTES.HANDOUTS,
    //   flag: "user_sidebar_communique",
    //   hasPermission: generatePermissionMatcher(TypesPermissions.ANNOUCEMENTS),
    // },
    /*{
      icon: <MaterialsIcon className={classes.icon} />,
      iconActive: <MaterialsIconActive className={classes.icon} />,
      text: "Material do revendedor",
      link: "material",
    },*/
    {
      icon: <ChangePasswordIcon className={classes.icon} />,
      iconActive: <ChangePasswordIconActive className={classes.icon} />,
      text: "Trocar senha",
      link: userState?.adminMode
        ? ROUTES.ADMIN_ROUTES.CHANGE_PASSWORD
        : ROUTES.USER_ROUTES.CHANGE_PASSWORD,
      flag: "user_sidebar_change_password",
      hasPermission: generatePermissionMatcher(
        TypesPermissions.CHANGE_PASSWORD
      ),
    },

    {
      icon: <EmployeesIcon className={classes.icon} />,
      iconActive: <EmployeesIconActive className={classes.icon} />,
      text: "Ir para modo Admin",
      link: ROUTES.ADMIN_ROUTES.HOME,
      flag: "user_sidebar_sidebar_redirect_admin",
      render: () => userState?.adminMode,
      onClick: () => {
        localStorage.setItem("sidebarAdmin", "true");
      },
    },
  ].filter((entry) => !entry.render || entry?.render());

  const [menuList, setListMenu] = useState<MenuList[]>([]);

  /* ATUALIZA O MENU COM BASE NAS PERMISSOES DO USUARIO e FEATURE FLAGS */
  const flags = useFlags(SIDEBAR_USER_FLAGS);
  const { flag_for_access_management } = useFlags([
    "flag_for_access_management",
  ]);

  useEffect(() => {
    function run() {
      const isAdmin =
        permissionSet?.ROLES.includes(Roles.ADMIN) ||
        permissionSet?.ROLES.includes(Roles.ADMINPORTAL);
      console.log("Verify if the user has admin privileges: ", isAdmin);

      if (isAdmin) {
        setListMenu(menuListUser.filter((item) => flags[item.flag]?.enabled));
        return;
      }

      const userPermission = permissionSet?.SYSTEM_MODULES;
      console.log(
        "Verify if the flagSmith 'access_management' is enabled: ",
        flag_for_access_management.enabled
      );

      const filteredMenus = flag_for_access_management.enabled
        ? menuListUser.filter((item: MenuList) => {
            const isTherePermission = item?.hasPermission;
            const UserHasPermission =
              isTherePermission && userPermission.some(isTherePermission);
            const hasFlag = flags[item.flag].enabled;

            return UserHasPermission && hasFlag;
          })
        : menuListUser.filter((item) => flags[item.flag]?.enabled);
      setListMenu(filteredMenus);
    }

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionSet, flag_for_access_management]);

  return (
    <List>
      {menuList.map((item, index) => {
        return (
          <ListItem
            key={index}
            className={`${clsx(classes.listItem)} List_Item-${
              item?.text
            }-${index}`}
            classes={{
              selected: classes.listItemSelected,
              button: classes.listItemHover,
            }}
            button
            selected={location.pathname === item.link}
            component={Link}
            to={item.link}
            onClick={item.onClick}
          >
            <ListItemIcon>
              <div
                className={
                  location.pathname === item.link
                    ? classes.borderMenu
                    : classes.borderMenuNormal
                }
              ></div>
              {location.pathname === item.link ? item.iconActive : item.icon}
            </ListItemIcon>
            <Typography
              className={
                location.pathname === item.link
                  ? classes.textMenuItemSelected
                  : classes.textMenuItem
              }
            >
              {item.text}
            </Typography>
          </ListItem>
        );
      })}
    </List>
  );
};

const ListDrawerAdmin: React.FC = () => {
  const location = useLocation();
  const classes = useStyles();

  const menuListAdmin = [
    {
      icon: <HomeIcon className={classes.icon} />,
      iconActive: <HomeIconActive className={classes.icon} />,
      text: "Home Admin",
      link: ROUTES.ADMIN_ROUTES.HOME,
      flag: "admin_sidebar_home",
    },
    {
      icon: <EmployeesIcon className={classes.icon} />,
      iconActive: <EmployeesIconActive className={classes.icon} />,
      text: "Criar Usuário",
      link: ROUTES.ADMIN_ROUTES.NEW_USER,
      flag: "admin_sidebar_create_new_user",
    },
    {
      icon: <CheckListIcon className={classes.icon} />,
      iconActive: <CheckListIconPrimary className={classes.icon} />,
      text: "Listar Usuários",
      link: ROUTES.ADMIN_ROUTES.LIST_USERS,
      flag: "admin_sidebar_list_user",
    },
    {
      icon: <ListIcon className={classes.icon} />,
      iconActive: <ListIconActive className={classes.icon} />,
      text: "Consultar Comprovantes",
      link: ROUTES.ADMIN_ROUTES.LIST_INVOICES_IMAGES,
      flag: "admin_sidebar_consult_check_receipts",
    },
    {
      icon: <EditIcon className={classes.icon} />,
      iconActive: <EditIconActive className={classes.icon} />,
      text: "Enviar Comunicado",
      link: ROUTES.ADMIN_ROUTES.ALL_COMMUNICATION,
      flag: "admin_sidebar_hide_send_communique",
    },
    {
      icon: <UnionIcon className={classes.icon} />,
      iconActive: <UnionIconActive className={classes.icon} />,
      text: "Bloqueio de produtos",
      link: ROUTES.ADMIN_ROUTES.PRODUCT_BLOCK,
      flag: "admin_sidebar_block_products",
    },
    {
      icon: <ContactUsIcon className={classes.icon} />,
      iconActive: <ContactUsIconActive className={classes.icon} />,
      text: "Fale conosco",
      link: ROUTES.ADMIN_ROUTES.RECLAMATION_ANALYSIS,
      flag: "admin_sidebar_contact_us",
    },

    {
      icon: <NozzleIcon className={classes.icon} />,
      iconActive: <NozzleActiveIcon className={classes.icon} />,
      text: "Validar anexo de bico e produto",
      link: ROUTES.ADMIN_ROUTES.NOZZLE,
      flag: "admin_sidebar_nozzle",
    },
    {
      icon: <ListIcon className={classes.icon} />,
      iconActive: <ListIconActive className={classes.icon} />,
      text: "Motorista vs Cliente",
      link: ROUTES.ADMIN_ROUTES.DRIVER_AND_CUSTOMER,
      flag: "admin_sidebar_driver_and_customer",
    },
    {
      icon: <ColetaIcon className={classes.icon} />,
      iconActive: <ColetaIconActive className={classes.icon} />,
      text: "Ir para modo Usuário",
      link: ROUTES.USER_ROUTES.HOME,
      onClick: () => {
        localStorage.setItem("sidebarAdmin", "false");
      },
      flag: "admin_sidebar_redirect_modo_user",
    },
  ];

  const [menuListAdminSideBar, setListMenuAdmin] = useState(menuListAdmin);
  const flags = useFlags(SIDEBAR_ADMIN_FLAGS);

  useEffect(() => {
    const filteredMenusAdmin = menuListAdminSideBar?.filter(
      (menuEntry) => flags[menuEntry.flag].enabled
    );

    setListMenuAdmin(filteredMenusAdmin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <List>
      {menuListAdminSideBar?.map((item, index) => (
        <ListItem
          key={index}
          button
          className={clsx(classes.listItem)}
          classes={{
            selected: classes.listItemSelected,
            button: classes.listItemHover,
          }}
          selected={location?.pathname === item?.link}
          component={Link}
          to={item?.link}
          onClick={item?.onClick}
        >
          <ListItemIcon>
            <div
              className={
                location?.pathname === item?.link
                  ? classes.borderMenu
                  : classes.borderMenuNormal
              }
            ></div>
            {location?.pathname === item?.link ? item?.iconActive : item?.icon}
          </ListItemIcon>
          <Typography
            className={
              location?.pathname === item?.link
                ? classes.textMenuItemSelected
                : classes.textMenuItem
            }
          >
            {item.text}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};

const Sidebar: React.FC<{ invisibleToolbar?: boolean }> = ({
  children,
  invisibleToolbar,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const userState = useUserState();
  const history = useHistory();

  const [isInvisible, setIsInvisible] = useState<Boolean>(
    invisibleToolbar ? invisibleToolbar : true
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const routesToHide = [
    ROUTES.USER_ROUTES.MY_ORDERS,
    ROUTES.USER_ROUTES.NEW_ORDER,
    ROUTES.USER_ROUTES.APPOINTMENT_TITLE,
    ROUTES.USER_ROUTES.APPOINTMENT_INVOICES,
    ROUTES.USER_ROUTES.APPOINTMENT_PRICE,
    ROUTES.USER_ROUTES.HANDOUTS,
    ROUTES.USER_ROUTES.RECLAMATION,
    ROUTES.USER_ROUTES.DASH_POSH,
    ROUTES.USER_ROUTES.CHANGE_PASSWORD,
    ROUTES.USER_ROUTES.SIMULATE_ORDER,
    ROUTES.ADMIN_ROUTES.NEW_USER,
    ROUTES.ADMIN_ROUTES.LIST_USERS,
    ROUTES.ADMIN_ROUTES.LIST_INVOICES_IMAGES,
    ROUTES.ADMIN_ROUTES.ADD_COMMUNICATION,
    ROUTES.ADMIN_ROUTES.ADD_IMAGES_LOGIN,
    ROUTES.ADMIN_ROUTES.HANDOUTS,
    ROUTES.ADMIN_ROUTES.HOME,
  ];

  const sessionStorageSidebarAdmin = localStorage.getItem("sidebarAdmin");
  const parsedSidebarAdmin = sessionStorageSidebarAdmin === "true";

  useEffect(() => {
    if (invisibleToolbar) {
      if (isInvisible === invisibleToolbar) return;
      if (isInvisible !== invisibleToolbar) {
        const updateStatusToolbar = () => {
          setIsInvisible(invisibleToolbar);
        };
        updateStatusToolbar();
      }
    }

    setIsInvisible(routesToHide.includes(history.location.pathname));

    if (isSidebarAdmin !== parsedSidebarAdmin) {
      setSidebarAdmin(parsedSidebarAdmin);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, routesToHide, invisibleToolbar]);

  const getInitialLettersName = (name: string) => {
    if (!name || name.trim().length === 0) return "";
    const splitedName = name.split(" ");
    let letters = "";
    for (let splited of splitedName) {
      letters += splited[0];
    }
    return letters;
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [isSidebarAdmin, setSidebarAdmin] = useState<Boolean>(
    parsedSidebarAdmin ?? false
  );

  return (
    <div
      className="Sidebar-main"
      style={{
        display: "flex",
      }}
    >
      <CssBaseline />

      <AppBar
        position="fixed"
        className={`${clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })} Sidebar-appbar`}
        style={{ display: invisibleToolbar ? "none" : "block" }}
      >
        <Toolbar>{!isInvisible && <Header />}</Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={`${clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })} Sidebar-drawer`}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Box className={classes.containerHeaderToolbarOpen}>
          {open && (
            <Box className={classes.containerAvatar}>
              <Avatar className={classes.avatar}>
                {userState?.username === "null" ||
                userState?.username === null ||
                userState?.username === undefined
                  ? "🧑‍🚒"
                  : getInitialLettersName(userState?.username)}
              </Avatar>
              <Box className={classes.containerAvatarText}>
                <Typography className={classes.userName}>
                  {localStorage.getItem("nameLogin")}
                </Typography>
              </Box>
            </Box>
          )}
          {open && (
            <Box
              className={classes.toolbar}
              style={{ textAlign: "right", width: "100%" }}
            >
              <IconButton onClick={handleDrawerClose}>
                <img
                  alt={"homeIconCollapse"}
                  src={homeIconCollapse}
                  width="14px"
                  height="10px"
                />
              </IconButton>
            </Box>
          )}
        </Box>

        <Box className={classes.containerButtonMenu}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <img alt={"iconMenu"} src={iconMenu} width="20px" height="16px" />
          </IconButton>
        </Box>

        <Divider className={classes.dividerStyle} />
        {isSidebarAdmin ? <ListDrawerAdmin /> : <ListDrawerUser />}

        <ListItem
          button
          className={clsx(classes.listItem)}
          classes={{
            selected: classes.listItemSelected,
            button: classes.listItemHover,
          }}
          component="a"
          onClick={() => goToLogout()}
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "-7px",
          }}
        >
          <ListItemIcon>
            <div className={classes.borderMenuNormal}></div>
            <LogoutIcon style={{ width: "22.6px" }} className={classes.icon} />
          </ListItemIcon>
          <Typography className={classes.textMenuItem}>Sair</Typography>
        </ListItem>

        <Box className={classes.containerLogoMenuOpened}>
          <img alt={"logoAtem"} src={logoAtem} width={37} height={28} />
        </Box>
      </Drawer>

      <main
        style={{
          flexGrow: 1,
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            border: "0px solid red",
            minHeight: "56px",
            // ...theme.mixins.toolbar,
          }}
        />
        {children}
      </main>
    </div>
  );
};

export default Sidebar;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    containerLogoMenuOpened: {
      width: "100%",
      textAlign: "center",
      marginRight: theme.spacing(1),
    },
    appBar: {
      paddingTop: theme.spacing(0.6),
      margin: 0,
      backgroundColor: "#F5F5F5",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxShadow: "none",
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      width: "60%",
      padding: "2rem",
      height: "10px",
      marginLeft: "10px",
      marginTop: "20px",
    },
    containerButtonMenu: {
      height: "70px",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      borderRight: "1px solid rgba(114, 101, 81, 0.1)",
      backgroundColor: "#F5F5F5",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: "hidden",
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "10px",
      },
      "&::-webkit-scrollbar-track": { width: "1px", height: "10px" },
      "&::-webkit-scrollbar-thumb": {
        background: "#c4c4c4",
        opacity: 0.3,
        borderRadius: "0.8rem",
      },
      "&::-webkit-scrollbar-thumb:hover ": {
        background: "#8E8484",
      },
    },
    drawerClose: {
      border: "none",
      backgroundColor: "#F5F5F5",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "10px",
      },
      "&::-webkit-scrollbar-track": { width: "1px", height: "10px" },
      "&::-webkit-scrollbar-thumb": {
        background: "#c4c4c4",
        opacity: 0.3,
        borderRadius: "0.8rem",
      },
      "&::-webkit-scrollbar-thumb:hover ": {
        background: "#8E8484",
      },

      width: theme.spacing(4) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(8) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    containerHeaderToolbarOpen: {
      display: "flex",
      alignItems: "center",
      marginTop: "60px",
    },
    avatar: {
      height: "40px",
      width: "40px",
      marginTop: "3.2rem",
      marginBottom: "3.2rem",
      color: "#FE5623",
      backgroundColor: "#f8e1e9",
    },
    userName: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "13.6px",
      lineHeight: "20px",
      color: "#D91F05",
    },
    city: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#1E1E1E",
      opacity: 0.7,
    },
    textMenuItem: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "13.6px",
      lineHeight: "21px",
      color: "#3E3D3D",
      opacity: 0.7,
      marginLeft: "25px",
    },
    textMenuItemSelected: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "13.6px",
      lineHeight: "21px",
      color: "#D91F05",
      marginLeft: "25px",
    },
    containerAvatar: {
      marginTop: "10px",
      display: "flex",
      marginLeft: "20px",
      alignItems: "center",
      height: "50px",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
    },
    containerAvatarText: {
      display: "block",
      marginLeft: "10px",
    },
    listItem: {
      paddingLeft: 0,
      fontSize: "1.6rem",
      color: theme.palette.getContrastText(theme.palette.background.paper),
      marginBottom: "1.4rem",
      borderRadius: "0.5rem",
      "& img": {
        filter:
          "invert(45%) sepia(0%) saturate(1244%) hue-rotate(218deg) brightness(85%) contrast(92%)",
      },
    },
    divider: {
      margin: "3.2rem 0 1.6rem",
    },
    borderMenu: {
      width: "5px",
      height: "25px",
      backgroundColor: "#D91F05",
      borderRadius: "8px",
      marginRight: "25px",
    },
    borderMenuNormal: {
      width: "5px",
      height: "25px",
      backgroundColor: "transparent",
      borderRadius: "8px",
      marginRight: "25px",
    },
    listItemSelected: {
      fontWeight: 500,
      fontSize: "16px",
      backgroundColor: `transparent !important`,
      color: "#D91F05",
      "& svg": { color: "#D91F05" },
      "& img": {
        filter:
          "invert(17%) sepia(47%) saturate(5421%) hue-rotate(350deg) brightness(88%) contrast(110%)",
      },
    },
    listItemHover: {
      "&:hover": {
        color: "#D91F05",
        backgroundColor: theme.palette.primaryLight.main,
        "& p": {
          color: "#D91F05",
        },
        "& img": {
          filter:
            "invert(17%) sepia(47%) saturate(5421%) hue-rotate(350deg) brightness(88%) contrast(110%)",
        },
        "& svg": {
          color: "#D91F05",
          filter:
            "invert(17%) sepia(47%) saturate(5421%) hue-rotate(350deg) brightness(88%) contrast(110%)",
        },
      },
    },
    dividerStyle: {
      backgroundColor: `rgba(114, 101, 81, 0.1)`,
      marginTop: theme.spacing(0.6),
    },
    icon: {
      fontSize: "22px",
    },
  })
);
