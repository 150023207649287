import * as Yup from "yup";

const FormHandoutValidationSchema = Yup.object().shape({
  title: Yup.string()
    .uppercase()
    .required("Preencha o títtulo do comunicado")
    .trim(),
  content: Yup.string().required("O comunicado não pode estar vazio"),
  expireAt: Yup.date().nullable(),
});

export default FormHandoutValidationSchema;
