import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { Grid, Hidden, Typography } from "@mui/material";

import {
  TypeAlignEnum,
  capitalizeFirstLetterFullString,
  formatAmount,
  formatCurrencyPriceWithTwoDigit,
} from "@utils/index";

import { useForm } from "@pages/User/NewRequest/resources/context/FormContext";
import TableCustom, {
  ITableBody,
  ITableHead,
} from "@pages/User/NewRequest/resources/table/TableCustom";
import TableMobile from "@pages/User/NewRequest/resources/table/TableMobile";

import { CloseRedIcon, SuccessIcon, WarningIcon } from "@components/Icons";
import Theme from "@components/Theme";
import { AtemStatusEnum } from "@modules/orders/dtos/ICreateOrderDataDTO";

interface TableMessage {
  title: string;
  icon: JSX.Element | null;
  subTitle: string;
}

function defineTableMessage(orders): TableMessage {
  const errorCount = orders.filter((order) => order.atemStatus === "error")
    .length;
  const SUCCESS_TITLE = "Sucesso!";
  const ERROR_TITLE = "Falha!";
  const WARNING_TITLE = "Atenção!";
  const SUCCESS_SUBTITLE = "Seu pedido foi efetuado com sucesso! Confira:";
  const ERROR_SUBTITLE = "Infelizmente, seu pedido não pôde ser processado.";
  const WARNING_SUBTITLE =
    "Alguns pedidos foram processados com sucesso, mas outros não.";

  if (errorCount === 0) {
    return {
      title: SUCCESS_TITLE,
      subTitle: SUCCESS_SUBTITLE,
      icon: <SuccessIcon style={{ fontSize: 12 }} />,
    };
  } else if (errorCount === orders.length) {
    return {
      title: ERROR_TITLE,
      subTitle: ERROR_SUBTITLE,
      icon: <CloseRedIcon style={{ fontSize: 12 }} />,
    };
  } else {
    return {
      title: WARNING_TITLE,
      subTitle: WARNING_SUBTITLE,
      icon: <WarningIcon style={{ fontSize: 12 }} />,
    };
  }
}
const TableSuccessMessage: React.FC = () => {
  const { newOrders } = useForm();

  const isMobile = useMediaQuery(Theme.breakpoints.only("xs"));
  let tableMessage: TableMessage = defineTableMessage(newOrders);

  return (
    <Grid
      container
      gap={1.5}
      sx={{ border: "1px solid #E0E0E0", borderRadius: 2, padding: 2 }}
    >
      <Grid item xs={12}>
        <Grid container gap={1} alignItems={"center"}>
          <Grid item>{tableMessage.icon}</Grid>
          <Grid item>
            <Typography variant="h5" sx={{ margin: "0rem", fontWeight: 700 }}>
              {tableMessage.title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography
          style={{
            fontFamily: "DM Sans",
            color: "#3E3D3D",
            fontSize: isMobile ? 14 : 12,
            opacity: 0.7,
            textAlign: "left",
          }}
        >
          {tableMessage.subTitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

const TablePageNewRequest: React.FC = () => {
  const { newOrders } = useForm();

  const THeadData: ITableHead[] = [
    {
      label: "Ordem",
      key: "numberOrder",
      align: TypeAlignEnum.LEFT,
    },
    {
      label: "Filial",
      key: "subsidiary",
      align: TypeAlignEnum.CENTER,
    },
    {
      label: "Data de entrega",
      key: "implantationDate",
      align: TypeAlignEnum.CENTER,
    },
    {
      label: "Produto",
      key: "product",
      align: TypeAlignEnum.CENTER,
    },
    {
      label: "Quantidade (L)",
      key: "quantity",
      align: TypeAlignEnum.RIGHT,
    },

    {
      label: "Preço",
      key: "price",
      align: TypeAlignEnum.CENTER,
    },
    {
      label: "Status",
      key: "atemStatus",
      align: TypeAlignEnum.CENTER,
    },
  ];

  // TODO: Desabilitado temporariamente
  // const customRenderPayment = (
  //   payment: string | undefined,
  //   deadlinePayment: number
  // ): string => {
  //   if (payment === TypePaymentEnum.TRANSFER) return "Transferência";
  //   if (payment === TypePaymentEnum.TICKET)
  //     return `Boleto - ${deadlinePayment} dias`;
  //   return "";
  // };

  const customRenderStatus = (status: string | undefined): string => {
    switch (status) {
      case AtemStatusEnum.LIB:
        return "Liberado";
      case AtemStatusEnum.BL:
        return "Bloqueado";
      case "error":
        return "Erro";
      default:
        return "-";
    }
  };

  const TBodyData: ITableBody[] = newOrders.map((order) => {
    return {
      numberOrder: `${order.numberOrder}`,
      subsidiary:
        `${capitalizeFirstLetterFullString(order?.subsidiary || "")}` || "", //  ! NOT FOUND YET !
      deliveryDate: order.deliveryDate,
      product: `${order.product.description}`,
      quantity: `${formatAmount(order.product.quantity)}`,

      price: order.product.price
        ? `${formatCurrencyPriceWithTwoDigit(
            order.product.price * order.product.quantity
          )}`
        : "",
      atemStatus: customRenderStatus(order.atemStatus),
    };
  });

  return (
    <Grid
      container
      sx={{
        border: "0px solid red",
        gap: 2,
      }}
    >
      <Grid item xs={12}>
        <TableSuccessMessage />
      </Grid>

      <Grid item xs={12}>
        <Hidden mdDown>
          <TableCustom THead={THeadData} TBody={TBodyData}></TableCustom>
        </Hidden>

        <Hidden mdUp>
          <TableMobile THead={THeadData} TBody={TBodyData} />
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default TablePageNewRequest;
