import {
  Button,
  Dialog,
  DialogTitle,
  Typography,
  makeStyles,
  createStyles,
  DialogContent,
  Grid,
} from "@material-ui/core";
import React from "react";
import { CheckGreenIcon, CloseIconRadiusIcon } from "@components/Icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    titleModal: {
      color: "#3E3D3D",
      fontSize: "2.2rem",
      fontFamily: "Montserrat",
      fontWeight: "bold",
      textAlign: "center",
      paddingTop: "30px",
    },
    subTitleModal: {
      color: "#3E3D3D",
      fontSize: "1.4rem",
      opacity: "0.6",
      fontFamily: "Montserrat",
      textAlign: "center",
      margin: "20px 0px",
    },
    closeButtonTop: {
      backgroudn: "transparent",
      paddingTop: "20px",
      paddingBottom: "20px",
      borderRadius: "30px",
    },
  })
);

interface Props {
  open: boolean;
  closeModal: () => void;
  text?: string;
  subtext?: string;
}

const ModalSendSuccess: React.FC<Props> = ({
  open,
  closeModal,
  text = "Registro enviado!",
  subtext = "Seu registro foi enviado com sucesso e em breve a central de atendimento entrará em contato.",
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      PaperProps={{ style: { borderRadius: "8px", padding: "20px 0px" } }}
    >
      <Grid
        xs={12}
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginRight: "30px",
          cursor: "pointer",
        }}
      >
        <Button onClick={() => closeModal()} className={classes.closeButtonTop}>
          <CloseIconRadiusIcon style={{ width: "14px", height: "14px" }} />
        </Button>
      </Grid>
      <DialogContent>
        <DialogTitle>
          <Grid container>
            <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
              <CheckGreenIcon style={{ width: "80px", height: "80px" }} />
            </Grid>
            <Grid xs={12}>
              <Typography className={classes.titleModal}>{text}</Typography>
            </Grid>
          </Grid>
          <Typography className={classes.subTitleModal}>{subtext}</Typography>
        </DialogTitle>
      </DialogContent>
    </Dialog>
  );
};

export { ModalSendSuccess };
