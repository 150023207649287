import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import {
  eachDayOfInterval,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  isEqual,
  isFriday,
  isSaturday,
  isSunday,
  setHours,
  startOfDay,
  startOfHour,
  startOfMonth,
} from "date-fns";
import { inject, injectable } from "inversify";
import moment from "moment-timezone";
import { ITimeLimitLocationDTO } from "../dtos/ITimeLimitLocationDTO";
import { ITimeLimitLocationService } from "../models/ITimeLimitLocationService";

@injectable()
export class TimeLimitLocationService implements ITimeLimitLocationService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  private verifyDate(date: Date, hourServer: Date, freighType: "CIF" | "FOB") {
    // é domingo, então desabilita
    if (isSunday(date)) {
      return true;
    }

    // qualquer data que venha antes da data do servidor tem que ser desabilitada
    if (isBefore(date, startOfDay(hourServer))) {
      return true;
    }

    /**
     * caso seja sabado (horario do servidor), então desabilita o sabado,
     * os sabados seguintes estão habilitados
     */
    if (
      isEqual(startOfDay(hourServer), startOfDay(date)) &&
      isSaturday(hourServer)
    ) {
      return true;
    }

    /**
     * caso seja sexta-feira (horario do servidor), então desabilita sábado após as 19 horas
     */
    if (isSaturday(date)) {
      if (isFriday(hourServer)) {
        const isAfter19 = isAfter(
          hourServer,
          startOfHour(setHours(hourServer, 19))
        );

        if (isAfter19) {
          return true;
        }
      }
    }

    /**
     * Se for CIF, só pode fazer pedidos até as 13 horas para o dia
     */
    if (freighType === "CIF") {
      const sameDay = isEqual(startOfDay(hourServer), startOfDay(date));
      if (sameDay) {
        if (isAfter(hourServer, startOfHour(setHours(hourServer, 13)))) {
          return true;
        }
      }
    }

    /**
     * Se for FOB, só pode fazer pedidos até as 14 horas para o dia
     */
    if (freighType === "FOB") {
      const sameDay = isEqual(startOfDay(hourServer), startOfDay(date));
      if (sameDay) {
        if (isAfter(hourServer, startOfHour(setHours(hourServer, 14)))) {
          return true;
        }
      }
    }

    return false;
  }

  public async execute(data: ITimeLimitLocationDTO) {
    const tzFormatted = `Etc/GMT${
      data.timeZone > 0 ? `+${data.timeZone}` : data.timeZone
    }`;

    const hourServer = moment(
      (await this.httpInstance.get<{ hour: string }>("/status")).hour
    )
      .tz(tzFormatted)
      .toDate();

    const startMonth = startOfMonth(data.date ? data.date : hourServer);
    const endMonth = endOfMonth(data.date ? data.date : hourServer);

    const daysMonth = eachDayOfInterval({ start: startMonth, end: endMonth });

    let dict: { [propName: string]: boolean } = {};

    for (let day of daysMonth) {
      dict[format(day, "dd/MM/yyyy")] = this.verifyDate(
        day,
        hourServer,
        data.freighType
      );
    }
    return dict;
  }
}
