import CalendarRange from "@components/CalendarRange";
import { CheckListIcon } from "@components/Icons";
import { useUserState } from "@context/UserContext";
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormQueryInvoices } from "./FormQueryInvoicesContext";
import { IQuery } from "./interface";
import DropdownSelectAll from "@components/DropdownSelectAll";

const FormQuery: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<IQuery>();
  const formQueryInvoicesContext = useFormQueryInvoices();
  const userState = useUserState();

  const [firstLoading, setFirstLoading] = useState(true);

  useEffect(() => {
    if (firstLoading) {
      setFirstLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDateRangeValid = (
    dateRange: [Date | null, Date | null] | null | undefined
  ): boolean => {
    return !!dateRange && !!dateRange[0] && !!dateRange[1];
  };

  const enableButton = useMemo((): boolean => {
    return values.CNPJ.length === 0 || !isDateRangeValid(values.dateRange);
  }, [values.CNPJ.length, values.dateRange]);

  const optionsCNPJ = userState.listCNPJ.map((cnpj) => ({
    value: cnpj.CNPJ,
    label: cnpj.companyName,
  }));

  const handleChangeSelectedCompanies = useCallback(
    (event: string[]) => {
      if (!firstLoading) {
        setFieldValue("CNPJ", event);
      }
    },
    [setFieldValue, firstLoading]
  );

  return (
    <Grid
      container
      alignItems="center"
      spacing={2}
      style={{
        backgroundColor: "#FFF",
        padding: "2rem",
        borderRadius: "4px",
      }}
    >
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography
          style={{ fontSize: isMobile ? "1.5rem" : "2rem", fontWeight: 700 }}
        >
          Consultar notas fiscais
        </Typography>
        <CheckListIcon />
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <DropdownSelectAll
          label="Razão social"
          options={optionsCNPJ}
          value={values.CNPJ}
          isCNPJ={true}
          isSubTitle={true}
          onChange={handleChangeSelectedCompanies}
          error={!!errors.CNPJ && !!touched.CNPJ}
          messageError={
            !!touched.CNPJ && typeof errors.CNPJ === "string" ? errors.CNPJ : ""
          }
        />
      </Grid>

      <Grid item xs={isMobile ? 12 : 4}>
        <CalendarRange
          name="dateRange"
          label="Período de emissão"
          value={values.dateRange}
          onChange={(dateRange) => {
            setFieldValue("dateRange", dateRange);
            setFieldTouched("beginDate", true);
            setFieldTouched("endDate", true);
          }}
          onBlur={() => {
            setFieldTouched("beginDate", true);
            setFieldTouched("endDate", true);
          }}
        />
      </Grid>

      <Grid
        item
        container
        style={{ marginTop: "2rem" }}
        justify="flex-end"
        xs={12}
      >
        <Button
          disabled={enableButton}
          style={{ boxShadow: "none", textTransform: "capitalize" }}
          color="primary"
          type="submit"
          variant="contained"
          startIcon={
            formQueryInvoicesContext.loadingQuery && (
              <CircularProgress
                color="secondary"
                style={{ height: 20, width: 20 }}
              />
            )
          }
        >
          Consultar
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormQuery;
