import {
  Button,
  createStyles,
  Fade,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PrintIcon from "@material-ui/icons/Print";
import { IQueryOrderDataDTO } from "@modules/orders/dtos/IQueryOrderDataDTO";
import clsx from "clsx";
import { format } from "date-fns";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import faturaImgIcon from "../../../assets/fatura.png";
import { useIoCContext } from "../../../context/IoCContext/IoCContext";
import { Types } from "../../../ioc/types";
import { ICreatePDFOrdersService } from "../../../modules/orders/models/ICreatePDFOrdersService";
import { formatCurrency, formatCurrency4DecimalsPlace } from "../../../utils";
import { useFormQuery } from "./FormQueryContext";
import { IQuery } from "./interface";
import { ModalCancelOrder } from "./ModalCancelOrder";

const useStyles = makeStyles((theme) =>
  createStyles({
    paperTable: {
      marginTop: "4rem",
      overflowX: "scroll",

      "&::-webkit-scrollbar": {
        background: theme.palette.grey[300],
        height: "0.7rem",
        borderRadius: ".4rem",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.grey["900"],
        borderRadius: ".4rem",
      },
    },
    iconImg: {
      filter:
        "invert(45%) sepia(0%) saturate(1244%) hue-rotate(218deg) brightness(85%) contrast(92%);",
    },
    paperSituation: {
      padding: "0 1rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paperSituationSuccess: {
      color: "#78C357",
      backgroundColor: "rgba(120,195,87,0.12)",
    },
    paperSituationError: {
      color: theme.palette.primary.main,
      backgroundColor: "rgba(195,12,12,0.12)",
    },
  })
);

const TableOrders: React.FC = () => {
  const classes = useStyles();
  const formQueryContext = useFormQuery();
  const iocContext = useIoCContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const createPDFOrdersService = iocContext.serviceContainer.get<
    ICreatePDFOrdersService
  >(Types.Orders.ICreatePDFOrdersService);
  const { values } = useFormikContext<IQuery>();
  const [orderToCancel, setOrderToCancel] = useState<IQueryOrderDataDTO | null>(
    null
  );
  const [openModal, setOpenModal] = useState(false);

  const nameLogisticStatus = ({
    logisticStatus,
  }: Pick<IQueryOrderDataDTO, "logisticStatus">) => {
    switch (logisticStatus) {
      case "A": {
        return "Não Faturado";
      }
      case "C": {
        return "Faturado Completamente";
      }
    }
  };

  const nameFinancialStatus = ({
    financialStatus,
  }: Pick<IQueryOrderDataDTO, "financialStatus">) => {
    switch (financialStatus) {
      case "A": {
        return "Liberado";
      }
      case "B": {
        return "Bloqueado";
      }
      case "C": {
        return "Bloqueado";
      }
      case "D": {
        return "Liberado";
      }
    }
  };

  return formQueryContext.dataQuery.length === 0 ? (
    <div style={{ marginTop: "4rem" }}>
      <Typography align="center" variant={isMobile ? "subtitle1" : "h5"}>
        Não houve resultado para o filtro da pesquisa.
      </Typography>
    </div>
  ) : (
    <Paper className={classes.paperTable}>
      <Grid container justify="center">
        <Button
          style={{ margin: "1rem" }}
          variant="contained"
          startIcon={<PictureAsPdfIcon />}
          color="primary"
          onClick={async () => {
            if (values.startDate && values.endDate) {
              await createPDFOrdersService.execute(formQueryContext.dataQuery, {
                beginDate: values.startDate,
                endDate: values.endDate,
              });
            }
          }}
        >
          Baixar PDF
        </Button>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Cancelar Pedido</TableCell>
            <TableCell align="center">Filial</TableCell>
            <TableCell align="center">Data de implantação</TableCell>
            <TableCell align="center">Pedido</TableCell>
            <TableCell align="center">Data de entrega</TableCell>
            <TableCell align="center">Razão social</TableCell>
            <TableCell align="center">Frete</TableCell>
            <TableCell align="center">Placa</TableCell>
            <TableCell align="center">Pagamento</TableCell>
            <TableCell align="center">Produto</TableCell>
            <TableCell align="center">Vl. unitário</TableCell>
            <TableCell align="center">Quantidade</TableCell>
            <TableCell align="center">Vl. total</TableCell>
            <TableCell align="center">Status logístico</TableCell>
            <TableCell align="center">Status financeiro</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formQueryContext.dataQuery.map((ele) => {
            return (
              <Fade
                key={ele.numberOrder}
                in={Boolean(formQueryContext.dataQuery.length)}
                timeout={1000}
              >
                <TableRow>
                  <TableCell align="center">
                    {ele.allowedToCancel ? (
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setOrderToCancel(ele);
                          setOpenModal(true);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                  <TableCell align="center">{ele.filialID}</TableCell>
                  <TableCell align="center">
                    {format(ele.implantationDate, "dd/MM/yyyy")}
                  </TableCell>
                  <TableCell align="center">{ele.numberOrder}</TableCell>
                  <TableCell align="center">
                    {((ele.deliveryDate as unknown) as string) !==
                      "0000-00-00" &&
                      format(new Date(ele.deliveryDate), "dd/MM/yyyy")}
                  </TableCell>
                  <TableCell align="center">
                    {ele.companyName && ele.companyName}
                  </TableCell>
                  <TableCell align="center">{ele.freightType}</TableCell>
                  <TableCell align="center">{ele.vehiclePlate}</TableCell>
                  <TableCell align="center">
                    {`${ele.payment}-${ele.payCond}`}
                  </TableCell>
                  <TableCell align="center">
                    {`${ele.productID} - ${ele.productName}`}
                  </TableCell>
                  <TableCell align="right">
                    {formatCurrency4DecimalsPlace(ele.price)}
                  </TableCell>
                  <TableCell align="right">
                    {ele.quantity.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell align="right">
                    {formatCurrency(ele.totalPrice)}
                  </TableCell>

                  <TableCell align="center">
                    <Paper
                      elevation={0}
                      className={clsx(classes.paperSituation, {
                        [classes.paperSituationError]:
                          ele.logisticStatus === "A",
                        [classes.paperSituationSuccess]:
                          ele.logisticStatus === "C",
                      })}
                    >
                      <Typography align="center" variant="button">
                        {nameLogisticStatus({
                          logisticStatus: ele.logisticStatus,
                        })}
                      </Typography>
                    </Paper>
                  </TableCell>
                  <TableCell align="center">
                    <Paper
                      elevation={0}
                      className={clsx(classes.paperSituation, {
                        [classes.paperSituationError]:
                          ele.financialStatus === "B" ||
                          ele.financialStatus === "C",
                        [classes.paperSituationSuccess]:
                          ele.financialStatus === "A" ||
                          ele.financialStatus === "D",
                      })}
                    >
                      <Typography align="center" variant="button">
                        {nameFinancialStatus({
                          financialStatus: ele.financialStatus,
                        })}
                      </Typography>
                    </Paper>
                  </TableCell>
                  <TableCell padding="none">
                    <Grid container justify="space-evenly">
                      <Tooltip title="Imprimir">
                        <IconButton size="medium">
                          <PrintIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Consultar fatura">
                        <IconButton size="medium">
                          <img
                            className={classes.iconImg}
                            src={faturaImgIcon}
                            alt="fatura"
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </TableCell>
                </TableRow>
              </Fade>
            );
          })}
        </TableBody>
      </Table>

      {orderToCancel ? (
        <ModalCancelOrder
          open={openModal}
          orderData={orderToCancel}
          closeModal={() => {
            setOrderToCancel(null);
            setOpenModal(false);
          }}
        />
      ) : (
        <></>
      )}
    </Paper>
  );
};

export default TableOrders;
