import React from "react";
import { Table, TableRow, TableCell, Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

interface ISkeletonTableOrderProps {
  rows?: number;
  cols?: number;
}

const SkeletonTableOrder: React.FC<ISkeletonTableOrderProps> = ({
  ...props
}) => {
  const generateSkeletonRow = (key) => {
    return (
      <>
        <TableRow key={key}>
          {[...Array(props.cols).keys()].map((col) => (
            <TableCell key={col} height={"40px"}>
              <Skeleton variant="rect" width={"100px"} height={"40px"} />
            </TableCell>
          ))}
        </TableRow>
      </>
    );
  };

  return (
    <>
      <Grid container style={{ padding: "16px" }}>
        <Grid item xs={3}>
          <Skeleton variant="rect" width={"70%"} height={"40px"}></Skeleton>
        </Grid>
      </Grid>

      <Table>
        <tbody>
          {[...Array(props.rows).keys()].map((row) => generateSkeletonRow(row))}
        </tbody>
      </Table>
    </>
  );
};

export default SkeletonTableOrder;
