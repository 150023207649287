import "./animate.css";
import React from "react";
import { Box } from "@material-ui/core";
import { StyledIcon } from "@components/StyledIcon";

const DashLoading: React.FC = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"100vh"}
      width={"100vw"}
    >
      <div className={"pulse"}>
        <StyledIcon
          iconType="logo-atem"
          sx={{
            width: "25rem",
            height: "25rem",
          }}
        />
      </div>
    </Box>
  );
};

export default DashLoading;
