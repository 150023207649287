/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Layout from "@components/Layout";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import iconGoal from "../../../assets/goals_and_commissions/goal.svg";
import iconGoalMix from "../../../assets/goals_and_commissions/goal_mix.svg";
import iconGoalChart from "../../../assets/goals_and_commissions/goal_chart.svg";
import { CardSales } from "@components/CardSales";

import TableProjectionSales from "@components/TableProjectionSales";
import { useHomeUserContext } from "@context/HomeUserContext";
import { Types } from "@ioc/types";
import { useIoCContext } from "@context/IoCContext/IoCContext";

import { useSnackbar } from "notistack";

import { IGetProjectionSalesService } from "@modules/projectionSales/models/IGetProjectionSalesService";
import { numberFormatter } from "@utils/index";
import iconProjectionSold from "../../../assets/goals_and_commissions/projection_sold.svg";
import iconProjectionMediaDailySale from "../../../assets/goals_and_commissions/projection_media_daily_sale.svg";
import iconProjectionMissAttainGoal from "../../../assets/goals_and_commissions/projection_miss_attain_goal.svg";
import iconProjectionDaysEndGoals from "../../../assets/goals_and_commissions/projection_days_end_goal.svg";
import iconProjectionMustSellPerDay from "../../../assets/goals_and_commissions/projection_must_sell_per_day.svg";
import iconArrowDown from "../../../assets/goals_and_commissions/arrow_down.svg";
import iconArrowUp from "../../../assets/goals_and_commissions/arrow_up.svg";
import SummaryChart from "@components/SummaryChart";

import { IGetYourSalesService } from "@modules/yourSales/models/IGetYourSalesService";
import { IGetYourSalesDTO } from "@modules/yourSales/dtos/IGetYourSalesDTO";
import ColumnChart from "@components/ColumnChart";
import {
  Content,
  IGetSalesPerformanceDTO,
} from "@modules/salesPerformance/dtos/IGetSalesPerformanceDTO";
import { IGetSalesPerformanceService } from "@modules/salesPerformance/models/IGetSalesPerformanceService";
import TableSalesLastMonth from "@pages/User/GolsAndComissions/resources/TableSalesLastMonth";
import {
  IGetProjectionSalesDTO,
  IGetSalesLastMonthDTO,
  IGetSummaryChartDTO,
} from "@pages/User/GolsAndComissions/model";
import { IGetSalesLastMonthService } from "@modules/salesLastMonth/model";
import { IGetSummaryChartService } from "@modules/summaryChart/model";
import useDialogAlert from "@hooks/useDialogAlert";
import AppError from "@utils/AppError";

const GoalsAndCommissions = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const iocContext = useIoCContext();
  const { documentList } = useHomeUserContext();
  let itemSelected = documentList.filter((item) => item.isSelected)[0];
  let documentItemSelect = itemSelected?.cnpj;
  const [yourSales, setYourSales] = useState<IGetYourSalesDTO>({});
  const [salesLastMonth, setSalesLastMonth] = useState<IGetSalesLastMonthDTO[]>(
    []
  );
  const [
    projectionSales,
    setProjectionSales,
  ] = useState<IGetProjectionSalesDTO | null>(null);
  const [
    salesPerformance,
    setSalesPerformance,
  ] = useState<IGetSalesPerformanceDTO | null>(null);
  const [summaryChart, setSummaryChart] = useState<IGetSummaryChartDTO>({});
  const [, setIsLoadingYourSales] = useState(true);
  const [, setIsLoadingSalesLastMonth] = useState(true);
  const [, setIsLoadingProjectionSales] = useState(true);
  const [, setIsLoadingSalesPerformance] = useState(true);
  const [, setIsLoadingSummaryChart] = useState(true);
  const [isSummaryChartEmpty, setIsSummaryChartEmpty] = useState(false);
  const [isProjectionSalesEmpty, setIsProjectionSalesEmpty] = useState(false);
  const [isSalesLastMonthEmpty, setIsSalesLastMonthEmpty] = useState(false);
  const [isSalesPerformanceEmpty, setIsSalesPerformanceEmpty] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const { snackbar } = useDialogAlert();

  const dataCardSales = [
    {
      icon: <img src={iconGoal} width="40px" alt="icone meta" />,
      title: yourSales.total_lt
        ? `${numberFormatter(yourSales.total_lt)} litros`
        : "0 litros",
      subtitle: "Metas de claros",
    },
    {
      icon: <img src={iconGoalMix} width="40px" alt="icon meta mix" />,
      title: yourSales.percent_mix
        ? `${Number.parseFloat(yourSales.percent_mix) * 100}%`
        : "0%",
      subtitle: "Metas mix",
    },
    {
      icon: <img src={iconGoalChart} width="40px" alt="icone grafico meta" />,
      title: yourSales.result_total_lt
        ? `${numberFormatter(
            Number.parseFloat(yourSales.result_total_lt)
          )} litros`
        : "0 litros",
      subtitle: "Sua venda",
    },
  ];

  const dataProjectionSales = [
    {
      icon: (
        <img
          src={iconProjectionSold}
          width="50px"
          alt="icone projeção vendida"
        />
      ),
      title: "Você já vendeu",
      subtitle: projectionSales?.total_refuel_volume
        ? `${projectionSales?.total_refuel_volume} litros`
        : "0 litros",
      description: "Porcentagem",
      percent: projectionSales?.total_refuel_volume_percentage
        ? `${
            Number.parseFloat(projectionSales?.total_refuel_volume_percentage) *
            100
          } %`
        : "0%",
      status: (
        <img src={iconArrowDown} width="24px" alt="icone seta para baixo" />
      ),
    },
    {
      icon: (
        <img
          src={iconProjectionMediaDailySale}
          width="50px"
          alt="icone de projeção média venda diária"
        />
      ),
      title: "Sua média diária de venda",
      subtitle: projectionSales?.daily_average
        ? `${Number.parseFloat(projectionSales?.daily_average).toFixed(
            2
          )} litros`
        : "0 litros",
      status: (
        <img src={iconArrowUp} width="24px" alt="ícone de seta para cima" />
      ),
    },
    {
      icon: (
        <img
          src={iconProjectionMissAttainGoal}
          width="50px"
          alt="ícone de abastecedor de combustível"
        />
      ),
      title: "Projeção de venda",
      subtitle: projectionSales?.remaining_fuel_volume
        ? `${numberFormatter(
            Number.parseFloat(projectionSales?.remaining_fuel_volume)
          )} litros`
        : "0 litros",
      description: "Porcentagem",
      percent: projectionSales?.remaining_fuel_volume_percentage
        ? `${
            Number.parseFloat(
              projectionSales.remaining_fuel_volume_percentage
            ) * 100
          } %`
        : "0%",
      status: (
        <img src={iconArrowUp} width="24px" alt="ícone de seta para cima" />
      ),
    },
    {
      icon: (
        <img
          src={iconProjectionDaysEndGoals}
          width="50px"
          alt="ícone de relógio"
        />
      ),
      title: "Dias para o fim desta meta",
      subtitle: projectionSales?.remaining_days
        ? `${projectionSales?.remaining_days} dias`
        : "0 dias",
      status: (
        <img src={iconArrowUp} width="24px" alt="ícone de seta para cima" />
      ),
    },
    {
      icon: (
        <img
          src={iconProjectionMustSellPerDay}
          alt="ícone de abastecedor "
          width="50px"
        />
      ),
      title: "Você deve vender por dia",
      subtitle: projectionSales?.should_sell_by_day
        ? `${numberFormatter(projectionSales?.should_sell_by_day)} litros`
        : "0 litros",
      status: (
        <img src={iconArrowDown} width="24px" alt="ícone seta para baixo" />
      ),
    },
  ];

  const getYourSalesService = iocContext.serviceContainer.get<
    IGetYourSalesService
  >(Types.YourSales.IGetYourSalesService);
  const getSalesLastMonthService = iocContext.serviceContainer.get<
    IGetSalesLastMonthService
  >(Types.SalesLastMonth.IGetSalesLastMonthService);
  const getProjectionSalesService = iocContext.serviceContainer.get<
    IGetProjectionSalesService
  >(Types.ProjectionSales.IGetProjectionSalesService);
  const getSummaryChartService = iocContext.serviceContainer.get<
    IGetSummaryChartService
  >(Types.SummaryChart.IGetSummaryChartService);
  const getSalesPerformanceService = iocContext.serviceContainer.get<
    IGetSalesPerformanceService
  >(Types.SalesPerformance.IGetSalesPerformanceService);

  const getYourSales = useCallback(async () => {
    try {
      setIsLoadingYourSales(true);
      const data = await getYourSalesService.execute(documentItemSelect);
      setYourSales(data ? data : {});
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro ao buscar suas vendas", {
        variant: "error",
      });
    } finally {
      setIsLoadingYourSales(false);
    }
  }, [getYourSalesService, documentItemSelect, enqueueSnackbar]);

  const getSalesLastMonth = useCallback(async () => {
    try {
      setIsLoadingSalesLastMonth(true);
      const data = await getSalesLastMonthService.execute(documentItemSelect);
      setSalesLastMonth(data);
      setIsSalesLastMonthEmpty(false);
    } catch (err) {
      if (err instanceof AppError) {
        console.info(`error reason for employee active: ${err!.message}`);
        snackbar({
          message: `Ocorreu um erro ao buscar vendas dos últimos meses:  ${
            err!.message
          }`,
          variant: "error",
        });
      }

      setIsSalesLastMonthEmpty(true);
    } finally {
      setIsLoadingSalesLastMonth(false);
    }
  }, [getSalesLastMonthService, documentItemSelect, enqueueSnackbar]);

  const getProjectionSales = useCallback(async () => {
    try {
      setIsLoadingProjectionSales(true);
      const data = await getProjectionSalesService.execute(documentItemSelect);
      setProjectionSales(data ? data : {});
      setIsProjectionSalesEmpty(false);
    } catch (error) {
      setIsProjectionSalesEmpty(true);
    } finally {
      setIsLoadingProjectionSales(false);
    }
  }, [getProjectionSalesService, documentItemSelect]);

  const getSalesPerformance = useCallback(async () => {
    try {
      setIsLoadingSalesPerformance(true);
      const data = await getSalesPerformanceService.execute({
        target: "sumBilling",
        cnpj: documentItemSelect,
      });

      setSalesPerformance(data ? data : null);
      setIsSalesPerformanceEmpty(false);
    } catch (err) {
      if (err instanceof AppError) {
        console.info(
          `error reason for SalesPerformance active: ${err!.message}`
        );
        snackbar({
          message: `Erro ao carregar as metas, ${err!.message}`,
          variant: "error",
        });
      }
      setIsSalesPerformanceEmpty(true);
    } finally {
      setIsLoadingSalesPerformance(false);
    }
  }, [getSalesPerformanceService, documentItemSelect]);

  const getSummaryChart = useCallback(async () => {
    try {
      setIsLoadingSummaryChart(true);
      const data = await getSummaryChartService.execute(documentItemSelect);
      setSummaryChart(data);
      setIsSummaryChartEmpty(false);
    } catch (err) {
      if (err instanceof AppError) {
        console.info(`error reason for SummaryChartService: ${err!.message}`);
        snackbar({
          message: `${err!.message}`,
          variant: "error",
        });
      }
      setIsSummaryChartEmpty(true);
    } finally {
      setIsLoadingSummaryChart(false);
    }
  }, [getSummaryChartService, documentItemSelect]);

  useEffect(() => {
    getYourSales();
    getSalesLastMonth();
    getProjectionSales();
    getSummaryChart();
    getSalesPerformance();
  }, [
    getYourSales,
    getSalesLastMonth,
    getProjectionSales,
    getSummaryChart,
    getSalesPerformance,
  ]);

  const data: number[] =
    salesPerformance?.content?.map((item: Content) =>
      parseFloat(item.refuel_volume_total ?? "0")
    ) ?? [];
  const categories: string[] =
    salesPerformance?.content?.map(
      (item: Content) => item.employee?.name ?? ""
    ) ?? [];

  return (
    <Layout disableFooter>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Box className={classes.paper}>
                <Typography
                  className={classes.title}
                  variant={isMobile ? "h6" : "h4"}
                >
                  Metas e comissões
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Paper className={classes.paper}>
              <Box className={classes.paper}>
                <Typography
                  className={classes.titleSection}
                  variant={isMobile ? "subtitle1" : "h5"}
                  style={{ marginBottom: "25px" }}
                >
                  Suas vendas
                </Typography>
                <Box
                  display={isMobile ? "block" : "flex"}
                  style={{
                    justifyContent: "space-between",
                    textAlign: "left",
                    width: "92%",
                  }}
                >
                  {dataCardSales.map((value, index) => (
                    <CardSales
                      key={index}
                      icon={value.icon}
                      title={value.title}
                      subtitle={value.subtitle}
                    />
                  ))}
                </Box>
                <Box className={classes.containerSection}>
                  <Typography
                    className={classes.titleSection}
                    variant={isMobile ? "subtitle1" : "h5"}
                  >
                    Resumo
                  </Typography>
                  <Box display={isMobile ? "block" : "flex"}>
                    {isSummaryChartEmpty ? (
                      <Typography>
                        Não há dados em gráficos de resumo.
                      </Typography>
                    ) : (
                      <SummaryChart
                        yourSales={summaryChart.sales_percent}
                        accumulatedGoal={summaryChart.common_percent}
                        addedWidth={summaryChart.added_percent}
                        mixGoalWidth={summaryChart.percent_mix}
                      />
                    )}
                  </Box>
                </Box>

                <Box className={classes.containerSection}>
                  <Typography
                    className={classes.titleSection}
                    variant={isMobile ? "subtitle1" : "h5"}
                  >
                    Performace de venda por frentista
                  </Typography>
                  <Box display="flex" width={"100%"}>
                    {isSalesPerformanceEmpty ? (
                      <Typography>
                        Não há dados em Performace de venda por frentista.
                      </Typography>
                    ) : (
                      <ColumnChart
                        chartType="bar"
                        height={350}
                        width={"100%"}
                        categories={categories}
                        data={data}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Paper className={classes.paper}>
              <Box className={classes.containerSection}>
                <Typography
                  className={classes.titleSection}
                  style={{ marginBottom: "25px" }}
                  variant={isMobile ? "subtitle1" : "h5"}
                >
                  Vendas dos últimos meses
                </Typography>
                {isSalesLastMonthEmpty || !salesLastMonth.length ? (
                  <Typography>
                    Não há dados na tabela de vendas dos últimos meses.
                  </Typography>
                ) : (
                  <div style={{ overflowX: "scroll" }}>
                    <Box width={isMobile ? "450px" : "100%"}>
                      <TableSalesLastMonth data={salesLastMonth} />
                    </Box>
                  </div>
                )}
              </Box>
              <Box className={classes.containerSection}>
                <Typography
                  className={classes.titleSection}
                  style={{ marginBottom: "25px" }}
                  variant={isMobile ? "subtitle1" : "h5"}
                >
                  Projeção de vendas
                </Typography>

                {isProjectionSalesEmpty ? (
                  <Typography>Não há dados em projeção de vendas.</Typography>
                ) : (
                  <div style={{ overflowX: "scroll" }}>
                    <Box width={isMobile ? "480px" : "100%"}>
                      <TableProjectionSales data={dataProjectionSales} />
                    </Box>
                  </div>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

export default GoalsAndCommissions;

const marginLeftDefault = "2.4%";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      justifyContent: "space-between",
    },
    paper: {
      width: "100%",
      border: "none",
      paddingLeft: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    containerSection: {
      width: "98%",
      border: "none",
      paddingTop: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      boxShadow: "none",
    },
    containerTitle: {
      maxWidth: "82%",
      marginLeft: marginLeftDefault,
    },
    title: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 700,
      // fontSize: "3rem",
      lineHeight: "59px",
      color: "#3E3D3D",
      paddingBottom: "1.4rem",
    },
    titleSection: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 700,
      // fontSize: "24px",
      lineHeight: "31px",
      color: "#3E3D3D",
      paddingLeft: 0,
      marginBottom: 2,
    },
    subtitleSection: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "20px",
      color: "#3E3D3D",
      opacity: 0.7,
    },
  })
);
