import BreadcrumbCustom, {
  BreadcrumbChild,
} from "@components/BreadcrumbCustom";
import PaginationDynamic from "@components/PaginationDynamic/PaginationDynamic";
import ROUTES from "@config/routes";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import { Types } from "@ioc/types";
import {
  createStyles,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { IQueryTitlesService } from "@modules/titles/models/IQueryTitlesService";
import { Grid } from "@mui/material";
import { resetTimeAndFormat } from "@utils/index";
import { calcPagesQuantity } from "@utils/pagination";
import { startOfMonth } from "date-fns";
import { endOfMonth } from "date-fns/esm";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Layout from "../../../../components/Layout";
import FormQuery from "./FormQuery";
import {
  FormQueryTitleProvider,
  useFormQueryTitle,
} from "./formQueryTitleContext";
import FormQueryValidationSchema from "./FormQueryValidationSchema";
import { IQuery } from "./interface";
import ModalImage from "./ModalImage";
import ModalUpload from "./ModalUpload";
import SkeletonTableTitle from "./SkeletonTableTitle/SkeletonTableTitle";
import TableTitles from "./TableTitles";
import {
  FILTER_BY_TYPE,
  ORDER_BY_TYPE,
  SITUATION_TYPE,
} from "./utils/ENUMS_TITLES";
import PageNotFound from "@components/PageNotFound/PageNotFound";

const useStyles = makeStyles((theme) =>
  createStyles({
    formTitle: {
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: 700,
      lineHeight: "46px",
      marginBottom: "2rem",
    },
    TilteFooter: {
      fontFamily: "Montserrat",
      color: "#3E3D3D",
    },
    CountItensFooter: {
      fontFamily: "Montserrat",
      color: "#FFB03A",
    },
    SubTilteFooter: {
      padding: 0,
      margin: 0,
      opacity: 0.6,
      fontFamily: "Montserrat",
      color: "#3E3D3D",
    },
  })
);

export const ITEMS_PER_PAGE = 10;

const AppointmentTitle: React.FC = () => {
  const classes = useStyles();
  const iocContext = useIoCContext();
  const formQueryTitleContext = useFormQueryTitle();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const userState = useUserState();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number | undefined>(0);

  const firstItemIndex = (currentPage - 1) * ITEMS_PER_PAGE + 1;
  const lastItemIndex = Math.min(currentPage * ITEMS_PER_PAGE, totalCount ?? 0);

  const allCNPJOptions = userState.listCNPJ.map((ele) => ele.CNPJ);

  const initialValues: IQuery = useMemo(
    () => ({
      CNPJ: allCNPJOptions,
      situation: SITUATION_TYPE.ALL,
      filterBy: FILTER_BY_TYPE.DUE_DATE,
      dateRange: [startOfMonth(new Date()), endOfMonth(new Date())],
      beginDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
    }),
    [allCNPJOptions]
  );

  const submitTitleForm = useCallback(
    async (values, page) => {
      try {
        formQueryTitleContext.setLoadingQuery(true);
        const queryTitlesService = iocContext.serviceContainer.get<
          IQueryTitlesService
        >(Types.Titles.IQueryTitlesService);

        if (values.CNPJ) {
          formQueryTitleContext.setCnpj(values.CNPJ);
        }

        if (values.filterBy) {
          formQueryTitleContext.setFilterBy(values.filterBy);
        }

        if (values.situation) {
          formQueryTitleContext.setSituation(values.situation);
        }

        const payloadFilterTitles = {
          CNPJ: values.CNPJ
            ? values.CNPJ
            : formQueryTitleContext.isCnpj
            ? formQueryTitleContext.isCnpj
            : initialValues.CNPJ,

          beginDate: resetTimeAndFormat(
            formQueryTitleContext.rangeDate[0]
              ? formQueryTitleContext.rangeDate[0]
              : initialValues.beginDate
          ),

          endDate: resetTimeAndFormat(
            formQueryTitleContext.rangeDate[1]
              ? formQueryTitleContext.rangeDate[1]
              : initialValues.endDate
          ),

          filterBy: values.filterBy
            ? values.filterBy
            : formQueryTitleContext.isFilterBy
            ? formQueryTitleContext.isFilterBy
            : initialValues.filterBy,

          situation: values.situation
            ? values.situation
            : formQueryTitleContext.isSituation
            ? formQueryTitleContext.isSituation
            : initialValues.situation,

          sortBy:
            values.filterBy ||
            formQueryTitleContext.isFilterBy ||
            initialValues.filterBy,

          specificOrderingTitles: false,

          sortOrder: ORDER_BY_TYPE.DESC,
          page: page > 1 ? page : 1,
          limit: ITEMS_PER_PAGE,
        };

        const situation = payloadFilterTitles.situation;

        switch (situation) {
          case SITUATION_TYPE.ALL:
            payloadFilterTitles.specificOrderingTitles = true;
            payloadFilterTitles.sortOrder = ORDER_BY_TYPE.EMPTY;
            break;

          case SITUATION_TYPE.DUE_DATE:
          case SITUATION_TYPE.OPEN:
            payloadFilterTitles.sortOrder = ORDER_BY_TYPE.ASC;
            break;

          case SITUATION_TYPE.CLOSED:
            payloadFilterTitles.sortOrder = ORDER_BY_TYPE.DESC;
            break;

          default:
            break;
        }

        formQueryTitleContext.setFilterTitles(payloadFilterTitles);

        const responseTitles = await queryTitlesService.execute(
          payloadFilterTitles
        );

        const filterDataTitles = {
          content: responseTitles.content,
          total: responseTitles.total,
          page: page > 1 ? page : 1,
        };

        setTotalCount(filterDataTitles.total);
        formQueryTitleContext.setDataQuery(filterDataTitles);
      } catch (error) {
        enqueueSnackbar("Ocorreu um erro ao buscar títulos", {
          variant: "error",
        });
      } finally {
        formQueryTitleContext.setLoadingQuery(false);
        formQueryTitleContext.setSearchFirstTime(false);
      }
    },
    [
      enqueueSnackbar,
      formQueryTitleContext,
      initialValues.CNPJ,
      initialValues.endDate,
      initialValues.filterBy,
      initialValues.situation,
      initialValues.beginDate,
      iocContext.serviceContainer,
    ]
  );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => {
    setCurrentPage(newPage + 1);
    submitTitleForm("", newPage + 1);
  };

  useEffect(() => {
    submitTitleForm(initialValues, currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadcrumbDataTitles: BreadcrumbChild[] = [
    {
      link: ROUTES.USER_ROUTES.HOME,
      label: "Início",
    },
    {
      label: "Títulos",
    },
  ];

  return (
    <Layout>
      <div
        style={{
          margin: isMobile ? 0 : "0 7rem",
          padding: "2rem",
          height: "100%",
          overflowY: "hidden",
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={submitTitleForm}
          validationSchema={FormQueryValidationSchema}
        >
          <Form>
            <Grid xs={10} style={{ padding: 8, marginLeft: "-20px" }}>
              <BreadcrumbCustom data={breadcrumbDataTitles} />
            </Grid>
            <Typography
              style={{
                color: "#3E3D3D",
                fontWeight: "bold",
                fontFamily: "Montserrat",
                fontSize: isMobile ? "2.0rem" : "3.0rem",
                paddingBottom: "20px",
                textAlign: "left",
                paddingLeft: isMobile ? "-5px" : "-14px",
              }}
            >
              Títulos
            </Typography>

            <FormQuery />

            {formQueryTitleContext.loadingQuery && (
              <SkeletonTableTitle rows={5} cols={isMobile ? 2 : 7} />
            )}
            {formQueryTitleContext.dataQuery.total === 0 &&
              !formQueryTitleContext.loadingQuery && (
                <PageNotFound
                  title="Não houve resultado para o filtro da pesquisa."
                  subtitle="Por favor tente outro filtro."
                />
              )}

            {!formQueryTitleContext.loadingQuery &&
              formQueryTitleContext.dataQuery.total !== 0 && (
                <>
                  <TableTitles page={currentPage} />
                  {isMobile ? (
                    <div>
                      <Grid container style={{ paddingTop: "20px" }}>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <PaginationDynamic
                            onPageChange={handlePageChange}
                            pagesTotal={calcPagesQuantity(
                              ITEMS_PER_PAGE,
                              totalCount ?? 0
                            )}
                            showFirstButton={true}
                            showLastButton={true}
                            page={currentPage}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            marginTop: isMobile ? "10px" : "",
                          }}
                        >
                          <span
                            className={classes.TilteFooter}
                            style={{
                              fontSize: isMobile ? "1.2rem" : "",
                            }}
                          >
                            <b>
                              Mostrando itens{" "}
                              <span className={classes.CountItensFooter}>
                                {firstItemIndex}-{lastItemIndex}
                              </span>
                            </b>
                            <p className={classes.SubTilteFooter}>
                              Total de {totalCount ?? 0}
                            </p>
                          </span>
                        </Grid>
                      </Grid>
                    </div>
                  ) : (
                    <div>
                      <Grid container style={{ paddingTop: "20px" }}>
                        <Grid item xs={12} md={6}>
                          <span className={classes.TilteFooter}>
                            <b>
                              Mostrando itens{" "}
                              <span className={classes.CountItensFooter}>
                                {firstItemIndex}-{lastItemIndex}
                              </span>
                            </b>
                            <p className={classes.SubTilteFooter}>
                              Total de {totalCount ?? 0}
                            </p>
                          </span>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <PaginationDynamic
                            onPageChange={handlePageChange}
                            pagesTotal={calcPagesQuantity(
                              ITEMS_PER_PAGE,
                              totalCount ?? 0
                            )}
                            showFirstButton={true}
                            showLastButton={true}
                            page={currentPage}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </>
              )}
          </Form>
        </Formik>
      </div>

      <ModalUpload />
      <ModalImage />
    </Layout>
  );
};

const Container: React.FC = () => {
  return (
    <FormQueryTitleProvider>
      <AppointmentTitle />
    </FormQueryTitleProvider>
  );
};

export default Container;
