import { CircularProgress, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";

interface SelectStylesProps {
  isDisabled: boolean;
  hasSelected: boolean;
  selectedIndex: number;
  colorText: string;
}

export enum SelectVariant {
  STANDARD,
  LOADING,
}

const useStyles = makeStyles<Theme, SelectStylesProps>((theme) => ({
  label: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    color: "#3E3D3D",
    marginBottom: 0.5,
  },
  list: {
    backgroundColor: (props) => (props.isDisabled ? "#E8E9EB" : "#F3F3F3"),
    border: `1px solid #E8E9EB`,
    borderRadius: 8,
    padding: 0,
    color: (props) => (props.isDisabled ? "#8c8d8f" : "#58595b"),
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(0.9),
    cursor: (props) => (props.isDisabled ? "not-allowed" : "pointer"),
  },
  selectedText: {
    color: (props) =>
      props.hasSelected || props.selectedIndex >= 0
        ? props.isDisabled
          ? "#8c8d8f"
          : props.colorText
        : props.colorText,
    fontWeight: 600,
    fontSize: 16,
    whiteSpace: "nowrap",
  },
  icon: {
    color: (props) => (props.colorText ? props.colorText : "#D91F05"),
  },
  errorText: {
    marginTop: 0.5,
    fontFamily: "Montserrat",
    fontSize: 10,
    color: "#D91F05",
  },
  menu: {
    minWidth: 300,
  },
  emptyOptionsText: {
    color: "#8c8d8f",
    padding: theme.spacing(2),
  },

  menuItem: {
    minWidth: 200,
    fontSize: 14,
    fontFamily: "Montserrat",
    "&.Mui-selected": {
      color: "#58595b",
      backgroundColor: "#E8E9EB",
      fontWeight: 700,
    },
    "&.Mui-selected:hover": {
      backgroundColor: "#E8E9EB",
    },
  },
}));

interface DefaultOptionsProps<T> {
  isDisabled?: boolean;
  option: T;
}

interface SelectProps<T> {
  variant?: SelectVariant;
  value?: T;
  options: Array<DefaultOptionsProps<T>>;
  label?: string;
  isDisabled?: boolean;
  hasError?: boolean;
  messageError?: string;
  getOptionItemLabel: (value: T) => string;
  onChange?: (selectedOption: T) => void;
  onBlur?: () => void;
  getSelectedOptionLabel: (value: T) => string;
  placeholder?: string;
  emptyOptionsText?: string;
  colorText?: string;
  backgroundSelect?: string;
  colorIconArrow?: string;
}

export default function Select<T>({
  variant = SelectVariant.STANDARD,
  value,
  options,
  label,
  isDisabled,
  hasError,
  messageError,
  getOptionItemLabel,
  onChange,
  onBlur,
  getSelectedOptionLabel,
  placeholder,
  emptyOptionsText,
  colorText = "#58595b",
  backgroundSelect = "background.paper",
  colorIconArrow = "#D91F05",
}: SelectProps<T>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const open = Boolean(anchorEl);

  const classes = useStyles({
    isDisabled: isDisabled || false,
    hasSelected: options.some((item) => item.option === value),
    selectedIndex,
    colorText,
  });

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    if (!isDisabled) setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (option: T, index: number) => {
    if (onChange) onChange(option);
    if (value === undefined) setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onBlur) onBlur();
  };

  const getIsSelectedItem = (index: number) => {
    if (value === undefined) {
      return index === selectedIndex;
    }
    return options[index].option === value;
  };

  const getSelectedValue = () => {
    if (value !== undefined) {
      return getSelectedOptionLabel(value);
    }

    if (selectedIndex >= 0 && options.length > 0) {
      return getSelectedOptionLabel(options[selectedIndex].option);
    }

    if (options.length > 0) {
      return getSelectedOptionLabel(options[0].option);
    }
    if (!options.some((item) => item.option === value)) {
      return placeholder ?? "Selecione";
    }
  };

  return (
    <div>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <List
        component="nav"
        aria-label="Device settings"
        className={classes.list}
      >
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
          className={classes.listItem}
        >
          <Typography className={classes.selectedText} noWrap>
            {getSelectedValue()}
          </Typography>
          {variant === SelectVariant.STANDARD ? (
            <KeyboardArrowDownIcon
              className={classes.icon}
              style={{ color: colorIconArrow ? colorIconArrow : "#D91F05" }}
            />
          ) : (
            <CircularProgress className={classes.icon} size={20} />
          )}
        </ListItem>
      </List>
      {hasError && (
        <Typography className={classes.errorText}>{messageError}</Typography>
      )}
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
        className={classes.menu}
      >
        {options.length === 0 && (
          <Typography className={classes.emptyOptionsText}>
            {emptyOptionsText ?? "Lista vazia"}
          </Typography>
        )}
        {options?.map((option, index) => (
          <MenuItem
            key={index}
            disabled={option.isDisabled}
            selected={getIsSelectedItem(index)}
            onClick={() => handleMenuItemClick(option.option, index)}
            className={classes.menuItem}
          >
            {getOptionItemLabel(option.option)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
