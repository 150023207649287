import { CheckedIcon, CheckListIconPrimary, DocIcon } from "@components/Icons";
import TableTitleModal from "@components/TableTitleModal";
import { Tag } from "@components/Tag";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import useDialogAlert from "@hooks/useDialogAlert";
import { Types } from "@ioc/types";
import {
  Button,
  CircularProgress,
  createStyles,
  Fade,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { IDownloadPDFBoletoTitleDTO } from "@modules/titles/dtos/IDownloadPDFBoletoTitleDTO";
import { IQueryDownloadTitlesDTO } from "@modules/titles/dtos/IQueryDownloadTitlesDTO";
import { IQueryTitlesDataDTO } from "@modules/titles/dtos/IQueryTitlesDataDTO";
import { IDownloadAllReportTitlesService } from "@modules/titles/models/IDownloadAllReportTitlesService";
import { IDownloadPDFBoletoTitlesService } from "@modules/titles/models/IDownloadPDFBoletoTitlesService";
import { ITEMS_PER_PAGE } from "@pages/User/MyOrders";
import AppError from "@utils/AppError";
import {
  cutText,
  formatCurrency,
  maskCNPJ,
  nameSituationTitle,
  resetTimeAndFormat,
} from "@utils/index";
import { DownloadPDF } from "@utils/MyOrders/download";
import { format } from "date-fns";
import { useFormikContext } from "formik";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useFormQueryTitle } from "./formQueryTitleContext";
import { IQuery } from "./interface";
import { columnsTitleMocked } from "./models/ITableHeaderTitles";
import {
  FILTER_BY_TYPE,
  ORDER_BY_TYPE,
  SITUATION_TYPE,
  TYPE_REPORT_TITLE,
} from "./utils/ENUMS_TITLES";
import { getSituation } from "./utils/utilsTitles";

const useStyles = makeStyles((theme) =>
  createStyles({
    footerTextTable: {
      fontWeight: "bold",
      display: "flex",
      "& span": {
        display: "block",
        marginLeft: "0.625rem",
        fontWeight: "bold",
      },
    },
    footerTextColorClosed: {
      color: "#78C357",
    },
    footerTextColorTotal: {
      color: "#000",
    },
    buttonDownloadReport: {
      textTransform: "none",
      fontFamily: "Montserrat",
      fontWeight: 600,
      lineHeight: "2.875rem",
      height: "auto",
    },
    lowercaseText: {
      textTransform: "lowercase",
    },
    gridColumn: {
      [theme.breakpoints.down("sm")]: {
        height: "5.9375rem",
      },
      height: "3.75rem",
      marginTop: "1rem",
      marginBottom: "1rem",
      alignItems: "left",
      width: "100%",
    },
    arrow: {
      [theme.breakpoints.up("sm")]: {
        display: "none",
        width: "25.625rem",
      },
    },
    buttonImg: {
      borderRadius: "0.3125rem",
      padding: "0.375rem",
    },
    numberProof: {
      fontFamily: "DM Sans",
      fontSize: "1rem",
      fontWeight: 700,
      paddingLeft: "0.625rem",
    },
    buttonProof: {
      textTransform: "none",
      width: "100%",
      fontWeight: 600,
      marginRight: "0.625rem",
    },
    checkIcon: {
      width: "0.75rem",
      height: "0.75rem",
      margin: "0px 0.625rem 0px 0.5rem",
    },
    gridContainerTable: {
      overflowX: "scroll",
      "&::-webkit-scrollbar": {
        width: "0.0625rem",
        height: "0.3125rem",
      },
      "&::-webkit-scrollbar-track": {
        width: "0.0625rem",
        height: "0.3125rem",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#8E8474",
        opacity: 0.3,
        borderRadius: "0.05rem",
      },
      "&::-webkit-scrollbar-thumb:hover ": {
        background: "#8E8484",
      },
    },
    TableContainer: {
      width: "100%",
      boxShadow: "none",
    },
    Table: {
      borderSpacing: "0 1.25rem",
      borderCollapse: "separate",
    },
    TableRow: {
      background: "#fff",
    },
    TableCell: {
      padding: "0.375rem 1rem 0.375rem 1rem",
      marginBottom: "1.25rem",
    },
    TableCellHead: {
      fontWeight: "bold",
      whiteSpace: "nowrap",
      fontFamily: "DM Sans",
      background: "#fff",
      "&:first-child": {
        paddingLeft: "0.15625rem",
        borderBottomLeftRadius: "0.0375rem",
        borderTopLeftRadius: "0.0375rem",
      },
      "&:last-child": {
        borderBottomRightRadius: "0.0375rem",
        borderTopRightRadius: "0.0375rem",
      },
    },
    TableCellFromData: {
      background: "#fff",
      marginBottom: "1.25rem",
      borderBottom: "none",
      fontFamily: "DM Sans",
      height: "80px",

      "&:first-child": {
        borderBottomLeftRadius: "0.0375rem",
        borderTopLeftRadius: "0.0375rem",
      },
      "&:last-child": {
        borderBottomRightRadius: "0.0375rem",
        borderTopRightRadius: "0.0375rem",
      },
    },
  })
);

interface IProductSelectedToDownload {
  [propName: string]: boolean;
}

interface ITableTitlesProps {
  page: number;
}

const TableTitles: React.FC<ITableTitlesProps> = (props) => {
  const classes = useStyles();
  const { snackbar } = useDialogAlert();
  const formQueryTitleContext = useFormQueryTitle();
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();
  const { values } = useFormikContext<IQuery>();
  const [selectedModalIndex, setSelectedModalIndex] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  const [titlesSelectedToDownload, setTitlesSelectedToDownload] = useState<
    IProductSelectedToDownload
  >({});

  const downloadAllReportTitlesService = iocContext.serviceContainer.get<
    IDownloadAllReportTitlesService
  >(Types.Titles.IDownloadAllReportTitlesService);

  const [loadingDownloadBoleto, setLoadingDownloadBoleto] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const [loadPDF, setLoadPDF] = useState<boolean>(false);
  const userState = useUserState();

  const downloadBoletos = useCallback(async () => {
    try {
      const downloadPDFBoletoService = iocContext.serviceContainer.get<
        IDownloadPDFBoletoTitlesService
      >(Types.Titles.IDownloadPDFBoletoTitlesService);
      const getAllTitlesSelected = Object.keys(titlesSelectedToDownload)
        .map((key) => {
          if (titlesSelectedToDownload[key]) {
            const title = formQueryTitleContext.dataQuery.content?.find(
              (title) => `${title.id}${title.Buzei}` === key
            );
            if (title) {
              return title;
            }
          }
          return null;
        })
        .filter((ele) => ele !== null)
        .map((title) => {
          if (title) {
            const dataBoleto: IDownloadPDFBoletoTitleDTO = {
              Buzei: title.Buzei,
              atemID: title.atemID,
              invoiceID: title.id,
              year: title.year,
            };
            return dataBoleto;
          }
          return null;
        });

      const url = await downloadPDFBoletoService.execute(
        getAllTitlesSelected as IDownloadPDFBoletoTitleDTO[]
      );

      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;

      const dateBase = new Date();
      const formattedDate = moment(dateBase).format("DD/MM/YYYY-HH:mm");
      const fileName = "NF-TITULOS"
        ? `${"NF-TITULOS"}-${formattedDate}`
        : `relatorio-${formattedDate}`;

      const fileNameFormated = fileName.replace("/", "-");

      link.download = `${fileNameFormated}.pdf`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      if (error instanceof AppError) {
        enqueueSnackbar(error.message, { variant: error.variant });
      } else {
        enqueueSnackbar("Ocorreu um erro ao baixar o(s) boleto(s)", {
          variant: "error",
        });
      }
    } finally {
      setLoadingDownloadBoleto(false);
    }
  }, [
    enqueueSnackbar,
    formQueryTitleContext.dataQuery,
    iocContext.serviceContainer,
    titlesSelectedToDownload,
  ]);

  const handleDownloadExcelTitles = async () => {
    try {
      setLoadPDF(true);
      if (values.beginDate && values.endDate) {
        if (userState.listCNPJ[0]) {
          let CNPJ = [`${userState.listCNPJ[0].CNPJ}`];
          if ((values?.CNPJ as string[]).length !== 0) {
            CNPJ = values?.CNPJ as string[];
          }

          const queryTitlesPDF: IQueryDownloadTitlesDTO = {
            CNPJ: formQueryTitleContext.isCnpj
              ? formQueryTitleContext.isCnpj
              : CNPJ,

            beginDate: formQueryTitleContext.rangeDate[0]
              ? formQueryTitleContext.rangeDate[0]
              : values?.beginDate,

            endDate: resetTimeAndFormat(
              formQueryTitleContext.rangeDate[1]
                ? formQueryTitleContext.rangeDate[1]
                : values?.endDate
            ),

            filterBy: formQueryTitleContext.isFilterBy
              ? formQueryTitleContext.isFilterBy
              : values?.filterBy
              ? values.filterBy
              : FILTER_BY_TYPE.DUE_DATE,

            situation: formQueryTitleContext.isSituation
              ? formQueryTitleContext.isSituation
              : values?.situation,

            sortBy:
              formQueryTitleContext.isFilterBy ||
              values?.filterBy ||
              FILTER_BY_TYPE.DUE_DATE,

            sortOrder: ORDER_BY_TYPE.DESC,
            specificOrderingTitles: false,

            page: props.page > 1 ? props.page : 1,
            limit: ITEMS_PER_PAGE,
            type: TYPE_REPORT_TITLE.EXCEL,
          };

          const situation = queryTitlesPDF.situation;

          switch (situation) {
            case SITUATION_TYPE.ALL:
              queryTitlesPDF.specificOrderingTitles = true;
              delete queryTitlesPDF.sortOrder;
              break;

            case SITUATION_TYPE.DUE_DATE:
            case SITUATION_TYPE.OPEN:
              queryTitlesPDF.sortOrder = ORDER_BY_TYPE.ASC;
              break;

            case SITUATION_TYPE.CLOSED:
              queryTitlesPDF.sortOrder = ORDER_BY_TYPE.DESC;
              break;

            default:
              break;
          }

          const urlFile = await downloadAllReportTitlesService.searchExcel(
            queryTitlesPDF
          );
          DownloadPDF(urlFile, "relatorio-dos-titulos");
        } else {
          throw new Error("Nao possui CNPJ para realizar a consulta");
        }
      }
    } catch (error) {
      if (error instanceof AppError) {
        snackbar({
          message: `Ocorreu um erro ao buscar relatorio ! ${error.message}`,
          variant: "error",
        });
      }
    } finally {
      setLoadPDF(false);
    }
  };

  useEffect(() => {
    if (Object.keys(titlesSelectedToDownload).length !== 0) {
      downloadBoletos();
    }
  }, [titlesSelectedToDownload, downloadBoletos]);

  const renderButtonUpload = (data: IQueryTitlesDataDTO) => {
    if (data.situation === SITUATION_TYPE.CLOSED && data.closed) {
      return format(new Date(data.closed), "dd/MM/yyyy");
    } else {
      if (data.imageTitle) {
        return (
          <Grid container alignItems="center">
            <Grid
              item
              xs={5}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tooltip
                title="1 comprovante de pagamento enviado"
                placement="top"
              >
                <IconButton
                  className={classes.buttonImg}
                  color="primary"
                  onClick={() => {
                    if (data.imageTitle) {
                      formQueryTitleContext.setImage({
                        id: data.imageTitle.id,
                        link: data.imageTitle.link,
                      });
                      formQueryTitleContext.setOpenModalImage(true);
                    }
                  }}
                >
                  <CheckListIconPrimary fontSize="small" />
                  <Typography className={classes.numberProof} color="primary">
                    1
                  </Typography>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Button
                className={classes.buttonProof}
                color="primary"
                onClick={() =>
                  formQueryTitleContext.setOpenModalUpload({
                    openModalUpload: true,
                    data: {
                      CNPJ: data.CNPJ,
                      atemID: data.atemID,
                      year: data.year,
                      invoiceID: data.id,
                    },
                  })
                }
              >
                Enviar novo
              </Button>
            </Grid>
          </Grid>
        );
      }
      return (
        <Button
          className={classes.buttonProof}
          color="primary"
          onClick={(event) => {
            event.stopPropagation();
            formQueryTitleContext.setOpenModalUpload({
              openModalUpload: true,
              data: {
                CNPJ: data.CNPJ,
                atemID: data.atemID,
                year: data.year,
                invoiceID: data.id,
              },
            });
          }}
        >
          Enviar Comprovante
        </Button>
      );
    }
  };

  const calculateHeight = () => {
    if (isMobile) {
      return "auto";
    }

    const contentLength = formQueryTitleContext.dataQuery.content?.length || 0;
    return contentLength < 5 ? "auto" : "72vh";
  };

  return formQueryTitleContext.dataQuery &&
    formQueryTitleContext.dataQuery.total ? (
    <>
      <Grid container style={{ justifyContent: "flex-start" }}>
        {loadPDF ? (
          <CircularProgress style={{ height: 25, width: 25 }} />
        ) : (
          <Button
            className={classes.buttonDownloadReport}
            color="primary"
            onClick={() => handleDownloadExcelTitles()}
          >
            Baixar relatório completo
          </Button>
        )}
      </Grid>
      <TableTitleModal
        open={openDialog}
        titlesData={
          (formQueryTitleContext.dataQuery?.content || [])[selectedModalIndex]
        }
        handleOnClose={() => setOpenDialog(false)}
      />

      <TableContainer
        className={classes.TableContainer}
        style={{ height: calculateHeight() }}
      >
        <Table className={classes.Table} stickyHeader>
          <TableHead>
            <TableRow className={classes.TableRow}>
              {columnsTitleMocked.map((item, index) => (
                <>
                  {isMobile && item.isVisibleMobile && (
                    <TableCell
                      key={index}
                      align={
                        item.option !== "Parcela" && isMobile
                          ? item.align
                          : "left"
                      }
                      className={classes.TableCellHead}
                      colSpan={item.option === "Parcela" ? 2 : 1}
                    >
                      {item.option}
                    </TableCell>
                  )}

                  {!isMobile && (
                    <TableCell
                      key={index}
                      align={item.align}
                      className={classes.TableCellHead}
                    >
                      {item.option}
                    </TableCell>
                  )}
                </>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {formQueryTitleContext.dataQuery.content?.map((ele, idx) => {
              const isLoading = loadingDownloadBoleto[`${ele.id}${ele.Buzei}`];
              return (
                <Fade
                  key={`${ele.id}${ele.Buzei}`}
                  in={Boolean(formQueryTitleContext.dataQuery.total)}
                  timeout={1000}
                >
                  <TableRow
                    onClick={() => {
                      setSelectedModalIndex(idx);
                      setOpenDialog(true);
                    }}
                  >
                    <TableCell
                      align="center"
                      className={classes.TableCellFromData}
                    >
                      {ele.situation === SITUATION_TYPE.CLOSED ? (
                        <IconButton size="small" onClick={() => {}}>
                          <CheckedIcon
                            htmlColor="#fff"
                            style={{
                              width: isMobile ? "15px" : "18px",
                              height: isMobile ? "15px" : "18px",
                            }}
                          />
                        </IconButton>
                      ) : (
                        <Tooltip title="Baixar Boleto">
                          <IconButton
                            size="small"
                            onClick={(event) => {
                              // Evitar a propagação do evento para a TableRow
                              event.stopPropagation();
                              setTitlesSelectedToDownload({
                                [`${ele.id}${ele.Buzei}`]: true,
                              });
                              setLoadingDownloadBoleto((prevState) => ({
                                ...prevState,
                                [`${ele.id}${ele.Buzei}`]: true,
                              }));
                            }}
                          >
                            {isLoading ? (
                              <CircularProgress
                                color="primary"
                                style={{
                                  width: isMobile ? "15px" : "24px",
                                  height: isMobile ? "15px" : "24px",
                                }}
                              />
                            ) : (
                              <DocIcon
                                htmlColor="#fff"
                                style={{
                                  width: isMobile ? "15px" : "24px",
                                  height: isMobile ? "15px" : "24px",
                                }}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>

                    <TableCell
                      align="left"
                      className={classes.TableCellFromData}
                      style={{ fontSize: isMobile ? "11px" : "" }}
                    >
                      {isMobile ? cutText(ele.id, 6) : ele.id}
                    </TableCell>
                    <TableCell
                      align={isMobile ? "left" : "right"}
                      className={classes.TableCellFromData}
                      style={{
                        fontSize: isMobile ? "11px" : "",
                      }}
                    >
                      {ele.Buzei}
                    </TableCell>

                    {!isMobile && (
                      <>
                        <TableCell
                          align="right"
                          className={classes.TableCellFromData}
                        >
                          {format(ele.emissionDate, "dd/MM/yyyy")}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.TableCellFromData}
                        >
                          {format(ele.dueDate, "dd/MM/yyyy")}
                        </TableCell>

                        <TableCell
                          align="left"
                          className={classes.TableCellFromData}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {ele.companyName}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.TableCellFromData}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {maskCNPJ(ele.CNPJ)}
                        </TableCell>

                        <TableCell
                          align="center"
                          className={classes.TableCellFromData}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {ele.reference ? ele.reference : "-"}
                        </TableCell>

                        <TableCell
                          align="right"
                          className={classes.TableCellFromData}
                        >
                          {formatCurrency(ele.value)}
                        </TableCell>

                        <TableCell
                          align="center"
                          className={classes.TableCellFromData}
                        >
                          <Tag
                            className={classes.lowercaseText}
                            style={{ whiteSpace: "nowrap" }}
                            type={getSituation(ele.situation)}
                          >
                            {nameSituationTitle(ele.situation)}
                          </Tag>
                          {ele.amountToBepaid > 0 ? (
                            <Typography variant="caption">
                              A pagar:&nbsp;
                              {formatCurrency(ele.amountToBepaid)}
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </TableCell>

                        <TableCell
                          align="center"
                          className={classes.TableCellFromData}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {renderButtonUpload(ele)}
                        </TableCell>
                      </>
                    )}

                    {isMobile && (
                      <TableCell
                        align="center"
                        className={classes.TableCellFromData}
                        style={{
                          paddingRight: "5px",
                          paddingLeft: "0px",
                        }}
                      >
                        <ArrowForwardIosIcon color="primary" />
                      </TableCell>
                    )}
                  </TableRow>
                </Fade>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  ) : (
    <></>
  );
};

export default TableTitles;
