import React, { createContext, useCallback, useContext, useState } from "react";

import { IQueryOrderDataDTO } from "../../../modules/orders/dtos/IQueryOrderDataDTO";
import { IClient } from "../../../utils/interfaces";

interface FormQueryContext {
  loading: boolean;
  loadingQuery: boolean;
  dataCustomer: IClient | null;
  dataQuery: IQueryOrderDataDTO[];
  searchFirstTime: boolean;
  closeMenuFilter: boolean;
  setLoading(loading: boolean): void;
  setLoadingQuery(loading: boolean): void;
  setDataCustomer(data: IClient): void;
  setDataQuery(data: IQueryOrderDataDTO[]): void;
  setSearchFirstTime(value: boolean): void;
  setCloseMenuFilter(value: boolean): void;
  isClickedFilter: boolean;
  setIsClickedFilter(value: boolean): void;
  openModalMap: boolean;
  setOpenModalMap: React.Dispatch<React.SetStateAction<boolean>>;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FormQueryContext = createContext<FormQueryContext>(
  {} as FormQueryContext
);

const FormQueryProvider: React.FC = ({ children }) => {
  const [loading, setLoadingState] = useState(false);
  const [loadingQuery, setLoadingQueryState] = useState(false);
  const [dataCustomer, setDataCustomer] = useState<IClient | null>(null);
  const [dataQuery, setDataQuery] = useState<IQueryOrderDataDTO[]>([]); // dataQueryMock | []
  const [searchFirstTime, setSearchFirstTime] = useState(true);
  const [closeMenuFilter, setCloseMenuFilter] = useState(false);
  const [isClickedFilter, setIsClickedFilter] = useState(false);
  const [openModalMap, setOpenModalMap] = useState<boolean>(false);

  const setLoading = useCallback((loading: boolean) => {
    setLoadingState(loading);
  }, []);

  const setLoadingQuery = useCallback((loading: boolean) => {
    setLoadingQueryState(loading);
  }, []);

  return (
    <FormQueryContext.Provider
      value={{
        loading,
        dataCustomer,
        loadingQuery,
        dataQuery,
        searchFirstTime,
        closeMenuFilter,
        isClickedFilter,
        setLoading,
        setLoadingQuery,
        setIsClickedFilter,
        setDataCustomer,
        setDataQuery,
        setSearchFirstTime,
        setCloseMenuFilter,
        openModalMap,
        setOpenModalMap,
      }}
    >
      {children}
    </FormQueryContext.Provider>
  );
};

const useFormQuery = (): FormQueryContext => {
  const context = useContext(FormQueryContext);
  if (!Object.values(context).length) {
    throw new Error(
      "useFormQuery deve ser utilizado dentro de um FormQueryProvider"
    );
  }
  return context;
};

export { FormQueryProvider, useFormQuery };
