import HeaderMobile from "@components/HeaderMobile";
import Sidebar from "@components/Sidebar";
import { useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";

interface Props {
  disableFooter?: boolean;
  disableToolbar?: boolean;
  disableToolbarMobile?: boolean;
}

const Layout: React.FC<Props> = ({
  children,
  disableFooter = false,
  disableToolbar,
  disableToolbarMobile = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <div
      className="Layout"
      style={{
        fontFamily: "monteserrat",
        maxWidth: "100%",
        overflow: "none",
      }}
    >
      {isMobile ? (
        <div>
          <HeaderMobile invisibleToolbar={disableToolbarMobile} />
          <div style={{ overflowX: "hidden" }}>{children}</div>
        </div>
      ) : (
        <Sidebar invisibleToolbar={disableToolbar}>
          <div
            style={{
              overflow: "hidden",
              maxWidth: "100%",
              width: "100%",
            }}
          >
            {children}
          </div>
        </Sidebar>
      )}
    </div>
  );
};

export default Layout;
