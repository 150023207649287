import ModalInactivatedUser from "@components/ModalInactivatedUser";
import PaginationDynamic from "@components/PaginationDynamic/PaginationDynamic";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { IBlockUserService } from "@modules/user/models/IBlockUserService";
import { IListUsersService } from "@modules/user/models/IListUsersService";
import { IUnlockUserService } from "@modules/user/models/IUnlockUserService";
import AppError from "@utils/AppError";
import { IUser } from "@utils/interfaces";
import { calcPagesQuantity } from "@utils/pagination";
import throttle from "lodash.throttle";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Layout from "../../../components/Layout";
import Title from "../../../components/Title";
import FilterListUsers from "./FilterListUsers";
import { HeaderTable } from "./ListUsersHeaderTable";
import ModalListBusinessName from "./ModalListBusinessName";
import SkeletonTableListUsers from "./SkeletonTableListUsers";

interface ISetLoadingUser {
  [propName: string]: boolean;
}

const ListUsersPage: React.FC = () => {
  const classes = useStyles();
  const [users, setUsers] = useState<IUser[]>([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [searchUsers, setSearchUsers] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [lockLoading, setLockLoading] = useState<ISetLoadingUser>({});
  const [unlockLoading, setUnlockLoading] = useState<ISetLoadingUser>({});
  const iocContext = useIoCContext();
  const [page, setPage] = useState<number>(0);
  const [active, setActive] = useState<boolean>(true);
  const [valueTab, setValueTab] = React.useState(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const [drawer, setDrawer] = useState<boolean>(false);
  const [catchemId, setCatchemId] = useState<string>("");
  const [catchBusiness, setCatchBusiness] = useState<IUser>();
  const rowsPerPage = 10;

  const listUsersService = iocContext.serviceContainer.get<IListUsersService>(
    Types.Users.IListUsersService
  );
  const blockUserService = iocContext.serviceContainer.get<IBlockUserService>(
    Types.Users.IBlockUserService
  );
  const unlockUserService = iocContext.serviceContainer.get<IUnlockUserService>(
    Types.Users.IUnlockUserService
  );

  const fetchUser = useCallback(() => {
    let mounted = true;
    async function fetch() {
      try {
        setLoadingUsers(true);
        const users = await listUsersService.execute(searchUsers);
        if (mounted) {
          setUsers(users);
        }
      } catch (error) {
        if (mounted) {
          enqueueSnackbar("Ocorreu um erro ao recuperar usuários", {
            variant: "error",
          });
        }
      } finally {
        if (mounted) {
          setLoadingUsers(false);
        }
      }
    }
    fetch();

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar, listUsersService, searchUsers]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser, active, lockLoading, unlockLoading]);

  const blockUser = useCallback(
    (userID: string) => {
      async function block(userID: string) {
        try {
          setLockLoading((oldLockLoading) => ({
            ...oldLockLoading,
            [userID]: true,
          }));

          const blockUser = await blockUserService.execute(userID);

          enqueueSnackbar(blockUser.message, { variant: "success" });
        } catch (error) {
          if (error instanceof AppError) {
            return enqueueSnackbar(error.message, {
              variant: error.variant,
            });
          }
          enqueueSnackbar("Ocorreu um erro ao bloquear usuário", {
            variant: "error",
          });
          return;
        } finally {
          setLockLoading((oldLockLoading) => ({
            ...oldLockLoading,
            [userID]: false,
          }));
          setShowModal(!showModal);
        }
      }

      block(userID);
    },
    [blockUserService, enqueueSnackbar, showModal]
  );

  const unlockUser = useCallback(
    (userID: string) => {
      async function unlock(userID: string) {
        try {
          setUnlockLoading((oldUnlockLoading) => ({
            ...oldUnlockLoading,
            [userID]: true,
          }));

          const unlockUser = await unlockUserService.execute(userID);

          enqueueSnackbar(unlockUser.message, { variant: "success" });
        } catch (error) {
          if (error instanceof AppError) {
            return enqueueSnackbar(error.message, {
              variant: error.variant,
            });
          }
          enqueueSnackbar("Ocorreu um erro ao desbloquear usuário", {
            variant: "error",
          });
          return;
        } finally {
          setUnlockLoading((oldUnlockLoading) => ({
            ...oldUnlockLoading,
            [userID]: false,
          }));
          setShowModal(!showModal);
        }
      }

      unlock(userID);
    },
    [enqueueSnackbar, unlockUserService, showModal]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttleAPI = useCallback(
    throttle((nextValue: string) => setSearchUsers(nextValue), 1000),
    []
  );

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValueTab(newValue);
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleDrawer = () => {
    setDrawer(!drawer);
  };

  const handleSeeMore = () => {
    setSeeMore(!seeMore);
  };

  const countUsersPerPage = useMemo((): number => {
    return users.filter((user) => user.enabled === active).length;
  }, [active, users]);

  const handleAlterTable = (active: boolean): void => {
    try {
      if (active) {
        setActive(true);
      } else {
        throw new Error("Show page of inactives");
      }
    } catch {
      setActive(false);
    } finally {
      setPage(0);
    }
  };

  return (
    <>
      {showModal && (
        <ModalInactivatedUser
          showModal={handleShowModal}
          blockUser={blockUser}
          unlockUser={unlockUser}
          userID={catchemId}
          enabled={active}
        />
      )}
      {seeMore && (
        <ModalListBusinessName
          business={seeMore}
          handle={handleSeeMore}
          user={catchBusiness as IUser}
          updateList={fetchUser}
        />
      )}
      <Layout disableToolbar={true} disableToolbarMobile={true}>
        <Title title="Usuários" />
        {loadingUsers && <SkeletonTableListUsers cols={6} rows={10} />}

        {!loadingUsers && (
          <Grid
            container
            alignItems="center"
            spacing={2}
            style={{
              padding: "1rem 5rem",
              borderRadius: "4px",
            }}
          >
            <Grid
              item
              xs={12}
              md={4}
              lg={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Tabs
                value={valueTab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab
                  style={{ textTransform: "capitalize" }}
                  onClick={() => handleAlterTable(true)}
                  label="Ativos"
                />
                <Tab
                  style={{ textTransform: "capitalize" }}
                  onClick={() => handleAlterTable(false)}
                  label="Inativos"
                />
              </Tabs>
              <Tabs>
                <Typography
                  className={classes.titleTypography}
                  onClick={handleDrawer}
                >
                  Filtrar
                </Typography>
                <FilterListUsers
                  drawer={drawer}
                  handle={handleDrawer}
                  throttle={throttleAPI}
                  initialPage={setPage}
                />
              </Tabs>
            </Grid>
          </Grid>
        )}
        {!loadingUsers && (
          <Table className={classes.tableContainer}>
            <TableHead className={classes.tableCard}>
              {HeaderTable.map((header) => (
                <TableCell className={classes.headerTextTable}>
                  {header.headerName}
                </TableCell>
              ))}
            </TableHead>
            <br />

            <TableBody>
              {users
                .filter((user) => user.enabled === active)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user) => (
                  <>
                    <TableRow key={user.id} className={classes.tableCard}>
                      <TableCell
                        className={classes.tableCard}
                      >{`${user.firstName} ${user.lastName}`}</TableCell>
                      <TableCell className={classes.tableCard}>
                        {user.email}
                      </TableCell>
                      <TableCell className={classes.tableCard}>
                        {
                          user.attributes.CNPJ.map(
                            (user) => user.companyName
                          )[0]
                        }
                        <Tooltip title="Ver mais" placement="top" arrow>
                          <span
                            className={classes.seeMore}
                            onClick={() => {
                              handleSeeMore();
                              setCatchBusiness(user);
                            }}
                          >
                            {user.attributes.CNPJ.map(
                              (user) => user.companyName
                            ).length <= 1 ? (
                              <i style={{ padding: ".5rem" }}>i</i>
                            ) : (
                              `+${
                                user.attributes.CNPJ.map(
                                  (user) => user.companyName
                                ).length - 1
                              }`
                            )}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center" className={classes.tableCard}>
                        -
                      </TableCell>
                      <TableCell align="center" className={classes.tableCard}>
                        -
                      </TableCell>
                      <TableCell
                        className={classes.buttonInactivate}
                        onClick={() => {
                          handleShowModal();
                          setCatchemId(user.id);
                        }}
                      >
                        {user.enabled ? "Inativar" : "Ativar"}
                      </TableCell>
                    </TableRow>
                    <br />
                  </>
                ))}
            </TableBody>
          </Table>
        )}

        {!loadingUsers && (
          <div className={classes.paginationContainer}>
            <Typography className={classes.Total}>
              Motrando um total de
              <span style={{ color: "#FFB03A" }}>{` ${Math.min(
                rowsPerPage,
                users.filter((user) => (active ? user.enabled : !user.enabled))
                  .length -
                  page * rowsPerPage
              )}`}</span>
            </Typography>

            {countUsersPerPage > 0 && (
              <PaginationDynamic
                onPageChange={(event, page) => setPage(page)}
                pagesTotal={calcPagesQuantity(rowsPerPage, countUsersPerPage)}
                page={page + 1}
              />
            )}
          </div>
        )}
      </Layout>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      margin: "0 5rem",
      marginBottom: "1rem",
    },
    tableContainer: {
      margin: "0 0 0 5rem",
      width: "93%",
    },
    containerTabs: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "1rem",
    },
    buttonFilter: {
      color: "#D91F05",
      fontWeight: 600,
      cursor: "pointer",
    },
    buttonInactivate: {
      fontWeight: 600,
      color: "#D91F05",
      cursor: "pointer",
      border: "none",
      boxShadow: "none",
    },
    tableCard: {
      backgroundColor: "#FFF",
      border: "none",
      fontFamily: "Montserrat",
    },
    headerTextTable: {
      color: "#3E3D3D",
      fontWeight: "bold",
      border: "none",
    },
    titleTypography: {
      color: "#D91F05",
      fontWeight: 600,
      cursor: "pointer",
    },
    seeMore: {
      backgroundColor: "#FFF5F7",
      color: "#D91F05",
      fontWeight: 500,
      fontSize: "1.2rem",
      borderRadius: "50%",
      textAlign: "center",
      marginLeft: "1rem",
      padding: ".2rem",
      cursor: "pointer",
    },
    Total: {
      fontWeight: 600,
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "1.3rem",
    },
    paginationContainer: {
      padding: "5px 48px",
      paddingBottom: "15px",
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

export default ListUsersPage;
