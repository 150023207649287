import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IDiscountProductResponse } from "../dtos/IProductResponse";
import { IProductService } from "../models/IProductService";
import { IProductQuery } from "../dtos/IProductQuery";
import { ISaveProductDTO } from "../dtos/ISaveProductDTO";

@injectable()
export class ProductService implements IProductService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async list(query: IProductQuery): Promise<IDiscountProductResponse> {
    const { cnpj, ...params } = query;
    return await this.httpInstance.get<IDiscountProductResponse>(
      `/products/company/${cnpj}`,
      {
        params,
      }
    );
  }

  public async save(body: ISaveProductDTO): Promise<void> {
    const { companyId, ...productData } = body;
    return this.httpInstance.post<void>(`/products/company/${companyId}`, {
      ...productData,
    });
  }

  public async edit(body: ISaveProductDTO): Promise<void> {
    const { companyId, ...productData } = body;
    return this.httpInstance.patch<void>(`/products/${companyId}`, {
      ...productData,
    });
  }

  public async delete(id: string): Promise<void> {
    return this.httpInstance.delete<void>(`/products/${id}`);
  }
}
