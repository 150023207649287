import React, { useState } from "react";

import {
  Modal,
  Fade,
  Paper,
  ButtonProps,
  Grid,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { useTheme } from "@material-ui/core";

import ContainedButton from "@pages/Admin/Communications/resource/ContainedButton";
import TextButton from "@pages/Admin/Communications/resource/TextButton";

import { CloseIcon, TrashIcon } from "@components/Icons";

import { filialLabel } from "@utils/interfaces";

interface IFilial {
  centers: string[];
  path: string;
  startDate?: string;
  endDate?: string;
}

interface ModalFilialDetailsProps extends ButtonProps {
  children?: React.ReactNode;
  filiais: IFilial;
  onClickRemoveCenters: (indexCenterRemove: number[]) => void;
}

const ModalFilialDetails: React.FC<ModalFilialDetailsProps> = ({
  children,
  ...props
}) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([] as number[]);

  const handleSetSelected = (index: number) => {
    const verified = selected.some((s) => s === index);
    // se retornar true significa que o elemento ja esta contido na lista, entao nao deve inserir
    if (!verified) {
      // logica para inserir o elemento
      setSelected([...selected, index]);
    } else {
      // logica para remover o elemento
      const newSelected = selected.filter((s) => s !== index);
      setSelected(newSelected);
    }
  };

  const handleRemoveAllCentersSelected = async (indexList: number[]) => {
    props.onClickRemoveCenters(indexList);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const TitleModal: React.FC = ({ children }) => {
    const theme = useTheme();
    return (
      <Box>
        <Typography
          sx={{
            fontSize: { xs: 14, sm: 16, md: 18, lg: 24 },
            fontFamily: theme.typography.fontFamily,
            fontWeight: 700,
          }}
        >
          {children}
        </Typography>
      </Box>
    );
  };

  const CardsCenter = () => {
    const styles = {
      textDecorationRed: {
        textDecoration: "line-through",
        textDecorationColor: "red",
        textDecorationStyle: "double",
      },
    };

    return (
      <Grid
        container
        className="ModalFilialDetails-CardsCenter"
        sx={{
          border: "0px solid red",
          gap: { lg: 1.5 },
          justifyContent: "center",
        }}
      >
        {props.filiais?.centers?.map((center, index) => {
          return (
            <Grid item xs={8} key={index}>
              <Grid
                container
                sx={{
                  border: "0px solid black",
                  justifyContent: "space-between",
                  textAlign: "left",
                  ...(selected.some((s) => s === index)
                    ? styles.textDecorationRed
                    : {}),
                }}
              >
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <Button onClick={() => handleSetSelected(index)}>
                    <TrashIcon
                      style={{
                        fontSize: 15,
                        color: "red",
                        fill: "white",
                      }}
                    />
                  </Button>
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {filialLabel(center)}
                  </Typography>
                </Grid>

                <Grid item>
                  <Grid container sx={{ gap: 2 }}>
                    <Grid item>
                      <Typography variant="h6" sx={{ fontWeight: 400 }}>
                        {center}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const CloseIconContainer = () => {
    return (
      <Grid
        container
        sx={{ paddingTop: "8px" }}
        className="modal-CloseIcon-container"
        justifyContent={"right"}
      >
        <Button sx={{ padding: 1 }} onClick={() => handleClose()}>
          <CloseIcon></CloseIcon>
        </Button>
      </Grid>
    );
  };

  const ClockContainer: React.FC = ({ children }) => {
    return (
      <Box>
        <Typography
          sx={{
            fontSize: { xs: 10, sm: 11, md: 11, lg: 10 },
            fontFamily: theme.typography.fontFamily,
            fontWeight: 400,
          }}
        >
          {children}
        </Typography>
      </Box>
    );
  };

  return (
    <div>
      <TextButton onClick={handleOpen}>{children}</TextButton>

      <Modal
        className="ModalFilialDetails-modal"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade className="ModalFilialDetails-modal-fade" in={open}>
          <Paper
            className="ModalFilialDetails-modal-fade-paper"
            sx={{
              border: "0px solid white",
              borderRadius: 2,
              width: { xs: "95%", sm: "70%", md: "60%", lg: "45%" },
              height: { xs: "95%", sm: "90%", md: "90%", lg: "90%" },
              padding: { xs: 0.5, sm: 1, md: 1, lg: 2 },
            }}
          >
            <Grid
              container
              className="ModalFilialDetails-grid-container"
              sx={{
                border: "0px solid red",
                height: "100%",
                overflowY: "auto",
                gap: { lg: 3 },
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Grid item xs={12}>
                {" "}
                <CloseIconContainer />{" "}
              </Grid>
              <Grid item xs={12}>
                {" "}
                <TitleModal>Lista de Filiais</TitleModal>
              </Grid>
              <Grid item xs={8}>
                {" "}
                <img
                  width={"100%"}
                  height={"100%"}
                  src={props.filiais.path}
                  alt={props.filiais.path}
                />{" "}
              </Grid>
              <Grid item xs={12}>
                {" "}
                <ClockContainer>
                  {" "}
                  <Typography variant={"body2"} style={{ marginBottom: "6px" }}>
                    Publicado em:{" "}
                    <b>
                      {props.filiais.startDate &&
                        new Date(
                          props?.filiais?.startDate
                        ).toLocaleDateString()}{" "}
                      {" as "}
                      {props.filiais.startDate &&
                        new Date(props.filiais.startDate).toLocaleTimeString(
                          [],
                          { hour: "2-digit", minute: "2-digit" }
                        )}
                    </b>
                  </Typography>
                  <Typography variant={"body2"}>
                    Expira em:
                    <b>
                      {" "}
                      {props.filiais.endDate &&
                        new Date(props?.filiais?.endDate).toLocaleDateString()}
                    </b>
                  </Typography>
                </ClockContainer>{" "}
              </Grid>
              <Grid item xs={12}>
                {" "}
                <CardsCenter />{" "}
              </Grid>
              <Grid item xs={12}>
                {" "}
                <ContainedButton
                  onClick={() => handleRemoveAllCentersSelected(selected)}
                >
                  Alterar
                </ContainedButton>{" "}
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalFilialDetails;
