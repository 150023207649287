import { Button, useTheme } from "@material-ui/core";
import React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import DrawerCustom from "@components/DrawerCustom";
import PaginationCustom from "@components/Pagination";

import { STATUSNOZZLE } from "@pages/Admin/Nozzle/model";
import {
  TableColumn,
  TypographyBody,
  TypographyHeader,
  getStatusColor,
} from "@pages/Admin/Nozzle/resource/table/TableNozzleAdmin";
import {
  imageNozzleNormalized,
  useStyles,
} from "@pages/Admin/Nozzle/resource/table/TableNozzleValidate";
import { INozzle } from "@pages/User/Nozzle/model";
import FormikUpdateImage from "@pages/User/Nozzle/resource/FormikUpdatedImage";

import { INozzlesResponse } from "@modules/nozzles/models";
import { ModalReasonReject } from "../ModalReasonReject/ModalReasonReject";

export interface INozzleGroup extends INozzlesResponse {}

interface Props {
  rows: INozzle[];
  rowsPerPage?: number;
  handleNozzlesUpdate: (e) => void;
}

enum KEY {
  NOZZLE = "nozzle",
  PRODUCT = "product",
  IMG = "image_src",
  STATUS = "status",
  REASON = "reason",
  ACTION = "action",
}

const TableNozzle: React.FC<Props> = ({ rows, handleNozzlesUpdate }) => {
  const theme = useTheme();
  const classes = useStyles();
  const rowsPerPage = 10;

  const [page, setPage] = React.useState(0);
  const [openModalReject, setOpenModalReject] = React.useState(false);
  const [reasonReject, setReasonReject] = React.useState("");

  const tableHeader: TableColumn[] = [
    { key: KEY.NOZZLE, label: "Bico", align: "center", width: "10%" },
    { key: KEY.PRODUCT, label: "Produto", align: "left", width: "15%" },
    { key: KEY.IMG, label: "Imagem", align: "left", width: "30%" },
    { key: KEY.STATUS, label: "Status", align: "left", width: "20%" },
    { key: KEY.REASON, label: "", align: "left", width: "20%" },
    { key: KEY.ACTION, label: "", align: "right", width: "35%" },
  ];

  const formatEntry = (entry: INozzle, key: string) => {
    switch (key) {
      case KEY.NOZZLE:
        return entry.nozzles.map((nozzle, index) => (
          <span key={index} style={{ lineHeight: 2.5 }}>
            {nozzle.nozzle}
            <br />
          </span>
        ));

      case KEY.PRODUCT:
        return (
          entry.nozzles.map((nozzle, index) => (
            <span key={index} style={{ lineHeight: 2.5 }}>
              {nozzle.product?.description}
              <br />
            </span>
          )) ?? "-"
        );

      case KEY.IMG:
        return entry.image_src !== null
          ? imageNozzleNormalized(entry.image_src, theme)
          : "-";

      case KEY.STATUS:
        if (entry.status === STATUSNOZZLE.APPROVED) return "Concluído";
        if (entry.status === STATUSNOZZLE.WAITING)
          return "Aguardando Aprovação";
        if (entry.status === STATUSNOZZLE.BLOCK) return "Recusado";

        if (entry.status === null)
          return (
            <div style={{ opacity: 0.7 }}>
              {`Para começar, clique no botão acima "Enviar fotos".`}
            </div>
          );

        return "-";

      case KEY.REASON:
        if (entry.status === STATUSNOZZLE.BLOCK)
          return (
            <Button
              style={{
                fontFamily: "Montserrat",
                color: "#D91F05",
                fontWeight: "bold",
                textTransform: "none",
              }}
              onClick={() => {
                setOpenModalReject(true);
                setReasonReject(entry.reject_reason ?? "");
              }}
            >
              Ver motivo da recusa
            </Button>
          );
        return "";

      case KEY.ACTION:
        return (
          (
            <DrawerCustom
              key={key}
              label={"Alterar"}
              anchortype={"right"}
              disabled={
                !entry.image_src || entry.status === STATUSNOZZLE.APPROVED
              }
            >
              <FormikUpdateImage
                groupSelected={entry}
                handleNozzlesUpdate={handleNozzlesUpdate}
              />
            </DrawerCustom>
          ) ?? "-"
        );

      default:
        return entry[key] ?? "-";
    }
  };

  const handleChangePage = (_, newPage: number) => {
    setPage(newPage);
  };

  const handleOnChange = (_, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleClose = () => {
    setReasonReject("");
    setOpenModalReject(false);
  };

  return (
    <TableContainer>
      <Table className={classes.table} style={{ paddingTop: "10px" }}>
        <TableHead>
          <TableRow>
            {tableHeader.map((item, index) => (
              <TableCell
                key={index}
                sx={{
                  textAlign: item.align ?? "left",
                  width: item.width ?? "auto",
                }}
                className={classes.tableCell}
              >
                <TypographyHeader>{item.label}</TypographyHeader>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {tableHeader.map((col, indexCol) => (
                <TableCell
                  key={indexCol}
                  sx={{ textAlign: col.align ?? "left" }}
                  className={classes.tableCell}
                >
                  <TypographyBody sx={{ color: getStatusColor(row[col.key]) }}>
                    {formatEntry(row, col.key)}
                  </TypographyBody>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <ModalReasonReject
        open={openModalReject}
        reasonReject={reasonReject ?? ""}
        closeModal={handleClose}
      />

      <PaginationCustom
        count={rows.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangeHandle={handleOnChange}
        onPageChange={handleChangePage}
      />
    </TableContainer>
  );
};

export default TableNozzle;
