/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import _ from "lodash";
import {
  ICreateSchedule,
  ICreateScheduleReponse,
  IQueryRoadLoad,
  IRoadLoadRows,
  IRoadLoadService,
  IScheduleVacanciesResponse,
} from "@modules/roadLoad/model";

import {
  IHandleRoadLoadMethod,
  IQueryRoadLoadModel,
  IRoadBlockedLoad,
  IRoadLoadAll,
  IRoadLoadAllNormalized,
  IRoadLoadFiltersOptions,
  IRoadNotLoad,
  IScheduledLoad,
} from "@pages/User/RoadLoad/model";

import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useAuth } from "@context/auth/AuthContext";

import {
  getDateInOneWeek,
  checkOnlyDriverPermission,
  isValidAvailableHours,
  sortByNameInAlphabeticalOrder,
  downloadPDF,
} from "@utils/index";
import { STATUSROADLOAD } from "@utils/enum";
import AppError from "@utils/AppError";

import useDialogAlert from "@hooks/useDialogAlert";
import { Types } from "@ioc/types";
import {
  IFormikValues,
  initialValuesFormikDefault,
} from "../resource/drawer/IDrawerformik";

interface IRoadLoadContext {
  valuesFilterFormik: IFormikValues;
  handleValueFilterFormik: (value: IFormikValues) => void;
  onlyDriverUserID: string;
  onlyDriver: boolean;
  tabSelected: number;
  roadLoad?: IRoadLoadAll;
  loading: ILoading | null;
  roadLoadFilterOptions: IRoadLoadFiltersOptions | undefined;
  roadLoadVacancies: IScheduleVacanciesResponse | undefined;
  handleRoadLoadMethod: IHandleRoadLoadMethod;
  normalizedRoadLoad: (
    roadLoad?: IRoadLoadAll
  ) => IRoadLoadAllNormalized | undefined;
  firstDateTimeAvailable: Date | undefined;
  availableDate: string[] | undefined;
  queryParams: IQueryRoadLoadModel | undefined;
  handleFetchDriverByCNPJ: (cnpj?: string) => Promise<void>;
  roadsSelected: IRoadNotLoad[];
  updateRoadSelected: (road?: IRoadNotLoad) => void;
  roadCreated: ICreateScheduleReponse | undefined;
}

interface ILoading {
  scheduled?: boolean;
  waiting?: boolean;
  finished?: boolean;
  blocked?: boolean;
  canceled?: boolean;

  vacancies?: boolean;
  customers?: boolean;
  products?: boolean;
  centers?: boolean;
  drivers?: boolean;
  availableDate?: boolean;
  pdfReport?: boolean[];
}

export const limitRowsPerPageDefault = 10;
export const LIMIT_ORDER_SALES = 10;
const RoadLoadContext = createContext<IRoadLoadContext | undefined>(undefined);

const RoadLoadProvider = ({ children }) => {
  const { snackbar } = useDialogAlert();

  const { userID, permissionSet } = useAuth();

  const [onlyDriverUserID, setOnlyDriverUserID] = useState<string>(userID);
  const [onlyDriver, setOnlyDriver] = useState<boolean>(
    checkOnlyDriverPermission(permissionSet.SYSTEM_MODULES, permissionSet.ROLES)
  );

  useEffect(() => {
    setOnlyDriver(() => {
      return checkOnlyDriverPermission(
        permissionSet.SYSTEM_MODULES,
        permissionSet.ROLES
      );
    });

    setOnlyDriverUserID((prev) => {
      if (prev !== onlyDriverUserID) {
        return userID;
      }
      return prev;
    });

    if (!onlyDriver) {
      handleFetchDrivers();
    }
  }, [permissionSet, userID]);

  const iocContext = useIoCContext();
  const roadLoadService = iocContext.serviceContainer.get<IRoadLoadService>(
    Types.RoadLoad.IRoadLoadService
  );

  const sessionTabPageRoadLoad = sessionStorage.getItem("tabSelectedRoadLoad");
  const parsedTabPageRoadLoad =
    sessionTabPageRoadLoad !== "undefined"
      ? JSON.parse(sessionTabPageRoadLoad || "0")
      : 0;
  const [tabSelected, setTabSelected] = useState<number>(parsedTabPageRoadLoad);
  const handleTabSelected = (tab: number) => {
    sessionStorage.setItem("tabSelectedRoadLoad", JSON.stringify(tab));
    setTabSelected(tab);
  };

  const [loading, setLoading] = useState<ILoading | null>({
    pdfReport: Array(LIMIT_ORDER_SALES).fill(false),
  });
  const [availableDate, setAvailableDate] = useState<string[] | undefined>(
    undefined
  );

  const [firstDateTimeAvailable, setFirstDateTimeAvailable] = useState<
    Date | undefined
  >(undefined);

  const [roadLoad, setRoadLoad] = useState<IRoadLoadAll | undefined>(() => {
    const storedRoadLoad = sessionStorage.getItem("roadLoad");
    return storedRoadLoad && storedRoadLoad !== "undefined"
      ? JSON.parse(storedRoadLoad)
      : undefined;
  });

  const [roadLoadVacancies, setRoadLoadVacancies] = useState<
    IScheduleVacanciesResponse | undefined
  >(undefined);

  const [roadLoadFilterOptions, setRoadLoadFilterOptions] = useState<
    IRoadLoadFiltersOptions | undefined
  >(undefined);
  const [queryParams, setQueryParams] = useState<
    IQueryRoadLoadModel | undefined
  >(undefined); // NOTE: guarda parametros de consulta usados pelo usuario apos filtrar dados

  const [valuesFilterFormik, setValuesFilterFormik] = useState<IFormikValues>(
    initialValuesFormikDefault
  );

  const handleValueFilterFormik = (value: IFormikValues) => {
    setValuesFilterFormik(value);
  };

  const [roadCreated, setRoadCreated] = useState<
    ICreateScheduleReponse | undefined
  >(undefined); // roadCreatedMock

  const resetRoadCreated = () => {
    setRoadCreated(undefined);
    setRoadsSelected([]);
  };

  const [roadsSelected, setRoadsSelected] = useState<IRoadNotLoad[]>(() => {
    const storedRoadLoadSelected = sessionStorage.getItem("roadLoadSelected");
    return storedRoadLoadSelected && storedRoadLoadSelected !== "undefined"
      ? JSON.parse(storedRoadLoadSelected)
      : [];
  });

  const updateRoadSelected = (roadSelected?: IRoadNotLoad) => {
    if (!roadSelected) return setRoadsSelected([]);
    const roadIndex = roadsSelected.findIndex(
      (road) => road.order === roadSelected.order
    );

    const newRoadsSelected =
      roadIndex === -1
        ? [...roadsSelected, roadSelected]
        : roadsSelected.filter((road) => road.order !== roadSelected.order);

    setRoadsSelected(newRoadsSelected);
    sessionStorage.setItem(
      "roadLoadSelected",
      JSON.stringify(newRoadsSelected)
    );
  };

  const from: string = getDateInOneWeek(new Date(), "backward");
  const to: string = getDateInOneWeek(new Date(), "forward");

  const queryDefault = {
    limit: limitRowsPerPageDefault,
    from,
    to,
  };

  const fetchQuerySchedules = useCallback(async (query: IQueryRoadLoad) => {
    try {
      if (query.status === STATUSROADLOAD.SCHEDULED)
        setLoading((prevLoading) => ({ ...prevLoading, scheduled: true }));
      if (query.status === STATUSROADLOAD.WAITING)
        setLoading((prevLoading) => ({ ...prevLoading, waiting: true }));
      if (query.status === STATUSROADLOAD.BLOCKED)
        setLoading((prevLoading) => ({ ...prevLoading, blocked: true }));
      if (query.status === STATUSROADLOAD.FINISHED)
        setLoading((prevLoading) => ({ ...prevLoading, finished: true }));
      if (query.status === STATUSROADLOAD.CANCELED)
        setLoading((prevLoading) => ({ ...prevLoading, canceled: true }));

      const roadLoadResponse = await roadLoadService.getRoadLoad(query);
      switch (query.status) {
        case STATUSROADLOAD.WAITING:
          if (_.isEqual(roadLoadResponse, roadLoad?.waiting)) break;

          setRoadLoad((prevRoadLoad) => ({
            ...prevRoadLoad,
            waiting: roadLoadResponse,
          }));
          break;

        case STATUSROADLOAD.SCHEDULED:
          setRoadLoad((prevRoadLoad) => {
            return {
              ...prevRoadLoad,
              scheduled: roadLoadResponse, // NOTE: roadLoadResponse <> scheduleLoadMockResponse
            };
          });
          break;

        case STATUSROADLOAD.FINISHED:
          setRoadLoad((prevRoadLoad) => ({
            ...prevRoadLoad,
            finished: roadLoadResponse,
          }));
          break;

        case STATUSROADLOAD.UPCOMING_SCHEDULE:
          setRoadLoad((prevRoadLoad) => ({
            ...prevRoadLoad,
            upcomingSchedule: roadLoadResponse,
          }));
          break;

        case STATUSROADLOAD.BLOCKED:
          setRoadLoad((prevRoadLoad) => ({
            ...prevRoadLoad,
            blocked: roadLoadResponse,
          }));
          break;

        case STATUSROADLOAD.CANCELED:
          setRoadLoad((prevRoadLoad) => ({
            ...prevRoadLoad,
            canceled: roadLoadResponse,
          }));
          break;

        default:
          break;
      }
    } catch (err) {
      if (err instanceof AppError) {
        showErrorSnackbar(
          `Nao foi possivel exibir dados do carregamento rodoviario AGENDADO - ${err.message}`
        );
      }
    } finally {
      if (query.status === STATUSROADLOAD.SCHEDULED)
        setLoading((prevLoading) => ({ ...prevLoading, scheduled: false }));
      if (query.status === STATUSROADLOAD.WAITING)
        setLoading((prevLoading) => ({ ...prevLoading, waiting: false }));
      if (query.status === STATUSROADLOAD.BLOCKED)
        setLoading((prevLoading) => ({ ...prevLoading, blocked: false }));
      if (query.status === STATUSROADLOAD.FINISHED)
        setLoading((prevLoading) => ({ ...prevLoading, finished: false }));
      if (query.status === STATUSROADLOAD.CANCELED)
        setLoading((prevLoading) => ({ ...prevLoading, canceled: false }));
    }
  }, []);

  const fetchScheduled = useCallback(
    async (query?) => {
      try {
        setLoading((prevLoading) => ({ ...prevLoading, scheduled: true }));

        const roadLoadScheduledResponse = await roadLoadService.getRoadLoad({
          ...queryDefault,
          ...query,
          ...queryParams,
          status: STATUSROADLOAD.SCHEDULED,
        });

        setRoadLoad((prevRoadLoad) => ({
          ...prevRoadLoad,
          scheduled: roadLoadScheduledResponse,
        }));
      } catch (err) {
        if (err instanceof AppError) {
          showErrorSnackbar(
            `Nao foi possivel exibir dados do carregamento rodoviario AGENDADO - ${err.message}`
          );
        }
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, scheduled: false }));
      }
    },
    [queryParams]
  );

  const fetchWaiting = useCallback(
    async (query?) => {
      try {
        setLoading((prevLoading) => ({ ...prevLoading, waiting: true }));
        const roadLoadWaitingResponse = await roadLoadService.getRoadLoad({
          ...queryDefault,
          ...query,
          ...queryParams,
          status: STATUSROADLOAD.WAITING,
        });
        setRoadLoad((prevRoadLoad) => ({
          ...prevRoadLoad,
          waiting: roadLoadWaitingResponse,
        }));
      } catch (err) {
        if (err instanceof AppError) {
          showErrorSnackbar(
            `Nao foi possivel exibir dados do carregamento rodoviario NAO programados - ${err.message}`
          );
        }
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, waiting: false }));
      }
    },
    [queryParams]
  );

  const fetchFinished = useCallback(
    async (query?) => {
      try {
        setLoading((prevLoading) => ({ ...prevLoading, finished: true }));
        const roadLoadFinishedResponse = await roadLoadService.getRoadLoad({
          ...queryDefault,
          ...query,
          ...queryParams,
          status: STATUSROADLOAD.FINISHED,
        });
        setRoadLoad((prevRoadLoad) => ({
          ...prevRoadLoad,
          finished: roadLoadFinishedResponse,
        }));
      } catch (err) {
        if (err instanceof AppError) {
          showErrorSnackbar(
            `Nao foi possivel exibir dados do carregamento rodoviario Faturado - ${err.message}`
          );
        }
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, finished: false }));
      }
    },
    [queryParams]
  );

  const fetchBlocked = useCallback(
    async (query?) => {
      try {
        setLoading((prevLoading) => ({ ...prevLoading, blocked: true }));
        const roadLoadBlockedResponse = await roadLoadService.getRoadLoad({
          ...queryDefault,
          ...query,
          ...queryParams,
          status: STATUSROADLOAD.BLOCKED,
        });
        setRoadLoad((prevRoadLoad) => ({
          ...prevRoadLoad,
          blocked: roadLoadBlockedResponse,
        }));
      } catch (err) {
        if (err instanceof AppError) {
          showErrorSnackbar(
            `Nao foi possivel exibir dados do carregamento rodoviario BLOQUEADO - ${err.message}`
          );
        }
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, blocked: false }));
      }
    },
    [queryParams]
  );

  const fetchCanceled = useCallback(
    async (query?) => {
      try {
        setLoading((prevLoading) => ({ ...prevLoading, canceled: true }));
        const roadLoadcanceledReponse = await roadLoadService.getRoadLoad({
          ...queryDefault,
          ...query,
          ...queryParams,
          status: STATUSROADLOAD.CANCELED,
        });

        setRoadLoad((prevRoadLoad) => ({
          ...prevRoadLoad,
          canceled: roadLoadcanceledReponse,
        }));
      } catch (err) {
        if (err instanceof AppError) {
          showErrorSnackbar(
            `Nao foi possivel exibir dados do carregamento rodoviario CANCELADO - ${err.message}`
          );
        }
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, canceled: false }));
      }
    },
    [queryParams]
  );

  const handleFetchCustomers = useCallback(async () => {
    try {
      setLoading((prevLoading) => ({ ...prevLoading, customers: true }));

      const customerResponse = permissionSet.CNPJ.map((item) => {
        return {
          desc: item.companyName,
          id: item.CNPJ,
        };
      }).sort((a, b) => a.desc.localeCompare(b.desc));

      setRoadLoadFilterOptions((prev) => ({
        ...prev,
        customers: customerResponse,
      }));
    } catch (err) {
      if (err instanceof AppError) {
        showErrorSnackbar(
          `Nao foi possivel carregar dados dos clientes - ${err.message}`
        );
      }
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, customers: false }));
    }
  }, []);

  const handleFetchProducts = useCallback(async () => {
    try {
      setLoading((prevLoading) => ({ ...prevLoading, products: true }));

      const productsResponse = await roadLoadService
        .getProducts()
        .then((response) =>
          response.sort((a, b) => a.desc.localeCompare(b.desc))
        );

      setRoadLoadFilterOptions((prev) => ({
        ...prev,
        products: productsResponse,
      }));
    } catch (err) {
      if (err instanceof AppError) {
        showErrorSnackbar(
          `Nao foi possivel carregar dados dos produtos - ${err.message}`
        );
      }
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, products: false }));
    }
  }, []);

  const handleFetchCenters = useCallback(async () => {
    try {
      setLoading((prevLoading) => ({ ...prevLoading, centers: true }));
      const centersResponse = await roadLoadService
        .getCenters()
        .then((response) =>
          response.sort((a, b) => a.desc.localeCompare(b.desc))
        );

      setRoadLoadFilterOptions((prev) => ({
        ...prev,
        centers: centersResponse,
      }));
    } catch (err) {
      if (err instanceof AppError) {
        showErrorSnackbar(
          `Nao foi possivel carregar dados dos centros - ${err.message}`
        );
      }
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, centers: false }));
    }
  }, []);

  const handleFetchDriverByCNPJ = async (cnpj?: string) => {
    try {
      if (onlyDriver) return;

      if (!cnpj) {
        throw new AppError("CNPJ não informado", "error", "500");
      }

      setLoading((prevLoading) => ({ ...prevLoading, drivers: true }));

      const driversResponse = await roadLoadService.getDrivers([cnpj]);

      setRoadLoadFilterOptions((prev) => ({
        ...prev,
        drivers: sortByNameInAlphabeticalOrder(driversResponse),
      }));
    } catch (err) {
      if (err instanceof AppError) {
        showErrorSnackbar(
          `Nao foi possivel carregar dados dos motoristas baseado no CNPJ: ${cnpj} - ${err.message}`
        );
      }
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, drivers: false }));
    }
  };

  const handleFetchDrivers = useCallback(async () => {
    if (onlyDriver) return;

    try {
      const driversResponse = await roadLoadService.getDrivers(
        permissionSet.CNPJ.map((item) => item.CNPJ)
      );

      setRoadLoadFilterOptions((prev) => ({
        ...prev,
        drivers: sortByNameInAlphabeticalOrder(driversResponse),
      }));
    } catch (err) {
      if (err instanceof AppError) {
        showErrorSnackbar(
          `Nao foi possivel carregar dados dos motoristas - ${err.message}`
        );
      }
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, drivers: false }));
    }
  }, [onlyDriver]);

  const handleFetchRoadLoadByFilter = useCallback(
    async (query?: IQueryRoadLoadModel) => {
      try {
        await fetchScheduled(query);
        await fetchWaiting(query);
        await fetchFinished(query);
        await fetchBlocked(query);
        await fetchCanceled(query);
      } catch (err) {
        throw err;
      } finally {
        setQueryParams(query);
      }
    },
    []
  );

  const fetchQueryScheduleAuto = async () => {
    fetchQuerySchedules({
      ...queryDefault,
      status: STATUSROADLOAD.UPCOMING_SCHEDULE,
    });

    fetchQuerySchedules({
      ...queryDefault,
      status: STATUSROADLOAD.SCHEDULED,
    });

    fetchQuerySchedules({
      ...queryDefault,
      status: STATUSROADLOAD.FINISHED,
    });

    fetchQuerySchedules({
      ...queryDefault,
      status: STATUSROADLOAD.WAITING,
    });

    fetchQuerySchedules({
      ...queryDefault,
      status: STATUSROADLOAD.BLOCKED,
    });

    fetchQuerySchedules({
      ...queryDefault,
      status: STATUSROADLOAD.CANCELED,
    });
  };

  const fetchCreateSchedule = useCallback(
    async (data: ICreateSchedule, scheduleID?) => {
      try {
        const checkSourceTab = sessionStorage.getItem("tabSelectedRoadLoad");
        const isEdit = checkSourceTab !== "0" ? true : false; // Note: Verifica se o usuario criou ou se editou um agendamento

        const response = await roadLoadService.createSchedule(
          { ...data },
          scheduleID
        );

        setRoadCreated({ ...response, active: true, hasBeenEdit: isEdit });

        showSuccessSnackbar(`Carregamento rodoviário agendado com sucesso`);

        fetchQueryScheduleAuto();

        // Deve enviar usuario para aba "agendado" apos receber numero de agendamento
        handleTabSelected(1);
      } catch (err) {
        if (err instanceof AppError) {
          snackbar({
            message: `Erro ao agendar carregamento rodoviário. Tentar novamente - ${err.message}`,
            variant: "error",
          });
        }
      } finally {
        setRoadsSelected([]);
      }
    },
    []
  );

  const handleSetQueryParams = useCallback(() => {
    setQueryParams(undefined);
    setValuesFilterFormik(initialValuesFormikDefault);
    fetchQueryScheduleAuto();
  }, [valuesFilterFormik]);

  const fetchVacancies = useCallback(async (orderId, date) => {
    try {
      setLoading((prevLoading) => ({ ...prevLoading, vacancies: true }));

      const roadLoadVacanciesResponse = await roadLoadService.scheduleQueryVacancies(
        { orderId, date }
      );

      setRoadLoadVacancies(roadLoadVacanciesResponse);

      return roadLoadVacanciesResponse;
    } catch (err) {
      if (err instanceof AppError) {
        showErrorSnackbar(`Nao foi possivel exibir horarios - ${err.message}`);
      }
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, vacancies: false }));
    }
  }, []);

  const fetchVacanciesReserve = useCallback(
    async (orderId, date, time, scheduleId?): Promise<boolean> => {
      try {
        setLoading((prevLoading) => ({ ...prevLoading, vacancies: true }));
        await roadLoadService.scheduleReserveVacancies(
          { orderId, date, time },
          scheduleId
        );
        return true;
      } catch (err) {
        if (err instanceof AppError) {
          showErrorSnackbar(
            `Por favor selecione outro horario - ${err.message}`
          );
        }
        return false;
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, vacancies: false }));
      }
    },
    []
  );

  const fetchAvailableDate = useCallback(
    async (orderID: string, scheduleID?: string) => {
      try {
        setLoading((prevLoading) => ({ ...prevLoading, availableDate: true }));

        // Etapa 1 e 2. Buscar dias / horarios disponiveis
        const availableDateResponse = await roadLoadService.scheduleAvailableDate(
          orderID,
          true
        );

        if (Object.keys(availableDateResponse).length === 0) {
          throw new AppError(
            "Nao existem dias / horarios disponiveis",
            "error"
          );
        }

        let firstTime = "";
        let firstDate = "";

        // Etapa 3. Reservar primeiro horario disponivel
        for (const date in availableDateResponse) {
          const availableHours = availableDateResponse[date].availableHours;

          for (const time in availableHours) {
            if (availableHours[time] > 0) {
              firstDate = date;
              firstTime = time;
              await fetchVacanciesReserve(
                orderID,
                firstDate,
                firstTime,
                scheduleID
              );
              break;
            }
          }
          if (firstDate !== "" && firstTime !== "") {
            break;
          }
        }

        if (!firstDate || !firstTime) {
          throw new AppError(
            "Nao foi possivel reservar horarios disponiveis",
            "error"
          );
        }

        const combinedDateTime = new Date(`${firstDate} ${firstTime}`);

        setFirstDateTimeAvailable(combinedDateTime); // registrar primeiro horario disponivel

        setAvailableDate(Object.keys(availableDateResponse)); // registrar lista de dias disponiveis

        const availableHours = availableDateResponse[firstDate]?.availableHours;
        const isValidHours = isValidAvailableHours(availableHours);
        setRoadLoadVacancies(isValidHours ? availableHours : undefined); // registrar lista de horarios disponiveis
      } catch (err) {
        if (err instanceof AppError) {
          showErrorSnackbar(
            `Nao foi possivel exibir horarios disponiveis - ${err.message}`
          );
        }
        setAvailableDate(undefined);
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, availableDate: false }));
      }
    },
    []
  );

  const normalizedRoadLoad = (
    roadLoad?: IRoadLoadAll
  ): IRoadLoadAllNormalized | undefined => {
    if (!roadLoad) return undefined;

    let normalizedRoadLoad = {
      upcomingScheduled: [],
      scheduledNormalized: [],
      waitingNormalized: [],
      finishedNormalized: [],
      blockedNormalized: [],
      canceledNormalized: [],
    } as IRoadLoadAllNormalized;

    normalizedRoadLoad.upcomingScheduled = normalizedRoadLoadGrouped(
      roadLoad?.upcomingSchedule?.content
    );
    normalizedRoadLoad.scheduledNormalized = normalizedRoadLoadGrouped(
      roadLoad?.scheduled?.content
    );
    normalizedRoadLoad.finishedNormalized = normalizedRoadLoadGrouped(
      roadLoad?.finished?.content
    );

    normalizedRoadLoad.canceledNormalized = normalizedRoadLoadGrouped(
      roadLoad?.canceled?.content
    );

    normalizedRoadLoad.waitingNormalized = roadLoad?.waiting?.content?.map(
      (row) => {
        return {
          customerID: row?.tableMetadata.CodCliente ?? "-",
          customer: row?.tableMetadata?.Cliente ?? "-",
          product: row?.tableMetadata?.Produto ?? "-",
          quantity: row?.tableMetadata?.Quantidade.toString() ?? "-",
          order: row?.numberOrder.toString() ?? "-",

          releaseDate: row?.tableMetadata?.Data_solicitada_de_entrega,
          cnpj: row?.tableMetadata?.CNPJ ?? "-",
          filial: row?.tableMetadata?.Filial ?? "-",
          filialName: row?.tableMetadata?.NomeFilial ?? "-",
          schedulable: row?.schedulable,
        };
      }
    ) as IRoadNotLoad[];

    normalizedRoadLoad.blockedNormalized = roadLoad?.blocked?.content?.map(
      (row) => {
        return {
          customerID: row?.tableMetadata.CodCliente ?? "-",
          customer: row?.tableMetadata?.Cliente ?? "-",
          product: row?.tableMetadata?.Produto ?? "-",
          quantity: row?.tableMetadata?.Quantidade.toString() ?? "-",
          truckDriver: row?.tableMetadata?.Motorista ?? "-",
          order: row?.numberOrder?.toString() ?? "-",

          blockedDate: row?.tableMetadata?.Data_solicitada_de_entrega,
          status: row?.schedulable,
          cnpj: row?.tableMetadata?.CNPJ ?? "-",
          filial: row?.tableMetadata?.Filial ?? "-",
          filialName: row?.tableMetadata?.NomeFilial ?? "-",
        };
      }
    ) as IRoadBlockedLoad[];

    return normalizedRoadLoad;
  };

  const normalizedRoadLoadGrouped = (
    content?: IRoadLoadRows[]
  ): IScheduledLoad[] => {
    return content?.map((row) => {
      return {
        Ref: row?.tableMetadata?.Ref ?? "-",
        reschedulable: row?.reschedulable ?? false,
        scheduleGroupID: row?.tableMetadata?.Id ?? "-",
        totalAmountLiters:
          row?.tableMetadata?.Items?.reduce(
            (acc, curr) => acc + (curr?.Quantidade ?? 0),
            0
          ).toString() ?? "",
        customerName: "-",
        truckDriver: row?.tableMetadata?.Motorista ?? "-",
        truckDriverId: row?.tableMetadata?.IdMotorista ?? "-",
        filial: row?.tableMetadata?.Filial ?? "-",
        filialName: row?.tableMetadata?.NomeFilial ?? "-",
        salesOrderQuantity:
          row?.tableMetadata?.Qtde_ordens_de_venda?.toString() ?? "-",
        scheduleDate: row?.tableMetadata?.Data_e_hora_agendamento ?? "-",
        salesOrder:
          row?.tableMetadata?.Items?.map((item) => {
            return {
              customerID: item.CodCliente ?? "-",
              customer: item.Cliente ?? "-",
              cnpj: item.CNPJ ?? "-",
              product: item.Produto ?? "-",
              productID: item.CodProduto ?? "-",
              quantity: item.Quantidade ?? "-",
              order: item.Ordem_de_venda ?? "-",
              deliveryDate: row?.tableMetadata?.Data_agendamento ?? "-",
              status: item?.Status ?? "-",
            };
          }) ?? [],
        reasonCancellation: row?.tableMetadata?.Motivo_cancelamento ?? "-",
        descriptionCancellation:
          row?.tableMetadata?.Descricao_cancelamento ?? "-",
        timezone: row?.tableMetadata?.Timezone ?? "-",
      };
    }) as IScheduledLoad[];
  };

  const fetchDeleteOrder = async (
    scheduleGroupIndex: number,
    orderIndex: number
  ) => {
    try {
      const scheduledNormalized = normalizedRoadLoadGrouped(
        roadLoad?.scheduled?.content
      );

      const scheduleSelected = scheduledNormalized[scheduleGroupIndex];

      if (!scheduleSelected || !scheduleSelected.salesOrder)
        throw new Error("Ordem de venda nao existe");

      const payload: ICreateSchedule = {
        orderId: scheduleSelected.salesOrder
          .filter((_, index) => index !== orderIndex)
          .map((item) => item?.order)
          .join(","),
        date: "",
        time: "",
      };

      await roadLoadService.createSchedule(
        payload,
        scheduleSelected?.scheduleGroupID
      );

      showSuccessSnackbar(`Ordem de venda excluida com sucesso`);

      fetchQuerySchedules({
        ...queryDefault,
        status: STATUSROADLOAD.SCHEDULED,
      });

      fetchQuerySchedules({
        ...queryDefault,
        status: STATUSROADLOAD.WAITING,
      });
    } catch (err) {
      if (err instanceof AppError) {
        showErrorSnackbar(
          `Nao foi possivel excluir ordem de venda - ${err.message}`
        );
      }
    }
  };

  const fetchAddOrder = async (
    scheduleGroupIndex: number,
    roadsSelected: IRoadNotLoad[]
  ) => {
    try {
      const scheduledSelectedNormalized = normalizedRoadLoadGrouped(
        roadLoad?.scheduled?.content
      );
      const schedule = scheduledSelectedNormalized[scheduleGroupIndex];

      if (!schedule || !schedule.salesOrder)
        throw new Error("Ordem de venda nao existe");

      const payload = {
        orderId: [
          ...roadsSelected.map((road) => road.order),
          ...schedule.salesOrder.map((item) => item?.order),
        ].join(","),
        date: "",
        time: "",
      };

      const scheduledNormalized = normalizedRoadLoadGrouped(
        roadLoad?.scheduled?.content
      );
      const scheduleSelected = scheduledNormalized[scheduleGroupIndex];

      await roadLoadService.createSchedule(
        payload,
        scheduleSelected?.scheduleGroupID
      );

      await fetchQuerySchedules({
        ...queryDefault,
        status: STATUSROADLOAD.SCHEDULED,
      });

      await fetchQuerySchedules({
        ...queryDefault,
        status: STATUSROADLOAD.WAITING,
      });

      showSuccessSnackbar(`Ordem de venda adicionada com sucesso`);
    } catch (err) {
      if (err instanceof AppError) {
        showErrorSnackbar(
          `Nao foi possivel adicionar ordem de venda - ${err.message}`
        );
      }
    }
  };

  const fetchDownloadPDF = async (
    refID: string,
    scheduleUUID: string,
    rowIndex: number
  ) => {
    try {
      setLoading((prevLoading) => {
        if (!prevLoading || !prevLoading.pdfReport) return prevLoading;
        const newPdfReport = prevLoading.pdfReport.map(
          (_, index) => index === rowIndex
        );
        return {
          ...prevLoading,
          pdfReport: newPdfReport,
        };
      });
      const response = await roadLoadService.downloadPdfByScheduled(
        scheduleUUID
      );

      const blob = new Blob([response], { type: "application/pdf" });
      downloadPDF(blob, `comprovante_${refID}.pdf`);

      snackbar({
        message: `Comprovante de agendamento n° ${refID} baixado com sucesso!`,
        variant: "success",
      });
    } catch (err) {
      if (err instanceof AppError) {
        snackbar({
          message: `Erro ao baixar comprovante de agendamento n° ${refID} | ${err.message}`,
          variant: "error",
        });
      }
    } finally {
      setLoading((prevLoading) => ({
        ...prevLoading,
        pdfReport: prevLoading?.pdfReport
          ? prevLoading.pdfReport.map(() => false)
          : undefined,
      }));
    }
  };

  const fetchCancelScheduling = async (id: string) => {
    try {
      await roadLoadService.cancelScheduling(id);
      showSuccessSnackbar(`Agendamento cancelado com sucesso`);
      fetchQueryScheduleAuto();
    } catch (err) {
      if (err instanceof AppError) {
        showErrorSnackbar(
          `Não foi possível cancelar agendamento - ${err.message}`
        );
      }
    }
  };

  const showSuccessSnackbar = (message: string) => {
    snackbar({
      message,
      variant: "success",
    });
  };

  const showErrorSnackbar = (message: string) => {
    snackbar({
      message,
      variant: "error",
    });
  };

  const handleRoadLoadMethod: IHandleRoadLoadMethod = {
    fetchScheduled,
    fetchWaiting,
    fetchFinished,
    fetchBlocked,
    fetchVacancies,
    resetVacancies: () => setRoadLoadVacancies(undefined),
    fetchVacanciesReserve,
    fetchCreateSchedule,
    fetchAvailableDate,
    fetchDeleteOrder,
    fetchAddOrder,
    fetchDownloadPDF,
    fetchCancelScheduling,

    fetchAllWithFilter: (query) => handleFetchRoadLoadByFilter(query),
    handleSetTabSelected: (tab: number) => handleTabSelected(tab),
    resetFirstDateTimeAvailable: () => setFirstDateTimeAvailable(undefined),
    resetFilter: () => handleSetQueryParams(),
    resetRoadCreated,
  };

  useEffect(() => {
    const handleRoadLoadFilterOptionsAuto = () => {
      handleFetchCustomers();
      handleFetchProducts();
      handleFetchCenters();
    };

    handleRoadLoadFilterOptionsAuto();
    fetchQueryScheduleAuto();

    return () => {
      sessionStorage.removeItem("roadLoadSelected");
    };
  }, []);

  useEffect(() => {
    sessionStorage.setItem("roadLoad", JSON.stringify(roadLoad));
  }, [roadLoad]);

  return (
    <RoadLoadContext.Provider
      value={{
        valuesFilterFormik,
        handleValueFilterFormik,
        onlyDriverUserID,
        onlyDriver,
        tabSelected,
        loading,
        roadLoad,
        availableDate,
        roadLoadVacancies,
        firstDateTimeAvailable,
        roadLoadFilterOptions,
        handleRoadLoadMethod,
        queryParams,
        normalizedRoadLoad,
        handleFetchDriverByCNPJ,
        roadsSelected,
        roadCreated,
        updateRoadSelected,
      }}
    >
      {children}
    </RoadLoadContext.Provider>
  );
};

const useRoadLoad = () => {
  const context = useContext(RoadLoadContext);
  if (!context) {
    throw new Error(
      "useRoadLoad deve ser utilizado dentro de um RoadLoadProvider"
    );
  }
  return context;
};

export { useRoadLoad, RoadLoadProvider };
