import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import Layout from "../../../components/Layout";
import FormAddUser from "./FormAddUser";

const AddUserPage: React.FC = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <Layout disableToolbar={true} disableToolbarMobile={true}>
      <Typography
        style={{
          color: "#3E3D3D",
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: "bold",
          lineHeight: "normal",
          fontSize: isMobile ? "1.9rem" : "2.5rem",
          paddingTop: "20px",
          paddingLeft: isMobile ? "1.5rem" : "5rem",
        }}
      >
        Cadastrar novo usuário
      </Typography>
      <FormAddUser />
    </Layout>
  );
};

export default AddUserPage;
