import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";

import TextFieldUploadFile from "@pages/User/Nozzle/resource/TextfieldUploadFile";

import { CloseIcon, ColetaWithBackgroungIcon } from "@components/Icons";
import { StyledButton } from "@components/StyledButton";
import TextFieldCustom from "@components/TextFieldCustom";

import { useHomeUserContext } from "@context/HomeUserContext";
import { INozzlesFileRequest, INozzlesResponse } from "@modules/nozzles/models";
import { Tooltip } from "@mui/material";

interface Props {
  children?: React.ReactNode;
  groupSelected: INozzlesResponse;
  handle?: () => void;
  handleNozzlesUpdate: (e) => void;
}

interface FormValues {
  title: string;
  description: string;
  file: File | null;
}

const FormikUpdateImage: React.FC<Props> = ({ children, ...props }) => {
  const { documentList } = useHomeUserContext();
  const [loadSubmit, setLoadSubmit] = useState<boolean>(false);
  let itemSelected = documentList.filter((item) => item.isSelected)[0];
  let documentItemSelect = itemSelected.cnpj;

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const initialValues: FormValues = { title: "", description: "", file: null };

  const validationSchema = yup.object().shape({
    title: yup.string().required("Este campo é obrigatório"),
    description: yup.string().required("Este campo é obrigatório"),
    file: yup
      .mixed()
      .required("Este campo é obrigatório, voce precisa anexar uma arquivo"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        setLoadSubmit(true);
        const params: INozzlesFileRequest = {
          document: documentItemSelect,
          group: props.groupSelected.group,
          file: values.file!,
          title: values.title,
          description: values.description,
        };

        await props.handleNozzlesUpdate(params);
        setLoadSubmit(false);
        actions.resetForm();
      }}
    >
      {(propsformik) => {
        const { title, description, file } = propsformik.values;
        const conditionDisable = !title || !description || !file;
        return (
          <Form>
            <Grid
              container
              justifyContent={"center"}
              style={{
                width: "auto",
                margin: "16px 48px 16px 16px",
                border: "0px solid blue",
              }}
              spacing={4}
            >
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "right",
                  marginTop: 12,
                  marginRight: 12,
                  marginBottom: 0,
                  border: "0px solid red",
                }}
              >
                <Button
                  onClick={props.handle}
                  style={{ padding: 0, margin: 0 }}
                >
                  <CloseIcon />
                </Button>
                <Typography
                  variant={"h6"}
                  style={{
                    textAlign: "left",
                    paddingLeft: 24,
                    fontWeight: 700,
                    margin: 0,
                    lineHeight: 1.5,
                  }}
                >
                  Solicitar alterações nos registros
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  marginTop: 48,
                  padding: 0,
                  border: "0px solid red",
                }}
              >
                <Box style={{ marginTop: 10, marginBottom: 30 }}>
                  <ColetaWithBackgroungIcon
                    style={{ fontSize: "11rem", fill: "none" }}
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  margin: 0,
                  padding: 24,
                  border: "0px solid red",
                }}
              >
                {/* BLOCO CAMPOS DO FORMULARIO - TITULO + DESCRICAO + ARQUIVO */}
                <Grid
                  container
                  style={{
                    padding: 48,
                    paddingTop: 0,
                    border: "0px solid red",
                  }}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <TextFieldCustom
                      name={"title"}
                      label={"Título"}
                      type={"text"}
                      onChange={propsformik.handleChange}
                      onBlur={propsformik.handleBlur}
                      value={propsformik.values.title}
                      error={
                        propsformik.touched.title &&
                        Boolean(propsformik.errors.title)
                      }
                      helperText={
                        propsformik.touched.title && propsformik.errors.title
                      }
                      variant={"outlined"}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldCustom
                      name={"description"}
                      label={"Comentário"}
                      type={"text"}
                      onChange={propsformik.handleChange}
                      onBlur={propsformik.handleBlur}
                      value={propsformik.values.description}
                      error={
                        propsformik.touched.description &&
                        Boolean(propsformik.errors.description)
                      }
                      helperText={
                        propsformik.touched.description &&
                        propsformik.errors.description
                      }
                      multiline
                      minRows={4}
                      variant={"outlined"}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldUploadFile
                      name={"file"}
                      label={"Anexar foto"}
                      value={propsformik.values.file}
                      onChange={propsformik.handleChange}
                      onBlur={propsformik.handleBlur}
                      error={
                        propsformik.touched.file &&
                        Boolean(propsformik.errors.file)
                      }
                      helperText={
                        propsformik.touched.file && propsformik.errors.file
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ marginTop: 24 }}>
                <Grid container style={{ paddingLeft: 48, paddingRight: 48 }}>
                  <Grid
                    item
                    xs={12}
                    style={{ marginLeft: 8, textAlign: "center" }}
                  >
                    <Tooltip
                      title={
                        <Typography>
                          Voce deve inserir um titulo, um anexo e uma imagem
                          antes de enviar
                        </Typography>
                      }
                      open={isHovered && conditionDisable}
                      arrow
                    >
                      <span>
                        {loadSubmit ? (
                          <CircularProgress />
                        ) : (
                          <StyledButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={{
                              color: "white",
                              opacity: conditionDisable ? 0.5 : 1,
                            }}
                            loading={propsformik.isSubmitting}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                          >
                            Enviar
                          </StyledButton>
                        )}
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormikUpdateImage;
