import React from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  ListItem,
} from "@material-ui/core";
import { Link } from "react-router-dom";

interface CardTitleAndIconProps {
  title: string;
  subTitle: string;
  icon: React.ReactNode;
  link: string;
  onClick?: any;
}
const CardTitleAndIcon: React.FC<CardTitleAndIconProps> = ({ ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));
  const isLaptopMini = useMediaQuery(theme.breakpoints.only("md"));

  return (
    <Box
      style={{
        background: "#FFFF",
        borderRadius: "7px",
        boxShadow: "0px 4px 4px rgba(114, 101, 81, 0.10)",
        marginTop: isMobile || isTablet ? "15px" : "20px",
        marginRight: isMobile || isTablet ? "15px" : "20px",
        cursor: "pointer",
      }}
    >
      <ListItem
        button
        component={Link}
        to={props.link}
        onClick={props.onClick}
        style={{
          backgroundColor: "transparent",
          borderRadius: "7px",
        }}
      >
        <Grid
          container
          style={{
            alignItems: "center",
            display: "flex",
            maxHeight: "130px",
            minHeight:
              isTablet || isLaptopMini ? "100px" : isMobile ? "100px" : "120px",
            paddingLeft: "20px",
          }}
        >
          <Grid item xs={2}>
            <Box
              style={{
                width: isMobile
                  ? "36px"
                  : isTablet || isLaptopMini
                  ? "38px"
                  : "54px",
                height: isMobile
                  ? "36px"
                  : isTablet || isLaptopMini
                  ? "38px"
                  : "54px",
              }}
            >
              {props.icon}
            </Box>
          </Grid>
          <Grid
            item
            xs={10}
            style={{ paddingLeft: isLaptopMini ? "8px" : "8px" }}
          >
            <Typography
              style={{
                color: "#D91F05",
                fontFamily: "Montserrat",
                fontWeight: "bold",
                fontSize: isMobile
                  ? "16px"
                  : isTablet
                  ? "16px"
                  : isLaptopMini
                  ? "14px"
                  : "2rem",
              }}
            >
              {props.title}
            </Typography>

            <Typography
              style={{
                color: "#3E3D3D",
                fontWeight: "normal",
                fontSize: isMobile
                  ? "12px"
                  : isTablet
                  ? "11.5px"
                  : isLaptopMini
                  ? "12px"
                  : "1.3rem",
                marginRight: "15px",
              }}
            >
              {props.subTitle}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
    </Box>
  );
};

export default CardTitleAndIcon;
