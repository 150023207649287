import * as Yup from "yup";

const FormQueryValidationSchemaContact = Yup.object().shape({
  full_name: Yup.string().required("Nome é obrigatório"),
  telephone_number: Yup.string().required("Número de telefone é obrigatório"),
  CNPJ: Yup.string().nullable().required("Selecione um CNPJ"),
  recordType: Yup.string().nullable().required("Selecione um tipo de registro"),
  observation: Yup.string().required("Insira o a observação"),
});

export default FormQueryValidationSchemaContact;
