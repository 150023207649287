import React from "react";
import { Grid } from "@mui/material";

import { RoadLoadRedFullIcon } from "@components/Icons";

import { TableHeadTypography, TableBodyTypography } from "../typography";
import { statusLabel } from "./tableUtils";

const TableEmpty: React.FC<{ status: string }> = (props) => {
  const { status } = props;
  return (
    <Grid
      className={"TableEmpty-container"}
      container
      justifyContent={"center"}
      sx={{ border: "0px solid red", backgroundColor: "#fff" }}
    >
      <Grid
        item
        className={"TableEmpty-item"}
        sx={{ py: 2, px: 1.5, textAlign: "center" }}
      >
        <Grid
          container
          justifyContent={"center"}
          gap={2}
          flexDirection={"column"}
        >
          <Grid item>
            <RoadLoadRedFullIcon
              style={{ width: "40px", padding: 0, margin: 0 }}
            />
          </Grid>
          <Grid item sx={{ display: "grid", gap: 0.8 }}>
            <TableHeadTypography>
              Não há carregamento rodoviário {statusLabel(status)} para ser
              exibido
            </TableHeadTypography>
            <TableBodyTypography sx={{ fontSize: { lg: 11 } }}>
              Quando houver novos carregamentos rodoviários, eles aparecerão
              aqui.
            </TableBodyTypography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TableEmpty;
