import { BreadcrumbChild } from "@components/BreadcrumbCustom";
import ROUTES from "@config/routes";

export const breadcrumbDataComission: BreadcrumbChild[] = [
  {
    link: ROUTES.USER_ROUTES.PERFORMANCE_MANAGEMENT,
    label: "Gestão de performance",
  },
  {
    label: "Calculadora de comissão",
  },
];
