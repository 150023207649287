export const ENUM_PERIOD = {
  MONTH: "period=last4Months&timeGranularity=month&formatTime=true",
  WEEK: "period=lastWeeksIn30Days&timeGranularity=week&formatTime=true",
  DAY: "period=last30Days&timeGranularity=day&formatTime=true",
};

export const ENUM_TARGET_VOLUME_SALES = {
  VOLUME: "target[]=sumVolume",
  BILLING: "target[]=sumBilling",
  PRICE: "target[]=avgPrice",
};

export const ENUM_TARGET_PERFORMANCE = {
  SALES_BY_EMPLOYEE:
    "groupBy[]=employee&period=currentMonth&target[]=countTransaction&target[]=sumVolume&target[]=sumBilling&orderValue=totalVolume&orderBy=desc",
  SALES_VOLUME:
    "target[]=sumVolume&target[]=avgPrice&groupBy[]=product&period=currentMonth&orderValue=totalVolume&orderBy=desc",
  LAST_MONTH:
    "target[]=sumVolume&target[]=sumBilling&target[]=avgPrice&target[]=avgVolume&period=last4Months&timeGranularity=month&formatTime=true&orderValue=date&orderBy=desc",
};
