import { ENUM_PERIOD, ENUM_TARGET_VOLUME_SALES } from ".";

export const OptionsSelectSalesVolumePeriod = [
  {
    option: {
      label: "Mensal",
      value: ENUM_PERIOD.MONTH,
    },
  },
  {
    option: {
      label: "Semanal",
      value: ENUM_PERIOD.WEEK,
    },
  },
  {
    option: {
      label: "Visão diária",
      value: ENUM_PERIOD.DAY,
    },
  },
];

export const OptionsSelectSalesVolume = [
  {
    option: {
      label: "Volume de vendas",
      value: ENUM_TARGET_VOLUME_SALES.VOLUME,
    },
  },
  {
    option: {
      label: "Faturamento",
      value: ENUM_TARGET_VOLUME_SALES.BILLING,
    },
  },
  {
    option: {
      label: "Preço médio",
      value: ENUM_TARGET_VOLUME_SALES.PRICE,
    },
  },
];
