// Setores destinatários

export const SetoresDestinatarios = [
  { label: "Comercial", value: "COMMERCIAL" },
  { label: "Fiscal", value: "TAX" },
  { label: "T.I", value: "TI" },
  { label: "Financeiro", value: "FINANCIAL" },
  { label: "Jurídico", value: "LEGAL" },
  { label: "Contábil", value: "ACCOUNTING" },
  { label: "Marketing", value: "MARKETING" },
  { label: "Operações", value: "OPERATIONS" },
];

export const ENUM_TARGET_SECTOR_ENGLISH = {
  COMMERCIAL: "COMMERCIAL",
  TAX: "TAX",
  TI: "TI",
  FINANCIAL: "FINANCIAL",
  LEGAL: "LEGAL",
  ACCOUNTING: "ACCOUNTING",
  MARKETING: "MARKETING",
  OPERATIONS: "OPERATIONS",
};

export const ENUM_TARGET_SECTOR_PTBR = {
  COMMERCIAL: "Comercial",
  TAX: "Fiscal",
  TI: "T.I",
  FINANCIAL: "Financeiro",
  LEGAL: "Jurídico",
  ACCOUNTING: "Contábil",
  MARKETING: "Marketing",
  OPERATIONS: "Operações",
};

export const getTargetSectorInPTBR = (targetSector: string) => {
  switch (targetSector) {
    case ENUM_TARGET_SECTOR_ENGLISH.COMMERCIAL: {
      return ENUM_TARGET_SECTOR_PTBR.COMMERCIAL;
    }
    case ENUM_TARGET_SECTOR_ENGLISH.TAX: {
      return ENUM_TARGET_SECTOR_PTBR.TAX;
    }
    case ENUM_TARGET_SECTOR_ENGLISH.TI: {
      return ENUM_TARGET_SECTOR_PTBR.TI;
    }
    case ENUM_TARGET_SECTOR_ENGLISH.FINANCIAL: {
      return ENUM_TARGET_SECTOR_PTBR.FINANCIAL;
    }
    case ENUM_TARGET_SECTOR_ENGLISH.LEGAL: {
      return ENUM_TARGET_SECTOR_PTBR.LEGAL;
    }
    case ENUM_TARGET_SECTOR_ENGLISH.ACCOUNTING: {
      return ENUM_TARGET_SECTOR_PTBR.ACCOUNTING;
    }
    case ENUM_TARGET_SECTOR_ENGLISH.MARKETING: {
      return ENUM_TARGET_SECTOR_PTBR.MARKETING;
    }
    case ENUM_TARGET_SECTOR_ENGLISH.OPERATIONS: {
      return ENUM_TARGET_SECTOR_PTBR.OPERATIONS;
    }
  }
};

// Tipos de registros
export const TiposDeRegistro = [
  { label: "Reclamação", value: "COMPLAINT" },
  { label: "Elogio", value: "PRAISE" },
  { label: "Sugestão", value: "SUGGESTION" },
];

export const ENUM_TYPE_RECORD_ENGLISH = {
  COMPLAINT: "COMPLAINT",
  PRAISE: "PRAISE",
  SUGGESTION: "SUGGESTION",
};

export const ENUM_TYPE_RECORD_PTBR = {
  COMPLAINT: "Reclamação",
  PRAISE: "Elogio",
  SUGGESTION: "Sugestão",
};

export const getTypeRecordInPTBR = (typeRecord: string) => {
  switch (typeRecord) {
    case ENUM_TYPE_RECORD_ENGLISH.COMPLAINT: {
      return ENUM_TYPE_RECORD_PTBR.COMPLAINT;
    }
    case ENUM_TYPE_RECORD_ENGLISH.PRAISE: {
      return ENUM_TYPE_RECORD_PTBR.PRAISE;
    }
    case ENUM_TYPE_RECORD_ENGLISH.SUGGESTION: {
      return ENUM_TYPE_RECORD_PTBR.SUGGESTION;
    }
  }
};
