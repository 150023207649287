import React from "react";
import {
  Button,
  Container,
  Modal,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { CloseIcon } from "@components/Icons";

interface ModalInactivatedUserProps {
  showModal: () => void;
  handleButtonPrimary: () => void;
  handleButtonContained: () => void;
  title: string;
  bodyDetails?: React.ReactNode;
  descriptionButtonPrimary?: string | undefined;
  descriptionButtonContained?: string | undefined;
  disabledPrimary?: boolean;
  disabledContained?: boolean;
}

const ModalShowDetails = ({
  showModal,
  handleButtonContained,
  handleButtonPrimary,
  title,
  bodyDetails = (
    <>
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum magni
      velit doloribus. Obcaecati, sequi ut. Ad, impedit expedita pariatur, et
      beatae natus maiores debitis qui facilis, sit recusandae deserunt itaque.
    </>
  ),
  descriptionButtonPrimary,
  descriptionButtonContained,
  ...props
}: ModalInactivatedUserProps) => {
  const classes = useStyles();

  return (
    <Modal open={true} onClose={showModal}>
      <Paper className={classes.Paper}>
        <Container className={classes.Container}>
          <Container className={classes.ContainerTitle}>
            <Typography className={classes.TitleInactivated}>
              {title}
            </Typography>
            <CloseIcon className={classes.CloseIcon} onClick={showModal} />
          </Container>
          <br />
          {bodyDetails && (
            <Container className={classes.ContainerBody}>
              {bodyDetails}
            </Container>
          )}
        </Container>

        {descriptionButtonPrimary && descriptionButtonContained && (
          <Container className={classes.ContainerButton}>
            <Button
              className={classes.Button}
              variant="text"
              color="primary"
              disabled={props.disabledPrimary}
              onClick={() => {
                handleButtonPrimary();
              }}
            >
              {descriptionButtonPrimary}
            </Button>
            <Button
              className={classes.Button}
              variant="contained"
              color="primary"
              disabled={props.disabledContained}
              onClick={() => {
                handleButtonContained();
              }}
            >
              {descriptionButtonContained}
            </Button>
          </Container>
        )}
      </Paper>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  Paper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "none",
    height: "auto",
    width: "auto",
    padding: "3rem",
    textAlign: "center",
    position: "fixed",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    borderRadius: "4px",
    [theme.breakpoints.up("xs")]: {
      width: "auto",
      transform: "translate(-50%, -50%)",
    },
    [theme.breakpoints.up("md")]: {
      width: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      width: "auto",
    },
  },
  Container: {
    display: "flex",
    alignItems: "top",
    flexDirection: "column",
    padding: "0",
    justifyContent: "flex-start",
    alignContent: "top",
  },
  ContainerTitle: {
    display: "flex",
    alignItems: "center",
    padding: "0",
    justifyContent: "space-between",
    alignContent: "center",
  },
  ContainerBody: {
    display: "flex",
    alignItems: "top",
    padding: "0",
    alignContent: "left",
  },
  TitleInactivated: {
    fontFamily: "Montserrat",
    fontWeight: 700,
  },
  ContainerButton: {
    display: "flex",
    alignItems: "center",
    padding: "0",
    justifyContent: "flex-end",
    [theme.breakpoints.up("xs")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  CloseIcon: {
    cursor: "pointer",
  },
  Button: {
    textTransform: "none",
    boxShadow: "none",

    [theme.breakpoints.up("xs")]: {
      margin: "1rem 0 0 0",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      margin: "0 0 0 1rem",
      width: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "0 0 0 1rem",
      width: "auto",
    },
  },
}));

export default ModalShowDetails;
