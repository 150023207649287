import DatePickerGeneric from "@components/DatePickerGeneric/DatePickerGeneric";
import {
  AlertDeliveryDateIcon,
  CheckGreenIcon,
  CloseIcon,
  EditIcon,
  EditIconActive,
} from "@components/Icons";
import { ModalGeneral } from "@components/ModalGeneral/ModalGeneral";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  IconButton,
  Modal,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { IUpdateOrderService } from "@modules/orders/models/IUpdateOrderService";
import {
  capitalizeFirstLetterFullString,
  formatCurrency,
  formatCurrency4DecimalsPlace,
} from "@utils/index";
import { format } from "date-fns";
import { useFlags } from "flagsmith/react";
import React, { useState } from "react";
import {
  getFinancialStatusPTBR,
  getStatusColorStatusFinancial,
  isBlockCalendarDelivery,
} from "../utilsOrders/utilsMyOrders";

interface Props {
  open: boolean;
  handleOnClose: () => void;
  dataOrder: any;
}
const ModalTableOrders: React.FC<Props> = ({ ...props }) => {
  const classes = useStyles();
  const [, setOpen] = React.useState(false);

  const iocContext = useIoCContext();
  const [modalDeliveryCalendar, setModalDeliveryCalendar] = useState<boolean>(
    false
  );
  const [modalSucessDelivery, setModalSucessDelivery] = useState<boolean>(
    false
  );
  const [modalErrorDelivery, setModalErrorDelivery] = useState<boolean>(false);
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(null);
  const [, setFreightTypeByLine] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [numberOrderByLine, setNumberOrderByLine] = useState("");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const updateOrder = iocContext.serviceContainer.get<IUpdateOrderService>(
    Types.Orders.IUpdateOrderService
  );

  const featureFlagDeliveryDate = useFlags(["your_orders_update_delivery_date"])
    .your_orders_update_delivery_date.enabled;

  const handleClose = () => {
    setOpen(false);
    props.handleOnClose();
  };

  const handleOpenDeliveryCalendar = () => {
    setModalDeliveryCalendar(true);
  };

  const handleCloseDeliveryCalendar = () => {
    setModalDeliveryCalendar(false);
  };

  const handleCloseModalSuccessDelivery = () => {
    setModalSucessDelivery(false);
  };

  const handleCloseModalErrorDelivery = () => {
    setModalErrorDelivery(false);
  };

  const isButtonDisabled = () => {
    return (
      !selectedDate ||
      (deliveryDate &&
        selectedDate.toISOString() === deliveryDate.toISOString()) ||
      undefined
    );
  };

  const handleUpdateDeliveryDate = async () => {
    try {
      setIsLoading(true);
      const formattedDate = selectedDate
        ? format(selectedDate, "yyyy-MM-dd")
        : "";

      await updateOrder.execute({
        orderId: numberOrderByLine,
        requestedDeliveryDate: formattedDate,
      });

      setModalSucessDelivery(true);
    } catch (error) {
      console.log("🚀 ~ handleUpdateDeliveryDate ~ error:", error);
      setModalErrorDelivery(true);
    } finally {
      setIsLoading(false);
    }
  };

  const ModalRow: React.FC<{
    title: string;
    subtitle: string;
    color?: string;
    fontWeight?: string;
  }> = ({ ...props }) => {
    return (
      <Grid item xs={12} className={classes.bodyGridItem}>
        <Box>
          <Typography className={classes.typographyHead}>
            {props.title}
          </Typography>
        </Box>
        <Box>
          <Typography
            className={classes.typographyBody}
            style={{ color: props.color, fontWeight: props.fontWeight }}
          >
            {props.subtitle}
          </Typography>
        </Box>
      </Grid>
    );
  };

  return (
    <>
      <Modal
        className={classes.modal}
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Grid container spacing={4} className={classes.container}>
            <Grid
              item
              xs={12}
              sm={11}
              md={11}
              lg={11}
              xl={11}
              style={{ paddingTop: 0, paddingBottom: 10 }}
            >
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  border: "0px solid blue",
                }}
              >
                <Button
                  onClick={props.handleOnClose}
                  style={{
                    justifyContent: "end",
                    width: "fit-content",
                    border: "0px solid red",
                    paddingTop: 14,
                  }}
                >
                  <CloseIcon className={classes.closeIcon} />
                </Button>
              </Grid>

              <Grid
                container
                style={{ alignItems: "center", paddingTop: "10px" }}
              >
                <Grid item xs={6}>
                  <Typography className={classes.typographyTitle}>
                    Nº Pedido
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: "#3D3D3D",
                      fontSize: "1.8rem",
                    }}
                  >
                    {props.dataOrder?.numberOrder
                      ? props.dataOrder?.numberOrder
                      : "-"}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography className={classes.typographyTitle}>
                    Status financeiro
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: getStatusColorStatusFinancial(
                        props.dataOrder?.statusAtem
                      ),
                      fontSize: "1.7rem",
                    }}
                  >
                    {props.dataOrder?.statusAtem
                      ? getFinancialStatusPTBR(props.dataOrder?.statusAtem)
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={11} md={11} lg={11} xl={11}>
              <Grid container spacing={2}>
                <Grid container>
                  <Grid item xs={featureFlagDeliveryDate ? 11 : 12}>
                    <ModalRow
                      title="Data da Entrega"
                      subtitle={
                        props.dataOrder?.deliveryDate
                          ? format(
                              new Date(props.dataOrder?.deliveryDate),
                              "dd/MM/yyyy"
                            )
                          : "-"
                      }
                    />
                  </Grid>
                  {/* {featureFlagDeliveryDate && ( */}
                  <Grid item xs={1}>
                    <IconButton
                      color="primary"
                      style={{ padding: "2px" }}
                      onClick={() => {
                        // Descomentar somente o IF se quiser testar fora do horário
                        if (
                          isBlockCalendarDelivery(props.dataOrder?.freightType)
                        ) {
                          handleOpenDeliveryCalendar();

                          setFreightTypeByLine(props.dataOrder?.freightType);
                          setNumberOrderByLine(props.dataOrder?.numberOrder);
                          setDeliveryDate(props.dataOrder?.deliveryDate);
                        }
                      }}
                    >
                      {isBlockCalendarDelivery(props.dataOrder?.freightType) ===
                      false ? (
                        <EditIcon
                          style={{ width: "13px", color: "transparent" }}
                        />
                      ) : (
                        <EditIconActive style={{ width: "13px" }} />
                      )}
                    </IconButton>
                  </Grid>
                  {/* )} */}
                </Grid>

                <ModalRow
                  title="Filial"
                  subtitle={
                    props.dataOrder?.filialID ? props.dataOrder.filialID : "-"
                  }
                />
                <ModalRow
                  title="Razão Social"
                  subtitle={
                    props.dataOrder?.companyName
                      ? capitalizeFirstLetterFullString(
                          props.dataOrder.companyName
                        ).slice(0, 25) + "..."
                      : "-"
                  }
                />
                <ModalRow
                  title="Produto"
                  subtitle={
                    props.dataOrder?.productName && props.dataOrder?.productID
                      ? `${
                          props.dataOrder?.productID
                        } - ${capitalizeFirstLetterFullString(
                          props.dataOrder?.productName
                        )}`
                      : "-"
                  }
                />
                <ModalRow
                  title="Quantidade"
                  subtitle={
                    props.dataOrder?.quantity
                      ? props.dataOrder?.quantity.toLocaleString("pt-BR")
                      : "-"
                  }
                />

                <ModalRow
                  title="Frete"
                  subtitle={props.dataOrder?.freightType || "-"}
                />

                <ModalRow
                  title="Valor Unitário"
                  subtitle={
                    props.dataOrder?.price
                      ? formatCurrency4DecimalsPlace(props.dataOrder?.price)
                      : "-"
                  }
                />

                <ModalRow
                  title="Valor Total"
                  subtitle={
                    props.dataOrder?.totalPrice
                      ? formatCurrency(props.dataOrder?.totalPrice)
                      : "-"
                  }
                />

                <Grid item xs={12} className={classes.bodyGridItemButton}>
                  <Button
                    variant="contained"
                    className={classes.button}
                    style={{ boxShadow: "none", marginTop: "10px" }}
                    onClick={props.handleOnClose}
                  >
                    OK
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      </Modal>

      {modalDeliveryCalendar && (
        <ModalGeneral
          open={modalDeliveryCalendar}
          closeModal={handleCloseDeliveryCalendar}
          titleHeaderModal={"Alterar data da entrega"}
          resetPadding={true}
          width={"100%"}
          height={"420px"}
          labelButtonCancel={"Cancelar"}
          labelButtonSubmit={"Alterar data"}
          onClickSubmit={handleUpdateDeliveryDate}
          hasDialogActions={true}
          isDisabledSubmitted={isButtonDisabled()}
          isLoading={isLoading}
        >
          <Grid xs={12}>
            <Typography
              className={classes.DescriptionChangeDeliveryCalendar}
              style={{
                fontSize: "1.2rem",
              }}
            >
              Selecione a nova data de entrega.
            </Typography>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DatePickerGeneric
              minDate={deliveryDate}
              dateSelected={deliveryDate}
              onDateChange={(newDate) => setSelectedDate(newDate)}
            />
          </Grid>
        </ModalGeneral>
      )}

      {modalSucessDelivery && (
        <ModalGeneral
          open={modalSucessDelivery}
          closeModal={handleCloseModalSuccessDelivery}
          title={"Data de entrega alterada com sucesso."}
          icon={<CheckGreenIcon style={{ width: "80px", height: "80px" }} />}
          width={"100%"}
          height={"320px"}
          hasDialogActions={false}
          resetPadding={false}
        >
          <Typography className={classes.DescriptionSucessDelivery}>
            Você alterou a data de entrega do pedido{" "}
            <span className={classes.SpanSubTitleDelivery}>
              {numberOrderByLine}
            </span>{" "}
            para
            <span className={classes.SpanSubTitleDelivery}>
              {selectedDate
                ? format(selectedDate, "dd/MM/yyyy")
                : "data não definida"}
            </span>
          </Typography>
        </ModalGeneral>
      )}

      {modalErrorDelivery && (
        <ModalGeneral
          open={modalErrorDelivery}
          closeModal={handleCloseModalErrorDelivery}
          title={
            "Ocoreu um erro ao tentar alterar data de agendamento. Tente novamente."
          }
          icon={
            <AlertDeliveryDateIcon style={{ width: "80px", height: "80px" }} />
          }
          width={"100%"}
          height={"360px"}
        >
          <Typography className={classes.DescriptionSucessDelivery}>
            Ocoreu um erro ao tentar alterar data de agendamento. Tente
            novamente
          </Typography>

          {/* {freightTypeByLine === freighTypesEnum.CIF ? (
            <Typography className={classes.DescriptionPermittedTimeDelivery}>
              Horário permitido: <br></br> 00h00 às 13h00
            </Typography>
          ) : (
            <Typography className={classes.DescriptionPermittedTimeDelivery}>
              Horário permitido: <br></br>
              00h00 às 14h00
            </Typography>
          )} */}
        </ModalGeneral>
      )}
    </>
  );
};

export default ModalTableOrders;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    color: theme.palette.text.primary,
    background: "#fff",
    border: "2px solid #fff",
    borderRadius: 16,
    justifyContent: "center",
    alignContent: "baseline",
    [theme.breakpoints.up("xs")]: {
      width: "90%",
      padding: "8px 0px 8px 0px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80%",
      padding: "16px 0px 16px 0px",
    },
    [theme.breakpoints.up("md")]: {
      width: "80%",
      padding: "16px 0px 16px 0px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "70%",
      padding: "24px 0px 24px 0px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "60%",
      padding: "32px 0px 32px 0px",
    },
  },
  typographyTitle: {
    marginBottom: "0.3rem",
    fontWeight: 600,
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "2.6rem",
    },
  },
  bodyGridItem: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("xs")]: {
      padding: "7px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "5px !important",
    },
    [theme.breakpoints.up("md")]: {
      padding: "6px !important",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "9px !important",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "10px !important",
    },
  },
  typographyHead: {
    fontWeight: 700,
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.6rem",
    },
  },
  typographyBody: {
    fontWeight: 500,
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.6rem",
    },
  },
  bodyGridItemButton: {
    padding: "1rem",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primaryLight.main,
    fontWeight: 700,
    padding: "0.6rem 3rem 0.6rem 3rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.0rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.0rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
    },
  },
  closeIcon: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "1rem !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.3rem !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.7rem !important",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.7rem !important",
    },
  },
  DescriptionPermittedTimeDelivery: {
    fontSize: "1.4rem",
    paddingTop: "10px",
    fontFamily: "Montserrat",
    color: "#3E3D3D",
    textAlign: "center",
    fontWeight: "bold",
  },
  SpanSubTitleDelivery: {
    color: "#D91F05",
    fontWeight: "bold",
  },
  DescriptionSucessDelivery: {
    fontSize: "1.6rem",
    paddingTop: "10px",
    fontFamily: "Montserrat",
    color: "#3E3D3D",
    textAlign: "center",
    [theme.breakpoints.only("xs")]: {
      fontSize: "1.3rem",
    },
  },
  DescriptionChangeDeliveryCalendar: {
    fontFamily: "Montserrat",
    color: "#3E3D3D",
    textAlign: "center",
    opacity: "0.3",
  },
  editIcon: {
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
  },
}));
