import { useHomeUserContext } from "@context/HomeUserContext";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { IGetProjectionSalesDTO } from "@modules/projectionSales/dtos/IGetProjectionSalesDTO";
import { IGetProjectionSalesService } from "@modules/projectionSales/models/IGetProjectionSalesService";
import { ISendProjectionSalesService } from "@modules/projectionSales/models/ISendProjectionSalesService";
import { IGetSalesPerformanceDTO } from "@modules/salesPerformance/dtos/IGetSalesPerformanceDTO";
import { IProductDTO } from "@modules/salesPerformance/dtos/IProductDTO";
import { IQuerySalesPerformanceDTO } from "@modules/salesPerformance/dtos/IQuerySalesPerformanceDTO";
import { IGetProductService } from "@modules/salesPerformance/models/IGetProductService";
import { IGetSalesPerformanceService } from "@modules/salesPerformance/models/IGetSalesPerformanceService";
import { IGetSalesVolumeDTO } from "@modules/salesVolume/dto/IGetSalesVolumeDTO";
import { IQuerySalesVolumeDTO } from "@modules/salesVolume/dto/IQuerySalesVolumeDTO";
import { IGetSalesVolumeService } from "@modules/salesVolume/models/IGetSalesVolumeService";
import {
  IGetYourSalesDTO,
  IGetYourSalesIndicatorsDTO,
} from "@modules/yourSales/dtos/IGetYourSalesDTO";
import { IGetYourSalesService } from "@modules/yourSales/models/IGetYourSalesService";
import {
  ENUM_PERIOD,
  ENUM_TARGET_PERFORMANCE,
  ENUM_TARGET_VOLUME_SALES,
} from "@pages/User/PerformanceManagement/Model";
import { transformData } from "@pages/User/PerformanceManagement/UtilsPerfomanceManagement/utilsPerformanceManagment";
import AppError from "@utils/AppError";
import { useSnackbar } from "notistack";
import {
  ISalesPerformanceSelectedDTO,
  ISalesVolumePeriodSelectedDTO,
  ISalesVolumeSelectedDTO,
  initialIndicators,
  initialProjectionSales,
  initialSalesPerformance,
} from "./model/constants";
interface ISelectType {
  label: string;
  value: string;
}

interface PerformanceManagementContext {
  yourSales: IGetYourSalesDTO;
  setYourSales: React.Dispatch<React.SetStateAction<IGetYourSalesDTO>>;
  indicators: IGetYourSalesIndicatorsDTO;
  setIndicators: React.Dispatch<
    React.SetStateAction<IGetYourSalesIndicatorsDTO>
  >;

  projectionSales: IGetProjectionSalesDTO | undefined;
  setProjectionSales: React.Dispatch<
    React.SetStateAction<IGetProjectionSalesDTO>
  >;
  salesPerformance: IGetSalesPerformanceDTO | undefined;
  setSalesPerformance: React.Dispatch<
    React.SetStateAction<IGetSalesPerformanceDTO>
  >;

  isProjectionSalesEmpty: boolean;
  setIsProjectionSalesEmpty: React.Dispatch<React.SetStateAction<boolean>>;

  isSalesPerformanceEmpty: boolean;
  setIsSalesPerformanceEmpty: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSalesVolumeEmpty: React.Dispatch<React.SetStateAction<boolean>>;
  isSalesVolumeEmpty: boolean;
  salesVolume: IGetSalesVolumeDTO[];
  setSalesVolume: React.Dispatch<React.SetStateAction<IGetSalesVolumeDTO[]>>;
  isLoadingSalesVolume: boolean;
  product: IProductDTO[];
  setProduct(data: IProductDTO[]): void;

  selectedProducts: string[];
  setSelectedProducts(product: string[]): void;

  setIsLoadingSalesVolume: React.Dispatch<React.SetStateAction<boolean>>;
  salesVolumePeriodSelected: ISalesVolumePeriodSelectedDTO;
  setSalesVolumePeriodSelected: React.Dispatch<
    React.SetStateAction<ISalesVolumePeriodSelectedDTO>
  >;
  salesVolumeSelected: ISalesVolumeSelectedDTO;
  setSalesVolumeSelected: React.Dispatch<
    React.SetStateAction<ISalesVolumeSelectedDTO>
  >;
  salesPerformanceSelected: ISalesPerformanceSelectedDTO;
  setSalesPerformanceSelected: React.Dispatch<
    React.SetStateAction<ISalesPerformanceSelectedDTO>
  >;

  querySalesVolume: IQuerySalesVolumeDTO;
  setQuerySalesVolume: React.Dispatch<
    React.SetStateAction<IQuerySalesVolumeDTO>
  >;

  getYourSales: () => Promise<void>;
  getYourSalesIndicators: () => Promise<void>;

  getProjectionSales: () => Promise<void>;
  getSalesPerformance: () => Promise<void>;
  getSalesVolume: (query: IQuerySalesVolumeDTO) => Promise<void>;
  handleSubmitSalesProjection: (values: any) => Promise<any>;

  getProduct: () => Promise<void>;

  isLoadingYourSales: boolean;

  isLoadingProjectionSales: boolean;
  isLoadingSalesPerformance: boolean;

  isSubmittingSalesProjection: boolean;

  isLoadingIndicator: boolean;

  isLodingProduct: boolean;
  setIsLodingProduct: (value: boolean) => void;
  showByProduct: boolean;
  setShowByProduct: React.Dispatch<React.SetStateAction<boolean>>;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const PerformanceManagementContext = createContext<
  PerformanceManagementContext
>({} as PerformanceManagementContext);

const PerformanceManagementProvider: React.FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const iocContext = useIoCContext();
  const { documentList } = useHomeUserContext();
  let itemSelected = documentList.filter((item) => item.isSelected)[0];
  let documentItemSelect = itemSelected?.cnpj;
  const [yourSales, setYourSales] = useState<IGetYourSalesDTO>({});
  const [indicators, setIndicators] = useState<IGetYourSalesIndicatorsDTO>(
    initialIndicators
  );
  const [projectionSales, setProjectionSales] = useState<
    IGetProjectionSalesDTO
  >(initialProjectionSales);
  const [salesPerformance, setSalesPerformance] = useState<
    IGetSalesPerformanceDTO
  >(initialSalesPerformance);
  const [product, setProduct] = useState<IProductDTO[]>([]);

  const [isLodingProduct, setIsLodingProduct] = useState<boolean>(false);

  const [salesVolume, setSalesVolume] = useState<IGetSalesVolumeDTO[]>([]);

  const [isLoadingSalesVolume, setIsLoadingSalesVolume] = useState(true);

  const [isLoadingYourSales, setIsLoadingYourSales] = useState(true);
  const [isLoadingIndicator, setIsLoadingIndicator] = useState(true);
  const [isLoadingProjectionSales, setIsLoadingProjectionSales] = useState(
    true
  );
  const [isLoadingSalesPerformance, setIsLoadingSalesPerformance] = useState(
    true
  );
  const [isProjectionSalesEmpty, setIsProjectionSalesEmpty] = useState(false);
  const [isSalesPerformanceEmpty, setIsSalesPerformanceEmpty] = useState(false);
  const [isSalesVolumeEmpty, setIsSalesVolumeEmpty] = useState(false);
  const [
    isSubmittingSalesProjection,
    setIsSubmittingSalesProjection,
  ] = useState(false);

  const [showByProduct, setShowByProduct] = useState<boolean>(true);

  const [salesVolumePeriodSelected, setSalesVolumePeriodSelected] = useState<
    ISalesVolumePeriodSelectedDTO
  >({
    label: "Mensal",
    value: ENUM_PERIOD.MONTH,
  });

  const OptionsProductFormated: ISelectType[] = transformData(product);

  const [selectedProducts, setSelectedProducts] = useState<string[]>(
    OptionsProductFormated.map((option) => option.value)
  );

  useEffect(() => {
    if (product.length) {
      // Define todos os produtos como selecionados na inicialização assim que consumir a API de produtos
      setSelectedProducts(OptionsProductFormated.map((option) => option.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const [salesVolumeSelected, setSalesVolumeSelected] = useState<
    ISalesVolumeSelectedDTO
  >({
    label: "Volume de vendas",
    value: ENUM_TARGET_VOLUME_SALES.VOLUME,
  });

  const [salesPerformanceSelected, setSalesPerformanceSelected] = useState<
    ISalesPerformanceSelectedDTO
  >({
    label: "Vendas por frentistas",
    value: ENUM_TARGET_PERFORMANCE.SALES_BY_EMPLOYEE,
  });

  const [querySalesVolume, setQuerySalesVolume] = useState<
    IQuerySalesVolumeDTO
  >({
    period: salesVolumePeriodSelected.value,
    salesVolume: salesVolumeSelected.value,
    productIds: selectedProducts ? selectedProducts : [""],
  });

  const [querySalesPerformance, setQuerySalesPerformance] = useState<
    IQuerySalesPerformanceDTO
  >({
    target: salesPerformanceSelected.value,
  });

  const getYourSalesService = iocContext.serviceContainer.get<
    IGetYourSalesService
  >(Types.YourSales.IGetYourSalesService);

  const getProjectionSalesService = iocContext.serviceContainer.get<
    IGetProjectionSalesService
  >(Types.ProjectionSales.IGetProjectionSalesService);

  const getSalesPerformanceService = iocContext.serviceContainer.get<
    IGetSalesPerformanceService
  >(Types.SalesPerformance.IGetSalesPerformanceService);

  const getSalesVolumeService = iocContext.serviceContainer.get<
    IGetSalesVolumeService
  >(Types.SalesVolume.IGetSalesVolumeService);

  const sendProjectionSalesService = iocContext.serviceContainer.get<
    ISendProjectionSalesService
  >(Types.ProjectionSales.ISendProjectionSalesService);

  const getProductService = iocContext.serviceContainer.get<IGetProductService>(
    Types.SalesPerformance.IGetProductService
  );

  const getYourSales = useCallback(async () => {
    try {
      setIsLoadingYourSales(true);
      const data = await getYourSalesService.execute(documentItemSelect);
      setYourSales(data ? data : {});
    } catch (error) {
      enqueueSnackbar(
        "Não foi possível mostrar volume de vendas e faturameto do mês para esse CNPJ",
        {
          variant: "error",
        }
      );
    } finally {
      setIsLoadingYourSales(false);
    }
  }, [getYourSalesService, documentItemSelect, enqueueSnackbar]);

  const getYourSalesIndicators = useCallback(async () => {
    try {
      setIsLoadingIndicator(true);
      const data = await getYourSalesService.getIndicators(documentItemSelect);
      setIndicators(data);
    } catch (error) {
      setIndicators(initialIndicators);
      if (error instanceof AppError) {
        enqueueSnackbar(error.message, {
          variant: "warning",
        });
      } else {
        enqueueSnackbar(
          "Não foi possível mostrar horário de pico, venda diária e projeção de venda para esse CNPJ",
          {
            variant: "error",
          }
        );
      }
    } finally {
      setIsLoadingIndicator(false);
    }
  }, [getYourSalesService, documentItemSelect, enqueueSnackbar]);

  const getProjectionSales = useCallback(async () => {
    try {
      setIsLoadingProjectionSales(true);
      const data = await getProjectionSalesService.execute(documentItemSelect);
      setProjectionSales(data);
      setIsProjectionSalesEmpty(false);
    } catch (error) {
      setProjectionSales(initialProjectionSales);
      setIsProjectionSalesEmpty(true);
    } finally {
      setIsLoadingProjectionSales(false);
    }
  }, [getProjectionSalesService, documentItemSelect]);

  const getSalesPerformance = useCallback(async () => {
    try {
      setIsLoadingSalesPerformance(true);
      if (querySalesPerformance.cnpj !== undefined) {
        const data = await getSalesPerformanceService.execute(
          querySalesPerformance
        );
        setSalesPerformance(data);
        setIsSalesPerformanceEmpty(false);
      }
    } catch (error) {
      const newSalesPerformance = {
        ...initialSalesPerformance,
      };
      setSalesPerformance(newSalesPerformance);
      setIsSalesPerformanceEmpty(true);
    } finally {
      setIsLoadingSalesPerformance(false);
    }
  }, [getSalesPerformanceService, querySalesPerformance]);

  const getSalesVolume = useCallback(async () => {
    try {
      setIsSalesVolumeEmpty(false);
      setIsLoadingSalesVolume(true);

      if (querySalesVolume.cnpj !== undefined) {
        const data = await getSalesVolumeService.execute(
          querySalesVolume,
          showByProduct
        );
        setSalesVolume(data);
      }
    } catch (error) {
      setSalesVolume([]);
      setIsSalesVolumeEmpty(true);
    } finally {
      setIsLoadingSalesVolume(false);
    }
  }, [getSalesVolumeService, querySalesVolume, showByProduct]);

  const handleSubmitSalesProjection = async (values) => {
    try {
      setIsSubmittingSalesProjection(true);
      const response = await sendProjectionSalesService.execute({
        value: values.value,
        document: values.document,
      });
      enqueueSnackbar("Meta atualizada com sucesso!", {
        variant: "success",
      });
      return response;
    } catch (error) {
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, { variant: error.variant });
      }
      enqueueSnackbar("Não foi possível enviar meta", {
        variant: "error",
      });
    } finally {
      setIsSubmittingSalesProjection(false);
      getProjectionSales();
    }
  };

  const getProduct = useCallback(async () => {
    try {
      const responseProduct = await getProductService.execute();
      setProduct(responseProduct);
      setIsLodingProduct(true);
    } catch (error) {
    } finally {
      setIsLodingProduct(false);
    }
  }, [getProductService]);

  useEffect(() => {
    setQuerySalesVolume((prevQuery) => ({
      ...prevQuery,
      salesVolume: salesVolumePeriodSelected.value,
      period: salesVolumeSelected.value,
      cnpj: documentItemSelect,
      productIds: selectedProducts,
    }));
  }, [
    salesVolumePeriodSelected,
    salesVolumeSelected,
    setQuerySalesVolume,
    documentItemSelect,
    selectedProducts,
  ]);

  useEffect(() => {
    setQuerySalesPerformance((prevQuery) => ({
      ...prevQuery,
      target: salesPerformanceSelected.value,
      cnpj: documentItemSelect,
    }));
  }, [salesPerformanceSelected, setQuerySalesPerformance, documentItemSelect]);

  useEffect(() => {
    getSalesVolume();
  }, [getSalesVolume]);

  useEffect(() => {
    getSalesPerformance();
  }, [getSalesPerformance]);

  useEffect(() => {
    getYourSales();
    getYourSalesIndicators();
    getProjectionSales();
    getProduct();
  }, [getYourSales, getYourSalesIndicators, getProjectionSales, getProduct]);

  return (
    <PerformanceManagementContext.Provider
      value={{
        yourSales,
        setYourSales,
        indicators,
        setIndicators,
        projectionSales,
        setProjectionSales,
        salesPerformance,
        isProjectionSalesEmpty,
        setIsProjectionSalesEmpty,
        isSalesPerformanceEmpty,
        isSalesVolumeEmpty,
        setIsSalesVolumeEmpty,
        setIsSalesPerformanceEmpty,
        salesVolume,
        product,
        setSalesVolume,
        isLoadingSalesVolume,
        setIsLoadingSalesVolume,
        getYourSales,
        getYourSalesIndicators,
        getProjectionSales,
        getSalesPerformance,
        setSalesPerformance,
        setProduct,
        getProduct,
        getSalesVolume,
        isLoadingYourSales,
        isLoadingProjectionSales,
        isLoadingSalesPerformance,
        isLoadingIndicator,
        isSubmittingSalesProjection,
        salesVolumePeriodSelected,
        setSalesVolumePeriodSelected,
        salesPerformanceSelected,
        setSalesPerformanceSelected,
        salesVolumeSelected,
        setSalesVolumeSelected,
        querySalesVolume,
        setQuerySalesVolume,
        handleSubmitSalesProjection,
        isLodingProduct,
        setIsLodingProduct,
        selectedProducts,
        setSelectedProducts,
        showByProduct,
        setShowByProduct,
      }}
    >
      {children}
    </PerformanceManagementContext.Provider>
  );
};

const usePerformanceManagement = (): PerformanceManagementContext => {
  const context = useContext(PerformanceManagementContext);
  if (!Object.values(context).length) {
    throw new Error(
      "usePerformanceManagement deve ser utilizado dentro de um  PerformanceManagementProvider"
    );
  }
  return context;
};

export { PerformanceManagementProvider, usePerformanceManagement };
