import * as Yup from "yup";
import { FILTER_BY_TYPE, SITUATION_TYPE } from "./utils/ENUMS_TITLES";

const FormQueryValidationSchema = Yup.object().shape({
  CNPJ: Yup.array().of(Yup.string()).required("Selecione um CNPJ").nullable(),
  situation: Yup.string()
    .oneOf(
      [
        SITUATION_TYPE.ALL,
        SITUATION_TYPE.CLOSED,
        SITUATION_TYPE.OPEN,
        SITUATION_TYPE.DUE_DATE,
      ],
      "Selecione uma das 3 opções"
    )
    .required("Selecione uma situação"),
  filterBy: Yup.string()
    .oneOf(
      [FILTER_BY_TYPE.DUE_DATE, FILTER_BY_TYPE.DOCUMENT_DATE],
      "Selecione uma das 2 opções"
    )
    .required("Selecione uma ordenação"),
  dateRange: Yup.array()
    .of(Yup.date().typeError("Selecione uma data válida"))
    .test(
      "start-date-before-end-date",
      "A data final não pode ser anterior à inicial",
      function (value) {
        if (!value || value.length !== 2) {
          return false; // Invalid array length
        }
        const [startDate, endDate] = value;
        return !startDate || !endDate || startDate <= endDate;
      }
    )
    .required("Selecione a data de início e a data final"),
});

export default FormQueryValidationSchema;
