import React from "react";

import { Typography, AppBar, Tabs, Tab, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import TableEmployeeUnregistered from "@pages/User/Employee/resource/TableEmployeeUnregistered";
import TableEmployeeInactive from "@pages/User/Employee/resource/TableEmployeeInactive";
import { useEmployee } from "@pages/User/Employee/context";

import RowPerPageOptions from "@components/TableGenericPagination/RowPerPageOptions";
import TableEmployeeActive from "@pages/User/Employee/resource/TableEmployeeActive";
import TabPanel from "@components/TabPanel";

interface Props {
  children?: React.ReactNode;
}

const EmployeeView: React.FC<Props> = ({ children, ...props }) => {
  const classes = useStyles();

  const menuList = ["Ativos", "Inativos", "Não cadastrados"];

  const {
    employeeActive,
    employeeInactive,
    employeeUnregistered,
    hasLoading,
    handleOnClickTurnOffEmployee,
  } = useEmployee();

  const defaultRowPerPage = 10;
  const defaultRowPerPageOptions = [10, 25, 50, 75, 100];
  const [rowPerPageControl, setRowPerPageControl] = React.useState(
    defaultRowPerPage
  );

  const [indexTabs, setIndexTabs] = React.useState(0);
  const handleTabChange = (event, value) => {
    setIndexTabs(value);
  };

  return (
    <Grid container className={classes.container}>
      {/*TITULO*/}
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.title}>
          Funcionários
        </Typography>
      </Grid>

      {/*TABS*/}
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <AppBar className={classes.appBar} position="static">
              <Tabs
                value={indexTabs}
                onChange={handleTabChange}
                classes={{
                  root: classes.tabsroot,
                  flexContainer: "flexContainer",
                  indicator: "indicator",
                }}
                variant="fullWidth"
                TabIndicatorProps={{ children: <span /> }}
                centered
              >
                {menuList.map((item, index) => (
                  <Tab
                    key={index}
                    label={item}
                    aria-controls={`atem-${index}`}
                  />
                ))}
              </Tabs>
            </AppBar>
          </Grid>

          <Grid
            item
            style={{
              display: "none",
              justifyContent: "right",
              border: "0px solid red",
            }}
          >
            <RowPerPageOptions
              RowPerPage={rowPerPageControl}
              rowPerPageControl={rowPerPageControl}
              RowPerPageOptionsDefault={defaultRowPerPageOptions}
              onRowPerPageChange={(e) => setRowPerPageControl(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* ---------------------------- */}
      {/* TABELAS Funcionarios ativos, inativos e nao registrados */}
      <Grid item xs={12}>
        {/* TABELA Funcionarios ativos */}
        <TabPanel value={indexTabs} index={0}>
          <TableEmployeeActive
            data={employeeActive}
            rowsPerPage={rowPerPageControl}
            onRowsPerPageChange={(e) => setRowPerPageControl(e.target.value)}
            onClick={(e) => handleOnClickTurnOffEmployee(e)}
            labelButton="Inativar"
            hasLoading={hasLoading}
          />
        </TabPanel>

        {/* TABELA Funcionarios inativos */}
        <TabPanel value={indexTabs} index={1}>
          <TableEmployeeInactive
            data={employeeInactive}
            rowsPerPage={rowPerPageControl}
            onRowsPerPageChange={(e) => setRowPerPageControl(e.target.value)}
            hasLoading={hasLoading}
          />
        </TabPanel>

        {/* TABELA Funcionarios nao registrados */}
        <TabPanel value={indexTabs} index={2}>
          <TableEmployeeUnregistered
            data={employeeUnregistered}
            rowsPerPage={rowPerPageControl}
            onRowsPerPageChange={(e) => setRowPerPageControl(e.target.value)}
            labelButton="Cadastrar"
            hasDrawer={true}
            onClickCreateEmployee={() => {}}
            hasLoading={hasLoading}
          />
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default EmployeeView;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: "95%",
      minHeight: "100%",
      marginLeft: "4rem",
      backgroundColor: "#F5F5F5",
      paddingRight: "2rem",
    },
    title: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "59px",
      color: "#3E3D3D",
      paddingBottom: "1.4rem",
    },
    appBar: {
      //////////////////////// MuiPaper-root MuiAppBar-root
      background: "none",
      boxShadow: "none",
      display: "flex",
      flexDirection: "initial",
      justifyContent: "space-between",
      height: "6rem",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 290,
    },
    select: {
      borderRadius: 10,
      "& .MuiSelect-icon": {
        color: "#D91F05",
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "initial",
      },
    },
    tabsroot: {
      "& .MuiTab-root": {
        padding: 0,
        minWidth: "fit-content", /// Aplica tamanho do box que envolve o texto do tamanho do texto
        marginRight: "5rem", /////// Aplica espacamento entre a label [Ativo, Inativo e Nao registrado]
      },
      "& .MuiTab-wrapper": {
        ////// Edita campo texpo das tabs [Ativo, Inativo e Nao registrado]
        textTransform: "none", ///// Remove funcacao UpperCase (Letras MAIUSCULAS) Default browser
        fontSize: "1.4rem", //////// Define o tamanho da Label
        fontWeight: "700", ///////// Define espessura da letra
        color: theme.palette.primary.highlightedplus,
        opacity: 1,
      },
      "& .Mui-selected": {
        color: `${theme.palette.primary.main} !important`, // Define a cor do item Selecionado
      },
      "& .indicator": {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        color: theme.palette.primary.main,
        marginBottom: "2rem", //////////////////////// Aproxima a distancia entre a borda e a label
        "& > span": {
          backgroundColor: theme.palette.primary.main,
          maxWidth: 50, /////////////////////////////// Define largura maxima estatica do indicador
          height: 3, ////////////////////////////////// Define espessura da borda do item selecionado
          width: "inherit",
          borderRadius: 16,
        },
      },
      "& .flexContainer": {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        color: "#000", // Define cor padrao da cor - label
      },
    },
  })
);
