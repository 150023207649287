import {
  createStyles,
  FormControl,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  Typography,
} from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect } from "react";
import { compartmentsAvailable } from "../../../../config/appConfig";
import { RemoveCircle, AddCircle } from "@material-ui/icons";
import {
  IFormCreateOrderService,
  SimulationProduct,
} from "../../../../utils/interfaces";
import { TrashIcon } from "@components/Icons";
import { capitalizeFirstLetterFullString } from "@utils/index";

interface TableProductsProps {
  slots: number;
}

interface TableProductRowProps {
  product: SimulationProduct;
  remove: (key: string) => void;
  key: any;
  id: number;
  slots: number;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paperTable: {
      width: "100%",
      marginTop: "5rem",
      overflow: "hidden",
      overflowX: "scroll",

      "&::-webkit-scrollbar": {
        background: theme.palette.grey[300],
        height: "0.7rem",
        borderRadius: ".4rem",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.grey[900],
        borderRadius: ".4rem",
      },
    },
    itemSelect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    titleColumn: {
      fontWeight: 700,
    },
    tableRow: {
      marginTop: "2rem",
    },
    grid: { height: "90px", marginTop: 16, marginBottom: 16 },
    gridColumn: { height: "60px", marginTop: 16, marginBottom: 16 },
    gridItem: {
      display: "flex",
      alignItems: "center",
      color: "#3E3D3D",
      fontFamily: "DM Sans",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "20px",
    },
    gridItemColumn: {
      color: "#3E3D3D",
      fontFamily: "DM Sans",
      fontSize: "16px",
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
    },

    formControl: {
      borderRadius: "8px",
      width: "408px",
      height: "60px",
    },
    customSelect: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      marginTop: "0",
      height: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
      paddingLeft: "14px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(114, 101, 81, 0.20)",
      },
      "& .MuiSvgIcon-root": {
        fill: "#D91F05",
      },
      borderRadius: "8px",
    },
    paperGrid: {
      borderRadius: "8px",
      padding: "0px 57px 0px 32px",
      boxShadow: `0px 4px 4px rgba(115, 97, 97, 0.10)`,
    },
    titleSearch: {
      color: "#3E3D3D",
      textAlign: "center",
      fontFamily: "DM Sans",
      fontSize: "16px",
      fontWeight: 400,
    },
  })
);

const TableProductRow: React.FC<TableProductRowProps> = ({
  product,
  remove,
  id,
  slots,
}) => {
  const { values, setFieldTouched, setFieldValue } = useFormikContext<
    IFormCreateOrderService
  >();
  const classes = useStyles();
  const incrementArrow = () => modifyArrow("NEXT");
  let decrementArrow = () => modifyArrow("PREVIOUS");

  const setCompartment = (value: string) => {
    const products = values.products.map((productID) => {
      if (product.uuid === productID.uuid) {
        product.compartment = value;
      }
      return productID;
    });

    setFieldValue("products", products);
  };

  const modifyArrow = (increment: "NEXT" | "PREVIOUS") => {
    const products = values.products.map((currentProducts) => {
      if (product.uuid === currentProducts.uuid) {
        const arrowCalc =
          ((product.current_arrow || 0) + (increment === "NEXT" ? 1 : -1)) %
          product.arrows!.length;
        product.current_arrow =
          arrowCalc >= 0 ? arrowCalc : product.arrows!.length - 1;
        product.quantity = (product.arrows as number[])[product.current_arrow];
      }
      return currentProducts;
    });

    setFieldValue("products", products);
  };

  return (
    <Paper className={classes.paperGrid}>
      <Grid xs={12} style={{ display: "flex" }} className={classes.grid}>
        <Grid
          item
          xs={2}
          className={classes.gridItem}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {product.id}
        </Grid>
        <Grid item xs={4} className={classes.gridItem}>
          {capitalizeFirstLetterFullString(product.description)}
        </Grid>

        <Grid
          item
          xs={2}
          className={classes.gridItem}
          style={{
            justifyContent: "center",
            paddingRight: "1rem",
          }}
        >
          <FormControl
            fullWidth
            className={classes.formControl}
            variant="outlined"
          >
            <Select
              className={classes.customSelect}
              name="compartment"
              id="compartment"
              displayEmpty={true}
              onBlur={() => setFieldTouched("compartment", true)}
              onChange={(e) =>
                setCompartment(
                  (e as { target: { value: string } }).target.value
                )
              }
              value={product.id ? product.compartment : ""}
              IconComponent={ExpandMoreIcon}
            >
              <MenuItem disabled value="">
                <em>Selecione o compartimento</em>
              </MenuItem>
              {compartmentsAvailable.slice(0, slots).map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={3}
          className={classes.gridItem}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <FormControl>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                backgroundColor: "white",
                border: "none",
                fontSize: "1.6rem",
                color: "#626166",
                borderRadius: "15px",
                padding: "1.6rem 2.4rem",
              }}
            >
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={decrementArrow}
              >
                <RemoveCircle />
              </IconButton>

              <Field
                value={`${product.quantity
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`}
                onFocus={(e: {
                  target: {
                    value: string;
                    setSelectionRange: (i: number, o: number) => void;
                  };
                }) => e.target.setSelectionRange(0, e.target.value.length)}
                onClick={(e: {
                  currentTarget: {
                    value: string;
                    setSelectionRange: (i: number, o: number) => void;
                  };
                }) =>
                  e.currentTarget.setSelectionRange(
                    0,
                    e.currentTarget.value.length
                  )
                }
                style={{
                  outline: "none",
                  fontSize: "1.6rem",
                  fontFamily: "Open Sans, sans-serif",
                  fontWeight: 400,
                  color: "#626166",
                  textAlign: "center",
                  width: 120,
                }}
                placeholder=""
                disabled={true}
              />
              <IconButton
                color="primary"
                component="label"
                onClick={incrementArrow}
              >
                <AddCircle />
              </IconButton>
            </div>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={1}
          className={classes.gridItem}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <IconButton
            color="primary"
            component="label"
            onClick={() => remove(product.uuid as string)}
          >
            <TrashIcon style={{ fontSize: "2rem", fill: "none" }} />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

const TableProducts: React.FC<TableProductsProps> = ({ slots }) => {
  const { values, setFieldValue } = useFormikContext<IFormCreateOrderService>();

  const classes = useStyles();

  const removeProduct = (key: string) => {
    let products = values.products.filter((ele) => ele.uuid !== key);
    setFieldValue("products", products);
  };

  useEffect(() => {
    setFieldValue("products", []);
  }, [setFieldValue]);

  return (
    <Table>
      <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <Typography className={classes.titleSearch}>
          Produtos para a simulação de carregamento
        </Typography>
      </Grid>

      <Paper className={classes.paperGrid}>
        <Grid
          xs={12}
          style={{ display: "flex", fontWeight: "bold" }}
          className={classes.gridColumn}
        >
          <Grid
            item
            xs={2}
            className={classes.gridItemColumn}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Código
          </Grid>
          <Grid item xs={4} className={classes.gridItemColumn}>
            Descrição
          </Grid>
          <Grid
            item
            xs={2}
            className={classes.gridItemColumn}
            style={{
              justifyContent: "center",
              paddingRight: "1rem",
            }}
          >
            Compartilhamento
          </Grid>
          <Grid
            item
            xs={3}
            className={classes.gridItemColumn}
            style={{
              justifyContent: "center",
            }}
          >
            Quantidade(LT)
          </Grid>
          <Grid
            item
            xs={1}
            className={classes.gridItemColumn}
            style={{
              justifyContent: "center",
            }}
          ></Grid>
        </Grid>
      </Paper>

      <TableBody>
        {values.products.map((product, idx) => {
          return (
            <TableProductRow
              slots={slots}
              key={product.uuid}
              id={idx}
              product={product}
              remove={removeProduct}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

export { TableProducts };
