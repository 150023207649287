import React from "react";
import Layout from "../../../components/Layout";
import Title from "../../../components/Title";
import FormEditUser from "./FormEditUser";

const EditUserPage: React.FC = () => {
  return (
    <Layout>
      <div style={{ gridArea: "content" }}>
        <Title title="Editar Usuário" />
        <FormEditUser />
      </div>
    </Layout>
  );
};

export default EditUserPage;
