import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { getValueInLocalStorage } from "@utils/localStorageUtils";
import { IGetOneReclamationUserService } from "../models/IGetOneReclamationUserService";
import { IDataOneReclamationUser } from "../dtos/IDataOneReclamationUser";

@injectable()
export class GetOneReclamationUserService
  implements IGetOneReclamationUserService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute() {
    return await this.httpInstance.get<IDataOneReclamationUser>(
      "/reclamation/" + getValueInLocalStorage("reclamationClicked")
    );
  }
}
