import React, { useMemo, useState } from "react";

import Actions from "./actions/actions";
import UserReducer from "./reducer";
import State from "./State";

import { useAuth } from "@context/auth/AuthContext";

type Dispatch = (action: Actions) => void;

const UserStateContext = React.createContext<State | undefined>(undefined);
const UserDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (!context) {
    throw Error("useUserState deve ser utilizado dentro de um UserProvider");
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (!context) {
    throw Error("useUserDispatch deve ser utilizado dentro de um UserProvider");
  }
  return context;
}

interface UserData {
  userID: string;
  refreshToken: string;
  firstName: string;
  fullName: string;
  email: string;
  roles: string[];
  listCNPJ: {
    CNPJ: string;
    companyName: string;
  }[];
  adminMode: boolean;
  token: string;
}

const UserProvider: React.FC = ({ children }) => {
  const currentAuthState = useAuth();
  const userData: UserData = {
    userID: currentAuthState?.userID || "null",
    firstName: currentAuthState?.firstName || "null",
    email: currentAuthState?.email || "null",
    fullName: currentAuthState?.fullName || "null",
    roles: currentAuthState?.roles || [],
    listCNPJ: currentAuthState?.listCNPJ ? currentAuthState.listCNPJ : [],
    adminMode: Boolean(currentAuthState?.adminMode),
    token: currentAuthState?.token || "null",
    refreshToken: currentAuthState?.refreshToken || "null",
  };

  const name = useMemo(() => {
    return userData.firstName.split(" ").length > 2
      ? userData.firstName.split(" ").slice(0, 1).join(" ")
      : userData.fullName.split(" ").slice(0, 1).join(" ");
  }, [userData.firstName, userData.fullName]);

  const [token] = useState<string>(userData.token);

  const [state, dispatch] = React.useReducer(UserReducer, {
    listCNPJ: userData.listCNPJ,
    username: name,
    userID: userData.userID,
    email: userData.email,
    roles: userData.roles,
    adminMode: userData.adminMode,
    token,
    refreshToken: userData.refreshToken,
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {state.token && children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

export { UserProvider, useUserDispatch, useUserState };
