import { Types } from "@ioc/types";
import * as IHttpService from "@modules/infra/http/models/IHttpService";
import { inject, injectable, named } from "inversify";
import { IProductDTO } from "../dtos/IProductDTO";
import { IGetProductService } from "../models/IGetProductService";

@injectable()
export class GetProductService implements IGetProductService {
  @inject(Types.IHttpService)
  @named("pump")
  private httpInstance!: IHttpService.IHttpService;

  public async execute(): Promise<IProductDTO[]> {
    try {
      const responseProduct = await this.httpInstance.get<IProductDTO[]>(
        "/product"
      );

      return responseProduct;
    } catch (err) {
      throw err;
    }
  }

  public setAuthorization(token: string): void {
    this.httpInstance.setAuthorization(token);
  }
}
