import { CalendarIcon } from "@components/Icons";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { withStyles } from "@material-ui/styles";
import { formatDateMinCifOrFob } from "@utils/index";
import { isSunday, isValid } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import React, { useEffect, useState } from "react";

const CalendarSpecific = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  minDate,
  freightType,
  ...props
}) => {
  const popoverClasses = usePopoverStyles();

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [minSelectableDate, setMinSelectableDate] = useState(new Date());
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const currentHour = formatDateMinCifOrFob(freightType);
    setMinSelectableDate(currentHour);
  }, [freightType]);

  const handleDateChange = (date) => {
    if (date && !isValid(date)) {
      setErrorMessage("Verifique o formato de data inserido");
    } else if (date && isSunday(date)) {
      setErrorMessage("Data inválida: não pode ser um domingo");
    } else if (date && minSelectableDate && date < minSelectableDate) {
      setErrorMessage("A data não deve ser anterior à data mínima");
    } else {
      setErrorMessage("");
      setSelectedDate(date);
      onChange(date);
    }

    setIsCalendarOpen(false);
  };

  useEffect(() => {
    if (value) {
      setSelectedDate(new Date(value));
    }
  }, [value]);

  const disableDates = (date) => {
    // Desabilita todos os domingos e valida outras regras conforme necessário
    return isSunday(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <SKeyboardDatePicker
        open={isCalendarOpen}
        onOpen={() => setIsCalendarOpen(true)}
        onClose={() => setIsCalendarOpen(false)}
        className="custom-SKeyboardDatePicker"
        // clearable={true}
        name={name}
        label={label}
        format="dd/MM/yyyy"
        value={selectedDate}
        onChange={handleDateChange}
        onBlur={(event) => {
          if (typeof onBlur === "function") {
            onBlur(event);
          }
        }}
        minDate={minSelectableDate}
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        margin="normal"
        id="date-picker-inline"
        PopoverProps={{ classes: popoverClasses }}
        KeyboardButtonProps={{ "aria-label": "change date" }}
        keyboardIcon={<CalendarIcon />}
        //@ts-ignore
        shouldDisableDate={disableDates}
        helperText={errorMessage}
        error={!!errorMessage}
        autoComplete="off"
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

export default CalendarSpecific;

const usePopoverStyles = makeStyles({
  root: {
    "& .MuiPickersDay-day": {
      color: "#000",
    },
    "& .MuiPickersDay-dayDisabled": {
      color: "#000",
      opacity: "0.3",
    },
    "& .MuiPickersDay-daySelected": {
      backgroundColor: "primary.main",
      color: "white",
    },
    "& .MuiPickersCalendarHeader-dayLabel": {
      color: "#000",
    },
  },
});
const SKeyboardDatePicker = withStyles({
  root: {
    margin: 0,
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0.6rem",
    },
    "& .MuiInput-underline:before": {},
    "& .MuiIconButton-root": {
      border: "0px solid red",
      color: "red",
      padding: "12px",
    },
    "& label": {
      // Controle de estilo antes de digitar
      border: "0px solid red",
      opacity: "0.6",
      fontWeight: 400,
      fontSize: "1.4rem",
      borderRadius: "1rem",
    },
    "& input.Mui-focused": {}, // Controle de estilo durante digitacao
    "& .MuiInputLabel-shrink": {
      // Controle de estilo apos campo digitado
      fontWeight: 900,
      color: "#000",
      fontSize: "1.8rem",
      backgroundColor: "#FFF",
      paddingRight: 6,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      // Controla cor da borda durante Digitacao
      border: "2px solid #FF9900",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      // Fieldset
      border: "1px solid rgba(0,0,0,0.3)",
      borderRadius: "1rem",
      opacity: "0.6",
    },
    "& .MuiOutlinedInput-input": {
      border: "0px solid red",
      padding: "16px 14px",
    },
    /* ATENCAO: A propriedade @media screen Precisa ficar no ultimo bloco para sobreescrever o css anterior */
    "@media screen and (max-width: 600px)": {
      "& .MuiIconButton-root": {
        border: "0px solid blue",
        padding: 0,
      },
      "& label": {
        border: "0px solid blue",
        fontSize: "1.2rem",
      },
      "& .MuiInputLabel-shrink": {
        // Controle de estilo apos campo digitado
        fontWeight: 900,
        color: "#000",
        fontSize: "1.4rem",
        backgroundColor: "#FFF",
        paddingRight: 6,
      },
    },
  },
})(KeyboardDatePicker);
