import {
  LockWithCircleIcon,
  NotVisibleIcon,
  VisibleIcon,
} from "@components/Icons";
import Layout from "@components/Layout";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { IChangePasswordService } from "@modules/user/models/IChangePasswordService";
import { Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import * as Yup from "yup";
import TexFieldCustomPassword from "./TextFieldCustomPassword.tsx/TextFieldCustomPassword";

const ChangePasswordPage: React.FC = () => {
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [isErrorPassword, setIsErrorPassword] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  return (
    <Layout>
      <Typography
        style={{
          textAlign: "center",
          fontWeight: "bold",
          fontFamily: "DM Sans",
          fontSize: "2.4rem",
          marginTop: "10px",
        }}
      >
        Alteração da senha
      </Typography>

      <Container
        style={{
          background: "white",
          borderRadius: "25px",
          padding: "50px 30px",
          width: isMobile ? "200px" : "400px",
          marginTop: "40px",
          display: "block",
        }}
      >
        <Grid
          container
          justifyContent="center"
          style={{ marginBottom: "20px" }}
        >
          <LockWithCircleIcon />
        </Grid>

        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            password: Yup.string().required("Senha é obrigatória"),
            confirmPassword: Yup.string().oneOf(
              [Yup.ref("password")],
              "As senhas não conferem"
            ),
          })}
          onSubmit={async (values, props) => {
            try {
              setLoading(true);
              const changePasswordService = iocContext.serviceContainer.get<
                IChangePasswordService
              >(Types.Users.IChangePasswordService);

              const { message } = await changePasswordService.execute(
                values.password
              );
              enqueueSnackbar(message, { variant: "success" });
              props.resetForm();
              setIsErrorPassword(false);
            } catch (error) {
              setIsErrorPassword(true);
              enqueueSnackbar("Ocorreu um erro ao alterar senha", {
                variant: "error",
              });
            } finally {
              setLoading(false);
            }
          }}
        >
          {(props) => {
            return (
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <Field
                      name="password"
                      id="password"
                      type={showPassword ? "text" : "password"}
                      as={TexFieldCustomPassword}
                      label="Nova senha"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowPassword((oldValue) => !oldValue)
                              }
                            >
                              {showPassword ? (
                                <VisibleIcon />
                              ) : (
                                <NotVisibleIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      helperText={
                        props.touched.password && props.errors.password
                      }
                      error={props.touched.password && !!props.errors.password}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="confirmPassword"
                      id="confirmPassword"
                      type={showPassword ? "text" : "password"}
                      as={TexFieldCustomPassword}
                      label="Confirme a senha"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowPassword((oldValue) => !oldValue)
                              }
                            >
                              {showPassword ? (
                                <VisibleIcon />
                              ) : (
                                <NotVisibleIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      helperText={
                        props.touched.confirmPassword &&
                        props.errors.confirmPassword
                      }
                      error={
                        props.touched.confirmPassword &&
                        !!props.errors.confirmPassword
                      }
                    />
                  </Grid>
                  <Grid item container xs={12} justify="center">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      startIcon={
                        loading && (
                          <CircularProgress
                            style={{ width: "2.5rem", height: "2.5rem" }}
                            color="secondary"
                          />
                        )
                      }
                      style={{
                        margin: "2px",
                        background: "#D91F05",
                        color: "white",
                        border: "none",
                        boxShadow: "none",
                        fontWeight: "bold",
                        fontFamily: "Montserrat",
                        textTransform: "none",
                        padding: "10px 30px",
                        marginTop: "20px",
                      }}
                    >
                      {loading ? "Alterando..." : "Alterar Senha"}
                    </Button>

                    {isErrorPassword ? (
                      <Typography
                        style={{
                          fontSize: "1.2rem",
                          opacity: 0.5,
                          paddingTop: "15px",
                        }}
                      >
                        Sua senha deve conter no mínimo 8 caracteres. Pelo menos
                        uma letra maiúscula, um dígito e um caractere
                        especial(Ex.: @#$%+_)
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Layout>
  );
};

export { ChangePasswordPage };
