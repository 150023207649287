import appConfig from "@config/appConfig";
import {
  createStyles,
  Divider,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../../config/routes";
import { useIoCContext } from "../../../../context/IoCContext/IoCContext";
import { Types } from "../../../../ioc/types";
import { ICreateOrderDTO } from "../../../../modules/orders/dtos/ICreateOrderDTO";
import { ICreateOrderService } from "../../../../modules/orders/models/ICreateOrderService";
import AppError from "../../../../utils/AppError";
import { IFormRequestValues } from "../../../../utils/interfaces";
import { useForm } from "../FormContext";
import FormItems from "./FormItems";
import NewRequestValidationSchema from "./NewRequestValidationSchema";
import PurchaseData from "./PurchaseData";

const useStyles = makeStyles(() =>
  createStyles({
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr auto 1fr",
      margin: "0 5rem",
    },
    block: {
      display: "block",
      width: "100%",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      padding: "1.4rem",
    },
  })
);

const FormNewRequest = () => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const formContext = useForm();
  const iocContext = useIoCContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const initialValues: IFormRequestValues = {
    CNPJ: "",
    address: null,
    freightType: "CIF",
    deliveryDate: new Date(),
    comments: "",
    withdrawBasisID: "",
    filialID: "",
    products: [],
    productSelected: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        const castValues = NewRequestValidationSchema.cast(
          values
        ) as IFormRequestValues;

        if (!formContext.dataCustomer || !castValues) return;

        const CNPJData = {
          CNPJ: formContext.dataCustomer.CNPJ,
          division: formContext.dataCustomer.Division,
          id: formContext.dataCustomer.id,
          distributionChannel: formContext.dataCustomer.DistributionChannel,
        };

        const transformedData: ICreateOrderDTO = {
          customer: CNPJData,
          address: {
            id: castValues.address?.id as string,
          },
          deliveryDate: castValues.deliveryDate,
          filialID: castValues.filialID,
          freightType: castValues.freightType,
          //@ts-ignore (formik não deixa chegar a este ponto sem que estejam todos preenchidos)
          products: castValues.products,
          withdrawBasisID: castValues.withdrawBasisID,
          comments: castValues.comments,
        };
        try {
          const createOrderService = iocContext.serviceContainer.get<
            ICreateOrderService
          >(Types.Orders.ICreateOrderService);

          formContext.setLoadingCreateOrder(true);

          const response = await createOrderService.execute(transformedData);
          if (response.status === "error") {
            enqueueSnackbar("Alguns pedidos não foram efetuados", {
              variant: "warning",
            });
          } else {
            enqueueSnackbar("Pedidos efetuados com sucesso!", {
              variant: "success",
            });
          }

          /**
           * salva no localStorage os dados da request
           * para uso na rota ROUTES.USER_ROUTES.ORDER_PLACED
           */
          localStorage.setItem(
            appConfig.keyStorage.newOrderData,
            JSON.stringify({ dataOrder: castValues, response })
          );

          history.push(ROUTES.USER_ROUTES.ORDER_PLACED);
        } catch (error) {
          if (error instanceof AppError) {
            return enqueueSnackbar(error.message, { variant: error.variant });
          }
          console.error(error);
          enqueueSnackbar(
            "Ocorreu um erro ao efetuar os pedidos, contate a central de atendimento",
            {
              variant: "error",
            }
          );
        } finally {
          formContext.setLoadingCreateOrder(false);
        }
      }}
      validationSchema={NewRequestValidationSchema}
    >
      <Form className={isMobile ? classes.block : classes.grid}>
        <PurchaseData />
        {!isMobile && (
          <Grid container justify="center">
            <Divider style={{ margin: "0 6.9rem" }} orientation="vertical" />
          </Grid>
        )}
        {isMobile && (
          <Divider style={{ marginTop: "2rem", marginBottom: "2rem" }} />
        )}
        <FormItems />
      </Form>
    </Formik>
  );
};

export default FormNewRequest;
