import React, { useState, useEffect, useCallback } from "react";
import TableReclamationIndex from "./Sections/TableReclamationIndex";
import Layout from "@components/Layout";
import OpenNewReclamation from "./Sections/OpenNewReclamation";
import { Form, Formik } from "formik";
import ValidationFilterReclamation from "./ValidationYup/ValidationFilterReclamation";
import { FormQueryFilterReclamation } from "./FormQueryFilterReclamation";
import {
  ReclamationUserProvider,
  useReclamationContext,
} from "@context/ReclamationContext/ReclamationUserContext";
import { endOfMonth, startOfMonth } from "date-fns";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { IFilterAllReclamationService } from "@modules/reclamation/models/IFilterAllReclamationService";
import { Types } from "@ioc/types";
import {
  Content,
  IFilterAllReclamationData,
} from "@modules/reclamation/dtos/IFilterAllReclamationData";
import { formatDateAndHourPTBR, maskCNPJ } from "@utils/index";
import { createStyles, makeStyles } from "@material-ui/core";
import PaginationDynamic from "@components/PaginationDynamic/PaginationDynamic";
import { calcPagesQuantity } from "@utils/pagination";
import { enqueueSnackbar } from "notistack";
import AppError from "@utils/AppError";
import { Container } from "@material-ui/core";
import SkeletonTable from "@components/SkeletonTable/SkeletonTable";
import { Grid } from "@mui/material";

const ReclamationConsult: React.FC = () => {
  const {
    setLoadingDataFilterReclamation,
    loadingDataFilterReclamation,
    setDataQueryReclamationUser,
    tabActive,
    totalCount,
    setTotalCount,
    setFilterUser,
    rangeDate,
    setIdRef,
    idRef,
    setCNPJSelected,
    cnpjSelected,
  } = useReclamationContext();
  const iocContext = useIoCContext();
  const classes = useStyles();

  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;
  const firstItemIndex = (currentPage - 1) * ITEMS_PER_PAGE + 1;
  const lastItemIndex = Math.min(currentPage * ITEMS_PER_PAGE, totalCount);

  const initalValues = {
    ref_id: "",
    cnpj: [],
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    date_range: [startOfMonth(new Date()), endOfMonth(new Date())],
  };

  const handleSubmit = useCallback(
    async (values, page) => {
      try {
        setLoadingDataFilterReclamation(true);

        const queryFilterReclamation = iocContext.serviceContainer.get<
          IFilterAllReclamationService
        >(Types.Reclamation.IFilterAllReclamationService);

        if (values.ref_id) {
          setIdRef(values.ref_id);
        }

        if (values.cnpj) {
          setCNPJSelected(values.cnpj);
        }

        const payloadFilterReclamation = {
          ref_id: values.ref_id ? values.ref_id : idRef,
          cnpj: values.cnpj ? values.cnpj : cnpjSelected,
          beginDate: rangeDate ? rangeDate[0] : initalValues.startDate,
          endDate: rangeDate ? rangeDate[1] : initalValues.endDate,
          status: tabActive === 0 ? "IN_PROGRESS" : "CLOSED",
          page: page > 1 ? page : 1,
          order: JSON.stringify([["updatedAt", "DESC"]]),
          limit: ITEMS_PER_PAGE,
        };

        setFilterUser(payloadFilterReclamation);

        const response = await queryFilterReclamation.execute(
          payloadFilterReclamation
        );
        setTotalCount(response.total);

        const newData = response.content.map((item: Content, index) => ({
          vid: item.refId,
          companyName: item.companyName,
          cnpj: item.cnpj ? maskCNPJ(item.cnpj) : "-",
          dateBegin: item.createdAt
            ? formatDateAndHourPTBR(item.createdAt)
            : "-",
          userRequest: item.fullName,
          id: item.id,
          status: item.status,
        }));

        const filterData: IFilterAllReclamationData = {
          content: newData,
          total: newData.length,
          page: page > 1 ? page : 1,
        };

        setDataQueryReclamationUser(filterData);
      } catch (error) {
        if (error instanceof AppError) {
          return enqueueSnackbar(error.message, {
            variant: error.variant,
          });
        }
        enqueueSnackbar("Ocorreu um erro ao filtrar pela reclamação", {
          variant: "error",
        });
      } finally {
        setLoadingDataFilterReclamation(false);
      }
    },
    [
      cnpjSelected,
      idRef,
      initalValues.endDate,
      initalValues.startDate,
      iocContext.serviceContainer,
      rangeDate,
      setCNPJSelected,
      setDataQueryReclamationUser,
      setFilterUser,
      setIdRef,
      setLoadingDataFilterReclamation,
      setTotalCount,
      tabActive,
    ]
  );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => {
    setCurrentPage(newPage + 1);
    handleSubmit("", newPage + 1);
  };

  useEffect(() => {
    handleSubmit("", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabActive]);

  return (
    <Layout disableToolbar={true}>
      <Grid container sx={{ padding: 1, justifyContent: { xl: "left" } }}>
        <Grid
          item
          xs={12}
          sm={11}
          md={11}
          lg={11}
          xl={10}
          sx={{ padding: 1, flexBasis: { xl: "auto" } }}
        >
          <OpenNewReclamation />

          <Formik
            initialValues={initalValues}
            onSubmit={handleSubmit}
            validationSchema={ValidationFilterReclamation}
          >
            <Form>
              <FormQueryFilterReclamation />
            </Form>
          </Formik>

          {loadingDataFilterReclamation && <SkeletonTable rows={10} cols={7} />}

          {!loadingDataFilterReclamation && (
            <>
              <TableReclamationIndex />
              <Container>
                <Grid container style={{ paddingTop: "20px" }}>
                  <Grid item xs={6}>
                    <span className={classes.TilteFooter}>
                      <b>
                        Mostrando itens{" "}
                        <span className={classes.CountItensFooter}>
                          {firstItemIndex}-{lastItemIndex}
                        </span>
                      </b>
                      <p className={classes.SubTilteFooter}>
                        Total de {totalCount}
                      </p>
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <PaginationDynamic
                      onPageChange={handlePageChange}
                      pagesTotal={calcPagesQuantity(ITEMS_PER_PAGE, totalCount)}
                      showFirstButton={true}
                      showLastButton={true}
                      page={currentPage}
                    />
                  </Grid>
                </Grid>
              </Container>
            </>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

const ContainerReclamation: React.FC = () => {
  return (
    <ReclamationUserProvider>
      <ReclamationConsult />
    </ReclamationUserProvider>
  );
};

export default ContainerReclamation;

const useStyles = makeStyles((theme) =>
  createStyles({
    TilteFooter: {
      fontFamily: "Montserrat",
      color: "#3E3D3D",
    },
    CountItensFooter: {
      fontFamily: "Montserrat",
      color: "#FFB03A",
    },
    SubTilteFooter: {
      padding: 0,
      margin: 0,
      opacity: 0.6,
      fontFamily: "Montserrat",
      color: "#3E3D3D",
    },
  })
);
