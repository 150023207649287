import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { getCorrectDate } from "@utils/index";
import { inject, injectable } from "inversify";
import { IQueryInvoicesDataDTO } from "../dtos/IQueryInvoicesDataDTO";
import { IQueryInvoicesDTO } from "../dtos/IQueryInvoicesDTO";
import { IQueryInvoicesService } from "../models/IQueryInvoicesService";

@injectable()
export class QueryInvoicesService implements IQueryInvoicesService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(
    data: IQueryInvoicesDTO
  ): Promise<IQueryInvoicesDataDTO[]> {
    let invoices: IQueryInvoicesDataDTO[] = [];
    if (data.CNPJ.length !== 14) {
      invoices = await this.httpInstance.get<IQueryInvoicesDataDTO[]>(
        "/invoices",
        { params: { ...data, action: "BP" } }
      );
    } else {
      invoices = await this.httpInstance.get<IQueryInvoicesDataDTO[]>(
        "/invoices",
        { params: data }
      );
    }

    return invoices.map((invoice) => {
      return {
        ...invoice,
        emissionDate: getCorrectDate(new Date(invoice.emissionDate)),
      };
    });
  }
}
