import { Grid, TextField } from "@material-ui/core";
import { ISendProjectionSalesDTO } from "@modules/projectionSales/dtos/ISendProjectionSalesDTO";
import { useFormikContext } from "formik";
import React from "react";
import { useStylesPerformanceManagement } from "..";
import { Button } from "@mui/material";
import { usePerformanceManagement } from "@context/PerformanceManagementContext";

const FormQueryProjection: React.FC = () => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<ISendProjectionSalesDTO>();
  const classes = useStylesPerformanceManagement();

  const { isSubmittingSalesProjection } = usePerformanceManagement();

  const handleOnChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue("value", event.target.value.replace(/[^\d]/g, ""));
  };

  function formatNumberWithSeparator(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  return (
    <Grid
      style={{
        display: "block",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 10,
      }}
    >
      <Grid item xs={12} md={12} style={{ height: 80, width: 300 }}>
        <TextField
          className={classes.textfield}
          label="Sua meta de volume"
          variant="outlined"
          id="meta"
          type="text"
          value={formatNumberWithSeparator(values.value)}
          onChange={handleOnChangeValue}
          onBlur={() => setFieldTouched("value", true)}
          error={touched.value && Boolean(errors.value)}
          helperText={touched.value && errors.value}
        />
      </Grid>

      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 10,
        }}
      >
        <Button
          disabled={isSubmittingSalesProjection}
          type="submit"
          variant="contained"
          style={{
            fontFamily: "Montserrat",
            borderRadius: "2px",
            textTransform: "none",
            background: "#D91F05",
            color: "#FFF",
            boxShadow: "none",
            width: 100,
            height: 30,
            fontSize: 12,
            opacity: isSubmittingSalesProjection ? 0.6 : 1,
            pointerEvents: isSubmittingSalesProjection ? "none" : "auto",
            position: "relative",
          }}
        >
          {isSubmittingSalesProjection ? "Salvando..." : "Salvar"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormQueryProjection;
