import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { IClient } from "@utils/interfaces";
import { inject, injectable } from "inversify";
import { IGetClientInfoService } from "../models/IGetClientInfoService";

@injectable()
export class GetClientInfoService implements IGetClientInfoService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(CNPJ: string, price?: boolean): Promise<IClient> {
    let customer;
    if (price) {
      customer = await this.httpInstance.get<IClient>(
        `/customer/${CNPJ}?priceRequest=true`
      );
    } else {
      customer = await this.httpInstance.get<IClient>(`/customer/${CNPJ}`);
    }

    return customer;
  }
}
