import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Hidden,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  CardLayer,
  GroupedButtons,
  GroupedObservationData,
  GroupedProductData,
  GroupedPurchaseData,
  GroupedResponsibleData,
  GroupedTotalData,
  SubTitle,
} from "@pages/User/NewRequest/resources/form/FormLayout";
import { useForm } from "../context/FormContext";

interface Props {
  children?: React.ReactNode;
}

const GroupedPurchaseDataMobile: React.FC = () => {
  return (
    <Grid container sx={{ justifyContent: "center" }}>
      {/* CAMPOS - DADOS DA COMPRA */}
      <Grid item xs={12} sx={{ border: "0px solid red" }}>
        <GroupedPurchaseData />
      </Grid>

      <Hidden mdUp>
        <Grid item xs={3}>
          <Divider
            sx={{
              m: "32px 0px 24px 0px",
              border: "2px solid rgba(0,0,0,0.1)",
              borderRadius: 1,
            }}
          />
        </Grid>
      </Hidden>

      {/* TITULO - DADOS DO RESPONSAVEL */}
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ p: "0px 0px 16px 0px" }}>
            <SubTitle subtitle={"Dados do responsável"} />
          </Grid>
        </Grid>
      </Grid>

      {/* CAMPOS - DADOS DO RESPONSAVEL */}
      <Grid item xs={12}>
        <GroupedResponsibleData />
      </Grid>
    </Grid>
  );
};

const GroupedProductDataMobile: React.FC = () => {
  return (
    <Grid item xs={12} sm={11} md={11} lg={5.8}>
      <CardLayer>
        <Grid
          container
          justifyContent={"space-between"}
          alignContent={"baseline"}
          sx={{ height: "100%" }}
          gap={4}
        >
          <Grid item xs={12} sx={{ height: "100%" }}>
            <GroupedProductData />
          </Grid>
        </Grid>
      </CardLayer>
    </Grid>
  );
};

const FormLayoutMobile: React.FC<Props> = ({ children, ...props }) => {
  const { dataCustomer } = useForm();
  const controlLockProductWithHiddenPrice = dataCustomer?.hiddePrice;

  const dataFormMobile = [
    {
      title: "Dados da compra",
      body: <GroupedPurchaseDataMobile />,
      rule: true,
    },
    {
      title: "Adicionar observação",
      body: <GroupedObservationData />,
      rule: true,
    },
    {
      title: "Produtos",
      body: <GroupedProductDataMobile />,
      rule: controlLockProductWithHiddenPrice,
    },
    {
      title: "Total",
      body: <GroupedTotalData />,
      rule: true,
    },
  ];

  return (
    <Grid
      container
      className="accordions-container"
      sx={{
        gap: { xs: 3, sm: 2, md: 2, lg: 3 },
        justifyContent: "space-between",
      }}
    >
      {dataFormMobile.map((item, index) => {
        return (
          <Grid item key={index} xs={12}>
            <Accordion
              sx={{
                opacity: item?.rule ? "1" : "0.4",
                pointerEvents: item?.rule ? "auto" : "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${index}-content`}
                id={`${index}-header`}
              >
                <SubTitle subtitle={item.title} />
              </AccordionSummary>
              <AccordionDetails sx={{ border: "0px solid red", p: "12px" }}>
                {item.body}
              </AccordionDetails>
            </Accordion>
          </Grid>
        );
      })}
      <GroupedButtons />
    </Grid>
  );
};

export default FormLayoutMobile;
