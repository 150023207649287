import SkeletonCardTitle from "@components/SkeletonCardTitle";
import React from "react";

const LoadingCardTitle: React.FC = () => {
  return (
    <>
      <SkeletonCardTitle />
      <SkeletonCardTitle />
      <SkeletonCardTitle />
      <SkeletonCardTitle />
      <SkeletonCardTitle />
    </>
  );
};

export default LoadingCardTitle;
