import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  makeStyles,
  Typography,
  Box,
  Modal,
} from "@material-ui/core";
import { IDownloadPDFInvoicesService } from "@modules/invoices/models/IDownloadPDFInvoicesService";
import AppError from "@utils/AppError";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { useFormQueryInvoices } from "./FormQueryInvoicesContext";
import { CloseIcon, PhotoDownloadIcon } from "@components/Icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogContent: {
      paddingTop: "2.4rem",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    generalBox: {
      width: "37rem",
      backgroundColor: "#FFF",
      borderRadius: "4px",
      padding: "2rem",
    },
    boxTitle: {
      display: "flex",
      justifyContent: "space-between",
    },
    boxAlign: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "2rem",
    },
    actions: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    button: {
      textTransform: "capitalize",
      boxShadow: "none",
    },
  })
);

const ModalDownload: React.FC = () => {
  const classes = useStyles();

  const formQueryInvoicesContext = useFormQueryInvoices();
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();

  const {
    invoicesSelectedToDownload,
    setInvoicesSelectedToDownload,
    checkAfterDownload,
    setInvoicesSelected,
  } = formQueryInvoicesContext;

  const [checkbox, setCheckbox] = useState({
    XML: false,
    PDF: false,
  });
  const [loadingDownloadNFEs, setLoadingDownloadNFEs] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckbox({ ...checkbox, [event.target.name]: event.target.checked });
  };

  const downloadNFEs = useCallback(() => {
    async function fetch() {
      try {
        setLoadingDownloadNFEs(true);
        const downloaInvoicesService = iocContext.serviceContainer.get<
          IDownloadPDFInvoicesService
        >(Types.Invoices.IDownloadPDFInvoicesService);

        const getAllInvoicesSelected = Object.keys(invoicesSelectedToDownload)
          .map((key) => {
            if (invoicesSelectedToDownload[key]) {
              const title = formQueryInvoicesContext.dataQuery.find(
                (title) => title.id === key
              );
              if (title) {
                return title;
              }
            }
            return null;
          })
          .filter((ele) => ele !== null)
          .map((title) => {
            if (title) {
              return title.accessKey;
            }
            return null;
          });

        const url = await downloaInvoicesService.execute({
          codes: getAllInvoicesSelected as string[],
          type:
            checkbox.PDF && checkbox.XML ? "all" : checkbox.PDF ? "pdf" : "xml",
        });

        window.open(url);
      } catch (error) {
        console.error("error", error);
        if (error instanceof AppError) {
          return enqueueSnackbar(error.message, { variant: error.variant });
        }
        enqueueSnackbar("Ocorreu um erro ao baixar a(s) nota(s)", {
          variant: "error",
        });
      } finally {
        setLoadingDownloadNFEs(false);
        setInvoicesSelected([...checkAfterDownload]);
      }
    }
    fetch();
  }, [
    checkbox.PDF,
    checkbox.XML,
    enqueueSnackbar,
    formQueryInvoicesContext.dataQuery,
    invoicesSelectedToDownload,
    iocContext.serviceContainer,
    checkAfterDownload,
    setInvoicesSelected,
  ]);

  const handleDownloadNFE = () => {
    downloadNFEs();
    setTimeout(() => {
      formQueryInvoicesContext.setCheckAllNFE(false);
      formQueryInvoicesContext.setOpenModalDownload(false);
      setInvoicesSelectedToDownload({});
    }, 1000);
  };

  const { XML, PDF } = checkbox;
  const error = [XML, PDF].filter((v) => v).length === 0;

  return (
    <Modal
      className={classes.modal}
      open={formQueryInvoicesContext.openModalDownload}
    >
      <Box component="div" className={classes.generalBox}>
        <Box component="div" className={classes.boxTitle}>
          <Typography style={{ fontSize: "1.3rem", fontWeight: 600 }}>
            Baixar nota
          </Typography>
          <Button
            onClick={() => {
              formQueryInvoicesContext.setCheckAllNFE(false);
              formQueryInvoicesContext.setOpenModalDownload(false);
              setInvoicesSelectedToDownload({});
            }}
          >
            <CloseIcon />
          </Button>
        </Box>

        <Box component="div" className={classes.boxAlign}>
          <PhotoDownloadIcon />
        </Box>

        <Box component="div" className={classes.boxAlign}>
          <Typography
            variant="h6"
            style={{ color: "#3E3D3D", fontWeight: "bold" }}
          >
            Escolha o formato:
          </Typography>
        </Box>

        <DialogContent className={classes.dialogContent}>
          <FormControl error={error} component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: "#D91F05" }}
                    color="primary"
                    checked={XML}
                    onChange={handleChange}
                    name="XML"
                  />
                }
                label="XML"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: "#D91F05" }}
                    color="primary"
                    checked={PDF}
                    onChange={handleChange}
                    name="PDF"
                  />
                }
                label="PDF"
              />
            </FormGroup>
            <FormHelperText>
              {error ? "Selecione um formato" : ""}
            </FormHelperText>
          </FormControl>
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button
            className={classes.button}
            disabled={error}
            color="primary"
            variant="contained"
            onClick={() => {
              handleDownloadNFE();
            }}
            startIcon={
              loadingDownloadNFEs && (
                <CircularProgress
                  color="secondary"
                  style={{ height: "2.5rem", width: "2.5rem" }}
                />
              )
            }
          >
            Baixar
          </Button>
        </DialogActions>
      </Box>
    </Modal>
  );
};

export default ModalDownload;
