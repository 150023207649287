import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { IFilterAllReclamationService } from "../models/IFilterAllReclamationService";
import { IFilterAllReclamationDTO } from "../dtos/IFilterAllReclamationDTO";
import { IFilterAllReclamationData } from "../dtos/IFilterAllReclamationData";
import { sanitizeParameters } from "@utils/index";

@injectable()
export class FilterAllReclamationService
  implements IFilterAllReclamationService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: IFilterAllReclamationDTO) {
    return await this.httpInstance.get<IFilterAllReclamationData>(
      "/reclamation",
      {
        params: sanitizeParameters(data),
      }
    );
  }
}
