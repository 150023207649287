import {
  Box,
  Theme,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { IGetLastOrdersDTO } from "@modules/lastOrders/dtos/IGetLastOrdersDTO";
import React, { useState } from "react";
import iconCircleDoubt from "../../assets/iconCircleDoubt.svg";
import iconTableLastOrderBL from "../../assets/iconTableLastOrderBL.svg";
import iconTableLastOrderFT from "../../assets/iconTableLastOrderFT.svg";
import iconTableLastOrderLIB from "../../assets/iconTableLastOrderLIB.svg";
import union from "../../assets/union.svg";

import { CarouselBasicItem } from "@components/CarouselBasic";
import {
  capitalizeFirstLetterFullString,
  formatDateWithoutHours,
} from "@utils/index";

const logisticalStatus = {
  BL: {
    text: "Bloqueado",
    color: "#F97A91",
    icon: (
      <img src={iconTableLastOrderBL} width="36px" alt="ícone de caminhão" />
    ),
    iconMobile: (
      <img
        src={iconTableLastOrderBL}
        width="28px"
        alt="ícone de caminhão mobile"
      />
    ),
  },
  FT: {
    text: "Faturado",
    color: "#0BB873",
    icon: (
      <img src={iconTableLastOrderFT} width="36px" alt="ícone de caminhão" />
    ),
    iconMobile: (
      <img
        src={iconTableLastOrderFT}
        width="28px"
        alt="ícone de caminhão mobile"
      />
    ),
  },
  LIB: {
    text: "Liberado",
    color: "#FFB800",
    icon: (
      <img src={iconTableLastOrderLIB} width="36px" alt="ícone de caminhão" />
    ),
    iconMobile: (
      <img src={iconTableLastOrderLIB} width="28px" alt="ícone de caminhão" />
    ),
  },
  DEFAULT: {
    text: "",
    color: "#ccc",
    icon: (
      <img src={iconCircleDoubt} width="36px" alt="ícone de interrogação" />
    ),
    iconMobile: (
      <img
        src={iconCircleDoubt}
        width="28px"
        alt="ícone de interrogação mobile"
      />
    ),
  },
};

const CardOrder: React.FC<IGetLastOrdersDTO> = ({ ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [communications] = useState<CarouselBasicItem[]>([]);
  const hasCommunication = communications.length > 0;

  return (
    <Box className={isMobile ? classes.paperMobile : classes.paper}>
      <Box
        className={isMobile ? classes.imgOrderMobile : classes.imgOrder}
        style={{
          width: isMobile ? "20%" : widthIcon,
        }}
      >
        {isMobile
          ? logisticalStatus[props.statusAtem ?? "DEFAULT"].iconMobile
          : logisticalStatus[props.statusAtem ?? "DEFAULT"].icon}
      </Box>
      <Box
        className={classes.order}
        style={{
          width: isMobile ? "18%" : !hasCommunication ? "90%" : widthOrder,
          textAlign: isMobile ? "left" : "center",
        }}
      >
        <Typography className={isMobile ? classes.titleMobile : classes.title}>
          Pedido
        </Typography>
        <Typography className={isMobile ? classes.valueMobile : classes.value}>
          {props.numberOrder}
        </Typography>
      </Box>
      {!isMobile && (
        <>
          <Box
            className={classes.order}
            style={{
              width: !hasCommunication ? "90%" : widthDateCreate,
              textAlign: "center",
            }}
          >
            <Typography className={classes.title}>Data de criação</Typography>
            <Typography className={classes.value}>
              {formatDateWithoutHours(props.implantationDate.toString())}
            </Typography>
          </Box>
          <Box
            className={classes.order}
            style={{
              width: !hasCommunication ? "90%" : widthDateDelivery,
              textAlign: "center",
            }}
          >
            <Typography className={classes.title}>Data de entrega</Typography>
            <Typography className={classes.value}>
              {formatDateWithoutHours(props.deliveryDate.toString())}
            </Typography>
          </Box>
        </>
      )}

      <Box
        className={classes.order}
        style={{
          width: isMobile
            ? "36%"
            : !hasCommunication
            ? "120%"
            : widthSocialReason,
          textAlign: isMobile ? "left" : "center",
        }}
      >
        <Typography className={isMobile ? classes.titleMobile : classes.title}>
          Razão social
        </Typography>
        <Tooltip
          title={
            props.companyName
              ? `
        ${capitalizeFirstLetterFullString(
          props.companyName ? props.companyName.toLowerCase() : "-"
        )}`
              : ""
          }
          placement="bottom"
          arrow
        >
          <Typography
            className={isMobile ? classes.valueMobile : classes.value}
          >
            {capitalizeFirstLetterFullString(
              props.companyName ? props.companyName.toLowerCase() : "-"
            )}
          </Typography>
        </Tooltip>
      </Box>

      {!isMobile && (
        <>
          <Box
            className={classes.order}
            style={{
              width: !hasCommunication ? "120%" : widthDriver,
            }}
          >
            <Typography className={classes.title}>Motorista</Typography>
            <Tooltip
              title={
                props.driverName
                  ? `
        ${capitalizeFirstLetterFullString(
          props.driverName ? props.driverName.toLowerCase() : ""
        )}`
                  : ""
              }
              placement="bottom"
              arrow
            >
              <Typography className={classes.value}>
                {capitalizeFirstLetterFullString(
                  props.driverName ? props.driverName.toLowerCase() : "-"
                )}
              </Typography>
            </Tooltip>
          </Box>
          <Box
            className={classes.order}
            style={{
              width: !hasCommunication ? "80%" : widthPlate,
            }}
          >
            <Typography className={classes.title}>Placa</Typography>
            <Typography className={classes.value}>
              {props.vehiclePlate ? props.vehiclePlate : "-"}
            </Typography>
          </Box>
          <Box
            className={classes.order}
            style={{
              width: !hasCommunication ? "100%" : widthStatus,
            }}
          >
            <Typography className={classes.title}>Status Financeiro</Typography>
            <Typography
              className={classes.value}
              style={{
                color: logisticalStatus[props.statusAtem ?? "DEFAULT"].color,
                fontWeight: 700,
              }}
            >
              {logisticalStatus[props.statusAtem ?? "DEFAULT"].text}
            </Typography>
          </Box>
        </>
      )}

      {isMobile && (
        <Box
          className={classes.order}
          style={{
            width: "8%",
          }}
        >
          <img src={union} width="7px" height="13px" alt="ícone de seta" />
        </Box>
      )}
    </Box>
  );
};

export default CardOrder;

const widthIcon = "5.5%";
const widthOrder = "5.5%";
const widthDateCreate = "13.2%";
const widthDateDelivery = "13%";
const widthSocialReason = "13.8%";
const widthDriver = "15.8%";
const widthPlate = "8%";
const widthStatus = "15%";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: "#FFFFFF",
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "31px",
      color: "#3E3D3D",
      border: "1px solid rgba(114, 101, 81, 0.1)",
      borderRadius: "12px",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: theme.spacing(1.4),
      paddingBottom: theme.spacing(1.4),
    },
    paperMobile: {
      height: "56px",
      backgroundColor: "transparent",
      border: "none",
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "31px",
      color: "#3E3D3D",
      borderRadius: "12px",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: theme.spacing(1.4),
      paddingBottom: theme.spacing(1.4),
    },
    order: {
      display: "block",
    },
    imgOrder: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(1.4),
    },
    imgOrderMobile: {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      backgroundColor: "#FFFFFF",
      borderRadius: "12px",
      height: "56px",
      justifyContent: "center",
      border: "1px solid rgba(114, 101, 81, 0.1)",
    },
    title: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "13.6px",
      lineHeight: "21px",
      color: "#3E3D3D",
      opacity: 0.7,
      paddingRight: "1px",
    },
    value: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "13.6px",
      lineHeight: "21px",
      color: "#3E3D3D",
      opacity: 0.7,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    titleMobile: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "16px",
      color: "#3E3D3D",
      opacity: 0.7,
    },
    valueMobile: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      color: "#3E3D3D",
      opacity: 0.7,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  })
);
