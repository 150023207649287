import ButtonLinkGeneric from "@components/ButtonLinkGeneric/ButtonLinkGeneric";
import ROUTES from "@config/routes";
import { Container, Grid, Typography, useTheme } from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import React from "react";

const OpenNewReclamation: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));

  return (
    <Container>
      <Grid
        container
        alignItems="center"
        style={{
          marginBottom: "30px",
          marginTop: "20px",
        }}
      >
        <Grid xs={6}>
          <Typography
            style={{
              color: "#3E3D3D",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize:
                isMobile || isTablet ? "2rem" : isTablet ? "2.5rem" : "2.4rem",
              marginLeft: isMobile || isTablet ? "10px" : "0px",
            }}
          >
            Fale conosco
          </Typography>
        </Grid>

        <Grid xs={6} container justify="flex-end">
          <ButtonLinkGeneric
            text={"Abrir novo registro"}
            link={ROUTES.USER_ROUTES.RECLAMATION}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default OpenNewReclamation;
