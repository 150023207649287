import Layout from "@components/Layout";
import {
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useMemo } from "react";
import { DiscountProvider, useDiscountContext } from "./DiscountContext";
import CardDefault from "@components/CardDefault";
import { CheckListIcon } from "@components/Icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { capitalizeFirstLetterFullString, maskCNPJ } from "@utils/index";
import { useUserState } from "@context/UserContext";
import TableDiscount from "./TableDiscount";
import { useHistory } from "react-router-dom";
import ROUTES from "@config/routes";

const useStyles = makeStyles((theme) =>
  createStyles({
    formTitle: {
      fontWeight: "bold",
      marginBottom: "2rem",
      color: theme.palette.primary.highlightedplus,
    },
    titleCard: {
      color: theme.palette.text.primary,
      fontFamily: "DM Sans",
      fontSize: "24px",
      fontWeight: 700,
      paddingTop: "2rem",
      paddingBottom: "1rem",
      marginLeft: "2rem",
    },
    customSelect: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      marginTop: "0",
      height: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
      paddingLeft: "14px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(114, 101, 81, 0.20)",
      },
      "& .MuiSvgIcon-root": {
        fill: theme.palette.primary.main,
      },
      borderRadius: "8px",
    },
    inputLabel: {
      position: "absolute",
      top: "-4px",
      left: "12px",
      color: theme.palette.primary.highlightedplus,
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 700,
      backgroundColor: theme.palette.text.hint,
      paddingLeft: "0.2rem",
      paddingRight: "0.8rem",
      marginLeft: "0rem",
      width: "auto",
    },
    itemSelectTitle: {
      color: theme.palette.primary.highlightedplus,
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
      marginTop: 10,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    itemSelectSubTitle: {
      color: theme.palette.grey[100],
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "46px",
      padding: 0,
      marginTop: "-1.6rem",
    },
    itemSelect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    formControl: {
      borderRadius: "8px",
      width: "px",
      height: "50px",
    },
  })
);

const PageContainer: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const userState = useUserState();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const {
    selectedCNPJ,
    setSelectedCNPJ,
    getDiscounts,
    loading,
  } = useDiscountContext();

  const commonStyle: any = {
    fontFamily: "Montserrat",
    borderRadius: "2px",
    fontWeight: "bold",
    textTransform: "none",
    background: theme.palette.primary.main,
    color: theme.palette.text.hint,
    padding: isMobile ? "10px 30px" : "10px 30px",
    boxShadow: "none",
    fontSize: isMobile ? "12px" : "",
    height: "56px",
  };

  const commonStyleDisabled = {
    ...commonStyle,
    background: !Boolean(selectedCNPJ)
      ? theme.palette.shadesOfDark.light
      : theme.palette.primary.main,
  };

  const renderCPNJ = useMemo(() => {
    return userState.listCNPJ.map((ele) => (
      <MenuItem key={ele.CNPJ} value={ele.CNPJ} className={classes.itemSelect}>
        <Typography className={classes.itemSelectTitle}>
          {capitalizeFirstLetterFullString(ele.companyName)}
        </Typography>
        <Typography className={classes.itemSelectSubTitle}>
          {maskCNPJ(ele.CNPJ)}
        </Typography>
      </MenuItem>
    ));
  }, [classes, userState.listCNPJ]);

  return (
    <Layout>
      <Grid
        item
        xs={12}
        style={{
          margin: isMobile ? 0 : "0 8rem",
          width: isMobile ? "100%" : "",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <Typography
          variant={isMobile ? "subtitle1" : "h5"}
          align="left"
          className={classes.formTitle}
        >
          Descontos
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "end",
          margin: isMobile ? 0 : "0 7rem",
          width: isMobile ? "100%" : "",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <Button
          type="submit"
          style={commonStyle}
          onClick={() => history.push(ROUTES.USER_ROUTES.PRODUCT)}
        >
          Ir para produtos
        </Button>
      </Grid>

      <div
        style={{
          margin: isMobile ? 0 : "0 7rem",
          width: isMobile ? "100%" : "",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <CardDefault spacing={0}>
          <Grid
            item
            xs={12}
            style={{ display: "flex", paddingBottom: "1.2rem" }}
          >
            <Grid item xs={10}>
              <Typography className={classes.titleCard}>
                Selecione o seu posto
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingRight: "1.6rem",
              }}
            >
              <CheckListIcon fontSize="small" />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={isMobile ? 12 : 3}
            alignItems="center"
            style={{ paddingLeft: isMobile ? "1rem" : "3rem" }}
          >
            <FormControl
              fullWidth
              className={classes.formControl}
              variant="outlined"
              style={{}}
            >
              <InputLabel htmlFor="freightType" className={classes.inputLabel}>
                Razão social
              </InputLabel>
              <Select
                className={classes.customSelect}
                value={selectedCNPJ}
                onChange={({ target }: any) => {
                  setSelectedCNPJ(target.value);
                }}
                name="CNPJ"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                IconComponent={ExpandMoreIcon}
              >
                {renderCPNJ}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{
              paddingLeft: isMobile ? "1rem" : "3rem",
              paddingRight: isMobile ? "1rem" : "3rem",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              disabled={!Boolean(selectedCNPJ)}
              type="submit"
              style={commonStyleDisabled}
              onClick={() => getDiscounts()}
              endIcon={
                loading && (
                  <CircularProgress
                    color="secondary"
                    style={{ height: "2rem", width: "2rem" }}
                  />
                )
              }
            >
              Consultar
            </Button>
          </Grid>
        </CardDefault>
        <TableDiscount />
      </div>
    </Layout>
  );
};

const Discount: React.FC = () => {
  return (
    <DiscountProvider>
      <PageContainer />
    </DiscountProvider>
  );
};

Discount.displayName = "Discount";

export default Discount;
