import React, { useState } from "react";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";

import { TrashIconPlus, WarnRedIcon } from "@components/Icons";
import Button from "@components/Button";
import Modal from "@components/Modal";

import { TypographyTitle, TypographyCaption } from "../typography";

interface Props {
  children?: React.ReactNode;
  scheduleID: string;
  isDisabled?: boolean;
  handleCancelScheduling: () => Promise<void>;
  hiddenIcon?: boolean;
}

const ModalCancelScheduling: React.FC<Props> = ({ children, ...props }) => {
  const { scheduleID, isDisabled, hiddenIcon, handleCancelScheduling } = props;

  const [load, setLoad] = useState(false);

  const onClickCancelScheduling = async () => {
    setLoad(true);
    await handleCancelScheduling();
    setLoad(false);
  };

  return (
    <Modal
      ModalButton={
        <Tooltip
          title={
            isDisabled ? (
              <TypographyCaption sx={{ fontSize: 10 }}>
                "Voce nao pode cancelar um agendamento que possua alguma ordem
                de venda faturada"
              </TypographyCaption>
            ) : (
              <TypographyCaption sx={{ fontSize: 10 }}>
                "Cancelar Agendamento"
              </TypographyCaption>
            )
          }
        >
          <span>
            <IconButton
              disabled={isDisabled}
              sx={{
                opacity: isDisabled ? "0.4" : "1",
                p: { xs: "4px", md: "8px" },
                gap: 1,
              }}
            >
              {children}
              <TrashIconPlus
                color="primary"
                sx={{
                  width: { xs: "2rem", md: "19px", lg: "22px" },
                  height: { xs: "2rem", md: "19px", lg: "22px" },
                  display: hiddenIcon ? "none" : "block",
                }}
              />{" "}
            </IconButton>
          </span>
        </Tooltip>
      }
    >
      {({ handleClose }) => {
        return (
          <Box
            className={"Box-Cancel-Scheduling"}
            sx={{
              py: { xs: 1, md: 2 },
              px: { xs: 0.3, md: 0.5 },
              width: { xs: "280px", md: "420px" },
            }}
          >
            <Grid container gap={4}>
              <Grid item xs={12}>
                <WarnRedIcon style={{ width: 90, height: 90 }} />
              </Grid>
              <Grid item xs={12}>
                <TypographyTitle>
                  Tem certeza que deseja cancelar o agendamento{" "}
                  <b>
                    <u style={{ textDecorationColor: "red" }}>{scheduleID}</u>
                  </b>{" "}
                  ?
                </TypographyTitle>
              </Grid>
              <Grid item xs={12}>
                <TypographyCaption>
                  Ao cancelar, as ordens de venda não estarão mais agendadas e
                  retornarão à lista de "Não agendados.
                </TypographyCaption>
              </Grid>
              <Grid item xs={12}>
                <Grid container gap={3} justifyContent={"center"}>
                  <Grid item>
                    <Button
                      disabled={load}
                      variant="outlined"
                      onClick={handleClose}
                    >
                      Cancelar
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      loading={load}
                      variant="contained"
                      onClick={async () => {
                        await onClickCancelScheduling();
                        handleClose();
                      }}
                    >
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        );
      }}
    </Modal>
  );
};

export default ModalCancelScheduling;
