import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  FormHelperText,
  Grid,
  InputBase,
  makeStyles,
  MenuItem,
  Select,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
  InputLabel,
  TextField,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useFormikContext } from "formik";
import React, { ReactNode, useCallback, useEffect, useState } from "react";

import {
  IFormSimulateOrder,
  SimulationProduct,
} from "../../../../utils/interfaces";
import { useForm } from "../FormContext";
import { TableProducts } from "./TableProduct";
import { v4 as uuidv4 } from "uuid";
import { useUserState } from "@context/UserContext";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { IGetClientInfoService } from "@modules/orders/models/IGetClientInfoService";
import { Types } from "@ioc/types";
import { useSnackbar } from "notistack";
import {
  IGetCompartmentsResponseDto,
  CompartmentDto,
  IGetSimulationResultDto,
} from "@modules/simulation/dtos/ISimulateLoadDTO";
import { ISimulateLoadService } from "@modules/simulation/models/ISimulateLoadService";
import {
  maskCNPJ,
  maskVehiclePlateMercoSul,
  validatePlate,
} from "@utils/index";
import CardDefault from "@components/CardDefault";
import { CheckListIcon } from "@components/Icons";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    // borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    color: "#626166",
    padding: "1.6rem",
    border: "none",
    borderRadius: "0.4rem",
    fontSize: 14,
    fontFamily: "Open Sans, sans-serif",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
    },
  },
}));

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: "2.4rem",
      fontWeight: "bold",
    },
    container: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    itemSelect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },

    itemSelectTitle: {
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
      marginTop: 10,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    itemSelectSubTitle: {
      color: "#D5D1CB",
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "46px",
      padding: 0,
      marginTop: "-1.6rem",
    },
    iconNotDisabled: {
      opacity: "0.6",
    },

    titlePage: { color: " #3E3D3D" },
    titleCard: {
      color: " #3E3D3D",
      fontFamily: "DM Sans",
      fontSize: "24px",
      fontWeight: 700,
      paddingTop: "2rem",
      paddingBottom: "1rem",
    },
    inputLabel: {
      position: "absolute",
      top: "-2px",
      left: "12px",
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 700,
      backgroundColor: "#fff",
      paddingLeft: "0.2rem",
      paddingRight: "0.8rem",
      marginLeft: "0rem",
      width: "auto",
    },

    customSelect: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      marginTop: "0",
      height: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
      // paddingLeft: "14px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(114, 101, 81, 0.20)",
      },
      "& .MuiSvgIcon-root": {
        fill: "#D91F05",
      },
      borderRadius: "8px",
    },
    formControl: {
      borderRadius: "8px",
      width: "100%",
      height: "100%",
    },
    labelChip: { marginBottom: "0.5rem" },
    containerButton: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "2rem",
      alignItems: "center",
    },
    containerInputs: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "2rem",
      alignItems: "flex-start",
      height: "82px",
    },
    styleButton: {
      width: "22rem",
      textTransform: "none",
      borderRadius: "2px",
      background: "#D91F05",
      boxShadow: "5px 15px 30px 0px rgba(126, 126, 177, 0.10)",
      "&:hover": {
        background: "#D91F31",
      },

      textAlign: "center",
    },
    inputField: {
      border: "none",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&.Mui-focused": {
        backgroundColor: "transparent",
      },
      "& input:valid + fieldset": {
        border: "1px solid rgba(114, 101, 81, 0.20)",
        backgroundColor: "transparent",
      },
      "& input:valid:focus + fieldset": {
        border: "1px solid rgba(114, 101, 81, 0.20)",
        backgroundColor: "transparent",
      },
    },
    qtd: {
      textAlign: "center",
      color: "#FFB03A",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: " 28px",
    },
    message: {
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: " 28px",
    },
  })
);

interface IProductSelecteToExclude {
  [propName: string]: boolean;
}

function missingNo(array: number[], begin = 1) {
  const sorted = array.sort();

  let pivot = 0;

  for (const it of sorted) {
    if (it - pivot > 1) {
      return pivot + 1;
    }
    pivot = it;
  }

  return pivot + 1;
}

interface FormItemsProps {
  handleModal: (resultDTO: IGetSimulationResultDto) => void;
}

const FormItems: React.FC<FormItemsProps> = ({ handleModal }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [, setProductSelectedToExclude] = useState<IProductSelecteToExclude>(
    {}
  );
  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<IFormSimulateOrder>();

  const formContext = useForm();

  const userState = useUserState();

  const iocContext = useIoCContext();

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const [products, setProducts] = useState<SimulationProduct[]>([]);
  const [compartments, setCompartments] = useState<
    IGetCompartmentsResponseDto[]
  >([]);
  const [nextAvailableCompartment, setNextAvailableCompartment] = useState<
    number
  >(1);
  const [inverseCompartments, setInverseCompartments] = useState<
    Record<string, IGetCompartmentsResponseDto>
  >({});
  const [inverseProducts, setInverseProducts] = useState<
    Record<string, string>
  >({});
  const [inverseCNPJ, setInverseCNPJ] = useState<Record<string, string>>({});

  const [selectedCenter, setSelectedCenter] = useState<string | null>(null);

  const simulateLoadService = iocContext.serviceContainer.get<
    ISimulateLoadService
  >(Types.Simulation.ISimulateLoad);

  const removeProducts = () => {
    setProductSelectedToExclude({});
    setFieldValue("products", []);
  };

  useEffect(() => {
    setProductSelectedToExclude({});
    setFieldValue("products", []);
  }, [setFieldValue]);

  const simulate = useCallback(async () => {
    try {
      formContext.setLoadingSimulation(true);
      const compartments = values.products.map((e) => {
        if (!e.compartment) {
          throw new Error(`Compartimento não selecionado em um dos itens`);
        }

        if (!e.quantity) {
          throw new Error(
            `Quantidade inválida no compartimento #${e.compartment}`
          );
        }

        return {
          id: parseInt(e.compartment),
          quantity: e.quantity,
          productID: `${e.id}`,
          seq: ("000" + e.compartment).slice(-3),
        } as CompartmentDto;
      });

      if (!values.placa) {
        throw new Error("Você deve selecionar um veículo");
      }

      if (!selectedCenter) {
        throw new Error("Você deve selecionar um CNPJ");
      }

      const selectedCompartments = values.products.map((p) => p.compartment);

      if (new Set(selectedCompartments).size < selectedCompartments.length) {
        throw new Error("Você selecionou mais de um produto por compartimento");
      }

      if (selectedCompartments.length < compartments.length) {
        throw new Error("Você selecionou mais compartimentos do que possui");
      }

      if (selectedCompartments.length < 1) {
        throw new Error("Você não selecionou nenhum compartimento");
      }

      const response = await simulateLoadService.getSimulation({
        center: selectedCenter as string,
        plate: values.placa.replace(/\W+/g, ""),
        compartments,
      });

      // enqueueSnackbar(response.message, {
      //   variant: response.status === "SUCCESS" ? "success" : "warning",
      // });
      handleModal(response);
    } catch (error) {
      enqueueSnackbar((error as Error).message, {
        variant: "error",
      });
    } finally {
      formContext.setLoadingSimulation(false);
    }
  }, [
    enqueueSnackbar,
    formContext,
    handleModal,
    selectedCenter,
    simulateLoadService,
    values.placa,
    values.products,
  ]);

  useEffect(() => {
    setNextAvailableCompartment(
      missingNo(values.products.map((p) => parseInt(p.compartment || "1")))
    );
    values.products.forEach((p) => {
      if (p.compartment) {
        const dueCompartment = inverseCompartments[p.compartment];
        p.arrows = dueCompartment.arrows;
        p.current_arrow = p.current_arrow || 0;

        p.quantity = p.quantity || p.arrows[p.current_arrow];
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.products]);

  useEffect(() => {
    setInverseCNPJ(
      Object.fromEntries(userState.listCNPJ.map((l) => [l.CNPJ, l.companyName]))
    );
  }, [userState.listCNPJ]);

  useEffect(() => {
    async function execute() {
      try {
        const plate = values.placa;
        if (validatePlate(plate)) {
          setFieldValue("products", []);
          setCompartments([]);
          setLoadingPLATE(true);
          const data = await simulateLoadService.getCompartments(plate);
          setCompartments(data);
        }
      } catch (err) {
        enqueueSnackbar("Placa não encontrada.", {
          variant: "error",
        });
      } finally {
        setLoadingPLATE(false);
      }
    }
    execute();
  }, [enqueueSnackbar, setFieldValue, simulateLoadService, values.placa]);

  useEffect(() => {
    setInverseProducts(
      Object.fromEntries(
        products.map((p) => [p.id, `${p.id} - ${p.description}`])
      )
    );
  }, [products]);

  useEffect(() => {
    setInverseCompartments(
      Object.fromEntries(compartments.map((p) => [p.comNumber, p]))
    );
  }, [compartments]);

  useEffect(() => {
    async function fetch() {
      if (!values.CNPJ) return;
      setFieldValue("products", []);
      try {
        formContext.setLoading(true);

        const getClientInfoService = iocContext.serviceContainer.get<
          IGetClientInfoService
        >(Types.Orders.IGetClientInfoService);

        setLoadingCNPJ(true);
        setProducts([]);

        const CNPJData = await getClientInfoService.execute(values.CNPJ, true);

        setProducts(CNPJData.products);

        if (CNPJData.products.length === 0) {
          enqueueSnackbar("Esse CNPJ não possui produtos disponpiveis!", {
            variant: "warning",
          });
        }

        if (CNPJData.withdrawBasis.length === 0) {
          enqueueSnackbar("Esse CNPJ não possui endereços de entrega!", {
            variant: "warning",
          });
          setSelectedCenter(null);
        } else {
          setSelectedCenter(CNPJData.withdrawBasis[0].filialID);
        }

        setLoadingCNPJ(false);
        setFieldValue("freightType", CNPJData.freightType);
      } catch (error) {
        enqueueSnackbar(
          "Ocorreu um erro ao baixar dados do CNPJ selecionado, recarregando página ...",
          {
            variant: "error",
          }
        );
      } finally {
        formContext.setLoading(false);
      }
    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.CNPJ]);

  const [loadingCNPJ, setLoadingCNPJ] = useState(false);
  const [loadingPLATE, setLoadingPLATE] = useState(false);

  const handleAddProduct = (productSelected: string) => {
    const product = (products.find(
      (ele) => ele.id === productSelected
    ) as unknown) as SimulationProduct;

    try {
      product.uuid = uuidv4();
      setFieldValue("products", [
        ...values.products,
        {
          ...product,
          // current_arrow: 0,
          // quantity: product.arrows![0],
          compartment: `${nextAvailableCompartment}`,
        } as SimulationProduct,
      ]);
    } catch (error) {}
  };

  useEffect(() => {
    setProductSelectedToExclude({});
    setFieldValue("products", []);
  }, [setFieldValue]);

  const renderCPNJ = () => {
    return userState.listCNPJ.map((ele) => {
      return (
        <MenuItem
          key={ele.CNPJ}
          value={ele.CNPJ}
          className={classes.itemSelect}
        >
          <Typography className={classes.itemSelectTitle}>
            {ele.companyName}
          </Typography>
          <Typography className={classes.itemSelectSubTitle}>
            {maskCNPJ(ele.CNPJ)}
          </Typography>
        </MenuItem>
      );
    });
  };

  let isProductDisabled =
    !compartments ||
    !products ||
    (compartments.length === 0 && products && products.length === 0);

  let isButtonAddDisabled = values.products.length >= compartments.length;
  let isButtonSimulationLoading =
    !Boolean(values.products.length) || !selectedCenter || !values.placa;

  return (
    <div>
      <CardDefault>
        <Box width="100%" padding="3rem">
          <Grid
            item
            xs={12}
            style={{ display: "flex", paddingBottom: "1.2rem" }}
          >
            <Grid xs={10}>
              <Typography className={classes.titleCard}>
                Informar dados para simulação
              </Typography>
            </Grid>
            <Grid
              xs={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <CheckListIcon fontSize="small" />
            </Grid>
          </Grid>

          <Grid item container xs={12} className={classes.containerInputs}>
            <Grid
              container
              item
              xs={12}
              xl={4}
              md={4}
              alignItems="center"
              style={{ paddingLeft: isMobile ? "1rem" : "0rem" }}
            >
              <FormControl
                fullWidth
                className={classes.formControl}
                variant="outlined"
              >
                <InputLabel
                  htmlFor="freightType"
                  className={classes.inputLabel}
                >
                  Razão social
                </InputLabel>
                <Select
                  className={classes.customSelect}
                  id="CNPJ"
                  error={!!errors.CNPJ && !!touched.CNPJ}
                  onBlur={() => setFieldTouched("CNPJ", true)}
                  value={values.CNPJ}
                  renderValue={(selected) =>
                    (selected as { length: number })?.length ? (
                      Array.isArray(selected) ? (
                        (selected.map(maskCNPJ).join(", ") as ReactNode)
                      ) : (
                        <MenuItem
                          key=""
                          value={selected as string}
                          style={{
                            padding: -7,
                            margin: -14,
                            display: "block",
                            height: 50,
                          }}
                        >
                          <Typography
                            className={classes.itemSelectTitle}
                            style={{ marginTop: -3 }}
                          >
                            {inverseCNPJ[selected as string]}
                          </Typography>
                          <Typography className={classes.itemSelectSubTitle}>
                            {maskCNPJ(selected as string)}
                          </Typography>
                        </MenuItem>
                      )
                    ) : (
                      ""
                    )
                  }
                  onChange={({ target }) => {
                    setFieldValue("CNPJ", target.value);
                  }}
                  name="CNPJ"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  IconComponent={ExpandMoreIcon}
                >
                  {renderCPNJ()}
                </Select>
              </FormControl>
              <FormHelperText error={!!errors.CNPJ && !!touched.CNPJ}>
                {!!touched.CNPJ && errors.CNPJ}
              </FormHelperText>
              {!loadingCNPJ && !selectedCenter && values.CNPJ && (
                <Box>
                  <Typography style={{ color: "#D91F05" }}>
                    Esse CNPJ não possui endereços de entrega
                  </Typography>
                </Box>
              )}
            </Grid>

            <Grid
              container
              item
              xs={12}
              xl={4}
              md={4}
              alignItems="center"
              style={{
                display: "block",
                paddingLeft: isMobile ? "1rem" : "2rem",
                paddingTop: isMobile ? "2rem" : "0rem",
              }}
            >
              <TextField
                id="placa"
                name="placa"
                onChange={(e: { target: { value: string } }) =>
                  setFieldValue(
                    "placa",
                    maskVehiclePlateMercoSul(e.target.value)
                  )
                }
                style={{
                  borderRadius: "8px",
                  height: "59px",
                  width: "100%",
                  border: "none",
                }}
                label="Placa"
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.inputField,
                  },
                }}
                variant="outlined"
              />
              <FormHelperText error={!!touched.placa && !!errors.placa}>
                {touched.placa && errors.placa && (
                  <div
                    style={{
                      color: "#D91F05",
                      fontSize: "1.2rem",
                      paddingTop: "0.8rem",
                    }}
                  >
                    {errors.placa}
                  </div>
                )}
              </FormHelperText>
            </Grid>

            <Grid
              container
              item
              xs={12}
              xl={4}
              md={4}
              alignItems="center"
              style={{
                paddingLeft: isMobile ? "1rem" : "2rem",
                paddingTop: isMobile ? "1rem" : "0rem",
              }}
            >
              <FormControl
                fullWidth
                className={classes.formControl}
                variant="outlined"
              >
                <InputLabel
                  htmlFor="freightType"
                  className={classes.inputLabel}
                  style={{
                    opacity: isProductDisabled ? 0.3 : 1,
                  }}
                >
                  Produtos
                </InputLabel>
                <Select
                  style={{
                    opacity: isProductDisabled ? 0.3 : 1,
                  }}
                  className={classes.customSelect}
                  disabled={isProductDisabled}
                  name="productSelected"
                  id="productSelected"
                  displayEmpty={true}
                  onChange={(event) => {
                    const e = event as { target: { value: string } };
                    setFieldValue("productSelected", e.target.value);

                    if (values.products.length < compartments.length) {
                      handleAddProduct(e.target.value);
                    }
                  }}
                  IconComponent={ExpandMoreIcon}
                  renderValue={(selected) =>
                    (selected as { length: number })?.length ? (
                      Array.isArray(selected) ? (
                        (selected
                          .map((p) => inverseProducts[p])
                          .join(", ") as ReactNode)
                      ) : (
                        (inverseProducts[selected as string] as ReactNode)
                      )
                    ) : (
                      <></>
                    )
                  }
                >
                  {products.map((prod) => (
                    <MenuItem value={prod.id}>{prod.description}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormHelperText error={!!errors.CNPJ && !!touched.CNPJ}>
                {!!touched.CNPJ && errors.CNPJ}
              </FormHelperText>
              {!loadingCNPJ && !selectedCenter && values.CNPJ && (
                <Box>
                  <Typography style={{ color: "#D91F05" }}>
                    Esse CNPJ não possui endereços de entrega
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>

          <Grid item container xs={12} className={classes.containerButton}>
            <Grid
              item
              xs={6}
              md={12}
              sm={12}
              style={{
                textAlign: "left",
                display: loadingPLATE ? "none" : "flex",
              }}
            >
              {compartments.length > 0 && (
                <Typography className={classes.message}>
                  O veículo selecionado possui{" "}
                  <span className={classes.qtd}>{compartments.length}</span>{" "}
                  compartimento(s).
                </Typography>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              xl={6}
              md={6}
              style={{
                textAlign: "left",
                display: !loadingPLATE ? "none" : "flex",
                alignItems: "center",
              }}
            >
              <Typography className={classes.message}>
                <CircularProgress
                  variant="indeterminate"
                  style={{ width: 16, height: 16, marginRight: 16 }}
                />
                Verificando placa
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }} xl={6} md={6}>
              <Button
                className={classes.styleButton}
                style={{
                  backgroundColor: isButtonAddDisabled ? "#D91F05" : "#D91F05",
                  opacity: isButtonAddDisabled ? 0.3 : 1,
                  color: "#fff",
                }}
                color="primary"
                type="submit"
                variant="contained"
                disabled={isButtonAddDisabled}
                onClick={() => handleAddProduct(values.productSelected)}
                onKeyPress={(e) => {
                  if (e.keyCode === 13 || e.which === 13) {
                    e.preventDefault();
                    return false;
                  }
                }}
              >
                Adicionar produto
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CardDefault>

      <Box
        sx={{
          paddingTop: "2.8rem",
          display: values.productSelected.length ? "flex" : "none",
        }}
      >
        <TableProducts slots={compartments.length} />
      </Box>

      <Box
        sx={{
          display: values.productSelected.length ? "flex" : "none",
          flexDirection: "row",
          justifyContent: "right",
          paddingTop: "2.4rem",
        }}
      >
        <Button variant="text" color="primary" onClick={() => removeProducts()}>
          Limpar
        </Button>
      </Box>

      <Box
        sx={{
          display: values.productSelected.length ? "flex" : "none",
          flexDirection: "row",
          justifyContent: "center",
          paddingTop: "0rem",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={
            !Boolean(values.products.length) || !selectedCenter || !values.placa
          }
          onClick={() => simulate()}
          style={{
            backgroundColor: isButtonSimulationLoading ? "#D91F05" : "#D91F05",
            opacity: isButtonSimulationLoading ? 0.3 : 1,
            color: "#fff",
          }}
        >
          <Typography style={{ textTransform: "none" }}>
            {formContext.loadingSimulation
              ? "Simulando carregamento, aguarde..."
              : "Simular Carregamento"}
          </Typography>
          {formContext.loadingSimulation && (
            <CircularProgress
              color="secondary"
              style={{
                marginLeft: "1rem",
                height: "2rem",
                width: "2rem",
              }}
            />
          )}
        </Button>
      </Box>
    </div>
  );
};

export default FormItems;
