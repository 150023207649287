export enum financialStatusDescriptionPTBR {
  BLOQUEADO = "Bloqueado",
  LIBERADO = "Liberado",
  FATURADO = "Faturado",
  DEVOLUCAO = "Devolução",
}

export enum financialStatusAcronym {
  BL_BLOQUEADO = "BL",
  LIB_LIBERADO = "LIB",
  FT_FATURADO = "FT",
  DEV_DEVOLUCAO = "DEV",
}

// Tratasse das modalidades de entrega
export enum freighTypesEnum {
  CIF = "CIF", // Distribuidora entrega
  FOB = "FOB", // Cliente compra
}
